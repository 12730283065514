import React from "react";
import Button from "../../../../shared/button";
import { useTranslation } from "react-i18next";
import { checkEmpty } from "../../../../../utils/helpers";

const Signature = ({
  provided,
  signatureEditHandler,
  deleteSectionHandler,
  coverLetterSection,
  type,
}) => {
  const { t } = useTranslation();
  // const checkEmptySignatureFields = (param) => {
  //   return !!coverLetterSection.signature && !checkEmpty(param) ? param : "";
  // };
  const signatureContent =
    !!coverLetterSection &&
    !!coverLetterSection.signature &&
    coverLetterSection.signature;
  let key = signatureContent.signType;
  let tabValue = signatureContent.tabValue
    ? JSON.parse(signatureContent.tabValue)
    : "";
  const name =
    !!coverLetterSection && !checkEmpty(coverLetterSection.signature.signName)
      ? coverLetterSection.signature.signName
      : type == "cover-letter"
        ? ""
        : "[Your Name]";

  const sign =
    !!coverLetterSection && !checkEmpty(coverLetterSection.signature.signature)
      ? coverLetterSection.signature.signature
      : "";

  const fullName = !!coverLetterSection && !checkEmpty(coverLetterSection.personalInformation.fullName)
    ? coverLetterSection.personalInformation.fullName
    : "[Your Name]"

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="resume__section resume__summary">
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={signatureEditHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={() => deleteSectionHandler(t("signature.signature"))}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>

        <div className="cvltr__section cvltr__signature">
          <div className="cvltr__content">
            <p className="cvltr-mt30 cvltr-mb30">
              <b>Sincerely,</b>
            </p>
            {/* <p className="cvltr-mb30">
              <b>
                {checkEmptySignatureFields(
                  coverLetterSection.signature.signature
                ) !== "" && (
                  <img
                    className="sign-img"
                    src={coverLetterSection.signature.signature}
                  />
                )}
              </b>
            </p>
            <p>
              <b>
                {checkEmptySignatureFields(
                  coverLetterSection.signature.signName
                )}
              </b>
            </p> */}
            <>
              <p className="">
                {key == t("signature.type") ? (
                  <label className="form-check-label d-flex justify-content-between ps-2 pe-5">
                    <span
                      className={`${tabValue.font} font-24 p-3`}
                      style={{
                        color: tabValue.color,
                      }}
                    >
                      {name}
                    </span>
                  </label>
                ) : (
                  <b>
                    {sign !== "" && <img src={sign} className="sign-img" />}
                  </b>
                )}
                {sign == "" && type !== "cover-letter" && <b>[Signature]</b>}
              </p>
              <p>
                <b>{fullName}</b>
              </p>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
