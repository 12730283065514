import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv3CollegiateCSS = css`
  .resume-layout.cv3 {
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv3 .resume__section {
    padding-top: 3rem;
    padding-bottom: 2em;
    margin-bottom: 0;
  }
  .resume-layout.cv3 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    font-weight: normal;
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv3 .sub-headline {
    display: block;
  }
  .resume-layout.cv3 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv3 .resume__section .sub-headline h2 {
    font-size: 1.29em;
    margin-bottom: 1em;
    text-transform: uppercase;
    background-color: #c2c2c2;
    padding: 0.5em;
    font-weight: bold;
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv3 .resume__section h1,
  .resume-layout.cv3 .resume__section h3 {
    font-size: 1.2em;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv3 .resume__skills .resume__text .wrap {
    width: 50%;
    float: left;
    display: inherit;
    border-right: 1px solid #c1c0c5;
  }
  .resume-layout.cv3 .resume__skills .resume__text .wrap:nth-child(even) {
    border-right: none;
    padding-left: 5rem;
  }
  .resume-layout.cv3 .resume__skills .resume__text .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv3 .resume__skills .resume__text:after {
    content: "";
    clear: both;
    display: table;
  }
  .resume-layout.cv3 .extra .extra-details {
    width: 120px;
  }
  @media (max-width: 767px) {
    .resume-layout.cv3 .resume__skills .resume__text .wrap:nth-child(even) {
      padding-left: 2.55rem;
    }
    .resume-layout.cv3 .extra .extra-details {
      width: 7em;
      margin-right: 5px;
      padding-right: 0;
    }
  }
`;

export const Cv3CollegiateStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv3CollegiateCSS}
`;
