import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";

const createParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderService.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchLanguagesSuccess(response));
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => dispatch(fetchLanguagesFailure(error)));
  };
};
const updateLanguageParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateLanguageSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateLanguageFailure(error)));
  };
};
const fetchLanguagesSuccess = (data) => ({
  type: Types.FETCH_LANGUAGES_SUCCESS,
  data,
});

const fetchLanguagesFailure = (error) => ({
  type: Types.FETCH_LANGUAGES_FAILURE,
  error,
});

const resetLanguages = () => ({
  type: Types.RESET_LANGUAGES,
});
const updateLanguageSuccess = (data) => ({
  type: Types.UPDATE_LANGUAGE_SUCCESS,
  data,
});

const updateLanguageFailure = (error) => ({
  type: Types.UPDATE_LANGUAGE_FAILED,
  error,
});
export { resetLanguages, createParagraph, updateLanguageParagraph };
