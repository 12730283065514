import { createReducer } from "reduxsauce";
import Types from "./types";
import appLogger from "../../utils/app-logger";
export const INITIAL_STATE = {
  docGuid: "",
  paraId: "",
  paragraph: null,
  selectedTemplate: ""
};

export const updatePersonalInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};

export const savePersonalInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const resetPersonalInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.UPDATE_SUCCESS]: updatePersonalInfo,
  [Types.SAVE_SUCCESS]: savePersonalInfo,
  [Types.RESET_PERSONAL_INFO]: resetPersonalInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
