import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/shared/button";
import { Trans, useTranslation } from "react-i18next";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const CancelSubscriptionConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let pathRedirect = PathHelper(APP_NAVIGATION.CASU);
  const onbackBtnHandler = () => {
    navigate(pathRedirect.back);
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.cancel_subscription_confirmation")}</title>
      </Helmet>
      <Header />
      <section className="section main-content">
        <div className="container">
          <div className="alignment-container text-center">
            <div className="row mb-4">
              <div className="col-12 headline-group">
                <hgroup>
                  <h1>{t("cancel_subscriptions_confirmation.heading")}</h1>
                </hgroup>
              </div>
            </div>

            <div className=" text-center">
              <div className="fbn">
                <Button
                  isLoading={false}
                  disabled={false}
                  classNames="btn btn-primary btn-lg"
                  ctaText={t("cancel_subscriptions_confirmation.back")}
                  onClick={onbackBtnHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CancelSubscriptionConfirmation;
