import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    SAVE_FAILED
    UPDATE_FAILED
    UPDATE_SUCCESS
    RESET_PERSONAL_INFO
    GET_SELECTED_TEMPLATE
`,
  {
    prefix: "PERSONAL_INFO/",
  }
);
