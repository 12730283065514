const currentEnv = `${process.env.REACT_APP_ENVIRONMENT}`;

export const getTokenExID = () => {
  const tokenExId = {
    QA: "8195444713621244",
    PRODUCTION: "7219578950671884",
    STAGING: "7219578950671884",
    DEVELOPMENT: "8195444713621244",
  };
  return tokenExId[currentEnv];
};
export const getTokenExSecretKey = () => {
  const tokenExSecretKey = {
    QA: "rXB4vnU6VV1FMIiPC5fPaIbame36vyET6RN8HIeW",
    PRODUCTION: "JEbKQopuB2RCYguHAWBwG2vi8tDgz5oCsD9ZMdcP",
    STAGING: "JEbKQopuB2RCYguHAWBwG2vi8tDgz5oCsD9ZMdcP",
    DEVELOPMENT: "rXB4vnU6VV1FMIiPC5fPaIbame36vyET6RN8HIeW",
  };
  return tokenExSecretKey[currentEnv];
};
export const getTokenExScheme = () => {
  return "sixANTOKENfour";
};
export const getTokenExApiKey = () => {
  const tokenExApiKey = {
    QA: "ZcSNit6Ws8g37fNu2ceE9xCXj0VMusoCJ49kXAfA",
    PRODUCTION: "tRj39D0PShGDYMXB0ySStF6cQa3WHTLI4h76krrg",
    STAGING: "tRj39D0PShGDYMXB0ySStF6cQa3WHTLI4h76krrg",
    DEVELOPMENT: "ZcSNit6Ws8g37fNu2ceE9xCXj0VMusoCJ49kXAfA",
  };
  return tokenExApiKey[currentEnv];
};
export const getTokenExJsURL = () => {
  const tokenExJsURL = {
    QA: "https://test-htp.tokenex.com/Iframe/Iframe-v3.min.js",
    PRODUCTION: "https://htp.tokenex.com/Iframe/Iframe-v3.min.js",
    STAGING: "https://htp.tokenex.com/Iframe/Iframe-v3.min.js",
    DEVELOPMENT: "https://test-htp.tokenex.com/Iframe/Iframe-v3.min.js",
  };
  return tokenExJsURL[currentEnv];
};

export const getPaypalHostUrl = () => {
  const paypalHostUrl = {
    QA: "https://api-m.sandbox.Paypal.com",
    PRODUCTION: "https://api-m.paypal.com",
    STAGING: "https://api-m.paypal.com",
    DEVELOPMENT: "https://api-m.sandbox.Paypal.com",
  };
  return paypalHostUrl[currentEnv];
};
export const getPaypalClientId = () => {
  const paypalClientId = {
    QA: "AS3U8jK4BzOmWOLwZWPUuxes_bp2D0d58moSXmSD-psi2cYR-g6-s9xi-Hl36sc6Bb1t-LEgT4pPqW-J",
    PRODUCTION: "AW4HnSaQDihQ7flT9RRBvNzV0PTkUT-D_Ry0vLZTsLi08ALdYGyb47jYT48ixFjZQfnF4hq_x9uCENRz",
    STAGING: "AW4HnSaQDihQ7flT9RRBvNzV0PTkUT-D_Ry0vLZTsLi08ALdYGyb47jYT48ixFjZQfnF4hq_x9uCENRz",
    DEVELOPMENT: "AS3U8jK4BzOmWOLwZWPUuxes_bp2D0d58moSXmSD-psi2cYR-g6-s9xi-Hl36sc6Bb1t-LEgT4pPqW-J",
  };
  return paypalClientId[currentEnv];
};
export const getPaypalClientSecret = () => {
  const paypalClientSecret = {
    QA: "EHAoKTvJgU5i0Ywli8A29CmGBwhKDzJkpsnAUqWn4-bFphNyEuokOcjT_NwS_8bZgzxkofGw4bRqsV0x",
    PRODUCTION: "EGvvLaMcnI-r5kwbGUGf2gMXSGIazT7IehWAl78ljCGt3DEnL7aqiFiaZe3WNAJyaj8wnv1DzCPl7cHa",
    STAGING: "EGvvLaMcnI-r5kwbGUGf2gMXSGIazT7IehWAl78ljCGt3DEnL7aqiFiaZe3WNAJyaj8wnv1DzCPl7cHa",
    DEVELOPMENT: "EHAoKTvJgU5i0Ywli8A29CmGBwhKDzJkpsnAUqWn4-bFphNyEuokOcjT_NwS_8bZgzxkofGw4bRqsV0x",
  };
  return paypalClientSecret[currentEnv];
};

export const getChargBeeSiteName = () => {
  const chargeBeeSiteName = {
    QA: "pdfsimpli-test",
    PRODUCTION: "pdfsimpli",
    STAGING: "pdfsimpli",
    DEVELOPMENT: "pdfsimpli-test",
  };
  return chargeBeeSiteName[currentEnv];
};
export const getChargBeeUserName = () => {
  const chargBeeUserName = {
    QA: "test_a522ubcua6nw6iEd5Y1VqRl87jY09q9Z3",
    PRODUCTION: "live_P7AGY9QJAvP0c5lXHku5LoVuT7RudUR9",
    STAGING: "live_P7AGY9QJAvP0c5lXHku5LoVuT7RudUR9",
    DEVELOPMENT: "test_a522ubcua6nw6iEd5Y1VqRl87jY09q9Z3",
  };
  return chargBeeUserName[currentEnv];
};

export const getGateWayIdfor3DSCheckout = () => {
  const gtwayId = {
    QA: "gw_Azqgj2Rt6Wc0U5xm",
    PRODUCTION: "gw_Azq9YyTf3P8zU41Ka",
    STAGING: "gw_Azq9YyTf3P8zU41Ka",
    DEVELOPMENT: "gw_Azqgj2Rt6Wc0U5xm",
  };
  return gtwayId[currentEnv];
};
export const getKountKey = () => {
  const kountKey = {
    QA: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMDE0NTIiLCJhdWQiOiJLb3VudC4xIiwiaWF0IjoxNjkwMTg4OTg2LCJzY3AiOnsia2EiOm51bGwsImtjIjpudWxsLCJhcGkiOnRydWUsInJpcyI6dHJ1ZSwidGRzIjpudWxsfX0.TZumbcVr194vAIQ-CJS2C08HnltKlOvhZ7fPQZGt_no",
    PRODUCTION:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMDE0NTIiLCJhdWQiOiJLb3VudC4xIiwiaWF0IjoxNjk1MDUyNDUzLCJzY3AiOnsia2EiOm51bGwsImtjIjpudWxsLCJhcGkiOnRydWUsInJpcyI6dHJ1ZSwidGRzIjpudWxsfX0.RF6sjRq39vcOzJluTp244oVkO07m4zEjofs-9_sf4SY",
    STAGING:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMDE0NTIiLCJhdWQiOiJLb3VudC4xIiwiaWF0IjoxNjk1MDUyNDUzLCJzY3AiOnsia2EiOm51bGwsImtjIjpudWxsLCJhcGkiOnRydWUsInJpcyI6dHJ1ZSwidGRzIjpudWxsfX0.RF6sjRq39vcOzJluTp244oVkO07m4zEjofs-9_sf4SY",
    DEVELOPMENT:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMDE0NTIiLCJhdWQiOiJLb3VudC4xIiwiaWF0IjoxNjkwMTg4OTg2LCJzY3AiOnsia2EiOm51bGwsImtjIjpudWxsLCJhcGkiOnRydWUsInJpcyI6dHJ1ZSwidGRzIjpudWxsfX0.TZumbcVr194vAIQ-CJS2C08HnltKlOvhZ7fPQZGt_no",
  };
  return kountKey[currentEnv];
};
export const getkountEnableOrNot = () => {
  const isKountEnable = {
    QA: "true",
    PRODUCTION: "true",
    STAGING: "true",
    DEVELOPMENT: "true",
  };
  return isKountEnable[currentEnv];
};
export const kountVersion = "0720";
export const kountMerchantId = "101452";
export const KountTransactionSite = "DEFAULT";
//export const KountApiKey="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMDE0NTIiLCJhdWQiOiJLb3VudC4xIiwiaWF0IjoxNjkwMTg4OTg2LCJzY3AiOnsia2EiOm51bGwsImtjIjpudWxsLCJhcGkiOnRydWUsInJpcyI6dHJ1ZSwidGRzIjpudWxsfX0.TZumbcVr194vAIQ-CJS2C08HnltKlOvhZ7fPQZGt_no";
