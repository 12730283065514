import React, { useState, useEffect } from "react";
import TempCoverLetterHeader from "../temp-cover-letter-header";
import TempCoverLetterDesiredCompany from "../temp-cover-letter-desired-company";
import TempCoverLetterOpenerRecommend from "../temp-cover-letter-opener-recommend";
import TempCoverLetterBodyRecommend from "../temp-cover-letter-body-recommend";
import TempCoverLetterCloserRecommend from "../temp-cover-letter-closer-recommend";
import TempCoverLetterSignature from "../temp-cover-letter-signature";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import "./cvltr-4.css";
import { findParaName, focusValidateField, updateActions } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { SECTION_ORDER } from "../../../../utils/constants";

const Cl04Classic = ({
  data,
  headingfontsize,
  coverLetterTemplate,
  paragraphOrdering,
  coverLetterOnDashboard,
  toggleRenameModal,
  toggleDeleteModal,
  setSelectedRenameSection,
  componentRef,
  fontSize,
  fontFamily,
  scrollToSection
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const SelectiveSection = {
    [t("personal_info.para_name")]: TempCoverLetterHeader,
    [t("opener.desired_company")]: TempCoverLetterDesiredCompany,
    [t("opener.opener_recommend")]: TempCoverLetterOpenerRecommend,
    [t("opener.body_recommend")]: TempCoverLetterBodyRecommend,
    [t("opener.closer_recommend")]: TempCoverLetterCloserRecommend,
    [t("signature.signature")]: TempCoverLetterSignature,
  };

  let filterData = data.filter((x) => x.paraCD != "NONE");

  useEffect(() => {
    if (!!data && data.length > 0) {
      let filterData = data.filter((x) => x.paraCD != "NONE");
      updateCharacters(filterData);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    // setTimeout : for smooth scrolling ui experience
    setTimeout(() => {
      focusValidateField(scrollToSection)
    }, 500);
  }, [])

  const [characters, updateCharacters] = useState(filterData);
  const [index, setIndex] = useState("");
  const [indexRecpt, setIndexRecpt] = useState(0);

  const handleOnDragEnd = (result) => {
    const disableDrag = [0];

    if (!result.destination) return;
    if (disableDrag.includes(result.destination.index)) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let list = [];
    items.map((attr) =>
      list.push({
        order: attr.order,
        paraid: attr.id,
        paraName: attr.paraName
      })
    );
    list.map((currElement, index) => {
      switch (currElement.paraName) {
        case t("personal_info.para_name"):
          currElement.order = SECTION_ORDER.HEAD;
          break;

        case t("opener.desired_company"):
          currElement.order = SECTION_ORDER.DSRDCPNY;
          break;

        case t("signature.signature"):
          currElement.order = SECTION_ORDER.SIGN;
          break;

        default:
          currElement.order = index + 1;
          break;
      }
    });
    updateCharacters(items);
    paragraphOrdering(JSON.stringify(list));
  };
  const isDragDisabled = [
    t("personal_info.para_name"),
    t("opener.desired_company"),
    t("signature.signature"),
  ];
  return (
    <>
      <div className="cvltr-wrapper cvltr-preview" ref={componentRef}>
        <div className="cvltr-view">
          <div
            className="cvltr-view-layout cv1 double left cvltr-layout cvltr-4"
            style={{
              fontFamily: fontFamily,
              fontSize: fontSize,
            }}
          >
            <div className="tripple-column cvltr-view-layout-box">
              <div className="single-column">
                {
                  findParaName(t("personal_info.para_name"), characters) &&
                  <TempCoverLetterHeader
                    data={findParaName(t("personal_info.para_name"), characters)}
                  />
                }

                {
                  findParaName(t("opener.desired_company"), characters) &&
                  <TempCoverLetterDesiredCompany
                    data={findParaName(t("opener.desired_company"), characters)}
                    toggleDeleteModal={toggleDeleteModal}
                  />
                }
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable
                    droppableId="characters"
                    isDropDisabled={coverLetterOnDashboard}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {characters?.map((item, index) => {
                          if (
                            item.paraContents &&
                            item.paraContents.length > 0
                          ) {
                            if (!isDragDisabled.includes(item.paraName)) {
                              const Section = SelectiveSection[item.paraName];
                              {
                                Object.assign(item.paraContents[0], {
                                  headingfontsize:
                                    headingfontsize +
                                    (headingfontsize ? "em" : ""),
                                  coverLetterOnDashboard: coverLetterOnDashboard,
                                  fontFamily: fontFamily,
                                });
                              }

                              let paraName = item.paraName;

                              if (paraName === t("opener.desired_company")) {
                                setIndexRecpt(index)
                              }

                              updateActions(dispatch, t, paraName, item)
                              setIndex(index);
                              return (
                                <Draggable
                                  key={item.paraCD + index}
                                  draggableId={item.paraCD + index}
                                  index={index}
                                  isDragDisabled={isDragDisabled.includes(
                                    paraName
                                  )}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...(
                                        paraName === scrollToSection && { id: scrollToSection }
                                      )}
                                    >
                                      {Section && (
                                        <Section
                                          headingfontsize={headingfontsize}
                                          index={index}
                                          fontFamily={fontFamily}
                                          templateType={coverLetterTemplate}
                                          data={item.paraContents}
                                          section={item.paraCD}
                                          paraName={item.paraName}
                                          characters={characters}
                                          docGuid={item.docGuid}
                                          paraId={item.id}
                                          coverLetterOnDashboard={
                                            coverLetterOnDashboard
                                          }
                                          toggleRenameModal={toggleRenameModal}
                                          toggleDeleteModal={toggleDeleteModal}
                                          setSelectedRenameSection={
                                            setSelectedRenameSection
                                          }
                                          dragHandleProps={
                                            provided.dragHandleProps
                                          }
                                          dragStart={provided.draggableProps}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          }
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {
                  findParaName(t("signature.signature"), characters) &&
                  <span {...(
                    t("signature.signature") === scrollToSection && { id: scrollToSection }
                  )} >
                    <TempCoverLetterSignature
                      data={findParaName(t("signature.signature"), characters)}
                      toggleDeleteModal={toggleDeleteModal}
                      characters={characters}
                    />
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cl04Classic;
