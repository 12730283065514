import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterSignatureSection from "./component";

const mapStateToProps = ({
  cvltrTemplatePreview,
  cvltrSignature,
  funnelStep,
}) => ({
  cvltrTemplatePreview,
  cvltrSignature,
  funnelStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterSignatureSection);
