import Types from "./types";

const previewTemplateAction = (data) => ({
  type: Types.PREVIEW,
  data,
});

const handleSteps = (data) => ({
  type: Types.STEPS,
  data,
});

export { previewTemplateAction, handleSteps };
