import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv6ModernCSS = css`
  .resume-layout.cv6 {
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv6 .resume__section {
    padding-top: 3rem;
    padding-bottom: 2em;
    margin-bottom: 0;
  }
  .resume-layout.cv6 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    margin-bottom: 0.5em;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv6 .sub-headline {
    display: block;
  }
  .resume-layout.cv6 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv6 .resume__section .sub-headline h2 {
    border-bottom: 2px dotted #444;
    font-family: "Merriweather", serif;
    font-size: 1.29em;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 0.11em dotted;
    padding-bottom: 0.28em;
  }
  .resume-layout.cv6 .resume__section h1,
  .resume-layout.cv6 .resume__section h3 {
    font-family: "Merriweather", serif;
    font-size: 1.15em;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-weight: normal;
  }
  .resume-layout.cv6 .resume__heading .info-main {
    display: grid;
  }
  .resume-layout.cv6 .resume__heading .info-main .info-item {
    margin-bottom: 3px;
  }
  .resume-layout.cv6 .resume__heading .info-main .info-item:first-child {
    order: 3;
  }
  .resume-layout.cv6 .resume__heading .info-main .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv6 .resume__heading .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv6 .info-item .info-label {
    display: none;
  }
  .resume-layout.cv6 .xp-item {
    display: block;
  }
  .resume-layout.cv6 .xp-item .xp-date,
  .resume-layout.cv6 .xp-item .xp-job {
    // font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv6 .xp-item .xp-date {
    padding-top: 8px;
  }
  .resume-layout.cv6 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv6 .sub-headline .svg-icon {
    left: 0;
    margin-right: 10px;
  }
  .resume-layout.cv6 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv6 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv6 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv6 .resume__education .edu-hide {
    display: block;
  }

  .resume-layout.cv6 .resume__skills .resume__text .wrap {
    width: 100%;
    float: left;
    display: inherit;
  }
  .resume-layout.cv6 .resume__skills .resume__text .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv6 .resume__skills .resume__text:after {
    content: "";
    clear: both;
    display: table;
  }
  .resume-layout.cv6 .extra .extra-details {
    width: 100px;
    padding-right: 10px;
  }
  .resume-layout.cv6 .resume__skills .resume__text .wrap .left-col .extra-info {
    font-weight: bold;
  }
  .resume-layout.cv6 .extra .extra-details {
    width: 120px;
  }
`;

export const Cv6ModernStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv6ModernCSS}
`;
