import React, { useState, useEffect } from "react";
import "./resumes-thumbnail.scss";
import { getTemplateList } from "../../services/master-data";

const ResumeThumb = ({
  item,
  index,
  updateSelection,
  selectedResume,
  callback,
  setThumbMouseOver,
}) => {
  useEffect(() => {
    callback(selectedResume.selected);
  }, []);
  const mouseOver = () => {
    callback(item);
    setThumbMouseOver(true);
  };
  const mouseOut = () => {
    callback(selectedResume.selected);
  };
  const onClick = () => {
    updateSelection({ item, index });
  };
  return (
    <div
      className={
        index === selectedResume.index
          ? "sb-template__template is-selected"
          : "sb-template__template"
      }
      onClick={onClick}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <img src={item.imagesource} />
      <span>{item.templatecd}</span>
    </div>
  );
};

const ResumesThumbnail = ({
  callback,
  selectedTemplateCallBack,
  selectedTemplate,
  setThumbMouseOver,
}) => {
  const [tumbnailList, setTumbnailList] = useState(getTemplateList().data);
  const [selectedResume, setSelectedResume] = useState({
    selected:
      selectedTemplate != ""
        ? tumbnailList.find((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic"),
    index:
      selectedTemplate != ""
        ? tumbnailList.findIndex((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic"),
  });
  const updateSelection = ({ item, index }) => {
    selectedTemplateCallBack(item);
    setSelectedResume({ selected: item, index });
  };
  useEffect(() => {
    const selectedResumeTemplate =
      selectedTemplate != ""
        ? tumbnailList.find((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic");
    const selectedResumeTemplateIndex =
      selectedTemplate != ""
        ? tumbnailList.findIndex((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic");
    setSelectedResume({
      selected: selectedResumeTemplate,
      index: selectedResumeTemplateIndex,
    });
  }, [selectedTemplate]);
  return (
    <>
      {tumbnailList.map((item, index) => {
        return (
          <ResumeThumb
            key={"ResumeThumb" + index}
            item={item}
            index={index}
            updateSelection={updateSelection}
            selectedResume={selectedResume}
            callback={callback}
            setThumbMouseOver={setThumbMouseOver}
          />
        );
      })}
    </>
  );
};

export default ResumesThumbnail;

// const ResumesThumbnail = ({ callback }) => {
//   const [tumbnailList, setTumbnailList] = useState(getTumbnailImages().data);
//   const [clikedClass, setClikedClass] = useState("sb-template__template");
//   const [imgSrc, setImgSrc] = useState("");
//   const selectTemp = (e) => {
//     e.preventDefault();
//     let selectedTag = e
//       ? parseInt(e.target.attributes["index"].value, 10)
//       : null;
//     setClikedClass(selectedTag);
//     const imgSrc = e.target.src.split("/")[e.target.src.split("/").length - 1];
//     const tmptype = imgSrc.split(".")[0];
//     const data = {
//       tmptype: tmptype,
//       category: e.target.attributes["data-category"].value,
//       position: e.target.attributes["data-position"].value,
//     };
//     setImgSrc(data);
//     callback(data);
//   };
//   const MouseOver = (e) => {
//     e.preventDefault();
//     if (e.target.nodeName !== "SPAN") {
//       const tmptype = e.target.attributes["data-temp"].value;
//       const data = {
//         tmptype: tmptype,
//         category: e.target.attributes["data-category"].value,
//         position: e.target.attributes["data-position"].value,
//       };
//       callback(data);
//     }
//   };
//   const MouseOut = (e) => {
//     e.preventDefault();
//     callback(imgSrc);
//   };
//   return (
//     <>
//       {tumbnailList.map((item, index) => {
//         return (
//           <div
//             className={
//               index === clikedClass
//                 ? "sb-template__template is-selected"
//                 : "sb-template__template"
//             }
//             data-temp={item.key}
//             data-category={item.category}
//             data-position={item.position}
//             key={item.key}
//             onClick={selectTemp}
//             onMouseOver={MouseOver}
//             onMouseOut={MouseOut}
//           >
//             <img
//               src={item.value}
//               index={index}
//               data-temp={item.key}
//               data-category={item.category}
//               data-position={item.position}
//             />
//             <span>{item.label}</span>
//           </div>
//         );
//       })}
//     </>
//   );
// };

// export default ResumesThumbnail;
