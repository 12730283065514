import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignOut from "./component";
import { partnerLogOut } from "../../pages/sign-in/actions";
import { resetStore } from "../../pages/review/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      partnerLogOutConnect: partnerLogOut,
      resetStoreConnect: resetStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
