import PersonalInfoTypes from "../../pages/personal-info/types";
import SummaryTypes from "../../pages/summary/types";
import EducationTypes from "../../pages/education/types";
import ExperienceTypes from "../../pages/experience/types";
import SkillTypes from "../../pages/skill/types";
import LanguageTypes from "../../pages/language/types";
import OtherSectionTypes from "../../pages/other-section/types";
import CustomSectionTypes from "../../pages/custom-section/types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import { SECTION_TYPE } from "../../utils/constants";

const getParsedResume = (data) => (dispatch) => {
  return DocBuilderService.getParsedDoc(data)
    .then((response) => {
      if (!!response) {
        let personalInfoSection = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.HEAD
        );
        if (!!personalInfoSection) {
          dispatch(PushDataToPersonalInfoSection(personalInfoSection));
        } else {
          dispatch({
            type: PersonalInfoTypes.RESET_PERSONAL_INFO,
          });
        }
        let summSEction = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.SUMM
        );
        if (!!summSEction) {
          dispatch(PushDataToSummarySection(summSEction));
        } else {
          dispatch({
            type: SummaryTypes.RESET_SUMMARY,
          });
        }
        let educSEction = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.EDUC
        );
        if (!!educSEction) {
          dispatch(PushDataToEducationSection(educSEction));
        } else {
          dispatch({
            type: EducationTypes.RESET_EDUCATION,
          });
        }
        let exprSection = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.EXPR
        );
        if (!!exprSection) {
          dispatch(PushDataToExperienceSection(exprSection));
        } else {
          dispatch({
            type: ExperienceTypes.RESET_EXPERIENCE,
          });
        }
        let skillsSection = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.SKLS
        );
        if (!!skillsSection) {
          dispatch(PushDataToSkillSection(skillsSection));
        } else {
          dispatch({
            type: SkillTypes.RESET_SKILLS,
          });
        }
        let languageSection = response.document.paragraphs.find(
          (x) => x.paraCD == SECTION_TYPE.LANG
        );
        if (!!languageSection) {
          dispatch(PushDataToLanguageSection(languageSection));
        } else {
          dispatch({
            type: LanguageTypes.RESET_LANGUAGES,
          });
        }
        let otherSection = response.document.paragraphs.filter(
          (x) => x.paraCD == SECTION_TYPE.OTHR
        );
        if (!!otherSection) {
          dispatch(PushDataToOtherSection(otherSection));
        } else {
          dispatch({
            type: OtherSectionTypes.RESET_OTHER_SECTION,
          });
        }
        let customSection = response.document.paragraphs.filter(
          (x) => x.paraCD == SECTION_TYPE.CSTM
        );
        if (!!customSection) {
          dispatch(PushDataToCustomSection(customSection));
        } else {
          dispatch({
            type: CustomSectionTypes.RESET_CUSTOM_SECTION,
          });
        }
      } else {
        //dispatch(getUserResumeFailure(response));
      }
      return response;
    })
    .catch((error) => {
      appLogger.log("inside getParsedResume error", error);
      throw new Error(error);
      //dispatch(getUserResumeFailure(error));
    });
};

const PushDataToPersonalInfoSection = (data) => ({
  type: PersonalInfoTypes.SAVE_SUCCESS,
  data,
});
const PushDataToSummarySection = (data) => ({
  type: SummaryTypes.FETCH_SUMMARY_SUCCESS,
  data,
});
const PushDataToEducationSection = (data) => ({
  type: EducationTypes.FETCH_EDUCATION_SUCCESS,
  data,
});
const PushDataToExperienceSection = (data) => ({
  type: ExperienceTypes.FETCH_EXPERIENCE_SUCCESS,
  data,
});
const PushDataToSkillSection = (data) => ({
  type: SkillTypes.FETCH_SKILLS_SUCCESS,
  data,
});
const PushDataToLanguageSection = (data) => ({
  type: LanguageTypes.FETCH_LANGUAGES_SUCCESS,
  data,
});
const PushDataToOtherSection = (data) => ({
  type: OtherSectionTypes.FETCH_OTHER_SECTION_SUCCESS,
  data,
});

const PushDataToCustomSection = (data) => ({
  type: CustomSectionTypes.FETCH_CUSTOM_SUCCESS,
  data,
});

export { getParsedResume };
