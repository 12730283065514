import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterSignature from "./component";
import { setDocGUID } from "../funnel-step/actions";
import {
  createSignatureParagraph,
  saveTabValue,
  updateSignatureParagraph,
  saveSignatureImageAndGetBlobUrl,
} from "./actions";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import {
  handleSteps,
  previewTemplateAction,
} from "../cover-letter-template/actions";
import { deleteParagraph } from "../cover-letter-review/actions";

const mapStateToProps = ({
  personalInfo,
  funnelStep,
  cvltrSignature,
  cvltrTemplatePreview,
  docReview,
}) => ({
  funnelStep,
  cvltrSignature,
  personalInfo,
  cvltrTemplatePreview,
  docReview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signatureConnect: createSignatureParagraph,
      updateSignatureParagraph: updateSignatureParagraph,
      SaveDocGUIDConnect: setDocGUID,
      updateProgress: updateProgress,
      previewTemplateAction: previewTemplateAction,
      handleSteps: handleSteps,
      saveTabValue: saveTabValue,
      deleteParagraphConnect: deleteParagraph,
      saveSignatureImageAndGetBlobUrlConnect: saveSignatureImageAndGetBlobUrl,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterSignature);
