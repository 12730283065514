import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import { SECTION_ORDER, SECTION_CODE, EMPTY_GUID, SECTION_TYPE } from "../../utils/constants";
import { createParagraph } from "../../initialState";

const savePersonalInfo = (data) => {
  return function (dispatch) {
    return DocBuilderService.createDoc(data)
      .then((response) => {
        return response;
      })
      .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};
const createPersonalInfoParagraph = (docGuid, para) => {
  return function (dispatch) {
    return DocBuilderService.createParagraph(docGuid, para)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(savePersonalInfoSuccess(response, response.id));
        }
        return response;
      })
      .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};

const updatePersonalInfo = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updatePersonalInfoSuccess(response));
        } else {
          // dispatch(updatePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => dispatch(updatePersonalInfoFailure(error)));
  };
};
const savePersonalInfoSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

const savePersonalInfoFailure = (error) => ({
  type: Types.SAVE_FAILED,
  error,
});
const updatePersonalInfoSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

const updatePersonalInfoFailure = (error) => ({
  type: Types.UPDATE_FAILED,
  error,
});
const resetPersonalInfo = () => ({
  type: Types.RESET_PERSONAL_INFO,
});

export { savePersonalInfo, updatePersonalInfo, resetPersonalInfo, createPersonalInfoParagraph };
