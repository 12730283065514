import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import FinalStepTypes from "../../pages/funnel-step/types";
import { getTemplateList } from "../../services/master-data";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { blobSignature } from "../../utils/general-config";
import DOMPurify from "dompurify";

const LoaderPopup = ({ loaderPopupShow, handleLoaderPopupHide, uploadResume, error }) => {
  const renderPdfFileUrl = uploadResume?.uploadResponse?.renderPdfFileUrl + blobSignature();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [previewUrl, setPreviewUrl] = useState("");
  const [isLoadedThumbnail, setIsLoadedThumbnail] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [resumeTemplateCd, setResumeTemplateCd] = useState(getTemplateList().data);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("Inside onDocumentLoadSuccess");
    setIsLoadedThumbnail(true);
    setNumPages(numPages);
  }
  const passwordHandler = () => {
    console.log("password handler click");
  };
  useEffect(() => {
    setPreviewUrl(renderPdfFileUrl);
    let currentStep = location.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  return (
    <>
      <Modal
        show={loaderPopupShow}
        onHide={handleLoaderPopupHide}
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="loader-popup"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h2 className="text-center loader-popup-heading">{t("apply_template.heading")}</h2>
          <div className="row justify-content-between">
            <div className="col-4 border p-0">
              <div className={"pdf-review apply-template-review " + (isLoadedThumbnail ? "show" : "loading")}>
                <Document file={previewUrl} onLoadSuccess={onDocumentLoadSuccess} onPassword={passwordHandler}>
                  <Page key={`page_${1}`} pageNumber={1} />
                </Document>
              </div>
            </div>
            <div className="col-4 position-relative">
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="col-4 border skeleton-img">
              <img alt="Quo" className="" src="/app/static/images/skeleton-img.gif" />
            </div>
          </div>

          {/* {error && <div className="text-danger text-center pt-4">{error}</div>} */}

          {error && (
            <div
              className="text-danger text-center pt-4"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(error),
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoaderPopup;
