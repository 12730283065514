import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, link } from "react-router-dom";

// Style
import "./experience.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Plugs
import dompurify from "dompurify";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import MetaContentService from "../../services/meta-content-api";
import appLogger from "../../utils/app-logger";
// Components
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import Footer from "../../components/footer";
import ResumeMap from "../../components/resume-map";
import Button from "../../components/shared/button";
import ExperienceFields from "../../components/experience-fields";
import WriterSnippetExperience from "../../components/writer-snippet-experience";
import WritingTips from "../../components/writing-tips";
import TextEditor from "../../components/text-editor";
import ExperienceList from "../experience-list/component";
import { RequiredValidation } from "../../validators/genericValidator";
import PathHelper from "../../utils/path-helper";
import { EMPTY_GUID } from "../../utils/constants";
import moment from "moment";
import { APP_NAVIGATION } from "../../utils/constants";
import ModalPopup from "../../components/shared/modal-popup";
import { getPortalCD, readCookie, sorterExperience } from "../../utils/helpers";
import ReactLoader from "../../components/shared/loader";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { trackRBEvents } from "../../utils/tracking-events";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Experience = ({
  experience,
  experienceConnect,
  updateExperienceParagraphConnect,
  deleteExperienceConnect,
  funnelStep,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [experienceFieldsData, setExperienceFieldsData] = useState([]);
  const [showTextList, setInitialTextList] = useState(true);
  const [showAddMoreBtn, setShowAddMoreBtn] = useState(false);
  const [isData, setIsData] = useState(false);
  const [expListData, setExpListData] = useState("");
  const [formMessages, setFormMessages] = useState("");
  const [formMessageClass, setFormMessageClass] = useState("alert add");
  const [updateCall, setUpdateCall] = useState(false);
  const [showModalPopUp, setShowModalPopUp] = useState(false);
  const [showLoader, setShowLoader] = useState("d-none");
  const [showStickyBar, setShowStickyBar] = useState(true);
  // Localization
  const { t } = useTranslation();
  let pathscrollRedirect = PathHelper(APP_NAVIGATION.TEXP);
  const sanitizer = dompurify.sanitize;
  const {
    Paragraphs: { paraContents, paraName, id },
  } = experience;
  const { docGUID, funnelStepsComplete, currentStep, userGUID, email, personalInfoName } = funnelStep || {};
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const { locale } = useParams();
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  // Nav
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const backToFormFieldsHandler = () => {
    setInitialTextEditor(!showTextEditor);
  };
  const [allValues, setAllValues] = useState({});
  useEffect(() => {
    trackRBEvents("experienceEditView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    if (!!paraContents) {
      setEditorContent(!!paraContents && paraContents.length > 0 ? paraContents[0].content : "");
      if (paraContents.length > 0) {
        setIsData(true);
        setExpListData(paraContents);
        setInitialTextEditor(!showTextEditor);
        setShowAddMoreBtn(true);
      } else {
        let fieldValue = {
          employer: "",
          jobTitle: "",
          city: "",
          state: "",
          startDateMonth: "",
          startDateYear: "",
          endDateMonth: "",
          endDateYear: "",
          startDateMonthLabel: "",
          endDateMonthLabel: "",
          presently_here: false,
          content: "",
        };
        setAllValues({ ...allValues, fieldValue });
      }
    }
  }, []);
  const updateOrderParaContents = (expParaContent) => {
    return expParaContent.sort(sorterExperience).map(({ order, ...expParaContent }, index) => ({
      ...expParaContent,
      order: index,
    }));
  };

  const saveClickHandler = () => {
    console.log("exp", paraContents);
    console.log("expval", allValues);

    if (typeof paraContents === "undefined" || paraContents.length === 0) {
      if (
        Object.values(allValues.fieldValue).every(
          (value) => value === null || value === false || (typeof value == "string" && !(value || false))
        ) &&
        allValues.content === undefined
      ) {
        toggleModalPopUp(true);
        return true;
      }
    }

    let isValid = triggerValidation();

    if (isValid) {
      setShowLoader("d-block");
      const paraGraphId = !!id ? id : EMPTY_GUID;
      let experienceParaContent = [];
      let objIndex = -1;
      if (!!experienceFieldsData && Object.keys(experienceFieldsData).length > 0) {
        objIndex = paraContents.findIndex((x) => x.id == experienceFieldsData.id);
      }
      if (objIndex > -1) {
        var updatedValues = Object.assign(JSON.parse(paraContents[objIndex].content));
        paraContents[objIndex].content = JSON.stringify(updatedValues);
        setExperienceFieldsData([]);
      } else {
        experienceParaContent.push({
          id: EMPTY_GUID,
          paraId: paraGraphId,
          content: JSON.stringify(allValues.fieldValue),
          createdOn: moment(new Date()),
          modifiedOn: moment(new Date()),
        });
      }
      if (!!paraContents && Object.keys(paraContents).length > 0) {
        paraContents.map((element, i) =>
          experienceParaContent.push({
            id: element.id,
            paraId: element.paraId,
            content: element.content,
            order: element.order,
            createdOn: element.createdOn,
            modifiedOn: element.modifiedOn,
          })
        );
      }
      setAllValues([]);
      const orderedExpParaContent = updateOrderParaContents(experienceParaContent);

      var para = {
        id: paraGraphId,
        docId: docGUID,
        paraContents: [...orderedExpParaContent],
        order:
          !!experience && !!experience.Paragraphs && !!experience.Paragraphs.order && experience.Paragraphs.order != 0
            ? experience.Paragraphs.order
            : SECTION_ORDER.EXPR,
        paraCD: SECTION_TYPE.EXPR,
        sectionCD: SECTION_CODE.EXPR,
        paraName: !!paraName ? paraName : t("experience.employment_history"),
      };
      if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
        updateExperienceParagraphConnect(docGUID, paraGraphId, para)
          .then((response) => {
            setExpListData(response.paraContents);
            setInitialTextEditor(!showTextEditor);
            setInitialTextList(showTextList);
            setShowAddMoreBtn(true);
            setShowLoader("d-none");
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        experienceConnect(para)
          .then((response) => {
            appLogger.log("inside experienceConnect then ");
            setExpListData(response.paraContents);
            setInitialTextEditor(!showTextEditor);
            setInitialTextList(showTextList);
            setShowAddMoreBtn(true);
            setShowLoader("d-none");
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
      setInitialTextEditor(!showTextEditor);
      setInitialTextList(showTextList);
    }
  };

  const saveNextClickHandler = () => {
    setInitialTextEditor(!showTextEditor);
    navigate(pathRedirect.next);
  };
  const [showTextEditor, setInitialTextEditor] = useState(true);
  const setShowEditor = () => {
    setErrors([]);
    setEditorContent();
    if (paraContents.length > 0) {
      let addjobmessage = t("experience.add_another_job");
      setFormMessages(addjobmessage);
      setIsData(true);
      setFormMessageClass("alert add");
    }

    let fieldValue = {
      employer: "",
      jobTitle: "",
      city: "",
      state: "",
      startDateMonth: "",
      startDateYear: "",
      endDateMonth: "",
      startDateMonthLabel: "",
      endDateMonthLabel: "",
      endDateYear: "",
      presently_here: false,
      content: "",
    };
    setAllValues({ ...allValues, fieldValue });
    setInitialTextList(showTextList);
    setShowAddMoreBtn(false);
    setInitialTextEditor(!showTextEditor);
  };
  // Other Stuff
  const [insdustry, setIndustry] = useState([]);

  const callBackExperience = (fieldValue) => {
    if (allValues.fieldValue.content != "") {
      fieldValue.content = allValues.fieldValue.content;
    }
    setAllValues({ ...allValues, fieldValue });
  };

  const callBackEditorVal = (fieldValue, fieldId) => {
    const newTodos = Object.assign({}, allValues);
    newTodos["fieldValue"]["content"] = fieldValue;
    setAllValues(newTodos);
  };

  const triggerValidation = () => {
    let isValidate = true;
    let newDate = moment(new Date());
    let month = newDate.month() + 1;
    let empErrorValidator = RequiredValidation("");
    var employerErrors = {
      errorControl: "expr_employer",
      errorMessage: "",
    };
    var jobTitle = {
      errorControl: "expr_jobTitle",
      errorMessage: "",
    };
    var dateErrors = {
      errorControl: "start_DateYear",
      errorMessage: "",
    };
    var dateMonthErrors = {
      errorControl: "End_DateMonth",
      errorMessage: "",
    };
    if (!!allValues.fieldValue && allValues.fieldValue.employer == "" && allValues.fieldValue.jobTitle == "") {
      isValidate = false;
      setIsValid(false);
      let finalErrors = [];
      employerErrors = {
        errorControl: "expr_employer",
        errorMessage: t("error_messages.invalid_message"),
      };
      jobTitle = {
        errorControl: "expr_jobTitle",
        errorMessage: t("error_messages.invalid_message"),
      };
      finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
      setErrors(finalErrors);
      let message = t("error_messages.must_fill_out_two_fields");
      if (typeof paraContents === "undefined" || paraContents.length >= 1) {
        toggleModalPopUp(true);
      }
      setFormMessages(message);
      setFormMessageClass("alert error");
      //setShowErrorMsg(true);
      setIsData(true);
    }
    if (
      !!allValues.fieldValue &&
      allValues.fieldValue.startDateYear !== "" &&
      allValues.fieldValue.endDateYear !== ""
    ) {
      if (
        (parseInt(allValues.fieldValue.startDateYear) > parseInt(allValues.fieldValue.endDateYear) &&
          parseInt(allValues.fieldValue.startDateYear) !== newDate.year() &&
          !allValues.fieldValue.presently_here) ||
        (parseInt(allValues.fieldValue.startDateYear) === parseInt(allValues.fieldValue.endDateYear) &&
          !allValues.fieldValue.presently_here &&
          parseInt(allValues.fieldValue.startDateMonth) >= parseInt(allValues.fieldValue.endDateMonth)) ||
        parseInt(allValues.fieldValue.startDateYear) > parseInt(allValues.fieldValue.endDateYear)
      ) {
        isValidate = false;
        setIsValid(false);
        let finalErrors = [];
        if (employerErrors === undefined) {
          var employerErrors = {
            errorControl: "expr_employer",
            errorMessage: "",
          };
        }

        if (jobTitle === undefined) {
          var jobTitle = {
            errorControl: "expr_jobTitle",
            errorMessage: "",
          };
        }
        if (allValues.fieldValue.presently_here !== true) {
          dateErrors = {
            errorControl: "start_DateYear",
            errorMessage: t("error_messages.start_date_prior_to_end_date"),
          };
        } else if (
          parseInt(allValues.fieldValue.startDateYear) === newDate.year() &&
          parseInt(allValues.fieldValue.startDateMonth) > month
        ) {
          dateErrors = {
            errorControl: "start_DateYear",
            errorMessage: t("error_messages.date_is_in_future"),
          };
        }

        dateMonthErrors = {
          errorControl: "End_DateMonth",
          errorMessage: "",
        };
        finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
        setErrors(finalErrors);
        //setShowErrorMsg(true);
        setIsData(true);
      } else if (
        parseInt(allValues.fieldValue.startDateYear) === newDate.year() &&
        parseInt(allValues.fieldValue.startDateMonth) > month
      ) {
        isValidate = false;
        setIsValid(false);
        let finalErrors = [];
        if (employerErrors === undefined) {
          var employerErrors = {
            errorControl: "expr_employer",
            errorMessage: "",
          };
        }
        if (jobTitle === undefined) {
          var jobTitle = {
            errorControl: "expr_jobTitle",
            errorMessage: "",
          };
        }
        dateErrors = {
          errorControl: "start_DateYear",
          errorMessage: t("error_messages.date_is_in_future"),
        };
        dateMonthErrors = {
          errorControl: "End_DateMonth",
          errorMessage: "",
        };
        finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
        setErrors(finalErrors);
        //setShowErrorMsg(true);
        setIsData(true);
      } else if (
        !allValues.fieldValue.presently_here &&
        !!allValues.fieldValue &&
        parseInt(allValues.fieldValue.startDateYear) < parseInt(allValues.fieldValue.endDateYear) &&
        parseInt(allValues.fieldValue.endDateMonth) > month &&
        parseInt(allValues.fieldValue.endDateYear) === newDate.year()
      ) {
        isValidate = false;
        setIsValid(false);
        let finalErrors = [];
        dateErrors = {
          errorControl: "start_DateYear",
          errorMessage: "",
        };
        dateMonthErrors = {
          errorControl: "End_DateMonth",
          errorMessage: t("error_messages.date_is_in_future"),
        };
        finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
        setErrors(finalErrors);
        //setShowErrorMsg(true);
        setIsData(true);
      }
      if (
        parseInt(allValues.fieldValue.startDateYear) === parseInt(allValues.fieldValue.endDateYear) &&
        !allValues.fieldValue.presently_here
      ) {
        isValidate = false;
        setIsValid(false);
        let finalErrors = [];
        if (employerErrors === undefined) {
          var employerErrors = {
            errorControl: "expr_employer",
            errorMessage: "",
          };
        }
        if (jobTitle === undefined) {
          var jobTitle = {
            errorControl: "expr_jobTitle",
            errorMessage: "",
          };
        }
        if (
          parseInt(allValues.fieldValue.startDateMonth) > month &&
          parseInt(allValues.fieldValue.endDateMonth) > month &&
          parseInt(allValues.fieldValue.endDateYear) === newDate.year() &&
          parseInt(allValues.fieldValue.startDateYear) === newDate.year()
        ) {
          dateErrors = {
            errorControl: "start_DateYear",
            errorMessage: t("error_messages.date_is_in_future"),
          };
          dateMonthErrors = {
            errorControl: "End_DateMonth",
            errorMessage: t("error_messages.date_is_in_future"),
          };
        } else if (
          parseInt(allValues.fieldValue.startDateMonth) <= month &&
          parseInt(allValues.fieldValue.endDateMonth) > month &&
          parseInt(allValues.fieldValue.endDateYear) === newDate.year()
        ) {
          dateErrors = {
            errorControl: "start_DateYear",
            errorMessage: "",
          };
          dateMonthErrors = {
            errorControl: "End_DateMonth",
            errorMessage: t("error_messages.date_is_in_future"),
          };
        } else if (
          parseInt(allValues.fieldValue.startDateMonth) > month &&
          parseInt(allValues.fieldValue.endDateMonth) < month &&
          parseInt(allValues.fieldValue.startDateYear) === newDate.year()
        ) {
          dateErrors = {
            errorControl: "start_DateYear",
            errorMessage: t("error_messages.date_is_in_future"),
          };
          dateMonthErrors = {
            errorControl: "End_DateMonth",
            errorMessage: "",
          };
        }

        finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
        setErrors(finalErrors);
        setIsData(true);
      }
    } else if (
      (!!allValues.fieldValue && parseInt(allValues.fieldValue.startDateYear) !== "") ||
      (parseInt(allValues.fieldValue.endDateYear) !== "" && !allValues.fieldValue.presently_here)
    ) {
      isValidate = false;
      setIsValid(false);
      let finalErrors = [];
      if (
        parseInt(allValues.fieldValue.startDateMonth) > month &&
        parseInt(allValues.fieldValue.startDateYear) === newDate.year()
      ) {
        dateErrors = {
          errorControl: "start_DateYear",
          errorMessage: t("error_messages.date_is_in_future"),
        };
        dateMonthErrors = {
          errorControl: "End_DateMonth",
          errorMessage: "",
        };
      } else if (
        parseInt(allValues.fieldValue.endDateMonth) > month &&
        parseInt(allValues.fieldValue.endDateYear) === newDate.year()
      ) {
        dateErrors = {
          errorControl: "start_DateYear",
          errorMessage: "",
        };
        dateMonthErrors = {
          errorControl: "End_DateMonth",
          errorMessage: t("error_messages.date_is_in_future"),
        };
      }
      finalErrors = [...[employerErrors], ...[jobTitle], ...[dateErrors], ...[dateMonthErrors]];
      setErrors(finalErrors);
      setIsData(true);
    }
    if (
      employerErrors.errorMessage == "" &&
      jobTitle.errorMessage == "" &&
      dateErrors.errorMessage == "" &&
      dateMonthErrors.errorMessage == ""
    ) {
      isValidate = true;
    }
    if (employerErrors.errorMessage == "" && jobTitle.errorMessage == "") {
      setIsData(false);
    }
    if (allValues.fieldValue.startDateMonthLabel === "Mon") {
      allValues.fieldValue.startDateMonthLabel = "";
    }
    if (allValues.fieldValue.endDateMonthLabel === "Mon") {
      allValues.fieldValue.endDateMonthLabel = "";
    }
    if (
      (!!allValues.fieldValue && !experienceFieldsData.length === 0) ||
      (!!allValues.fieldValue && experienceFieldsData.content != undefined)
    ) {
      if (allValues.fieldValue.content == "") {
        allValues.fieldValue.content = JSON.parse(experienceFieldsData.content).content;
      }
      let dataupdated = JSON.stringify(allValues.fieldValue);
      experienceFieldsData.content = dataupdated;
      setExperienceFieldsData(experienceFieldsData);
    }

    return isValidate;
  };

  let pathRedirect = PathHelper(APP_NAVIGATION.EXPR, funnelStepsComplete);

  const UpdateParaContent = (paraContentId) => {
    if (!!paraContents && paraContents.length > 0) {
      let paraData = paraContents.find((x) => x.id == paraContentId);
      setInitialTextEditor(true);
      let message = JSON.parse(paraData.content).jobTitle;
      setFormMessages(message);
      setFormMessageClass("alert edit");
      setErrors([]);
      let fieldValue = JSON.parse(paraData.content);
      setAllValues({ ...allValues, fieldValue });
      setIsData(true);
      setExperienceFieldsData(paraData);
      setUpdateCall(true);
      setShowAddMoreBtn(false);
      setEditorContent(paraData.content);
    }
  };
  const UpdateExpList = (paraContentId, i) => {
    if (!!expListData && expListData.length > 0) {
      setInitialTextEditor(false);
      setShowAddMoreBtn(true);
      let newFormValues = [...expListData];
      var index = newFormValues.findIndex((o) => o["id"] === paraContentId);
      if (index > -1) {
        newFormValues.splice(index, 1);
      }
      if (newFormValues.length === 0) {
        setExperienceFieldsData([]);
      }
      setExpListData(newFormValues);
      var para = {
        id: expListData[0].paraId,
        docId: docGUID,
        paraContents: [...newFormValues],
        order: SECTION_ORDER.EXPR,
        paraCD: SECTION_TYPE.EXPR,
        sectionCD: 40,
        paraName: !!paraName ? paraName : t("experience.employment_history"),
      };
      deleteExperienceConnect(docGUID, expListData[0].paraId, para)
        .then((response) => {
          console.log(response);
          if (!!response.paraContents) {
            setFormMessages("");
            setExperienceFieldsData([]);
            setAllValues([]);
            setIsData(false);
            setInitialTextEditor(false);
            setShowAddMoreBtn(true);
          }
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail);
        });
    }
  };
  const toggleModalPopUp = () => setShowModalPopUp((q) => !q);
  const callBackModalValue = (fieldValue) => {
    console.log(fieldValue);
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.experience")}</title>
      </Helmet>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={2} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}

      <ReactLoader showloader={showLoader} />
      <section className={classNames(funnelStepsComplete ? "" : "sidebar-sticky", "section main-content templates")}>
        <div className="container ">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="exp-header">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("experience.form_title")),
                    }}
                  />
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("experience.form_subtitle")),
                    }}
                  />
                </hgroup>
                <WritingTips section={SECTION_TYPE.EXPR} page="Experience" />
              </div>
            </div>
            <div className="row">
              {" "}
              <div className="col-md-12">
                {showTextList && (
                  <ExperienceList
                    paraContents={expListData}
                    UpdateParaContent={UpdateParaContent}
                    UpdateExpList={UpdateExpList}
                  />
                )}

                {showTextEditor && (
                  <div className="row">
                    {isData && (
                      <div className="col-12">
                        <div id="form-messages" className={formMessageClass} role="alert">
                          {formMessageClass != "alert edit" && formMessages}
                          {formMessageClass === "alert edit" && (
                            <>
                              {t("experience.editing_your")}
                              <strong> "{formMessages}" </strong>
                              {t("experience.job_position")}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 col-lg-9 ">
                      <ExperienceFields
                        callback={callBackExperience}
                        isValid={isValid}
                        errors={errors}
                        experienceFieldsData={experienceFieldsData}
                        updateCall={updateCall}
                        setShowStickyBar={setShowStickyBar}
                      />
                    </div>

                    <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--xp">
                      <ResumeMap />
                    </div>
                    {showTextEditor && (
                      <div className="editor-box">
                        <label className="text optional px-1" htmlFor="experience_content">
                          {t("experience.job_Description")}
                        </label>
                        <div className="col-sm-12 col-lg-12 text-editor-container">
                          <div className="writer ">
                            <TextEditor
                              sectionCode="EXPR"
                              callback={callBackEditorVal}
                              editorContent={editorContent}
                              placeholder={t("experience.exp_editor_placeholder")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                {showAddMoreBtn && (
                  <button className="btn btn-primary btn-lg add-exp-btn is-mobile" onClick={setShowEditor}>
                    <span className="fa fa-plus"></span>
                    {t("experience.add_another_exp")}
                  </button>
                )}
              </div>
              <div
                className={
                  showStickyBar === true
                    ? "btn-container fbn sticky-bar btn-justify cvltr-footer-btn"
                    : "btn-container fbn sticky-bar btn-justify d-none"
                }
              >
                <Button
                  isLoading={false}
                  classNames="btn btn-outline-secondary btn-lg exp-back-btn cvltr-btn-blue"
                  id="exp_back_btn"
                  ctaText={t("personal_info.back_action")}
                  onClick={backClickHandler}
                />
                {/* {!showTextEditor && (
                  <Button
                    isLoading={false}
                    classNames="btn btn-outline-secondary btn-lg"
                    ctaText={t("personal_info.back_action")}
                    onClick={backToFormFieldsHandler}
                  />
                )} */}
                {showAddMoreBtn && (
                  <button
                    className="btn btn-primary btn-lg add-exp-btn is-desktop"
                    onClick={setShowEditor}
                    id="add_other_position"
                  >
                    <span className="fa fa-plus"></span>
                    {t("experience.add_another_exp")}
                  </button>
                )}
                {!showTextEditor && (
                  <Button
                    isLoading={false}
                    disabled={false}
                    onClick={saveNextClickHandler}
                    classNames="btn btn-primary btn-lg submit-on-enter save-n-next-btn"
                    id="save-next-btn-exp"
                    ctaText={t("experience.next_action")}
                  />
                )}
                {showTextEditor && (
                  <Button
                    isLoading={false}
                    disabled={false}
                    onClick={saveClickHandler}
                    classNames="btn btn-primary btn-lg submit-on-enter "
                    id="save_next_btn"
                    ctaText={t("experience.save")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalPopup
        message={
          paraContents.length === 0
            ? {
                __html: sanitizer(t("experience.exp_modal_message")),
              }
            : {
                __html: sanitizer(t("experience.exp_modal_message_second_exp")),
              }
        }
        footerText={
          paraContents.length === 0
            ? {
                __html: sanitizer(t("experience.exp_modal__footer_text")),
              }
            : {
                __html: sanitizer(t("experience.exp_modal__footer_text_second_exp")),
              }
        }
        skipPage={pathRedirect}
        show={showModalPopUp}
        toggleShow={toggleModalPopUp}
        header={t("experience.exp_modal_title")}
        callback={callBackModalValue}
      />
      <Footer />
    </>
  );
};

export default Experience;
