import React from "react";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const CoverLetterReviewAddSection = ({ alreadyFilledPara }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let personalInfoRedirect = PathHelper(APP_NAVIGATION.TPRSNLINFO, false);
  let openerRedirect = PathHelper(APP_NAVIGATION.TDSRDCPNY, false);
  let bodyRedirect = PathHelper(APP_NAVIGATION.TBODY, false);
  let closerRedirect = PathHelper(APP_NAVIGATION.TCLSR, false);
  let signatureRedirect = PathHelper(APP_NAVIGATION.TSIGN, false);

  let checkCompany = alreadyFilledPara.includes(t("opener.desired_company"));
  let checkOpener = alreadyFilledPara.includes(t("opener.opener_recommend"));

  return (
    <div className="review-add-section">
      {!alreadyFilledPara.includes(t("personal_info.para_name")) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(personalInfoRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.cvltr_header")}
        />
      )}
      {!checkCompany || !checkOpener ? (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(openerRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.cvltr_opener")}
        />
      ) : (
        ""
      )}
      {!alreadyFilledPara.includes(t("opener.body_recommend")) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(bodyRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.cvltr_body")}
        />
      )}
      {!alreadyFilledPara.includes(t("opener.closer_recommend")) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(closerRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.cvltr_closer")}
        />
      )}
      {!alreadyFilledPara.includes(t("signature.signature")) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(signatureRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.cvltr_signature")}
        />
      )}
    </div>
  );
};

export default CoverLetterReviewAddSection;
