import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UploadResume from "./component";
import { uploadResume, uploadDocs } from "./actions";

const mapStateToProps = ({ uploadResume, funnelStep }) => ({
  uploadResume,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadResumeConnect: uploadResume,
      uploadDocsConnect: uploadDocs,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadResume);
