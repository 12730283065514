import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../../utils/constants";
import Button from "../../shared/button";
import { useNavigate, link } from "react-router-dom";
import PathHelper from "../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../utils/constants";

const ResumeSummary = ({ data }) => {
  const summary = JSON.parse(data.content);
  const [content, setContent] = useState();

  useEffect(() => {
    if (!!data && !!data.content) {
      let summaryContent = JSON.parse(data.content)["content"];
      setContent(summaryContent);
    }
  }, [data]);

  return (
    <div className="other">
      <div
        className="other-info"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

const TempSummary = ({
  data,
  toggleRenameModal,
  toggleDeleteModal,
  setCurrentParaConnect,
  setSelectedRenameSection,
  paraName,
  dragHandleProps,
  dragStart,
}) => {
  let dragClass = false;
  if (dragStart !== undefined) {
    if (dragStart.style.position !== undefined) {
      dragClass = true;
    }
  }
  const { t } = useTranslation();
  if (paraName === "Professional Summary") {
    paraName = t("summary.para_name");
  }
  let pathRedirect = PathHelper(APP_NAVIGATION.TSUM, false);
  const FinalReviewSummaryClick = () => {
    setSelectedRenameSection(paraName);
    toggleRenameModal();
    setCurrentParaConnect(SECTION_TYPE.SUMM, "");
  };
  const delteSectionHandler = () => {
    toggleDeleteModal();
    setCurrentParaConnect(SECTION_TYPE.SUMM, "");
  };
  // Nav
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };
  return (
    <>
      <div
        className={
          dragClass
            ? "resume__section resume__summary dragging"
            : "resume__section resume__summary"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={editHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={FinalReviewSummaryClick}
            icon={
              <>
                <span className="fa fa-file-text-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.rename")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={delteSectionHandler}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>

        <div className="resume__content">
          <div className="sub-headline">
            <i className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                aria-hidden="true"
                style={{ MsTransform: "rotate(360deg)" }}
                transform="rotate(360)"
                viewBox="0 0 1792 1408"
              >
                <path
                  fill="currentColor"
                  d="M888 1056l116-116-152-152-116 116v56h96v96h56zm440-720q-16-16-33 1L945 687q-17 17-1 33t33-1l350-350q17-17 1-33zm80 594v190q0 119-84.5 203.5T1120 1408H288q-119 0-203.5-84.5T0 1120V288Q0 169 84.5 84.5T288 0h832q63 0 117 25 15 7 18 23 3 17-9 29l-49 49q-14 14-32 8-23-6-45-6H288q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113V994q0-13 9-22l64-64q15-15 35-7t20 29zm-96-738l288 288-672 672H640V864zm444 132l-92 92-288-288 92-92q28-28 68-28t68 28l152 152q28 28 28 68t-28 68z"
                ></path>
              </svg>
            </i>
            <h2
              style={{
                fontSize: data[0].headingfontsize,
                fontFamily: data[0].fontFamily,
              }}
            >
              {paraName}
            </h2>
          </div>
          {data.map((item, index) => (
            <ResumeSummary data={item} key={"TempSummary" + index} />
          ))}
        </div>

        <div className="resume__move" {...dragHandleProps}>
          <a
            className="btn-green resume__control-button js-sortableHandle"
            href="#"
          >
            <span className="fa fa-arrows" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </>
  );
};

export default TempSummary;
