import React, { useEffect, useState, useCallback } from "react";
import RBDropDown from "../../components/shared/dropdown/component";
import { EMPTY_GUID } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";

const WriterSnippetExperience = ({
  actionName,
  sectionCode,
  fetchIndustryConnect,
  fetchSubIndustryConnect,
  fetchMetaContentConnect,
}) => {
  // Other Stuff
  const { t } = useTranslation();
  const [insdustry, setIndustry] = useState([]);
  const [subInsdustry, setSubInsdustry] = useState([]);
  const [selectInsdustry, setSelectInsdustry] = useState("");
  const [selectSubInsdustry, setSelectSubInsdustry] = useState("");
  const [writingContent, setWritingContent] = useState([]);
  const [careerField, setcareerField] = useState("");
  const [careerSubField, setcareerSubField] = useState("");
  const { locale } = useParams();

  useEffect(() => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    const metaContentSectionCode = sectionCode == "SUMM" ? "EXPR" : sectionCode;
    fetchIndustryConnect(metaContentSectionCode, verifiedLocale)
      .then((response) => {
        if (!!response) {
          let industryOptions = response.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          industryOptions = [{ label: t("experience.please_select"), value: EMPTY_GUID }, ...industryOptions];
          setIndustry(industryOptions);
          if (!!industryOptions && !!industryOptions[0] && industryOptions[0].value != EMPTY_GUID) {
            fetchSubIndustryConnect(industryOptions[0].value)
              .then((subResponse) => {
                if (!!subResponse) {
                  let subIndustryOptions = subResponse.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  });
                  subIndustryOptions = [
                    { label: t("experience.please_select"), value: EMPTY_GUID },
                    ...subIndustryOptions,
                  ];
                  setSubInsdustry(subIndustryOptions);
                }
              })
              .catch((error) => {
                console.log("Error in getSubIndustryComp");
              });
          }
        }
      })
      .catch((error) => {
        console.log("Error in getIndustryComp");
      });
    fetchWritingContent(sectionCode);
  }, []);
  useEffect(() => {
    if (!!selectInsdustry && selectInsdustry != EMPTY_GUID) {
      fetchSubIndustryConnect(selectInsdustry)
        .then((response) => {
          if (!!response) {
            var subindustryOptions = response.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            });
            subindustryOptions = [{ label: t("experience.please_select"), value: EMPTY_GUID }, ...subindustryOptions];
            setSubInsdustry(subindustryOptions);
          }
        })
        .catch((error) => {
          console.log("Error in getSubIndustryComp");
        });
    }
    fetchWritingContent(sectionCode, selectInsdustry);
  }, [selectInsdustry]);
  useEffect(() => {
    fetchWritingContent(sectionCode, selectInsdustry, selectSubInsdustry);
  }, [selectSubInsdustry]);
  const fetchWritingContent = (sectionCode, selectInsdustry, selectSubInsdustry) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    if (!!selectInsdustry == false && !!selectSubInsdustry) {
      selectInsdustry = insdustry[0].value;
    }
    fetchMetaContentConnect(sectionCode, selectInsdustry, selectSubInsdustry, verifiedLocale)
      .then((response) => {
        if (!!response) {
          setWritingContent(response);
        }
      })
      .catch((error) => {
        console.log("error in getMetaContentComp");
      });
  };

  const [experienceTxt, setExperience] = useState("");
  const addexperienceHandler = (content) => (e) => {
    let newFormValues = [...experienceTxt];
    newFormValues = content;
    setExperience(newFormValues);
    let type = "";
    if (sectionCode == "SUMM") {
      //type = "list-item";
      type = "unordered-list-item";
    } else {
      type = "unordered-list-item";
    }
    //const type = 'list-item';
    actionName(newFormValues, type);
  };
  const ChangeHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setcareerField(value);
    setSelectInsdustry(value);
    const newIndustry = insdustry.filter((t) => t.value !== EMPTY_GUID);
    setIndustry(newIndustry);
  };
  const SubIndustryChangeHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setcareerSubField(value);
    setSelectSubInsdustry(value);
    const newSubIndustry = subInsdustry.filter((t) => t.value !== EMPTY_GUID);
    setSubInsdustry(newSubIndustry);
  };
  return (
    <>
      <div className="writer__snippet-wrapper">
        <div className="writer__snippet-options snippet-summary">
          <div className="writer__snippet-intro">
            <p>
              {sectionCode !== "SUMM" && t("experience.editor_writter_snippet")}
              {sectionCode === "SUMM" && t("summary.editor_writter_snippet")}
            </p>
          </div>

          <div className="row snippet-summary--dropdown">
            <div className={sectionCode == "SUMM" ? "col-12 summary-select" : "col-6"}>
              <label>{t("experience.carrier_field")}</label>

              <RBDropDown
                selectedValue={careerField || ""}
                index={0}
                id="exp-career"
                options={insdustry}
                onChange={ChangeHandler}
              />
            </div>
            <div className={sectionCode == "SUMM" ? "d-none" : "col-6"}>
              <label>{t("experience.carrier_sub_field")}</label>
              <RBDropDown
                selectedValue={careerSubField || ""}
                index={0}
                id="exp-sub-career"
                options={subInsdustry}
                onChange={SubIndustryChangeHandler}
                disabled={careerField != "" ? false : true}
              />
            </div>
          </div>
        </div>

        <div className="writer__snippet-inner">
          <div className="writer__snippet-container">
            {!!writingContent &&
              writingContent.length > 0 &&
              writingContent.map((item, index) => (
                <div
                  key={index}
                  className="writer__snippet add-label-wrap"
                  data-content={item.content}
                  data-format="list"
                  onClick={addexperienceHandler(item.content)}
                >
                  <div className="add-label">
                    <span>{t("experience.add_text")}</span>
                  </div>
                  <div className="add_text_content" id={item.id}>
                    {item.content?.slice(0, 176)}
                    {item.content?.length > 176 && "..."}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WriterSnippetExperience;
