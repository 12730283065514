import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv15GravityCSS = css`
  .resume-layout.cv15 {
    font-weight: normal;
    font-family: "Roboto TTF", sans-serif;
  }
  // .resume-layout.cv15 .resume__section {
  //   padding-top: 3rem;
  //   padding-bottom: 2em;
  //   margin-bottom: 0;
  // }
  .resume-layout.cv15 .resume-main .resume__section {
    padding-left: 0;
  }
  .resume-layout.cv15 .resume__heading {
    font-weight: 300;
  }
  .resume-layout.cv15 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    padding-top: 0.5rem;
    text-transform: capitalize;
    font-weight: bold;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv15 .resume__section .sub-headline h2 {
    font-size: 1.43em;
    line-height: 1;
    margin-bottom: 0;
    font-family: "Roboto TTF", sans-serif;
    display: inline-block;
    text-transform: capitalize;
  }
  .resume-layout.cv15 .resume__section h1,
  .resume-layout.cv15 .resume__section h3 {
    font-size: 1.15em;
    line-height: 1;
    margin-bottom: 0.25em;
    font-family: "Roboto TTF", sans-serif;
    display: inline-block;
    text-transform: capitalize;
  }
  .resume-layout.cv15 .resume-header .info-main {
    display: grid;
  }
  .resume-layout.cv15 .resume-header .info-main .info-item:first-child {
    order: 3;
  }
  .resume-layout.cv15 .resume-header .info-main .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv15 .resume-header .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv15 .resume__summary ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .resume-layout.cv15 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv15 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv15 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv15 .resume__education .edu-hide {
    display: block;
  }
  .resume-layout.cv15 .resume-aside {
    order: 2;
    width: 25%;
    margin-right: 15px;
    padding-top: 1rem;
  }
  .resume-layout.cv15 .resume-main {
    order: 1;
    width: 75%;
    padding-top: 1rem;
    padding-right: 5rem;
  }
  .resume-layout.cv15 .resume-aside .resume__heading {
    display: none;
  }
  .resume-layout.cv15 .sub-headline {
    margin-bottom: 1rem;
  }
  .resume-layout.cv15 .sub-headline .svg-icon {
    display: block;
    margin-right: 10px;
  }
  .resume-layout.cv15 .sub-headline .svg-icon {
    background-color: #42a2e0;
    border: 4px solid #a1d1f0;
    border-radius: 100%;
    color: #ffefe3;
    height: 3em;
    width: 3em;
    line-height: normal;
    padding: 0.65rem;
    text-align: center;
    margin-left: 0;
  }
  .resume-layout.cv15 .sub-headline .svg-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .resume-layout.cv15 .info-item {
    display: block;
  }
  .resume-layout.cv15 .info-item .svg-icon,
  .resume-layout.cv15 .info-item .info-text {
    display: inline-block;
  }
  .resume-layout.cv15 .info-item .info-label {
    display: none;
  }
  .resume-layout.cv15 .wrap .left-col,
  .resume-layout.cv15 .wrap .extra-info,
  .resume-layout.cv15 .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv15 .extra {
    display: block;
  }
  .resume-layout.cv15 .extra .extra-details .progress-starts,
  .resume-layout.cv15 .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv15 .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv15
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #42a2e0;
  }
  .resume-layout.cv15 .xp-item {
    display: block;
  }
  .resume-layout.cv15 .xp-item .xp-date,
  .resume-layout.cv15 .xp-item .xp-job {
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv15 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv15 .xp-item .xp-date {
    color: #42a2e0;
  }
  @media (max-width: 767px) {
    .resume-layout.cv15 .sub-headline .svg-icon svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: -4px;
      margin-top: -6px;
    }
  }
`;

export const Cv15GravityStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv15GravityCSS}
`;
