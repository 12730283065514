import React from "react";
import { useNavigate, useCallback } from "react-router-dom";
import Button from "../../components/shared/button";
import { APP_NAVIGATION } from "../../utils/constants";
import PathHelper from "../../utils/path-helper";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

const PrintBtnGroup = ({ callback, content, downloadId, printId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let pathRedirect = PathHelper(APP_NAVIGATION.REVW);
  const downloadResume = (e) => {
    const {
      currentTarget: { name },
    } = e;
    callback(name);
  };
  const printResume = (e) => {
    const {
      currentTarget: { name },
    } = e;
    callback(name);
  };
  return (
    <>
      <Button
        isLoading={false}
        classNames="btn btn-primary btn-lg download-resume"
        ctaText=""
        onClick={downloadResume}
        id={downloadId ? downloadId : "btnDownload"}
        name={"btnDownload"}
        icon={
          <>
            <span className="fa fa-download fa-downlaod-icon"></span>
            <span className="resume__control-text download-text">{t("review.download_resume")}</span>
          </>
        }
      />
      {/* <ReactToPrint
        trigger={() => (
          <Button
            isLoading={false}
            disabled={false}
            onClick={printResume}
            classNames="btn btn-primary btn-lg submit-on-enter"
            ctaText={t("review.print_resume")}
            id="btnPrint"
          />
        )}
        content={() => content}
      /> */}
      <Button
        isLoading={false}
        disabled={false}
        onClick={printResume}
        classNames="btn btn-primary btn-lg print-btn"
        id={printId ? printId : "btnPrint"}
        name={"btnPrint"}
        ctaText=""
        icon={
          <>
            <span className="fa fa-print"></span>
            <span className="resume__control-text" id="btnPrint">
              {t("review.print_resume")}
            </span>
          </>
        }
      />
    </>
  );
};

export default PrintBtnGroup;
