import React from "react";

const UnauthorizedAccess = () => {
    return (
        <div>
      UnauthorizedAccess
        </div>
    );
};

export default UnauthorizedAccess;
