import React, { useState } from "react";
import PropTypes from "prop-types";
import useToggle from "../../hooks/use-toggle";

const InputRadio = ({
  showLabel,
  value = "",
  name = "",
  id = "",
  className = "form-control",
  text = "",
  checked = "",
  placeholder = "",
  onChange = () => {},
  onBlurCallback = () => {},
  validator = () => {},
  errorMessage = "",
  inputRef,
  onFocusCallback = () => {},
  maxLength = 30,
  onKeyPress = () => {},
  type = "radio",
  validationError = false,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useToggle(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMessage);

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    if (validator(inputValue)) {
      setHasError(false);
      setError("");
    } else {
      setHasError(true);
      setError(errorMessage);
    }
    onChange(e);
  };

  const onFocus = () => {
    setIsFocused();
    setIsTouched(true);
    onFocusCallback();
  };

  const onBlur = (e) => {
    setIsFocused();
    onBlurCallback(e.target.value);
  };
  return (
    <>
      {showLabel && <label className="form-label">{text}</label>}
      <input
        ref={inputRef}
        id={id}
        placeholder={placeholder}
        type={type}
        name={name}
        className={className}
        checked={checked === value}
        value={value}
        onChange={onChangeHandler}
        onFocus={(e) => onFocus(e)}
        onBlur={(e) => onBlur(e)}
        onKeyPress={(e) => onKeyPress(e)}
      />
      <label htmlFor={id} className="boolean optional">
        {value}{" "}
      </label>
    </>
  );
};

InputRadio.propTypes = {
  value: PropTypes.string,
  showLabel: PropTypes.bool,
  id: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onBlurCallback: PropTypes.func,
  maxLength: PropTypes.number,
  validator: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  onFocusCallback: PropTypes.func,
};
export default InputRadio;
