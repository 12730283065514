import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createParagraph, updateSummaryParagraph } from "./actions";
import Summary from "./component";

const mapStateToProps = ({ summary, funnelStep }) => ({ summary, funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      summaryConnect: createParagraph,
      updateSummaryConnect: updateSummaryParagraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
