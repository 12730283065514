import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CancelSubscription from "./component";
import { cancelUserByUserGuid } from "./actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ cancelUserConnect: cancelUserByUserGuid }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscription);
