import React, { useEffect } from "react";
import { useLocation, Redirect, useParams } from "react-router-dom";
import { createCookie, readCookie } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { useTranslation } from "react-i18next";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { DOC_TYPE } from "../../utils/constants";
import FinalStepTypes from "../funnel-step/types";
import { useDispatch, useSelector } from "react-redux";
import { ProcessLogout } from "../../utils/process-logout";
import { isPaymentMSEnable } from "../../utils/general-config";

const SsoTransfer = ({ getUserObjectConnect, funnelStep, resetStoreConnect, validateLgctTokenConnect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { docGUID, funnelStepsComplete, docType } = funnelStep || {};

  const { locale } = useParams();
  const dispatch = useDispatch();

  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  const NavigateRoute = (target) => {
    let targetParams =
      decodeURIComponent(target).indexOf("?") > -1 ? "?" + decodeURIComponent(target).split("?").pop() : "";

    if (target.split("/").pop().includes("resumes") === true) {
      navigate((!!locale ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES) + targetParams);
    } else if (target.split("/").pop().includes("cover-letters") === true) {
      navigate(
        (!!locale ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTERS_LOCALE) : ROUTE_CONSTANT.COVER_LETTERS) + targetParams
      );
    } else if (target.split("/").pop().includes("cover-letter-introduction") === true) {
      navigate(
        (!!locale ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTER_INTRO_LOCALE) : ROUTE_CONSTANT.COVER_LETTER_INTRO) +
          targetParams
      );
    } else if (target.split("/").pop().includes("plans") === true) {
      // if (target.split("/").pop().includes("cvlt")) {
      //   let targetUrl = decodeURIComponent(target.split("/").pop()).split("?");
      //   let urlStr = targetUrl[targetUrl.length - 1];
      //   let docGUID = urlStr.split("=")
      //     ? urlStr.split("=")[urlStr.split("=").length - 1]
      //     : "";
      //   if (docGUID !== "") {
      //     dispatch({
      //       type: FinalStepTypes.SET_DOCGUID,
      //       docGUID,
      //     });
      //   }
      //   let docType = DOC_TYPE.COVER_LETTER;
      //   dispatch({
      //     type: FinalStepTypes.SET_DOC_TYPE,
      //     docType,
      //   });
      // }
      //navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS);
      const isPmsAvaiable = readCookie("pms");
      if (isPmsAvaiable === "true" || isPaymentMSEnable() === true) {
        window.location.assign(decodeURIComponent(target));
      } else {
        navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS);
      }
    } else if (target.split("/").pop().includes("review") === true) {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW);
    } else if (target.split("/").pop().includes("cancel-subscription") === true) {
      navigate(
        !!locale ? setLocaleURL(ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_LOCALE, locale) : ROUTE_CONSTANT.CANCEL_SUBSCRIPTION
      );
    } else if (target.split("/").pop().includes("dashboard")) {
      // external href/redirect to dashboard as it is not a component.
      window.location.assign(decodeURIComponent(target));
    } else {
      navigate(
        (!!locale ? setLocaleURL(ROUTE_CONSTANT.INTRODUCTION_LOCALE) : ROUTE_CONSTANT.INTRODUCTION) + targetParams
      );
    }
  };
  useEffect(() => {
    const jwtToken = new URLSearchParams(location.search).get("jtkn");
    const legacyTicket = new URLSearchParams(location.search).get("lgct");
    const target = new URLSearchParams(location.search).get("tgt");
    createCookie("jwtToken", jwtToken, 1);
    if (!!legacyTicket) {
      const tgtUrl = new URL(decodeURIComponent(target));
      const searchParamsTgt = new URLSearchParams(tgtUrl.search);
      if (searchParamsTgt.has("pt")) {
        validateLgctTokenConnect(decodeURIComponent(legacyTicket)).then((response) => {
          if (response) {
            createCookie("legacyTicket", decodeURIComponent(legacyTicket), 1);
            if (!!jwtToken) {
              getUserObjectConnect().then((response) => {
                console.log(response);
                createCookie("jwtToken", jwtToken, 1);
                createCookie("ssoToken", jwtToken, 1);
                createCookie("ssoauth", "1");
                NavigateRoute(target);
              });
            } else {
              NavigateRoute(target);
            }
          } else {
            resetStoreConnect(true);
            ProcessLogout("", "", "", locale);
          }
        });
      } else {
        createCookie("legacyTicket", decodeURIComponent(legacyTicket), 1);
        if (!!jwtToken) {
          getUserObjectConnect().then((response) => {
            console.log(response);
            createCookie("jwtToken", jwtToken, 1);
            createCookie("ssoToken", jwtToken, 1);
            createCookie("ssoauth", "1");
            NavigateRoute(target);
          });
        } else {
          NavigateRoute(target);
        }
      }
    } else if (!!jwtToken) {
      getUserObjectConnect().then((response) => {
        console.log(response);
        createCookie("jwtToken", jwtToken, 1);
        createCookie("ssoToken", jwtToken, 1);
        createCookie("ssoauth", "1");
        NavigateRoute(target);
      });
    } else {
      NavigateRoute(target);
    }
  }, []);
  return (
    <div>
      <div className="displaymobile" style={{marginLeft:"144px"}}>
        <img
          className="rb-logo height-37 my-4 ps-20"
          src={process.env.PUBLIC_URL + "/static/images/resumebuild-logo.svg"}
          width="117"
          alt="resumebuild"
          draggable="false"
        />
      </div>
      <section className="section main-content text-center">
        <div className="container intro">
          <div className="row">
            <div className="col-12 headline-group">
              <hgroup>
                <h2>{t("generic_error.please_wait")}</h2>
              </hgroup>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SsoTransfer;
