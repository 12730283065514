import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminLogin from "./component";
import { adminLoginProcess } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ adminLoginProcessConnect: adminLoginProcess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
