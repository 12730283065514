import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import { getCoverLetterSkills } from "../../services/master-data";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";
import { checkEmpty } from "../../utils/helpers";

const fetchSkills = (t) => (dispatch) => {
  let response = getCoverLetterSkills(t);
  dispatch(fetchSkillsSuccess(response.data));
};

const createSkillsParagraph = (
  docGUID,
  paragraph,
  paraName,
  selectedSkilsList,
  newSkillsList,
  data
) => {
  return function (dispatch) {
    if (checkEmpty(docGUID)) {
      return DocBuilderService.createDoc(data).then((response) => {
        if (response) {
          let para = createParagraph(
            response.docGuid,
            EMPTY_GUID,
            EMPTY_GUID,
            paragraph,
            SECTION_ORDER.BODY,
            SECTION_TYPE.BODY,
            SECTION_CODE.BODY,
            paraName
          );

          DocBuilderService.createParagraph(response.docGuid, para).then(
            (paraResponse) => {
              dispatch(saveSkillsSuccess(paraResponse, paraResponse.id));
              dispatch(selectedSkills(selectedSkilsList));
              dispatch(getNewSkills(newSkillsList));
            }
          );
        }

        return response;
      });
    } else {
      let para = createParagraph(
        docGUID,
        EMPTY_GUID,
        EMPTY_GUID,
        paragraph,
        SECTION_ORDER.BODY,
        SECTION_TYPE.BODY,
        SECTION_CODE.BODY,
        paraName
      );

      return DocBuilderService.createParagraph(docGUID, para).then(
        (paraResponse) => {
          dispatch(saveSkillsSuccess(paraResponse, paraResponse.id));
          dispatch(selectedSkills(selectedSkilsList));
          dispatch(getNewSkills(newSkillsList));
          let response = {
            docGuid: docGUID,
          };
          return response;
        }
      );
    }
  };
};

const updateSkillsParagraph = (
  docid,
  paraid,
  data,
  selectedSkilsList,
  newSkillsList
) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateSkillsSuccess(response));
          dispatch(selectedSkills(selectedSkilsList));
          dispatch(getNewSkills(newSkillsList));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveSkillsSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateSkillsSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

const fetchSkillsSuccess = (data) => ({
  type: Types.FETCH_SKILLS_SUCCESS,
  data,
});

export const selectedSkills = (data) => ({
  type: Types.SELECTED_SKILLS,
  data,
});

export const getNewSkills = (data) => ({
  type: Types.NEW_SKILL_LIST,
  data,
});
export const resetSkills = () => ({
  type: Types.RESET_SKILLS,
});
export { fetchSkills, createSkillsParagraph, updateSkillsParagraph };
