import React from "react";

const IconFileLeftArrow = ({
  width = 94,
  height = 103,
  className = { className },
  color = "black", // or any color of your choice
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 94 103"
        className={className}
        width={width}
        height={height}
      >
        <g
          id="Get_Back_File"
          data-name="Get Back File"
          transform="translate(-646 -263)"
        >
          <g
            id="Group_97"
            data-name="Group 97"
            transform="translate(-5283 2271.112)"
          >
            <g
              id="Subtraction_6"
              data-name="Subtraction 6"
              transform="translate(5929 -2008.112)"
              fill="#fff"
            >
              <path
                d="M 72.00089263916016 101.9996185302734 L 6.000309944152832 101.9996185302734 C 3.243138313293457 101.9996185302734 1.000009775161743 99.75649261474609 1.000009775161743 96.99930572509766 L 1.000009775161743 28.82760047912598 L 28.77824401855469 0.9999914169311523 L 72.00089263916016 1.000019788742065 C 74.75758361816406 1.000019788742065 77.00031280517578 3.243134021759033 77.00031280517578 6.000305652618408 L 77.00031280517578 96.99930572509766 C 77.00031280517578 99.75649261474609 74.75758361816406 101.9996185302734 72.00089263916016 101.9996185302734 Z"
                stroke="none"
              />
              <path
                d="M 72.00090789794922 100.9996032714844 C 74.20618438720703 100.9996032714844 76.00030517578125 99.20508575439453 76.00030517578125 96.99929809570312 L 76.00030517578125 6.000297546386719 C 76.00030517578125 3.794543981552124 74.20618438720703 2.000020742416382 72.00090789794922 2.000020742416382 L 29.19296264648438 2.000005722045898 L 1.999998688697815 29.24127388000488 L 1.999998688697815 96.99929809570312 C 1.999998688697815 99.20508575439453 3.794521808624268 100.9996032714844 6.000306606292725 100.9996032714844 L 72.00090789794922 100.9996032714844 M 72.00090789794922 102.9996032714844 L 6.000306606292725 102.9996032714844 C 2.69172191619873 102.9996032714844 -1.258263182535302e-06 100.3078842163086 -1.258263182535302e-06 96.99929809570312 L -1.258263182535302e-06 28.41389846801758 L 28.36350631713867 5.450439402920892e-06 L 72.00090789794922 2.083505569316912e-05 C 75.30898284912109 2.083505569316912e-05 78.00030517578125 2.691728591918945 78.00030517578125 6.000297546386719 L 78.00030517578125 96.99929809570312 C 78.00030517578125 100.3078842163086 75.30898284912109 102.9996032714844 72.00090789794922 102.9996032714844 Z"
                stroke="none"
                fill="#2fbe6e"
              />
            </g>
            <line
              id="Line_13"
              data-name="Line 13"
              x2="27.169"
              transform="translate(5967.435 -1993.217)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-2"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1983.756)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-3"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1974.295)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-4"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1964.834)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-5"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1955.374)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-6"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1945.913)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-7"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1936.452)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-8"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1926.991)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <line
              id="Line_13-9"
              data-name="Line 13"
              x2="53.155"
              transform="translate(5941.449 -1917.53)"
              fill="none"
              stroke="#dfdfdf"
              strokeWidth="2"
            />
            <g
              id="Path_133"
              data-name="Path 133"
              transform="translate(5929 -2008)"
              fill="#fff"
            >
              <path
                d="M 24.8349723815918 28.56544303894043 L 1.23161244392395 28.56544303894043 L 28.56544303894043 1.23161244392395 L 28.56544303894043 24.8349723815918 C 28.56544303894043 26.8919620513916 26.8919620513916 28.56544303894043 24.8349723815918 28.56544303894043 Z"
                stroke="none"
              />
              <path
                d="M 27.56544303894043 3.645801544189453 L 3.645801544189453 27.56544303894043 L 24.8349723815918 27.56544303894043 C 26.34056282043457 27.56544303894043 27.56544303894043 26.34056282043457 27.56544303894043 24.8349723815918 L 27.56544303894043 3.645801544189453 M 28.38282203674316 1.9073486328125e-06 C 29.03597259521484 1.9073486328125e-06 29.56544303894043 0.5294723510742188 29.56544303894043 1.182613372802734 L 29.56544303894043 24.8349723815918 C 29.56544303894043 27.44754219055176 27.44754219055176 29.56544303894043 24.8349723815918 29.56544303894043 L 1.182613372802734 29.56544303894043 C 0.5294723510742188 29.56544303894043 1.9073486328125e-06 29.03597259521484 1.9073486328125e-06 28.38282203674316 L 28.38282203674316 1.9073486328125e-06 Z"
                stroke="none"
                fill="#2fbe6e"
              />
            </g>
          </g>
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(705 320)"
            fill="#2fbe6e"
          />
          <path
            id="Path_136"
            data-name="Path 136"
            d="M12,19.5V4.5m0,0L5.25,11.25M12,4.5l6.75,6.75"
            transform="translate(710.75 349.5) rotate(-90)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.5"
          />
        </g>
      </svg>
    </>
  );
};

export default IconFileLeftArrow;
