// Core
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FinalStepTypes from "../funnel-step/types";
import { useLocation } from "react-router-dom";
// Style
import "./review.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch } from "react-redux";

// Plugs
import dompurify from "dompurify";
import { useTranslation } from "react-i18next";

// Components
import Header from "../../components/header";
import Footer from "../../components/footer";
import ResumeTools from "../../components/resume-tools";
import ResumesThumbnail from "../../components/resumes-thumbnail";
import FinalReview from "../../components/cvs/final-review";
import PrintBtnGroup from "../../components/print-btn-group";
import ReviewAddSection from "../../components/review-add-section";
import AddSectionModal from "../../components/shared/add-section-modal";
import RenameSectionModal from "../../components/shared/rename-section-modal";
import DeletePopupModal from "../../components/shared/delete-popup-modal";
import { EMPTY_GUID, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, AFFILIATE_STORAGE_NAME, AFFILIATE_KEYWORD_NAME } from "../../utils/constants";
import ReactToPrint from "react-to-print";
import DownloadModal from "../../components/shared/download-modal";
import ReactLoader from "../../components/shared/loader";
import DownloadLoaderPopup from "../../components/shared/download-loader-popup";

import Cv1Classic from "../../components/cvs/resume-templates/cv1-classic";
import Cv2ExecutiveI from "../../components/cvs/resume-templates/cv2-executive-i";
import Cv3Collegiate from "../../components/cvs/resume-templates/cv3-collegiate";
import Cv4Elegant from "../../components/cvs/resume-templates/cv4-elegant";
import Cv5Original from "../../components/cvs/resume-templates/cv5-original";
import Cv6Modern from "../../components/cvs/resume-templates/cv6-modern";
import Cv7Fugiat from "../../components/cvs/resume-templates/cv7-fugiat";
import Cv8Totam from "../../components/cvs/resume-templates/cv8-totam";
import Cv9Lectus from "../../components/cvs/resume-templates/cv9-lectus";
import Cv10Harum from "../../components/cvs/resume-templates/cv10-harum";
import Cv11Pure from "../../components/cvs/resume-templates/cv11-pure";
import Cv12Graphite from "../../components/cvs/resume-templates/cv12-graphite";
import Cv13Quo from "../../components/cvs/resume-templates/cv13-quo";
import Cv14Armony from "../../components/cvs/resume-templates/cv14-armony";
import Cv15Gravity from "../../components/cvs/resume-templates/cv15-gravity";
import Cv19Pro from "../../components/cvs/resume-templates/cv19-pro";
import Cv20Neque from "../../components/cvs/resume-templates/cv20-neque";
import CvDistinct from "../../components/cvs/resume-templates/cv-distinct";
import CvExpressive from "../../components/cvs/resume-templates/cv-expressive";
import CvBold from "../../components/cvs/resume-templates/cv-bold";
import ReviewStyle from "../../components/cvs/review-style/common-style/component";
import { getTemplateList } from "../../services/master-data";
import { getTumbnailImages } from "../../services/master-data";
import { Helmet } from "react-helmet";
import { downloadUserResume } from "../../initialState";
import {
  readCookie,
  deleteCookie,
  getPortalCD,
  setLocaleURL,
  navigateToPaymentMS,
  createCookie,
  PerquisiteBountyEnable,
} from "../../utils/helpers";
import appLogger from "../../utils/app-logger";
import { useParams } from "react-router-dom";
import { trackRBEvents } from "../../utils/tracking-events";
import { DOC_TYPE } from "../../utils/constants";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { isPaymentMSEnable } from "../../utils/general-config";
import { setTemplate } from "../funnel-step/actions";
const SelectedResumeComponent = ({ name, ...other }) => {
  const SelectiveSection = {
    cv1: Cv1Classic,
    cv2: Cv2ExecutiveI,
    cv3: Cv3Collegiate,
    cv4: Cv4Elegant,
    cv5: Cv5Original,
    cv6: Cv6Modern,
    cv7: Cv7Fugiat,
    cv8: Cv8Totam,
    cv9: Cv9Lectus,
    cv10: Cv10Harum,
    cv11: Cv11Pure,
    cv12: Cv12Graphite,
    cv13: Cv13Quo,
    cv14: Cv14Armony,
    cv15: Cv15Gravity,
    cv19: Cv19Pro,
    cv20: Cv20Neque,
    Distinct: CvDistinct,
    Expressive: CvExpressive,
    Bold: CvBold,
  };
  if (!name) {
    name = "Classic";
  }
  const SelectedResume = SelectiveSection[name];
  if (!SelectedResume) {
    return null;
  }
  return <SelectedResume {...other} />;
};

const Review = ({
  getUserResumeConnect,
  docReview,
  resetStoreConnect,
  funnelStep,
  updateOrderingConnect,
  renameParagraphConnect,
  deleteParagraphConnect,
  updateDocStyleConnect,
  updateUserGuidConnect,
  getUserProfileConnect,
  setTemplateConnect,
  updateTemplateCdConnect,
  generatePDFPathConnect,
  setDocGUIDConnect,
}) => {
  let pathRedirect = PathHelper(APP_NAVIGATION.REVW);
  const { locale } = useParams();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { t } = useTranslation();
  const { reviewDoc } = docReview || {};
  const [fontFamily, setFontFamily] = useState();
  const [templatefontFamily, setTemplateFontFamily] = useState();
  const [fontSize, setFontSize] = useState();
  const [headingfontsize, setHeadingFontSize] = useState("");
  const [resumeTemplate, setResumeTemplate] = useState("cv1");
  const [resumeCategory, setResumeCategory] = useState("single");
  const [resumePosition, setResumePosition] = useState("left");
  const [resumePersonalInfo, setResumePersonalInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [resumeOnDashboard, setResumeOnDashboard] = useState(false);
  const [renameVal, setRenameVal] = useState("");
  const [thumbMouseOver, setThumbMouseOver] = useState(false);
  const [selectedHtml, setselectedHtml] = useState("");
  const componentRef = useRef(null);
  const [isUpdated, setIsUpdated] = useState(true);
  const {
    templateCd,
    docGUID,
    currentPara,
    currentParaId,
    userGUID,
    parentUserGuid,
    email,
    personalInfoName,
    docType,
  } = funnelStep || {};
  const [showLoader, setShowLoader] = useState("d-none");
  const [tumbnailList, setTumbnailList] = useState(getTemplateList().data);
  const [isLoaded, setIsLoaded] = useState(false);
  let renameSection = "";
  let alreadyFilledDocPara = [];
  let isRequestForPrint = false;
  let filteredDocPara = reviewDoc?.paragraphs || [];
  let selectedTemp = reviewDoc?.templateCD || "";
  //const [selectedTemplate, setSelectedTemplate] = useState(templateCd);
  const [selectedTemplate, setSelectedTemplate] = useState(templateCd ? templateCd : selectedTemp);
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
    filteredDocPara.map((element) => {
      if (
        !(
          (element.paraCD === SECTION_TYPE.EXPR && element.paraContents.length === 0) ||
          (element.paraCD === SECTION_TYPE.EDUC && element.paraContents.length === 0)
        )
      ) {
        alreadyFilledDocPara.push(element.paraCD);
      }
    });
  }

  const [docSectionDetail, setDocSectionDetail] = useState(filteredDocPara);
  const [isDataLoaded, setIsDataLoaded] = useState(filteredDocPara.length > 0 ? true : false);
  const [downloadResumeModal, setDownloadResumeModal] = useState(false);
  const [downloadLoaderModal, setDownloadLoaderModal] = useState(false);
  const [downloadModalText, setDownloadModalText] = useState(t("review.resume_is_being_built"));
  const [downloadModalTitle, setDownloadModalTitle] = useState(t("review.review_download_btn"));
  const [downloadModalBtnText, setDownloadModalBtnText] = useState(t("review.review_download_btn"));
  const [isDownloadError, setIsDownloadError] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);

  let docGuid = docGUID;
  if (queryParams.has("docid") && !!queryParams.get("docid")) {
    docGuid = queryParams.get("docid");
  }

  let userGuid = userGUID;
  if (!docGuid) {
    docGuid = !!readCookie("docId") ? readCookie("docId") : reviewDoc?.id;
  }
  if (!userGuid) {
    userGuid = readCookie("userguid");
  }

  // if (!docGuid || !userGuid) {
  //   docGuid = !!readCookie("docId") ? readCookie("docId") : reviewDoc?.id;
  //   userGuid = readCookie("userguid");
  // }
  useEffect(() => {
    trackRBEvents("resumeRevisitView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGuid,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    setShowLoader("d-block");

    LoadResume(docGuid, userGuid);
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    var funnelStepsComplete = true;
    dispatch({
      type: FinalStepTypes.SET_FUNNEL_STEP_COMPLETE,
      funnelStepsComplete,
    });
    if (docType != DOC_TYPE.RESUME) {
      let docTypeUpdate = DOC_TYPE.RESUME;
      dispatch({
        type: FinalStepTypes.SET_DOC_TYPE,
        docTypeUpdate,
      });
    }
    if (!!readCookie("jwtToken")) {
      generatePDFPathConnect(docGuid, userGuid).then((response) => {
        if (response) {
        } else {
          appLogger.log("error while generatePDFPath");
        }
      });
    }
    if (!!readCookie(AFFILIATE_STORAGE_NAME)) {
      deleteCookie(AFFILIATE_STORAGE_NAME);
    }
    if (!!readCookie(AFFILIATE_KEYWORD_NAME)) {
      deleteCookie(AFFILIATE_KEYWORD_NAME);
    }
  }, []);

  useEffect(() => {
    setShowLoader("d-block");
    let isLoadDone = false;
    if (!!reviewDoc && reviewDoc?.paragraphs.length > 0) {
      setIsLoaded(true);
      isLoadDone = true;
      let filteredDocPara = reviewDoc?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }
      setDocSectionDetail(filteredDocPara.sort((a, b) => a.order - b.order));
    } else {
      if (!!docGuid && !!userGuid) {
        getUserResumeConnect(docGuid, userGuid, t)
          .then((response) => {
            if (!!response && response?.paragraphs.length > 0) {
              setIsLoaded(true);
              isLoadDone = true;
            }
          })
          .catch((err) => {
            const { detail } = err;
          });
      } else {
        navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
      }
    }
    if (isLoadDone) {
      setSelectedTemplate(reviewDoc.templateCD);
      setselectedHtml(componentRef.current);
      setShowLoader("d-none");
    }
  }, [JSON.stringify(reviewDoc)]);
  const LoadResume = (docGuid, userGuid) => {
    if (!!docGuid && !!userGuid) {
      getUserResumeConnect(docGuid, userGuid, t).then((response) => {
        if (!templateCd) {
          let selectedResume = tumbnailList.find((x) => x.templatecd === response.templateCD);
          setResumeTemplate(selectedResume.key);
          setSelectedTemplate(response.templateCD);
          setTemplateConnect(response.templateCD);
          setDocGUIDConnect(docGuid);
        }
      });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
  };
  const stateDocStyle =
    !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle ? docReview.reviewDoc.docStyle : {};
  const callBackResumeToolsValue = (fieldValue, fieldId, resetVal, isDocStyleUpdate) => {
    let fontFamilyValue = "";
    let fontSizeValue = "";
    let headingSizeValue = "";
    if (fieldId === "fontFamily") {
      setFontFamily(fieldValue);
      if (!!stateDocStyle.fontFamily == false) {
        fontFamilyValue = fieldValue;
      } else if (!!stateDocStyle.fontFamily && stateDocStyle.fontFamily != fieldValue) fontFamilyValue = fieldValue;
      //setIsUpdated(false);
    }
    if (fieldId === "fontSize") {
      setFontSize(fieldValue);
      if (!!stateDocStyle.fontSize == false) {
        fontSizeValue = fieldValue;
      } else if (!!stateDocStyle.fontSize && stateDocStyle.fontSize != fieldValue) fontSizeValue = fieldValue;
    }
    if (fieldId === "headingFontSize") {
      setHeadingFontSize(fieldValue);
      if (!!stateDocStyle.headingSize == false) {
        headingSizeValue = fieldValue;
      } else if (!!stateDocStyle.headingSize && stateDocStyle.headingSize != fieldValue) headingSizeValue = fieldValue;
    }
    if (fieldId === "reset") {
      fontFamilyValue = resetVal.fontFamily;
      fontSizeValue = resetVal.fontSize;
      headingSizeValue = resetVal.headingFontSize;
      if (!!stateDocStyle && Object.keys(stateDocStyle).length > 0) {
        stateDocStyle.fontSize = "";
        stateDocStyle.fontFamily = "";
        stateDocStyle.headingSize = "";
      }
      setFontFamily(resetVal.fontFamily);
      setFontSize(resetVal.fontSize);
      setHeadingFontSize(resetVal.headingFontSize);
      setTemplateFontFamily(resetVal.fontFamily);
    }
    fontFamilyValue = !!fontFamilyValue ? fontFamilyValue : stateDocStyle.fontFamily;
    fontSizeValue = !!fontSizeValue ? fontSizeValue : stateDocStyle.fontSize;
    headingSizeValue = !!headingSizeValue ? headingSizeValue : stateDocStyle.headingSize;
    if (isDocStyleUpdate) {
      let docStyle = {
        docId: docGuid,
        fontFamily: fontFamilyValue,
        fontSize: fontSizeValue,
        headingSize: headingSizeValue,
      };
      updateDocStyleConnect(docGuid, docStyle)
        .then((response) => {
          setIsUpdated(true);
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };

  const paragraphOrderingList = (data) => {
    const docId = docGUID;
    updateOrderingConnect(docId, data)
      .then((response) => {})
      .catch((err) => {
        const { detail } = err;
      });
  };
  const callBackMouseHoverTemplate = (fieldValue) => {
    if (!!fieldValue) {
      setResumeTemplate(fieldValue.key);
    }
  };
  const callBackSelectedTemplate = (fieldValue) => {
    setselectedHtml(componentRef.current);
    setResumeTemplate(fieldValue.key);
    setTemplateConnect(fieldValue.templatecd);
    updateTemplateCdConnect(docGuid, fieldValue.templatecd);
    let docStyle = {
      fontFamily: fieldValue.resetFontFamily,
      docId: docGuid,
      fontSize: fieldValue.resetFontSize,
      headingSize: fieldValue.resetHeadingFontSize,
    };
    // updateDocStyleConnect(docGUID, docStyle)
    //   .then((response) => {
    //     if (!!response) {
    //       //callBackResumeToolsValue(fieldValue.resetFontFamily, "fontFamily");
    //       setTemplateFontFamily(fieldValue.resetFontFamily);
    //     }
    //   })
    //   .catch((err) => {
    //     const { detail } = err;
    //   });
    setSelectedTemplate(fieldValue.templatecd);
  };
  const toggleShow = () => setShow((p) => !p);
  const toggleRenameModal = () => {
    setRenameVal(renameSection);
    setShowRenameModal((q) => !q);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((d) => !d);
  };

  const callBackCustomSection = (fieldValue) => {
    console.log(fieldValue);
  };
  const callBackRenameSection = (newSectionName) => {
    if (!!reviewDoc && !!reviewDoc.paragraphs && !!reviewDoc.paragraphs.length > 0) {
      if (currentPara === SECTION_TYPE.OTHR || currentPara === SECTION_TYPE.CSTM) {
        let para = reviewDoc.paragraphs.find((x) => x.id == currentParaId && x.paraCD == currentPara);
        renameParagraphConnect(para.docId, para.id, currentPara, newSectionName);
      } else {
        let para = reviewDoc.paragraphs.find((x) => x.paraCD == currentPara);
        renameParagraphConnect(para.docId, para.id, currentPara, newSectionName);
      }
    }
    setShowRenameModal(false);
  };
  const callBackDeleteSection = (newSectionName) => {
    if (!!reviewDoc && !!reviewDoc.paragraphs && !!reviewDoc.paragraphs.length > 0) {
      let para = reviewDoc.paragraphs.find((x) => x.paraCD == currentPara);
      deleteParagraphConnect(para.docId, para.id, para.paraCD);
    }
    setShowDeleteModal(false);
  };
  const callBackPrintBtnGroup = (fieldValue) => {
    setShowLoader("d-block");
    // || fieldValue == "btnPrint"
    //console.log(componentRef.current);
    if (fieldValue === "btnDownload") {
      getUserProfileConnect(userGuid)
        .then((response) => {
          if (
            (!!response &&
              (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
            (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
          ) {
            //TODO: Functionality to download
            // alert("DOWNLOAD start");
            setShowLoader("d-none");
            setDownloadResumeModal((d) => !d);
            setDownloadModalText(t("review.resume_is_being_built"));
            setDownloadModalTitle(t("review.review_download_btn"));
            setIsDownloadError(false);
          } else {
            // navigate(pathRedirect.next);
            if (!navigateToPaymentMS(docGuid, locale)) {
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    } else if (fieldValue === "btnPrint") {
      setShowLoader("d-none");
      getUserProfileConnect(userGuid)
        .then((response) => {
          if (
            !!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)
          ) {
            isRequestForPrint = true;
            setShowLoader("d-none");
            toggleDownloadLoaderModal();
            setDownloadModalText(t("review.resume_is_being_print"));
            setDownloadModalTitle(t("review.review_print_btn"));
            downloadUserResume(
              docGuid,
              "1",
              "ASPOSE",
              toggleDownloadLoaderModal,
              downloadErrorMessage,
              isRequestForPrint
            );
            setIsDownloadError(false);
            // var divToPrint = selectedHtml;
            // var htmlToPrint =
            //   "" +
            //   '<style type="text/css">' +
            //   ReviewStyle(resumeTemplate) +
            //   "</style>";
            // htmlToPrint += divToPrint.outerHTML;

            // let newWin = window.open(
            //   "/app/en/review",
            //   "Page Title Here",
            //   'rel="noopener"'
            // );
            // newWin.document.write(htmlToPrint);
            // newWin.focus();
            // newWin.print();
            // newWin.close();
          } else {
            // navigate(pathRedirect.next);
            if (!navigateToPaymentMS(docGuid, locale)) {
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };
  const setSelectedRenameSection = (name) => {
    renameSection = name;
  };
  const callBackDownloadResume = (formatName) => {
    console.log(formatName);
    setDownloadResumeModal(false);
  };
  const toggleDownloadModal = () => {
    setDownloadResumeModal((d) => !d);
  };
  const toggleDownloadLoaderModal = () => {
    setDownloadLoaderModal((l) => !l);
  };
  const downloadErrorMessage = (isError) => {
    if (isError === "error") {
      setIsDownloadError(true);
      setDownloadModalText(t("review.download_error_message"));
    } else {
      setIsDownloadError(false);
      setDownloadModalText(t("review.resume_is_being_built"));
    }
  };
  // const navigateToPaymentMS = () => {
  //   // navigate(pathRedirect.next);
  //   let isPmsEnable = readCookie("pms");
  //   if ((!!isPmsEnable && isPmsEnable === "true") || isPaymentMSEnable() === true) {
  //     createCookie("docId", docGuid, 1);
  //     window.location.assign(!!locale && locale !== "en" ? `/app/${locale}/billing` : "/app/billing");
  //   } else {
  //     navigate(pathRedirect.next);
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{t("page_title.review")}</title>
      </Helmet>
      <Header activeStep={6} />
      {isLoaded && (
        <section className="section main-content review">
          <div className="container">
            <div className="alignment-container">
              <div className="row review">
                <div className="col-md-9 col-lg-9">
                  <div className="resume-buttons">
                    {/* <ReactToPrint
                  trigger={() => <a href="#">Print this out!</a>}
                  content={() => componentRef.current}
                /> */}
                    <PrintBtnGroup
                      callback={callBackPrintBtnGroup}
                      thumbMouseOver={thumbMouseOver}
                      content={selectedHtml}
                      downloadId={"download-btn"}
                      printId={"print-btn"}
                    ></PrintBtnGroup>
                  </div>
                  {/* <img src={imageUrl}></img> */}
                  {/* <div className="resume-wrapper">
                <div className="resume">
                  <div
                    className={
                      "resume-layout " +
                      resumeTemplate +
                      " " +
                      resumeCategory +
                      " " +
                      resumePosition
                    }
                    style={{
                      fontFamily: fontFamily,
                      fontSize: fontSize + "rem",
                    }}
                  >
                   
                  </div>
                </div>
              </div> */}
                  <ReactLoader showloader={showLoader} />
                  <SelectedResumeComponent
                    name={resumeTemplate}
                    data={docSectionDetail}
                    headingfontsize={headingfontsize}
                    docReview={docReview}
                    resumeTemplate={resumeTemplate}
                    resumePosition={resumePosition}
                    resumeCategory={resumeCategory}
                    resumePersonalInfo={resumePersonalInfo}
                    dataLoaded={isDataLoaded}
                    paragraphOrdering={paragraphOrderingList}
                    resumeOnDashboard={resumeOnDashboard}
                    toggleRenameModal={toggleRenameModal}
                    toggleDeleteModal={toggleDeleteModal}
                    setSelectedRenameSection={setSelectedRenameSection}
                    fontFamily={fontFamily != "" ? fontFamily : ""}
                    fontSize={fontSize != "" ? fontSize + "em" : ""}
                    componentRef={componentRef}
                  ></SelectedResumeComponent>

                  {/* <FinalReview
                data={docSectionDetail}
                headingfontsize={headingfontsize}
                docReview={docReview}
                resumeTemplate={resumeTemplate}
                resumePosition={resumePosition}
                resumeCategory={resumeCategory}
                resumePersonalInfo={resumePersonalInfo}
                dataLoaded={isDataLoaded}
                paragraphOrdering={paragraphOrderingList}
                resumeOnDashboard={resumeOnDashboard}
                toggleRenameModal={toggleRenameModal}
                toggleDeleteModal={toggleDeleteModal}
                setSelectedRenameSection={setSelectedRenameSection}
                fontFamily={fontFamily}
                fontSize={fontSize + "rem"}
                componentRef={componentRef}
              ></FinalReview> */}
                  <div className="resume-buttons">
                    <PrintBtnGroup callback={callBackPrintBtnGroup} content={componentRef.current}></PrintBtnGroup>
                  </div>
                </div>

                <div className="col-md-3 col-lg-3 resume-sidebox-wrapper">
                  <div id="templates-selector" className="resume-sidebox sb-template box">
                    <div className="box__header box__header--small">
                      <h4 className="box-title">{t("review.resume_designs")}</h4>
                    </div>

                    <div
                      id="change-template"
                      className="resume-sidebox__inner sb-template__change"
                      data-reload-url="/resume/dada38e2-4012-40b1-b40a-863e737232d2/change_template"
                    >
                      <div className="sb-template__change-inner">
                        <ResumesThumbnail
                          callback={callBackMouseHoverTemplate}
                          selectedTemplateCallBack={callBackSelectedTemplate}
                          selectedTemplate={selectedTemplate}
                          setThumbMouseOver={setThumbMouseOver}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resume-sidebox">
                    <ReviewAddSection
                      toggleShow={toggleShow}
                      alreadyFilledPara={alreadyFilledDocPara}
                    ></ReviewAddSection>
                  </div>
                  <div className="resume-sidebox box">
                    <div className="box__header box__header--small">
                      <h4 className="box-title">{t("review.resume_tools")}</h4>
                    </div>
                    <div className="resume-sidebox__inner">
                      {isUpdated && (
                        <ResumeTools
                          callback={callBackResumeToolsValue}
                          selectedTemplate={selectedTemplate}
                          fontFamily={templatefontFamily}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <AddSectionModal
        message="hei you"
        show={show}
        toggleShow={toggleShow}
        header={t("review.custom_modal_title")}
        callback={callBackCustomSection}
      />
      <RenameSectionModal
        message="hei you"
        show={showRenameModal}
        toggleShow={toggleRenameModal}
        setRenameVal={setRenameVal}
        renameVal={renameVal}
        header={t("review.edit_section_name")}
        callback={callBackRenameSection}
      />
      <DeletePopupModal
        message={t("review.delete_popup_message")}
        show={showDeleteModal}
        toggleShow={toggleDeleteModal}
        header={t("review.delete")}
        callback={callBackDeleteSection}
      />
      <DownloadModal
        message=""
        toggleShow={toggleDownloadModal}
        toggleDownloadLoaderModal={toggleDownloadLoaderModal}
        show={downloadResumeModal}
        inputLabel={t("review.review_choose_format")}
        header={t("review.review_download_heading")}
        callback={callBackDownloadResume}
        docGUID={docGuid}
        downloadErrorMessage={downloadErrorMessage}
        isRequestForPrint={isRequestForPrint}
        downloadBtnTxt={downloadModalBtnText}
      />
      <DownloadLoaderPopup
        message=""
        toggleShow={toggleDownloadLoaderModal}
        show={downloadLoaderModal}
        inputLabel={downloadModalText}
        header={downloadModalTitle}
        docGUID={docGuid}
        isDownloadError={isDownloadError}
      />
      <Footer />
    </>
  );
};
export default Review;
