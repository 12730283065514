import React from "react";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { useNavigate, Link } from "react-router-dom";
import { SECTION_TYPE } from "../../utils/constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ReviewAddSection = ({ toggleShow, alreadyFilledPara }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let expRedirect = PathHelper(APP_NAVIGATION.TEXP, false);
  let eduRedirect = PathHelper(APP_NAVIGATION.TEDU, false);
  let eskillRedirect = PathHelper(APP_NAVIGATION.TSKLS, false);
  let langRedirect = PathHelper(APP_NAVIGATION.TLAN, false);
  let summRedirect = PathHelper(APP_NAVIGATION.TSUM, false);
  return (
    <div className="review-add-section">
      {!alreadyFilledPara.includes(SECTION_TYPE.EXPR) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(expRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          id="add_experience"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.add_experience")}
        />
      )}
      {!alreadyFilledPara.includes(SECTION_TYPE.EDUC) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(eduRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          id="add_education"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.add_education")}
        />
      )}
      {!alreadyFilledPara.includes(SECTION_TYPE.SKLS) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(eskillRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          id="add_skills"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.add_skills")}
        />
      )}
      {!alreadyFilledPara.includes(SECTION_TYPE.SUMM) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(summRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          id="add_summary"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.add_summary")}
        />
      )}
      {!alreadyFilledPara.includes(SECTION_TYPE.LANG) && (
        <Button
          isLoading={false}
          disabled={false}
          onClick={() => {
            navigate(langRedirect.next);
          }}
          classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
          id="add_languages"
          icon={<span className="fa fa-plus"></span>}
          ctaText={" " + t("review.add_languages")}
        />
      )}
      <Button
        isLoading={false}
        disabled={false}
        onClick={toggleShow}
        classNames="btn btn-outline-success w-100 mb-2 add-section-btns"
        id="add_other_section"
        icon={<span className="fa fa-plus"></span>}
        ctaText={" " + t("review.add_other_sections")}
      />
    </div>
  );
};

export default ReviewAddSection;
