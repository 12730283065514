import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_TYPE, SECTION_CODE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  Paragraphs: emptyParagraph(SECTION_CODE.EDUC, SECTION_TYPE.EDUC), //sectionType :  EDUC = 30
  error: null,
  total: 0,
};
export const updateEducationParagraph = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data, isLoading: false, error: null };
};

export const fetchEducationSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    Paragraphs: data,
    isLoading: false,
    error: null,
  };
};

export const fetchEducationFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};
export const resetEducation = () => {
  return { ...INITIAL_STATE };
};
export const renameEducationSection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};

export const HANDLERS = {
  [Types.FETCH_EDUCATION_SUCCESS]: fetchEducationSuccess,
  [Types.FETCH_EDUCATION_FAILURE]: fetchEducationFailure,
  [Types.UPDATE_EDU_SUCCESS]: updateEducationParagraph,
  [Types.RESET_EDUCATION]: resetEducation,
  [Types.RENAME_EDUCATION]: renameEducationSection,
};

export default createReducer(INITIAL_STATE, HANDLERS);
