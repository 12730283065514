import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkout from "./component";
import {
  paymentProcess,
  getSkuDetails,
  orderStatus,
  getUserProfile,
  BadBinCards,
  CreatePaymentIntent,
  paymentProcess3DS,
  abTestUserCheck,
  getCaptchaScore,
  calculateGuestUserPricing,
  getGatewayABTest,
  getAllDocumentOfUser,
  isCardInBadBins,
  getTaxEstimate,
} from "./actions";
import { eventServicePost } from "../sign-up/actions";
import { partnerLogOut } from "../sign-in/actions";
import { postDataToEventService } from "../sign-up/actions";

const mapStateToProps = ({ Checkout, funnelStep, ccpage }) => ({
  Checkout,
  funnelStep,
  ccpage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkoutConnect: paymentProcess,
      skuConnect: getSkuDetails,
      orderConnect: orderStatus,
      getUserProfileConnect: getUserProfile,
      eventServicePostConnect: eventServicePost,
      BadBinCardsConnect: BadBinCards,
      CreatePaymentIntentConnect: CreatePaymentIntent,
      paymentProcess3DSConnect: paymentProcess3DS,
      abTestCheck: abTestUserCheck,
      partnerLogOutConnect: partnerLogOut,
      getCaptchaScoreConnect: getCaptchaScore,
      postDataToEventServiceConnect: postDataToEventService,
      calculateGuestUserPricingConnect: calculateGuestUserPricing,
      getGatewayABTestConnect: getGatewayABTest,
      getAllDocumentOfUserConnect: getAllDocumentOfUser,
      isCardInBadBinsConnect: isCardInBadBins,
      getTaxEstimateConnect: getTaxEstimate,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
