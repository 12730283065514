import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentPara } from "../../../../pages/funnel-step/actions";
import TempCoverLetterBodyRecommend from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentParaConnect: setCurrentPara }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TempCoverLetterBodyRecommend);
