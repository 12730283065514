import React from "react";

const IconGoogleDrive = ({
  width = 190,
  height = 46,
  className = { className },
}) => {
  return (
    <>
      <svg
        id="Google_Drive"
        data-name="Google Drive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 189.469 46.697"
        className={className}
        width={width}
        height={height}
      >
        <path
          id="Path_107"
          data-name="Path 107"
          d="M31.63,15.19a9.138,9.138,0,0,1,9.158-9,8.619,8.619,0,0,1,6.186,2.48L45.236,10.4a6.3,6.3,0,0,0-4.448-1.755,6.463,6.463,0,0,0-6.471,6.541,6.464,6.464,0,0,0,6.471,6.541,6.048,6.048,0,0,0,4.558-1.8,5.12,5.12,0,0,0,1.342-3.1h-5.9V14.378h8.3a8.042,8.042,0,0,1,.131,1.535,8.108,8.108,0,0,1-2.135,5.752,8.268,8.268,0,0,1-6.3,2.524,9.14,9.14,0,0,1-9.158-9"
          transform="translate(28.392 5.558)"
          fill="#666"
        />
        <path
          id="Path_108"
          data-name="Path 108"
          d="M47.193,9.568a5.794,5.794,0,1,0,5.833,5.8,5.721,5.721,0,0,0-5.833-5.8m0,9.308a3.356,3.356,0,0,1-3.279-3.511,3.287,3.287,0,1,1,6.558,0,3.356,3.356,0,0,1-3.279,3.511"
          transform="translate(37.125 8.588)"
          fill="#666"
        />
        <path
          id="Path_109"
          data-name="Path 109"
          d="M53.9,9.568a5.794,5.794,0,1,0,5.833,5.8,5.721,5.721,0,0,0-5.833-5.8m0,9.308a3.356,3.356,0,0,1-3.279-3.511,3.287,3.287,0,1,1,6.558,0A3.356,3.356,0,0,1,53.9,18.876"
          transform="translate(43.146 8.588)"
          fill="#666"
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M63.5,9.921v.943h-.089a4.025,4.025,0,0,0-3.059-1.3,5.8,5.8,0,0,0,0,11.591,3.963,3.963,0,0,0,3.059-1.317H63.5v.833c0,2.216-1.19,3.4-3.1,3.4a3.215,3.215,0,0,1-2.928-2.063l-2.224.924a5.541,5.541,0,0,0,5.152,3.423c2.994,0,5.526-1.755,5.526-6.036V9.921Zm-2.928,8.955a3.331,3.331,0,0,1-3.237-3.49,3.357,3.357,0,0,1,3.237-3.533,3.269,3.269,0,0,1,3.1,3.533,3.244,3.244,0,0,1-3.1,3.49"
          transform="translate(49.17 8.588)"
          fill="#666"
        />
        <rect
          id="Rectangle_286"
          data-name="Rectangle 286"
          width="2.554"
          height="17.031"
          transform="translate(116.848 12.364)"
          fill="#666"
        />
        <path
          id="Path_111"
          data-name="Path 111"
          d="M69.34,18.873a2.953,2.953,0,0,1-2.816-1.755l7.771-3.2-.264-.658a5.4,5.4,0,0,0-4.976-3.687,5.515,5.515,0,0,0-5.482,5.793,5.68,5.68,0,0,0,5.769,5.793,5.783,5.783,0,0,0,4.843-2.567L72.2,17.271a3.331,3.331,0,0,1-2.864,1.6m-.2-7.067a2.251,2.251,0,0,1,2.158,1.23l-5.2,2.15a3.182,3.182,0,0,1,3.038-3.38"
          transform="translate(57.064 8.589)"
          fill="#666"
        />
        <path
          id="Path_112"
          data-name="Path 112"
          d="M73.968,23.057V7.343h4.82A7.852,7.852,0,0,1,84.6,9.527,7.676,7.676,0,0,1,86.779,15.2,7.678,7.678,0,0,1,84.6,20.873a7.852,7.852,0,0,1-5.814,2.184Zm2.025-1.932h2.8a5.921,5.921,0,0,0,4.327-1.558,5.814,5.814,0,0,0,1.6-4.366,5.821,5.821,0,0,0-1.6-4.357,5.9,5.9,0,0,0-4.327-1.569h-2.8ZM90.73,23.057H88.7V12.3h1.939v1.755h.085A2.764,2.764,0,0,1,92,12.61a3.625,3.625,0,0,1,1.862-.571,3.845,3.845,0,0,1,1.518.264l-.77,1.886a3.1,3.1,0,0,0-1.08-.154,2.652,2.652,0,0,0-1.947.867,3.119,3.119,0,0,0-.848,2.271ZM98.2,10.02a1.426,1.426,0,1,1,0-2.852A1.425,1.425,0,0,1,99.214,9.6a1.371,1.371,0,0,1-1.01.417m1.01,13.037H97.189V12.3h2.025Zm6.175,0L101.03,12.3h2.2l3.169,8.34h.042l3.215-8.34h2.158l-4.4,10.754Zm12.623.351a5.2,5.2,0,0,1-3.972-1.624,5.742,5.742,0,0,1-1.531-4.1A5.9,5.9,0,0,1,114,13.641a4.828,4.828,0,0,1,3.8-1.689,4.982,4.982,0,0,1,3.841,1.558,5.9,5.9,0,0,1,1.44,4.169l-.021.374h-8.52a3.642,3.642,0,0,0,1.091,2.567,3.3,3.3,0,0,0,2.345.943,2.906,2.906,0,0,0,2.949-1.843l1.8.746a4.791,4.791,0,0,1-1.738,2.1,5.063,5.063,0,0,1-2.972.844m2.907-7.023a2.857,2.857,0,0,0-.85-1.755,3.026,3.026,0,0,0-2.321-.835,2.921,2.921,0,0,0-1.949.7,3.587,3.587,0,0,0-1.133,1.888Z"
          transform="translate(66.395 6.434)"
          fill="#666"
          fill-rule="evenodd"
        />
        <path
          id="Path_113"
          data-name="Path 113"
          d="M6.256,28.995A5.418,5.418,0,0,0,8.232,30.97l8.232-14.249H0a5.416,5.416,0,0,0,.719,2.695c.565.979-2.053-3.549,5.537,9.579"
          transform="translate(0 15.009)"
          fill="#0066da"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M26.132,14.627,17.9.378a5.447,5.447,0,0,0-1.975,1.975L.717,28.7A5.413,5.413,0,0,0,0,31.391H16.464Z"
          transform="translate(0 0.339)"
          fill="#00ac47"
        />
        <path
          id="Path_115"
          data-name="Path 115"
          d="M27.1,30.97a5.432,5.432,0,0,0,1.975-1.975c7.589-13.128,4.972-8.6,5.539-9.579a5.434,5.434,0,0,0,.717-2.695H18.866l3.5,6.885Z"
          transform="translate(16.934 15.009)"
          fill="#e94235"
        />
        <path
          id="Path_116"
          data-name="Path 116"
          d="M17.665,14.966,25.9.717A5.27,5.27,0,0,0,23.2,0H12.128A5.587,5.587,0,0,0,9.433.717Z"
          transform="translate(8.467 0)"
          fill="#00832d"
        />
        <path
          id="Path_117"
          data-name="Path 117"
          d="M31.908,16.721H12.57L4.338,30.97a5.27,5.27,0,0,0,2.695.717H37.446a5.587,5.587,0,0,0,2.695-.717Z"
          transform="translate(3.894 15.009)"
          fill="#2684fc"
        />
        <path
          id="Path_118"
          data-name="Path 118"
          d="M23.979,2.354A5.418,5.418,0,0,0,22,.379L13.772,14.628,23.44,31.392H39.874a5.434,5.434,0,0,0-.717-2.695C39.136,28.663,24,2.39,23.979,2.354"
          transform="translate(12.362 0.34)"
          fill="#ffbe00"
        />
      </svg>
    </>
  );
};

export default IconGoogleDrive;
