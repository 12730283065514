import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_CODE, SECTION_TYPE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  error: null,
  total: 0,
  Paragraphs: emptyParagraph(SECTION_CODE.SKLS, SECTION_TYPE.SKLS),
};
export const updateSkillParagraph = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data };
};
export const fetchSkillsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    isLoading: false,
    error: null,
    Paragraphs: data,
  };
};

export const fetchSkillsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const resetSkills = () => {
  return { ...INITIAL_STATE };
};
export const renameSkillsSection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};
export const HANDLERS = {
  [Types.FETCH_SKILLS_SUCCESS]: fetchSkillsSuccess,
  [Types.FETCH_SKILLS_FAILURE]: fetchSkillsFailure,
  [Types.RESET_SKILLS]: resetSkills,
  [Types.UPDATE_SKILL_SUCCESS]: updateSkillParagraph,
  [Types.RENAME_SKILL]: renameSkillsSection,
};

export default createReducer(INITIAL_STATE, HANDLERS);
