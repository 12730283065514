import {
  createCookie,
  readCookie,
  deleteCookie,
  deletedomainCookie,
  goToPdfSimpli,
  gotToDashBoardWithLocale,
  goToSignSimpli,
  goToLegalSimpli,
} from "../utils/helpers";
export const ProcessLogout = (ssologout, domain, nextLogout, locale) => {
  //ssologout true/false
  //cd current domain
  //nextlg with ~ next logout
  let logoutPageUrl = "";
  let x = gotToDashBoardWithLocale(locale);

  console.log("locale", locale);

  localStorage.clear();
  deleteCookie("jwtToken");
  deleteCookie("refreshToken");
  deletedomainCookie("jwtToken");
  deleteCookie("ssoToken");
  deleteCookie("ssoauth");
  deleteCookie("legacyTicket");
  deleteCookie("ssopl");

  if (ssologout === "true") {
    if (!!nextLogout) {
      if (nextLogout.includes("ss")) {
        // logout from SignSimpli
        console.log("clear sign simpli");
        nextLogout = nextLogout.replace("ss", "");
        if (nextLogout.Length <= 0) {
          nextLogout = "~";
        }
        logoutPageUrl = goToSignSimpli(locale) + "/account/logout";
        logoutPageUrl = logoutPageUrl + "?ssologout=true&cd=" + domain + "&nextlg=" + nextLogout;
        console.log(logoutPageUrl);
        window.location.assign(logoutPageUrl);
      } else if (nextLogout.includes("ps")) {
        // logout from pdfSimpli
        console.log("clear pdf simpli");
        nextLogout = nextLogout.replace("ps", "");
        if (nextLogout.Length <= 0) {
          nextLogout = "~";
        }
        logoutPageUrl = goToPdfSimpli(locale) + "/account/logout";
        logoutPageUrl = logoutPageUrl + "?ssologout=true&cd=" + domain + "&nextlg=" + nextLogout;
        console.log(logoutPageUrl);
        window.location.assign(logoutPageUrl);
      } else if (nextLogout.includes("ls")) {
        // logout from pdfSimpli
        console.log("clear legal simpli");
        nextLogout = nextLogout.replace("ls", "");
        if (nextLogout.Length <= 0) {
          nextLogout = "~";
        }
        logoutPageUrl = goToLegalSimpli() + "/template/sign-out";
        logoutPageUrl = logoutPageUrl + "?ssologout=true&cd=" + domain + "&nextlg=" + nextLogout;
        console.log(logoutPageUrl);
        window.location.assign(logoutPageUrl);
      } else {
        if (!!domain) {
          console.log("No nextLogout Redirect to domain :", domain);
          if (domain.includes("pdfsimpli") || domain.includes("signsimpli")) {
            window.location.assign(domain + "/account/logout");
          } else if (domain.includes("legalsimpli")) {
            window.location.assign(domain + "/template/sign-out");
          } else {
            redirectToRB(locale);
          }
        } else {
          console.log("No portal found !");
          redirectToRB(locale);
        }
      }
    } else {
      if (!!domain) {
        if (domain.includes("pdfsimpli") || domain.includes("signsimpli")) {
          window.location.assign(domain + "/account/logout");
        } else if (domain.includes("legalsimpli")) {
          window.location.assign(domain + "/template/sign-out");
        } else {
          redirectToRB(locale);
        }
      } else {
        console.log("No nextLogout or portal found !");
        redirectToRB(locale);
      }
    }
  } else {
    console.log("Noraml Logout ");
    redirectToRB(locale);
  }
};

const redirectToRB = (locale) => {
  let localeVal = "";
  if (!!locale && locale != "en") {
    localeVal = locale;
  }
  if (window.location.host.indexOf("local") > -1 || window.location.host.indexOf("test") > -1) {
    setTimeout(() => {
      window.location.href = "https://test.resumebuild.com/" + localeVal;
    }, 2000);
  } else if (window.location.host.indexOf("test2") > -1) {
    setTimeout(() => {
      window.location.href = "https://test2.resumebuild.com/" + localeVal;
    }, 2000);
  } else {
    setTimeout(() => {
      window.location.href = "https://resumebuild.com/" + localeVal;
    }, 2000);
  }
};
