import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Introduction from "./component";
import { saveIntroduction } from "./actions";
import { resetStore } from "../review/actions";
import { setQueryStringInfo } from "./../funnel-step/actions";
import { resetSidebarProgress } from "../../components/cover-letter-sidebar/actions";

//const mapStateToProps = () => ({});

const mapStateToProps = ({ docguid }) => ({ docguid });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveIntroductionConnect: saveIntroduction,
      resetStoreConnect: resetStore,
      setQueryStringInfoConnect: setQueryStringInfo,
      resetSidebarProgress: resetSidebarProgress,
      //updateIntroductionConnect: updateIntroduction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Introduction);
