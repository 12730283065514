import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getOrderServiceEndPoint } from "../services/api-constants";

class OrderService {
  OrderService(url) {
    return getOrderServiceEndPoint(url);
  }

  getOrderDetailsByUserGuid(userGuid) {
    appLogger.log("inside Order Details ");
    var createOrderDetailsEndpoint = `${endPoints.orderService.GET_USER_ORDERS}?userId=${userGuid}`;
    const getOrderDetailsEndpoint = this.OrderService(
      createOrderDetailsEndpoint
    );
    return apiService.get(getOrderDetailsEndpoint);
  }
}

export default new OrderService();
