import baseApi from "./base-api";
import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getEventServiceEndPoint } from "../services/api-constants";
import { getPortalCD, readCookie } from "../utils/helpers";

class EventService {
  EventService(url) {
    return getEventServiceEndPoint(url);
  }
  postEvent(data) {
    appLogger.log("inside postEvent");
    const postEventEndpoint = this.EventService(`${endPoints.eventService.CREATE_EVENT}`);
    const jwtToken =
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI0NGUwZmY2MzgwY2E0YzhkOWFlNzBjMjExMjc5NGFiNyJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImtyaXRpa2ErMDQyNjIyQHdvcmtzaW1wbGkuaW8iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI0ZmZmZmMwYi05NDllLTRmMWItYjY1Mi1hZWQwZTkyNDk0MDQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2dyb3Vwc2lkIjoiZDZjYWNiNjEtYzk1OS00NGQ0LWI3YTctYzJjMDA5YjIzNjM1IiwibmJmIjoxNjUyMzM4OTM0LCJleHAiOjE5Njc2OTg5MzQsImlzcyI6IkxlZ2FsU2ltcGxpIiwiYXVkIjoiUERGU2ltcGxpIn0.bxV4hDcfgqoBOeva25yf80YDK9gragJmFujCrLawIdIsZF60OYKaM9g5IznZTiuKM1WtqHqW2AfqXIEL7ZaGqYUig3TO2Jxm5EzQlrwQu91lxUZ_0IGeiQFTgAjcu9sSmxV-x4XMrYCFXyP0mJbJXBEH8N8DHDv6FxXKLIdFqqAx9uG0i5nD0EDKfcfumnx462IpNskfx6ctwitKJq1b1UWIr5t5HoqCBLz93NoxgktfWQnpTvDDD8b-mTF37i7XDjmpIWSXmmkHEwSWsi9DinIGbHoYrIa2PwGrBoBEfPaC4MSRycLEJXuwkn83OhP2Wcbq5selhIk8_23UdMd1jQ";
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const eventData = {
      id: "00000000-0000-0000-0000-000000000000",
      eventId: "",
      actorId: "00000000-0000-0000-0000-000000000000",
      eventPublishedOn: "0001-01-01T00:00:00",
      eventType: getPortalCD(),
      eventSubType: "Envelope_DigitalSigned",
      data: data,
      context: {
        authToken: null,
        sourceAppCD: null,
        topicName: "emailengine",
      },
    };
    return apiService.post(postEventEndpoint, eventData, headers);
  }

  postEventData(data) {
    appLogger.log("inside postEventData");
    const postEventEndpoint = this.EventService(`${endPoints.eventService.CREATE_EVENT}`);
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const eventData = {
      id: guid(),
      eventId: guid(),
      actorId: guid(),
      eventPublishedOn: formatDate(new Date()),
      eventType: "user",
      eventSubType: "login",
      data: data,
      context: {
        authToken: null,
        sourceAppCD: null,
        topicName: "user",
      },
    };
    return apiService.post(postEventEndpoint, eventData, headers);
  }
  postDataToEventService(data, eventType, eventSubType, topicName) {
    appLogger.log("inside postDataToEventService");
    const postEventEndpoint = this.EventService(`${endPoints.eventService.CREATE_EVENT}`);
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const eventData = {
      id: guid(),
      eventId: guid(),
      actorId: guid(),
      eventPublishedOn: formatDate(new Date()),
      eventType: eventType,
      eventSubType: eventSubType,
      data: data,
      context: {
        authToken: null,
        sourceAppCD: null,
        topicName: topicName,
      },
    };
    return apiService.post(postEventEndpoint, eventData, headers);
  }
  postDataToEventServiceScheduledTime(data, eventType, eventSubType, topicName, scheduleTime) {
    appLogger.log("inside postDataToEventService");
    const postEventEndpoint = this.EventService(`${endPoints.eventService.CREATE_EVENT}`);
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const eventData = {
      id: guid(),
      eventId: guid(),
      actorId: guid(),
      eventPublishedOn: formatDate(new Date()),
      eventType: eventType,
      eventSubType: eventSubType,
      data: data,
      scheduleUTCDateTime: scheduleTime,
      context: {
        authToken: null,
        sourceAppCD: null,
        topicName: topicName,
      },
    };
    return apiService.post(postEventEndpoint, eventData, headers);
  }
}
const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const formatDate = (date) => {
  return (
    [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join("-") +
    " " +
    [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(":")
  );
};
export default new EventService();
