import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SET_CURRENTSTEP
    SET_TEMPLATE
    SET_DOCGUID
    SET_USERINFO
    SET_FUNNEL_STEP_COMPLETE
    SET_CURRENT_PARA
    RESET_FUNNEL_STEPS
    SET_NAME
    SET_QUERYSTRING_INFO
    RESET_FOLDER_QUERYSTRING
    SET_DOC_TYPE
    SET_IMAGE_URL
`,
  {
    prefix: "funnelstep/",
  }
);
