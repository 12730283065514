import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";

const createParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderService.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchSkillsSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(fetchSkillsFailure(error)));
  };
};
const updateSkillParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateSkillSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateSkillFailure(error)));
  };
};

const fetchSkillsSuccess = (data) => ({
  type: Types.FETCH_SKILLS_SUCCESS,
  data,
});

const fetchSkillsFailure = (error) => ({
  type: Types.FETCH_SKILLS_FAILURE,
  error,
});

const resetSkills = () => ({
  type: Types.RESET_SKILLS,
});
const updateSkillSuccess = (data) => ({
  type: Types.UPDATE_SKILL_SUCCESS,
  data,
});

const updateSkillFailure = (error) => ({
  type: Types.UPDATE_SKILL_FAILED,
  error,
});
export { resetSkills, createParagraph, updateSkillParagraph };
