import React, { useEffect } from "react";
import "../../assets/scss/pages.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import FinalStepTypes from "../funnel-step/types";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { Helmet } from "react-helmet";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants.js";
import IconFilePlus from "../../components/icons/icon-file-plus";
import IconFileUp from "../../components/icons/icon-file-up";
import { getPortalCD, readCookie, setLocaleURL } from "../../utils/helpers";
import PersonalInfoTypes from "../../pages/personal-info/types";
import SummaryTypes from "../../pages/summary/types";
import EducationTypes from "../../pages/education/types";
import ExperienceTypes from "../../pages/experience/types";
import SkillTypes from "../../pages/skill/types";
import LanguageTypes from "../../pages/language/types";
import OtherSectionTypes from "../../pages/other-section/types";
import CustomSectionTypes from "../../pages/custom-section/types";
import { trackRBEvents } from "../../utils/tracking-events";

const ChooseOptions = ({ saveDocGUIDConnect, funnelStep, resetImageURlConnect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { locale } = useParams();
  let pathRedirect = PathHelper(APP_NAVIGATION.THED);
  const { userGUID, email, personalInfoName } = funnelStep || {};
  const goToCreateResume = () => {
    resetResume();
    saveDocGUIDConnect("");
    navigate(pathRedirect.next);
  };
  const goToNext = () => {
    navigate(pathRedirect.next);
  };
  const resetResume = () => {
    dispatch({
      type: PersonalInfoTypes.RESET_PERSONAL_INFO,
    });
    dispatch({
      type: SummaryTypes.RESET_SUMMARY,
    });
    dispatch({
      type: EducationTypes.RESET_EDUCATION,
    });
    dispatch({
      type: ExperienceTypes.RESET_EXPERIENCE,
    });
    dispatch({
      type: SkillTypes.RESET_SKILLS,
    });
    dispatch({
      type: LanguageTypes.RESET_LANGUAGES,
    });
    dispatch({
      type: OtherSectionTypes.RESET_OTHER_SECTION,
    });
    dispatch({
      type: CustomSectionTypes.RESET_CUSTOM_SECTION,
    });
  };
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const goToUploadResume = () => {
    if (!!locale) {
      navigate(setLocaleURL(ROUTE_CONSTANT.UPLOAD_RESUME_LOCALE, locale));
    } else navigate(ROUTE_CONSTANT.UPLOAD_RESUME);
  };

  useEffect(() => {
    resetImageURlConnect();
    trackRBEvents("chooseOptionsView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    let currentStep = location.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.choose_options")}</title>
      </Helmet>
      <section className="section main-content text-center">
        <div className="container page-section">
          <div className="row">
            <div className="col-12 headline-group">
              <hgroup>
                <h1>{t("choose_options.heading")}</h1>
              </hgroup>
            </div>
          </div>

          <div className="row page-section-wrap justify-content-around">
            <div className="col-md-6 col-lg-4 mb-4" onClick={goToCreateResume}>
              <div className="position-relative border pt-5 pb-4 px-4 h-100 pointer-hover" id="create-resume-section">
                <span className="badge rounded-pill bg-success position-absolute top-0 start-0 ml-4 mt-n1 mx-3">
                  {t("choose_options.popular_txt")}
                </span>
                <div className="pt-3" id="create_new_resume">
                  <IconFilePlus width={90} height={90} className="mb-4" />
                </div>
                <h3>{t("choose_options.create_resume")}</h3>
                <p className="small text-secondary">{t("choose_options.create_resume_txt")}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mb-4" onClick={goToUploadResume}>
              <div className="border pt-5 pb-4 px-4 h-100 pointer-hover" id="upload-resume-section">
                <div className="pt-3" id="upload_resume">
                  <IconFileUp width={90} height={90} className="mb-4" />
                </div>
                <h3>{t("choose_options.upload_resume")}</h3>
                <p className="small text-secondary">{t("choose_options.upload_resume_txt")}</p>
              </div>
            </div>
          </div>
          <div className="btn-container fbn sticky-bar btn-justify">
            <Button
              isLoading={false}
              classNames="btn btn-outline-secondary btn-lg"
              id="back_to_builder"
              ctaText={t("universal.back_action")}
              onClick={backClickHandler}
            />
            <Button
              isLoading={false}
              disabled={false}
              classNames="btn btn-primary btn-lg submit-on-enter float-end"
              id="next_page_editing"
              ctaText={t("universal.next_action")}
              onClick={goToNext}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ChooseOptions;
