import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getABTestEndPoint } from "../services/api-constants";
import { readCookie } from "../utils/helpers";
import header from "../components/header";

class ABTestService {
  ABTestService(url) {
    return getABTestEndPoint(url);
  }
  UserConduct(data) {
    appLogger.log("inside abtest user conduct ");
    const userConductEndpoint = this.ABTestService(
      `${endPoints.abtestService.USER_CONDUCT}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(userConductEndpoint, data, headers);
  }
  VisitorConduct(data) {
    appLogger.log("inside abtest visitor conduct ");
    const visitorConductEndpoint = this.ABTestService(
      `${endPoints.abtestService.VISITOR_CONDUCT}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(visitorConductEndpoint, data, headers);
  }
}

export default new ABTestService();
