import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import "./signature.css";
import { Col, Container, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as htmlToImage from "html-to-image";
import Sidebar from "../../components/cover-letter-sidebar";
import {
  APP_NAVIGATION,
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
  SIGNATURE_TYPE,
  DEFAULT_USER_GUID,
  ImageType,
  ImageFormat,
} from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { createParagraph } from "../../initialState";
import { getColor, getFonts } from "../../services/master-data";
import PathHelper from "../../utils/path-helper";
import CoverLetterPreview from "../../components/cover-letter-preview";
import { checkEmpty, getPortalCD, isUserLoggedIn, navigateToSignleLoginAPP, readCookie } from "../../utils/helpers";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import SignatureColorDropdown from "../../components/signature-color-dropdown";
import { useParams } from "react-router-dom";
import { trackRBEvents } from "../../utils/tracking-events";
import {
  blobSignature,
  imageBlobSignature,
  createUpdateDocumentUpload,
  IsSingleLoginAPPEnable,
} from "../../utils/general-config";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function isCanvasBlank(canvas) {
  const context = canvas.getContext("2d");

  const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);

  return !pixelBuffer.some((color) => color !== 0);
}
const CoverLetterSignature = ({
  funnelStep,
  signatureConnect,
  updateSignatureParagraph,
  cvltrSignature,
  SaveDocGUIDConnect,
  personalInfo,
  updateProgress,
  cvltrTemplatePreview,
  previewTemplateAction,
  handleSteps,
  saveTabValue,
  docReview,
  deleteParagraphConnect,
  saveSignatureImageAndGetBlobUrlConnect,
}) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const signRef = useRef();
  let alreadyFilledDocPara = [];
  const { reviewDoc } = docReview;
  let filteredDocPara = reviewDoc?.paragraphs || [];
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
  }

  const { docGUID, funnelStepsComplete, userGUID, email, personalInfoName } = funnelStep;

  const id =
    !!cvltrSignature && !!cvltrSignature.paragraph && !!cvltrSignature.paragraph.id ? cvltrSignature.paragraph.id : "";

  const paraGraphId = !!id ? id : EMPTY_GUID;
  const fontFamily = getFonts();
  const fontColor = getColor();
  const initialKey = t("signature.type");
  const initialFont = {
    fontName: fontFamily[0].fontName,
    fontColor: fontColor[0].color,
  };
  const [count, setCount] = useState(0);
  const content =
    !!personalInfo &&
    !!personalInfo.paragraph &&
    !!personalInfo.paragraph.paraContents &&
    !!personalInfo.paragraph.paraContents[0].content
      ? JSON.parse(personalInfo.paragraph.paraContents[0].content)
      : {};
  const templateContent =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;
  const templateName = !!templateContent && !checkEmpty(templateContent.signName) ? templateContent.signName : "";
  const fullName = !!content && !checkEmpty(content.fullName) ? content.fullName : "";
  const [name, setName] = useState(
    templateName !== "" ? templateName : !!content && !checkEmpty(content.fullName) ? content.fullName : ""
  );
  const [selectedFont, setSelectedFont] = useState(initialFont);
  const setSelectedFonts = (f) => setSelectedFont(f);
  const [uploadImageView, setUploadImageView] = useState("");
  const [uploadImageFile, setUploadImageFile] = useState("");
  const [key, setKey] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [activeSign, setActiveSign] = useState(0);
  const [isImageError, setImageError] = useState("");
  const [signToText, setSignToText] = useState("");
  //const [isDrawEmpty, setIsDrawEmpty] = useState(false);
  const [isSignatureLoading, setIsSignatureLoading] = useState(t("personal_info.next_action"));

  let pathRedirect = PathHelper(APP_NAVIGATION.SIGN, funnelStepsComplete, true);
  let pathRedirectSignIn = PathHelper(APP_NAVIGATION.CVLTRSINI);
  let navigationParam = {
    state: {
      isCoverLetter: true,
      prevParaName: t("signature.signature"),
    },
  };

  const goToNext = async () => {
    createUpdateDocumentUpload();
    if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
      updateSignature();
    } else {
      saveSignature();
    }
  };

  const saveTabValues = async () => {
    let signHtml = document.getElementById("sign-html");
    let response = "";
    if (key == t("signature.type")) {
      response = await htmlToImage.toPng(signHtml);
    }

    let value = "";
    if (key == t("signature.type")) {
      value = {
        inputValue: name,
        color: selectedFont.fontColor,
        activeSign: activeSign,
        font: selectedFont.fontName,
        previewImage: response,
      };
    } else if (key == t("signature.draw")) {
      //
      let base64url = {
        signbase64: signRef.current.getTrimmedCanvas().toDataURL("image/png"),
      };
      //Create Image Request to save image to blob and get blob url
      let signbase64 = base64url.signbase64;
      let signatureUrl = "";
      const regex = /data:.*base64,/;
      signbase64 = signbase64.replace(regex, "");

      const data = {
        ImageData: signbase64,
        UploadType: ImageType.SIGNATURE,
        ImageFormat: ImageFormat.png,
      };
      const res = await saveSignatureImageAndGetBlobUrlConnect(docGUID, data);
      signatureUrl = res?.data?.blobUrl + imageBlobSignature();
      value = {
        sign: signRef.current.toData(),
        previewImage: signatureUrl,
        color: selectedFont.fontColor,
      };
      return value;

      // saveSignatureImageAndGetBlobUrlConnect(docGUID, data).then((res) => {
      //   console.log("saveSignatureImageAndGetBlobUrlConnect", res);
      //   signatureUrl = res?.data?.blobUrl + blobSignature();
      //   value = {
      //     sign: signRef.current.toData(),
      //     previewImage: signatureUrl,
      //     color: selectedFont.fontColor,
      //   };
      //   return value;
      // });
      //
    } else if (key == t("signature.upload")) {
      value = {
        image: uploadImageView,
        previewImage: uploadImageView,
      };
    }

    return value;
  };

  const saveSignature = async () => {
    setShowLoader("d-flex");

    let sign = await getSignature();
    let tabValue = await saveTabValues();

    let head = {
      signature: sign,
      labelSincerely: t("signature.sincerely"),
      signName: name,
      signType: key,
      tabValue: JSON.stringify(tabValue),
      selectedFont: selectedFont,
      fullName: fullName,
    };

    signatureConnect(docGUID, JSON.stringify(head), t("signature.signature"), tabValue, key)
      .then((response) => {
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
        if (!!docGUID) {
          // if (!!readCookie("jwtToken")) {
          //   navigate(pathRedirectSignIn.next, navigationParam);
          // } else {
          //   navigate(pathRedirect.next, navigationParam);
          // }
          if (IsSingleLoginAPPEnable()) {
            if (isUserLoggedIn()) {
              navigate(pathRedirectSignIn.next, navigationParam);
            } else {
              navigateToSignleLoginAPP(locale, docGUID, "cvlt", true);
            }
          } else {
            if (isUserLoggedIn()) {
              navigate(pathRedirectSignIn.next, navigationParam);
            } else {
              navigate(pathRedirect.next, navigationParam);
            }
          }
        }
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  const updateSignature = async () => {
    setShowLoader("d-flex");
    let sign = await getSignature();
    let tabValue = await saveTabValues();
    let head = {
      signature: sign,
      labelSincerely: t("signature.sincerely"),
      signName: name,
      signType: key,
      tabValue: JSON.stringify(tabValue),
      selectedFont: selectedFont,
      fullName: fullName,
    };

    let cvltrSignatureOrder = SECTION_ORDER.SIGN;

    let data = createParagraph(
      docGUID,
      cvltrSignature.paragraph.id,
      cvltrSignature.paragraph.paraContents[0].id,
      JSON.stringify(head),
      cvltrSignatureOrder,
      SECTION_TYPE.CLSR,
      SECTION_CODE.CLSR,
      t("signature.signature")
    );

    updateSignatureParagraph(docGUID, paraGraphId, data, tabValue, key)
      .then((response) => {
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
        if (!!docGUID) {
          if (!!readCookie("jwtToken")) {
            navigate(pathRedirectSignIn.next, navigationParam);
          } else {
            navigate(pathRedirect.next, navigationParam);
          }
        }
      })
      .catch((err) => {});
  };

  const goToBack = () => {
    navigate(pathRedirect.back);
  };

  const getSignature = async () => {
    let sign = "";
    if (key == t("signature.type")) {
      let signHtml = document.getElementById("sign-html");
      sign = await htmlToImage.toPng(signHtml);
    } else {
      if (cvltrTemplatePreview.templateField.signature) {
        sign = cvltrTemplatePreview.templateField.signature;
      }
    }
    if (!!sign) {
      return sign.split("?")[0];
    } else {
      return sign;
    }
  };

  const clearSign = () => {
    signRef.current.clear();
    let value = {
      sign: signRef.current.toData(),
      previewImage: "",
      color: selectedFont.fontColor,
      signature: "",
    };
    saveTabValue({
      key: key,
      tabValue: value,
    });
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signature: "",
    });
  };

  const handleSelected = (item) => {
    setActiveSign(item.id);
    setSelectedFonts({ ...selectedFont, fontName: item.fontName });
    handlSignature(name);
  };

  const handleImageChange = (e) => {
    setImageError("");
    const file = e.target.files[0];
    if (isImageValid(file)) {
      const reader = new FileReader();
      setUploadImageFile(file);
      reader.onload = async (event) => {
        let base64Imgurl = {
          signbase64: event.target.result,
        };
        //Create Image Request to save image to blob and get blob url
        let signImgbase64 = base64Imgurl.signbase64;
        let signatureUrl = "";
        const regex = /data:.*base64,/;
        signImgbase64 = signImgbase64.replace(regex, "");
        const data = {
          ImageData: signImgbase64,
          UploadType: ImageType.SIGNATURE,
          ImageFormat: ImageFormat.png,
        };
        const res = await saveSignatureImageAndGetBlobUrlConnect(docGUID, data);
        signatureUrl = res?.data?.blobUrl + imageBlobSignature();
        setUploadImageView(signatureUrl);
        let value = {
          image: signatureUrl,
          previewImage: signatureUrl,
        };
        saveTabValue({
          key: key,
          tabValue: value,
        });
      };

      reader.readAsDataURL(file);
    } else {
      setImageError("Please select valid image.");
    }
  };

  useEffect(() => {
    let signSavedContent = { signature: "" };
    if (funnelStep.funnelStepsComplete) {
      if (filteredDocPara.length > 0) {
        filteredDocPara.map((item) => {
          if (item.paraContents && item.paraContents.length > 0) {
            let paraName = item.paraName;
            switch (paraName) {
              case t("signature.signature"):
                signSavedContent.signature = JSON.parse(item.paraContents[0].content);
                break;
              default:
                break;
            }
          }
        });
      }
      trackRBEvents("cvSignatureView", {
        visitId: readCookie("visitGuid"),
        userGuid: userGUID,
        portalSource: getPortalCD(locale),
        eventLabel: "Page View",
        email: email,
        firstName: personalInfoName,
        lastName: "",
      });
      updateProgress(100);
      const { data } = cvltrSignature;
      let key = signSavedContent.signature.signType;
      //let key = "Draw";
      let tabValue = signSavedContent.signature.tabValue ? JSON.parse(signSavedContent.signature.tabValue) : "";
      //let tabValue = data.tabValue ? data.tabValue : "";
      setKey(key && key !== "" ? key : t("signature.type"));
      if (key == t("signature.type")) {
        if (tabValue !== "") {
          if (tabValue.inputValue !== "") {
            templateName != "" && setName(templateName);
            templateName != "" && handlSignature(templateName);
            setIsSignatureLoading(t("signature.save"));
          }
          tabValue.activeSign && setActiveSign(tabValue.activeSign);
          setSelectedFonts({
            fontColor: tabValue.color,
            fontName: tabValue.font,
          });
        }
      }
      if (key == t("signature.draw")) {
        if (tabValue.sign) {
          //setIsDrawEmpty(true);
          signRef.current.fromData(tabValue.sign);
          setSelectedFonts({ ...selectedFont, fontColor: tabValue.color });
          saveDraw();
        }
      }
      if (key == t("signature.upload")) {
        if (tabValue.image) {
          setUploadImageView(tabValue.image);
        }
      }
    } else {
      trackRBEvents("cvSignatureView", {
        visitId: readCookie("visitGuid"),
        userGuid: userGUID,
        portalSource: getPortalCD(locale),
        eventLabel: "Page View",
        email: email,
        firstName: personalInfoName,
        lastName: "",
      });
      updateProgress(100);
      const { data } = cvltrSignature;
      let key = data.key;
      let tabValue = data.tabValue ? data.tabValue : "";
      setKey(key && key !== "" ? key : t("signature.type"));
      if (key == t("signature.type")) {
        if (tabValue !== "") {
          if (tabValue.inputValue !== "") {
            templateName != "" && setName(templateName);
            templateName != "" && handlSignature(templateName);
            setIsSignatureLoading(t("signature.save"));
          }
          tabValue.activeSign && setActiveSign(tabValue.activeSign);
          setSelectedFonts({
            fontColor: tabValue.color,
            fontName: tabValue.font,
          });
        }
      }
      if (key == t("signature.draw")) {
        if (tabValue.sign) {
          signRef.current.fromData(tabValue.sign);
          setSelectedFonts({ ...selectedFont, fontColor: tabValue.color });
        }
      }
      if (key == t("signature.upload")) {
        if (tabValue.image) {
          setUploadImageView(tabValue.image);
        }
      }
    }
  }, []);

  const isImageValid = (imageFile) => {
    setImageError("");
    let fileSizeCheck = imageFile.size / 1024 / 1024;
    if (!imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      return false;
    } else if (fileSizeCheck > 1) {
      setImageError("*size should be less than 1 mb !!");
    }
    return true;
  };

  const handleDrop = (e) => {
    setImageError("");
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (isImageValid(file)) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        let base64Imgurl = {
          signbase64: event.target.result,
        };
        //Create Image Request to save image to blob and get blob url
        let signImgbase64 = base64Imgurl.signbase64;
        let signatureUrl = "";
        const regex = /data:.*base64,/;
        signImgbase64 = signImgbase64.replace(regex, "");
        const data = {
          ImageData: signImgbase64,
          UploadType: ImageType.SIGNATURE,
          ImageFormat: ImageFormat.png,
        };
        const res = await saveSignatureImageAndGetBlobUrlConnect(docGUID, data);
        signatureUrl = res?.data?.blobUrl + imageBlobSignature();
        setUploadImageView(signatureUrl);
        let value = {
          image: signatureUrl,
          previewImage: signatureUrl,
        };
        saveTabValue({
          key: key,
          tabValue: value,
        });
      };

      reader.readAsDataURL(file);
    } else {
      setImageError("Please select valid image.");
    }
  };

  const handleTab = () => {
    if (key !== "") {
      if (key == t("signature.type")) {
        setSelectedFonts(initialFont);
        setActiveSign(0);
      }
      if (key == t("signature.draw")) {
        signRef.current.clear();
      }
      if (key == t("signature.upload")) {
        setUploadImageView("");
      }
    }
    setImageError("");
  };

  const saveType = async () => {
    let value = {
      inputValue: name,
      color: selectedFont.fontColor,
      activeSign: activeSign,
      font: selectedFont.fontName,
      previewImage: "",
      signature: name !== "" ? signToText : "",
    };
    saveTabValue({
      key: key,
      tabValue: value,
    });
    // if (cvltrTemplatePreview.templateField.signatureType === undefined) {
    //   cvltrTemplatePreview.templateField.signatureType = SIGNATURE_TYPE.TYPE;
    // }
    let response;
    // if (!!cvltrTemplatePreview.templateField.signatureType) {
    if (!cvltrTemplatePreview.templateField.signatureType) {
      let signHtml = document.getElementById("sign-html");
      response = await htmlToImage.toPng(signHtml);
    } else {
      response = cvltrTemplatePreview.templateField.signatureType;
    }
    // }
    // if (response !== undefined) {
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signName: name,
      signature: name !== "" ? response : "",
    });
    // }
  };

  useEffect(() => {
    handleSteps({
      ...cvltrTemplatePreview.steps,
      signature: true,
    });
  }, []);

  const saveDraw = async () => {
    let isDrawEmpty = isCanvasBlank(signRef.current.getTrimmedCanvas());

    let base64url = {
      signbase64: signRef.current.getTrimmedCanvas().toDataURL("image/png"),
    };
    //Create Image Request to save image to blob and get blob url
    let signbase64 = base64url.signbase64;
    let signatureUrl = "";
    const regex = /data:.*base64,/;
    signbase64 = signbase64.replace(regex, "");

    const data = {
      ImageData: signbase64,
      UploadType: ImageType.SIGNATURE,
      ImageFormat: ImageFormat.png,
    };
    const res = await saveSignatureImageAndGetBlobUrlConnect(docGUID, data);
    signatureUrl = res?.data?.blobUrl + imageBlobSignature();
    let value = {
      sign: signRef.current.toData(),
      previewImage: signatureUrl,
      color: selectedFont.fontColor,
      signature: signatureUrl,
    };
    saveTabValue({
      key: key,
      tabValue: value,
    });
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signature: isDrawEmpty ? "" : signatureUrl,
    });

    //Create Image Request to save image to blob and get blob url
  };

  const saveUpload = () => {
    let value = {
      image: uploadImageView,
      previewImage: uploadImageView,
      signature: uploadImageView,
    };
    saveTabValue({
      key: key,
      tabValue: value,
    });

    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signature: uploadImageView,
    });
  };
  useEffect(() => {
    if (key == t("signature.type")) {
      saveType();
    }
  }, [key, selectedFont, activeSign, signToText]);

  useEffect(() => {
    if (key == t("signature.draw")) {
      saveDraw();
    }
  }, [key, selectedFont]);

  const handlSignature = async (value) => {
    setIsSignatureLoading("Please Wait");

    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signName: value,
    });
    setIsSignatureLoading(t("signature.save"));
  };
  const callBackHandlSignature = (value) => {
    handlSignature(value);
  };

  useEffect(() => {
    if (key == t("signature.upload")) {
      saveUpload();
    }
  }, [uploadImageView, key]);

  const skip = () => {
    deleteSignSection();
    navigate(pathRedirect.next);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      signature: "",
    });
  };

  const deleteSignSection = async (newSectionName) => {
    if (!!reviewDoc && !!reviewDoc.paragraphs && !!reviewDoc.paragraphs.length > 0) {
      let signParaSection = { signature: "" };
      reviewDoc.paragraphs.map((item) => {
        if (item.paraContents && item.paraContents.length > 0) {
          let paraName = item.paraName;
          switch (paraName) {
            case t("signature.signature"):
              signParaSection.signature = item;
              break;
            default:
              break;
          }
        }
      });
      let para = signParaSection.signature;
      //let paraName = para ? para.paraName : "";
      deleteParagraphConnect(para.docId, para.id, para.paraCD);
    }
  };
  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={3} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      {/* <Container fluid>
        <Row>
          <Col md={8} className="cvltr-signature-spacing">
            
          </Col>
          <Col md={4}>
            <>
              <CoverLetterPreview />
            </>
          </Col>
        </Row>
      </Container> */}
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="mb-5">
              <hgroup>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("signature.title"),
                  }}
                />
                <h4
                  style={{ textTransform: "none" }}
                  className="cvltr-ln-height cvltr-black-color"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("signature.subtitle")),
                  }}
                />
              </hgroup>
            </div>
            <div className="row iPad-size">
              <div className="col-md-12 col-lg-9">
                <div className="sign-pad">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(type) => {
                      setKey(type);
                      handleTab();
                    }}
                    className="mb-3"
                  >
                    <Tab eventKey={t("signature.type")} title={t("signature.type_in_keyboard")}>
                      <form className="row cvltr-signatureform">
                        <div className="col-md-12 cvltr-signaturename mb-2">
                          <input
                            type="name"
                            className={`form-control`}
                            id="inputname"
                            value={name}
                            autoComplete="off"
                            onChange={(e) => {
                              let value = e.target.value.replace(/(<([^>]+)>)/gi, "");
                              setName(value);
                              handlSignature(value);
                            }}
                            // onBlur={(e) => handlSignature(e.target.value)}
                            maxLength={40}
                            placeholder="Full Name"
                          />
                          <SignatureColorDropdown
                            key={key}
                            fontColor={fontColor}
                            setSelectedFonts={setSelectedFonts}
                            selectedFont={selectedFont}
                            name={name}
                            callBackHandlSignature={callBackHandlSignature}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="cvltr-radioheight">
                            {fontFamily.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`form-check cursor-pointer ${activeSign == item.id && "active"}`}
                                  onClick={() => handleSelected(item)}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    checked={activeSign == item.id}
                                    id={item.id + item.fontName}
                                  />
                                  <label
                                    className="form-check-label d-flex justify-content-between ps-2 pe-5 m-0 cursor-pointer"
                                    htmlFor={item.id + item.fontName}
                                  >
                                    <span
                                      id={activeSign == item.id ? "sign-html" : ""}
                                      className={`${item.fontName} font-24 px-2`}
                                      style={{
                                        color: selectedFont.fontColor,
                                      }}
                                    >
                                      {name !== "" ? name : "Full Name"}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </form>
                    </Tab>
                    <Tab eventKey={t("signature.draw")} title={t("signature.draw_with_mouse")}>
                      <div>
                        <h4>{t("signature.draw_sign_label")}</h4>
                        <SignatureColorDropdown
                          key={key}
                          fontColor={fontColor}
                          setSelectedFonts={setSelectedFonts}
                          selectedFont={selectedFont}
                          name={name}
                          callBackHandlSignature={callBackHandlSignature}
                        />
                        <SignatureCanvas
                          penColor={selectedFont.fontColor}
                          ref={signRef}
                          canvasProps={{
                            width: 335,
                            height: 120,
                            className: "sigCanvas cvltr-signaturebox p-0",
                          }}
                          onEnd={() => {
                            //setIsDrawEmpty(true);
                            saveDraw();
                          }}
                        />
                        <div>
                          <button onClick={clearSign} className="btn btn-clear btn-light" type="button">
                            <img
                              src={process.env.PUBLIC_URL + "/static/images/ic_refresh_blue.svg"}
                              className="cvltr-ic20"
                            />
                          </button>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey={t("signature.upload")} title={t("signature.upload_an_Image")}>
                      {uploadImageView === "" && (
                        <label htmlFor="file-upload" className="custom-file-upload cursor-pointer">
                          <div
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                            className="cvltr-uploadimgbox"
                          >
                            <>
                              <img
                                draggable={false}
                                src={process.env.PUBLIC_URL + "/static/images/icon-downloadblue.svg"}
                                className="cvltr-ic50 mb-5"
                              />
                              <p className="mb-4">Drag & Drop files here</p>
                              <p className="mb-4">OR</p>
                              <div className="cvltr-upldimg">
                                <p>
                                  <span className="text-decoration-underline blue-color">Browse</span> files on your
                                  computer
                                </p>{" "}
                                <input id="file-upload" type="file" onChange={handleImageChange} />
                              </div>
                            </>
                          </div>
                        </label>
                      )}

                      {uploadImageView !== "" && (
                        <span onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}>
                          <img src={uploadImageView} className="cvltr-icSign mb-5" style={{ width: 200 }} />
                          <div className="cvltr-upldimg">
                            <label htmlFor="file-upload" className="custom-file-upload">
                              <h4>
                                <span className="text-decoration-underline blue-color">Re-upload photo</span>
                              </h4>
                            </label>
                            <input id="file-upload" type="file" onChange={handleImageChange} />
                          </div>
                        </span>
                      )}

                      {isImageError !== "" && <span className="text-danger">{isImageError}</span>}
                    </Tab>
                  </Tabs>

                  <div className="col-md-11 mt-3 font-10">{t("signature.T&C")}</div>
                  <div className="col-md-11 mt-2">
                    <button onClick={skip} className="btn text-primary small px-0">
                      {t("signature.skip")}
                    </button>
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    id="back-btn"
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("opener.back")}
                    onClick={goToBack}
                  />

                  <Button
                    isLoading={false}
                    disabled={isImageError ? true : false}
                    id="next-btn"
                    classNames="btn btn-primary btn-lg submit-on-enter float-end cvltr-btn-spacing"
                    ctaText={isSignatureLoading}
                    onClick={goToNext}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterSignature;
