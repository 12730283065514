import Types from "./types";
import MetaContentService from "../../services/meta-content-api";

const fetchIndustry = (metaContentSectionCode, verifiedLocale) => {
  return function (dispatch) {
    return MetaContentService.getIndusty(metaContentSectionCode, verifiedLocale)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error in getIndustry");
        return null;
      });
  };
};
const fetchSubIndustry = (industryId) => {
  return function (dispatch) {
    return MetaContentService.getSubIndusty(industryId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error in getSubIndustry");
        return null;
      });
  };
};
const fetchMetaContent = (
  sectionCode,
  selectInsdustry,
  selectSubInsdustry,
  verifiedLocale
) => {
  return function (dispatch) {
    return MetaContentService.getWritingContent(
      sectionCode,
      selectInsdustry,
      selectSubInsdustry,
      verifiedLocale
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error in getMetaContent");
        return null;
      });
  };
};
export { fetchIndustry, fetchSubIndustry, fetchMetaContent };
