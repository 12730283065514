import React from "react";
import { useTranslation } from "react-i18next";

const CoverLetterGreetingSection = ({ cvltrTemplatePreview }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        <b>{t("opener.greeting_to_recipient_name")}:</b>
      </p>
    </>
  );
};

export default CoverLetterGreetingSection;
