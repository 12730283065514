import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";

const getDocument = (docGuid) => (dispatch) => {
  return DocBuilderService.getDoc(docGuid)
    .then((response) => {
      if (response) {
        //appLogger.log("inside getUserResume success",response.data);
        dispatch(getUserResumeSuccess(response));
        //dispatch({type:"Types.RESUME_GET",payload:response.data});
      } else {
        dispatch(getUserResumeFailure(response));
      }
      return response;
    })
    .catch((error) => {
      appLogger.log("inside Resume Review error", error);
      dispatch(getUserResumeFailure(error));
    });
};

const getUserResumeSuccess = (resume) => ({
  type: Types.RESUME_GET_SUCCESS,
  resume,
});

const getUserResumeFailure = (error) => ({
  type: Types.RESUME_GET_FAILURE,
  error,
});

export { getDocument };
