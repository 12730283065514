import React from "react";
import { Trans, useTranslation } from "react-i18next";
// Logic, to display where in resume the user is, will go here.

const ResumeMap = ({ funnelStep }) => {
  const { t } = useTranslation();
  let personalInfoName = funnelStep.personalInfoName.trim();
  return (
    <>
      <svg viewBox="0 0 200 250" width="200" height="250">
        <polygon
          className="resume-navigator__frame"
          points="0,0 180,0 200,20 200,250 0,250"
        ></polygon>
        <rect
          x="10"
          y="10"
          width="180"
          height="30"
          className="resume-navigator__section resume-navigator__section--info"
        ></rect>
        <text
          x="15"
          y="29"
          className={
            "resume-navigator__label resume-navigator__label--info " +
            (personalInfoName != "" ? "d-block" : "")
          }
        >
          {personalInfoName != "" ? personalInfoName : t("personal_info.title")}
        </text>
        <rect
          x="10"
          y="50"
          width="180"
          height="30"
          className="resume-navigator__section resume-navigator__section--summary"
        ></rect>
        <text
          x="15"
          y="69"
          className="resume-navigator__label resume-navigator__label--summary"
        >
          {t("summary.title")}
        </text>
        <rect
          x="10"
          y="90"
          width="180"
          height="50"
          className="resume-navigator__section resume-navigator__section--xp"
        >
          {" "}
        </rect>
        <text
          x="15"
          y="119"
          className="resume-navigator__label resume-navigator__label--xp"
        >
          {t("experience.title")}
        </text>
        <rect
          x="10"
          y="150"
          width="180"
          height="50"
          className="resume-navigator__section resume-navigator__section--education"
        ></rect>
        <text
          x="15"
          y="179"
          className="resume-navigator__label resume-navigator__label--education"
        >
          {t("education.title")}
        </text>
        <rect
          x="10"
          y="210"
          width="180"
          height="30"
          className="resume-navigator__section resume-navigator__section--skills"
        ></rect>
        <text
          x="15"
          y="229"
          className="resume-navigator__label resume-navigator__label--skills"
        >
          {t("skill.title")}
        </text>
        <polyline
          className="resume-navigator__corner"
          points="180,0 180,20 200,20"
        ></polyline>
      </svg>
    </>
  );
};

export default ResumeMap;
