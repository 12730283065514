import Types from "./types";
import DocBuilderApi from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import DocBuilderService from "../../services/doc-builder-api";

const createCustomSectionParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderApi.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchCustomSuccess(response));
        } else {
        }
        return response;
      })

      .catch((error) => dispatch(fetchCustomFailure(error)));
  };
};
const updateCustomSectionParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateCustomSectionSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateCustomSectionFailure(error)));
  };
};
const fetchCustomSuccess = (data) => ({
  type: Types.FETCH_CUSTOM_SUCCESS,
  data,
});

const fetchCustomFailure = (error) => ({
  type: Types.FETCH_CUSTOM_FAILURE,
  error,
});
const updateCustomSectionSuccess = (data) => ({
  type: Types.UPDATE_CUSTOM_SUCCESS,
  data,
});

const updateCustomSectionFailure = (error) => ({
  type: Types.UPDATE_CUSTOM_FAILED,
  error,
});
const resetCustomSection = () => ({
  type: Types.RESET_CUSTOM_SECTION,
});
export {
  createCustomSectionParagraph,
  updateCustomSectionParagraph,
  resetCustomSection,
};
