import React from "react";

const TopNav = () => {
  return (
    <ul className="nav navbar-nav me-auto">
      {/* <li><a className="nav-link active" aria-current="page" href="#">Resume templates</a></li>
      <li><a className="nav-link" href="#">Resume examples</a></li>
      <li><a className="nav-link" href="#">Resume builder</a></li> */}
    </ul>
  );
};

export default TopNav;
