import React from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { createParagraph, emptyDocument } from "../../initialState";
import {
  APP_NAVIGATION,
  DOC_STAGE,
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useNavigate, useParams } from "react-router-dom";
import "./component.css";
import PathHelper from "../../utils/path-helper";
import CoverLetterContentBox from "../../components/cover-letter-content-box";
import MetaContentService from "../../services/meta-content-api";
import { availableLanguages } from "../../i18nextInit";
import { useEffect } from "react";
import moment from "moment";
import { getTenantId } from "../../utils/general-config";
import { checkEmpty, documentName, findSquareBrackets, getPortalCD, readCookie } from "../../utils/helpers";
import Sidebar from "../../components/cover-letter-sidebar";
import CoverLetterPreview from "../../components/cover-letter-preview";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CoverLetterBodyRecommend = ({
  funnelStep,
  recommendConnect,
  updateRecommendParagraph,
  cvltrCloserRecommend,
  SaveDocGUIDConnect,
  jobtitle,
  desiredCompany,
  cvltrExperience,
  personalInfo,
  updateProgress,
  cvltrTemplatePreview,
  previewTemplateAction,
  handleSteps,
  saveSelected,
  cvltrSkills,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { locale } = useParams();
  let verifiedLocale = "en";
  if (!!locale) {
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
  }
  const { templateCd, userGUID, docGUID, funnelStepsComplete, email, personalInfoName } = funnelStep;
  let pathRedirect = PathHelper(APP_NAVIGATION.CLSRRECOM, funnelStepsComplete, true);
  let visitorGuid = readCookie("visitorGuid");
  // saved State :
  const { selectedData } = cvltrCloserRecommend;

  const prevParaName = {
    state: {
      prevParaName: t("opener.closer_recommend"),
    },
  };

  const skils = !!cvltrSkills && cvltrSkills.selectedSkillsState && cvltrSkills.selectedSkillsState.data;

  const id =
    !!cvltrCloserRecommend && !!cvltrCloserRecommend.paragraph && !!cvltrCloserRecommend.paragraph.id
      ? cvltrCloserRecommend.paragraph.id
      : "";

  const paraGraphId = !!id ? id : EMPTY_GUID;

  const jobContent =
    !!jobtitle &&
    !!jobtitle.paragraph &&
    !!jobtitle.paragraph.paraContents &&
    !!jobtitle.paragraph.paraContents[0].content
      ? JSON.parse(jobtitle.paragraph.paraContents[0].content)
      : {};

  const companyContent =
    !!desiredCompany &&
    !!desiredCompany.paragraph &&
    !!desiredCompany.paragraph.paraContents &&
    !!desiredCompany.paragraph.paraContents[0].content
      ? JSON.parse(desiredCompany.paragraph.paraContents[0].content)
      : {};

  const personalInfoContent =
    !!personalInfo &&
    !!personalInfo.paragraph &&
    !!personalInfo.paragraph.paraContents &&
    !!personalInfo.paragraph.paraContents[0].content
      ? JSON.parse(personalInfo.paragraph.paraContents[0].content)
      : {};

  const experienceContent =
    !!cvltrExperience &&
    !!cvltrExperience.paragraph &&
    !!cvltrExperience.paragraph.paraContents &&
    !!cvltrExperience.paragraph.paraContents[0].content
      ? JSON.parse(cvltrExperience.paragraph.paraContents[0].content)
      : {};

  const desiredPosition = !!jobContent && !checkEmpty(jobContent.desiredPosition) ? jobContent.desiredPosition : "";
  const Experience =
    !!experienceContent && !checkEmpty(experienceContent.Experience) ? experienceContent.Experience : 0;
  const desiredCompanyName =
    !!companyContent && !checkEmpty(companyContent.DesiredCompany) ? companyContent.DesiredCompany : "";
  const emailId =
    !!personalInfoContent && !checkEmpty(personalInfoContent.emailAddress) ? personalInfoContent.emailAddress : "";
  const phoneNumber =
    !!personalInfoContent && !checkEmpty(personalInfoContent.phone) ? personalInfoContent.phone.trim() : "";

  const [showLoader, setShowLoader] = useState("d-none");
  const [recommendList, setRecommendList] = useState([]);
  const [isSelected, setIsSelected] = useState({
    index: "",
    value: "",
    id: "",
  });

  const getRecommendList = async () => {
    setShowLoader("d-flex");
    const response = await MetaContentService.getIndusty("cvlt", verifiedLocale);
    let industryId = response.length > 0 && response.find((item) => item.name == t("cover-letter.title")).id; // find CVLTR industryId
    let updatedContent = [];

    if (industryId !== "" && industryId !== undefined) {
      const writingContentResponse = await MetaContentService.getWritingContent(
        SECTION_TYPE.CLSR,
        industryId,
        "",
        verifiedLocale,
        Experience
      );

      let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
      let experience_10 = Experience !== "" ? Experience : t("recommend.exp_10");
      let emailAddress = emailId !== "" ? emailId : t("recommend.email");
      let phone = phoneNumber !== "" ? phoneNumber : t("recommend.phone_number");
      let companyName = desiredCompanyName !== "" ? desiredCompanyName : t("recommend.company");
      let skill_1 = skils && skils.length !== 0 ? skils[0] : t("recommend.skill_1");
      let skill_2 = skils && skils.length !== 0 ? skils[1] : t("recommend.skill_2");
      let skill_3 = skils && skils.length !== 0 ? skils[2] : t("recommend.skill_3");

      writingContentResponse.map((item, index) => {
        let str = item.content;

        // let findSqareBrakets = str.match(/(?:\[)[^\][]*(?=])/g);
        let findSqareBrakets = findSquareBrackets(str);

        let mapObj = {};

        if (findSqareBrakets !== null) {
          findSqareBrakets
            .filter((x) => x != "")
            .map((item) => {
              if (item.toLowerCase() == "job title") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerJobTitle'>${jobtitle}</span>`;
              } else if (item == "10") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerExperience_10'>${experience_10}</span>`;
              } else if (item == "15") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerExperience_15'>${experience_10}</span>`;
              } else if (item.toLowerCase() == "company") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerCompanyName'>${companyName}</span>`;
              } else if (item.toLowerCase() == "email") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerEmailAddress'>${emailAddress}</span>`;
              } else if (item.toLowerCase() == "phone number") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='closerPhone'>${phone}</span>`;
              } else if (item.toLowerCase() == "skill1") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill1'>${skill_1}</span>`;
              } else if (item.toLowerCase() == "skill2") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill2'>${skill_2}</span>`;
              } else if (item.toLowerCase() == "skill3") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill3'>${skill_3}</span>`;
              } else {
                mapObj[`[${item}]`] = `<span  style="color: #FF6600;" class='cvltr-red-color'>[${item}]</span>`;
              }
            });
        }

        Object.keys(mapObj).forEach((key) => {
          str = str.replaceAll(key, mapObj[key]);
        });

        updatedContent.push({
          ...item,
          content: str,
        });
      });

      if (!!writingContentResponse) {
        if (
          writingContentResponse &&
          writingContentResponse.length > 0 &&
          !IsRecommenderExist(writingContentResponse)
        ) {
          setIsSelected({
            ...isSelected,
            id: updatedContent[0].id,
            value: updatedContent[0].content,
            index: 0,
          });
        } else if (selectedData && selectedData.index !== "" && selectedData.value !== "") {
          if (updatedContent && updatedContent[selectedData.index]) {
            updatedContent[selectedData.index].content = selectedData.value;
          }
          setIsSelected({
            ...isSelected,
            id: selectedData.id,
            value: selectedData.value,
            index: selectedData.index,
          });
        } else {
          setIsSelected({
            ...isSelected,
            id: updatedContent[0].id,
            value: updatedContent[0].content,
            index: 0,
          });
          previewTemplateAction({
            ...cvltrTemplatePreview.templateField,
            closerRecommend: updatedContent[0].content,
          });
        }
        setRecommendList(updatedContent);
      }
      setShowLoader("d-none");
    }
  };
  const IsRecommenderExist = (writingContentResponse) => {
    const item = writingContentResponse?.find((item) => item?.id == selectedData?.id);
    return item;
  };
  useEffect(() => {
    trackRBEvents("cvCloserRecommendView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    getRecommendList();
  }, []);

  useEffect(() => {
    if (selectedData && selectedData.index !== "" && selectedData.value !== "") {
      let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
      let experience_10 = Experience !== "" ? Experience : t("recommend.exp_10");
      let experience_15 = Experience !== "" ? Experience : t("recommend.exp_15");
      let companyName = desiredCompanyName !== "" ? desiredCompanyName : t("recommend.company");
      let phone = phoneNumber !== "" ? phoneNumber : t("recommend.phone_number");
      let skill_1 = skils && skils.length !== 0 ? skils[0] : t("recommend.skill_1");
      let skill_2 = skils && skils.length !== 0 ? skils[1] : t("recommend.skill_2");
      let skill_3 = skils && skils.length !== 0 ? skils[2] : t("recommend.skill_3");
      let emailAddress = emailId !== "" ? emailId : t("recommend.email");

      const jobId = document.getElementById("closerJobTitle");
      const experienceId15 = document.getElementById("closerExperience_15");
      const experienceId10 = document.getElementById("closerExperience_10");
      const companyNameId = document.getElementById("closerCompanyName");
      const phoneId = document.getElementById("closerPhone");
      const emailAddressId = document.getElementById("closerEmailAddress");
      const skill1 = document.getElementById("skill1");
      const skill2 = document.getElementById("skill2");
      const skill3 = document.getElementById("skill3");

      if (jobId) {
        jobId.innerText = jobtitle;
      }
      if (experienceId15) {
        experienceId15.innerText = experience_15;
      }
      if (experienceId10) {
        experienceId10.innerText = experience_10;
      }
      if (companyNameId) {
        companyNameId.innerText = companyName;
      }
      if (phoneId) {
        phoneId.innerText = phone;
      }
      if (emailAddressId) {
        emailAddressId.innerText = emailAddress;
      }
      if (skill1) {
        skill1.innerText = skill_1;
      }
      if (skill2) {
        skill2.innerText = skill_2;
      }
      if (skill3) {
        skill3.innerText = skill_3;
      }
    }
  }, [selectedData]);

  useEffect(() => {
    if (isSelected.index !== "" && isSelected.value !== "") {
      saveSelected(isSelected);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        closerRecommend: isSelected.value,
      });
    }
  }, [isSelected]);

  useEffect(() => {
    updateProgress(80);
    handleSteps({
      ...cvltrTemplatePreview.steps,
      closer: true,
    });
  }, []);

  const buttonClickHandler = () => {
    if (
      cvltrCloserRecommend.paragraph == "" ||
      (selectedData && selectedData.index == "" && selectedData.value == "")
    ) {
      saveRecommend();
    } else {
      updateRecommend();
    }
  };

  const saveRecommend = () => {
    setShowLoader("d-flex");
    const parentId = document.querySelector("#parentId");
    const spanTag = parentId?.querySelector("#closerJobTitle");
    let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
    if (spanTag) {
      spanTag.textContent = jobtitle;
    }

    let head = {
      closerRecommend: parentId?.innerHTML,
    };

    let data = emptyDocument(
      documentName(cvltrTemplatePreview.fullName),
      JSON.stringify({}),
      templateCd,
      userGUID,
      getTenantId(),
      visitorGuid,
      ""
    );

    //Note: For cover letter document, we are passing typeOfDoc = 20 and DocStage = DOC_STAGE.CLSR
    data.typeOfDoc = 20;
    data.DocStage = DOC_STAGE.CLSR;

    recommendConnect(docGUID, JSON.stringify(head), t("opener.closer_recommend"), isSelected, data)
      .then((response) => {
        setShowLoader("d-none");
        navigate(pathRedirect.next, prevParaName);
        if (docGUID !== "" || docGUID !== undefined) {
          SaveDocGUIDConnect(response.docGuid);
        } else {
          SaveDocGUIDConnect(docGUID);
        }
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  const updateRecommend = () => {
    setShowLoader("d-flex");
    const parentId = document.querySelector("#parentId");
    const spanTag = parentId?.querySelector("#closerJobTitle");
    let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
    if (spanTag) {
      spanTag.textContent = jobtitle;
    }

    let head = {
      closerRecommend: parentId?.innerHTML,
    };
    let recommendOrder =
      !!cvltrCloserRecommend &&
      !!cvltrCloserRecommend.paragraph &&
      !!cvltrCloserRecommend.paragraph.order &&
      cvltrCloserRecommend.paragraph.order != 0
        ? cvltrCloserRecommend.paragraph.order
        : SECTION_ORDER.CLSR;

    let data = createParagraph(
      docGUID,
      cvltrCloserRecommend.paragraph.id,
      cvltrCloserRecommend.paragraph.paraContents[0].id,
      JSON.stringify(head),
      recommendOrder,
      SECTION_TYPE.CLSR,
      SECTION_CODE.CLSR,
      t("opener.closer_recommend")
    );

    updateRecommendParagraph(docGUID, paraGraphId, data, isSelected)
      .then((response) => {
        navigate(pathRedirect.next, prevParaName);
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={3} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      <ScrollToTop />
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <hgroup>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("opener.closer_recommend_title"),
                  }}
                />
                <h4
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("opener.recommend_subtitle")),
                  }}
                />
              </hgroup>
            </div>
            <div className="row iPad-size">
              <div className="col-md-12 col-lg-9">
                <div>
                  <div className="row pt-5">
                    <div className="cvltr-list-wrapper">
                      <CoverLetterContentBox
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        recommendList={recommendList}
                        setRecommendList={setRecommendList}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    id="back-btn"
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("opener.back")}
                    onClick={backClickHandler}
                  />

                  <Button
                    isLoading={false}
                    disabled={false}
                    id="next-btn"
                    classNames="btn btn-primary btn-lg submit-on-enter float-end"
                    ctaText={t("opener.next")}
                    onClick={buttonClickHandler}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterBodyRecommend;
