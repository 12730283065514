import React, { useState } from "react";

const RBDropDown = ({
  options,
  index,
  name,
  selectedValue,
  disabled,
  id = "",
  classNames = "",
  yearText = "",
  showLabel,
  text,
  onChange = () => {},
}) => {
  const [isEmpty, setisEmpty] = useState(true);
  const onChangeHandler = (selectedOption) => {
    const inputValue = selectedOption.target.value;
    onChange(index, selectedOption);
    // console.log("selectedOption", inputValue);
    if (inputValue.trim().length > 0) {
      setisEmpty(false);
    }
    if (inputValue.trim().length === 0 || inputValue === "empty") {
      setisEmpty(true);
    }
  };
  return (
    <>
      {showLabel && <label className="form-label">{text}</label>}
      <select
        name={name}
        id={id}
        className={`${classNames} ${!isEmpty ? "success" : ""}`}
        value={selectedValue}
        onChange={onChangeHandler}
        {...(disabled && { disabled: "disabled" })}
      >
        {yearText && <option value="">{yearText}</option>}

        {options.map((options, key) => {
          return (
            <>
              <option value={options.value} key={"key" + index + key}>
                {options.label}
              </option>
            </>
          );
        })}
      </select>
    </>
  );
};

export default RBDropDown;
