import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDocGUID } from "../funnel-step/actions";
import {
  createSkillsParagraph,
  fetchSkills,
  getNewSkills,
  selectedSkills,
  updateSkillsParagraph,
} from "./actions";
import CoverLetterSkills from "./component";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import { previewTemplateAction } from "../cover-letter-template/actions";

const mapStateToProps = ({
  funnelStep,
  cvltrSkills,
  cvltrTemplatePreview,
}) => ({
  funnelStep,
  cvltrSkills,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      skillsConnect: createSkillsParagraph,
      updateSkillsParagraph: updateSkillsParagraph,
      SaveDocGUIDConnect: setDocGUID,
      fetchSkills: fetchSkills,
      updateProgress: updateProgress,
      previewTemplateAction: previewTemplateAction,
      getSelectedSkills: selectedSkills,
      getNewSkills: getNewSkills,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetterSkills);
