import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const CvExpressiveCSS = css`
  .resume-layout.cv-expressive {
    padding: 0;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-expressive .resume-photo {
    display: block;
    background-color: #fff;
    position: absolute;
    right: 0;
    border-radius: 100%;
    overflow: hidden;
  }
  .resume-layout.cv-expressive .resume-photo-content {
    padding-right: 165px;
    min-height: 140px;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-expressive .resume-photo-content {
      padding-right: 90px;
      min-height: 80px;
    }
    .resume-layout.cv-expressive .resume-layout-box .resume__section {
      margin-bottom: 1.25rem;
    }
  }
  .resume-layout.cv-expressive .resume-main .resume__section {
    padding-left: 0;
  }
  .resume-layout.cv-expressive .resume-header {
    background-color: #ffa02f;
    padding: 3em 3em 0;
    border-bottom: 5px #d1d9e1 solid;
  }
  .resume-layout.cv-expressive .resume-body {
    padding: 2em 3em 1em;
  }
  .resume-layout.cv-expressive .resume-header .info-main {
    display: grid;
    font-weight: 300;
    position: relative;
  }
  @media (min-width: 768px) {
    .resume-layout.cv-expressive
      .resume-header
      .info-main
      .info-item:first-child {
      order: 3;
      // position: absolute;
      // left: 50%;
      // top: 38px;
      // bottom: 0;
      width: 100%;
    }
  }
  .resume-layout.cv-expressive
    .resume-header
    .info-main
    .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv-expressive .resume-header .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv-expressive .resume__section.resume__heading h1 {
    font-weight: bold;
    font-size: 2.86em;
    color: #fff;
    padding-top: 0;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-expressive .resume__section.resume__heading h1 {
      padding-top: 0;
      margin-bottom: 0.5rem;
    }
  }
  .resume-layout.cv-expressive .resume-aside {
    order: 2;
    width: 25%;
    margin-right: 15px;
    padding-top: 1rem;
  }
  .resume-layout.cv-expressive .resume-main {
    order: 1;
    width: 75%;
    padding-top: 1rem;
    padding-right: 5rem;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-expressive .resume-main {
      padding-right: 2rem;
    }
  }
  .resume-layout.cv-expressive .resume-aside .resume__heading {
    display: none;
  }
  .resume-layout.cv-expressive .sub-headline {
    margin-bottom: 1rem;
  }
  .resume-layout.cv-expressive .resume__section .sub-headline h2 {
    display: inline-block;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-expressive .resume__section h1,
  .resume-layout.cv-expressive .resume__section h3 {
    font-size: 1.15em;
    margin-bottom: 0.25em;
    font-weight: 600;
    letter-spacing: 0;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-expressive .sub-headline .svg-icon {
    display: inline-block;
    margin-right: 10px;
    display: none;
  }
  .resume-layout.cv-expressive .sub-headline .svg-icon {
    background-color: #fff;
    border: 4px solid #fbc498;
    border-radius: 100%;
    color: #ffefe3;
    height: 3em;
    width: 3em;
    line-height: normal;
    padding: 0.65rem;
    text-align: center;
    margin-left: 0;
  }
  .resume-layout.cv-expressive .sub-headline .svg-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .resume-layout.cv-expressive .info-item {
    display: block;
  }
  .resume-layout.cv-expressive .info-item .svg-icon {
    color: #fff;
    margin-top: 4px;
  }
  .resume-layout.cv-expressive .info-item .svg-icon,
  .resume-layout.cv-expressive .info-item .info-text {
    // display: inline-block;
    display: flex;
    color: #fff;
  }
  .resume-layout.cv-expressive .info-item .info-label {
    display: none;
  }
  .resume-layout.cv-expressive .wrap .left-col,
  .resume-layout.cv-expressive .wrap .extra-info,
  .resume-layout.cv-expressive .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv-expressive .extra {
    display: block;
  }
  .resume-layout.cv-expressive .extra .extra-details .progress-starts,
  .resume-layout.cv-expressive .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv-expressive .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv-expressive
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #ffa02f;
  }
  .resume-layout.cv-expressive .xp-item {
    display: block;
  }
  .resume-layout.cv-expressive .xp-item .xp-date,
  .resume-layout.cv-expressive .xp-item .xp-job {
    //font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv-expressive .xp-item .xp-date {
    color: #ffa02f;
  }
  .resume-layout.cv-expressive .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv-expressive .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv-expressive .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv-expressive .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv-expressive .resume__education .edu-hide {
    display: block;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-expressive .sub-headline .svg-icon svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: -4px;
      margin-top: -6px;
    }
    .resume-layout.cv-expressive .resume-main {
      width: 70%;
    }
    .resume-layout.cv-expressive .resume-aside {
      width: 30%;
    }
  }
`;

export const CvExpressiveStyle = createGlobalStyle`
  ${CommonCSS}
  ${CvExpressiveCSS}
`;
