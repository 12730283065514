import Types from "./types";
import appLogger from "../../utils/app-logger";
import { apiService } from "../../services/base-api-service";
import { endPoints, getUserServiceEndPoint } from "../../services/api-constants";
import AuthServiceApi from "../../services/auth-service-api";
import { toast } from "react-toastify";
import { EMPTY_GUID } from "../../utils/constants";
import FunnelStepTypes from "../funnel-step/types";
import UserServiceApi from "../../services/user-service-api";
import DocBuilderService from "../../services/doc-builder-api";
import { readCookie, getPortalCD, createCookie } from "../../utils/helpers";
import SingleSignOnApi from "../../services/single-sign-on-api";
import moment from "moment";
import { getTenantGroupId } from "../../utils/general-config";

const signInUser = (data) => {
  return function (dispatch) {
    return AuthServiceApi.LoginUser(data)
      .then((response) => {
        if (response) {
          const { jwtToken, refreshToken, expiresAt, ssoToken, legacyToken } = response;
          localStorage.setItem("jwtToken", jwtToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("expiresAt", expiresAt);
          let days = moment(expiresAt).diff(moment(), "days");
          if (days < 1) {
            days = 1;
          }
          createCookie("jwtToken", jwtToken, days);
          createCookie("refreshToken", refreshToken, days);
          createCookie("expiresAt", expiresAt, days);
          createCookie("ssoToken", ssoToken, days);
          createCookie("legacyTicket", legacyToken, days);
          createCookie("ssoauth", "1");
          appLogger.log("inside signUpUser1 ");
          // call user service to get userguid
          return UserServiceApi.getUserByUserName(encodeURIComponent(data.username), getTenantGroupId()).then(
            (userResponse) => {
              if (userResponse) {
                const { userGuid, isMigrated, isAccountLocked } = userResponse;
                var validUserGuid = userGuid;
                if (userGuid.includes("-") == false) {
                  validUserGuid = userGuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
                }
                createCookie("userguid", validUserGuid, 1);
                dispatch(PushUserInformationToFunnelStep(validUserGuid, data.username));
                SingleSignOnApi.partnerLogin(encodeURIComponent(legacyToken), "SS").then((ssoResponse) => {});
                SingleSignOnApi.partnerLogin(encodeURIComponent(legacyToken), "PS").then((ssoResponse) => {});

                return {
                  validUserGuid: validUserGuid,
                  isMigratedUser: isMigrated,
                  isAccountLocked: isAccountLocked,
                };
              }
            }
          );
        }
      })
      .catch((error) => {
        if (error.isAxiosError) {
          let errorMsg = `${error.toString()} `;
          toast.error(errorMsg);
          if (!!error.response && error?.response?.data?.isAccountLocked) {
            return {
              validUserGuid: EMPTY_GUID,
              isMigratedUser: false,
              isAccountLocked: true,
            };
          } else {
            return UserServiceApi.getUserByUserName(encodeURIComponent(data.username), getTenantGroupId())
              .then((userResponse) => {
                if (userResponse && !!userResponse.isMigrated) {
                  const { userGuid, isMigrated, isAccountLocked } = userResponse;
                  var validUserGuid = userGuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
                  return {
                    validUserGuid: validUserGuid,
                    isMigratedUser: isMigrated,
                    isAccountLocked: isAccountLocked,
                  };
                } else {
                  return {
                    validUserGuid: EMPTY_GUID,
                    isMigratedUser: null,
                    isAccountLocked: null,
                  };
                }
              })
              .catch((error) => {
                return {
                  validUserGuid: EMPTY_GUID,
                  isMigratedUser: null,
                  isAccountLocked: null,
                };
              });
          }
        }
      });
  };
};

const getUserDocs = (userguid) => {
  return function (dispatch) {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
};

const getUserDocsByTypeOfDoc = (userguid, typeofdoc) => {
  return function (dispatch) {
    return DocBuilderService.findByUserGuidAndTypeOfDoc(userguid, typeofdoc)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
};

const partnerLogOut = () => {
  return function (dispatch) {
    SingleSignOnApi.partnerLogOut("PS")
      .then((ssoResponse) => {})
      .catch((error) => {});
    SingleSignOnApi.partnerLogOut("SS")
      .then((ssoResponse) => {})
      .catch((error) => {});
  };
};

const PushUserInformationToFunnelStep = (userGUID, email) => ({
  type: FunnelStepTypes.SET_USERINFO,
  userGUID,
  email,
});
export { signInUser, getUserDocs, partnerLogOut, getUserDocsByTypeOfDoc };
