import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import appLogger from "../../utils/app-logger";
import Button from "../../components/shared/button";
import PathHelper from "../../utils/path-helper";
import ResumeMap from "../../components/resume-map";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import moment from "moment";
import { APP_NAVIGATION } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import TextEditor from "../../components/text-editor";

const CustomSection = ({
  customsection,
  customSectionConnect,
  funnelStep,
  docReview,
  updateCustomSectionConnect,
}) => {
  const { t } = useTranslation();
  const { sectionname, id } = useParams();
  let pathRedirect = PathHelper(APP_NAVIGATION.CSTM);
  const navigate = useNavigate();
  const { paraContents, paraName } =
    customsection.Paragraphs.find((x) => x.paraName === sectionname) ??
    customsection.Paragraphs[0];
  const { docGUID } = funnelStep || {};
  const [customSectionParaGuid, setCustomSectionParaGuid] =
    useState(EMPTY_GUID);
  const [editorContent, setEditorContent] = useState("");
  const [allValues, setAllValues] = useState();
  const [sectionOrder, setsectionOrder] = useState();
  useEffect(() => {
    if (
      !!docReview &&
      !!docReview.reviewDoc &&
      !!docReview.reviewDoc.paragraphs
    ) {
      let section = docReview.reviewDoc.paragraphs.find((element) => {
        return element.paraName.toLowerCase() === sectionname.toLowerCase();
      });
      if (!!section) {
        setsectionOrder(section.order);
      }
    }

    if (!!paraContents) {
      setEditorContent(
        !!paraContents && paraContents.length > 0 ? paraContents[0].content : ""
      );
      setCustomSectionParaGuid(
        !!paraContents && paraContents.length > 0 && paraName === sectionname
          ? paraContents[0].paraId
          : EMPTY_GUID
      );
    }
  }, []);
  const callBackEditorVal = (fieldValue, fieldId) => {
    setAllValues({ ...allValues, [fieldId]: fieldValue });
  };
  let back_action = PathHelper(APP_NAVIGATION.OTHR, false);

  const backClickHandler = () => {
    navigate(back_action.back);
  };
  const saveCustomSectionClickHandler = () => {
    appLogger.log("saveCustomSectionClickHandler");
    if (!!allValues) {
      var customSectionParaContent = [];
      customSectionParaContent.push({
        id: EMPTY_GUID,
        paraId: customSectionParaGuid,
        content: JSON.stringify(allValues),
        order: 0,
        createdOn: moment(new Date()),
        modifiedOn: moment(new Date()),
      });
      var para = {
        id: customSectionParaGuid,
        docId: docGUID,
        paraContents: [...customSectionParaContent],
        order:
          sectionOrder > 0
            ? sectionOrder
            : !!customsection &&
              !!customsection.Paragraphs &&
              !!customsection.Paragraphs.order &&
              customsection.Paragraphs.order != 0
            ? customsection.Paragraphs.order
            : SECTION_ORDER.CSTM,
        paraCD: SECTION_TYPE.CSTM,
        sectionCD: SECTION_CODE.CSTM,
        paraName: sectionname,
      };
      setAllValues([]);
      if (!!customSectionParaGuid && customSectionParaGuid !== EMPTY_GUID) {
        updateCustomSectionConnect(docGUID, customSectionParaGuid, para)
          .then((response) => {
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        customSectionConnect(para)
          .then((response) => {
            appLogger.log("inside customSectionConnect then ");
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
    } else {
      navigate(pathRedirect.next);
    }
  };

  return (
    <>
      <Header />
      <section className="section main-content templates custom-section">
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="builder-header exp-header">
                <h2>{sectionname.toUpperCase()}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-9 custom--editor-section">
                <label>{t("summary.content")}</label>
                <div className="col-sm-12 col-lg-12 text-editor-container">
                  <div className="writer other-editor">
                    <TextEditor
                      sectionCode={SECTION_TYPE.CSTM}
                      callback={callBackEditorVal}
                      editorContent={editorContent}
                    />
                  </div>
                </div>
                {/* <Button
                isLoading={false}
                disabled={false}
                classNames="btn btn-primary btn-lg submit-on-enter"
                ctaText="SAVE & NEXT"
                onClick={saveCustomSectionClickHandler}
              /> */}
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--summary">
                <ResumeMap />
              </div>
            </div>
            <div className="row py-4 mb-5">
              <div className="col">
                <div className="box__footer box__footer--steps fbn btn-justify">
                  <Button
                    isLoading={false}
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("summary.back_action")}
                    onClick={backClickHandler}
                  />
                  <Button
                    isLoading={false}
                    disabled={false}
                    classNames="btn btn-primary btn-lg submit-on-enter"
                    ctaText={t("personal_info.next_action")}
                    onClick={saveCustomSectionClickHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default CustomSection;
