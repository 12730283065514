import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChooseTemplates from "./component";
import { fetchTemplates } from "./actions";
import { setTemplate } from "../funnel-step/actions";
import { resetImageURl } from "../../components/image-upload-modal/actions";

const mapStateToProps = ({ chooseTemplate, funnelStep }) => ({
  chooseTemplate,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTemplatesConnect: fetchTemplates,
      setTemplateConnect: setTemplate,
      resetImageURlConnect: resetImageURl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTemplates);
