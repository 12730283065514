import appLogger from "../../utils/app-logger";
import Types from "./types";
import EventServiceApi from "../../services/event-service-api";
import userServiceApi from "../../services/user-service-api";

const forgotPassword = (data) => {
  return function (dispatch) {
    return userServiceApi
      .forgotPassword(data)
      .then((response) => {
        if (response) {
          appLogger.log("inside forgotPassword");
          //dispatch(savePersonalInfoSuccess(response.data.docGuid));
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};

const generateResetToken = (data) => {
  return function (dispatch) {
    return userServiceApi
      .postEvent(data)
      .then((response) => {
        if (response) {
          appLogger.log("inside postEvent");
          //dispatch(savePersonalInfoSuccess(response.data.docGuid));
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};

export { forgotPassword };
