import appLogger from "../../utils/app-logger";
import Types from "./types";
import UserServiceApi from "../../services/user-service-api";

const resetPassword = (data) => {
	return function (dispatch) {
		return UserServiceApi.resetPassword(data)
			.then((response) => {
				if (response) {
					appLogger.log("inside reset password");
					//dispatch(savePersonalInfoSuccess(response.data.docGuid));
				} else {
					//dispatch(savePersonalInfoFailure(response.data));
				}
				return response;
			})
			.catch((error) => console.log("Error"));
		// .catch((error) => dispatch(savePersonalInfoFailure(error)));
	};
};

export { resetPassword };
