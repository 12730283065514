import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
  selectedData: "",
};

export const saveOpenerRecommendInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const updateOpenerRecommendInfo = (
  state = INITIAL_STATE,
  headerdata
) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const saveSelectedInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, selectedData: data };
};
export const resetOpenerRecommendInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveOpenerRecommendInfo,
  [Types.UPDATE_SUCCESS]: updateOpenerRecommendInfo,
  [Types.SAVE_SELECTED]: saveSelectedInfo,
  [Types.RESET_RECOMMEND]: resetOpenerRecommendInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
