import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./component";
import { updateProgress } from "./actions";
import personalInfo from "../../pages/personal-info";

const mapStateToProps = ({ cvltrSidebar }) => ({
  cvltrSidebar,
  personalInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProgress: updateProgress,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
