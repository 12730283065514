import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import UserServiceApi from "../../services/user-service-api";

const getUserDocs = (userguid) => {
  return function (dispatch) {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        if (response) {
          if (!!response && response.documents.length > 0) {
          }
          appLogger.log("inside getUserDocs response");
        } else {
          appLogger.log("inside getUserDocs response else");
        }
        return response;
      })
      .catch((error) => dispatch(getUserDocsFailure(error)));
  };
};
const renameCoverLetter = (docGuid, newDocumentName) => {
  return function (dispatch) {
    return DocBuilderService.renameDocument(docGuid, newDocumentName).then((response) => {
      if (response) {
      }
      return response;
    });
  };
};

const getUserProfile = (userId) => {
  return function (dispatch) {
    return UserServiceApi.getUserProfile(userId).then((response) => {
      appLogger.log("inside getUserProfile");
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const getUserDocsSuccess = (data) => ({
  type: Types.GET_SUCCESS,
  data,
});

const getUserDocsFailure = (error) => ({
  type: Types.GET_FAILED,
  error,
});
export { getUserDocs, getUserDocsSuccess, renameCoverLetter, getUserProfile };
