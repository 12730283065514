import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/login";
import { isAuthorizedAccess } from "./utils/helpers";
import UnauthorizedAccess from "./pages/unauthorized-access";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ComponentList } from "./routing/componentlist";
import Introduction from "./pages/introduction";
import ChooseTemplate from "./pages/choose-templates";
import PersonalInfo from "./pages/personal-info";
import ExperienceTips from "./pages/experience-tips";
import Experience from "./pages/experience";
import EducationTips from "./pages/education-tips";
import Education from "./pages/education";
import SummaryTips from "./pages/summary-tips";
import Summary from "./pages/summary";
import SkillTips from "./pages/skill-tips";
import Skill from "./pages/skill";
import Review from "./pages/review";
import Language from "./pages/language";
import Resumes from "./pages/resumes";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import ResetPassword from "./pages/reset-password";
import ForgotPassword from "./pages/forgot-password";
import CustomSection from "./pages/custom-section";
import Confirmation from "./pages/confirmation";
import Plans from "./pages/plans";
import CheckoutPage from "./pages/checkout-page";
import Checkout from "./pages/checkout";
import OtherSection from "./pages/other-section";
import CancelSubscription from "./pages/cancel-subscription";
import CancelSubscriptionConfirmation from "./pages/cancel-subscription-confirmation";
import UserSettings from "./pages/user-settings";
import PageNotFound from "./pages/page-not-found";
import withAppInsights from "./AppInsights";
import SessionExpire from "./pages/session-expire";
import SsoTransfer from "./pages/sso-transfer";
import SignOut from "./pages/sign-out";
import ChooseOptions from "./pages/choose-options";
import UploadResume from "./pages/upload-resume";
import SelectedDocument from "./pages/selected-document";
import ApplyTemplate from "./pages/apply-template";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getGoogleClientId } from "./utils/general-config";
import CoverLetterIntroPage from "./pages/cover-letter-intro-page";
import CoverLetterOpenerPage from "./pages/cover-letter-opener-page";
import CoverLetterBodyPage from "./pages/cover-letter-body-page";
import CoverLetterCloserPage from "./pages/cover-letter-closer-page";
import CoverLetterPersonalInformation from "./pages/cover-letter-personal-information";
import CoverLetterJobTitle from "./pages/cover-letter-job-title";
import CoverLetterDesiredCompany from "./pages/cover-letter-desired-company";
import CoverLetterExperience from "./pages/cover-letter-experience";
import CoverLetterRecommend from "./pages/cover-letter-recommend";
import CoverLetterSkills from "./pages/cover-letter-skills";
import CoverLetterBodyRecommend from "./pages/cover-letter-body-recommend";
import CoverLetterCloserRecommend from "./pages/cover-letter-closer-recommend";
import CoverLetterSignature from "./pages/cover-letter-signature";
import CoverLetterReview from "./pages/cover-letter-review";
import CoverLetters from "./pages/cover-letters";
import CoverLetterIntro from "./pages/cover-letter-introduction";

const RenderRoutes = (props) => {
  const localtion = useLocation();
  const { pathname } = localtion;
  const currentEnv = `${process.env.REACT_APP_ENVIRONMENT}`;
  console.log("current-environment::", currentEnv);
  const navigate = useNavigate();
  const redirectfunnelStepRuleData = useSelector((state) => state.funnelStep);
  const { templateCd, currentStep } = redirectfunnelStepRuleData || {};
  const [showHome, setShowHome] = useState(false);
  useEffect(() => {
    setShowHome(true);
    if (localtion.pathname.indexOf("/en/") > -1) {
      const path = localtion.pathname.replaceAll("/en/", "/");
      return navigate(path);
    }
    // if (
    //   !!currentStep &&
    //   (pathname == "/" || pathname == "/app" || pathname == "/app/")
    // ) {
    //   navigate(currentStep);
    //   setShowHome(false);
    // } else {
    //   setShowHome(true);
    // }
  }, []);

  // const baseUrl = () => {
  //   i18n.language === "en" ? "" : "/" + i18n.language;
  // };

  return (
    <>
      <Routes>
        {!isAuthorizedAccess() && <Route element={UnauthorizedAccess} />}

        <Route element={<Introduction />}>
          <Route
            path={ComponentList.RootHome.Path}
            element={showHome ? <Introduction /> : <></>}
          />
          <Route
            path={ComponentList.RootHome.LocalePath}
            element={showHome ? <Introduction /> : <></>}
          />
        </Route>
        <Route element={<Introduction />}>
          <Route
            path={ComponentList.Home.Path}
            element={showHome ? <Introduction /> : <></>}
          />
          <Route
            path={ComponentList.Home.LocalePath}
            element={showHome ? <Introduction /> : <></>}
          />
        </Route>
        <Route exact path="/login" element={<Login />} />

        <Route element={<SsoTransfer />}>
          <Route
            path={ComponentList.SsoTransfer.Path}
            element={<SsoTransfer />}
          />
          <Route
            path={ComponentList.SsoTransfer.LocalePath}
            element={<SsoTransfer />}
          />
        </Route>

        <Route element={<SignOut />}>
          <Route path={ComponentList.SignOut.Path} element={<SignOut />} />
          <Route
            path={ComponentList.SignOut.LocalePath}
            element={<SignOut />}
          />
        </Route>

        <Route element={<UserSettings />}>
          <Route
            path={ComponentList.UserSettings.Path}
            element={<UserSettings />}
          />
          <Route
            path={ComponentList.UserSettings.LocalePath}
            element={<UserSettings />}
          />
        </Route>
        <Route element={<Introduction />}>
          <Route
            path={ComponentList.Introduction.Path}
            element={<Introduction />}
          />
          <Route
            path={ComponentList.Introduction.LocalePath}
            element={<Introduction />}
          />
        </Route>
        <Route element={<ChooseTemplate />}>
          <Route
            path={ComponentList.ChooseTemplate.Path}
            element={<ChooseTemplate />}
          />
          <Route
            path={ComponentList.ChooseTemplate.LocalePath}
            element={<ChooseTemplate />}
          />
        </Route>
        <Route element={<CoverLetterIntro />}>
          <Route
            path={ComponentList.CoverLetterIntro.Path}
            element={<ChooseTemplate />}
          />
          <Route
            path={ComponentList.CoverLetterIntro.LocalePath}
            element={<CoverLetterIntro />}
          />
        </Route>
        <Route element={<CoverLetterIntroPage />}>
          <Route
            path={ComponentList.IntroPage.Path}
            element={<CoverLetterIntroPage />}
          />
          <Route
            path={ComponentList.IntroPage.LocalePath}
            element={<CoverLetterIntroPage />}
          />
        </Route>
        <Route element={<CoverLetterPersonalInformation />}>
          <Route
            path={ComponentList.PersonalInformation.Path}
            element={<CoverLetterPersonalInformation />}
          />
          <Route
            path={ComponentList.PersonalInformation.LocalePath}
            element={<CoverLetterPersonalInformation />}
          />
        </Route>
        <Route element={<CoverLetterDesiredCompany />}>
          <Route
            path={ComponentList.DesiredCompany.Path}
            element={<CoverLetterDesiredCompany />}
          />
          <Route
            path={ComponentList.DesiredCompany.LocalePath}
            element={<CoverLetterDesiredCompany />}
          />
        </Route>
        <Route element={<CoverLetterExperience />}>
          <Route
            path={ComponentList.CoverLetterExperience.Path}
            element={<CoverLetterExperience />}
          />
          <Route
            path={ComponentList.CoverLetterExperience.LocalePath}
            element={<CoverLetterExperience />}
          />
        </Route>
        <Route element={<CoverLetterJobTitle />}>
          <Route
            path={ComponentList.JobTitle.Path}
            element={<CoverLetterJobTitle />}
          />
          <Route
            path={ComponentList.JobTitle.LocalePath}
            element={<CoverLetterJobTitle />}
          />
        </Route>
        <Route element={<CoverLetterOpenerPage />}>
          <Route
            path={ComponentList.Opener.Path}
            element={<CoverLetterOpenerPage />}
          />
          <Route
            path={ComponentList.Opener.LocalePath}
            element={<CoverLetterOpenerPage />}
          />
        </Route>
        <Route element={<PersonalInfo />}>
          <Route
            path={ComponentList.PersonalInfo.Path}
            element={<PersonalInfo />}
          />
          <Route
            path={ComponentList.PersonalInfo.LocalePath}
            element={<PersonalInfo />}
          />
        </Route>
        <Route element={<ExperienceTips />}>
          <Route
            path={ComponentList.ExperienceTips.Path}
            element={<ExperienceTips />}
          />
          <Route
            path={ComponentList.ExperienceTips.LocalePath}
            element={<ExperienceTips />}
          />
        </Route>
        <Route element={<Experience />}>
          <Route
            path={ComponentList.Experience.Path}
            element={<Experience />}
          />
          <Route
            path={ComponentList.Experience.LocalePath}
            element={<Experience />}
          />
        </Route>

        <Route element={<EducationTips />}>
          <Route
            path={ComponentList.EducationTips.Path}
            element={<EducationTips />}
          />
          <Route
            path={ComponentList.EducationTips.LocalePath}
            element={<EducationTips />}
          />
        </Route>

        <Route element={<Education />}>
          <Route path={ComponentList.Education.Path} element={<Education />} />
          <Route
            path={ComponentList.Education.LocalePath}
            element={<Education />}
          />
        </Route>

        <Route element={<SkillTips />}>
          <Route path={ComponentList.SkillTips.Path} element={<SkillTips />} />
          <Route
            path={ComponentList.SkillTips.LocalePath}
            element={<SkillTips />}
          />
        </Route>
        <Route element={<Skill />}>
          <Route path={ComponentList.Skill.Path} element={<Skill />} />
          <Route path={ComponentList.Skill.LocalePath} element={<Skill />} />
        </Route>
        <Route element={<Language />}>
          <Route path={ComponentList.Language.Path} element={<Language />} />
          <Route
            path={ComponentList.Language.LocalePath}
            element={<Language />}
          />
        </Route>

        <Route element={<SummaryTips />}>
          <Route
            path={ComponentList.SummaryTips.Path}
            element={<SummaryTips />}
          />
          <Route
            path={ComponentList.SummaryTips.LocalePath}
            element={<SummaryTips />}
          />
        </Route>

        <Route element={<Summary />}>
          <Route path={ComponentList.Summary.Path} element={<Summary />} />
          <Route
            path={ComponentList.Summary.LocalePath}
            element={<Summary />}
          />
        </Route>

        <Route element={<Review />}>
          <Route path={ComponentList.Review.Path} element={<Review />} />
          <Route path={ComponentList.Review.LocalePath} element={<Review />} />
        </Route>

        <Route element={<Summary />}>
          <Route path={ComponentList.Summary.Path} element={<Summary />} />
          <Route
            path={ComponentList.Summary.LocalePath}
            element={<Summary />}
          />
        </Route>

        <Route
          element={
            <GoogleOAuthProvider clientId={getGoogleClientId()}>
              <SignIn />
            </GoogleOAuthProvider>
          }
        >
          <Route
            path={ComponentList.SignIn.Path}
            element={
              <GoogleOAuthProvider clientId={getGoogleClientId()}>
                <SignIn />
              </GoogleOAuthProvider>
            }
          />
          <Route
            path={ComponentList.SignIn.LocalePath}
            element={
              <GoogleOAuthProvider clientId={getGoogleClientId()}>
                <SignIn />
              </GoogleOAuthProvider>
            }
          />
        </Route>
        <Route
          element={
            <GoogleOAuthProvider clientId={getGoogleClientId()}>
              <SignUp />
            </GoogleOAuthProvider>
          }
        >
          <Route
            path={ComponentList.SignUp.Path}
            element={
              <GoogleOAuthProvider clientId={getGoogleClientId()}>
                <SignUp />
              </GoogleOAuthProvider>
            }
          />
          <Route
            path={ComponentList.SignUp.LocalePath}
            element={
              <GoogleOAuthProvider clientId={getGoogleClientId()}>
                <SignUp />
              </GoogleOAuthProvider>
            }
          />
        </Route>
        <Route element={<ResetPassword />}>
          <Route
            path={ComponentList.ResetPassword.Path}
            element={<ResetPassword />}
          />
          <Route
            path={ComponentList.ResetPassword.LocalePath}
            element={<ResetPassword />}
          />
        </Route>
        <Route element={<ForgotPassword />}>
          <Route
            path={ComponentList.ForgotPassword.Path}
            element={<ForgotPassword />}
          />
          <Route
            path={ComponentList.ForgotPassword.LocalePath}
            element={<ForgotPassword />}
          />
        </Route>
        <Route element={<CustomSection />}>
          <Route
            path={ComponentList.CustomSection.Path}
            element={<CustomSection />}
          />
          <Route
            path={ComponentList.CustomSection.LocalePath}
            element={<CustomSection />}
          />
        </Route>
        <Route element={<Confirmation />}>
          <Route
            path={ComponentList.Confirmation.Path}
            element={<Confirmation />}
          />
          <Route
            path={ComponentList.Confirmation.LocalePath}
            element={<Confirmation />}
          />
        </Route>

        <Route element={<Plans />}>
          <Route path={ComponentList.Plans.Path} element={<Plans />} />
          <Route path={ComponentList.Plans.LocalePath} element={<Plans />} />
        </Route>
        <Route element={<CheckoutPage />}>
          <Route
            path={ComponentList.CheckoutPage.Path}
            element={<CheckoutPage />}
          />
          <Route
            path={ComponentList.CheckoutPage.LocalePath}
            element={<CheckoutPage />}
          />
        </Route>
        <Route element={<Checkout />}>
          <Route path={ComponentList.Checkout.Path} element={<Checkout />} />
          <Route
            path={ComponentList.Checkout.LocalePath}
            element={<Checkout />}
          />
        </Route>

        <Route element={<CancelSubscription />}>
          <Route
            path={ComponentList.CancelSubscription.Path}
            element={<CancelSubscription />}
          />
          <Route
            path={ComponentList.CancelSubscription.LocalePath}
            element={<CancelSubscription />}
          />
        </Route>

        <Route element={<CancelSubscriptionConfirmation />}>
          <Route
            path={ComponentList.CancelSubscriptionConfirmation.Path}
            element={<CancelSubscriptionConfirmation />}
          />
          <Route
            path={ComponentList.CancelSubscriptionConfirmation.LocalePath}
            element={<CancelSubscriptionConfirmation />}
          />
        </Route>

        <Route element={<Language />}>
          <Route path={ComponentList.Language.Path} element={<Language />} />
          <Route
            path={ComponentList.Language.LocalePath}
            element={<Language />}
          />
        </Route>
        <Route element={<OtherSection />}>
          <Route
            path={ComponentList.OtherSection.Path}
            element={<OtherSection />}
          />
          <Route
            path={ComponentList.OtherSection.LocalePath}
            element={<OtherSection />}
          />
        </Route>
        <Route element={<Resumes />}>
          <Route path={ComponentList.Resumes.Path} element={<Resumes />} />
          <Route
            path={ComponentList.Resumes.LocalePath}
            element={<Resumes />}
          />
        </Route>
        <Route element={<CoverLetters />}>
          <Route
            path={ComponentList.CoverLetters.Path}
            element={<CoverLetters />}
          />
          <Route
            path={ComponentList.CoverLetters.LocalePath}
            element={<Resumes />}
          />
        </Route>
        <Route element={<SessionExpire />}>
          <Route
            path={ComponentList.SessionExpire.Path}
            element={<SessionExpire />}
          />
          <Route
            path={ComponentList.SessionExpire.LocalePath}
            element={<SessionExpire />}
          />
        </Route>
        <Route element={<ChooseOptions />}>
          <Route
            path={ComponentList.ChooseOptions.Path}
            element={<ChooseOptions />}
          />
          <Route
            path={ComponentList.ChooseOptions.LocalePath}
            element={<ChooseOptions />}
          />
        </Route>
        <Route element={<UploadResume />}>
          <Route
            path={ComponentList.UploadResume.Path}
            element={<UploadResume />}
          />
          <Route
            path={ComponentList.UploadResume.LocalePath}
            element={<UploadResume />}
          />
        </Route>
        <Route element={<SelectedDocument />}>
          <Route
            path={ComponentList.SelectedDocument.Path}
            element={<SelectedDocument />}
          />
          <Route
            path={ComponentList.SelectedDocument.LocalePath}
            element={<SelectedDocument />}
          />
        </Route>
        <Route element={<ApplyTemplate />}>
          <Route
            path={ComponentList.ApplyTemplate.Path}
            element={<ApplyTemplate />}
          />
          <Route
            path={ComponentList.ApplyTemplate.LocalePath}
            element={<ApplyTemplate />}
          />
        </Route>

        <Route
          exact
          path={ComponentList.AdminLogin.Path}
          element={ComponentList.AdminLogin.Component}
        />
        {/* <Route path={"*"} element={<PageNotFound />} /> */}

        <Route element={<PageNotFound />}>
          <Route path={"*"} element={<PageNotFound />} />
          <Route
            path={ComponentList.PageNotFound.LocalePath}
            element={<PageNotFound />}
          />
        </Route>

        {/* Dummy routes will remove after sometime */}

        <Route element={<CoverLetterSignature />}>
          <Route
            path={ComponentList.CoverLetterSignature.Path}
            element={<CoverLetterSignature />}
          />
          <Route
            path={ComponentList.CoverLetterSignature.LocalePath}
            element={<CoverLetterSignature />}
          />
        </Route>
        <Route element={<CoverLetterBodyPage />}>
          <Route
            path={ComponentList.Body.Path}
            element={<CoverLetterBodyPage />}
          />
          <Route
            path={ComponentList.Body.LocalePath}
            element={<CoverLetterBodyPage />}
          />
        </Route>
        <Route element={<CoverLetterSkills />}>
          <Route
            path={ComponentList.CoverLetterSkills.Path}
            element={<CoverLetterSkills />}
          />
          <Route
            path={ComponentList.CoverLetterSkills.LocalePath}
            element={<CoverLetterSkills />}
          />
        </Route>
        <Route element={<CoverLetterRecommend />}>
          <Route
            path={ComponentList.CoverLetterRecommend.Path}
            element={<CoverLetterRecommend />}
          />
          <Route
            path={ComponentList.CoverLetterRecommend.LocalePath}
            element={<CoverLetterRecommend />}
          />
        </Route>
        <Route element={<CoverLetterBodyRecommend />}>
          <Route
            path={ComponentList.CoverLetterBodyRecommend.Path}
            element={<CoverLetterBodyRecommend />}
          />
          <Route
            path={ComponentList.CoverLetterBodyRecommend.LocalePath}
            element={<CoverLetterBodyRecommend />}
          />
        </Route>
        <Route element={<CoverLetterCloserRecommend />}>
          <Route
            path={ComponentList.CoverLetterCloserRecommend.Path}
            element={<CoverLetterCloserRecommend />}
          />
          <Route
            path={ComponentList.CoverLetterCloserRecommend.LocalePath}
            element={<CoverLetterCloserRecommend />}
          />
        </Route>
        <Route element={<CoverLetterCloserPage />}>
          <Route
            path={ComponentList.Closer.Path}
            element={<CoverLetterCloserPage />}
          />
          <Route
            path={ComponentList.Closer.LocalePath}
            element={<CoverLetterCloserPage />}
          />
        </Route>
        <Route element={<CoverLetterReview />}>
          <Route
            path={ComponentList.CoverLetterReview.Path}
            element={<CoverLetterReview />}
          />
          <Route
            path={ComponentList.CoverLetterReview.LocalePath}
            element={<CoverLetterReview />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default withAppInsights(RenderRoutes);
