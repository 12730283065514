import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TempHeader from "./component";

const mapStateToProps = ({ imageUploadPreview }) => ({
  imageUploadPreview,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TempHeader);
