import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getOrchestrationServiceEndPoint } from "../services/api-constants";
import { readCookie } from "../utils/helpers";

class OrchestrationService {
  OrchestrationService(url) {
    return getOrchestrationServiceEndPoint(url);
  }
  checkoutProcess(data) {
    appLogger.log("inside checkout process ");
    const createOrchestrationProcessEndpoint = this.OrchestrationService(`${endPoints.orchestrationService.CHECKOUT}`);
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(createOrchestrationProcessEndpoint, data, headers);
  }

  checkoutProcess3DS(data) {
    appLogger.log("inside checkout process ");
    const createOrchestrationProcessEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.CHECKOUT_3DS}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(createOrchestrationProcessEndpoint, data, headers);
  }

  getBillingInfo(userGuid, locale) {
    appLogger.log("inside getBillingInfo ");
    const bilingInfoEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.BILLING_INFO.replace(":userGuid", userGuid).replace(":localeVal", locale)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.get(bilingInfoEndpoint, null, headers);
  }

  cancelUserByUserGuid(data, userGUID) {
    appLogger.log("inside cancelUserByUserGuid ");
    const cancelUserEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.CANCEL_USER.replace(":userGuid", userGUID)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.put(cancelUserEndpoint, data, headers);
  }

  restartSubscription(data, userGUID) {
    appLogger.log("inside cancelUserByUserGuid ");
    const restartSubscriptionEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.RESTART_SUB.replace(":userGuid", userGUID)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(restartSubscriptionEndpoint, data, headers);
  }

  setConfirmationinCB(userGUID) {
    appLogger.log("inside setConfirmationinCB ");
    const confirmationinCBEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.SET_CONFIRMATION.replace(":userGuid", userGUID)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.put(confirmationinCBEndpoint, JSON.stringify("true"), headers);
  }

  getUserPlan(userGuid, data) {
    appLogger.log("inside getUserPlan ");
    const PlanEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.GET_USER_PLANS.replace(":userGuid", userGuid)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.post(PlanEndpoint, data, headers);
  }
  getGatewayAbtestResponse(cardtoken, currency, portal) {
    appLogger.log("inside getGatewayAbtestResponse ");
    const gatewayABtestEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.GATEWAY_ABTEST.replace(":cardtoken", cardtoken)
        .replace(":currency", currency)
        .replace(":portal", portal)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.get(gatewayABtestEndpoint, null, headers);
  }
  isCardInBadBins(bin, portal, currencyCD, country) {
    appLogger.log("inside getGatewayAbtestResponse ");
    const gatewayABtestEndpoint = this.OrchestrationService(
      `${endPoints.orchestrationService.BAD_BINS.replace(":token", bin)
        .replace(":currency", currencyCD)
        .replace(":portal", portal)
        .replace(":CountryCd", country)}`
    );
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.get(gatewayABtestEndpoint, null, headers);
  }
}

export default new OrchestrationService();
