import React from "react";
import CoverLetterPersonalSection from "../../cover-letter-personal-section";
import CoverLetterRecipientSection from "../../cover-letter-recipient-section";
import CoverLetterOpenerSection from "../../cover-letter-opener-section";
import CoverLetterBodySection from "../../cover-letter-body-section";
import CoverLetterCloserSection from "../../cover-letter-closer-section";
import CoverLetterSignatureSection from "../../cover-letter-signature-section";
import "./cvltr-3.css";
import { useTranslation } from "react-i18next";

const Cl03Professional = ({ cvltrTemplatePreview, orderComponent }) => {
  const { t } = useTranslation()

  const steps =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.steps
      ? cvltrTemplatePreview.steps
      : false;

  return (
    <div className="cvltr-preview">
      <div className="cvltr-layout cvltr-3">
        {steps.personalInfo && <CoverLetterPersonalSection />}
        {steps.recipient && <CoverLetterRecipientSection />}
        {
          orderComponent && orderComponent.length > 0 && orderComponent.map((item) => {
            return (
              <>
                {item === t("opener.opener_recommend") && steps.opener && <CoverLetterOpenerSection />}
                {item === t("opener.body_recommend") && steps.body && <CoverLetterBodySection />}
                {item === t("opener.closer_recommend") && steps.closer && <CoverLetterCloserSection />}
              </>
            )
          })
        }
        {steps.signature && <CoverLetterSignatureSection />}
      </div>
    </div>
  );
};

export default Cl03Professional;
