import { createTypes } from "reduxsauce";

export default createTypes(
  `
  PREVIEW
  RESET_PREVIEW
  STEPS
`,
  {
    prefix: "template/",
  }
);
