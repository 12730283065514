import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FinalStepTypes from "../funnel-step/types";
import Cl01Modern from "../../components/cvlt/cover-letter-preview-templates/cl-01-modern";
import Cl02Elegant from "../../components/cvlt/cover-letter-preview-templates/cl-02-elegant";
import Cl03Professional from "../../components/cvlt/cover-letter-preview-templates/cl-03-professional";
import Cl04Classic from "../../components/cvlt/cover-letter-preview-templates/cl-04-classic";
import Cl05Contemporary from "../../components/cvlt/cover-letter-preview-templates/cl-05-contemporary";
import Cl06Standard from "../../components/cvlt/cover-letter-preview-templates/cl-06-standard";
import Cl07Professional from "../../components/cvlt/cover-letter-preview-templates/cl-07-professional";
import Cl08Modern from "../../components/cvlt/cover-letter-preview-templates/cl-08-modern";
import { DOC_TYPE } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const SelectedCoverLetterComponent = ({ orderComponent, name }) => {
  const SelectiveSection = {
    Modern: Cl01Modern,
    Elegant: Cl02Elegant,
    Professional: Cl03Professional,
    Classic: Cl04Classic,
    Contemporary: Cl05Contemporary,
    Standard: Cl06Standard,
    Professional1: Cl07Professional,
    Modern1: Cl08Modern,
  };
  const SelectedCoverLetter = SelectiveSection[name];
  if (!SelectedCoverLetter) {
    return null;
  }
  return <SelectedCoverLetter orderComponent={orderComponent} />;
};

const CoverLetterTemplate = ({ funnelStep, docReview }) => {
  const { templateCd, docType } = funnelStep;
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const reviewDoc = docReview && docReview.reviewDoc ? docReview.reviewDoc : ""

  const initialOrdering = [
    t("opener.opener_recommend"),
    t("opener.body_recommend"),
    t("opener.closer_recommend"),
  ];

  const [orderComponent, setOrderComponent] = useState(initialOrdering)

  useEffect(() => {
    let docType = DOC_TYPE.COVER_LETTER;
    dispatch({
      type: FinalStepTypes.SET_DOC_TYPE,
      docType,
    });
  }, []);

  useEffect(() => {
    if (reviewDoc && reviewDoc !== "") {
      let filteredDocPara = reviewDoc?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }

      filteredDocPara.sort((a, b) => a.order - b.order)

      let filterPara = []

      filteredDocPara.map((item) => {
        filterPara.push(item.paraName)
      })

      setOrderComponent(filterPara)
    } else {
      setOrderComponent(initialOrdering)
    }
  }, [reviewDoc])

  return (
    <>
      {templateCd && templateCd !== "" && (
        <div className="img-fluid border cvltr-preview-spacing">
          <SelectedCoverLetterComponent orderComponent={orderComponent} name={templateCd} />
        </div>
      )}
    </>
  );
};

export default CoverLetterTemplate;
