import React from "react";
import Button from "../../shared/button";
import { useNavigate, link } from "react-router-dom";
import PathHelper from "../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../utils/constants";
import { Trans, useTranslation } from "react-i18next";
import { concatNotEmptyBy } from "../../../utils/helpers";
import { blobSignature, imageBlobSignature } from "../../../utils/general-config";
const ResumeHeader = ({ data }) => {
  const { firstName, lastName, phone, emailAddress, address, city, state, zipCode, country } = JSON.parse(data.content);
  const { t } = useTranslation();
  return (
    <>
      <h1 className="info-headline" style={{ fontFamily: data.fontFamily }}>
        {firstName} {lastName}
      </h1>
      <div className="personal-info sub-headline">
        <h2>{t("review.review_tmp_personal_info")}</h2>
      </div>
      <div className="info-main">
        <div className="info-item">
          <span className="info-label">{t("review.review_tmp_phone")}:</span>
          {phone && (
            <span className="info-text">
              <i className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  aria-hidden="true"
                  data-height="1em"
                  data-icon="fa:phone"
                  data-inline="false"
                  data-width="1em"
                  transform="rotate(360)"
                  viewBox="0 0 1408 1408"
                >
                  <path
                    fill="currentColor"
                    d="M1408 1112q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5T856 1357t-49-18q-98-35-175-83-127-79-264-216T152 776q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47T3.5 392 0 339q0-92 51-186Q107 52 157 31q25-11 68.5-21T296 0q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z"
                  ></path>
                </svg>
              </i>
              <span className="info-text">{phone}</span>
            </span>
          )}
        </div>

        <div className="info-item">
          <span className="info-label">{t("review.review_tmp_email")}:</span>
          {emailAddress && (
            <span className="info-text">
              <i className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  aria-hidden="true"
                  data-height="1em"
                  data-icon="fa:envelope"
                  data-inline="false"
                  data-width="1em"
                  transform="rotate(360)"
                  viewBox="0 0 1792 1408"
                >
                  <path
                    fill="currentColor"
                    d="M1792 454v794q0 66-47 113t-113 47H160q-66 0-113-47T0 1248V454q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48T1194 886q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38T639 759q-91-64-262-182.5T172 434q-62-42-117-115.5T0 182q0-78 41.5-130T160 0h1472q65 0 112.5 47t47.5 113z"
                  ></path>
                </svg>
              </i>
              <span className="info-text">{emailAddress}</span>
            </span>
          )}
        </div>

        <div className="info-item">
          <span className="info-label">{t("review.review_tmp_address")}:</span>
          {concatNotEmptyBy(", ")(address, city, zipCode, state, country) ? (
            <span className="info-text">
              <i className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  aria-hidden="true"
                  data-height="1em"
                  data-icon="fa:location-arrow"
                  data-inline="false"
                  data-width="1em"
                  transform="rotate(360)"
                  viewBox="0 0 1408 1408"
                >
                  <path
                    fill="currentColor"
                    d="M1401 93L761 1373q-17 35-57 35-5 0-15-2-22-5-35.5-22.5T640 1344V768H64q-22 0-39.5-13.5T2 719t4-42 29-30L1315 7q13-7 29-7 27 0 45 19 15 14 18.5 34.5T1401 93z"
                  ></path>
                </svg>
              </i>
              <span className="info-text">
                {concatNotEmptyBy(", ")(address, city, zipCode, state)}
                {country && " (" + country + ")"}
                {/* Temp Apartments, Floor 19, Blue Street, San Franciso, 94016 (US) */}
              </span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const ResumeProfileHeader = ({ data }) => {
  const { firstName, lastName, phone, emailAddress, address, city, state, zipCode, country, profilePicUrl } =
    JSON.parse(data.content);
  const { t } = useTranslation();
  return (
    <>
      {profilePicUrl ? (
        <img src={profilePicUrl + imageBlobSignature()} className="square" />
      ) : (
        <img src="/app/static/images/profile-placeholder.png" className="square" />
      )}
    </>
  );
};

const TempHeader = ({ data, resumePersonalInfo, imageUploadPreview }) => {
  const { t } = useTranslation();
  // Nav
  let pathRedirect = PathHelper(APP_NAVIGATION.THED, false);
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };
  return (
    <div className="resume__section resume__heading resume__contact">
      <div className="resume__controls"></div>
      <div className="controls-menu">
        <Button
          isLoading={false}
          disabled={false}
          onClick={editHandler}
          icon={
            <>
              <span className="fa fa-pencil" aria-hidden="true"></span>
              <span className="resume__control-text"> {t("review.edit")}</span>
            </>
          }
          classNames="btn-green resume__control-button"
          ctaText=""
        />
      </div>
      <div className={`resume__content ${resumePersonalInfo ? "personal-info" : ""}`}>
        <div className="resume-photo">
          {!!data && data.map((item, index) => <ResumeProfileHeader data={item} key={"temProfileHeader" + index} />)}
        </div>
        <div className="resume-photo-content">
          {!!data && data.map((item, index) => <ResumeHeader data={item} key={"temHeader" + index} />)}
        </div>
      </div>
    </div>
  );
};

export default TempHeader;
