import { readCookie, createCookie, updateUploadDocCookie } from "../utils/helpers";
import { DomainName } from "./constants";
const currentEnv = `${process.env.REACT_APP_ENVIRONMENT}`;

// export const getTenantId = () => {
//   const tenantId = {
//     QA: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
//     PRODUCTION: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
//     STAGING: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
//     DEVELOPMENT: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
//   };
//   return tenantId[currentEnv];
// };

export const getTenantId = () => {
  let tenantId = {};
  let domain = currentDomain();
  switch (domain?.toLowerCase()) {
    case DomainName.RESUMEBUILD:
      tenantId = {
        QA: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
        PRODUCTION: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
        STAGING: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
        DEVELOPMENT: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
      };
      break;
    case DomainName.QUICKWERX:
      tenantId = {
        QA: "9DF21E9A-0332-422C-82A5-FE69EF0FE21F",
        PRODUCTION: "9DF21E9A-0332-422C-82A5-FE69EF0FE21F",
        STAGING: "9DF21E9A-0332-422C-82A5-FE69EF0FE21F",
        DEVELOPMENT: "9DF21E9A-0332-422C-82A5-FE69EF0FE21F",
      };
      break;
    default:
      console.log("default tenant");
      tenantId = {
        QA: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
        PRODUCTION: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
        STAGING: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
        DEVELOPMENT: "7fc9f425-1463-4a8e-8556-6c6b34cfcff6",
      };
      break;
  }
  return tenantId[currentEnv];
};

// export const getTenantGroupId = () => {
//   const tenantGroupId = {
//     PRODUCTION: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
//     STAGING: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
//     QA: "580C8B5A-DB83-4834-14EF-08D9501E735D",
//     DEVELOPMENT: "580C8B5A-DB83-4834-14EF-08D9501E735D",
//   };
//   return tenantGroupId[currentEnv];
// };

export const getTenantGroupId = () => {
  let tenantGroupId = {};
  let domain = currentDomain();
  switch (domain?.toLowerCase()) {
    case DomainName.RESUMEBUILD:
      tenantGroupId = {
        PRODUCTION: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
        STAGING: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
        QA: "580C8B5A-DB83-4834-14EF-08D9501E735D",
        DEVELOPMENT: "580C8B5A-DB83-4834-14EF-08D9501E735D",
      };
      break;
    case DomainName.QUICKWERX:
      tenantGroupId = {
        QA: "AAD7AC69-E7DA-45B3-B97C-EA2036616DD5",
        PRODUCTION: "AAD7AC69-E7DA-45B3-B97C-EA2036616DD5",
        STAGING: "AAD7AC69-E7DA-45B3-B97C-EA2036616DD5",
        DEVELOPMENT: "AAD7AC69-E7DA-45B3-B97C-EA2036616DD5",
      };
      break;
    default:
      console.log("default tenant");
      tenantGroupId = {
        PRODUCTION: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
        STAGING: "9C7D9766-9EBF-47A4-94E6-3F1F50CD4B2F",
        QA: "580C8B5A-DB83-4834-14EF-08D9501E735D",
        DEVELOPMENT: "580C8B5A-DB83-4834-14EF-08D9501E735D",
      };
      break;
  }
  return tenantGroupId[currentEnv];
};

export const getGoogleClientId = () => {
  return "448279941363-0as5am8vori1vf6nk02n488cne8a5c1j.apps.googleusercontent.com";
};

export const getFacebookClientId = () => {
  return "237961018547564";
};

export const getServiceCodeForgotPwd = () => {
  return "SRVRGR";
};

export const proIPKey = () => {
  return "qGpUO64dv9znGra";
};

export const TrustPilotKey = () => {
  return "k57tr84bd96sp32xg63oc47em85if29q";
};
export const userGuid = () => {
  let userguid = readCookie("userguid");
  if (!!userguid) {
    return userguid.toLocaleLowerCase();
  } else {
    console.log("PLEASE LOGIN !!");
  }
};

export const hidePaypal = (currencyCD) => {
  let isHide = false;
  const currenciesToHide = "ZAR|TRY|MYR|SAR|AED";
  if (currenciesToHide.split("|").includes(currencyCD)) {
    isHide = true;
  }
  return isHide;
};

export const getService = () => {
  const ServiceId = {
    QA: "BBCF66C5-D049-4EE6-98B9-AEF39E854D23",
    PRODUCTION: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
    STAGING: "F583361C-4EE9-485C-9CBC-2F83DE7020C1",
    DEVELOPMENT: "BBCF66C5-D049-4EE6-98B9-AEF39E854D23",
  };
  return ServiceId[currentEnv];
};
export const getServiceCode = () => {
  const ServiceCode = {
    QA: "SRVRGR",
    PRODUCTION: "SRVRGR",
    STAGING: "SRVRGR",
    DEVELOPMENT: "SRVRGR",
  };
  return ServiceCode[currentEnv];
};

export const getGoogleDriveClientId = () => {
  let gDriveId = "448279941363-0as5am8vori1vf6nk02n488cne8a5c1j.apps.googleusercontent.com";
  return gDriveId;
};

export const getGoogleDriveDeveloperKey = () => {
  let gDriveDeveloperKey = "AIzaSyAdECE4XLeOUjPxh1OYacEmXSfPTzDpU2k";

  return gDriveDeveloperKey;
};
export const currentDomain = () => {
  const currentUrl = window.location.href;
  if (currentUrl.indexOf("resumebuild") > -1) {
    return DomainName.RESUMEBUILD;
  } else if (currentUrl.indexOf("signsimpli") > -1) {
    return DomainName.SIGNSIMPLI;
  } else if (currentUrl.indexOf("pdfsimpli") > -1) {
    return DomainName.PDFSIMPLI;
  } else if (currentUrl.indexOf("quickwerx") > -1) {
    return DomainName.QUICKWERX;
  } else if (currentUrl.indexOf("localhost") > -1) {
    return DomainName.RESUMEBUILD;
  } else {
    return DomainName.RESUMEBUILD;
  }
};
export const getDropboxAppKey = () => {
  return "zn24myb1flq9r5t";
};
export const getResumeBuildOneDriveClientID = () => {
  return "7b430211-4602-4943-91d5-1033a5717768"; //App Name - PDFSimpli-onedrive
};
export const blobSignature = () => {
  const blobSign = {
    QA: "?sv=2020-04-08&si=pdfsimpliuserstest&sr=c&sig=C5E6T6kNLW0pcWAW2l2KrY1pboKjZQh1IhO%2FP9iozO8%3D",
    PRODUCTION: "?sv=2017-04-17&si=pdfsimpliusers&sr=c&sig=tLI3WycMdpg2v5sTxxX1RVi22y9Hz6tI8eeyOriK%2BjE%3D",
    STAGING: "?sv=2017-04-17&si=pdfsimpliusers&sr=c&sig=tLI3WycMdpg2v5sTxxX1RVi22y9Hz6tI8eeyOriK%2BjE%3D",
    DEVELOPMENT: "?sv=2020-04-08&si=pdfsimpliuserstest&sr=c&sig=C5E6T6kNLW0pcWAW2l2KrY1pboKjZQh1IhO%2FP9iozO8%3D",
  };
  return blobSign[currentEnv];
};
export const imageBlobSignature = () => {
  const imageBlobSign = {
    QA: "?sv=2020-04-08&si=pdfsimpliuserstest&sr=c&sig=C5E6T6kNLW0pcWAW2l2KrY1pboKjZQh1IhO%2FP9iozO8%3D",
    PRODUCTION: "?sv=2017-04-17&si=pdfsimpliusers&sr=c&sig=tLI3WycMdpg2v5sTxxX1RVi22y9Hz6tI8eeyOriK%2BjE%3D",
    STAGING: "?sv=2017-04-17&si=pdfsimpliusers&sr=c&sig=tLI3WycMdpg2v5sTxxX1RVi22y9Hz6tI8eeyOriK%2BjE%3D",
    DEVELOPMENT: "?sv=2020-04-08&si=pdfsimpliuserstest&sr=c&sig=C5E6T6kNLW0pcWAW2l2KrY1pboKjZQh1IhO%2FP9iozO8%3D",
  };
  return imageBlobSign[currentEnv];
};
export const getAnonymousUserToken = () => {
  const anonymousUserToken = {
    QA: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI0NGUwZmY2MzgwY2E0YzhkOWFlNzBjMjExMjc5NGFiNyJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IlZpdmVrIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiNzBiNWZiMjAtMGQ1Mi00ZWM1LTgyOGQtNTQxNTkzMmJlMzI5IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9ncm91cHNpZCI6ImQ2Y2FjYjYxLWM5NTktNDRkNC1iN2E3LWMyYzAwOWIyMzYzNSIsIm5iZiI6MTY1NzAwNjk4NiwiZXhwIjoxOTcyMzY2OTg2LCJpc3MiOiJMZWdhbFNpbXBsaSIsImF1ZCI6IlBERlNpbXBsaSJ9.Qan8dbl9yEHMwzGK1L4yk6FPZH7YpKnBRWYKoH9AyeCSXMmu_G3AVZZAPqcdZaSkf-B5U2RB6eHPz0LRA829FPVgw9r9-ouvu0JUcXNVVHptZ6Pv9btVpgVJ7gVOXrs995_iCtgfJoJqYYhW-mdF0RbjCgFdXmSEaqFTvDRKwDGFQ5JhoELd5-Dcvb3pcfTGuZ98T5K5jJd7dOp3Tj4FPrCijvV8pc3C70mFcwwfNSSi39Dd0e9dQ6yC35qG50bXGxq-CeUyV_BLKsReBSLEe5OnQzvjrIozHFDtmLFfr1qdVWKDMsyis0X-CeminHDeO1QIwJVEaAgcjfXjT_EILw",
    PRODUCTION:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI2NzM5MWEyMWVlMDU0NzJhYjEzM2NkNzA4ZWM4MDQ4ZiJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IlZpdmVrIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiYjU0MDA4NDgtNzU1OS00OWM0LWIwMDEtODFiMGYyNDUxNGRlIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9ncm91cHNpZCI6ImEwMWZkOGMxLWRjZGEtNDczYi04NWNiLWVjYTcwOThmNWI2OSIsIm5iZiI6MTY1NzA4NDg4OCwiZXhwIjoxOTcyNDQ0ODg4LCJpc3MiOiJMZWdhbFNpbXBsaSIsImF1ZCI6IlBERlNpbXBsaSJ9.Je085mV9UuwHSCAHHuPzZB0mQPRoQKfbA7gDhOuWOtVhj9Spl8zp6-8DNgOpbyBv-9G5HhBe0ezgjJEzHtOtM_ZRGvBLpqROvKGnhGBmqVwsiikbxG-f4x3MJe-jXTrsn1M24a9FOOtNRbK9gC1m_wsQZlIoQvkv15--X4ZmyqA-dxLxcFIoEH52q6QzZinWQ1o-ojxM9PRnthhQ4ycdqa6kI1dIilLWXMcpxZ1P7_wKPGRm1kBECNFbM_278SMsa1Cix6MDKoQg6TAiD-hffa-n9omTc8UVmwi3eXs8Rseo8mlTZHCm4rUuyufwniXZ3kX5BXFUTpgbN4voS4_q6w",
    STAGING:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI2NzM5MWEyMWVlMDU0NzJhYjEzM2NkNzA4ZWM4MDQ4ZiJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IlZpdmVrIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiYjU0MDA4NDgtNzU1OS00OWM0LWIwMDEtODFiMGYyNDUxNGRlIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9ncm91cHNpZCI6ImEwMWZkOGMxLWRjZGEtNDczYi04NWNiLWVjYTcwOThmNWI2OSIsIm5iZiI6MTY1NzA4NDg4OCwiZXhwIjoxOTcyNDQ0ODg4LCJpc3MiOiJMZWdhbFNpbXBsaSIsImF1ZCI6IlBERlNpbXBsaSJ9.Je085mV9UuwHSCAHHuPzZB0mQPRoQKfbA7gDhOuWOtVhj9Spl8zp6-8DNgOpbyBv-9G5HhBe0ezgjJEzHtOtM_ZRGvBLpqROvKGnhGBmqVwsiikbxG-f4x3MJe-jXTrsn1M24a9FOOtNRbK9gC1m_wsQZlIoQvkv15--X4ZmyqA-dxLxcFIoEH52q6QzZinWQ1o-ojxM9PRnthhQ4ycdqa6kI1dIilLWXMcpxZ1P7_wKPGRm1kBECNFbM_278SMsa1Cix6MDKoQg6TAiD-hffa-n9omTc8UVmwi3eXs8Rseo8mlTZHCm4rUuyufwniXZ3kX5BXFUTpgbN4voS4_q6w",
    DEVELOPMENT:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI0NGUwZmY2MzgwY2E0YzhkOWFlNzBjMjExMjc5NGFiNyJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IlZpdmVrIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiNzBiNWZiMjAtMGQ1Mi00ZWM1LTgyOGQtNTQxNTkzMmJlMzI5IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9ncm91cHNpZCI6ImQ2Y2FjYjYxLWM5NTktNDRkNC1iN2E3LWMyYzAwOWIyMzYzNSIsIm5iZiI6MTY1NzAwNjk4NiwiZXhwIjoxOTcyMzY2OTg2LCJpc3MiOiJMZWdhbFNpbXBsaSIsImF1ZCI6IlBERlNpbXBsaSJ9.Qan8dbl9yEHMwzGK1L4yk6FPZH7YpKnBRWYKoH9AyeCSXMmu_G3AVZZAPqcdZaSkf-B5U2RB6eHPz0LRA829FPVgw9r9-ouvu0JUcXNVVHptZ6Pv9btVpgVJ7gVOXrs995_iCtgfJoJqYYhW-mdF0RbjCgFdXmSEaqFTvDRKwDGFQ5JhoELd5-Dcvb3pcfTGuZ98T5K5jJd7dOp3Tj4FPrCijvV8pc3C70mFcwwfNSSi39Dd0e9dQ6yC35qG50bXGxq-CeUyV_BLKsReBSLEe5OnQzvjrIozHFDtmLFfr1qdVWKDMsyis0X-CeminHDeO1QIwJVEaAgcjfXjT_EILw",
  };
  return anonymousUserToken[currentEnv];
};

export const getABTestId = () => {
  const abTestId = {
    gatewayABTestId: "B2D0A139-AE13-42FA-906C-B24CD3082E84",
  };
  return abTestId.gatewayABTestId;
};

export const getGateway = (caseIndex) => {
  const WorldPay_RB = {
    DEVELOPMENT: "gw_16BjjoSgt6UxO3B1n",
    QA: "gw_16BjjoSgt6UxO3B1n",
    STAGING: "gw_AzZN47TLw9FdW16y1",
    PRODUCTION: "gw_AzZN47TLw9FdW16y1",
  };
  const RBCheckoutcomrathunas = {
    DEVELOPMENT: "gw_AzqgoORrOIoRK3vm",
    QA: "gw_AzqgoORrOIoRK3vm",
    STAGING: "gw_16BVLPTFEaZEcFNYs",
    PRODUCTION: "gw_16BVLPTFEaZEcFNYs",
  };
  const RBCheckoutRathunas3ds = {
    DEVELOPMENT: "gw_AzqgoORrPWu3xCoP",
    QA: "gw_AzqgoORrPWu3xCoP",
    STAGING: "gw_Azq9YyTf3P8zU41Ka",
    PRODUCTION: "gw_Azq9YyTf3P8zU41Ka",
  };
  switch (caseIndex) {
    case 1:
      return WorldPay_RB[currentEnv];

    case 2:
      return RBCheckoutcomrathunas[currentEnv];

    case 3:
      return RBCheckoutRathunas3ds[currentEnv];

    default:
      return "";
  }
};

export const downloadCounter = 10;

export const defaultIPAddress = "40.80.156.205";
export const defaultCountryCode = "US";
export const defaultCurrencyCode = "USD";
export const dashboardSettingPageURl = true;
//export const googleScore = 0.4;
export const site_key = "6Lf5G3wmAAAAAGtoQk-IVo7wCIMFwk3dWIWFsKhU";

export const googleScore = () => {
  const score = {
    DEVELOPMENT: 0.0,
    QA: 0.0,
    STAGING: 0.0,
    PRODUCTION: 0.0,
  };
  return score[currentEnv];
};
export const createUpdateDocumentUpload = () => {
  var numberOfDocumentUploaded = readCookie("documentUploaded") ? parseInt(readCookie("documentUploaded")) : 0;
  numberOfDocumentUploaded = numberOfDocumentUploaded + 1;
  if (numberOfDocumentUploaded == 1) {
    var date = new Date();
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    createCookie("uploadexpiretime", date.toGMTString(), 1);
    createCookie("documentUploaded", numberOfDocumentUploaded, 1);
  } else {
    updateUploadDocCookie("documentUploaded", numberOfDocumentUploaded);
  }
};
export const monthlyLimitedCategory = ["MROW000", "MROW195", "MSBRW95", "MSMBROW"];
export const monthlyFullAccessategory = ["M0SMB00", "MSMB195", "M000000", "M000195"];

export const cardType3ds = () => {
  const cardType = {
    QA: "discover",
    PRODUCTION: "discover",
    STAGING: "discover",
    DEVELOPMENT: "discover",
  };
  return cardType[currentEnv];
};
export const GigWorkersWelcomeEmailSubject = "Claim up to $32,220 in Tax Credits and Receive It Within 10 Days!";
export const GigWorkersWelcomeEmailDelayMinutes = 60;
export const GigWorkersWelcomeEmailLink = "https://www.gigworkersolutions.com/?source=sf-iera9iac";
export const GigWorkersEnable = true;
export const WelcomeEmailTemplateId = "9E815D62-4AA7-4989-8817-6532AD29CA93";
export const GigWorkerUserSignUpEvent = {
  TemplateId: "9E815D62-4AA7-4989-8817-6532AD29CA93",
  EmailProvider: "SendGrid",
  TopicName: "emailengine",
  EventSubType: "Email",
  EventType: "DSBD",
};

export const isPaymentMSEnable = () => {
  const isPaymentMSEnable = {
    QA: true,
    PRODUCTION: true,
    STAGING: true,
    DEVELOPMENT: true,
  };
  return isPaymentMSEnable[currentEnv];
};
export const ip2LocationKey = "A61D2C17410C9E4C36C1487DD80FA32A";

export const IsSingleLoginAPPEnable = () => {
  const isLoginAPPEnable = {
    PRODUCTION: true,
    STAGING: true,
    QA: true,
    DEVELOPMENT: false,
  };
  var isSingleLoginAPP = readCookie("slaenable");
  if (isSingleLoginAPP === "true" || isSingleLoginAPP === true) {
    return true;
  } else if (isLoginAPPEnable[currentEnv] === true) {
    return true;
  } else {
    return false;
  }
  //return isLoginAPPEnable[currentEnv];
};

export const getInitializationVector = () => {
  const ivKey = {
    QA: "2908290630030702",
    PRODUCTION: "2908290630030702",
    STAGING: "2908290630030702",
    DEVELOPMENT: "2908290630030702",
  };
  return ivKey[currentEnv];
};
