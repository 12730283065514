import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../utils/constants";
import Popover from "react-bootstrap/Popover";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";

const WritingTips = ({ section, page, question, answers }) => {
  const { t } = useTranslation();

  const getExperienceWitingTips = () => {
    return (
      <>
        <div className="writing-tips-parent">
          {" "}
          <h5 className="writing-tips-heading">
            {t("experience.writting_tips_headline")}
          </h5>
          <hr className="divider"></hr>
          <ul className="writing-tips">
            <li>{t("experience_tips.exp_list_first")}</li>
            <li>{t("experience_tips.exp_list_second")}</li>
            <li>{t("experience_tips.exp_list_third")}</li>
            <li>{t("experience_tips.exp_list_forth")}</li>
          </ul>
        </div>
      </>
    );
  };
  const getEducationWitingTips = () => {
    return (
      <>
        <div className="writing-tips-parent">
          <h5 className="writing-tips-heading">
            {t("education.writting_tips_headline")}
          </h5>
          <hr className="divider"></hr>
          <ul className="writing-tips">
            <li>{t("education_tips.edu_list_first")}</li>
            <li>{t("education_tips.edu_list_second")}</li>
            <li>{t("education_tips.edu_list_third")}</li>
            <li>{t("education_tips.edu_list_forth")}</li>
          </ul>
        </div>
      </>
    );
  };
  const getSkillWitingTips = () => {
    return (
      <>
        <div className="writing-tips-parent">
          <h5 className="writing-tips-heading">
            {t("skill.writting_tips_headline")}
          </h5>
          <hr className="divider"></hr>
          <ul className="writing-tips">
            <li>{t("skill_tips.skill_list_first")}</li>
            <li>{t("skill_tips.skill_list_second")}</li>
            <li>{t("skill_tips.skill_list_third")}</li>
            <li>{t("skill_tips.skill_list_forth")}</li>
          </ul>
        </div>
      </>
    );
  };
  const getSummaryWitingTips = () => {
    return (
      <>
        <div className="writing-tips-parent">
          <h5 className="writing-tips-heading">
            {t("summary.writting_tips_headline")}
          </h5>
          <hr className="divider"></hr>
          <ul className="writing-tips">
            <li>{t("summary_tips.sum_list_first")}</li>
            <li>{t("summary_tips.sum_list_second")}</li>
            <li>{t("summary_tips.sum_list_third")}</li>
            <li>{t("summary_tips.sum_list_forth")}</li>
          </ul>
        </div>
      </>
    );
  };

  const getLanguageWitingTips = () => {
    return (
      <>
        <div className="writing-tips-parent">
          <h5 className="writing-tips-heading">
            {t("language_tips.headline")}
          </h5>
          <hr className="divider"></hr>
          <ul className="writing-tips">
            <li>{t("language_tips.lang_list_first")}</li>
            <li>{t("language_tips.lang_list_second")}</li>
            <li>{t("language_tips.lang_list_third")}</li>
            <li>{t("language_tips.lang_list_forth")}</li>
          </ul>
        </div>
      </>
    );
  };
  const getCoverLetterWitingTips = () => {
    return (
      <>
        {answers !== undefined && (
          <div className="writing-tips-parent">
            <h5 className="writing-tips-heading">{question}</h5>
            <hr className="divider"></hr>
            <ul className="writing-tips">
              {answers.map((answer, index) => {
                return (
                  <li key={index}>
                    <p className="mb-3">{answer.faq}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  };
  const GetSectionTips = (section) => {
    switch (section) {
      case SECTION_TYPE.EXPR:
        return getExperienceWitingTips();
        break;
      case SECTION_TYPE.EDUC:
        return getEducationWitingTips();
        break;
      case SECTION_TYPE.SKLS:
        return getSkillWitingTips();
        break;
      case SECTION_TYPE.SUMM:
        return getSummaryWitingTips();
        break;
      case SECTION_TYPE.LANG:
        return getLanguageWitingTips();
      case SECTION_TYPE.CVLTS:
        return getCoverLetterWitingTips();
        break;
    }
  };

  const popoverClickRootClose = (
    <Popover id="popover-trigger-click-root-close">
      {GetSectionTips(section)}
    </Popover>
  );
  return (
    <>
      <ButtonToolbar>
        <OverlayTrigger
          trigger={["hover"]}
          rootClose
          placement="bottom-end"
          overlay={popoverClickRootClose}
        >
          <button className="btn-muted--light writting-tip-btn">
            <i className="fa fa-lightbulb-o" aria-hidden="true"></i>{" "}
            {section === SECTION_TYPE.CVLTS ? "FAQ" : t("writing_tips.title")}
          </button>
        </OverlayTrigger>
      </ButtonToolbar>
    </>
  );
};

export default WritingTips;
