import React, { useEffect, useState, useCallback } from "react";
import RBAnchore from "../../shared/button-link/component";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCoverLetterSignInLink, getCoverLetterSignUpLink, getSignInLink } from "../../../utils/path-helper";
import { getSignUpLink } from "../../../utils/path-helper";
import "./btns-group.scss";
import Button from "../../shared/button";
import { APP_NAVIGATION, DOC_TYPE } from "../../../utils/constants";
import PathHelper from "../../../utils/path-helper";
import { gotToDashBoardWithLocale, navigateToSignleLoginAPP, readCookie } from "../../../utils/helpers";
import { dashboardSettingPageURl, IsSingleLoginAPPEnable } from "../../../utils/general-config";

const TopBtnGroup = ({ callback, isShow, funnelStep }) => {
  const { locale } = useParams();
  let signInLink = getSignInLink(locale);
  let signUpLink = getSignUpLink(locale);

  if (window.location.pathname.split("/").pop().includes("cover-letter")) {
    signInLink = getCoverLetterSignInLink(locale);
    signUpLink = getCoverLetterSignUpLink(locale);
  }

  const { docType } = funnelStep || {};

  const { t } = useTranslation();
  let pathToDashboard = gotToDashBoardWithLocale(locale);

  //let pathRedirect = PathHelper(APP_NAVIGATION.SIGNIN, false);
  // Nav
  const navigate = useNavigate();
  const onClickSignIn = () => {
    if (IsSingleLoginAPPEnable()) {
      navigateToSignleLoginAPP(locale, "", "", false);
    } else {
      navigate(signInLink);
    }
  };
  const onClickSignUp = () => {
    navigate(signUpLink);
  };
  let localeval = "";
  if (!!locale) {
    localeval = locale;
  }
  const showCoverLetter = () => {
    return localeval == "" || localeval == "en";
  };
  //console.log("isPremium", isPremium);
  const pathToDashboardLocale = pathToDashboard + "dashboard";
  const pathToDashboardSettingsLocale = pathToDashboard + "dashboard/settings";
  const ddOnclickHandler = (i) => {
    let selectedDD = i.target.id;
    if (selectedDD == "ddResumes") {
      callback("ddResumes");
    }
    if (selectedDD == "ddCoverLetters") {
      callback("ddCoverLetters");
    }
    if (selectedDD == "ddSignout") {
      callback("ddSignout");
    }
  };
  if (
    // !!localStorage.getItem("jwtToken") ||
    // !!localStorage.getItem("refreshToken")
    !!readCookie("jwtToken") ||
    !!readCookie("refreshToken")
  ) {
    return (
      <>
        <div className="show">
          <div className="account-menu navbar-menu">
            <Dropdown>
              <Dropdown.Toggle aria-hidden="true" id="my_account" variant="btn btn-outline-primary btn-sm navbar-btn">
                <span className="bars-hidden-cls">
                  <i className="fa fa-bars menu-bars" aria-hidden="true"></i>
                </span>
                <span className="dropdown-btn-hide" id="my_account">
                  {t("header.my_account")}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu onClick={ddOnclickHandler} align="end">
                <Dropdown.Toggle aria-hidden="true" variant="btn btn-link btn-cross">
                  <img src={process.env.PUBLIC_URL + "/static/images/icon-cross.svg"} />
                </Dropdown.Toggle>

                <Dropdown.Item eventKey="1" id="ddResumes">
                  {t("header.my_resumes")}
                </Dropdown.Item>
                {showCoverLetter() && (
                  <Dropdown.Item eventKey="2" id="ddCoverLetters">
                    {t("header.my_cover_letters")}
                  </Dropdown.Item>
                )}
                {dashboardSettingPageURl ? (
                  <Dropdown.Item href={pathToDashboardSettingsLocale} id="ddSettings">
                    {t("header.settings")}
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item eventKey="2" id="ddSettings">
                    {t("header.settings")}
                  </Dropdown.Item>
                )}

                <Dropdown.Item href={pathToDashboardLocale} id="ddDashboard">
                  {t("header.gotodashboard")}
                </Dropdown.Item>

                <Dropdown.Item eventKey="3" id="ddSignout">
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  {t("header.signout")}
                </Dropdown.Item>
                <hr className="divider"></hr>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="show">
      <div className="sign-menu navbar-menu hide-arrow">
        {["cover-letter-signin", "signin"].includes(window.location.pathname.split("/").pop()) ? (
          <>
            <Dropdown>
              <Dropdown.Toggle aria-hidden="true" id="my_account" variant="btn btn-outline-primary btn-sm navbar-btn">
                <span className="bars-hidden-cls">
                  <i className="fa fa-bars menu-bars" aria-hidden="true"></i>
                </span>
                <span className="dropdown-btn-hide" onClick={onClickSignUp}>
                  {t("sign_up.btn_cta")}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                <Dropdown.Toggle aria-hidden="true" variant="btn btn-link btn-cross">
                  <img src={process.env.PUBLIC_URL + "/static/images/icon-cross.svg"} />
                </Dropdown.Toggle>
                <Dropdown.Item onClick={onClickSignUp}>{t("sign_up.btn_cta")}</Dropdown.Item>
                <hr className="divider"></hr>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : (
          <>
            <Dropdown>
              <Dropdown.Toggle aria-hidden="true" id="my_account" variant="btn btn-outline-primary btn-sm navbar-btn">
                <span className="bars-hidden-cls">
                  <i className="fa fa-bars menu-bars" aria-hidden="true"></i>
                </span>
                <span className="dropdown-btn-hide" onClick={onClickSignIn}>
                  {t("sign_up.sign_in")}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                <Dropdown.Toggle aria-hidden="true" variant="btn btn-link btn-cross">
                  <span className="btn-cross-icon">
                    <img src={process.env.PUBLIC_URL + "/static/images/icon-cross.svg"} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Item onClick={onClickSignIn}>{t("sign_up.sign_in")}</Dropdown.Item>
                <hr className="divider"></hr>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};
export default TopBtnGroup;
