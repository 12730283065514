import { ROUTE_CONSTANT } from "./route-constants";
import Login from "../pages/login";
import Home from "../pages/home";
import SsoTransfer from "../pages/sso-transfer";
import SignOut from "../pages/sign-out";
import Introduction from "../pages/introduction";
import UserSettings from "../pages/user-settings";
import PersonalInfo from "../pages/personal-info";
import ChooseTemplate from "../pages/choose-templates";
import Experience from "../pages/experience";
import ExperienceTips from "../pages/experience-tips/component";
import Education from "../pages/education";
import EducationTips from "../pages/education-tips/component";
import SkillTips from "../pages/skill-tips/component";
import SummaryTips from "../pages/summary-tips/component";
import Review from "../pages/review";
// import { isAuthorizedAccess } from "./utils/helpers";
import UnauthorizedAccess from "../pages/unauthorized-access";
import Skill from "../pages/skill";
import SignIn from "../pages/sign-in";
import SignUp from "../pages/sign-up";
import CustomSection from "../pages/custom-section";
import Confirmation from "../pages/confirmation";
import ResetPassword from "../pages/reset-password";
import ForgotPassword from "../pages/forgot-password";

import Plans from "../pages/plans";
import CheckoutPage from "../pages/checkout-page";
import Checkout from "../pages/checkout";
import CancelSubscription from "../pages/cancel-subscription";
import CancelSubscriptionConfirmation from "../pages/cancel-subscription-confirmation";
import Language from "../pages/language";
import Summary from "../pages/summary";
import OtherSection from "../pages/other-section";
import RootHome from "../pages/home";
import Resumes from "../pages/resumes";
import AdminLogin from "../pages/admin-login";
import PageNotFound from "../pages/page-not-found";
import SessionExpire from "../pages/session-expire/component";
import ChooseOptions from "../pages/choose-options";
import UploadResume from "../pages/upload-resume";
import SelectedDocument from "../pages/selected-document";
import ApplyTemplate from "../pages/apply-template";
import IntroPage from "../pages/cover-letter-intro-page";
import CoverLetterPersonalInformation from "../pages/cover-letter-personal-information";
import JobTitle from "../pages/cover-letter-job-title";
import DesiredCompany from "../pages/cover-letter-desired-company";
import CoverLetterExperience from "../pages/cover-letter-experience";
import CoverLetterOpenerPage from "../pages/cover-letter-opener-page";
import CoverLetterBodyPage from "../pages/cover-letter-body-page";
import CoverLetterSkills from "../pages/cover-letter-skills";
import CoverLetterSignature from "../pages/cover-letter-signature";
import CoverLetterRecommend from "../pages/cover-letter-recommend";
import CoverLetterBodyRecommend from "../pages/cover-letter-body-recommend";
import CoverLetterCloserRecommend from "../pages/cover-letter-closer-recommend";
import CoverLetterCloserPage from "../pages/cover-letter-closer-page";
import CoverLetterReview from "../pages/cover-letter-review";
import CoverLetters from "../pages/cover-letters";
import CoverLetterIntro from "../pages/cover-letter-introduction";

export const ComponentList = {
  RootHome: {
    Component: <Home />,
    Path: ROUTE_CONSTANT.ROOT_HOME,
    LocalePath: ROUTE_CONSTANT.ROOT_HOME_LOCALE,
  },
  Home: {
    Component: <Home />,
    Path: ROUTE_CONSTANT.HOME,
    LocalePath: ROUTE_CONSTANT.HOME_LOCALE,
  },

  SsoTransfer: {
    Component: <SsoTransfer />,
    Path: ROUTE_CONSTANT.SSO_TRANSFER,
    LocalePath: ROUTE_CONSTANT.SSO_TRANSFER_LOCALE,
  },
  SignOut: {
    Component: <SignOut />,
    Path: ROUTE_CONSTANT.SIGNOUT,
    LocalePath: ROUTE_CONSTANT.SIGNOUT_LOCALE,
  },
  Introduction: {
    Component: <Introduction />,
    Path: ROUTE_CONSTANT.INTRODUCTION,
    LocalePath: ROUTE_CONSTANT.INTRODUCTION_LOCALE,
  },
  UserSettings: {
    Component: <UserSettings />,
    Path: ROUTE_CONSTANT.SETTINGS,
    LocalePath: ROUTE_CONSTANT.SETTINGS_LOCALE,
  },
  PersonalInfo: {
    Component: <PersonalInfo />,
    Path: ROUTE_CONSTANT.PERSON_INFO,
    LocalePath: ROUTE_CONSTANT.PERSON_INFO_LOCALE,
  },
  ChooseTemplate: {
    Component: <ChooseTemplate />,
    Path: ROUTE_CONSTANT.CHOOSE_TEMPLATE,
    LocalePath: ROUTE_CONSTANT.CHOOSE_TEMPLATE_LOCALE,
  },
  IntroPage: {
    Component: <IntroPage />,
    Path: ROUTE_CONSTANT.INTRO_PAGE,
    LocalePath: ROUTE_CONSTANT.INTRO_PAGE_LOCALE,
  },
  CoverLetterIntro: {
    Component: <CoverLetterIntro />,
    Path: ROUTE_CONSTANT.COVER_LETTER_INTRO,
    LocalePath: ROUTE_CONSTANT.COVER_LETTER_INTRO_LOCALE,
  },
  PersonalInformation: {
    Component: <CoverLetterPersonalInformation />,
    Path: ROUTE_CONSTANT.PERSONAL_INFORMATION,
    LocalePath: ROUTE_CONSTANT.PERSONAL_INFORMATION_LOCAL,
  },
  JobTitle: {
    Component: <JobTitle />,
    Path: ROUTE_CONSTANT.JOB_TITLE,
    LocalePath: ROUTE_CONSTANT.JOB_TITLE_LOCAL,
  },
  DesiredCompany: {
    Component: <DesiredCompany />,
    Path: ROUTE_CONSTANT.DESIRED_COMPANY,
    LocalePath: ROUTE_CONSTANT.DESIRED_COMPANY_LOCAL,
  },
  CoverLetterExperience: {
    Component: <CoverLetterExperience />,
    Path: ROUTE_CONSTANT.CVLTR_EXPERIENCE,
    LocalePath: ROUTE_CONSTANT.CVLTR_EXPERIENCE_LOCAL,
  },
  CoverLetterReview: {
    Component: <CoverLetterReview />,
    Path: ROUTE_CONSTANT.CVLTR_REVIEW,
    LocalePath: ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL,
  },
  Opener: {
    Component: <CoverLetterOpenerPage />,
    Path: ROUTE_CONSTANT.OPENER_PAGE,
    LocalePath: ROUTE_CONSTANT.OPENER_PAGE_LOCAL,
  },
  Body: {
    Component: <CoverLetterBodyPage />,
    Path: ROUTE_CONSTANT.BODY_PAGE,
    LocalePath: ROUTE_CONSTANT.BODY_PAGE_LOCAL,
  },
  CoverLetterSkills: {
    Component: <CoverLetterSkills />,
    Path: ROUTE_CONSTANT.SKILLS_PAGE,
    LocalePath: ROUTE_CONSTANT.SKILLS_PAGE_LOCAL,
  },
  CoverLetterSignature: {
    Component: <CoverLetterSignature />,
    Path: ROUTE_CONSTANT.SIGNATURE_PAGE,
    LocalePath: ROUTE_CONSTANT.SIGNATURE_PAGE_LOCAL,
  },
  CoverLetterRecommend: {
    Component: <CoverLetterRecommend />,
    Path: ROUTE_CONSTANT.RECOMMEND_PAGE,
    LocalePath: ROUTE_CONSTANT.RECOMMEND_PAGE_LOCAL,
  },
  CoverLetterBodyRecommend: {
    Component: <CoverLetterBodyRecommend />,
    Path: ROUTE_CONSTANT.BODY_RECOMMEND_PAGE,
    LocalePath: ROUTE_CONSTANT.BODY_RECOMMEND_PAGE_LOCAL,
  },
  CoverLetterCloserRecommend: {
    Component: <CoverLetterCloserRecommend />,
    Path: ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE,
    LocalePath: ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE_LOCAL,
  },
  Closer: {
    Component: <CoverLetterCloserPage />,
    Path: ROUTE_CONSTANT.CLOSER,
    LocalePath: ROUTE_CONSTANT.CLOSER_LOCAL,
  },
  ExperienceTips: {
    Component: <ExperienceTips />,
    Path: ROUTE_CONSTANT.EXPERIENCE_TIPS,
    LocalePath: ROUTE_CONSTANT.EXPERIENCE_TIPS_LOCALE,
  },
  Experience: {
    Component: <Experience />,
    Path: ROUTE_CONSTANT.EXPERIENCE,
    LocalePath: ROUTE_CONSTANT.EXPERIENCE_LOCALE,
  },
  EducationTips: {
    Component: <EducationTips />,
    Path: ROUTE_CONSTANT.EDUCATION_TIPS,
    LocalePath: ROUTE_CONSTANT.EDUCATION_TIPS_LOCALE,
  },
  Education: {
    Component: <Education />,
    Path: ROUTE_CONSTANT.EDUCATION,
    LocalePath: ROUTE_CONSTANT.EDUCATION_LOCALE,
  },
  Skill: {
    Component: <Skill />,
    Path: ROUTE_CONSTANT.SKILLS,
    LocalePath: ROUTE_CONSTANT.SKILLS_LOCALE,
  },
  SkillTips: {
    Component: <SkillTips />,
    Path: ROUTE_CONSTANT.SKILLS_TIPS,
    LocalePath: ROUTE_CONSTANT.SKILLS_TIPS_LOCALE,
  },
  SummaryTips: {
    Component: <SummaryTips />,
    Path: ROUTE_CONSTANT.SUMMARY_TIPS,
    LocalePath: ROUTE_CONSTANT.SUMMARY_TIPS_LOCALE,
  },
  Summary: {
    Component: <Summary />,
    Path: ROUTE_CONSTANT.SUMMARY,
    LocalePath: ROUTE_CONSTANT.SUMMARY_LOCALE,
  },
  Review: {
    Component: <Review />,
    Path: ROUTE_CONSTANT.REVIEW,
    LocalePath: ROUTE_CONSTANT.REVIEW_LOCALE,
  },
  SignIn: {
    Component: <SignIn />,
    Path: ROUTE_CONSTANT.SIGNIN,
    LocalePath: ROUTE_CONSTANT.SIGNIN_LOCALE,
  },
  SignUp: {
    Component: <SignUp />,
    Path: ROUTE_CONSTANT.SIGNUP,
    LocalePath: ROUTE_CONSTANT.SIGNUP_LOCALE,
  },
  Language: {
    Component: <Language />,
    Path: ROUTE_CONSTANT.LANGUAGE,
    LocalePath: ROUTE_CONSTANT.LANGUAGES_LOCALE,
  },
  AdminLogin: {
    Component: <AdminLogin />,
    Path: ROUTE_CONSTANT.ADMIN_LOGIN,
  },
  Plans: {
    Component: <Plans />,
    Path: ROUTE_CONSTANT.PLANS,
    LocalePath: ROUTE_CONSTANT.PLANS_LOCALE,
  },
  ResetPassword: {
    Component: <ResetPassword />,
    Path: ROUTE_CONSTANT.RESET_PASSWORD,
    LocalePath: ROUTE_CONSTANT.RESET_PASSWORD_LOCALE,
  },
  ForgotPassword: {
    Component: <ForgotPassword />,
    Path: ROUTE_CONSTANT.FORGOT_PASSWORD,
    LocalePath: ROUTE_CONSTANT.FORGOT_PASSWORD_LOCALE,
  },
  CustomSection: {
    Component: <CustomSection />,
    Path: ROUTE_CONSTANT.CUSTOM_SECTION,
    LocalePath: ROUTE_CONSTANT.CUSTOM_SECTION_LOCALE,
  },
  Confirmation: {
    Component: <Confirmation />,
    Path: ROUTE_CONSTANT.THANK_YOU,
    LocalePath: ROUTE_CONSTANT.THANK_YOU_LOCALE,
  },

  CheckoutPage: {
    Component: <CheckoutPage />,
    Path: ROUTE_CONSTANT.CHECKOUT_PAGE,
    LocalePath: ROUTE_CONSTANT.CHECKOUT_PAGE_LOCALE,
  },
  Checkout: {
    Component: <Checkout />,
    Path: ROUTE_CONSTANT.CHECKOUT,
    LocalePath: ROUTE_CONSTANT.CHECKOUT_LOCALE,
  },

  CancelSubscription: {
    Component: <CancelSubscription />,
    Path: ROUTE_CONSTANT.CANCEL_SUBSCRIPTION,
    LocalePath: ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_LOCALE,
  },
  CancelSubscriptionConfirmation: {
    Component: <CancelSubscriptionConfirmation />,
    Path: ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_CONFIRMATION,
    LocalePath: ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_CONFIRMATION_LOCALE,
  },

  OtherSection: {
    Component: <OtherSection />,
    Path: ROUTE_CONSTANT.OTHER_SECTIONS,
    LocalePath: ROUTE_CONSTANT.OTHER_SECTIONS_LOCALE,
  },
  Resumes: {
    Component: <Resumes />,
    Path: ROUTE_CONSTANT.RESUMES,
    LocalePath: ROUTE_CONSTANT.RESUMES_LOCALE,
  },
  CoverLetters: {
    Component: <CoverLetters />,
    Path: ROUTE_CONSTANT.COVER_LETTERS,
    LocalePath: ROUTE_CONSTANT.COVER_LETTERS_LOCALE,
  },
  PageNotFound: {
    Component: <PageNotFound />,
    Path: ROUTE_CONSTANT.PAGE_NOT_FOUND,
    LocalePath: ROUTE_CONSTANT.PAGE_NOT_FOUND_LOCALE,
  },
  SessionExpire: {
    Component: <SessionExpire />,
    Path: ROUTE_CONSTANT.SESSION_EXPIRE,
    LocalePath: ROUTE_CONSTANT.SESSION_EXPIRE_LOCALE,
  },
  ChooseOptions: {
    Component: <ChooseOptions />,
    Path: ROUTE_CONSTANT.CHOOSE_OPTIONS,
    LocalePath: ROUTE_CONSTANT.CHOOSE_OPTIONS_LOCALE,
  },
  UploadResume: {
    Component: <UploadResume />,
    Path: ROUTE_CONSTANT.UPLOAD_RESUME,
    LocalePath: ROUTE_CONSTANT.UPLOAD_RESUME_LOCALE,
  },
  SelectedDocument: {
    Component: <SelectedDocument />,
    Path: ROUTE_CONSTANT.SELECTED_DOCUMENT,
    LocalePath: ROUTE_CONSTANT.SELECTED_DOCUMENT_LOCALE,
  },
  ApplyTemplate: {
    Component: <ApplyTemplate />,
    Path: ROUTE_CONSTANT.APPLY_TEMPLATE,
    LocalePath: ROUTE_CONSTANT.APPLY_TEMPLATE_LOCALE,
  },
};
