import DocBuilderService from "../../services/doc-builder-api";

const duplicateCoverLetter = (docGuid, dupDocInfo) => () => {
  return DocBuilderService.duplicateDoc(docGuid, dupDocInfo)
    .then((response) => {
      if (response) {
        //DocBuilderService.updateUserGuid(docGuid, userGUID);
      } else {
      }
      return response;
    })
    .catch((error) => {});
};
const deleteCoverLetter = (docGuid) => () => {
  return DocBuilderService.deleteDoc(docGuid)
    .then((response) => {
      if (response) {
      } else {
      }
      return response;
    })
    .catch((error) => {});
};

export { duplicateCoverLetter, deleteCoverLetter };
