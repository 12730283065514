import React, { useState, useEffect } from "react";
import TempHeader from "../../temp-header";
import TempPersonalInfo from "../../temp-personal-info";
import TempSummary from "../../temp-summary";
import TempExperience from "../../temp-experience";
import TempSkills from "../../temp-skills";
import TempEducation from "../../temp-education";
import TempCustom from "../../temp-custom";
import TempLanguages from "../../temp-languages";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Cv20NequeStyle } from "./cv20-neque.style";

// import "./cv20.styles.scss";

const Cv20Neque = (
  {
    data,
    headingfontsize,
    docReview,
    resumeTemplate,
    resumePersonalInfo,
    paragraphOrdering,
    resumeOnDashboard,
    toggleRenameModal,
    toggleDeleteModal,
    setSelectedRenameSection,
    componentRef,
    thumbMouseOver,
    fontSize,
    fontFamily,
  },
  ref
) => {
  const { reviewDoc } = data;
  const [docSectionDetail, setDocSectionDetail] = useState(
    reviewDoc?.paragraphs || []
  );

  const SelectiveSection = {
    HEAD: TempHeader,
    SUMM: TempSummary,
    EXPR: TempExperience,
    SKLS: TempSkills,
    EDUC: TempEducation,
    CSTM: TempCustom,
    LANG: TempLanguages,
    OTHR: TempCustom,
  };
  //const [characters, updateCharacters] = useState(docSectionDetail);
  let filterData = data.filter((x) => x.paraCD != "NONE");

  useEffect(() => {
    if (!!data && data.length > 0) {
      let filterData = data.filter((x) => x.paraCD != "NONE");
      updateCharacters(filterData);
    }
  }, [JSON.stringify(data)]);

  // Single
  const [characters, updateCharacters] = useState(filterData);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === 0) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let list = [];
    items.map((attr) =>
      list.push({
        order: attr.order,
        paraid: attr.id,
      })
    );
    list.map((currElement, index) => {
      currElement.order = index;
    });
    updateCharacters(items);
    paragraphOrdering(JSON.stringify(list));
  };

  return (
    <>
      <div className="resume-wrapper" ref={!thumbMouseOver && componentRef}>
        <Cv20NequeStyle />
        <div className="resume">
          <div
            className="resume-layout cv20 double left"
            style={{
              fontFamily: fontFamily,
              fontSize: fontSize,
            }}
          >
            <div className="tripple-column resume-layout-box">
              <div className="single-column">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable
                    droppableId="characters"
                    isDropDisabled={resumeOnDashboard}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {characters?.map((item, index) => {
                          //debugger;
                          if (
                            item.paraContents &&
                            item.paraContents.length > 0
                          ) {
                            const Section = SelectiveSection[item.paraCD];
                            {
                              Object.assign(item.paraContents[0], {
                                headingfontsize:
                                  headingfontsize +
                                  (headingfontsize ? "em" : ""),
                                resumeOnDashboard: resumeOnDashboard,
                                fontFamily: fontFamily,
                              });
                            }

                            return (
                              <Draggable
                                key={item.paraCD + index}
                                draggableId={item.paraCD + index}
                                index={index}
                                isDragDisabled={
                                  item.paraCD === "HEAD" || resumeOnDashboard
                                }
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <Section
                                      // key={item.paraCD + index}
                                      headingfontsize={headingfontsize}
                                      fontFamily={fontFamily}
                                      templateType={resumeTemplate}
                                      data={item.paraContents}
                                      section={item.paraCD}
                                      paraName={item.paraName}
                                      docGuid={item.docGuid}
                                      paraId={item.id}
                                      resumeOnDashboard={resumeOnDashboard}
                                      toggleRenameModal={toggleRenameModal}
                                      toggleDeleteModal={toggleDeleteModal}
                                      setSelectedRenameSection={
                                        setSelectedRenameSection
                                      }
                                      dragHandleProps={provided.dragHandleProps}
                                      dragStart={provided.draggableProps}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cv20Neque;
