import React, { useEffect, useState, useCallback } from "react";
import RBDropDown from "../shared/dropdown/component";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import "./resumes-action.scss";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import moment from "moment";
import { availableLanguages } from "../../i18nextInit";
import { getTenantId } from "../../utils/general-config";
import { readCookie } from "../../utils/helpers";

const ResumesAction = ({
  selectedResume,
  numberOfResumes,
  resetStoreConnect,
  duplicateResumeConnect,
  callbackduplicate,
  deleteResumeConnect,
  callbackdelete,
  toggleRenameModal,
  downloadResumeModal,
  printResumeCallBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  const nextPage = (resumesAction) => {
    if (resumesAction === "CreateNew") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_TEMPLATE_LOCALE) : ROUTE_CONSTANT.CHOOSE_TEMPLATE;
    } else if (resumesAction === "Edit") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
    } else if (resumesAction === "Delete") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
    }
  };
  const editResumeHandler = () => {
    navigate(nextPage("Edit"));
  };
  const duplicateResumeHandler = () => {
    let dupDocInfo = {
      visitorId: readCookie("visitorGuid"),
      tenantId: getTenantId(),
    };
    duplicateResumeConnect(selectedResume.id, dupDocInfo).then((response) => {
      if (!!response) {
        callbackduplicate(selectedResume.name, response);
      }
      window.scrollTo(0, 0);
    });
  };
  const deleteResumeHandler = () => {
    if (window.confirm(t("resumes.alert_message"))) {
      deleteResumeConnect(selectedResume.id).then((response) => {
        callbackdelete(selectedResume.name);
        window.scrollTo(0, 0);
        if (numberOfResumes === 1) {
          resetStoreConnect();
          navigate(nextPage("Delete"));
        }
      });
    }
  };
  const downloadResumeHandler = () => {};
  const printResumeHandler = (e) => {
    const {
      target: { id },
    } = e;

    printResumeCallBack(id);
  };
  const createResumeHandler = () => {
    resetStoreConnect();
    navigate(nextPage("CreateNew"));
  };
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const returnFormattedDate = (dateValue) => {
    let dateObj = new Date(dateValue);
    let localeValue = "en-US";
    if (!!locale) {
      localeValue = locale;
    }
    return dateObj.toLocaleDateString(localeValue, options);
  };
  return (
    <>
      <span className="dashboard-item__title">
        {selectedResume.name}
        <Button
          id="resume-rename-btn"
          isLoading={false}
          disabled={false}
          onClick={toggleRenameModal}
          icon={<i className="fa fa-pencil"></i>}
          classNames="muted"
          ctaText=""
        />
      </span>
      <span className="dashboard-item__edited">
        {t("resumes.last_edited_on")} {returnFormattedDate(selectedResume.modifiedOn)}
      </span>
      <div className="dashboard-item__actions">
        <Button
          id="resume-edit-button"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-pencil"></i>}
          onClick={editResumeHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("resumes.edit_resume")}
        />
        <Button
          id="resume-duplicate-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-copy"></i>}
          onClick={duplicateResumeHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("resumes.duplicate_resume")}
        />
        <Button
          id="resume-delete-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-trash"></i>}
          onClick={deleteResumeHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("resumes.delete_resume")}
        />
      </div>
      <div className="dashboard-item__actions">
        <Button
          id="resume-download-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-download"></i>}
          onClick={downloadResumeModal}
          classNames="btn btn--icon btn-green btn-lg "
          ctaText={t("resumes.download_resume")}
        />
        <Button
          id="resume-print-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-print"></i>}
          onClick={printResumeHandler}
          classNames="btn btn--icon btn-green btn-lg"
          ctaText={t("resumes.print_resume")}
        />
      </div>
      <Button
        isLoading={false}
        disabled={false}
        onClick={createResumeHandler}
        id="create-new-btn"
        classNames="btn btn-primary btn-lg create-resume"
        ctaText={t("resumes.create_resume")}
      />
    </>
  );
};

export default ResumesAction;
