import { createReducer } from "reduxsauce";
import { DOC_TYPE } from "../../utils/constants";
import Types from "./types";

export const INITIAL_STATE = {
  templateCd: "",
  currentStep: "",
  paraCount: 5,
  docGUID: "",
  userGUID: "",
  email: "",
  funnelStepsComplete: false,
  currentPara: "",
  currentParaId: "",
  personalInfoName: "",
  parentUserGuid: "",
  queryStringsInfo: {},
  docType: DOC_TYPE.RESUME,
  imageUrl: "",
};

export const quack = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const setFunnelStepComplete = (
  state = INITIAL_STATE,
  { funnelStepsComplete }
) => {
  return {
    ...state,
    funnelStepsComplete,
  };
};

export const setCurrentStep = (state = INITIAL_STATE, { currentStep }) => {
  return {
    ...state,
    currentStep,
  };
};
export const setCurrentPara = (
  state = INITIAL_STATE,
  { currentPara, currentParaId }
) => {
  return {
    ...state,
    currentPara,
    currentParaId,
  };
};
export const setTemplate = (state = INITIAL_STATE, { templateCd }) => {
  return {
    ...state,
    templateCd,
  };
};

export const setDocGUID = (state = INITIAL_STATE, { docGUID }) => {
  return {
    ...state,
    docGUID,
  };
};
export const setDocType = (state = INITIAL_STATE, { docType }) => {
  return {
    ...state,
    docType,
  };
};
export const setUserInformation = (
  state = INITIAL_STATE,
  { userGUID, email, parentUserGuid }
) => {
  return {
    ...state,
    userGUID,
    email,
    parentUserGuid,
  };
};
export const setName = (state = INITIAL_STATE, { personalInfoName }) => {
  return {
    ...state,
    personalInfoName,
  };
};
export const resetFunnelSteps = (state = INITIAL_STATE, param) => {
  if (!!param && param.isUserGuid == true) {
    const newState = {
      ...state,
      templateCd: "",
      currentStep: "",
      paraCount: 5,
      docGUID: "",
      funnelStepsComplete: false,
      currentPara: "",
      currentParaId: "",
      userGUID: "",
      email: "",
      personalInfoName: "",
    };
    return { ...newState };
  } else {
    const newState = {
      ...state,
      templateCd: "",
      currentStep: "",
      paraCount: 5,
      docGUID: "",
      funnelStepsComplete: false,
      currentPara: "",
      currentParaId: "",
      personalInfoName: "",
    };
    return { ...newState };
  }
};
export const setQueryStringInfo = (
  state = INITIAL_STATE,
  { queryStringsInfo }
) => {
  return {
    ...state,
    queryStringsInfo,
  };
};
export const resetFolderQueryStringInfo = (state = INITIAL_STATE) => {
  return {
    ...state,
    queryStringsInfo: { ...state.queryStringsInfo, fp: "" },
  };
};

export const HANDLERS = {
  [Types.SET_CURRENTSTEP]: setCurrentStep,
  [Types.SET_CURRENT_PARA]: setCurrentPara,
  [Types.SET_TEMPLATE]: setTemplate,
  [Types.SET_DOCGUID]: setDocGUID,
  [Types.SET_USERINFO]: setUserInformation,
  [Types.SET_FUNNEL_STEP_COMPLETE]: setFunnelStepComplete,
  [Types.RESET_FUNNEL_STEPS]: resetFunnelSteps,
  [Types.SET_NAME]: setName,
  [Types.SET_QUERYSTRING_INFO]: setQueryStringInfo,
  [Types.RESET_FOLDER_QUERYSTRING]: resetFolderQueryStringInfo,
  [Types.SET_DOC_TYPE]: setDocType,
};

export default createReducer(INITIAL_STATE, HANDLERS);
