import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import UserService from "../../services/user-service-api";
// import FunnelStepTypes from "../education/types";
// import FunnelStepsTypes from "../../funnel-step/types";
import FunnelStepsTypes from "../../pages/funnel-step/types";

const getUserDocs = (userguid) => {
  return function () {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        if (response) {
        }
        return response;
      })
      .catch((error) => {});
  };
};

const getUserCVs = (userguid) => {
  return function () {
    return DocBuilderService.findCvsByUserGuid(userguid)
      .then((response) => {
        if (response) {
        }
        return response;
      })
      .catch((error) => {});
  };
};

const getUserObject = () => {
  return function (dispatch) {
    return UserService.getUserObject()
      .then((response) => {
        if (!!response) {
          let userGuid = response.userGuid;
          let email = response.email;
          var validUserGuid = userGuid;
          if (userGuid.includes("-") == false) {
            validUserGuid = userGuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
          }
          let parentUserGuid = response.parentUserGuid;
          dispatch(PushDataToUserInfo(validUserGuid, email, parentUserGuid));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const PushDataToUserInfo = (userGUID, email, parentUserGuid) => ({
  type: FunnelStepsTypes.SET_USERINFO,
  userGUID,
  email,
  parentUserGuid,
});
const getUserDocumentAttributes = (userguid, typeofdoc) => {
  return function () {
    return DocBuilderService.getUserDocumentAttributes(userguid, typeofdoc)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
};
export { getUserDocs, getUserObject, getUserCVs, getUserDocumentAttributes };
