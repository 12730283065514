import { CreateUUID, deleteCookie, readCookie } from "./utils/helpers";
import DocBuilderService from "./services/doc-builder-api";
import appLogger from "./utils/app-logger";
import { EMPTY_GUID, DOC_STAGE, SECTION_TYPE, SECTION_ORDER } from "./utils/constants";
import moment from "moment";
import { blobSignature, downloadCounter } from "./utils/general-config";
import axios from "axios";

export default {
  Doc: {
    id: CreateUUID(),
    Name: "DocPoC1",
    DocStage: "PoC",
    createdOn: moment(new Date()),
    modifiedOn: "2022-04-10T05:21:26.171Z",
    tenantId: EMPTY_GUID,
    userId: EMPTY_GUID,
    typeOfDoc: 0,
    Paragraphs: [
      {
        id: EMPTY_GUID,
        docId: EMPTY_GUID,
        paraContents: [
          {
            id: EMPTY_GUID,
            paraId: EMPTY_GUID,
            content: "",
            order: 0,
            createdOn: "2022-04-10T05:21:26.171Z",
            modifiedOn: "2022-04-10T05:21:26.171Z",
          },
        ],
        order: 0,
        paraCD: "HEAD",
        sectionCD: 0,
      },
    ],
    docStyle: {},
    originalDocId: EMPTY_GUID,
    templateCD: "default",
  },
};
export function emptyParagraph(sectionCode, paraCD) {
  let ret = {
    id: EMPTY_GUID,
    docId: EMPTY_GUID,
    // paraContents: [
    //   {
    //     id: EMPTY_GUID,
    //     paraId: EMPTY_GUID,~
    //     Content: "",
    //     order: 0,
    //     createdOn: "2022-04-10T05:21:26.171Z",
    //     modifiedOn: "2022-04-10T05:21:26.171Z",
    //   },
    // ],
    paraContents: [],
    order: 0,
    paraCD: paraCD,
    sectionCD: sectionCode,
    paraName: "",
  };
  return ret;
}

export function emptyDocument(documentName, headerContent, templateCode, userId, tenantId, visitorGuid, folderPath) {
  let ret = {
    id: EMPTY_GUID,
    Name: documentName,
    DocStage: DOC_STAGE.HEAD,
    createdOn: moment(new Date()),
    modifiedOn: moment(new Date()),
    tenantId: !!tenantId ? tenantId : EMPTY_GUID,
    userId: !!userId ? userId : EMPTY_GUID,
    typeOfDoc: 0,
    folderPath: folderPath,
    Paragraphs: [
      {
        id: EMPTY_GUID,
        docId: EMPTY_GUID,
        paraName: "",
        paraContents: [
          {
            id: EMPTY_GUID,
            paraId: EMPTY_GUID,
            content: headerContent,
            order: 0,
            createdOn: moment(new Date()),
            modifiedOn: moment(new Date()),
          },
        ],
        order: SECTION_ORDER.NONE,
        paraCD: SECTION_TYPE.NONE,
        sectionCD: 0,
      },
    ],
    docStyle: {},
    originalDocId: EMPTY_GUID,
    templateCD: templateCode,
    VisitorId: visitorGuid,
  };
  return ret;
}

export function createParagraph(docId, paraId, contentId, paraContent, order, paraCD, sectionCD, paraName) {
  let ret = {
    id: paraId,
    docId: docId,
    paraContents: [
      {
        id: contentId,
        paraId: paraId,
        content: paraContent,
        order: 0,
        createdOn: moment(new Date()),
        modifiedOn: moment(new Date()),
      },
    ],
    order: order,
    paraCD: paraCD,
    sectionCD: sectionCD,
    paraName: paraName,
  };
  return ret;
}

export function downloadUserResume(
  docGuid,
  conversionType,
  conversionMethod,
  toggleDownloadLoaderModal,
  downloadErrorMessage,
  isRequestForPrint
) {
  console.log(isRequestForPrint);
  if (docGuid != undefined) {
    let regenerateDoc = false;
    if (!!readCookie("regenerateDoc")) {
      regenerateDoc = readCookie("regenerateDoc");
    }
    return DocBuilderService.downloadDocument(docGuid, conversionType, conversionMethod, "v2", regenerateDoc)
      .then((response) => {
        if (response) {
          if (response.conversionId !== "") {
            appLogger.log("conversionId ", response.conversionId);
            let timer = 0;
            var e = setInterval(() => {
              timer += 1;
              getConversionStatus(
                docGuid,
                response.conversionId,
                toggleDownloadLoaderModal,
                downloadErrorMessage,
                isRequestForPrint
              ).then((responseStatus) => {
                if (!!responseStatus && responseStatus.conversionStatus == "COMPLETED") {
                  clearInterval(e);
                  downloadErrorMessage("");
                  toggleDownloadLoaderModal();
                  deleteCookie("regenerateDoc");

                  if (
                    responseStatus.docDownload.conversionType == "DOC" ||
                    responseStatus.docDownload.conversionType == "DOCX"
                  ) {
                    downloadDOC(responseStatus.docDownload);
                  } else {
                    downloadPDF(responseStatus.docDownload, isRequestForPrint);
                  }
                } else if (
                  !!responseStatus &&
                  (responseStatus.conversionStatus == "NONE" || responseStatus.conversionStatus == "ERROR")
                ) {
                  downloadErrorMessage("error");
                  clearInterval(e);
                } else if (responseStatus == null || responseStatus == undefined) {
                  downloadErrorMessage("error");
                  clearInterval(e);
                } else if (timer === downloadCounter) {
                  downloadErrorMessage("error");
                  clearInterval(e);
                }
              });
            }, 2000);
          }
          // dispatch(getDownloadResumeSuccess(response.data));
        } else {
          //dispatch(getDownloadResumeFailure(response.data));
        }
        return response;
      })
      .catch((error) => {
        downloadErrorMessage("error");
        appLogger.log("inside Download Resume error", error);
        //dispatch(getDownloadResumeFailure(error));
      });
  } else {
    appLogger.log("Invalid Document Id");
  }
}

export function getConversionStatus(documentId, conversionId) {
  return DocBuilderService.conversionStatus(documentId, conversionId)
    .then((response) => {
      if (response) {
      }
      return response;
    })
    .catch((error) => {
      appLogger.log("inside Conversion Resume error", error);
    });
}

const downloadDOC = async (docDownload) => {
  const respByteArray = await downloadUsingBlobPath(docDownload.documentBlobPath);
  let blob = new Blob([respByteArray], { type: "application/msword" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // let byteChar = atob(docDownload.document);
    // let byteArray = new Array(byteChar.length);
    // for (let i = 0; i < byteChar.length; i++) {
    //   byteArray[i] = byteChar.charCodeAt(i);
    // }
    // let uIntArray = new Uint8Array(byteArray);
    // let blob = new Blob([uIntArray], { type: "application/msword" });
    window.navigator.msSaveOrOpenBlob(blob, `${docDownload.documentName}.doc`);
  } else {
    //const source = `data:application/msword;base64,${docDownload.document}`;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${docDownload.documentName}.doc`;
    link.click();
  }
};
const downloadUsingBlobPath = async (documentBlobPath) => {
  //download single file as blob
  const resp = await axios.get(documentBlobPath + blobSignature(), {
    responseType: "blob",
  });
  //return array buffer of blob file as we want to modify the file name
  return await resp.data.arrayBuffer();
};

const downloadPDF = async (docDownload, isRequestForPrint) => {
  const respByteArray = await downloadUsingBlobPath(docDownload.documentBlobPath);
  var blob = new Blob([respByteArray], { type: "application/pdf" });
  // let byteChar = atob(docDownload.document);
  // let byteArray = new Array(byteChar.length);
  // for (let i = 0; i < byteChar.length; i++) {
  //   byteArray[i] = byteChar.charCodeAt(i);
  // }
  // let uIntArray = new Uint8Array(byteArray);
  // var blob = new Blob([uIntArray], {
  //   type: "application/pdf",
  // }); // change resultByte to bytes
  if (isRequestForPrint) {
    let pdfUrl = URL.createObjectURL(blob);
    if (!!pdfUrl) {
      let printwWindow = window.open(pdfUrl);
      printwWindow.print();
    }
    //TODO - Message for print error
  } else {
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${docDownload.documentName}.pdf`;
    link.click();
  }
};
