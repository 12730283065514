import React from "react";

const IconDropBox = ({
  width = 190,
  height = 46,
  className = { className },
}) => {
  return (
    <>
      <svg
        id="Drop_Box"
        data-name="Drop Box"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 197.747 38.921"
        className={className}
        width={width}
        height={height}
      >
        <path
          id="Path_119"
          data-name="Path 119"
          d="M11.428,31.62l11.463,7.3,11.461-7.3-11.461-7.3ZM11.463,0,0,7.3l11.463,7.3L0,21.9l11.463,7.3,11.461-7.3L11.463,14.6,22.925,7.3ZM34.386,0,22.925,7.3l11.461,7.3L22.925,21.9l11.461,7.3,11.461-7.3L34.386,14.6,45.847,7.3Z"
          fill="#006aff"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M26.206,4.5h9.279c5.935,0,10.779,3.445,10.779,11.256v1.671c0,7.847-4.571,11.394-10.643,11.394H26.206Zm5.186,4.4V24.358h3.991c3.343,0,5.629-2.217,5.629-7.1V16c0-4.876-2.353-7.093-5.8-7.093Zm17.636.238h4.127l.648,4.571c.784-3.138,2.8-4.742,6.447-4.742h1.261v5.219H59.4c-4.23,0-5.253,1.466-5.253,5.629v9.005H49.028ZM62.6,19.411v-.546c0-6.617,4.23-10.164,9.959-10.164,5.868,0,9.928,3.615,9.928,10.164v.546c0,6.481-3.991,9.926-9.928,9.926-6.343,0-9.959-3.445-9.959-9.926m14.7-.067v-.479c0-3.684-1.841-5.832-4.742-5.832-2.864,0-4.774,2.012-4.774,5.832v.479c0,3.548,1.807,5.525,4.74,5.525s4.776-1.943,4.776-5.525m7.88-10.2h4.3l.51,3.751A6.625,6.625,0,0,1,96.478,8.7c5.014,0,8.288,3.615,8.288,10.233v.546c0,6.481-3.65,9.857-8.288,9.857-3.14,0-5.219-1.4-6.278-3.684v9.928H85.186Zm14.4,10.233v-.443c0-3.922-2.012-5.868-4.709-5.868-2.864,0-4.776,2.184-4.776,5.834v.374c0,3.479,1.843,5.663,4.673,5.663,2.935,0,4.811-1.945,4.811-5.56M112.3,25.245l-.41,3.581H107.46V2.561h5.016v10.1A6.4,6.4,0,0,1,118.854,8.7c4.673.033,8.152,3.274,8.152,9.823V19.1c0,6.55-3.274,10.233-8.288,10.233a6.454,6.454,0,0,1-6.414-4.092m9.551-6.142v-.477c0-3.615-1.979-5.594-4.707-5.594-2.832,0-4.776,2.284-4.776,5.663V19.1c0,3.686,1.876,5.8,4.707,5.8,2.968,0,4.776-1.912,4.776-5.8m7.437.308v-.546c0-6.617,4.2-10.164,9.926-10.164,5.868,0,9.961,3.615,9.961,10.164v.546c0,6.481-4.025,9.926-9.961,9.926-6.345,0-9.926-3.445-9.926-9.926m14.7-.067v-.479c0-3.684-1.841-5.832-4.74-5.832-2.866,0-4.776,2.012-4.776,5.832v.479c0,3.548,1.807,5.525,4.74,5.525,2.968,0,4.776-1.943,4.776-5.525m11.871-.718-6.891-9.482h5.9l3.991,5.9,4.025-5.9h5.868l-6.993,9.449,7.368,10.233h-5.8L158.8,22.31l-4.4,6.516h-6Z"
          transform="translate(28.613 2.796)"
          fill="#1a1a1a"
        />
      </svg>
    </>
  );
};

export default IconDropBox;
