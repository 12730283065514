import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  imageUrl: "",
};

export const setImageUrl = (state = INITIAL_STATE, { imageUrl }) => {
  return {
    ...state,
    imageUrl,
  };
};
export const resetImageURl = () => {
  return { ...INITIAL_STATE };
};
export const HANDLERS = {
  [Types.SET_IMAGE_URL]: setImageUrl,
  [Types.RESET_IMAGE_URL]: resetImageURl,
};

export default createReducer(INITIAL_STATE, HANDLERS);
