import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SKILL_SELECTION_LIMIT } from "../../../utils/constants";

const ChipBox = ({
  SkillList,
  selectedSkills,
  setSelectedSkills,
  setNewSkillList,
  newSkillList,
}) => {
  const [newSkill, setNewSkill] = useState("");
  const [oldSkill, setOldSkill] = useState("");
  const [updateSkill, setUpdateSkill] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showInput, setHideInput] = useState(false);
  const [requestHandler, setRequestHandler] = useState("add");
  const [inputBoxIndex, setinputBoxIndex] = useState("");
  const [showEditInput, setShowEditInput] = useState(false);
  const [checkInputChange, setCheckChangeInput] = useState(true);
  const { t } = useTranslation();

  const handleClick = (item) => {
    const array = [...selectedSkills];
    const index = array.indexOf(item.skillTitle);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      if (array.length >= SKILL_SELECTION_LIMIT) {
        array.splice(0, 1);
      }
      array.push(item.skillTitle);
    }

    setSelectedSkills(array);
  };

  const handleSelected = (item) => {
    if (selectedSkills.length > 0) {
      if (selectedSkills.includes(item.skillTitle)) {
        return "cvltr-chip cvltr-activechip";
      }
    }

    return "cvltr-chip";
  };

  const getImage = (item) => {
    if (selectedSkills.length > 0) {
      if (selectedSkills.includes(item.skillTitle)) {
        return "/static/images/ic_minus_white.svg";
      }
    }

    return "/static/images/ic_plus.svg";
  };

  const handleInput = (e) => {
    let value = e.target.value.replace(/(<([^>]+)>)/ig, '');

    if (value == "") {
      setCheckChangeInput(true);
    } else {
      setCheckChangeInput(false);
    }

    setNewSkill(value);
    setErrorMessage("");
  };

  const addSkills = () => {
    let valid = handleValidation();

    if (valid.isValid) {
      let array = [...newSkillList];
      let selectedArray = [...selectedSkills];
      let obj = { skillTitle: newSkill };

      array.push(obj);
      selectedArray.push(newSkill);

      handleClick(obj);
      setNewSkillList(array);
      setNewSkill("");
      setShowEditInput(false);

      return true;
    } else {
      setErrorMessage(valid.message);
      return false;
    }
  };

  const removeItem = (item) => {
    let array = [...newSkillList];
    let index = array.indexOf(item);

    if (index > -1) {
      array.splice(index, 1);
    }

    setNewSkillList(array);
    setErrorMessage("");
    setNewSkill("");
    setHideInput(false);
    setOldSkill("");
  };

  const editItem = () => {
    let valid = handleValidation();
    let array = [...newSkillList];
    let selectedArray = [...selectedSkills];
    let index = selectedArray.indexOf(oldSkill);

    if (valid.isValid) {
      if (updateSkill !== "") {
        array[updateSkill].skillTitle = newSkill;
        selectedArray[index] = newSkill;
      }

      setSelectedSkills(selectedArray);
      setNewSkillList(array);
      setUpdateSkill("");
      setErrorMessage("");
      setNewSkill("");
      setHideInput(false);
      setOldSkill("");
      setinputBoxIndex("");
      setShowEditInput(false);

      return true;
    } else {
      setErrorMessage(valid.message);
      return false;
    }
  };

  const handleValidation = () => {
    let valid = {
      isValid: false,
      message: "",
    };

    if (checkInputChange) {
      setHideInput(false);
      setShowEditInput(false);
    }

    if (newSkill.trim() == "") {
      valid.isValid = false;
      // valid.message = t("opener.skill_required");
    } else {
      valid.isValid = true;
      valid.message = "";
    }

    const value = newSkill.toLocaleLowerCase();
    const list = [...newSkillList, ...SkillList];

    if (newSkill.trim() !== "") {
      if (
        list.filter(
          (f, index) =>
            index !== inputBoxIndex &&
            f.skillTitle.toLocaleLowerCase() === value
        ).length > 0
      ) {
        valid.isValid = false;
        valid.message = t("opener.skill_validation");
      } else {
        valid.isValid = true;
        valid.message = "";
      }
    }

    return valid;
  };

  const handleAddSkillInput = () => {
    setinputBoxIndex("");
    setNewSkill("");
    setRequestHandler("add");
    setHideInput(true);
    setErrorMessage("");

    if (newSkillList.length >= 1) {
      setShowEditInput(true);
    } else {
      setShowEditInput(false);
    }
    // if (newSkillList.length >= SKILL_SELECTION_LIMIT) {
    //   setSkillLimitError(t("opener.skill_validation_limit"));
    //   setTimeout(() => {
    //     setSkillLimitError("");
    //   }, 2000);
    // } else {
    //   setinputBoxIndex("");
    //   setNewSkill("");
    //   setRequestHandler("add");
    //   setHideInput(true);
    //   setErrorMessage("");

    //   if (newSkillList.length >= 1) {
    //     setShowEditInput(true);
    //   } else {
    //     setShowEditInput(false);
    //   }
    // }
  };

  const handleBlurEvent = () => {
    if (requestHandler == "add") {
      if (addSkills()) {
        setHideInput(false);
        setCheckChangeInput(true);
      }
    } else {
      editItem();
      setCheckChangeInput(true);
    }
  };

  const editHandler = (item, index) => {
    setErrorMessage("");
    setShowEditInput(false);
    setUpdateSkill(index);
    setRequestHandler("edit");
    setHideInput(true);
    setNewSkill(item.skillTitle);
    setOldSkill(item.skillTitle);
    setinputBoxIndex(index);
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        {SkillList.length > 0 &&
          SkillList.map((item) => {
            return (
              <>
                <div
                  key={item}
                  className={handleSelected(item)}
                  onClick={() => handleClick(item)}
                >
                  <a
                    href="javscript:void(0)"
                    onClick={(e) => e.preventDefault()}
                    className="d-flex align-items-center"
                  >
                    <img
                      src={process.env.PUBLIC_URL + getImage(item)}
                      className="me-2"
                    />
                    {item.skillTitle}
                  </a>
                </div>
              </>
            );
          })}
      </div>

      <div className="col-md-8 d-flex flex-wrap cvltr-chipadd">
        <label
          htmlFor="inputEmail4"
          className="form-label me-3 d-flex align-items-center"
          onClick={handleAddSkillInput}
        >
          <span className="cvltr-skilladd me-3">
            <i className="cvltr-addimg"></i>
          </span>
          {t("opener.add_skills")}
        </label>
        {newSkillList.length == 0 && showInput && (
          <>
            <div>
              <div className="d-flex cvltr-add-skill-input mb-1">
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder={t("opener.enter_skill")}
                  onBlur={handleBlurEvent}
                  value={newSkill}
                  autoComplete="off"
                  onChange={handleInput}
                  autoFocus
                  maxLength="30"
                />
                <span className="input-group-text  border-end-0 px-3 border-start-0 border-light bg-transparent mb-1">
                  <i className="cvltr-addimg"></i>
                </span>
              </div>
              <div className="text-danger my-3">{errorMessage}</div>
            </div>
          </>
        )}
      </div>
      <div className="d-flex flex-wrap mt-3">
        {newSkillList.length > 0 &&
          newSkillList.map((item, index) => {
            return (
              <>
                {inputBoxIndex === index && showInput ? (
                  <div className="me-3 cvltr-chipadd">
                    <div className="d-flex cvltr-add-skill-input mb-1">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeholder={t("opener.enter_skill")}
                        onBlur={handleBlurEvent}
                        value={newSkill}
                        autoComplete="off"
                        onChange={handleInput}
                        autoFocus
                        maxLength="30"
                      />
                      <span className="input-group-text  border-end-0 px-3 border-start-0 border-light bg-transparent mb-1">
                        <i className="cvltr-addimg"></i>
                      </span>
                    </div>
                    <div className="text-danger my-3">{errorMessage}</div>
                  </div>
                ) : (
                  <>
                    <div
                      key={item}
                      className={handleSelected(item)}
                      onClick={() => handleClick(item)}
                    >
                      <a
                        href="javscript:void(0)"
                        onClick={(e) => e.preventDefault()}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={process.env.PUBLIC_URL + getImage(item)}
                          className="me-2"
                        />
                        {item.skillTitle}
                      </a>
                    </div>
                    <div className={"mt-2 me-3"}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => editHandler(item, index)}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/static/images/icedit.svg"
                          }
                          className="cvltr-edit me-3"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => removeItem(item)}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/static/images/ic_delete_blue.svg"
                          }
                          className="cvltr-delete me-3"
                        />
                      </a>
                    </div>
                  </>
                )}
              </>
            );
          })}
        {showEditInput && (
          <div className="col-md-8 cvltr-chipadd">
            <div className="d-flex cvltr-add-skill-input mb-1">
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                placeholder={t("opener.enter_skill")}
                onBlur={handleBlurEvent}
                value={newSkill}
                onChange={handleInput}
                autoComplete="off"
                autoFocus
                maxLength="30"
              />
              <span className="input-group-text  border-end-0 px-3 border-start-0 border-light bg-transparent mb-1">
                <i className="cvltr-addimg"></i>
              </span>
            </div>
            <div className="text-danger my-3">{errorMessage}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChipBox;
