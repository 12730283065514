import React from "react";
import "./education-list.scss";
import PathHelper from "../../utils/path-helper";
import { useNavigate } from "react-router-dom";
import { APP_NAVIGATION } from "../../utils/constants";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const EducationList = ({
  paraContents,
  UpdateParaContent,
  UpdateEducationList,
}) => {
  const { t } = useTranslation();
  let pathRedirect = PathHelper(APP_NAVIGATION.EDUL);
  let pathscrollRedirect = PathHelper(APP_NAVIGATION.TEDU);
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const UpdateData = (id) => {
    UpdateParaContent(id);
  };
  const DeleteList = (id, i) => {
    UpdateEducationList(id, i);
  };
  return (
    <>
      {!!paraContents &&
        paraContents.length > 0 &&
        paraContents
          .sort((a, b) => a.order - b.order)
          .map(
            (item, index) =>
              item.content && (
                <div className="added-item" key={index}>
                  <div className="added_item__details">
                    <span className="added-item__title">
                      {!!JSON.parse(item.content)?.school_name &&
                        JSON.parse(item.content)?.school_name}
                      {JSON.parse(item.content)?.degree &&
                        JSON.parse(item.content)?.degree !== "Custom Degree" &&
                        ", " + JSON.parse(item.content)?.degree}
                      {JSON.parse(item.content)?.degree &&
                        JSON.parse(item.content)?.degree === "Custom Degree" &&
                        ", " + JSON.parse(item.content)?.custom_degree}
                    </span>
                    <span className="added-item__date">
                      {!JSON.parse(item.content)?.presently_here &&
                        JSON.parse(item.content)?.graduationMonthLabel}
                      {!JSON.parse(item.content)?.presently_here &&
                        JSON.parse(item.content)?.education_graduation_year &&
                        ". " +
                          JSON.parse(item.content)?.education_graduation_year}
                      {JSON.parse(item.content)?.presently_here &&
                        t("experience.label_Present")}
                    </span>
                  </div>
                  <div className="added-item__actions">
                    <HashLink
                      smooth
                      to={pathscrollRedirect.next + "#edu-scroll-container"}
                    >
                      {" "}
                      <Button
                        isLoading={false}
                        disabled={false}
                        onClick={() => UpdateData(item.id)}
                        titleName={t("education.edit")}
                        icon={<i className="fa fa-pencil"></i>}
                        classNames="muted added-item__action"
                        ctaText=""
                      />
                    </HashLink>
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={() => DeleteList(item.id, index)}
                      titleName={t("education.destroy")}
                      icon={<i className="fa fa-trash"></i>}
                      classNames="muted added-item__action disable-resubmit"
                      ctaText=""
                    />
                  </div>
                </div>
              )
          )}
    </>
  );
};

export default EducationList;
