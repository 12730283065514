import React, { useState, useEffect, useRef } from "react";

import "../checkout-page/checkout.styles.scss";
import "../checkout/checkout.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text";
import RBDropDown from "../../components/shared/dropdown/component";
import Footer from "../../components/footer";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { getExpMonthList } from "../../services/master-data";
import { getExpYearList } from "../../services/master-data";
import appLogger from "../../utils/app-logger";
import PathHelper from "../../utils/path-helper";
import CryptoJS from "crypto-js";
import ReactLoader from "../../components/shared/loader";
import axios from "axios";
import { Overlay, Tooltip } from "react-bootstrap";
import qs from "qs";
import dompurify from "dompurify";
import { readCookie, getPortalCD, setLocaleURL, getIPConfig, isAllAddressCookiePresent } from "../../utils/helpers";
import {
  APP_NAVIGATION,
  DOC_TYPE,
  EMPTY_GUID,
  EVENT_CATEGORY,
  EVENT_SUBCATEGORY,
  EVENT_TOPIC,
  NewPricingFlowQueryString,
} from "../../utils/constants";
import Logo from "../../components/header/navbar/logo";
import CreateUUID from "../../utils/helpers";
import ScrollToTop from "../../components/scroll-to-top/component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  userGuid,
  hidePaypal,
  defaultIPAddress,
  defaultCountryCode,
  defaultCurrencyCode,
  getGateway,
  getABTestId,
  googleScore,
  site_key,
  monthlyFullAccessategory,
  monthlyLimitedCategory,
  SMBPlanID,
  cardType3ds,
} from "../../utils/general-config";
import { trackRBEvents } from "../../utils/tracking-events";
import { useSearchParams } from "react-router-dom";
// import Chargebee from "chargebee";

import {
  getTokenExID,
  getTokenExSecretKey,
  getTokenExScheme,
  getTokenExApiKey,
  getTokenExJsURL,
  getPaypalHostUrl,
  getPaypalClientId,
  getPaypalClientSecret,
  getChargBeeSiteName,
  getChargBeeUserName,
  getGateWayIdfor3DSCheckout,
  kountVersion,
  kountMerchantId,
  KountTransactionSite,
  getKountKey,
  getkountEnableOrNot,
} from "../../utils/payment-config";

import { caseIndexValue } from "../../services/enum/index";
import { ProcessLogout } from "../../utils/process-logout";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import moment from "moment";
import { getTenantId } from "../../utils/general-config";
import { PerquisiteBountyEnable } from "../../utils/helpers";
const Checkout = ({
  checkoutConnect,
  funnelStep,
  skuConnect,
  ccpage,
  orderConnect,
  isLogoDisabled,
  getUserProfileConnect,
  eventServicePostConnect,
  BadBinCardsConnect,
  CreatePaymentIntentConnect,
  paymentProcess3DSConnect,
  abTestCheck,
  partnerLogOutConnect,
  getCaptchaScoreConnect,
  postDataToEventServiceConnect,
  calculateGuestUserPricingConnect,
  getGatewayABTestConnect,
  getAllDocumentOfUserConnect,
  isCardInBadBinsConnect,
  getTaxEstimateConnect,
}) => {
  window.$ = $;
  window.jQuery = $;
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const [expMonthList, setExpMonthList] = useState(getExpMonthList());
  const [expYearList, setExpYearList] = useState(getExpYearList());
  const queryParams = new URLSearchParams(window.location.search);
  const { userGUID, parentUserGuid } = funnelStep || {};
  const { email, personalInfoName, docType } = funnelStep || {};
  const { isLoading } = ccpage;
  const [isLoadTokenScript, setIsLoadTokenScript] = useState(false);
  const [skuGuid, setskuGuid] = useState(!!ccpage && !!ccpage.skuGuid ? ccpage.skuGuid : "");
  const [skuGuidDetails, setSkuGuidDetails] = useState(
    !!ccpage && !!ccpage.skuGuidDetails ? ccpage.skuGuidDetails : ""
  );
  const [skuDetailsText, setSkuDetailsText] = useState("");
  const [skuDetailsDownContent, setSkuDetailsDownContent] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [isShowError, setIsShowError] = useState("d-none");
  const { locale } = useParams();
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [recurringPrice, setRecurringPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [searchParams] = useSearchParams();
  const [docCount, setdocCount] = useState(0);
  const navigate = useNavigate();
  const [epochTime, setepochTime] = useState("");
  const qsGateWayId = searchParams.get("gatewayid");
  const [estimateTax, setEstimateTax] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [hideTaxEstimate, setHideTaxEstimate] = useState(true);
  let uGuid = !!userGUID ? userGUID : userGuid();
  // let pathRedirect = PathHelper(window.location.pathname);
  let pathRedirect = PathHelper(APP_NAVIGATION.CHPG);
  useEffect(() => {
    setShowLoader("d-block");
    if (!!readCookie("jwtToken")) {
      if (
        queryParams.has(NewPricingFlowQueryString.USERS) &&
        queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
        queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
        queryParams.has(NewPricingFlowQueryString.SKUGUID)
      ) {
        calculatePricingAndSetSkuDetails();
      } else {
        skuDetails();
      }
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
    if (!!uGuid) {
      getUserProfileConnect(uGuid)
        .then((response) => {
          let createdOn = moment(response.createdOn).format("M/D/YYYY");
          setepochTime(moment(createdOn).unix().toString());

          // setIsLoadTokenScript(true);
          if (!!response && (PerquisiteBountyEnable(response.perquisiteBountyKey, uGuid) == true || !!parentUserGuid)) {
            navigate(pathRedirect.cstm);
          }
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
    // if (queryParams.has("pplrtrn") && queryParams.has("token")) {
    //   setShowLoader("d-block");
    //   excecutePaypalProcess(queryParams.get("token"));
    // }

    loadScript("/app/tokenEXscript.js");
    return () => {
      removeScript("/app/tokenEXscript.js");
    };
  }, [isLoading]);
  useEffect(() => {
    if (!isAllAddressCookiePresent()) {
      getIPConfig();
    }
    if (!!uGuid) {
      getAllDocumentOfUserConnect(uGuid)
        .then((response) => {
          if (response && response?.documents) {
            setdocCount(response.documents.length);
          }
        })
        .catch((err) => {
          // const { detail } = err;
          // appLogger.log(detail);
        });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
    trackRBEvents("checkoutView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
      portallocale: !!locale ? locale : "en",
    });

    loadScript(`https://www.google.com/recaptcha/api.js?render=${site_key}`);
    if (queryParams.has("pplrtrn") && queryParams.has("token")) {
      setShowLoader("d-block");
      excecutePaypalProcess(queryParams.get("token"));
    }
    return () => {
      removeScript(`https://www.google.com/recaptcha/api.js?render=${site_key}`);
    };
  }, []);
  // useEffect(() => {
  //   if (isLoadTokenScript) {
  //     loadScript("/app/tokenEXscript.js");
  //   }
  // }, [isLoadTokenScript]);
  const setCheckoutDetails = (response) => {
    setSkuGuidDetails(response);
    let _skudetailsText = "";
    if (monthlyFullAccessategory.includes(response.skuSubCategory)) {
      _skudetailsText = queryParams.has("users")
        ? t("checkout.footer_full_access_plan_calculate_users")
        : t("checkout.footer_full_access_plan");
    } else if (monthlyLimitedCategory.includes(response.skuSubCategory)) {
      _skudetailsText = queryParams.has("users")
        ? t("checkout.footer_limited_access_plan_calculate_users")
        : t("checkout.footer_limited_access_plan");
    } else {
      _skudetailsText = t("checkout.footer_annual_access_plan");
    }

    let monthOrYear = "";
    let d = new Date();
    let nextBillingDate;
    if (response.scheduleTypeCD == "MONTH") {
      monthOrYear = "month";
      if (!!queryParams.get("users")) {
        nextBillingDate = d.setDate(d.getDate());
      } else {
        nextBillingDate = d.setDate(d.getDate() + 14);
      }
    } else {
      monthOrYear = "year";
      nextBillingDate = d;
    }
    if (!!queryParams.get("skuc")) {
      let skusubcategory = queryParams.get("skuc");
      if (skusubcategory.startsWith("M")) {
        _skudetailsText = _skudetailsText.replace("year", "month");
      }
    }
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const returnFormattedDate = (dateValue) => {
      let dateObj = new Date(dateValue);
      let localeValue = "en-US";
      if (!!locale) {
        localeValue = locale;
      }
      return dateObj.toLocaleDateString(localeValue, dateOptions);
    };

    _skudetailsText = _skudetailsText
      .replace("{skuGuidDetails.currencySymbol}", response.currencySymbol)
      .replace("{skuGuidDetails.unitPrice}", response.unitPrice)
      .replace("{skuGuidDetails.currencySymbol}", response.currencySymbol)
      .replace("{skuGuidDetails.recurringPrice}", parseFloat(response.recurringPrice).toFixed(2))
      .replace("{skuGuidDetails.billingDate}", returnFormattedDate(nextBillingDate));
    setSkuDetailsText(_skudetailsText);
    if (queryParams.has("pplrtrn") == false) {
      setShowLoader("d-none");
    }
    setRecurringPrice(parseFloat(response.recurringPrice).toFixed(2));
    setUnitPrice(parseFloat(response.unitPrice).toFixed(2));
    setDynamicTitle(response.label);

    let _skuDetailsDownContent = t("checkout.down_content");
    _skuDetailsDownContent = _skuDetailsDownContent.replace("{skuDetailsDownContent.scheduleTypeCD}", monthOrYear);
    setSkuDetailsDownContent(_skuDetailsDownContent);
    // const {
    //   data: {
    //     detail: { data },
    //   },
    // } = response;
  };
  const setEstimateSaleTax = (responseSku) => {
    const stateCode = readCookie("region"); //"IL";
    const zipCode = readCookie("zip"); //"60630"; //
    const planAmount =
      queryParams.has(NewPricingFlowQueryString.USERS) || responseSku.scheduleTypeCD == "YEAR"
        ? parseFloat(responseSku.recurringPrice)
        : parseFloat(responseSku.unitPrice);
    getTaxEstimateConnect(stateCode, zipCode, planAmount).then((response) => {
      if (!!response) {
        console.log("response ok from estimate tax");
        setHideTaxEstimate(false);
        setEstimateTax(response.estimatedTax);
        setSubTotal(response.subTotal);
      } else {
        console.log("no response from estimate tax");
        setHideTaxEstimate(true);
      }
    });
  };

  const skuDetails = () => {
    let skuId = queryParams.has(NewPricingFlowQueryString.SKUGUID)
      ? queryParams.get(NewPricingFlowQueryString.SKUGUID)
      : queryParams.get("s");
    skuConnect(skuId, locale)
      .then((response) => {
        // appLogger.log("inside skuConnect then ");
        const country = readCookie("country");
        if (!!country && country.toLowerCase() == "us") {
          setEstimateSaleTax(response);
        }
        setCheckoutDetails(response);
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };
  const calculatePricingAndSetSkuDetails = () => {
    const skuCategory = queryParams.get(NewPricingFlowQueryString.SKUCATEGORY);
    const skuId = queryParams.get(NewPricingFlowQueryString.SKUGUID);
    const currency = queryParams.get(NewPricingFlowQueryString.CURRENCY);
    const users = queryParams.get(NewPricingFlowQueryString.USERS);
    calculateGuestUserPricingConnect(skuId, currency, skuCategory, users)
      .then((response) => {
        setCheckoutDetails(response);
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };
  const loadScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    document.head.appendChild(script);
  };

  const removeScript = (url) => {
    var scripts = document.getElementsByTagName("script");
    for (let script of scripts) {
      if (script.src === window.location.origin + url) {
        script.remove();
        break;
      }
    }
  };
  const [fullName, setFullName] = useState("");
  const [ExpMonth, setExpMonth] = useState("");
  const [ExpYear, setExpYear] = useState("");
  const [acceptcondition, setAcceptCondition] = useState(false);

  const nameOnCard = (e) => {
    const {
      target: { value },
    } = e;
    setFullName(value);
  };
  const addExpMonthHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setExpMonth(value);
  };
  const addEXpYearHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setExpYear(value);
  };
  const setIPConfig = (param, defaultValue) => {
    if (localStorage.getItem("ipconfig") !== null) {
      let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
      return ipConfigData[param];
    } else {
      return defaultValue;
    }
  };

  let customFieldsData = {
    ipcountry: readCookie("country"),
    protalsrc: getPortalCD(locale),
    localeval: locale,
  };
  function CCDt() {
    this.t = "";
    this.n = "";
    this.em = "";
    this.ey = "";
    this.cv = "";
  }

  const paymentRequstData = (
    score,
    tokenxToken,
    cardType,
    transactionId,
    gatewayId,
    users,
    outCome,
    omniScore,
    kountResponse
  ) => {
    const name = fullName.replace(/[^a-zA-Z ]/g, "").split(" ");
    let fname = name[0];

    let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";
    let paymentRquestObject = {
      firstName: fname,
      lastName: lname,
      email: email,
      paymentPortal: getPortalCD(locale),
      skuGuid: queryParams.has(NewPricingFlowQueryString.SKUGUID)
        ? queryParams.get(NewPricingFlowQueryString.SKUGUID)
        : queryParams.get("s"),
      userGuid: uGuid,
      visitid: readCookie("visitGuid"),
      visitorid: readCookie("visitorGuid"),
      portalSource: getPortalCD(locale),
      customFields: customFieldsData,
      customerSource: "",
      paypalToken: "",
      ipAddress: readCookie("ipAddress"),
      gatewayID: !!qsGateWayId ? qsGateWayId : gatewayId,
      currency: skuGuidDetails.currencyCD,
      customerId: "",
      ccDetails: {
        cardType: cardType, //document.getElementById("hdnCardType").value,
        token: tokenxToken, //document.getElementById("tokenExToken").value,
        ccExpiryMonth: ExpMonth,
        ccExpiryYear: ExpYear,
        cvv: "0",
      },
      transactionId: transactionId || EMPTY_GUID,
      score: score,
      users: users,
      docCount: docCount,
      outCome: outCome,
      omniScore: omniScore,
      kountResponse: kountResponse,
      subTotal: subTotal != "" ? subTotal.toString() : "",
      estimatedTax: estimateTax != "" ? estimateTax.toString() : "",
      userAddress: getUserAddressObject(),
    };
    return paymentRquestObject;
  };
  const getUserAddressObject = () => {
    var userAddressObject = {
      userGuid: uGuid,
      city: readCookie("city"),
      state: readCookie("region"),
      country: readCookie("country"),
      zipCode: readCookie("zip"),
      addressType: 1,
      isActive: true,
      tenantId: getTenantId(),
    };
    return userAddressObject;
  };

  const abTestData = () => {
    let abTestRquestObject = {
      abTestId: getABTestId(),
      userGuid: uGuid,
      visitorID: readCookie("visitorGuid"),
      portalCD: getPortalCD(locale),
    };
    return abTestRquestObject;
  };

  const PaymentFailureResponse = () => {
    try {
      window.TrackAmplitudeEvent("payment_failure");
      window.TrackMixPanelEvent("payment_failure");
    } catch {}
    const name = fullName.replace(/[^a-zA-Z ]/g, "").split(" ");
    let fname = name[0];
    let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";
    setShowLoader("d-none");
    setIsShowError("d-block");
    trackRBEvents("paymentFailure", {
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "payment Clicked",
      email: email,
      firstName: fname,
      lastName: lname,
      portallocale: !!locale ? locale : "en",
    });
  };
  const paymentSuccessResponse = () => {
    const name = fullName.replace(/[^a-zA-Z ]/g, "").split(" ");
    let fname = name[0];
    let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";

    trackRBEvents("paymentSuccess", {
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "payment Clicked",
      email: email,
      firstName: fname,
      lastName: lname,
      portallocale: !!locale ? locale : "en",
    });
    if (queryParams.has(NewPricingFlowQueryString.USERS)) {
      navigate(pathRedirect.next + "?isNewPricingFlowCalc=true");
    } else if (
      queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
      queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
      queryParams.has(NewPricingFlowQueryString.SKUGUID)
    ) {
      navigate(pathRedirect.next + "?isNewPricingFlowPlan=true");
    } else {
      navigate(pathRedirect.next);
    }
  };
  const LogPaymentSuccessEvent = () => {
    try {
      window.TrackAmplitudeEvent("payment_success");
      window.TrackMixPanelEvent("payment_success");
    } catch {}
    let eventData = {
      Id: CreateUUID(),
      UserId: uGuid,
      VisitorId: readCookie("visitorGuid"),
      VisitId: readCookie("visitGuid"),
    };
    eventServicePostConnect(eventData).then((response) => {
      if (!!response) {
        paymentSuccessResponse();
      } else {
        PaymentFailureResponse();
      }
    });
  };
  const processNormalPayment = (score, tokenxToken, cardType, gatewayId, outCome, omniScore, kountResponse) => {
    const name = fullName.replace(/[^a-zA-Z ]/g, "").split(" ");
    let fname = name[0];
    let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";
    const users = queryParams.has(NewPricingFlowQueryString.USERS)
      ? queryParams.get(NewPricingFlowQueryString.USERS)
      : null;
    var tobeProcessDataForPayment = paymentRequstData(
      score,
      tokenxToken,
      cardType,
      null,
      gatewayId,
      users,
      outCome,
      omniScore,
      kountResponse
    );

    checkoutConnect(tobeProcessDataForPayment)
      .then((response) => {
        if (!!response) {
          appLogger.log("inside checkoutConnect then ");
          LogPaymentSuccessEvent();
        } else {
          PaymentFailureResponse();
        }
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
        PaymentFailureResponse();
      });
  };
  const process3DSPayment = (score, tokenxToken, cardType, gatewayId, outCome, omniScore, kountResponse) => {
    var cbInstance = window.Chargebee.init({
      site: getChargBeeSiteName(),
      publishableKey: getChargBeeUserName(),
    });
    var ccDt = new CCDt();
    ccDt.t = tokenxToken;
    ccDt.n = fullName;
    ccDt.em = ExpMonth;
    ccDt.ey = ExpYear;
    ccDt.cv = "";

    cbInstance
      .load("components")
      .then(() => {
        cbInstance
          .load3DSHandler()
          .then((threeDSHandler) => {
            window.threeDS = threeDSHandler;
            const users = queryParams.has(NewPricingFlowQueryString.USERS)
              ? queryParams.get(NewPricingFlowQueryString.USERS)
              : null;
            var data = paymentRequstData(
              score,
              tokenxToken,
              cardType,
              null,
              gatewayId,
              users,
              outCome,
              omniScore,
              kountResponse
            );
            data.gatewayID = getGateWayIdfor3DSCheckout();
            CreatePaymentIntentConnect(data).then((response) => {
              const { customerId, paymentIntent, transactionId } = response || {};
              if (!!customerId) {
                // window.threeDS.setPaymentIntent(JSON.parse(response.paymentIntent));
                window.threeDS.setPaymentIntent(JSON.parse(paymentIntent));
                window.threeDS
                  .handleCardPayment()
                  .then((paymentIntent) => {
                    var tobeProcessDataForPayment = paymentRequstData(
                      score,
                      tokenxToken,
                      cardType,
                      transactionId,
                      null,
                      users,
                      outCome,
                      omniScore,
                      kountResponse
                    );
                    tobeProcessDataForPayment.customerId = response.customerId;
                    paymentProcess3DSConnect(tobeProcessDataForPayment).then((response) => {
                      if (!!response) {
                        appLogger.log("inside checkoutConnect then ");
                        LogPaymentSuccessEvent();
                      } else {
                        PaymentFailureResponse();
                      }
                    });
                  })
                  .catch((err) => {
                    const { detail } = err;
                    PaymentFailureResponse();
                  });
              } else {
                PaymentFailureResponse();
                //processNormalPayment(tokenxToken, cardType);
              }
            });
          })
          .catch((err) => {
            const { detail } = err;
            PaymentFailureResponse();
          });
      })
      .catch((err) => {
        const { detail } = err;
        PaymentFailureResponse();
      });
  };
  const InitiatePaymentProcess = (
    score,
    tokenxToken,
    cardType,
    isBadBin,
    gatewayId,
    outCome,
    omniScore,
    kountResponse
  ) => {
    //Step 1 - Check BadBins
    //Step 2 - If Exist than chargbee create PaymentIntent call
    //Step 2.1 - If not than normal payment
    //Step 3 - If Create Payment Intent Call success then checkout 3 ds call
    //Step 4 - in case error show err

    //const gatewayId = getGateway(caseIndex);

    if (isBadBin) {
      process3DSPayment(score, tokenxToken, cardType, "", outCome, omniScore, kountResponse);
    } else {
      processNormalPayment(score, tokenxToken, cardType, gatewayId, outCome, omniScore, kountResponse);
    }

    // .catch((err) => {
    //   processNormalPayment(tokenxToken, cardType);
    // });
  };
  const getPostData = (email, portal, score) => {
    const postData = {
      userGuid: userGuid(),
      emailAddress: email,
      ipAddress: readCookie("ipAddress"),
      visitorId: readCookie("visitorGuid"),
      visitId: readCookie("visitGuid"),
      portalCD: portal,
      gScore: score,
    };
    return postData;
  };
  const processEventServiceAndLogOut = (postData) => {
    postDataToEventServiceConnect(
      postData,
      EVENT_CATEGORY.PAYMENT,
      EVENT_SUBCATEGORY.PAYMENT_FAILED,
      EVENT_TOPIC.PAYMENT
    ).then((response) => {
      partnerLogOutConnect();
      ProcessLogout("", "", "", locale);
    });
  };

  const onPaymentClickHandler = () => {
    try {
      window.TrackAmplitudeEvent("payment_page_CTA");
      window.TrackMixPanelEvent("payment_page_CTA");
    } catch {}
    setShowLoader("d-block");
    window.reactFunction = () => {
      window?.grecaptcha.ready(function () {
        window?.grecaptcha
          .execute(site_key, {
            action: "payment",
          })
          .then(function (token) {
            //setShowLoader("d-block");
            var tokenExValue = document.getElementById("tokenExToken").value;
            var cardTypeValue = document.getElementById("hdnCardType").value;
            var outCome = document.getElementById("hdnOutCome").value;
            var omniScore = document.getElementById("hdnOmniScore").value;
            var kountResponse = document.getElementById("hdnKountResponse").value;
            const name = fullName.replace(/[^a-zA-Z ]/g, "").split(" ");
            let fname = name[0];
            let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";
            const currencyCD = readCookie("currency");
            //let processDataForpAbTest = abTestData();
            const portal = getPortalCD(locale);
            let isBadBin = false;

            if (!!token) {
              getCaptchaScoreConnect(token, portal)
                .then((score) => {
                  if (score < googleScore()) {
                    try {
                      window.TrackAmplitudeEvent("payment_page_captha_score_failure");
                      window.TrackMixPanelEvent("payment_page_captha_score_failure");
                    } catch {}
                    // const customData = {
                    //   action: "Get Captha Score",
                    //   error: `Captha score is less than  ${googleScore()} `,
                    // };
                    const postData = getPostData(email, portal, score);
                    processEventServiceAndLogOut(postData);
                  } else {
                    //Step 1 - if not Check BadBins
                    //Step 2 - If Exist than chargbee create PaymentIntent call
                    //Step 3 - else do normal payment
                    isCardInBadBinsConnect(
                      tokenExValue.slice(0, 6),
                      portal,
                      skuGuidDetails.currencyCD,
                      readCookie("country")
                    ).then((response) => {
                      isBadBin = response.isBadBin;
                      InitiatePaymentProcess(
                        score,
                        tokenExValue,
                        cardTypeValue,
                        isBadBin,
                        "",
                        outCome,
                        omniScore,
                        kountResponse
                      );
                    });
                  }
                })
                .catch((err) => {
                  PaymentFailureResponse();
                });
            } else {
              PaymentFailureResponse();
            }
          });
      });
    };
  };

  const getUTCTimeStamp = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      ("0" + date.getUTCDate()).slice(-2) +
      ("0" + date.getUTCHours()).slice(-2) +
      ("0" + date.getUTCMinutes()).slice(-2) +
      ("0" + date.getUTCSeconds()).slice(-2)
    );
  };
  const createHmac = () => {
    var concatinatedString =
      getTokenExID() + "|" + window.location.origin + "|" + getUTCTimeStamp() + "|" + getTokenExScheme();
    var hash = CryptoJS.HmacSHA256(concatinatedString, getTokenExSecretKey());
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    return hashInBase64;
  };

  const createPaypalAccessToken = () => {
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      grant_type: "client_credentials",
    });
    var auth = "Basic " + btoa(getPaypalClientId() + ":" + getPaypalClientSecret());
    var config = {
      method: "post",
      url: getPaypalHostUrl() + "/v1/oauth2/token",
      headers: {
        Authorization: auth,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (!!response && !!response.data) {
          localStorage.setItem("ppat", response.data.access_token); //ppat=>PayPal Access Token
          createBillingAgreementToken(response.data.access_token);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const createBillingAgreementToken = (accessToken) => {
    let returnUrl = window.location.href + "&pplrtrn=true";
    let cancelUrl = window.location.href + "&cancel=true";

    var data = JSON.stringify({
      description: "ResumeBuild Subscription",
      payer: {
        payment_method: "Paypal",
        payer_info: {
          email: email,
        },
      },
      plan: {
        type: "MERCHANT_INITIATED_BILLING",
        merchant_preferences: {
          return_url: returnUrl,
          cancel_url: cancelUrl,
          accepted_pymt_type: "INSTANT",
          skip_shipping_address: true,
          immutable_shipping_address: true,
        },
      },
    });
    let access_token = "Bearer " + accessToken;
    var config = {
      method: "post",
      url: getPaypalHostUrl() + "/v1/billing-agreements/agreement-tokens",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        localStorage.setItem("pptoken_id", response.data.token_id); //pp=>PayPal
        window.location = response.data.links[0].href;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const excecutePaypalProcess = (token) => {
    setShowLoader("d-block");
    let data = {
      firstName: email,
      lastName: "",
      email: email,
      paymentPortal: getPortalCD(locale),
      skuGuid: queryParams.has(NewPricingFlowQueryString.SKUGUID)
        ? queryParams.get(NewPricingFlowQueryString.SKUGUID)
        : queryParams.get("s"),
      userGuid: uGuid,
      visitid: readCookie("visitGuid"),
      visitorid: readCookie("visitorGuid"),
      portalSource: getPortalCD(locale),
      customFields: customFieldsData,
      customerSource: "",
      paypalToken: token,
      ipAddress: readCookie("ipAddress"),
      ccDetails: null,
      users: queryParams.has(NewPricingFlowQueryString.USERS) ? queryParams.get(NewPricingFlowQueryString.USERS) : null,
      userAddress: getUserAddressObject(),
    };
    checkoutConnect(data)
      .then((response) => {
        appLogger.log("inside checkoutConnect then ");
        if (!!response) {
          trackRBEvents("paymentSuccess", {
            userGuid: userGUID,
            portalSource: getPortalCD(locale),
            eventLabel: "payment Clicked",
            email: email,
            portallocale: !!locale ? locale : "en",
          });
          if (queryParams.has(NewPricingFlowQueryString.USERS)) {
            navigate(pathRedirect.next + "?isNewPricingFlowCalc=true");
          } else if (
            queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
            queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
            queryParams.has(NewPricingFlowQueryString.SKUGUID)
          ) {
            navigate(pathRedirect.next + "?isNewPricingFlowPlan=true");
          } else {
            navigate(pathRedirect.next);
          }
        } else {
          setShowLoader("d-none");
          setIsShowError("d-block");
          trackRBEvents("paymentFailure", {
            userGuid: userGUID,
            portalSource: getPortalCD(locale),
            eventLabel: "payment Clicked",
            email: email,
            portallocale: !!locale ? locale : "en",
          });
        }
      })
      .catch((err) => {
        trackRBEvents("paymentFailure", {
          userGuid: userGUID,
          portalSource: getPortalCD(locale),
          eventLabel: "payment Clicked",
          email: email,
          portallocale: !!locale ? locale : "en",
        });
      });
  };

  const paypalHandler = () => {
    setShowLoader("d-block");
    let acccessToken = createPaypalAccessToken();
  };

  const [show, setShow] = useState(false);

  const handleMouseOver = () => {
    setShow(true);
  };

  const handleMouseOut = () => {
    setShow(false);
  };

  const target = useRef(null);

  return (
    <>
      <style>
        {`
            .d-hotline.h-btn {
              display: none;
            }
            #fc_frame.fc-widget-small{
          display: none;
      }
          `}
      </style>
      <ScrollToTop />
      <Helmet>
        <title>{t("page_title.checkout")}</title>
      </Helmet>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <img
              className="rb-logo"
              src={process.env.PUBLIC_URL + "/static/images/resumebuild-logo.svg"}
              width="117"
              alt="Logo"
            />
          </div>
        </nav>
      </header>
      {/* <Header isShow={"d-none"} isLogoDisabled={true} /> */}

      {isLoading && (
        <>
          <input type="hidden" id="txOrigin" value={window.location.origin} />
          <input type="hidden" id="txID" value={getTokenExID()} />
          <input type="hidden" id="txTokenScheme" value={getTokenExScheme()} />
          <input type="hidden" id="hdnCardType" value="" />
          <input type="hidden" id="tokenExToken" value="" />
          <input type="hidden" id="hmac" value={createHmac()} />
          <input type="hidden" id="timeStamp" value={getUTCTimeStamp()} />
          <input type="hidden" id="cardNumberText" value={t("checkout.card_number")} />
          <input type="hidden" id="hdnOmniScore" value="" />
          <input type="hidden" id="hdnOutCome" value="" />
          <input type="hidden" id="hdnKountResponse" value="" />
          <input type="hidden" id="hdnipAddress" value={readCookie("ipAddress")} />
          <input type="hidden" id="hdnuserguid" value={userGUID} />
          <input type="hidden" id="hdnAmount" value={skuGuidDetails.unitPrice} />
          <input type="hidden" id="hdnCurrency" value={skuGuidDetails.currencyCD} />
          <input type="hidden" id="hdnbillingEmailAddress" value={email} />
          <input type="hidden" id="hdnproductType" value={skuGuidDetails.scheduleTypeCD} />
          <input type="hidden" id="hdnproductDescription" value={skuGuidDetails.description} />
          <input type="hidden" id="hdnplanAmount" value={skuGuidDetails.recurringPrice} />
          <input type="hidden" id="hdnskuid" value={skuGuidDetails.id} />
          <input type="hidden" id="hdnKountVersion" value={kountVersion} />
          <input type="hidden" id="hdnKountMerchantId" value={kountMerchantId} />
          <input type="hidden" id="hdnKountTransactionSite" value={KountTransactionSite} />
          <input type="hidden" id="hdnKountApiKey" value={getKountKey()} />
          <input type="hidden" id="hdnIskountEnable" value={getkountEnableOrNot()} />

          <ReactLoader showloader={showLoader} />
          <input type="hidden" id="hdnepochtime" value={epochTime} />
          <ReactLoader showloader={showLoader} />
          <section className="checkout">
            <div className="container">
              <h1 className="mainHeading">
                {docType != DOC_TYPE.COVER_LETTER ? t("checkout.mainHeading") : t("checkout.cvlt_mainHeading")}{" "}
              </h1>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className={isShowError}>
                    <p className="cardnumber-error">{t("checkout.error")}</p>
                  </div>
                  <div className="card-details pt-3">
                    <div
                      className={hideTaxEstimate ? "d-none" : ""}
                      style={{
                        borderBottom: "1px solid #8294ac",
                        marginBottom: "12px",
                        padding: "0 0 5px",
                      }}
                    >
                      <div className={hideTaxEstimate ? "d-none" : "d-flex justify-content-between"}>
                        <div className="fw-normal">Subtotal:</div>
                        <div>
                          <span className="saleTaxSubtotal">
                            {skuGuidDetails.currencySymbol}
                            {subTotal}
                          </span>
                        </div>
                      </div>
                      <div className={hideTaxEstimate ? "d-none" : "d-flex justify-content-between"}>
                        <div className="fw-normal">Estimated Sale Tax:</div>
                        <div>
                          <span className="saleTaxEstimate">
                            {skuGuidDetails.currencySymbol}
                            {estimateTax}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <h1> {t("checkout.heading")} </h1>
                      </div>
                      <div className="col-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                        {" "}
                        <span className="subheading-price">
                          {skuGuidDetails.currencySymbol}
                          {queryParams.has(NewPricingFlowQueryString.USERS) || skuGuidDetails.scheduleTypeCD == "YEAR"
                            ? recurringPrice
                            : unitPrice}
                          <span className="payment-summary--total total"></span>
                        </span>
                      </div>
                    </div>
                    <div className="total-divider"></div>
                    <div className="payment__cards-container ">
                      <ul className="payment__cards col-12">
                        <li id="visaCard">
                          <img src="/app/static/images/card-visa.svg" />
                        </li>
                        <li id="masterCard">
                          <img src="/app/static/images/card-mc.svg" />
                        </li>
                        <li id="americanexp">
                          <img src="/app/static/images/card-amex-new.svg" />
                        </li>
                        <li id="discover">
                          <img src="/app/static/images/card-discover.svg" />
                        </li>
                        <li>
                          <div className={hidePaypal(skuGuidDetails.currencyCD) == true ? "d-none" : "paypal-style"}>
                            {" "}
                            <img
                              isLoading={false}
                              disabled={false}
                              onClick={paypalHandler}
                              classNames="btn btn-primary btn-lg submit-on-enter w-100 "
                              ctaText="Paypal"
                              src="/app/static/images/pay-new-pp.svg"
                            />
                          </div>
                        </li>
                      </ul>
                      <div className="all-card">
                        <img src="/app/static/images/grey-card.jpg" />

                        <div className={hidePaypal(skuGuidDetails.currencyCD) == true ? "d-none" : "paypal-styles"}>
                          {" "}
                          <img
                            isLoading={false}
                            disabled={false}
                            onClick={paypalHandler}
                            classNames="btn btn-primary btn-lg submit-on-enter w-100"
                            ctaText="Paypal"
                            src="/app/static/images/pay-new-pp.svg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="payment-forms checkout">
                      <div className="row">
                        <div className="col" id="cardName">
                          <InputText
                            showLabel={true}
                            id="checkout_form_card_name"
                            text={t("checkout.name")}
                            type="text"
                            required
                            style={{ paddingLeft: 50 }}
                            onChange={nameOnCard}
                            value={fullName}
                          />

                          <span id="billing-card-name-flag" className="flagger red hidden">
                            {t("checkout.name_error")}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          {}
                          <div className="card_number" id="cardnumber">
                            <label htmlFor="card-number" className=" collapseitcc">
                              {t("checkout.card_number")}
                            </label>

                            <div id="tokenExIframeDiv" className="form-control collapseitcc security-field"></div>
                            <span id="card-number-flag" className="flagger red hidden">
                              {t("checkout.card_error")}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="col" id="CVV">
                            <div className="card_date" id="cvvDiv">
                              <label
                                htmlFor="billing-card-cvv"
                                className="form-label"
                                dangerouslySetInnerHTML={{
                                  __html: t("checkout.securtity_Code"),
                                }}
                              />

                              <div id="card-cvv-element" className="form-control collapseitcc security-field"></div>
                              <span id="cvv-flag" className="flagger red hidden">
                                {t("checkout.securtity_Code_error")}
                              </span>
                              {/* <span class="cvv-card-icon"></span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row exp-date-year">
                        <div className="col-lg-8 col-md-6 col-6 " id="expMonthDiv">
                          <RBDropDown
                            id="expmo"
                            showLabel={true}
                            text={t("checkout.exp_month")}
                            selectedValue={ExpMonth || ""}
                            classNames="ccMonthExp"
                            index={0}
                            options={expMonthList.data}
                            onChange={addExpMonthHandler}
                          />
                          <span id="billing-card-exp-month-flag" className="flagger red hidden">
                            {t("checkout.exp_month_error")}
                          </span>
                        </div>
                        <div className="col-lg-4 col-md-6 col-6 ps-3" id="expYearDiv">
                          <RBDropDown
                            id="expyr"
                            showLabel={true}
                            classNames="ccYearExp"
                            text={t("checkout.exp_year")}
                            selectedValue={ExpYear || ""}
                            index={0}
                            options={expYearList.data}
                            onChange={addEXpYearHandler}
                          />
                          <span id="billing-card-exp-year-flag" className="flagger red hidden">
                            {t("checkout.exp_year_error")}
                          </span>
                        </div>
                      </div>
                      <div className="row align-items-center ">
                        <div className="row col-12 secure-msg">
                          <div className="col-9">
                            {" "}
                            <div className="small secureHeading">
                              <img src="/app/static/images/lock.svg" />
                              {t("checkout.secure_checkout")}
                            </div>
                          </div>
                          <div className="col-3 logocontainer ">
                            <img src="/app/static/images/NortonSeal.png" className="nortonSeal" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="fbn bounce-effect">
                            <Button
                              id="btnChargeebeeSubmit"
                              isLoading={false}
                              disabled={false}
                              onClick={onPaymentClickHandler}
                              classNames="btn btn-primary btn-lg submit-on-enter checkout-btn"
                              ctaText={
                                docType != DOC_TYPE.COVER_LETTER
                                  ? t("checkout.pay_securely")
                                  : t("checkout.cvltr_pay_securely")
                              }
                            />
                          </div>

                          <span id="acceptCheckboxFlag" className="flagger checkbox-error red hidden">
                            {t("checkout.checkbox_error")}
                          </span>

                          <div className="checkout-checkbox ">
                            <div>
                              <input type="checkbox" value={acceptcondition} id="acceptCheckbox" />
                            </div>
                            <div
                              className={
                                skuGuidDetails.scheduleTypeCD == "MONTH" ? "payment-footer" : "d-none payment-footer"
                              }
                              dangerouslySetInnerHTML={{
                                __html: skuDetailsText,
                              }}
                            />
                            <div
                              className={
                                skuGuidDetails.scheduleTypeCD === "YEAR" ? "payment-footer" : "d-none payment-footer"
                              }
                              dangerouslySetInnerHTML={{
                                __html: skuDetailsText,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" row flex-column-reverse flex-md-row col-md-12 col-lg-6 left-side-container">
                  <div className="payment-info-container">
                    <div className="payment-header">
                      <div>
                        <h5> {t("checkout.money_back")} </h5>
                        <p>{t("checkout.money_back_description")}</p>
                      </div>
                      <div style={{ marginTop: -10 }}>
                        <h5> {t("checkout.cancel_anytime")} </h5>
                        <p>{t("checkout.cancel_anytime_description")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="row trust-review-widget pb-5">
                      <img className="trustpilot" src="/app/static/images/trust-pilot.png" alt="trustpilot" />
                    </div>
                    <div className="col-lg-12 mt-2 left-bottom-side">
                      <p style={{ fontSize: 12 }}>{t("checkout.five_star_rating")}</p>
                      <Swiper
                        cssMode={true}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        className="review--slider"
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          992: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                        }}
                      >
                        <SwiperSlide>
                          <b className="review-header">{t("reviews.reviews_title_1")}</b>
                          <div className="review-text">{t("reviews.reviews_text_1")}</div>
                          <div className="review-name">{t("reviews.reviews_name_1")}</div>
                          <div className="review-date">{t("reviews.reviews_date_1")}</div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="review-stars tp-stars tp-stars--5"></div>
                          <b className="review-header">{t("reviews.reviews_title_2")}</b>
                          <div className="review-text">{t("reviews.reviews_text_2")}</div>
                          <div className="review-name">{t("reviews.reviews_name_2")}</div>
                          <div className="review-date">{t("reviews.reviews_date_2")}</div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="review-stars tp-stars tp-stars--5"></div>
                          <b className="review-header">{t("reviews.reviews_title_3")}</b>
                          <div className="review-text">{t("reviews.reviews_text_3")}</div>
                          <div className="review-name">{t("reviews.reviews_name_3")}</div>
                          <div className="review-date">{t("reviews.reviews_date_3")}</div>
                        </SwiperSlide>
                        <div className="swiper-button">
                          <div className="swiper-button-prev">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle
                                className="arrow-slider-circle"
                                cx="12"
                                cy="12"
                                r="11.5"
                                fill="none"
                                stroke="#8C8C8C"
                              ></circle>

                              <path
                                className="arrow-slider-shape"
                                fill="#8C8C8C"
                                d="M10.5088835 12l3.3080582-3.02451041c.2440777-.22315674.2440777-.5849653 0-.80812204-.2440776-.22315673-.6398058-.22315673-.8838834 0L9.18305826 11.595939c-.24407768.2231567-.24407768.5849653 0 .808122l3.75000004 3.4285714c.2440776.2231568.6398058.2231568.8838834 0 .2440777-.2231567.2440777-.5849653 0-.808122L10.5088835 12z"
                              ></path>
                            </svg>
                          </div>
                          <div className="swiper-button-next">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="11.5" fill="none" stroke="#8C8C8C"></circle>
                              <path
                                fill="#8C8C8C"
                                d="M13.5,12l-3.3,3c-0.2,0.2-0.2,0.6,0,0.8s0.6,0.2,0.9,0l3.8-3.4c0.2-0.2,0.2-0.6,0-0.8l-3.8-3.4
	                                    c-0.2-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.2,0.6,0,0.8L13.5,12z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default Checkout;
