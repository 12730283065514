import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectedDocument from "./component";
import { getParsedResume } from "./actions";
import { resetStore } from "../review/actions";
import { setDocGUID } from "./../funnel-step/actions";
import { resetUploadResume } from "../upload-resume/actions";

const mapStateToProps = ({ uploadResume, funnelStep }) => ({
  uploadResume,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      parsedResumeConnect: getParsedResume,
      saveDocGUIDConnect: setDocGUID,
      resetUploadResumeConnect:resetUploadResume
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectedDocument);
