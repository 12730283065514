import { LOCAL_STORAGE_KEYS } from "./constants";

export const currentEnv = process.env.REACT_APP_ENVIRONMENT;

export const appUrl = () => {
  const urls = {
    DEVELOPMENT: "http://localhost:4000/",
    QA: "https://test-app.resumebuild.com/",
    STAGING: "https://staging-app.resumesimpli.com/",
    PRODUCTION: "https://app.resumebuild.com/",
  };
  return urls[currentEnv];
};

export const apiUrl = () => {
  const urls = {
    DEVELOPMENT: "https://docbuilder-qa.azurewebsites.net/api/v1/docbuilder/",
    QA: "https://test-api.resumebuild.com/",
    STAGING: "https://staging-api.resumebuild.com/",
    PRODUCTION: "https://api.resumebuild.com/",
  };
  return urls[currentEnv];
};

export const apiAuthKey = () => {
  //const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  //const authKey = loginData ? JSON.parse(loginData).apiAuthKey : "";
  //return authKey;
  return "27ea8487-f6d9-45b5-a5eb-eece787b841e";
};

export const getCLientId = () => {
  const urls = {
    DEVELOPMENT: "",
    QA: "",
    STAGING: "",
    PRODUCTION: "",
  };
  return urls[currentEnv];
};
