import { createReducer } from "reduxsauce";
import Types from "./types";
import appLogger from "../../utils/app-logger";
import { REHYDRATE } from "redux-persist";
import { EMPTY_GUID, DOC_STAGE, SECTION_TYPE } from "../../utils/constants";

export const INITIAL_STATE = {
  reviewDoc: null,
  isLoading: true,
  //   reviewDoc: {
  //     id: EMPTY_GUID,
  //     name: "string",
  //     docStage: "string",
  //     createdOn: "2022-05-12T09:44:25.738Z",
  //     modifiedOn: "2022-05-12T09:44:25.738Z",
  //     tenantId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     userId: EMPTY_GUID,
  //     typeOfDoc: 0,
  //     paragraphs: [
  //       {
  //         id: EMPTY_GUID,
  //         docId: EMPTY_GUID,
  //         paraContents: [
  //           {
  //             id: EMPTY_GUID,
  //             paraId: EMPTY_GUID,
  //             content: "string",
  //             order: 0,
  //             createdOn: "2022-05-12T09:44:25.738Z",
  //             modifiedOn: "2022-05-12T09:44:25.738Z",
  //           },
  //         ],
  //         order: 0,
  //         paraCD: "string",
  //         sectionCD: 0,
  //       },
  //     ],
  //     docStyle: {
  //       id: EMPTY_GUID,
  //       docId: EMPTY_GUID,
  //       fontFamily: "string",
  //       fontSize: "string",
  //       headingSize: "string",
  //       themeColor: "string",
  //     },
  //     originalDocId: EMPTY_GUID,
  //     templateCD: "string",
  //   },
};

export const getUserResume = (state = INITIAL_STATE, { resume }) => {
  return {
    ...state,
    reviewDoc: resume,
    isLoading: false,
  };
};

export const resetResume = () => {
  return { ...INITIAL_STATE };
};

export const fetchUpdateOrderingSuccess = (state = INITIAL_STATE, { data }) => {
  let newState = { ...state };
  let jsonPara = JSON.parse(data);
  jsonPara.forEach((element) => {
    newState.reviewDoc.paragraphs.find((x) => x.id == element.paraid).order =
      element.order;
  });
  return { ...newState };
};
export const renameParagraphSuccess = (
  state = INITIAL_STATE,
  { paraId, newParaname }
) => {
  let newState = { ...state };
  newState.reviewDoc.paragraphs.find((x) => x.id == paraId).paraName =
    newParaname;
  return { ...newState };
};

export const deleteSuccess = (state = INITIAL_STATE, { paraId }) => {
  let newState = { ...state };
  let index = newState.reviewDoc.paragraphs.findIndex((p) => p.id == paraId);
  newState.reviewDoc.paragraphs.splice(index, 1);
  return { ...newState };
};

export const fetchUpdateDocStyleSuccess = (state = INITIAL_STATE, { data }) => {
  let newState = { ...state };
  newState.reviewDoc.docStyle = data;
  return {
    ...newState,
  };
};

export const fetchUpdateThemeSuccess = (state = INITIAL_STATE, { data }) => {
  let newState = { ...state };
  newState.reviewDoc.templateCD = data;
  return {
    ...newState,
  };
};

export const HANDLERS = {
  [Types.RESUME_GET_SUCCESS]: getUserResume,
  [Types.RESUME_RESET]: resetResume,
  [Types.UPDATE_ORDERING_GET_SUCCESS]: fetchUpdateOrderingSuccess,
  [Types.RENAME_PARAGRAPH_SUCCESS]: renameParagraphSuccess,
  [Types.DELETE_SUCCESS]: deleteSuccess,
  [Types.UPDATE_DOCSTYLE_SUCCESS]: fetchUpdateDocStyleSuccess,
  [Types.UPDATE_THEME_SUCCESS]: fetchUpdateThemeSuccess,
};
export default createReducer(INITIAL_STATE, HANDLERS);
