import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDocGUID } from "../funnel-step/actions";
import {
  createExperienceParagraph,
  updateExperienceParagraph,
} from "./actions";
import CoverLetterExperience from "./component";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import { previewTemplateAction } from "../cover-letter-template/actions";

const mapStateToProps = ({
  funnelStep,
  cvltrExperience,
  cvltrTemplatePreview,
}) => ({
  funnelStep,
  cvltrExperience,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      experienceConnect: createExperienceParagraph,
      updateExperienceParagraph: updateExperienceParagraph,
      SaveDocGUIDConnect: setDocGUID,
      previewTemplateAction: previewTemplateAction,
      updateProgress: updateProgress,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterExperience);
