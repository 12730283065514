import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  templateField: "",
  steps: "",
};

export const handlePreview = (state = INITIAL_STATE, value) => {
  const { data } = value;
  return { ...state, templateField: data };
};

export const handleSteps = (state = INITIAL_STATE, value) => {
  const { data } = value;
  return { ...state, steps: data };
};

export const resetPreview = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.PREVIEW]: handlePreview,
  [Types.STEPS]: handleSteps,
  [Types.RESET_PREVIEW]: resetPreview,
};

export default createReducer(INITIAL_STATE, HANDLERS);
