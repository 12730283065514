import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApplyTemplate from "./component";

const mapStateToProps = ({ funnelStep, uploadResume }) => ({
  funnelStep,
  uploadResume,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplyTemplate);
