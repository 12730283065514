//import baseApi from "./base-api";
import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getDocBuilderServicendPoint, getDocParserServicendPoint } from "../services/api-constants";
import { readCookie, getServiceToken } from "../utils/helpers";
import { DEFAULT_USER_GUID } from "../utils/constants";

class DocBuilderService {
  DocBuilderService(url) {
    return getDocBuilderServicendPoint(url);
  }
  DocParserService(url) {
    return getDocParserServicendPoint(url);
  }

  getDoc(docId) {
    const jwtToken = readCookie("jwtToken");
    // const headers = { Authorization: "Bearer" + " " + jwtToken };
    const headers = {
      Authorization: "Bearer" + " " + jwtToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    const getDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.GET_DOCUMENT.replace(":docId", docId)}`
    );
    return apiService.get(getDocEndpoint, null, headers);
  }

  getCoverLetterDoc(docId) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.GET_DOCUMENT.replace(":docId", docId)}`
    );
    return apiService.get(getDocEndpoint, null, headers);
  }

  createDoc(data) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const createDocEndpoint = this.DocBuilderService(`${endPoints.docBuilderService.CREATE_DOCUMENT}`);
    return apiService.post(createDocEndpoint, data, headers);
    //return baseApi.post("doc", data);
  }
  deleteDoc(docId) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const deleteDocEndPoint = this.DocBuilderService(
      `${endPoints.docBuilderService.DELETE_DOCUMENT.replace(":docId", docId)}`
    );
    return apiService.delete(deleteDocEndPoint, docId, headers);
  }
  duplicateDoc(docId, dupDocInfo) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const duplicateDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.DUPLICATE_DOCUMENT.replace(":docId", docId)}`
    );
    return apiService.post(duplicateDocEndpoint, dupDocInfo, headers);
  }

  chooseTemplate(docId, templateCD) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const chooseTemplateEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.CHOOSE_TEMPLATE.replace(":docId", docId)}`
    );
    return apiService.patch(chooseTemplateEndpoint, templateCD, headers);
  }

  createParagraph(docId, data) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var createParaEndPoint = `${endPoints.docBuilderService.CREATE_DOCUMENT}/${docId}/paragraph`;
    const getCreateParaEndpoint = this.DocBuilderService(createParaEndPoint);
    return apiService.post(getCreateParaEndpoint, data, headers);
  }

  updateParagraph(docId, paraId, data) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const updateParagraphEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.UPDATE_PARAGRAPH.replace(":docId", docId).replace(":paraId", paraId)}`
    );
    return apiService.put(updateParagraphEndpoint, data, headers);
  }

  saveSignatureImage = async (docId, data) => {
    //const jwtToken = readCookie("jwtToken");
    const jwtToken = await getServiceToken();
    //console.log("jwtToken", jwtToken);
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const saveSignatureImageEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.UPLOAD_IMAGE.replace(":docId", docId)}`
    );
    return apiService.post(saveSignatureImageEndpoint, data, headers);
  };

  renameParagraph(docId, paraId, paraName) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const renameParagraphEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.RENAME_PARAGRAPH.replace(":docId", docId).replace(":paraId", paraId)}`
    );
    return apiService.patch(renameParagraphEndpoint, paraName, headers);
  }
  updateUserGuid(docId, userGuid) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var updateUserEndPoint = `${endPoints.docBuilderService.CREATE_DOCUMENT}/${docId}/user`;
    const userEndpoint = this.DocBuilderService(updateUserEndPoint);
    return apiService.patch(userEndpoint, userGuid, headers);
  }
  findByUserGuid(userguid) {
    const jwtToken = readCookie("jwtToken"); // localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var getByUserGuidEndPoint = `${endPoints.docBuilderService.CREATE_DOCUMENT}?userguid=${userguid}`;
    const findByUserGuidEndpoint = this.DocBuilderService(getByUserGuidEndPoint);
    return apiService.get(findByUserGuidEndpoint, null, headers);
  }
  //This method is used for getting document of the users  based on type of doc
  findByUserGuidAndTypeOfDoc(userguid, typeofdoc) {
    const jwtToken = readCookie("jwtToken"); // localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var getByUserGuidEndPoint = `${endPoints.docBuilderService.CREATE_DOCUMENT}?userguid=${userguid}&typeofdoc=${typeofdoc}`;
    const findByUserGuidEndpoint = this.DocBuilderService(getByUserGuidEndPoint);
    return apiService.get(findByUserGuidEndpoint, null, headers);
  }

  //This method is use for retrieving cover-letters
  findCvsByUserGuid(userguid) {
    const jwtToken = readCookie("jwtToken"); // localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getByUserGuidEndPoint = `${endPoints.docBuilderService.CREATE_DOCUMENT}?userguid=${userguid}&typeofdoc=20`;
    const findByUserGuidEndpoint = this.DocBuilderService(getByUserGuidEndPoint);
    return apiService.get(findByUserGuidEndpoint, null, headers);
  }

  updateParagraphOrdering(docId, data) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const updateParagraphOrderingEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.UPDATE_PARAGRAPH_ORDERING.replace(":docId", docId)}`
    );
    return apiService.patch(updateParagraphOrderingEndpoint, data, headers);
  }

  deleteParagraph(docId, paraId) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const deleteParagraphEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.DELETE_DOC_STYLE.replace(":docId", docId).replace(":paraId", paraId)}`
    );

    return apiService.delete(deleteParagraphEndpoint, paraId, headers);
  }

  updateDocStyle(docId, data) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const updateDocStyleEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.UPDATE_DOC_STYLE.replace(":docId", docId)}`
    );
    return apiService.put(updateDocStyleEndpoint, data, headers);
  }
  downloadDocument(docId, conversionType, conversionMethod, version, regenerateDoc) {
    const jwtToken = readCookie("jwtToken"); // localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    let userId = readCookie("userguid");
    if (!userId) {
      userId = DEFAULT_USER_GUID;
    }
    const getDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.DOCUMENT_DOWNLOAD.replace(":docId", docId)
        .replace(":downloadDocType", conversionType)
        .replace(":docConversionMethod", "SOLID")
        .replace(":version", version)
        .replace(":userId", userId)
        .replace(":regenerateDoc", regenerateDoc)}`
    );
    return apiService.get(getDocEndpoint, null, headers);

    // return baseApi.get(
    //   `doc/${docId}/download?downloadDocType=${conversionType}`
    // );
  }
  conversionStatus(docId, conversionId) {
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.CONVERSION_STATUS.replace(":docId", docId).replace(":conversionId", conversionId)}`
    );
    return apiService.get(getDocEndpoint, null, headers);

    // return baseApi.get(`doc/${conversionId}/download-status`);
  }
  renameDocument(docId, newDocName) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const updateDocumentName = this.DocBuilderService(
      `${endPoints.docBuilderService.RENAME_DOCUMENT.replace(":docId", docId)}`
    );
    return apiService.patch(updateDocumentName, newDocName, headers);
  }

  generatePDFPath(docId, userId) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.GENERATE_PDF_PATH.replace(":docId", docId).replace(":userId", userId)}`
    );
    return apiService.get(getDocEndpoint, null, headers);

    // return baseApi.get(
    //   `doc/${docId}/download?downloadDocType=${conversionType}`
    // );
  }
  getParsedDoc(data) {
    const jwtToken = getServiceToken();
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const createDocParseEndpoint = this.DocParserService(`${endPoints.docBuilderService.DOC_PARSE}`);
    //const headers= { Authorization: "Bearer" + " " + "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInRva2VuX3R5cGUiOjEwLCJraWQiOiI0NGUwZmY2MzgwY2E0YzhkOWFlNzBjMjExMjc5NGFiNyJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6Im1yaXR5dW5qYWlrK3RleHQxMjNAd29ya3NpbXBsaS5pbyIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3NpZCI6IjQ4ZmY3MjJhLTM4ZGUtNGE5OS04NjUwLTUxNTZkMjMzMzkzOCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvZ3JvdXBzaWQiOiI3ZmM5ZjQyNS0xNDYzLTRhOGUtODU1Ni02YzZiMzRjZmNmZjYiLCJuYmYiOjE2NzQ4MDYwOTksImV4cCI6MTk5MDE2NjA5OSwiaXNzIjoiTGVnYWxTaW1wbGkiLCJhdWQiOiJQREZTaW1wbGkifQ.BMYthZX80FUNag7hgbsmhOhcBPwZ7_8Y4O0w07g0oZDeQ9khHA1p8LCL1_zFvOZT5rNIf2pyZeR3fJwpQCqVU1QLD3-H9vl44Q4ulMCEZ7NhjpTB64NDURjFhxYcPDjdTrF019_KDSzc64MZOyeX7kFmOZy7ujsMH31fQq5X4V_0FA_cox9i6X21oBnJdwOJ7wDgTEbtxRk0LLrd-B68_907RgeXGn54vo5QROwP8CXhXeBT7T79dAIf8HEAlqeVGyp8SMvQefPshzSIs6gQkOfhe4-VnrJ0qJQnG9oqmGNmUIMj9tZQFI2eq1z-b4MbsGulSJlZ_N4A0MimFKbFpw" };
    //const createDocParseEndpoint =  "https://localhost:6801/api/v1/docparser/doc"

    return apiService.post(createDocParseEndpoint, data, headers);
  }
  saveImage = async (docId, data) => {
    //const jwtToken = readCookie("jwtToken");
    const jwtToken = await getServiceToken();
    //console.log("jwtToken", jwtToken);
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const saveImageEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.UPLOAD_IMAGE.replace(":docId", docId)}`
    );
    return apiService.post(saveImageEndpoint, data, headers);
  };
  getAllDocumentOfUser(userGuid) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getAllDocEndpoint = this.DocBuilderService(
      `${endPoints.docBuilderService.GET_ALLDOCUMENT_OF_USER.replace(":userGuid", userGuid)}`
    );
    return apiService.get(getAllDocEndpoint, null, headers);
  }
  findByUserGuidAbridged(userGuid, typeofdoc) {
    const jwtToken = readCookie("jwtToken"); // localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getAbridged = this.DocBuilderService(
      `${endPoints.docBuilderService.GET_ABRIDGED_DATA.replace(":userGuid", userGuid).replace(":typeofdoc", typeofdoc)}`
    );
    return apiService.get(getAbridged, null, headers);
  }

  getUserDocumentAttributes(userguid, typeofdoc) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const getUserDocumentAttributesEndPoint = this.DocBuilderService(
      `${endPoints.docBuilderService.GET_USER_DOCUMENTS_ATTRIBUTES.replace(":userGuid", userguid).replace(
        ":typeofdoc",
        typeofdoc
      )}`
    );
    return apiService.get(getUserDocumentAttributesEndPoint, null, headers);
  }
}

export default new DocBuilderService();
