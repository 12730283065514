import { createTypes } from "reduxsauce";

export default createTypes(
    `
    RESUME_GET_SUCCESS
    RESUME_GET_FAILURE
`,
    {
        prefix: "REVIEW/"
    }
);
