import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text/component";
import RBDropDown from "../../components/shared/dropdown/component";
import Footer from "../../components/footer";
import "./skill.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import appLogger from "../../utils/app-logger";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import PathHelper from "../../utils/path-helper";
import WritingTips from "../../components/writing-tips/component";
import ResumeMap from "../../components/resume-map";
import { HashLink } from "react-router-hash-link";
import { EMPTY_GUID, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import moment from "moment";
import { APP_NAVIGATION } from "../../utils/constants";
import { getSkillList } from "../../services/master-data";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { trackRBEvents } from "../../utils/tracking-events";
import { getPortalCD, readCookie } from "../../utils/helpers";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Skill = ({
  skills, //rootreducer
  funnelStep, //rootreducer
  createSkillsParagraph,
  updateSkillsParagraph,
}) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const {
    Paragraphs: { paraContents, paraName },
  } = skills;
  const { docGUID, funnelStepsComplete, userGUID, email, personalInfoName } = funnelStep || {};
  let pathRedirect = PathHelper(APP_NAVIGATION.SKLS, funnelStepsComplete);
  const [skillParaGuid, setSkillParaGuid] = useState(EMPTY_GUID);
  const [skillList, setSkillList] = useState(getSkillList(t));

  const [skillvalue, setSkillvalue] = useState([
    { skill: "", rating: "", ratingText: "" },
    { skill: "", rating: "", ratingText: "" },
    { skill: "", rating: "", ratingText: "" },
  ]);
  useEffect(() => {
    trackRBEvents("skillsEditView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    if (!!paraContents && paraContents.length > 0) {
      var data = paraContents.map((item) => {
        return {
          skill: JSON.parse(item.content)["skill"],
          rating: JSON.parse(item.content)["rating"],
          ratingText: JSON.parse(item.content)["ratingText"],
        };
      });
      setSkillvalue(data);
      setSkillParaGuid(paraContents[0].paraId);
    }
  }, []);
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const handleChange = (i, e) => {
    let newFormValues = [...skillvalue];
    newFormValues[i][e.target.name] = e.target.value;
    setSkillvalue(newFormValues);
  };
  const ratingHandleChange = (i, e) => {
    let newFormValues = [...skillvalue];
    newFormValues[i]["rating"] = e.target.value;
    newFormValues[i]["ratingText"] = RatingDisplay(e.target.value);
    setSkillvalue(newFormValues);
  };

  const addFormFields = () => {
    setSkillvalue([...skillvalue, { skill: "", rating: "", ratingText: "" }]);
  };

  const RatingDisplay = (rating) => {
    let ratingDisplay = "";
    if (!!rating && rating > 0) {
      ratingDisplay = t("skill.skill_Options").split(",")[rating - 1];
    }
    return ratingDisplay;
  };

  const removeFormFields = (i) => {
    let newFormValues = [...skillvalue];
    newFormValues.splice(i, 1);
    setSkillvalue(newFormValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let skillParaContents = [];
    let isSkillFilled = false;
    skillvalue.every((element) => {
      if (element.skill != "") {
        isSkillFilled = true;
        return false;
      }
      return true;
    });
    if (!isSkillFilled) {
      navigate(pathRedirect.next);
    } else {
      const updatedSkill = skillvalue.filter((x) => x.skill !== "");
      updatedSkill.map((element, i) =>
        skillParaContents.push({
          id: !!element.id ? element.id : EMPTY_GUID,
          paraId: skillParaGuid,
          content: JSON.stringify(element),
          order: i,
          createdOn: moment(new Date()),
          modifiedOn: moment(new Date()),
        })
      );
      var para = {
        id: skillParaGuid,
        docId: docGUID,
        paraContents: [...skillParaContents],
        order:
          !!skills && !!skills.Paragraphs && !!skills.Paragraphs.order && skills.Paragraphs.order != 0
            ? skills.Paragraphs.order
            : SECTION_ORDER.SKLS,
        paraCD: SECTION_TYPE.SKLS,
        sectionCD: SECTION_CODE.SKLS,
        paraName: !!paraName ? paraName : t("skill.title"),
      };
      if (!!skillParaGuid && skillParaGuid !== EMPTY_GUID) {
        updateSkillsParagraph(docGUID, skillParaGuid, para)
          .then((response) => {
            console.log(response);
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        createSkillsParagraph(para)
          .then((response) => {
            appLogger.log("inside saveskillsconnect then " + response);
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
    }
  };
  let ratingOptions = t("skill.skill_rating.data", { returnObjects: true });

  return (
    <>
      <Helmet>
        <title>{t("page_title.skill")}</title>
      </Helmet>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={4} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}

      <section className={classNames(funnelStepsComplete ? "" : "sidebar-sticky", "section main-content templates")}>
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className=" builder-header exp-header">
                <hgroup>
                  <h1>{t("skill.headline")}</h1>
                  <h4>{t("skill.subheading")}</h4>
                </hgroup>
                <WritingTips section={SECTION_TYPE.SKLS} page="Skills" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-9  px-2">
                {skillvalue.map((element, index) => (
                  <div className="row" key={index}>
                    <div className="form-group col-md-6">
                      <label>{t("skill.skill")}</label>
                      <InputText
                        showLabel={false}
                        id="skill"
                        placeholder={index < 10 ? skillList.data[index].placeholder : ""}
                        name="skill"
                        value={element.skill || ""}
                        maxLength={100}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>{t("skill.exp_level")}</label>
                      <div className="remove-wrapper option-select">
                        <RBDropDown
                          selectedValue={element.rating || ""}
                          index={index}
                          options={ratingOptions}
                          onChange={ratingHandleChange}
                        />
                        {index ? (
                          <button
                            type="button"
                            className="button btn-remove remove "
                            onClick={() => removeFormFields(index)}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--skills">
                <ResumeMap />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {skillvalue.length < 10 && (
                  <HashLink smooth to="#footerScroll">
                    <Button
                      id="resume-edit-btn"
                      isLoading={false}
                      disabled={false}
                      icon={<span className="fa fa-plus"></span>}
                      onClick={() => addFormFields()}
                      classNames="btn btn-primary btn-lg add-exp-btn is-mobile mt-5"
                      ctaText={" " + t("skill.add_more_skills")}
                    />
                  </HashLink>
                )}
              </div>
              <div className="btn-container tips-btn-container fbn btn-justify">
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={backClickHandler}
                  classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                  id="skill_back_button"
                  ctaText={t("skill.back_action")}
                />
                {skillvalue.length < 10 && (
                  <HashLink smooth to="#footerScroll">
                    <Button
                      id="add_more_skills"
                      isLoading={false}
                      disabled={false}
                      icon={<span className="fa fa-plus"></span>}
                      onClick={() => addFormFields()}
                      classNames="btn btn-primary btn-lg add-exp-btn is-desktop"
                      ctaText={" " + t("skill.add_more_skills")}
                    />
                  </HashLink>
                )}
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={handleSubmit}
                  classNames="btn btn-primary btn-lg submit-on-enter save-n-next-btn"
                  id="skill_next_button"
                  ctaText={t("skill.save_and_next")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="footerScroll">
        <Footer />
      </div>
    </>
  );
};

export default Skill;
