import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  skuGuid: "",
  skuGuidDetails: null,
  orderId: null,
  isLoading: false,
};

export const fetchSkuSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    skuGuid: data.id,
    skuGuidDetails: data,
    orderId: null,
    isLoading: true,
  };
};

export const fetchSkuFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const fetchOrderSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    orderId: data,
  };
};

export const fetchOrderFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_SKU_SUCCESS]: fetchSkuSuccess,
  [Types.FETCH_SKU_FAILURE]: fetchSkuFailure,
  [Types.FETCH_ORDER_SUCCESS]: fetchOrderSuccess,
  [Types.FETCH_ORDER_FAILURE]: fetchOrderFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
