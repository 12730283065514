import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv11PureCSS = css`
  .resume-layout.cv11 {
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv11 .resume__section {
    margin-bottom: 0;
    padding-top: 2em;
    padding-bottom: 1.6em;
  }
  .resume-layout.cv11 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    margin-bottom: 0.5em;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv11 .sub-headline {
    display: block;
  }
  .resume-layout.cv11 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv11 .resume__section.resume__heading {
    text-align: center;
    border-top: 0;
    padding-top: 1rem;
  }
  .resume-layout.cv11 .resume__section.resume__heading .info-item {
    margin-bottom: 2px;
  }
  .resume-layout.cv11 .resume__section.resume__heading .info-item .info-label {
    display: none;
  }
  .resume-layout.cv11 .resume__section.resume__heading .info-item .info-text {
    width: 100%;
  }
  .resume-layout.cv11 .resume__heading .info-main {
    display: grid;
  }
  .resume-layout.cv11 .resume__heading .info-main .info-item {
    margin-bottom: 3px;
  }
  .resume-layout.cv11 .resume__heading .info-main .info-item:first-child {
    order: 2;
  }
  .resume-layout.cv11 .resume__heading .info-main .info-item:nth-child(2) {
    order: 3;
  }
  .resume-layout.cv11 .resume__heading .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv11 .resume__section .sub-headline h2 {
    border-bottom: 2px dotted #444;
    font-family: "Merriweather", serif;
    font-size: 1.29em;
    margin-bottom: 1.66em;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 0.11em dotted;
    padding-bottom: 0.28em;
  }
  .resume-layout.cv11 .resume__section h1,
  .resume-layout.cv11 .resume__section h3 {
    font-family: "Merriweather", serif;
    font-size: 1.15em;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-weight: normal;
  }
  .resume-layout.cv11 .info-item .info-label {
    display: none;
  }
  .resume-layout.cv11 .xp-item {
    display: block;
  }
  .resume-layout.cv11 .xp-item .xp-date,
  .resume-layout.cv11 .xp-item .xp-job {
    // font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv11 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv11 .sub-headline .svg-icon {
    left: 0;
    margin-right: 10px;
  }
  .resume-layout.cv11 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv11 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv11 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv11 .resume__education .edu-hide {
    display: block;
  }

  .resume-layout.cv11 .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv11 .wrap .left-col .extra {
    display: block;
  }
  .resume-layout.cv11 .wrap .left-col .extra .extra-info {
    float: left;
    font-weight: bold;
    padding-right: 20px;
    position: relative;
  }
  .resume-layout.cv11 .wrap .left-col .extra .extra-info:after {
    position: absolute;
    right: 6px;
    content: " - ";
  }
  .resume-layout.cv11 .wrap .left-col .extra .extra-details {
    padding-left: 1.5rem;
    font-style: normal;
  }
`;

export const Cv11PureStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv11PureCSS}
`;
