import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TempEducation from "./component";
import { setCurrentPara } from "../../../pages/funnel-step/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentParaConnect: setCurrentPara,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TempEducation);
