import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getPaymentServiceEndPoint } from "../services/api-constants";
import { readCookie } from "../utils/helpers";

class PaymentService {
  PaymentService(url) {
    return getPaymentServiceEndPoint(url);
  }
  getPlans(data) {
    appLogger.log("inside createUser ");
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const createPaymentEndpoint = this.PaymentService(`${endPoints.paymentService.GET_PLANS}`);
    return apiService.post(createPaymentEndpoint, data, headers);
  }
  paymentProcess(data) {
    appLogger.log("inside payment process ");
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const createPaymentProcessEndpoint = this.PaymentService(`${endPoints.paymentService.PAYMENT_PROCESS}`);
    return apiService.post(createPaymentProcessEndpoint, data, headers);
  }
  getSkuDetails(skuId, locale) {
    //appLogger.log("inside get Sku Details ");
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var createSkuDetailsEndpoint = `${endPoints.paymentService.GET_SKU_DETAILS}/${skuId}`;
    if (!!locale) {
      createSkuDetailsEndpoint = `${endPoints.paymentService.GET_SKU_DETAILS}/${skuId}/${locale}`;
    }
    const getSkuDetailsEndpoint = this.PaymentService(createSkuDetailsEndpoint);
    return apiService.get(getSkuDetailsEndpoint, null, headers);
  }
  isCardInBadBins(bin) {
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    var badbinEndPoint = `${endPoints.paymentService.BAD_BINS}?bin=${bin}`;
    const getSkuDetailsEndpoint = this.PaymentService(badbinEndPoint);
    return apiService.get(getSkuDetailsEndpoint, null, headers);
  }
  CreatePaymentIntent(data) {
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const paymentIntentEndPoint = this.PaymentService(`${endPoints.paymentService.PAYMENT_INTENT}`);
    return apiService.post(paymentIntentEndPoint, data, headers);
  }
  CalculatePricing(skuGuid, currency, skuCategory, users) {
    const jwtToken = readCookie("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    appLogger.log("inside CalculatePricing ");
    const PricingEndpoint = this.PaymentService(
      `${endPoints.paymentService.GET_GUSETUSER_PRICING.replace(":skuguid", skuGuid)
        .replace(":currency", currency)
        .replace(":skucategory", skuCategory)
        .replace(":users", users)}`
    );
    return apiService.get(PricingEndpoint, null, headers);
  }
  getTaxEstimate(stateCode, zipCode, planAmount) {
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    const taxMatrixEndPoint = this.PaymentService(
      `${endPoints.paymentService.TAX_MATRIX.replace(":stateCode", stateCode)
        .replace(":zipCode", zipCode)
        .replace(":planAmount", planAmount)}`
    );
    return apiService.get(taxMatrixEndPoint, null, headers);
  }
}

export default new PaymentService();
