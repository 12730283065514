import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv8TotamCSS = css`
  .resume-layout.cv8 {
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv8 .resume__section.resume__heading h1 {
    padding-top: 0.5rem;
    text-transform: capitalize;
    margin-bottom: 3rem;
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.2em;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv8 .sub-headline {
    margin-bottom: 1em;
  }
  .resume-layout.cv8 .resume__section .sub-headline h2 {
    display: inline-block;
    font-size: 1.71em;
    line-height: 1;
    font-weight: 900;
    text-transform: capitalize;
  }
  .resume-layout.cv8 .resume__section h1,
  .resume-layout.cv8 .resume__section h3 {
    display: inline-block;
    font-size: 1.15em;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 0.25em;
    text-transform: capitalize;
  }
  .resume-layout.cv8 .sub-headline .svg-icon {
    display: inline-block;
  }
  .resume-layout.cv8 .resume-aside .sub-headline .svg-icon {
    display: none;
  }
  .resume-layout.cv8 .personal-info {
    display: block;
  }
  .resume-layout.cv8 .info-item {
    display: block;
  }
  .resume-layout.cv8 .info-item .info-label,
  .resume-layout.cv8 .info-item .info-text,
  .resume-layout.cv8 .wrap .left-col,
  .resume-layout.cv8 .wrap .extra-info,
  .resume-layout.cv8 .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv8 .wrap .extra-info {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .resume-layout.cv8 .extra {
    display: block;
  }
  .resume-layout.cv8 .extra .extra-details .progress-starts,
  .resume-layout.cv8 .extra .extra-details .progress-line {
    display: none;
  }
  .resume-layout.cv8 .extra .extra-details .progress-circle {
    display: block;
  }
  .resume-layout.cv8 .xp-item {
    display: block;
  }
  .resume-layout.cv8 .xp-item .xp-date,
  .resume-layout.cv8 .xp-item .xp-job {
    font-size: 1em;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv8 .xp-item .xp-date {
    color: #42a2e0;
  }
  .resume-layout.cv8 .resume-main .resume__section {
    padding-bottom: 1em;
    border-left: 0.07em solid #cbcbcb;
  }
  .resume-layout.cv8 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv8 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv8 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv8 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv8 .resume__education .edu-hide {
    display: block;
  }
`;

export const Cv8TotamStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv8TotamCSS}
`;
