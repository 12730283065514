import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ImageUploadModal from "./component";
import { saveImageAndGetBlobUrl } from "./actions";
import { setImageUrl } from "./actions";

const mapStateToProps = ({ imageUploadPreview }) => ({
  imageUploadPreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveImageAndGetBlobUrlConnect: saveImageAndGetBlobUrl,
      setImageUrlConnect: setImageUrl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadModal);
