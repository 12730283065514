import { css, createGlobalStyle } from "styled-components";

export const CommonCSS = css`
  .resume {
    font-size: 13px;
  }
  .resume-layout {
    padding: 3em 3em 1em;
    //font-size: 0.875em;
    font-size: 13px;
    // font-size: 1em;
    font-weight: normal;
  }
  @media (max-width: 767px) {
    .resume,
    .resume-layout {
      font-size: 5.409177471108444px;
    }
  }
  .resume-layout h1 {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .resume-layout .sub-headline {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .resume-layout .resume__content .sub-headline h2 {
    //font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .resume-layout .resume__content h3 {
    //font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .resume-layout .sub-headline .svg-icon {
    display: none;
    background: white;
    margin-left: -3.25em;
    margin-right: 20px;
  }
  .resume-layout .sub-headline .svg-icon svg {
    width: 2rem;
    height: 2rem;
  }
  .resume-layout .personal-info.sub-headline {
    display: none;
  }
  .resume-layout .info-item .svg-icon {
    display: none;
    color: #42a2e0;
    margin-top: 3px;
    margin-right: 10px;
  }
  .resume-layout .info-item {
    display: flex;
    margin-bottom: 1em;
  }
  .resume-layout .info-item .info-label {
    width: 12%;
    font-weight: bold;
    display: inline-block;
  }
  .resume-layout .info-item .info-text {
    width: 88%;
  }
  .resume-layout .resume__summary ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .resume-layout .resume__summary ul li {
    margin-bottom: 10px;
  }
  .resume-layout .xp-item {
    overflow: hidden;
    margin-bottom: 1.6em;
    display: flex;
  }
  .resume-layout .xp-item .xp-date {
    width: 30%;
    font-weight: bold;
    text-align: right;
    padding-right: 1.6em;
    min-height: 1px;
  }
  .resume-layout .xp-item .xp-date .xp-city {
    display: block;
  }
  .resume-layout .xp-item .xp-job {
    width: 70%;
    border: 0;
    border-left: 1px solid lightgrey;
    padding-left: 1.5rem;
  }
  .resume-layout .xp-item .xp-job .xp-detail {
    margin-top: 1rem;
    line-height: 1.6;
  }
  .resume-layout .xp-item .xp-job .xp-detail ul {
    margin: 0;
  }
  .resume-layout .wrap {
    display: flex;
  }
  .resume-layout .wrap .left-col {
    width: 50%;
  }
  .resume-layout .extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
  }
  .resume-layout .extra .extra-details {
    position: relative;
    // font-size: 0.75rem;
    text-align: left;
    font-style: italic;
    margin-left: auto;
    padding-right: 20px;
  }
  .resume-layout .extra .extra-details .progress-starts {
    display: block;
  }
  .resume-layout .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout .extra .extra-details .progress-circle .extra-details__bg {
    min-height: 22px;
  }
  .resume-layout
    .extra
    .extra-details
    .progress-circle
    .extra-details__bg
    > span {
    background-color: #e1e1e1;
    display: block;
    float: left;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.54em;
    border-radius: 50%;
  }
  .resume-layout
    .extra
    .extra-details
    .progress-circle
    .extra-details__bg
    > span.active {
    background-color: #42a2e0;
  }
  .resume-layout
    .extra
    .extra-details
    .progress-circle
    .extra-details__progress {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
  }
  .resume-layout
    .extra
    .extra-details
    .progress-circle
    .extra-details__progress
    > span {
    background-color: #e1e1e1;
    display: block;
    width: 1.5em;
    height: 1.5em;
    float: left;
    margin-right: 0.54em;
    border-radius: 50%;
  }
  .resume-layout .extra .extra-details .progress-line {
    display: none;
    margin-top: 1em;
    background-color: #d1d9e1;
    width: 100%;
    height: 5px;
    position: relative;
    margin-bottom: 5px;
  }
  .resume-layout .extra .extra-details .progress-line .extra-details__progress {
    background-color: #f68931;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    .resume-layout .extra .extra-details .progress-line,
    .resume-layout
      .extra
      .extra-details
      .progress-line
      .extra-details__progress {
      height: 3px;
    }
  }
  .resume-layout .resume-body {
    display: flex;
  }
  .resume-layout .resume-body h1 {
    font-size: 2rem;
  }
  .resume-layout .resume-body .resume-aside {
    width: 33%;
    margin-right: 15px;
    padding-top: 1rem;
  }
  .resume-layout .resume-body .resume-main {
    width: 77%;
    padding-top: 2rem;
  }
  .resume-layout
    .resume-body
    .resume__section
    button.resume__control-button:not(:last-child)::after,
  .resume-layout
    .resume-body
    .resume__section
    button.resume__control-button:not(:last-child)::after {
    display: none;
  }
  .resume-layout .resume-body .resume__section .resume__controls,
  .resume-layout .resume-body .resume__section .resume__move {
    left: -10px;
    right: -10px;
  }
  .resume-layout .resume-body .resume__section .controls-menu {
    top: -10px;
    right: -8px;
  }
  .resume-layout .resume-body .resume__section .controls-menu {
    width: 33px;
  }
  .resume-layout
    .resume-body
    .resume__section
    .controls-menu
    .resume__control-text {
    display: none;
  }
  .resume-layout .resume__experience .exp-show {
    display: block;
  }
  .resume-layout .resume__experience .exp-hide {
    display: none;
  }
  .resume-layout .resume__education .edu-show {
    display: block;
  }
  .resume-layout .resume__education .edu-hide {
    display: none;
  }
  .resume-layout .txt-bold {
    font-weight: bold !important;
  }
  .cvltr-view-layout-box .resume__section .resume__controls,
  .resume-layout-box .resume__section .resume__controls {
    min-height: 103px;
  }
  .cvltr-view-layout-box .resume-main .resume__section,
  .resume-layout-box .resume-main .resume__section {
    padding-left: 2.25em;
  }
  .resume__section.resume__others .wrap ul ul {
    list-style-type: disc;
  }
  // @media print {
  //   @media (max-width: 767px) {
  //     .resume,
  //     .resume-layout {
  //       font-size: 13px;
  //     }
  //   }
  // }
  @media all {
    .cvltr-view-layout-box .resume__section .controls-menu .btn-green,
    .resume-layout-box .resume__section .controls-menu .btn-green {
      text-align: center;
      font-size: 1.4rem;
      font-weight: normal;
      padding: 5px 10px;
      font-family: "Rubik", sans-serif;
    }
    .cvltr-view-layout-box .resume__section:hover .resume__controls,
    .cvltr-view-layout-box .resume__section:hover .controls-menu,
    .cvltr-view-layout-box .resume__section:hover .resume__move,
    .resume-layout-box .resume__section:hover .resume__controls,
    .resume-layout-box .resume__section:hover .controls-menu,
    .resume-layout-box .resume__section:hover .resume__move {
      display: none;
    }
    .cvltr-view-layout-box .resume__section:hover .resume__controls,
    .cvltr-view-layout-box .resume__section:hover .controls-menu,
    .cvltr-view-layout-box .resume__section:hover .resume__move,
    .resume-layout-box .resume__section:hover .resume__controls,
    .resume-layout-box .resume__section:hover .controls-menu,
    .resume-layout-box .resume__section:hover .resume__move {
      display: block;
    }
    .cvltr-view-layout-box .resume__section .resume__controls,
    .cvltr-view-layout-box .resume__section .controls-menu,
    .cvltr-view-layout-box .resume__section .resume__move,
    .resume-layout-box .resume__section .resume__controls,
    .resume-layout-box .resume__section .controls-menu,
    .resume-layout-box .resume__section .resume__move {
      display: none;
      font-size: 12px;
    }
    .resume-layout
      .resume-body
      .resume__section
      .controls-menu
      .resume__control-text {
      display: none;
    }
    .resume__control-button {
      display: none;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
  @media print {
    .dashboard-item__img .resume-layout {
      font-size: unset !important;
    }
  }
`;

export const CommonStyle = createGlobalStyle`
  ${CommonCSS}
`;
