import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { APP_NAVIGATION } from "../../../../utils/constants";
import PathHelper from "../../../../utils/path-helper";

const CoverLetterFooter = () => {
  const { t } = useTranslation();
  let pathRedirectPrivacy = PathHelper(APP_NAVIGATION.CVLTRPRVY, false);
  let pathRedirectContact = PathHelper(APP_NAVIGATION.CVLTRCNTC, false);
  let pathRedirectTerms = PathHelper(APP_NAVIGATION.CVLTRTUSE, false);

  return (
    <div className="cover-letter-footer">
      <div>
        <div className="d-flex justify-content-center mb-2">
          <a
            className="text-center body-font text-secondary"
            href={pathRedirectPrivacy.next}
            title="Privacy policy"
            target="_blank"
          >
            <u> {t("footer.privacy_policy")}</u> |
          </a>
          <a
            className="text-center body-font text-secondary"
            href={pathRedirectTerms.next}
            title=" Terms and conditions"
            target="_blank"
          >
            <u>{t("footer.terms_condition")}</u> |
          </a>
          <a
            className="text-center body-font text-secondary"
            href={pathRedirectContact.next}
            title=" Contact us"
            target="_blank"
          >
            <u>{t("footer.contact_us")}</u>
          </a>
        </div>
        <p className="text-center body-font mb-0">
          © {moment().format("yyyy")}, WorkSimpli Software, LLC. a subsidiary of
          LifeMD Inc., All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default CoverLetterFooter;
