import { createTypes } from "reduxsauce";

export default createTypes(
  `
    GET_SUCCESS
    GET_FAILED
`,
  {
    prefix: "resumes/",
  }
);
