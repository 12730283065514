import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/shared/button";
import { Trans, useTranslation } from "react-i18next";
import appLogger from "../../utils/app-logger";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoader from "../../components/shared/loader";
import { Helmet } from "react-helmet";
import { userGuid } from "../../utils/general-config";

const CancelSubscription = ({ cancelUserConnect, funnelStep }) => {
  const { t } = useTranslation();
  const { userGUID, email } = funnelStep || {};
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState("d-none");
  let pathRedirect = PathHelper(APP_NAVIGATION.CASU);
  const { locale } = useParams();
  const onCancelSubscriptionHandler = () => {
    setShowLoader("d-block");
    let localeval = "";
    if (!!locale) {
      localeval = locale;
    }
    var data = {
      isEndOfTerm: true,
      comment: "USER:CancelAccount",
      locale: localeval,
    };
    let uGuid = !!userGUID ? userGUID : userGuid();
    cancelUserConnect(data, uGuid)
      .then((response) => {
        appLogger.log("inside cancelUserConnect then ");
        // navigate(ROUTE_CONSTANT.USER_ADMIN_SETTINGS);
        navigate(pathRedirect.next);
      })
      .catch((err) => {
        setShowLoader("d-none");
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };

  const onbackBtnHandler = () => {
    navigate(pathRedirect.back);
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.cancel_subscription")}</title>
      </Helmet>
      <Header />
      <ReactLoader showloader={showLoader} />
      <section className="section main-content">
        <div className="container">
          <div className="alignment-container">
            <div className="row mb-4">
              <div className="col-12 headline-group">
                <hgroup>
                  <h1>{t("cancel_subscriptions.heading")}</h1>
                  <h4>{t("cancel_subscriptions.sub_heading")}</h4>
                </hgroup>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 cancel-subheading">
                <h5 className="mb-5">{t("cancel_subscriptions.tips_title")}</h5>
                <ul
                  className="writing-tips list-group"
                  dangerouslySetInnerHTML={{
                    __html: t("cancel_subscriptions.tips_list"),
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="btn-container">
                <Button
                  isLoading={false}
                  disabled={false}
                  classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                  ctaText={t("cancel_subscriptions.cancel_subscription")}
                  onClick={onCancelSubscriptionHandler}
                />
                <div className="fbn">
                  <Button
                    isLoading={false}
                    disabled={false}
                    classNames="btn btn-primary btn-lg submit-on-enter"
                    ctaText={t("cancel_subscriptions.back")}
                    onClick={onbackBtnHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CancelSubscription;
