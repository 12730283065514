import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_SUMMARY_SUCCESS
    FETCH_SUMMARY_FAILURE
    QUACK
    RESET_SUMMARY
    UPDATE_SUMMARY_SUCCESS
    UPDATE_SUMMARY_FAILED
    RENAME_SUMMARY
`,
  {
    prefix: "summary/",
  }
);
