import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const SignatureColorDropdown = ({
  key,
  fontColor,
  setSelectedFonts,
  selectedFont,
  name,
  callBackHandlSignature,
}) => {
  const { t } = useTranslation();
  const colorBtnRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const signatureColorHandler = () => {
    setShowDropdown((d) => !d);
  };
  const handleClickOutside = (event) => {
    if (colorBtnRef.current && !colorBtnRef.current.contains(event.target)) {
      if (colorBtnRef.current.attributes["data-color"].value === "show") {
        colorBtnRef.current.click();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      {key !== t("signature.upload") && (
        <>
          <button
            className={` ${
              selectedFont.fontColor === "#111111" && "cvltr-color-border-black"
            }
              ${selectedFont.fontColor == "#044F8A" && "cvltr-bluecircle"}
              btn-alignment p-0 px-3 signaturecolor mb-3`}
            data-color={showDropdown ? "show" : "hide"}
            type="button"
            onClick={(e) => signatureColorHandler(e)}
            ref={colorBtnRef}
          >
            <FontAwesomeIcon icon={faCircle} />
          </button>
          <div
            className={classNames(
              showDropdown ? "d-flex" : "",
              "dropdown-menu"
            )}
          >
            <div className="cvltr-color mt-0">
              <div className="btn-color-group">
                {fontColor.map((item, index) => {
                  return (
                    <div className="btn-color-div" key={index}>
                      <button
                        className={`btn ${
                          selectedFont.fontColor == item.color &&
                          "cvltr-color-border-black"
                        }
                ${item.type == "blue" && "cvltr-bluecircle"}
                btn-alignment p-0`}
                        type="button"
                        onClick={() => {
                          setSelectedFonts({
                            ...selectedFont,
                            fontColor: item.color,
                          });
                          key == t("signature.type") &&
                            callBackHandlSignature(name);
                        }}
                      >
                        <FontAwesomeIcon icon={faCircle} />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignatureColorDropdown;
