import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./download-loader-modal.css";
const { REACT_APP_ENV } = process.env;

const DownloadLoaderPopup = ({
  show,
  toggleShow,
  header,
  inputLabel,
  isDownloadError,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-download-loader-section "
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label
                className="string optional resume-built"
                htmlFor="experience_start_date"
              >
                {inputLabel}
              </label>
              <div className="loader-img">
                <img
                  src={process.env.PUBLIC_URL + "/static/images/icon-renew.svg"}
                />
              </div>
              <span className="waitfor_seconds">
                {isDownloadError ? "" : t("review.please_wait_for_few_seconds")}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadLoaderPopup;
