import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetStore } from "../../pages/review/actions";
import CoverLetterAction from "./component";
import {deleteCoverLetter, duplicateCoverLetter} from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStoreConnect: resetStore,
      duplicateCoverLetterConnect: duplicateCoverLetter,
      deleteCoverLetterConnect: deleteCoverLetter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetterAction);
