import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterBodyRecommend from "./component";
import { setDocGUID } from "../funnel-step/actions";
import {
  createRecommendParagraph,
  saveSelected,
  updateRecommendParagraph,
} from "./actions";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import {
  handleSteps,
  previewTemplateAction,
} from "../cover-letter-template/actions";

const mapStateToProps = ({
  funnelStep,
  cvltrBodyRecommend,
  jobtitle,
  desiredCompany,
  cvltrExperience,
  cvltrSkills,
  personalInfo,
  cvltrTemplatePreview,
}) => ({
  funnelStep,
  cvltrBodyRecommend,
  jobtitle,
  desiredCompany,
  cvltrExperience,
  cvltrSkills,
  personalInfo,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      recommendConnect: createRecommendParagraph,
      updateRecommendParagraph: updateRecommendParagraph,
      SaveDocGUIDConnect: setDocGUID,
      previewTemplateAction: previewTemplateAction,
      updateProgress: updateProgress,
      handleSteps: handleSteps,
      saveSelected: saveSelected,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterBodyRecommend);
