import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Cl01Modern from "../../cvlt/cover-letter-preview-templates/cl-01-modern";
import Cl02Elegant from "../../cvlt/cover-letter-preview-templates/cl-02-elegant";
import Cl03Professional from "../../cvlt/cover-letter-preview-templates/cl-03-professional";
import Cl04Classic from "../../cvlt/cover-letter-preview-templates/cl-04-classic";
import Cl05Contemporary from "../../cvlt/cover-letter-preview-templates/cl-05-contemporary";
import Cl06Standard from "../../cvlt/cover-letter-preview-templates/cl-06-standard";
import Cl07Professional from "../../cvlt/cover-letter-preview-templates/cl-07-professional";
import Cl08Modern from "../../cvlt/cover-letter-preview-templates/cl-08-modern";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const SelectedCoverLetterComponent = ({ orderComponent, name }) => {
  const SelectiveSection = {
    Modern: Cl01Modern,
    Elegant: Cl02Elegant,
    Professional: Cl03Professional,
    Classic: Cl04Classic,
    Contemporary: Cl05Contemporary,
    Standard: Cl06Standard,
    Professional1: Cl07Professional,
    Modern1: Cl08Modern,
  };
  const SelectedCoverLetter = SelectiveSection[name];
  if (!SelectedCoverLetter) {
    return null;
  }
  return <SelectedCoverLetter orderComponent={orderComponent} />;
};

const CoverLetterPreviewButton = ({ funnelStep, docReview }) => {
  const { templateCd } = funnelStep;
  const { t } = useTranslation();

  const reviewDoc = docReview && docReview.reviewDoc ? docReview.reviewDoc : ""

  const initialOrdering = [
    t("opener.opener_recommend"),
    t("opener.body_recommend"),
    t("opener.closer_recommend"),
  ];

  const [orderComponent, setOrderComponent] = useState(initialOrdering)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (reviewDoc && reviewDoc !== "") {
      let filteredDocPara = reviewDoc?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }

      filteredDocPara.sort((a, b) => a.order - b.order)

      let filterPara = []

      filteredDocPara.map((item) => {
        filterPara.push(item.paraName)
      })

      setOrderComponent(filterPara)
    } else {
      setOrderComponent(initialOrdering)
    }
  }, [reviewDoc])

  return (
    <>
      <a
        onClick={handleShow}
        href="JAVASCRIPT:void(0)"
        data-bs-target="#preview-mode"
        data-bs-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
        className="d-flex align-items-center pe-3 justify-content-end prv-btn"
      >
        {/* <img
          alt=""
          src={process.env.PUBLIC_URL + "/static/images/ic_view.svg"}
          className="me-2"
        /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          width={16}
          height={16}
          fill={"#f07201"}
          className="me-2"
        >
          <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
        </svg>
        <p className="btn-color sm-font fw-600 mb-0">
          <b className="cursor-pointer">{t("personal_info.preview")}</b>
        </p>
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="cvltr-preview-modal-header"
        ></Modal.Header>
        <Modal.Body className="cvltr-preview-modal-spacing cvtr-prv-modal">
          <SelectedCoverLetterComponent orderComponent={orderComponent} name={templateCd} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CoverLetterPreviewButton;
