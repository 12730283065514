import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text/component";
import RBDropDown from "../../components/shared/dropdown/component";
import Footer from "../../components/footer";
import "./language.scss";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import ResumeMap from "../../components/resume-map";
import appLogger from "../../utils/app-logger";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { event } from "jquery";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, SECTION_CODE } from "../../utils/constants";
import { EMPTY_GUID, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import moment from "moment";
import WritingTips from "../../components/writing-tips/component";
import { getLanguageList } from "../../services/master-data";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";

const Language = ({
  languages, //rootreducer
  funnelStep, //rootreducer
  createLanguagesParagraph,
  updateLanguagesParagraph,
}) => {
  const { t } = useTranslation();
  const {
    Paragraphs: { paraContents, paraName },
  } = languages;
  const { docGUID, funnelStepsComplete } = funnelStep || {};
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  let pathRedirect = PathHelper(APP_NAVIGATION.LANG, funnelStepsComplete);

  const { data, isLoading, total, error } = languages || {};
  const [languageParaGuid, setLanguageParaGuid] = useState(EMPTY_GUID);
  const [languageList, setLanguageList] = useState(getLanguageList());
  const [languagevalue, setLanguagevalue] = useState(
    data != undefined && data.length > 0
      ? data
      : [{ language: "", rating: "", ratingText: "" }]
  );
  useEffect(() => {
    if (!!paraContents && paraContents.length > 0) {
      var data = paraContents.map((item) => {
        return {
          language: JSON.parse(item.content)["language"],
          rating: JSON.parse(item.content)["rating"],
          ratingText: JSON.parse(item.content)["ratingText"],
        };
      });
      setLanguagevalue(data);
      setLanguageParaGuid(paraContents[0].paraId);
    }
  }, []);

  const handleChange = (i, e) => {
    let newFormValues = [...languagevalue];
    newFormValues[i][e.target.name] = e.target.value;
    setLanguagevalue(newFormValues);
  };
  const ratingHandleChange = (i, e) => {
    let newFormValues = [...languagevalue];
    newFormValues[i]["rating"] = e.target.value;
    newFormValues[i]["ratingText"] = RatingDisplay(e.target.value);
    setLanguagevalue(newFormValues);
  };

  const addFormFields = () => {
    setLanguagevalue([
      ...languagevalue,
      { language: "", rating: "", ratingText: "" },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...languagevalue];
    newFormValues.splice(i, 1);
    setLanguagevalue(newFormValues);
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const RatingDisplay = (rating) => {
    let ratingDisplay = "";
    if (!!rating && rating > 0) {
      ratingDisplay = t("language.language_Options").split(",")[rating - 1];
    }
    return ratingDisplay;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let isLanguageFilled = false;
    languagevalue.every((element) => {
      if (element.language != "") {
        isLanguageFilled = true;
        return false;
      }
      return true;
    });
    let languageParaContents = [];
    if (!isLanguageFilled) {
      navigate(pathRedirect.next);
    } else {
      //   const updatedLanguage = languagevalue.map((x) =>
      //   x.language !== "" && x.rating === "" ? { ...x, rating: "Basic" } : x
      // );
      const updatedLanguage = languagevalue.filter((x) => x.language !== "");
      updatedLanguage.map((element, i) =>
        languageParaContents.push({
          id: !!element.id ? element.id : EMPTY_GUID,
          paraId: languageParaGuid,
          content: JSON.stringify(element),
          order: i,
          createdOn: moment(new Date()),
          modifiedOn: moment(new Date()),
        })
      );

      var para = {
        id: languageParaGuid,
        docId: docGUID,
        paraContents: [...languageParaContents],
        order:
          !!languages &&
          !!languages.Paragraphs &&
          !!languages.Paragraphs.order &&
          languages.Paragraphs.order != 0
            ? languages.Paragraphs.order
            : SECTION_ORDER.LANG,
        paraCD: SECTION_TYPE.LANG,
        sectionCD: SECTION_CODE.LANG,
        paraName: !!paraName ? paraName : t("language.title"),
      };
      if (!!languageParaGuid && languageParaGuid !== EMPTY_GUID) {
        updateLanguagesParagraph(docGUID, languageParaGuid, para)
          .then((response) => {
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        createLanguagesParagraph(para)
          .then((response) => {
            appLogger.log("inside saveLanguagesconnect then " + response);
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
    }
  };
  let ratingOptions = t("language.language_rating.data", {
    returnObjects: true,
  });
  return (
    <>
      <Helmet>
        <title>{t("page_title.language")}</title>
      </Helmet>
      <Header />
      <section className="section main-content templates">
        <div className="container-fluid">
          <div className="alignment-container">
            <div className="row">
              <div
                className="builder-header exp-header "
                style={{ marginBottom: 50 }}
              >
                <hgroup>
                  <h1>{t("language.title")}</h1>
                  <h4>{t("language.subheading")}</h4>
                </hgroup>
                <WritingTips section={SECTION_TYPE.LANG} page="Language" />
              </div>
            </div>
            <div className="row language-container">
              {" "}
              <div className="col-md-12 col-lg-9">
                {languagevalue.map((element, index) => (
                  <div className="row " key={index}>
                    <div className="form-group col-md-6">
                      <label>{t("language.language")}</label>
                      <InputText
                        showLabel={false}
                        id="language"
                        placeholder={
                          index < 10 ? languageList.data[index].placeholder : ""
                        }
                        name="language"
                        value={element.language || ""}
                        maxLength={100}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>{t("language.level")}</label>
                      <div className="remove-wrapper option-select">
                        <RBDropDown
                          selectedValue={element.rating || ""}
                          index={index}
                          options={ratingOptions}
                          onChange={ratingHandleChange}
                        />
                        {index ? (
                          <button
                            type="button"
                            className="button btn-remove remove"
                            onClick={() => removeFormFields(index)}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--languages">
                <ResumeMap />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {languagevalue.length < 10 && (
                  <HashLink smooth to="#footerScroll">
                    <Button
                      id="resume-edit-btn"
                      isLoading={false}
                      disabled={false}
                      icon={<span className="fa fa-plus"></span>}
                      onClick={() => addFormFields()}
                      classNames="btn btn-primary btn-lg add-exp-btn is-mobile mt-5"
                      ctaText={" " + t("language.add_more_language")}
                    />
                  </HashLink>
                )}
              </div>
              <div className="btn-container fbn btn-justify">
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={backClickHandler}
                  classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                  ctaText={t("language.back_action")}
                />
                {languagevalue.length < 10 && (
                  <HashLink smooth to="#footerScroll">
                    <Button
                      id="resume-edit-btn"
                      isLoading={false}
                      disabled={false}
                      icon={<span className="fa fa-plus"></span>}
                      onClick={() => addFormFields()}
                      classNames="btn btn-primary btn-lg add-exp-btn is-desktop"
                      ctaText={" " + t("language.add_more_language")}
                    />
                  </HashLink>
                )}
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={handleSubmit}
                  classNames="btn btn-primary btn-lg submit-on-enter float-end"
                  ctaText={t("language.save_and_next")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="footerScroll">
        <Footer />
      </div>
    </>
  );
};

export default Language;
