import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignUp from "./component";
import { setDocType } from "./../funnel-step/actions";
import {
  signUpUser,
  signUpExternalUser,
  eventServicePost,
  getUserDocs,
  loginAttempt,
  postDataToEventService,
  postDataToEventServiceSchedule,
} from "./actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUpUserConnect: signUpUser,
      signUpExternalUserConnect: signUpExternalUser,
      eventServicePostConnect: eventServicePost,
      getUserDocsConnect: getUserDocs,
      setDocTypeConnect: setDocType,
      loginAttemptConnect: loginAttempt,
      postDataToEventServiceConnect: postDataToEventService,
      postDataToEventServiceScheduleConnect: postDataToEventServiceSchedule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
