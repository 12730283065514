import React, { useState, useEffect } from "react";
import "./thank-you.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LanguageSelect from "../../languageSelect";
import Button from "../../components/shared/button";
import DownloadModal from "../../components/shared/download-modal";
import DownloadLoaderPopup from "../../components/shared/download-loader-popup";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, DOC_TYPE } from "../../utils/constants";
import { downloadUserResume } from "../../initialState";
import { Helmet } from "react-helmet";
import ReactLoader from "../../components/shared/loader";
import TrustBox from "../../components/trustBox";
import {
  getPortalCD,
  getTrustPilotURL,
  loadScript,
  readCookie,
  removeScript,
  setLocaleURL,
  PerquisiteBountyEnable,
} from "../../utils/helpers";
import { trackRBEvents } from "../../utils/tracking-events";
const Confirmation = ({
  ccpage,
  funnelStep,
  getUserDocsConnect,
  getUserConnect,
  getUserProfileConnect,
  setConfirmationConnect,
  getUserCoverLetterConnect,
  getBillingInfoConnect,
}) => {
  let pathRedirect = PathHelper(APP_NAVIGATION.CONF);
  let cvltrPathRedirect = PathHelper(APP_NAVIGATION.CVLTS);
  const queryParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDownloadBtn, setshowDownloadBtn] = useState("false");
  const [trustPilotURL, settrustPilotURL] = useState("");
  const { userGUID, docGUID, parentUserGuid, email, personalInfoName, docType } = funnelStep || {};
  const getTrustPilotUrl = getTrustPilotURL(email, userGUID);
  const redirectDashboardHandler = () => {
    //console.log("getTrustPilotURL", getTrustPilotUrl);
    settrustPilotURL(getTrustPilotUrl);
    if (getTrustPilotUrl !== "") {
      toggleTrustPilotModal(true);
    } else {
      if (docType !== DOC_TYPE.COVER_LETTER) {
        navigate(pathRedirect.next);
      } else {
        navigate(cvltrPathRedirect.next);
      }
    }
  };
  const { skuGuidDetails } = ccpage || {};
  // const [skuDetails, setSkuDetails] = useState(!!ccpage && !!ccpage.skuGuidDetails ? ccpage.skuGuidDetails : "");
  const [accountId, setAccountId] = useState("");
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [finishStatus, setfinishStatus] = useState(false);
  const [downloadResumeModal, setDownloadResumeModal] = useState(false);
  const [downloadLoaderModal, setDownloadLoaderModal] = useState(false);
  const { locale } = useParams();
  const [showLoader, setShowLoader] = useState("d-none");
  const [recurringPrice, setRecurringPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [downloadModalText, setDownloadModalText] = useState(t("review.resume_is_being_built"));
  const [downloadModalBtnText, setDownloadModalBtnText] = useState(t("review.review_download_btn"));
  const [downloadModalHeading, setDownloadModalHeading] = useState(t("review.review_download_heading"));
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [scheduleTypeCD, setScheduleTypeCD] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const toggleDownloadModal = () => {
    setDownloadResumeModal((d) => !d);
  };
  const toggleDownloadLoaderModal = () => {
    setDownloadLoaderModal((l) => !l);
  };
  const [trustPilotModal, setTrustPilotModal] = useState(false);
  const toggleTrustPilotModal = (t) => setTrustPilotModal(t);
  const downloadResume = (conversionType) => {
    if (docType !== DOC_TYPE.COVER_LETTER) {
      setDownloadModalText(t("review.resume_is_being_built"));
    } else {
      setDownloadModalText(t("review.cover_letter_is_being_built"));
      setDownloadModalHeading(t("cover_letters.cover_letter_download_heading"));
    }
    setIsDownloadError(false);
    setDownloadResumeModal((d) => !d);
    //downloadUserResume(docGUID, conversionType, "NRECO");
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setfinishStatus(true);
      getUserProfileConnect(userGUID)
        .then((response) => {
          if (
            !!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGUID) == true || !!parentUserGuid)
          ) {
            if (!!docGUID) {
              navigate(pathRedirect.back);
            } else {
              navigate(pathRedirect.cstm);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };
  useEffect(() => {
    // trackRBEvents("paymentSuccess", {
    //   eventLabel: "payment confirmation view",
    // });
    trackRBEvents("confirmationView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
      portallocale: !!locale ? locale : "en",
    });
    setShowLoader("d-block");
    if (!!docGUID && docType === DOC_TYPE.COVER_LETTER) {
      setDownloadModalBtnText(t("cover_letters.cover_letter_download_btn"));
      setshowDownloadBtn("true");
    } else if (!!docGUID && docType === DOC_TYPE.RESUME) {
      setshowDownloadBtn("true");
    }
    if (!!userGUID) {
      getUserConnect(userGUID).then((response) => {
        if (!!response) {
          setAccountId(response.accountId);
          if (localStorage.getItem("isAdmin") == "true" && !skuGuidDetails) {
            let localeVal = !!locale ? locale : "en";
            getBillingInfoConnect(userGUID, localeVal).then((resp) => {
              setScheduleTypeCD(resp.scheduleTypeCD);
              setCurrencySymbol(resp.currencySymbol);
              setDynamicTitle(resp.planKey);
              setRecurringPrice(parseFloat(resp.recurringPrice).toFixed(2));
              setUnitPrice(parseFloat(resp.unitPrice).toFixed(2));
              setConfirmationConnect(userGUID).then((response) => {
                if (!!response) {
                  setShowLoader("d-none");
                }
              });
            });
          } else {
            setScheduleTypeCD(skuGuidDetails.scheduleTypeCD);
            setCurrencySymbol(skuGuidDetails.currencySymbol);
            setDynamicTitle(skuGuidDetails.label);
            setRecurringPrice(parseFloat(skuGuidDetails.recurringPrice).toFixed(2));
            setUnitPrice(parseFloat(skuGuidDetails.unitPrice).toFixed(2));
            setConfirmationConnect(userGUID).then((response) => {
              if (!!response) {
                setShowLoader("d-none");
              }
            });
          }
        }
      });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }

    const url = window.location.pathname + window.location.search;
    window.history.pushState(null, null, url);
    window.addEventListener("popstate", onBackButtonEvent);
    loadScript("/app/vwoBilling.js");
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      removeScript("/app/vwoBilling.js");
    };
  }, []);
  const callBackDownloadResume = (formatName) => {
    //console.log(formatName);
    setDownloadResumeModal(false);
    settrustPilotURL(getTrustPilotUrl);
    if (getTrustPilotUrl !== "") {
      toggleTrustPilotModal(true);
    }
  };
  const downloadErrorMessage = (isError) => {
    if (isError === "error") {
      setIsDownloadError(true);
      setDownloadModalText(t("review.download_error_message"));
    } else {
      setIsDownloadError(false);
      if (docType !== DOC_TYPE.COVER_LETTER) {
        setDownloadModalText(t("review.resume_is_being_built"));
      } else {
        setDownloadModalText(t("review.cover_letter_is_being_built"));
      }
    }
  };
  const closeTrustPilotModalHandler = () => {
    toggleTrustPilotModal(false);
    navigate(pathRedirect.next);
  };
  return (
    <>
      <style>
        {`
            .d-hotline.h-btn {
              display: none;
            }
            #fc_frame.fc-widget-small{
          display: none;
      }
          `}
      </style>
      <Helmet>
        <title>{t("page_title.thank_you")}</title>
      </Helmet>
      <Header />
      <section className="thank-you main-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="alignment-container">
                {/* <div className="language-select">
                <LanguageSelect />
              </div> */}
                <hgroup className="thank-you--box text-center">
                  <h1>{t("confirmation.purchase_confirmation")}</h1>
                </hgroup>

                <div className="thank-you--box border-top border-bottom">
                  <h3>
                    {t("confirmation.payment_processed")}
                    <br />
                    {scheduleTypeCD == "YEAR"
                      ? t("confirmation.access_enabled_yealy")
                      : t("confirmation.access_enabled")}
                    {/* {t("confirmation.access_enabled")} */}
                  </h3>
                </div>

                <div className="thank-you--box">
                  <p>
                    <b>{t("confirmation.account_id")} </b>
                    {accountId}
                  </p>
                  <p>
                    <b>
                      {queryParams.has("isNewPricingFlowPlan") || queryParams.has("isNewPricingFlowCalc")
                        ? ""
                        : t("confirmation.order_summary")}{" "}
                    </b>
                    {queryParams.has("isNewPricingFlowPlan") || queryParams.has("isNewPricingFlowCalc")
                      ? ""
                      : dynamicTitle}
                  </p>
                  <p>
                    <b>
                      {scheduleTypeCD == "YEAR"
                        ? t("confirmation.annual_payment_plan")
                        : t("confirmation.payment_plan")}{" "}
                    </b>
                    {currencySymbol}
                    {queryParams.has("isNewPricingFlowCalc") || scheduleTypeCD == "YEAR" ? recurringPrice : unitPrice}
                  </p>
                  <p>{t("confirmation.features")}</p>
                </div>

                <div className="thank-you--box border-top small">{t("confirmation.any_questions")}</div>

                <div className="thank-you--box box-footer">
                  <div className="fbn">
                    <Button
                      onClick={redirectDashboardHandler}
                      classNames="btn btn-primary btn-lg text-uppercase"
                      ctaText={t("confirmation.return_dashboard")}
                    ></Button>
                  </div>

                  <Button
                    onClick={() => {
                      downloadResume(1);
                    }}
                    classNames={showDownloadBtn == "true" ? "btn btn-lg btn-green text-uppercase" : "d-none"}
                    ctaText={
                      <>
                        <i className="fa fa-download"></i> {t("confirmation.download_now")}
                      </>
                    }
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadModal
        message=""
        show={downloadResumeModal}
        toggleShow={toggleDownloadModal}
        toggleDownloadLoaderModal={toggleDownloadLoaderModal}
        inputLabel={t("resumes.resumes_choose_format")}
        header={downloadModalHeading}
        callback={callBackDownloadResume}
        docGUID={docGUID}
        downloadErrorMessage={downloadErrorMessage}
        downloadBtnTxt={downloadModalBtnText}
      />
      <DownloadLoaderPopup
        message=""
        toggleShow={toggleDownloadLoaderModal}
        show={downloadLoaderModal}
        inputLabel={downloadModalText}
        header={downloadModalBtnText}
        docGUID={docGUID}
        isDownloadError={isDownloadError}
      />
      <TrustBox
        toggleShow={toggleTrustPilotModal}
        show={trustPilotModal}
        closeTrustPilotModalHandler={closeTrustPilotModalHandler}
        trustPilotURL={trustPilotURL}
      />
      <ReactLoader showloader={showLoader} />
      <Footer />
    </>
  );
};

export default Confirmation;
