export const baseUrls = {
  DEVELOPMENT: {
    USER_SERVICE: "https://user-api-qa.worksimpli.io/api/v2/",
    DOCBUILDER_SERVICE: "https://docbuilder-api-qa.worksimpli.io/api/v1/docbuilder/",
    DOCPARSER_SERVICE: "https://docbuilder-api-qa.worksimpli.io/api/v1/docparser/",
    EVENT_SERVICE: "https://event-api-qa.worksimpli.io",
    AUTH_SERVICE: "https://auth-qa.worksimpli.io/api/",
    META_CONTENT_SERVICE: "https://docmetacontent-api-qa.worksimpli.io/api/v1/",
    PAYMENT_SERVICE: "https://payment-api-qa.worksimpli.io/api/v1/",
    ORDER_SERVICE: "https://order-api-qa.worksimpli.io/api/",
    ORCHESTRATION_SERVICE: "https://paymentorchestration-qa.worksimpli.io/api/v1/",
    SINGLE_SIGN_ON_PDFSIMPLI: "https://test-app.pdfsimpli.com/sso/",
    SINGLE_SIGN_ON_SIGNSIMPLI: "https://test.signsimpli.com/sso/",
    SINGLE_SIGN_ON_DASHBOARD: "https://test.resumebuild.com/app/",
    SIGNSIMPLI_URL: "https://test.signsimpli.com",
    PDFSIMPLI_URL: "https://test-app.pdfsimpli.com",
    DASHBOARD_ORCH_SERVICE: "https://dashboardorch-qa.worksimpli.io",
    TRUSTPILOT_SERVICE: "",
    ABTEST_SERVICE: "https://abtest-api-qa.worksimpli.io",
    LEGALSIMPLI_URL: "https://test.legalsimpli.com/app",
    LOGIN_APP: "https:/localhost:5000/app/account",
  },
  QA: {
    USER_SERVICE: "https://user-api-qa.worksimpli.io/api/v2/",
    DOCBUILDER_SERVICE: "https://docbuilder-api-qa.worksimpli.io/api/v1/docbuilder/",
    DOCPARSER_SERVICE: "https://docbuilder-api-qa.worksimpli.io/api/v1/docparser/",
    EVENT_SERVICE: "https://event-api-qa.worksimpli.io",
    AUTH_SERVICE: "https://auth-qa.worksimpli.io/api/",
    META_CONTENT_SERVICE: "https://docmetacontent-api-qa.worksimpli.io/api/v1/",
    PAYMENT_SERVICE: "https://payment-api-qa.worksimpli.io/api/v1/",
    ORDER_SERVICE: "https://order-api-qa.worksimpli.io/api/",
    ORCHESTRATION_SERVICE: "https://paymentorchestration-qa.worksimpli.io/api/v1/",
    SINGLE_SIGN_ON_PDFSIMPLI: "https://test-app.pdfsimpli.com/sso/",
    SINGLE_SIGN_ON_SIGNSIMPLI: "https://test.signsimpli.com/sso/",
    SINGLE_SIGN_ON_DASHBOARD: "##ResumeBuild_BaseUrl##",
    SIGNSIMPLI_URL: "https://test.signsimpli.com",
    PDFSIMPLI_URL: "https://test-app.pdfsimpli.com",
    DASHBOARD_ORCH_SERVICE: "https://dashboardorch-qa.worksimpli.io",
    TRUSTPILOT_SERVICE: "",
    ABTEST_SERVICE: "https://abtest-api-qa.worksimpli.io",
    LEGALSIMPLI_URL: "https://test.legalsimpli.com/app",
    LOGIN_APP: "https://test.resumebuild.com/app/account",
  },
  STAGING: {
    USER_SERVICE: "https://user-api.worksimpli.io/api/v2/",
    DOCBUILDER_SERVICE: "https://docbuilder-api.worksimpli.io/api/v1/docbuilder/",
    DOCPARSER_SERVICE: "https://docbuilder-api.worksimpli.io/api/v1/docparser/",
    EVENT_SERVICE: "https://event-api.worksimpli.io",
    AUTH_SERVICE: "https://auth-api.worksimpli.io/api/",
    META_CONTENT_SERVICE: "https://docmetacontent-api.worksimpli.io/api/v1/",
    PAYMENT_SERVICE: "https://payment-api.worksimpli.io/api/v1/",
    ORDER_SERVICE: "https://order-api.worksimpli.io/api/",
    ORCHESTRATION_SERVICE: "https://paymentorchestration-api.worksimpli.io/api/v1/",
    SINGLE_SIGN_ON_PDFSIMPLI: "https://pdfsimpli.com/sso/",
    SINGLE_SIGN_ON_SIGNSIMPLI: "https://signsimpli.com/sso/",
    SINGLE_SIGN_ON_DASHBOARD: "https://staging.resumebuild.com/app/",
    SIGNSIMPLI_URL: "https://staging.signsimpli.com",
    PDFSIMPLI_URL: "https://staging.pdfsimpli.com",
    DASHBOARD_ORCH_SERVICE: "https://dashboardorch-api.worksimpli.io",
    TRUSTPILOT_SERVICE: "https://www.trustpilot.com/evaluate/embed/resumebuild.com",
    ABTEST_SERVICE: "https://abtest-api.worksimpli.io",
    LEGALSIMPLI_URL: "https://staging.legalsimpli.com/app",
    LOGIN_APP: "https://staging.resumebuild.com/app/account",
  },
  PRODUCTION: {
    USER_SERVICE: "https://user-api.worksimpli.io/api/v2/",
    DOCBUILDER_SERVICE: "https://docbuilder-api.worksimpli.io/api/v1/docbuilder/",
    DOCPARSER_SERVICE: "https://docbuilder-api.worksimpli.io/api/v1/docparser/",
    EVENT_SERVICE: "https://event-api.worksimpli.io",
    AUTH_SERVICE: "https://auth-api.worksimpli.io/api/",
    META_CONTENT_SERVICE: "https://docmetacontent-api.worksimpli.io/api/v1/",
    PAYMENT_SERVICE: "https://payment-api.worksimpli.io/api/v1/",
    ORDER_SERVICE: "https://order-api.worksimpli.io/api/",
    ORCHESTRATION_SERVICE: "https://paymentorchestration-api.worksimpli.io/api/v1/",
    SINGLE_SIGN_ON_PDFSIMPLI: "https://pdfsimpli.com/sso/",
    SINGLE_SIGN_ON_SIGNSIMPLI: "https://signsimpli.com/sso/",
    SINGLE_SIGN_ON_DASHBOARD: "https://resumebuild.com/app/",
    SIGNSIMPLI_URL: "https://signsimpli.com",
    PDFSIMPLI_URL: "https://pdfsimpli.com",
    DASHBOARD_ORCH_SERVICE: "https://dashboardorch-api.worksimpli.io",
    TRUSTPILOT_SERVICE: "https://www.trustpilot.com/evaluate/embed/resumebuild.com",
    ABTEST_SERVICE: "https://abtest-api.worksimpli.io",
    LEGALSIMPLI_URL: "https://legalsimpli.com/app",
    LOGIN_APP: "https://resumebuild.com/app/account",
  },
};

//export default baseUrls;
