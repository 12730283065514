import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_CLOSER_RECOMMEND
    SAVE_SELECTED
`,
  {
    prefix: "closer/recommend",
  }
);
