import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/button";
import { checkEmpty, concatNotEmptyBy } from "../../../../../utils/helpers";

const DesiredCompany = ({
  provided,
  desiredCompanyEditHandler,
  deleteSectionHandler,
  date,
  coverLetterSection,
}) => {
  const { t } = useTranslation();

  const checkEmptyDesiredCompanyFields = (param) => {
    return !!coverLetterSection.desiredCompany && !checkEmpty(param)
      ? param
      : "";
  };
  const streetAddress =
    !!coverLetterSection &&
    !checkEmpty(coverLetterSection.desiredCompany.companyStreetAddress)
      ? `${coverLetterSection.desiredCompany.companyStreetAddress}`
      : "";

  const city =
    !!coverLetterSection &&
    !checkEmpty(coverLetterSection.desiredCompany.companyCity)
      ? `${coverLetterSection.desiredCompany.companyCity}`
      : "";

  const ST =
    !!coverLetterSection &&
    !checkEmpty(coverLetterSection.desiredCompany.companyState)
      ? `${coverLetterSection.desiredCompany.companyState}`
      : "";

  const zipCode =
    !!coverLetterSection &&
    !checkEmpty(coverLetterSection.desiredCompany.companyZipCode)
      ? `${coverLetterSection.desiredCompany.companyZipCode}`
      : "";
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        className={
          false
            ? "resume__section resume__summary dragging"
            : "resume__section resume__summary"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={desiredCompanyEditHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={() => deleteSectionHandler(t("opener.desired_company"))}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <div className="cvltr__section cvltr__recipient">
          <div className="cvltr__content">
            <p className="cvltr-mt0">
              <b>{date}</b>
            </p>
            <div className="cvltr-companyinfo">
              <p>{t("opener.recipient_name")}</p>
              <p>
                {checkEmptyDesiredCompanyFields(
                  coverLetterSection.desiredCompany.DesiredCompany
                )}
              </p>
              <p>
                {checkEmptyDesiredCompanyFields(
                  coverLetterSection.desiredCompany.companyStreetAddress
                )}
              </p>

              <p>{concatNotEmptyBy(", ")(city, ST, zipCode)}</p>
            </div>
            <p className="cvltr-mt30">
              <b>{t("opener.greeting_to_recipient_name")}:</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesiredCompany;
