import React from "react";
import { checkEmpty } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const CoverLetterSignatureSection = ({ cvltrTemplatePreview, data, type, cvltrSignature, funnelStep }) => {
  const { t } = useTranslation();
  const signatureContent = !!cvltrSignature && !!cvltrSignature.data && cvltrSignature.data;
  let key = signatureContent.key;
  let tabValue = signatureContent.tabValue ? signatureContent.tabValue : "";
  //debugger;
  let content;
  if (type == "cover-letter" || (data && data.length > 0)) {
    content = JSON.parse(data[0].content);
  } else {
    content =
      !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;
  }
  // let key =
  //   content.signType !== undefined ? content.signType : signatureContent.key;
  // let tabValue = content.tabValue ? JSON.parse(content.tabValue) : tabVal;
  const name =
    !!content && !checkEmpty(content.signName) ? content.signName : type == "cover-letter" ? "" : "[Your Name]";

  const sign = !!content && !checkEmpty(content.signature) ? content.signature : "";

  const fullName = !!content && !checkEmpty(content.fullName) ? content.fullName : "[Your Name]";

  return (
    <>
      <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
        <div className="cvltr__section cvltr__signature">
          <div className="cvltr__content">
            <p className="">
              <b>{type == "cover-letter" && name !== "" && sign !== "" && "Sincerely,"}</b>
              {type !== "cover-letter" && <b>Sincerely,</b>}
            </p>

            <>
              <p className="">
                {key == t("signature.type") ? (
                  <label className="form-check-label d-flex justify-content-between ps-2 pe-5">
                    <span
                      className={`${tabValue.font} font-24 p-3`}
                      style={{
                        color: tabValue.color,
                      }}
                    >
                      {name}
                    </span>
                  </label>
                ) : (
                  <b>{sign !== "" && <img src={sign} className="sign-img" />}</b>
                )}
                {sign && sign == "" && type !== "cover-letter" && <b>[Signature]</b>}
              </p>
              <p>
                <b>{fullName}</b>
              </p>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetterSignatureSection;
