import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  progress: 0,
};

export const saveProgress = (state = INITIAL_STATE, data) => {
  return { ...state, progress: data };
  // if (state.progress < data.data || state.progress.data < data.data) {
  // }

  //return { ...state };
};

export const resetSidebarProgress = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.PROGRESS]: saveProgress,
  [Types.RESET_SIDEBAR_PROGRESS]: resetSidebarProgress,
};

export default createReducer(INITIAL_STATE, HANDLERS);
