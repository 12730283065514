import React, { useState, useEffect, useRef } from "react";
import "./resumes.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import { EMPTY_GUID, TYPE_OF_DOC } from "../../utils/constants";
import RenameSectionModal from "../../components/shared/rename-section-modal";
import DownloadLoaderPopup from "../../components/shared/download-loader-popup";
import { useParams } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { Helmet } from "react-helmet";
import { downloadUserResume } from "../../initialState";
import { getTrustPilotURL, readCookie, navigateToPaymentMS, PerquisiteBountyEnable } from "../../utils/helpers";
import CoverLetterAction from "../../components/cover-letter-action";
import CoverLetterList from "../../components/cover-letter-list";
import CoverLetterDownloadModal from "../../components/shared/cover-letter-download-modal";
import { useLocation } from "react-router-dom";
import FinalStepTypes from "../funnel-step/types";
import { DOC_TYPE } from "../../utils/constants";
import { useDispatch } from "react-redux";
import ReactLoader from "../../components/shared/loader/component";
import { isPaymentMSEnable } from "../../utils/general-config";
const CoverLetters = ({
  funnelStep,
  setDocGuidConnect,
  renameCoverLetterConnect,
  getUserProfileConnect,
  setDocTypeConnect,
  getAbridgedUserDocsConnect,
  getDocumentConnect,
}) => {
  const localtion = useLocation();
  const dispatch = useDispatch();
  let pathRedirect = PathHelper(APP_NAVIGATION.RSMS);
  const { t } = useTranslation();
  const [userDocs, setUserDocs] = useState("");
  const [coverLetterOptions, setCoverLetterOptions] = useState("");
  const [selectedCoverLetter, setSelectedCoverLetter] = useState();
  const { docGUID, userGUID, parentUserGuid, email, docType } = funnelStep || {};
  const [renameCoverLetterModal, setRenameCoverLetterModal] = useState(false);
  const [renameVal, setRenameVal] = useState("");
  const [renameDocGuid, setRenameDocGuid] = useState(EMPTY_GUID);
  const [downloadCoverLetterModal, setDownloadCoverLetterModal] = useState(false);
  const [downloadLoaderModal, setDownloadLoaderModal] = useState(false);
  const [selectedHtml, setselectedHtml] = useState("");
  const componentRef = useRef(null);
  const [signedInUser, setSignedInUser] = useState(false);
  const [signedInUserMsg, setSignedInUserMsg] = useState(false);
  const [downloadModalText, setDownloadModalText] = useState(t("review.cover_letter_is_being_built"));
  const [downloadModalTitle, setDownloadModalTitle] = useState(t("review.cover_letter_review_download_btn"));
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [showLoader, setShowLoader] = useState("d-none");
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  let isRequestForPrint = false;
  let docGuid = docGUID;
  let userGuid = userGUID;
  if (!docGuid) {
    docGuid = readCookie("docId");
  }
  if (!userGuid) {
    userGuid = readCookie("userguid");
  }
  useEffect(() => {
    setShowLoader("d-flex");
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    if (!!userGuid && !!readCookie("jwtToken")) {
      getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.COVER_LETTER).then((response) => {
        if (!!response && response.documents.length > 0) {
          var coverLetterOptions = response.documents
            .sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn))
            .map((item) => {
              return {
                label: item["name"],
                value: item["id"],
              };
            });
          const docIdFromQueryString = new URLSearchParams(window.location.search).get("docId");
          let latestDocGuid =
            !!docGuid && docGuid != EMPTY_GUID && !!docType && docType != DOC_TYPE.RESUME
              ? docGuid
              : coverLetterOptions[0].value;
          latestDocGuid = !!docIdFromQueryString ? docIdFromQueryString : latestDocGuid;
          console.log("Before setDocTypeConnect");
          setDocTypeConnect(DOC_TYPE.COVER_LETTER);
          setUserDocs(response.documents);
          setDocGuidConnect(latestDocGuid);
          setCoverLetterOptions(coverLetterOptions);
          if (!!coverLetterOptions) {
            if (response.documents.find((x) => x.id == latestDocGuid) !== undefined) {
              getDocumentConnect(latestDocGuid).then((response) => {
                if (!!response) {
                  setSelectedCoverLetter(response);
                  setShowLoader("d-none");
                }
              });
            } else {
              getDocumentConnect(coverLetterOptions[0].value).then((response) => {
                if (!!response) {
                  setSelectedCoverLetter(response);
                  setShowLoader("d-none");
                }
              });
            }
          }
        } else {
          navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
        }
      });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }

    setSignedInUserMsg("Welcome! You have signed up successfully.");
    setselectedHtml(componentRef.current);
    getTrustPilotURL(email, userGuid);
  }, []);

  const toggleRenameModal = () => {
    if (selectedCoverLetter != undefined) {
      setRenameVal(selectedCoverLetter.name);
      setRenameDocGuid(selectedCoverLetter.id);
    }
    setRenameCoverLetterModal((q) => !q);
  };

  const toggleDownloadModal = () => {
    getUserProfileConnect(userGuid)
      .then((response) => {
        if (
          (!!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
          (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
        ) {
          setDownloadCoverLetterModal((d) => !d);
          setDownloadModalText(t("review.cover_letter_is_being_built"));
          setDownloadModalTitle(t("review.cover_letter_review_download_btn"));
          setIsDownloadError(false);
        } else {
          if (!navigateToPaymentMS(docGuid, locale)) {
            navigate(pathRedirect.back);
          }
        }
      })
      .catch((err) => {
        const { detail } = err;
      });
  };
  const toggleDownloadLoaderModal = () => {
    setDownloadLoaderModal((l) => !l);
  };
  const downloadErrorMessage = (isError) => {
    if (isError === "error") {
      setIsDownloadError(true);
      setDownloadModalText(t("review.download_error_message"));
    } else {
      setIsDownloadError(false);
      setDownloadModalText(t("review.cover_letter_is_being_built"));
    }
  };
  const callBackCoverLetter = (fieldValue, fieldId) => {
    if (!!userDocs && userDocs.length > 0) {
      getDocumentConnect(fieldValue).then((response) => {
        if (!!response) {
          let resumeData = response;
          if (resumeData != undefined && resumeData != null && resumeData != "") {
            setSelectedCoverLetter(resumeData);
          }
          setDocGuidConnect(fieldValue);
          setselectedHtml(componentRef.current);
        }
      });
    }
  };
  const callBackDuplicate = (prevDocLabel, newDocId) => {
    setDocGuidConnect(newDocId);
    getAbridgedUserDocsConnect(userGUID, TYPE_OF_DOC.COVER_LETTER).then((response) => {
      if (!!response) {
        setUserDocs(response.documents);
        var coverLetterOptions = response.documents.map((item) => {
          return {
            label: item["name"],
            value: item["id"],
          };
        });
        setCoverLetterOptions(coverLetterOptions);
        if (!!coverLetterOptions) {
          getDocumentConnect(newDocId).then((response) => {
            if (!!response) {
              setSelectedCoverLetter(response);
            }
          });
        }
      }
    });
  };
  const callBackDelete = (deletedDocId) => {
    const resetToFirstDocId = !!userDocs ? userDocs[0].id : EMPTY_GUID;
    getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.COVER_LETTER).then((response) => {
      if (!!response) {
        setUserDocs(response.documents);
        if (response.documents.length > 0) {
          setDocGuidConnect(resetToFirstDocId);
        } else {
          setDocGuidConnect(EMPTY_GUID);
          setSelectedCoverLetter(undefined);
        }
        var coverLetterOptions = response.documents.map((item) => {
          return {
            label: item["name"],
            value: item["id"],
          };
        });
        setCoverLetterOptions(coverLetterOptions);
        if (!!coverLetterOptions) {
          getDocumentConnect(response.documents[0].id).then((response) => {
            if (!!response) {
              setSelectedCoverLetter(response);
            }
          });
        }
      }
    });
  };
  const callBackCoverLetterName = (renameCoverLetter, renameDocGuid) => {
    renameCoverLetterConnect(renameDocGuid, renameCoverLetter).then((response) => {
      getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.COVER_LETTER).then((response) => {
        if (!!response) {
          setUserDocs(response.documents);
          var coverLetterOptions = response.documents.map((item) => {
            return {
              label: item["name"],
              value: item["id"],
            };
          });
          setCoverLetterOptions(coverLetterOptions);
          if (!!coverLetterOptions) {
            getDocumentConnect(renameDocGuid).then((response) => {
              if (!!response) {
                setSelectedCoverLetter(response);
              }
            });
          }
        }
      });
    });
    setRenameCoverLetterModal(false);
  };
  const callBackDownloadCoverLetter = (formatName) => {
    setDownloadCoverLetterModal(false);
  };
  const printCoverLetterCallBack = (fieldValue) => {
    getUserProfileConnect(userGuid)
      .then((response) => {
        if (
          (!!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
          (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
        ) {
          isRequestForPrint = true;
          toggleDownloadLoaderModal();
          setDownloadModalText(t("review.cover_letter_is_being_print"));
          setDownloadModalTitle(t("review.cover_letter_review_print_btn"));
          downloadUserResume(
            docGuid,
            "1",
            "ASPOSE",
            toggleDownloadLoaderModal,
            downloadErrorMessage,
            isRequestForPrint
          );
          setIsDownloadError(false);
        } else {
          // navigate(pathRedirect.back); // back to plan page
          if (!navigateToPaymentMS(docGuid, locale)) {
            navigate(pathRedirect.back);
          }
        }
      })
      .catch((err) => {
        const { detail } = err;
      });
  };
  // const navigateToPaymentMS = () => {
  //   // navigate(pathRedirect.next);
  //   let isPmsEnable = readCookie("pms");
  //   if ((!!isPmsEnable && isPmsEnable === "true") || isPaymentMSEnable() === true) {
  //     createCookie("docId", docGuid, 1);
  //     window.location.assign(!!locale && locale !== "en" ? `/app/${locale}/billing` : "/app/billing");
  //   } else {
  //     navigate(pathRedirect.next);
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{t("page_title.resume")}</title>
      </Helmet>
      <Header />
      <section className="section main-content templates">
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <h1> {t("cover_letters.headline")}</h1>
            </div>
            <div className="box_content">
              <div className="row dashboard-item">
                <div className="col-md-12 col-lg-4">
                  <div className="form-text">
                    {signedInUser && (
                      <p
                        key="welcomMsg"
                        className="alert error"
                        dangerouslySetInnerHTML={{
                          __html: signedInUserMsg,
                        }}
                      />
                    )}
                  </div>
                  {coverLetterOptions != undefined ? (
                    <CoverLetterList
                      callback={callBackCoverLetter}
                      coverLetterOptions={coverLetterOptions}
                      selectedCoverLetter={selectedCoverLetter}
                      componentRef={componentRef}
                    />
                  ) : null}
                </div>
                <div className="col-md-12 col-lg-4 col-md-offset-1 dashboard-item__info">
                  {selectedCoverLetter != undefined ? (
                    <CoverLetterAction
                      numberOfCoverLetters={userDocs.length}
                      selectedCoverLetter={selectedCoverLetter}
                      callbackduplicate={callBackDuplicate}
                      callbackdelete={callBackDelete}
                      toggleRenameModal={toggleRenameModal}
                      downloadCoverLetterModal={toggleDownloadModal}
                      printCoverLetterCallBack={printCoverLetterCallBack}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RenameSectionModal
        message=""
        show={renameCoverLetterModal}
        toggleShow={toggleRenameModal}
        setRenameVal={setRenameVal}
        renameDocGuid={renameDocGuid}
        renameVal={renameVal}
        header={t("cover_letters.edit_cover_letter")}
        callback={callBackCoverLetterName}
      />
      <CoverLetterDownloadModal
        message=""
        show={downloadCoverLetterModal}
        toggleShow={toggleDownloadModal}
        toggleDownloadLoaderModal={toggleDownloadLoaderModal}
        inputLabel={t("cover_letters.cover_letter_choose_format")}
        header={t("cover_letters.cover_letter_download_btn")}
        callback={callBackDownloadCoverLetter}
        docGUID={docGuid}
        downloadErrorMessage={downloadErrorMessage}
        isRequestForPrint={isRequestForPrint}
      />
      <DownloadLoaderPopup
        message=""
        toggleShow={toggleDownloadLoaderModal}
        show={downloadLoaderModal}
        inputLabel={downloadModalText}
        header={downloadModalTitle}
        docGUID={docGuid}
        isDownloadError={isDownloadError}
      />
      <Footer />
      <ReactLoader showloader={showLoader} />
    </>
  );
};

export default CoverLetters;
