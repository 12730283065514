import DOMPurify from "dompurify";
import React, { useEffect } from "react";
import { checkEmpty } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const CoverLetterBodySection = ({ cvltrTemplatePreview, type, data }) => {
  let content;
  const { t } = useTranslation();

  if (type == "cover-letter") {
    content = JSON.parse(data[0].content);
  } else {
    content =
      !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField
        ? cvltrTemplatePreview.templateField
        : false;
  }

  const body =
    !!content && !checkEmpty(content.bodyRecommend)
      ? content.bodyRecommend
      : "";

  const jobtitle =
    !!content && !checkEmpty(content.desiredPosition)
      ? content.desiredPosition
      : t("recommend.job_title");
  const experience =
    !!content && !checkEmpty(content.experience)
      ? content.experience
      : t("recommend.exp_10");
  const experience_15 =
    !!content && !checkEmpty(content.experience)
      ? content.experience
      : t("recommend.exp_15");
  const companyName =
    !!content && !checkEmpty(content.DesiredCompany)
      ? content.DesiredCompany
      : t("recommend.company");
  const emailAddress =
    !!content && !checkEmpty(content.emailAddress)
      ? content.emailAddress
      : t("recommend.company");
  const phone =
    !!content && !checkEmpty(content.phone)
      ? content.phone
      : t("recommend.company");

  useEffect(() => {
    if (type != "cover-letter") {
      const jobId = document.getElementById("bodyJobTitle");
      const experienceId15 = document.getElementById("bodyExperience_15");
      const experienceId10 = document.getElementById("bodyExperience_10");
      const companyNameId = document.getElementById("bodyCompanyName");
      const phoneId = document.getElementById("bodyPhone");
      const emailAddressId = document.getElementById("bodyEmailAddress");

      if (jobId) {
        jobId.innerText = jobtitle;
      }
      if (experienceId15) {
        experienceId15.innerText = experience_15;
      }
      if (experienceId10) {
        experienceId10.innerText = experience;
      }
      if (companyNameId) {
        companyNameId.innerText = companyName;
      }
      if (phoneId) {
        phoneId.innerText = phone;
      }
      if (emailAddressId) {
        emailAddressId.innerText = emailAddress;
      }
    }
  }, [cvltrTemplatePreview]);

  return (
    <>
      <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
        <div className="cvltr__section cvltr__body">
          <div className="cvltr__content">
            <p
              className="cvltr-mb30"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(body),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetterBodySection;
