import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    SAVE_FAILED
    UPDATE_FAILED
    UPDATE_SUCCESS
`,
  {
    prefix: "INTRO/",
  }
);
