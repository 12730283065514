import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
};

export const saveExperienceInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const updateExperienceInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const resetExperienceInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveExperienceInfo,
  [Types.UPDATE_SUCCESS]: updateExperienceInfo,
  [Types.RESET_EXPERIENCE]: resetExperienceInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
