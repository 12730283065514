export const environment = {
  DEVELOPMENT: "DEVELOPMENT",
  QA: "QA",
  STAGING: "STAGING",
  PRODUCTION: "PRODUCTION",
};

export const environmentTest = {
  DEVELOPMENT: "DEVELOPMENT",
  QA: "QA",
  STAGING: "STAGING",
  PRODUCTION: "PRODUCTION",
};

//export default environment;
