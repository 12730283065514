import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FinalStepTypes from "../funnel-step/types";
import { Helmet } from "react-helmet";
import { getTemplateList } from "../../services/master-data";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { blobSignature } from "../../utils/general-config";

const ApplyTemplate = ({ funnelStep, uploadResume }) => {
  const originalFileUrl =
    uploadResume?.uploadResponse?.renderPdfFileUrl + blobSignature();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [previewUrl, setPreviewUrl] = useState("");
  const [isLoadedThumbnail, setIsLoadedThumbnail] = useState(false);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    console.log("Inside onDocumentLoadSuccess");
    setIsLoadedThumbnail(true);
    setNumPages(numPages);
  }
  const passwordHandler = () => {
    console.log("password handler click");
  };
  useEffect(() => {
    setPreviewUrl(originalFileUrl);
    let currentStep = location.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.choose_options")}</title>
      </Helmet>
      <section className="section main-content text-center">
        <div className="container page-section">
          <div className="row">
            <div className="col-12 headline-group">
              <hgroup>
                <h1>{t("apply_template.heading")}</h1>
              </hgroup>
            </div>
          </div>

          <div className="row page-section-wrap justify-content-center">
            <div className="col-md-4 col-lg-4 border p-0">
              <div
                className={
                  "pdf-review apply-template-review " +
                  (isLoadedThumbnail ? "show" : "loading")
                }
              >
                <Document
                  file={previewUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onPassword={passwordHandler}
                >
                  <Page key={`page_${1}`} pageNumber={1} />
                </Document>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 px-4 d-none d-md-block">
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <img
                alt="Quo"
                className="border"
                src="/app/static/images/skeleton-img.gif"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ApplyTemplate;
