import React, { useState } from "react";
import "../../assets/scss/login-forms.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text/component";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { validateForgetPassword } from "../../validators/genericValidator";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { getTenantId, getServiceCodeForgotPwd, getTenantGroupId } from "../../utils/general-config";
import { getSignInLink } from "../../utils/path-helper";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const ForgotPassword = ({ forgotPasswordConnect }) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [buttonText, setButtonText] = useState(t("forgot_password.btn_cta"));
  const changeText = () => setButtonText(t("forgot_password.btn_cta_swap"));

  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailSuccessMsg, setEmailSuccessMsg] = useState("");
  const [isCoverLetter, setIsCoverLetter] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      let checkIsCoverLetter = location.state.isCoverLetter;
      if (checkIsCoverLetter) {
        setIsCoverLetter(true);
      }
    }
  }, [location]);

  const onemailChange = (e) => {
    setEmailError(false);
    const {
      target: { value },
    } = e;
    setEmail(value);
  };

  const onSubmitClickHandler = (e) => {
    console.log("onSubmitClickHandler");
    const isValid = validateData();
    let localeVal = "en";
    if (!!locale) {
      localeVal = locale;
    }
    if (isValid) {
      const data = {
        serviceCode: getServiceCodeForgotPwd(),
        email: email.trim(),
        tenantId: getTenantId(),
        locale: localeVal,
        tenantGroupId: getTenantGroupId(),
      };

      forgotPasswordConnect(data)
        .then((response) => {
          if (!!response) {
            if (response.isMailSent) {
              changeText();
              setEmailSuccess(true);
              setEmailSuccessMsg(t("forgot_password.forget_success_alert"));
            } else {
              setEmailError(true);
              setEmailErrorMsg(t("forgot_password.invalid_email"));
            }
          } else {
            setEmailError(true);
            setEmailErrorMsg(t("forgot_password.email_error"));
          }
        })
        .catch((error) => {
          if (!!error && error?.statusCode == 400 && error?.data?.ErrorData?.SocialLoginUnableToResetPassword !== "") {
            setEmailError(true);
            setEmailErrorMsg(t("forgot_password.social_login_user"));
          } else {
            setEmailError(true);
            setEmailErrorMsg(t("forgot_password.email_error"));
          }
        });
    }
  };
  const validateData = () => {
    setEmailSuccess(false);
    setEmailError(false);
    let emailValidator = validateForgetPassword(email);
    if (emailValidator.isValid === false) {
      setEmailError(true);
      setEmailErrorMsg(emailValidator.errorMessage);
    }
    return emailValidator.isValid;
  };

  return (
    <>
      <Helmet>
        <title>{t("page_title.forgot_password")}</title>
      </Helmet>
      <Header isCoverLetter={isCoverLetter} />
      <section className="main-content">
        <div className="container">
          <div className="form-wrap">
            <div className="form-container">
              <hgroup className="text-center">
                <h1 className="main-headline">{t("forgot_password.headline")}</h1>
                <p className="sub-para">{t("forgot_password.content")}</p>
              </hgroup>
              <div className="form-group">
                <div className="form-text">
                  {emailError && (
                    <p key="pswrdErr" role={alert} className="alert error">
                      <h2> {t("forgot_password.forget_alert_text")}</h2>
                      <br />
                      <li> {emailErrorMsg}</li>
                    </p>
                  )}

                  {emailSuccess && (
                    <p key="pswrdErr" role={alert} className="alert error">
                      <h4> {t("forgot_password.forget_success_alert")}</h4>
                    </p>
                  )}
                </div>
                {/* <input required="required" placeholder="Your email address" type="email" value="" name="email" id="email" onChange={onemailChange} /> */}
                <InputText
                  showLabel={false}
                  required="required"
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  maxLength={100}
                  onChange={onemailChange}
                  placeholder={t("forgot_password.placeholder_email")}
                />
              </div>

              <div className="align-center fbn">
                {/* <input type="submit" name="commit" value="Send password-reset email" onClick={onSubmitClickHandler} /> */}
                <Button
                  isLoading={false}
                  disabled={false}
                  type="submit"
                  classNames="btn btn-primary w-100 text-uppercase btn-form"
                  onClick={onSubmitClickHandler}
                  ctaText={buttonText}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgotPassword;
