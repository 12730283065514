import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Language from "./component";
import { createParagraph, updateLanguageParagraph } from "./actions";

const mapStateToProps = ({ languages, funnelStep }) => ({
  languages,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLanguagesParagraph: createParagraph,
      updateLanguagesParagraph: updateLanguageParagraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Language);
