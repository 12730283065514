import React from "react";
import { Trans, useTranslation } from "react-i18next";
//import "../../pages/choose-templates/template-chooser.scss";

const TemplateContainer = ({ data, onClick = {} }) => {
  const { t } = useTranslation();
  const onClickHandler = (templatecd) => {
    onClick(templatecd);
  };
  return (
    <div className="col-md-4  template-file">
      <button
        id={data.templatename}
        className="template-file__image"
        type="submit"
        onClick={(e) => onClickHandler(data.templatecd)}
      >
        <img alt={data.templatename} src={data.imagesource} />
        <span className="btn btn-primary btn--xlarge">{t("choose_templates.use_this_template")}</span>
      </button>
      <div className="template-name">{data.templatecd} </div>
    </div>
  );
};

export default TemplateContainer;
