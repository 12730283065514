import React from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { createParagraph } from "../../initialState";
import { APP_NAVIGATION, EMPTY_GUID, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useNavigate, useParams } from "react-router-dom";
import "./component.css";
import PathHelper from "../../utils/path-helper";
import CoverLetterContentBox from "../../components/cover-letter-content-box";
import MetaContentService from "../../services/meta-content-api";
import { availableLanguages } from "../../i18nextInit";
import { useEffect } from "react";
import Sidebar from "../../components/cover-letter-sidebar";
import { getRecommendFaqs } from "../../services/master-data";
import CoverLetterPreview from "../../components/cover-letter-preview";
import { checkEmpty, getPortalCD, readCookie, findSquareBrackets } from "../../utils/helpers";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import WritingTips from "../../components/writing-tips";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterRecommend = ({
  funnelStep,
  recommendConnect,
  updateRecommendParagraph,
  cvltrRecommend,
  SaveDocGUIDConnect,
  jobtitle,
  desiredCompany,
  cvltrExperience,
  updateProgress,
  cvltrTemplatePreview,
  previewTemplateAction,
  handleSteps,
  saveSelected,
  cvltrSkills,
  docReview,
  updateOpenerRecommendSuccess,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { locale } = useParams();
  let verifiedLocale = "en";
  const { reviewDoc } = docReview;
  let filteredDocPara = reviewDoc?.paragraphs || [];
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
  }

  if (!!locale) {
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
  }
  const { docGUID, funnelStepsComplete, userGUID, email, personalInfoName } = funnelStep;
  let pathRedirect = PathHelper(APP_NAVIGATION.OPNRRECOM, funnelStepsComplete, true);
  const recommendFaqs = getRecommendFaqs(t);
  const { question, answers } = recommendFaqs.data;
  // saved State :
  const { selectedData } = cvltrRecommend;

  const skils = !!cvltrSkills && cvltrSkills.selectedSkillsState && cvltrSkills.selectedSkillsState.data;

  const id =
    !!cvltrRecommend && !!cvltrRecommend.paragraph && !!cvltrRecommend.paragraph.id ? cvltrRecommend.paragraph.id : "";

  const paraName =
    !!cvltrRecommend && !!cvltrRecommend.paragraph && !!cvltrRecommend.paragraph.paraName
      ? cvltrRecommend.paragraph.paraName
      : "";

  const paraGraphId = !!id ? id : EMPTY_GUID;

  const prevParaName = {
    state: {
      prevParaName: t("opener.opener_recommend"),
    },
  };

  const jobContent =
    !!jobtitle &&
    !!jobtitle.paragraph &&
    !!jobtitle.paragraph.paraContents &&
    !!jobtitle.paragraph.paraContents[0].content
      ? JSON.parse(jobtitle.paragraph.paraContents[0].content)
      : {};

  const companyContent =
    !!desiredCompany &&
    !!desiredCompany.paragraph &&
    !!desiredCompany.paragraph.paraContents &&
    !!desiredCompany.paragraph.paraContents[0].content
      ? JSON.parse(desiredCompany.paragraph.paraContents[0].content)
      : {};

  const experienceContent =
    !!cvltrExperience &&
    !!cvltrExperience.paragraph &&
    !!cvltrExperience.paragraph.paraContents &&
    !!cvltrExperience.paragraph.paraContents[0].content
      ? JSON.parse(cvltrExperience.paragraph.paraContents[0].content)
      : {};

  const desiredPosition = !!jobContent && !checkEmpty(jobContent.desiredPosition) ? jobContent.desiredPosition : "";
  const Experience =
    !!experienceContent && !checkEmpty(experienceContent.Experience) ? experienceContent.Experience : 0;
  const desiredCompanyName =
    !!companyContent && !checkEmpty(companyContent.DesiredCompany) ? companyContent.DesiredCompany : "";

  const [paragraphName, setParagraphName] = useState(paraName);
  const [showLoader, setShowLoader] = useState("d-none");
  const [recommendList, setRecommendList] = useState([]);
  const [isSelected, setIsSelected] = useState({
    index: "",
    value: "",
    id: "",
  });
  const getRecommendList = async () => {
    setShowLoader("d-flex");
    const response = await MetaContentService.getIndusty("cvlt", verifiedLocale);

    let industryId = response.length > 0 && response.find((item) => item.name == t("cover-letter.title")).id; // find CVLTR industryId
    let updatedContent = [];

    if (industryId !== "" && industryId !== undefined) {
      const writingContentResponse = await MetaContentService.getWritingContent(
        "OPNR",
        industryId,
        "",
        verifiedLocale,
        Experience
      );
      let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
      let experience_10 = Experience !== "" ? Experience : t("recommend.exp_10");
      let experience_15 = Experience !== "" ? Experience : t("recommend.exp_15");
      let companyName = desiredCompanyName !== "" ? desiredCompanyName : t("recommend.company");
      let skill_1 = skils && skils.length !== 0 ? skils[0] : t("recommend.skill_1");
      let skill_2 = skils && skils.length !== 0 ? skils[1] : t("recommend.skill_2");
      let skill_3 = skils && skils.length !== 0 ? skils[2] : t("recommend.skill_3");

      writingContentResponse.map((item, index) => {
        let str = item.content;
        // let findSqareBrakets = str.match(/(?:\[)[^\][]*(?=])/g);
        let findSqareBrakets = findSquareBrackets(str);
        let mapObj = {};

        if (findSqareBrakets !== null) {
          findSqareBrakets
            .filter((x) => x != "")
            .map((item) => {
              if (item.toLowerCase() == "job title") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='jobTitle'>${jobtitle}</span>`;
              } else if (item == "10") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='experience_10'>${experience_10}</span>`;
              } else if (item == "15") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='experience_15'>${experience_15}</span>`;
              } else if (item.toLowerCase() == "company") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='companyName'>${companyName}</span>`;
              } else if (item.toLowerCase() == "skill1") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill1'>${skill_1}</span>`;
              } else if (item.toLowerCase() == "skill2") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill2'>${skill_2}</span>`;
              } else if (item.toLowerCase() == "skill3") {
                mapObj[
                  `[${item}]`
                ] = `<span  style="color: #FF6600;" class='cvltr-red-color' id='skill3'>${skill_3}</span>`;
              } else {
                mapObj[`[${item}]`] = `<span  style="color: #FF6600;" class='cvltr-red-color'>[${item}]</span>`;
              }
            });
        }

        Object.keys(mapObj).forEach((key) => {
          str = str.replaceAll(key, mapObj[key]);
        });

        updatedContent.push({
          ...item,
          content: str,
        });
      });
      if (!!writingContentResponse) {
        if (
          writingContentResponse &&
          writingContentResponse.length > 0 &&
          !IsRecommenderExist(writingContentResponse)
        ) {
          setIsSelected({
            ...isSelected,
            id: updatedContent[0].id,
            value: updatedContent[0].content,
            index: 0,
          });
        } else if (selectedData && selectedData.index !== "" && selectedData.value !== "") {
          if (updatedContent && updatedContent[selectedData.index]) {
            updatedContent[selectedData.index].content = selectedData.value;
          }
          setIsSelected({
            ...isSelected,
            id: selectedData.id,
            value: selectedData.value,
            index: selectedData.index,
          });
        } else {
          setIsSelected({
            ...isSelected,
            id: updatedContent[0].id,
            value: updatedContent[0].content,
            index: 0,
          });
          previewTemplateAction({
            ...cvltrTemplatePreview.templateField,
            openerRecommend: updatedContent[0].content,
          });
        }
        setRecommendList(updatedContent);
      }
      setShowLoader("d-none");
    }
  };
  const IsRecommenderExist = (writingContentResponse) => {
    const item = writingContentResponse?.find((item) => item?.id == selectedData?.id);
    return item;
  };
  useEffect(() => {
    trackRBEvents("cvOpenerRecommendView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    updateProgress(50);
    getRecommendList();

    if (filteredDocPara.length > 0) {
      filteredDocPara.map((item) => {
        if (item.paraContents && item.paraContents.length > 0) {
          let paraName = item.paraName;
          switch (paraName) {
            case t("opener.opener_recommend"):
              setParagraphName(paraName);
              updateOpenerRecommendSuccess(item);
              break;
            default:
              break;
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedData && selectedData.index !== "" && selectedData.value !== "") {
      let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
      let experience_10 = Experience !== "" ? Experience : t("recommend.exp_10");
      let experience_15 = Experience !== "" ? Experience : t("recommend.exp_15");
      let companyName = desiredCompanyName !== "" ? desiredCompanyName : t("recommend.company");
      let skill_1 = skils && skils.length !== 0 ? skils[0] : t("recommend.skill_1");
      let skill_2 = skils && skils.length !== 0 ? skils[1] : t("recommend.skill_2");
      let skill_3 = skils && skils.length !== 0 ? skils[2] : t("recommend.skill_3");

      const jobId = document.getElementById("jobTitle");
      const experienceId15 = document.getElementById("experience_15");
      const experienceId10 = document.getElementById("experience_10");
      const companyNameId = document.getElementById("companyName");
      const skill1 = document.getElementById("skill1");
      const skill2 = document.getElementById("skill2");
      const skill3 = document.getElementById("skill3");

      if (jobId) {
        jobId.innerText = jobtitle;
      }
      if (experienceId15) {
        experienceId15.innerText = experience_15;
      }
      if (experienceId10) {
        experienceId10.innerText = experience_10;
      }
      if (companyNameId) {
        companyNameId.innerText = companyName;
      }
      if (skill1) {
        skill1.innerText = skill_1;
      }
      if (skill2) {
        skill2.innerText = skill_2;
      }
      if (skill3) {
        skill3.innerText = skill_3;
      }
    }
  }, [selectedData, isSelected]);

  useEffect(() => {
    if (isSelected.index !== "" && isSelected.value !== "") {
      saveSelected(isSelected);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        openerRecommend: isSelected.value,
      });
    }
  }, [isSelected]);

  const buttonClickHandler = () => {
    if (!!paraGraphId && paraGraphId !== EMPTY_GUID && paragraphName == t("opener.opener_recommend")) {
      updateRecommend();
    } else {
      saveRecommend();
    }
  };

  const saveRecommend = () => {
    setShowLoader("d-flex");
    const parentId = document.querySelector("#parentId");
    const spanTag = parentId?.querySelector("#jobTitle");
    let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
    if (spanTag) {
      spanTag.textContent = jobtitle;
    }

    let head = {
      openerRecommend: parentId?.innerHTML,
      Experience: Experience,
    };

    recommendConnect(docGUID, JSON.stringify(head), t("opener.opener_recommend"), isSelected)
      .then((response) => {
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
        navigate(pathRedirect.next, prevParaName);
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  const updateRecommend = () => {
    setShowLoader("d-flex");
    const parentId = document.querySelector("#parentId");
    const spanTag = parentId?.querySelector("#jobTitle");
    let jobtitle = desiredPosition !== "" ? desiredPosition : t("recommend.job_title");
    if (spanTag) {
      spanTag.textContent = jobtitle;
    }

    let head = {
      openerRecommend: parentId?.innerHTML,
      Experience: Experience,
    };

    let recommendOrder =
      !!cvltrRecommend &&
      !!cvltrRecommend.paragraph &&
      !!cvltrRecommend.paragraph.order &&
      cvltrRecommend.paragraph.order != 0
        ? cvltrRecommend.paragraph.order
        : SECTION_ORDER.OPNR;

    let data = createParagraph(
      docGUID,
      cvltrRecommend.paragraph.id,
      cvltrRecommend.paragraph.paraContents[0].id,
      JSON.stringify(head),
      recommendOrder,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      t("opener.opener_recommend")
    );

    updateRecommendParagraph(docGUID, paraGraphId, data, isSelected)
      .then((response) => {
        setShowLoader("d-none");
        navigate(pathRedirect.next, prevParaName);
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  useEffect(() => {
    handleSteps({
      ...cvltrTemplatePreview.steps,
      opener: true,
    });
  }, []);
  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={1} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      <ScrollToTop />
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="cvrLtr-header">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("opener.recommend_title"),
                    }}
                  />
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("opener.recommend_subtitle")),
                    }}
                  />
                </hgroup>
                <WritingTips section={SECTION_TYPE.CVLTS} question={question} answers={answers} />
              </div>
            </div>
            <div className="row iPad-size">
              <div className="col-md-12 col-lg-9">
                <div>
                  <div className="row">
                    <div className="cvltr-list-wrapper">
                      <CoverLetterContentBox
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        recommendList={recommendList}
                        setRecommendList={setRecommendList}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    id="back-btn"
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("opener.back")}
                    onClick={backClickHandler}
                  />

                  <Button
                    isLoading={false}
                    disabled={false}
                    id="next-btn"
                    classNames="btn btn-primary btn-lg submit-on-enter float-end"
                    ctaText={t("opener.next")}
                    onClick={buttonClickHandler}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterRecommend;
