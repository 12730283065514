import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../assets/scss/login-forms.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import ToastMessage from "../../components/toast-message";
import appLogger from "../../utils/app-logger";
import InputText from "../../components/shared/input-text/component";
import FacebookLogin from "react-facebook-login";
import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { validateSignupEmail, validateSignupPassword } from "../../validators/genericValidator";
import PathHelper from "../../utils/path-helper";
import { getSignInLink } from "../../utils/path-helper";
import { getTenantGroupId, isPaymentMSEnable } from "../../utils/general-config";
import {
  APP_NAVIGATION,
  AFFILIATE_STORAGE_NAME,
  AFFILIATE_KEYWORD_NAME,
  AFFILIATE_SOURCE_VALUE,
  AFFILIATE_SOURCE_NAME,
  DOC_TYPE,
  EMPTY_GUID,
  NewPricingFlowQueryString,
} from "../../utils/constants";
import {
  getTenantId,
  defaultCountryCode,
  defaultCurrencyCode,
  GigWorkersWelcomeEmailLink,
  GigWorkersWelcomeEmailSubject,
  GigWorkerUserSignUpEvent,
  GigWorkersWelcomeEmailDelayMinutes,
} from "../../utils/general-config";
import ReactLoader from "../../components/shared/loader";
import axios from "axios";
import {
  readCookie,
  getPortalCD,
  createCookie,
  deleteCookie,
  setLocaleURL,
  loadScript,
  removeScript,
  getIPConfig,
  generateUinqueIDentifier,
} from "../../utils/helpers";
import { Helmet } from "react-helmet";
import { CreateUUID, isAllAddressCookiePresent } from "../../utils/helpers";
import { useGoogleLogin } from "@react-oauth/google";
import { trackRBEvents } from "../../utils/tracking-events";
import moment from "moment";

const SignUp = ({
  signUpUserConnect,
  signUpExternalUserConnect,
  funnelStep,
  eventServicePostConnect,
  getUserDocsConnect,
  setDocTypeConnect,
  loginAttemptConnect,
  postDataToEventServiceConnect,
  postDataToEventServiceScheduleConnect,
}) => {
  const { locale } = useParams();
  let pathRedirect = PathHelper(APP_NAVIGATION.SINU);
  let pathRedirectSignIn = PathHelper(APP_NAVIGATION.CVLTRSINI);
  let pathRedirectPlan = PathHelper(APP_NAVIGATION.REVW);
  let signInLink = getSignInLink(locale);
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const { docGUID, userGUID, personalInfoName, email, docType } = funnelStep || {};
  const navigate = useNavigate();
  const [emailId, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const [showLoader, setShowLoader] = useState("d-none");
  const [disableBtn, setdisableBtn] = useState(false);
  const [errorCount, setErrorCount] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    loadScript("https://www.google.com/recaptcha/api.js?render=6Lf5G3wmAAAAAGtoQk-IVo7wCIMFwk3dWIWFsKhU");
    trackRBEvents("signUpView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    let isPmsqueryString = queryParams.get("pms");
    if (!!isPmsqueryString) {
      createCookie("pms", "true", 1);
    }
    NavigateToReviewForAuth();
    if (!isAllAddressCookiePresent()) {
      getIPConfig();
    }
    const documentType = new URLSearchParams(window.location.search).get("doctype");
    setDocType(documentType);
    return () => {
      removeScript("https://www.google.com/recaptcha/api.js?render=6Lf5G3wmAAAAAGtoQk-IVo7wCIMFwk3dWIWFsKhU");
    };
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (!!tokenResponse) {
        const userInfo = await axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          })
          .then((res) => {
            responseGoogle(res.data, tokenResponse.access_token);
          });
      } else {
        setLoginError(true);
        setLoginErrorMessage("Failed to login with google");
      }
    },
    onError: (errorResponse) => {
      setLoginError(true);
      setLoginErrorMessage("Failed to login with google");
      console.log(errorResponse);
    },
  });
  let isPmsEnable = readCookie("pms");
  const isPayment_MSEnable = (!!isPmsEnable && isPmsEnable === "true") || isPaymentMSEnable() === true;
  const NavigateToReviewForAuth = () => {
    const isNewPricingCookiePresent =
      queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
      queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
      queryParams.has(NewPricingFlowQueryString.SKUGUID);
    if (isNewPricingCookiePresent && (!!readCookie("jwtToken") || !!readCookie("refreshToken"))) {
      //navigate(pathRedirectPlan.next);
      if (isPayment_MSEnable) {
        window.location.assign(redirectToPaymentMS(pmsPricingFlow(isNewPricingCookiePresent, isPayment_MSEnable)));
      } else {
        navigate(pathRedirectPlan.next);
      }
    } else if (
      // !!localStorage.getItem("jwtToken") ||
      // !!localStorage.getItem("refreshToken")
      !!readCookie("jwtToken") ||
      !!readCookie("refreshToken")
    ) {
      let acc = "";
      if (!!readCookie(AFFILIATE_STORAGE_NAME)) {
        acc = readCookie(AFFILIATE_STORAGE_NAME);
      }
      let kwrd = "";
      if (!!readCookie(AFFILIATE_KEYWORD_NAME)) {
        kwrd = readCookie(AFFILIATE_KEYWORD_NAME);
      }
      if (!!docGUID) {
        if (docType === DOC_TYPE.COVER_LETTER) {
          navigate(pathRedirectSignIn.next);
        } else {
          // Navigate to review page..
          navigate(pathRedirect.next + "?account=" + acc + "&keyword=" + kwrd);
        }
      } else {
        getUserDocsConnect(userGUID).then((docResponse) => {
          if (docType === DOC_TYPE.COVER_LETTER) {
            navigate(pathRedirectSignIn.next);
          } else {
            if (!!docResponse && docResponse.documents.length > 0) {
              navigate(pathRedirect.cstm + "?account=" + acc + "&keyword=" + kwrd);
            } else {
              navigate(pathRedirect.back);
            }
          }

          //return docResponse;
        });
      }
    }
  };

  const onEmailChangeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  const onPwdChangeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setPwd(value);
  };
  const onPasswordKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) {
      onSignIn();
    }
  };
  const isAffilateUser = () => {
    return readCookie(AFFILIATE_SOURCE_NAME) == AFFILIATE_SOURCE_VALUE;
  };
  const signUpBackNavigate = () => {
    const isNewPricingFlow =
      queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
      queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
      queryParams.has(NewPricingFlowQueryString.SKUGUID);
    if (isAffilateUser()) {
      redirectToChooseTemplate();
    } else {
      if (docType === DOC_TYPE.COVER_LETTER) {
        navigate(pathRedirectSignIn.back);
      } else {
        // Navigate to review page..
        if (!!readCookie(AFFILIATE_STORAGE_NAME) && !!readCookie(AFFILIATE_KEYWORD_NAME)) {
          if (isNewPricingFlow) {
            if (isPayment_MSEnable) {
              window.location.assign(redirectToPaymentMS(pmsPricingFlow(isNewPricingFlow, isPayment_MSEnable)));
            } else {
              redirectToCheckOut(true);
            }
          } else {
            navigate(
              pathRedirect.back +
                "?account=" +
                readCookie(AFFILIATE_STORAGE_NAME) +
                "&keyword=" +
                readCookie(AFFILIATE_KEYWORD_NAME)
            );
          }
        } else {
          if (isNewPricingFlow) {
            if (isPayment_MSEnable) {
              window.location.assign(redirectToPaymentMS(pmsPricingFlow(isNewPricingFlow, isPayment_MSEnable)));
            } else {
              redirectToCheckOut(true);
            }
          } else {
            navigate(pathRedirect.back);
          }
        }
      }
    }
  };
  const signUpNextNavigate = () => {
    const isNewPricingCookiePresent =
      queryParams.has(NewPricingFlowQueryString.CURRENCY) &&
      queryParams.has(NewPricingFlowQueryString.SKUCATEGORY) &&
      queryParams.has(NewPricingFlowQueryString.SKUGUID);
    if (isAffilateUser()) {
      redirectToChooseTemplate();
    } else if (isNewPricingCookiePresent) {
      if (isPayment_MSEnable) {
        window.location.assign(redirectToPaymentMS(pmsPricingFlow(isNewPricingCookiePresent, isPayment_MSEnable)));
      } else {
        redirectToCheckOut();
      }
      //redirectToCheckOut();
    } else {
      if (docType === DOC_TYPE.COVER_LETTER) {
        navigate(pathRedirectSignIn.next);
      } else {
        // Navigate to review page..
        if (!!readCookie(AFFILIATE_STORAGE_NAME) && !!readCookie(AFFILIATE_KEYWORD_NAME)) {
          navigate(
            pathRedirect.next +
              "?account=" +
              readCookie(AFFILIATE_STORAGE_NAME) +
              "&keyword=" +
              readCookie(AFFILIATE_KEYWORD_NAME)
          );
        } else {
          navigate(pathRedirect.next);
        }
      }
    }
  };
  const redirectToCheckOut = () => {
    if (!!locale) {
      navigate(setLocaleURL(ROUTE_CONSTANT.CHECKOUT_LOCALE, locale) + location.search);
    } else {
      navigate(ROUTE_CONSTANT.CHECKOUT + location.search);
    }
  };
  const redirectToChooseTemplate = () => {
    if (!!locale) {
      navigate(setLocaleURL(ROUTE_CONSTANT.CHOOSE_TEMPLATE_LOCALE, locale) + location.search);
    } else navigate(ROUTE_CONSTANT.CHOOSE_TEMPLATE + location.search);
  };
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const onSignIn = () => {
    setShowLoader("d-block");
    setLoginError(false);
    const isValid = validateData();
    if (isValid) {
      // try{
      //   window.TrackAmplitudeEvent("registration_page_CTA");
      //   window.TrackMixPanelEvent("registration_page_CTA");
      //  }
      //  catch{}
      // Login Attempt
      window?.grecaptcha.ready(function () {
        window?.grecaptcha
          .execute("6Lf5G3wmAAAAAGtoQk-IVo7wCIMFwk3dWIWFsKhU", {
            action: "register",
          })
          .then(function (token) {
            const loginAttemptReq = {
              emailAddress: emailId.trim(),
              ipAddress: readCookie("ipAddress"),
              attemptType: "Registration",
              visitorId: readCookie("visitorGuid"),
              visitId: readCookie("visitGuid"),
              createdBy: readCookie("visitorGuid"),
              portalCD: getPortalCD(locale),
              greCaptchaToken: token,
              isCaptchaRequired: true,
            };

            loginAttemptConnect(loginAttemptReq)
              .then((response) => {
                if (response != null && response?.Id != EMPTY_GUID && response.error == "") {
                  const params = {
                    email: emailId.trim(),
                    password: pwd.trim(),
                    portalCD: getPortalCD(locale),
                    tenantId: getTenantId(),
                    countryCD: readCookie("country"),
                    currencyCD: readCookie("currency"),
                    tenantGroupId: getTenantGroupId(),
                  };
                  signUpUserConnect(params)
                    .then((response) => {
                      if (response.isValid) {
                        window.registerUser(emailId.trim());
                        let isPmsqueryString = queryParams.get("pms");
                        if (!!isPmsqueryString) {
                          createCookie("pms", "true", 1);
                        }
                        let data = {
                          Id: CreateUUID(),
                          UserId: response.userGuid,
                          VisitorId: readCookie("visitorGuid"),
                          VisitId: readCookie("visitGuid"),
                        };
                        eventServicePostConnect(data).then((serviceResponse) => {});
                        let isUSUser = false;
                        if (localStorage.getItem("ipconfig") !== null) {
                          let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
                          const localStorageCurrency = ipConfigData["currency"];
                          isUSUser = localStorageCurrency === "USD" && (!locale || locale == "en") ? true : false;
                        }

                        if (isUSUser) {
                          gigWorkersSignUpUserEvent(response.userGuid, emailId.trim());
                        }
                        if (!!docGUID && !!response) {
                          signUpNextNavigate();
                        } else {
                          signUpBackNavigate();
                        }
                      } else {
                        setShowLoader("d-none");
                        setdisableBtn(false);
                        setLoginError(true);
                        setLoginErrorMessage(t("sign_up.user_exists"));
                      }
                    })
                    .catch((error) => {
                      setShowLoader("d-none");
                      setLoginError(true);
                      setLoginErrorMessage(error.message);
                    });
                } else {
                  setShowLoader("d-none");
                  setdisableBtn(false);
                  setLoginError(true);
                  if (!!response && response.statusCode == 412) {
                    setLoginErrorMessage(t("generic_error.Suspicious_Signup"));
                  } else {
                    setLoginErrorMessage(t("generic_error.max_Signup_attempt"));
                  }
                }
              })
              .catch((error) => {
                setLoginErrorMessage(t("generic_error.inaction_message"));
                setShowLoader("d-none");
                setLoginError(true);
              });
          });
      });
      //Login Attempt
    } else {
      setShowLoader("d-none");
      setdisableBtn(false);
    }
  };
  const gigWorkersSignUpUserEvent = (userGuid, emailId) => {
    const eventData = {
      froUserGuid: userGuid,
      userGuid: userGuid,
      content: "DSBD",
      templateId: GigWorkerUserSignUpEvent.TemplateId,
      emailProvider: GigWorkerUserSignUpEvent.EmailProvider,
      emailMetaData: {
        portal: "ResumeBuild",
        PortalCD: getPortalCD(locale),
        currenturl: GigWorkersWelcomeEmailLink,
        toEmail: emailId.trim(),
        sendername: emailId.trim(),
        emailsubject: GigWorkersWelcomeEmailSubject,
        disablebcc: true,
      },
      envelopeId: "00000000-0000-0000-0000-000000000000",
      isAttachment: false,
      tenantId: getTenantId(),
    };
    const scheduleUtcTime = moment.utc().add(GigWorkersWelcomeEmailDelayMinutes, "m").toDate();
    postDataToEventServiceScheduleConnect(
      eventData,
      GigWorkerUserSignUpEvent.EventType,
      GigWorkerUserSignUpEvent.EventSubType,
      GigWorkerUserSignUpEvent.TopicName,
      scheduleUtcTime
    ).then((response) => {});
  };

  const validateData = () => {
    setEmailError(false);
    setPasswordError(false);
    let errors = 0;

    let emailValidator = validateSignupEmail(emailId.trim());
    if (emailValidator.isValid === false) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg(emailValidator.errorMessage);
    }
    let passwordValidator = validateSignupPassword(pwd);
    if (passwordValidator.isValid === false) {
      ++errors;
      setPasswordError(true);
      setPasswordErrorMsg(passwordValidator.errorMessage);
    }
    setErrorCount(errors);
    console.log("errorCount");
    if (errors > 0) {
      ++errors;
      setdisableBtn(false);
      return false;
    } else {
      setdisableBtn(true);
      return true;
    }
  };

  const responseGoogle = (response, accessToken) => {
    if (!!response && !!accessToken) {
      setShowLoader("d-block");
      setEmailError(false);
      const { email, id } = response;
      const externalData = {
        createUser: {
          countryCD: readCookie("country"),
          currencyCD: readCookie("currency"),
          portalCD: getPortalCD(locale),
          parentUserGuid: "",
          firstName: "",
          lastName: "",
          company: "",
          title: "",
          email: email,
          password: "",
          tenantId: getTenantId(),
          tenantGroupId: getTenantGroupId(),
        },
        externalLoginInfo: {
          loginType: 2,
          providerKey: id, // "100732998798818",
        },
      };
      signUpExternalUserConnect(externalData)
        .then((response) => {
          if (!!response) {
            window.registerUser(externalData.createUser.email.trim());
            let data = {
              Id: CreateUUID(),
              UserId: response.UserGuid,
              VisitorId: readCookie("visitorGuid"),
              VisitId: readCookie("visitGuid"),
            };
            eventServicePostConnect(data).then((serviceResponse) => {});
            let isUSUser = false;
            if (localStorage.getItem("ipconfig") !== null) {
              let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
              const localStorageCurrency = ipConfigData["currency"];
              isUSUser = localStorageCurrency === "USD" && (!locale || locale == "en") ? true : false;
            }

            if (isUSUser) {
              gigWorkersSignUpUserEvent(response.UserGuid, email.trim());
            }
            if (!!docGUID && !!response) {
              signUpNextNavigate();
            } else {
              signUpBackNavigate();
            }
          }
        })
        .catch((error) => {
          setLoginError(true);
          setLoginErrorMessage(error.message);
        });
    }
  };

  const responseFacebook = (response) => {
    if (!!response && response.status != "unknown") {
      setShowLoader("d-block");
      setEmailError(false);
      const { name, email, id } = response;
      const externalData = {
        createUser: {
          countryCD: readCookie("country"),
          currencyCD: readCookie("currency"),
          portalCD: getPortalCD(locale),
          parentUserGuid: "",
          firstName: name,
          lastName: "",
          company: "",
          title: "",
          email: email, //email
          password: "",
          tenantId: getTenantId(),
          tenantGroupId: getTenantGroupId(),
        },
        externalLoginInfo: {
          loginType: 1,
          providerKey: id, // "100732998798818",
        },
      };
      signUpExternalUserConnect(externalData)
        .then((response) => {
          if (!!response) {
            window.registerUser(externalData.createUser.email.trim());
            let data = {
              Id: CreateUUID(),
              UserId: response.UserGuid,
              VisitorId: readCookie("visitorGuid"),
              VisitId: readCookie("visitGuid"),
            };
            eventServicePostConnect(data).then((serviceResponse) => {});
            let isUSUser = false;
            if (localStorage.getItem("ipconfig") !== null) {
              let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
              const localStorageCurrency = ipConfigData["currency"];
              isUSUser = localStorageCurrency === "USD" && (!locale || locale == "en") ? true : false;
            }

            if (isUSUser) {
              gigWorkersSignUpUserEvent(response.UserGuid, email.trim());
            }
            if (!!docGUID && !!response) {
              signUpNextNavigate();
            } else {
              signUpBackNavigate();
            }
          }
        })
        .catch((error) => {
          setLoginError(true);
          setLoginErrorMessage(error.message);
        });
    }
  };

  // const getIPConfig = async () => {
  //   const res = await axios.get(
  //     "https://pro.ip-api.com/json?key=qGpUO64dv9znGra&fields=country,countryCode,status,query,currency,region,zip"
  //   );
  //   console.log(res.data);
  //   localStorage.setItem("ipconfig", JSON.stringify(res.data));
  // };

  const setIPConfig = (param, defaultValue) => {
    if (localStorage.getItem("ipconfig") !== null) {
      let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
      return ipConfigData[param];
    } else {
      return defaultValue;
    }
  };
  const navigateSignIn = () => {
    navigate(signInLink);
  };
  const setDocType = (documentType) => {
    console.log("setDocType Sign UP", documentType);
    if (
      documentType &&
      (documentType.toUpperCase() == DOC_TYPE.RESUME || documentType.toUpperCase() == DOC_TYPE.COVER_LETTER)
    ) {
      setDocTypeConnect(documentType.toUpperCase());
    } else {
      if (docType == null || docType == undefined) {
        setDocTypeConnect(DOC_TYPE.RESUME);
      }
    }
  };
  const redirectToPaymentMS = (queryPram) => {
    return !!locale && locale !== "en" ? `/app/${locale}/billing${queryPram}` : `/app/billing${queryPram}`;
  };
  const pmsPricingFlow = (isNewPricingCookiePresent, isPayment_MSEnable) => {
    let pmsPricingFlowUrl = "";
    if (isNewPricingCookiePresent && isPayment_MSEnable) {
      pmsPricingFlowUrl = `?skuid=${queryParams.get(NewPricingFlowQueryString.SKUGUID)}&skuc=${queryParams.get(
        NewPricingFlowQueryString.SKUCATEGORY
      )}&currency=${queryParams.get(NewPricingFlowQueryString.CURRENCY)}&users=${queryParams.get(
        NewPricingFlowQueryString.USERS
      )}&id=${generateUinqueIDentifier()}`;
    }
    return pmsPricingFlowUrl;
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.sign_up")}</title>
      </Helmet>
      <ReactLoader showloader={showLoader} />
      <section className="main-content">
        <div className="container">
          <div className="form-wrap">
            <hgroup className="text-center">
              <h1 className="main-headline">{t("sign_up.headline")}</h1>
              <h2 className="sub-headline">{t("sign_up.sub_headline")}</h2>
              <p className="sub-para">{t("sign_up.content")}</p>
            </hgroup>
            <div className="social-login">
              <div className="social-login--facebook" id="facebook_login_btn">
                <FacebookLogin
                  appId="237961018547564"
                  autoLoad={false}
                  fields="name,email,picture"
                  textButton={t("sign_up.continue_with_fb")}
                  callback={responseFacebook}
                  icon={<img src="/app/static/images/facebook-login.svg" className="facebook-login-button" />}
                />
              </div>

              <div className="social-login--google">
                <button type="button" className="google-login-button" onClick={googleLogin}>
                  <div>
                    <img src={process.env.PUBLIC_URL + "/static/images/google-normal.svg"} />
                  </div>
                  <span>{t("sign_up.continue_with_google")}</span>
                </button>
              </div>
            </div>
            <div className="or-container">
              <span></span> or <span></span>
            </div>
            <div className="form-container">
              <div className="form-group">
                <div className="form-text">
                  {emailError && (
                    <p key="pswrdErr" role={alert} className="alert error signup-error-message">
                      <h2> {errorCount + t("sign_up.signup_alert_text")}</h2>
                      <br />
                      {emailError && (
                        <li>
                          <small key="pswrdErr">{emailErrorMsg}</small>
                        </li>
                      )}

                      {passwordError && (
                        <li>
                          <small key="pswrdErrw">{passwordErrorMsg}</small>
                        </li>
                      )}

                      {loginError && (
                        <li>
                          <small key="pswrdErrww">{loginErrorMessage}</small>
                        </li>
                      )}
                    </p>
                  )}
                </div>
                <InputText
                  showLabel={false}
                  id="email"
                  text="Email Address"
                  onChange={onEmailChangeHandler}
                  value={emailId.trim()}
                  maxLength={100}
                  placeholder={t("sign_up.placeholder_email")}
                />
              </div>
              <div className="form-text">
                {emailError && (
                  <small key="pswrdErr" className="form-text text-danger">
                    {emailErrorMsg}
                  </small>
                )}
              </div>
              <div className="form-group">
                <InputText
                  showLabel={false}
                  id="password"
                  text="Password"
                  type="password"
                  onChange={onPwdChangeHandler}
                  value={pwd}
                  onKeyPress={onPasswordKeyPress}
                  placeholder={t("sign_up.placeholder_password")}
                />
              </div>

              <div className="form-text">
                {passwordError && (
                  <small key="pswrdErr" className="form-text text-danger">
                    {passwordErrorMsg}
                  </small>
                )}
              </div>

              <div className="form-text">
                {loginError && (
                  <small key="pswrdErr" className="form-text text-danger">
                    {loginErrorMessage}
                  </small>
                )}
              </div>
              <div className="small">
                <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("sign_up.agree")),
                  }}
                />
              </div>

              <div className="mb-3">
                <div className="fbn">
                  <Button
                    isLoading={false}
                    disabled={disableBtn}
                    onClick={onSignIn}
                    classNames="btn btn-primary w-100 btn-form"
                    id="sign_up_btn"
                    ctaText={t("sign_up.btn_cta")}
                  />
                </div>
                <div className="form-container--footer text-center">
                  <p className="small">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(t("sign_up.already_account")),
                      }}
                    />
                    <Button
                      isLoading={false}
                      disabled={disableBtn}
                      onClick={navigateSignIn}
                      classNames="btn btn-link px-0"
                      id="sign_in_btn"
                      ctaText={t("sign_up.sign_in")}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignUp;
