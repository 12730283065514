import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv10HarumCSS = css`
  .resume-layout.cv10 {
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv10 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv10 .resume__section .sub-headline h2 {
    display: inline-block;
    text-transform: capitalize;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv10 .resume__section h1,
  .resume-layout.cv10 .resume__section h3 {
    font-size: 1.15em;
    margin-bottom: 0.25em;
    font-weight: 600;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv10 .sub-headline .svg-icon {
    display: inline-block;
  }
  .resume-layout.cv10 .resume-aside {
    padding-top: 1rem;
  }
  .resume-layout.cv10 .resume-aside .info-headline {
    display: none;
  }
  .resume-layout.cv10 .resume-aside .sub-headline .svg-icon {
    display: none;
  }
  .resume-layout.cv10 .resume-aside .personal-info {
    display: block;
  }
  .resume-layout.cv10 .resume-body .resume-main {
    padding-top: 1rem;
  }
  .resume-layout.cv10 .info-item {
    display: block;
  }
  .resume-layout.cv10 .resume-header .info-main {
    display: none;
  }
  .resume-layout.cv10 .info-item .info-label,
  .resume-layout.cv10 .info-item .info-text,
  .resume-layout.cv10 .wrap .left-col,
  .resume-layout.cv10 .wrap .extra-info,
  .resume-layout.cv10 .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv10 .wrap .extra-info {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .resume-layout.cv10 .extra {
    display: block;
  }
  .resume-layout.cv10 .extra .extra-details .progress-starts,
  .resume-layout.cv10 .extra .extra-details .progress-line {
    display: none;
  }
  .resume-layout.cv10 .extra .extra-details .progress-circle {
    display: block;
  }
  .resume-layout.cv10
    .extra
    .extra-details
    .progress-circle
    .extra-details__bg
    > span.active {
    background-color: #10a555;
  }
  .resume-layout.cv10 .xp-item {
    display: block;
  }
  .resume-layout.cv10 .xp-item .xp-date,
  .resume-layout.cv10 .xp-item .xp-job {
    // font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv10 .xp-item .xp-date {
    padding-top: 2px;
    margin-bottom: 1em;
    color: #10a555;
  }
  .resume-layout.cv10 .resume-main .resume__section {
    padding-bottom: 1em;
    border-left: 0.07em solid #cbcbcb;
  }
  .resume-layout.cv10 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv10 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv10 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv10 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv10 .resume__education .edu-hide {
    display: block;
  }
`;

export const Cv10HarumStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv10HarumCSS}
`;
