import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../button";
import "./delete-popup-modal.scss";
import { useTranslation } from "react-i18next";

const DeletePopupModal = ({ show, toggleShow, header, message, callback }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonClickHandler = () => {
    callback();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-delete-section"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-outline-secondary section-modal"
            onClick={toggleShow}
            ctaText={t("review.cancel")}
          />
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-primary section-modal"
            ctaText={t("reviews.ok")}
            onClick={buttonClickHandler}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeletePopupModal;
