import React from "react";
import { useTranslation } from "react-i18next";

const TemplateView = ({ data, onClick = {} }) => {
  const { t } = useTranslation();
  const onClickHandler = (templatecd) => {
    onClick(templatecd);
  };
  return (
    <div className="col-md-4 template-file">
      <button id="" className="template-file__image" type="submit" onClick={(e) => onClickHandler(data.templatecd)}>
        <img alt={data.templatename} src={data.imagesource} />
        <span className="btn btn-primary btn--xlarge" id={data.templatename}>
          {t("choose_cover_letter.title")}
        </span>
      </button>
      <div className="template-name">{data.templatename} </div>
    </div>
  );
};

export default TemplateView;
