import Types from "./types";
import appLogger from "../../utils/app-logger";
import UserServiceApi from "../../services/user-service-api";
import AuthServiceApi from "../../services/auth-service-api";
import EventServiceApi from "../../services/event-service-api";
import { apiService } from "../../services/base-api-service";
import FunnelStepTypes from "../funnel-step/types";
import { createCookie, readCookie, getZipCityStateBasedOnIP } from "../../utils/helpers";
import SingleSignOnApi from "../../services/single-sign-on-api";

import { endPoints, getUserServiceEndPoint } from "../../services/api-constants";
import DocBuilderService from "../../services/doc-builder-api";
import userServiceApi from "../../services/user-service-api";
import { getTenantId } from "../../utils/general-config";
const signUpUser = (data) => {
  return function (dispatch) {
    return UserServiceApi.createUser(data)
      .then((response) => {
        if (response.isValid) {
          appLogger.log("inside signUpUser1 ");
          const authData = {
            Username: data.email.trim(),
            UserGuid: response.userGuid,
            Email: data.email.trim(),
          };
          createCookie("userguid", response.userGuid, 1);
          return AuthServiceApi.AuthenticateUser(authData).then((authResponse) => {
            const { jwtToken, refreshToken, expiresAt, ssoToken, legacyToken } = authResponse;
            localStorage.setItem("jwtToken", jwtToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("expiresAt", expiresAt);
            createCookie("jwtToken", jwtToken, 1);
            createCookie("refreshToken", refreshToken, 1);
            createCookie("expiresAt", expiresAt, 1);
            createCookie("ssoauth", "1");
            createCookie("legacyTicket", legacyToken, 1);
            dispatch(PushUserInformationToFunnelStep(authData.UserGuid, authData.Email));
            SingleSignOnApi.partnerLogin(encodeURIComponent(legacyToken), "SS").then((ssoResponse) => {});
            SingleSignOnApi.partnerLogin(encodeURIComponent(legacyToken), "PS").then((ssoResponse) => {});
            //save the user address from ip-pro
            var ipAddress = readCookie("ipAddress");
            var addressObject = {
              UserGuid: response.userGuid,
              City: readCookie("city"),
              State: readCookie("region"),
              Country: readCookie("country"),
              ZipCode: readCookie("zip"),
              AddressType: 0,
              IsActive: 1,
              TenantId: getTenantId(),
            };
            userServiceApi.saveUserAddress(addressObject).then((res) => {});
            return response;
          });
        } else {
          return response;
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};
const PushUserInformationToFunnelStep = (userGUID, email) => ({
  type: FunnelStepTypes.SET_USERINFO,
  userGUID,
  email,
});
const signUpExternalUser = (data) => {
  const {
    createUser: { email },
  } = data;
  return function (dispatch) {
    return UserServiceApi.createExternalUser(data)
      .then((response) => {
        if (response) {
          appLogger.log("inside createExternalUser ");
          const authData = {
            Username: email.trim(),
            UserGuid: response.userGuid,
            Email: email.trim(),
          };
          return AuthServiceApi.AuthenticateUser(authData).then((authResponse) => {
            let getReposne = authResponse;
            const { jwtToken, refreshToken, expiresAt, legacyToken } = authResponse;
            localStorage.setItem("jwtToken", jwtToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("expiresAt", expiresAt);
            createCookie("jwtToken", jwtToken, 1);
            createCookie("refreshToken", refreshToken, 1);
            createCookie("expiresAt", expiresAt, 1);
            createCookie("legacyTicket", legacyToken, 1);
            dispatch(PushUserInformationToFunnelStep(authData.UserGuid, authData.Email));
            return authData;
          });
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};

const eventServicePost = (data) => {
  return function (dispatch) {
    return EventServiceApi.postEventData(data)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log("Error");
        return false;
      });
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};

const getUserDocs = (userguid) => {
  return function (dispatch) {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        return response;
      })
      .catch((error) => dispatch());
  };
};

const loginAttempt = (data) => {
  return function (dispatch) {
    return UserServiceApi.loginAttempt(data)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const postDataToEventService = (data, eventType, eventSubType, topicName) => {
  return function (dispatch) {
    return EventServiceApi.postDataToEventService(data, eventType, eventSubType, topicName)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log("Error");
        return false;
      });
  };
};
const postDataToEventServiceSchedule = (data, eventType, eventSubType, topicName, scheduleTime) => {
  return function (dispatch) {
    return EventServiceApi.postDataToEventServiceScheduledTime(data, eventType, eventSubType, topicName, scheduleTime)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log("Error");
        return false;
      });
  };
};

export {
  signUpUser,
  signUpExternalUser,
  eventServicePost,
  getUserDocs,
  loginAttempt,
  postDataToEventService,
  postDataToEventServiceSchedule,
};
