import {
  proIPKey,
  TrustPilotKey,
  getServiceCode,
  getService,
  getTenantId,
  ip2LocationKey,
  isPaymentMSEnable,
  IsSingleLoginAPPEnable,
  getInitializationVector,
} from "../utils/general-config";
import axios from "axios";
import { PORTAL_CD } from "../utils/constants";
import {
  getSsoResumeBuildEndPoint,
  getPdfSimpliBaseURL,
  getSignSimpliBaseURL,
  getTrustPilotServiceEndPoint,
  getLegalSimpliBaseURL,
} from "../services/api-constants";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import sha1 from "sha1";
import appLogger from "../utils/app-logger";
import { availableLanguages } from "../i18nextInit";
import moment from "moment";
import { updateBodyRecommendSuccess } from "../pages/cover-letter-body-recommend/actions";
import { updateCloserRecommendSuccess } from "../pages/cover-letter-closer-recommend/actions";
import { updateDesiredCompanySuccess } from "../pages/cover-letter-desired-company/actions";
import { updateExperienceSuccess } from "../pages/cover-letter-experience/actions";
import { updateJobTitleSuccess } from "../pages/cover-letter-job-title/actions";
import { updatePersonalInfoSuccess } from "../pages/cover-letter-personal-information/actions";
import { updateOpenerRecommendSuccess } from "../pages/cover-letter-recommend/actions";
import { updateSignatureSuccess } from "../pages/cover-letter-signature/actions";
import { updateSkillsSuccess } from "../pages/cover-letter-skills/actions";

import AuthService from "../services/auth-service-api";
import UserService from "../services/user-service-api";
import CryptoJS from "crypto-js";
import { useEffect } from "react";
const queryParams = new URLSearchParams(window.location.search);
export const isAuthorizedAccess = () => {
  // const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  // let token = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.TOKEN] : "";
  // if (!!token) {
  //     return true;
  // }
  // else
  // {
  //     return false;
  // }
  return true;
};
export const CreateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const readCookie = (name) => {
  // alert("app.js file");

  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const createCookie = (name, value, days, domain) => {
  var expires = "";
  var _domain = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else expires = "";

  if (domain) {
    _domain = "; domain=" + domain;
  }
  document.cookie = name + "=" + value + expires + _domain + "; path=/;";
};
export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export const deletedomainCookie = (name) => {
  document.cookie = name + "=; domain=.resumebuild.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export const concatStringsUsingComma = (s1, s2, s3, s4, s5) => {
  let arr = [s1, s2, s3, s4, s5];
  return arr.filter((x) => !!x && x != "").join(", ");
};

export const concatNotEmptyBy =
  (separator) =>
  (...args) => {
    return args.filter((n) => n).join(separator);
  };

export default CreateUUID;

export const sorterExperience = (a, b) => {
  const year1 = JSON.parse(a.content).endDateYear;
  const year2 = JSON.parse(b.content).endDateYear;
  const month1 = JSON.parse(a.content).endDateMonth;
  const month2 = JSON.parse(b.content).endDateMonth;
  const presently_here1 = JSON.parse(a.content).presently_here;
  const presently_here2 = JSON.parse(b.content).presently_here;
  if (presently_here1) {
    return -1;
  } else if (presently_here2) {
    return 1;
  } else if (year1 !== year2) {
    return year2 - year1;
  } else {
    return month2 - month1;
  }
};

export const sorterEducation = (a, b) => {
  const year1 = JSON.parse(a.content).education_graduation_year;
  const year2 = JSON.parse(b.content).education_graduation_year;
  const month1 = JSON.parse(a.content).education_graduation_month;
  const month2 = JSON.parse(b.content).education_graduation_month;
  const presently_here1 = JSON.parse(a.content).presently_here;
  const presently_here2 = JSON.parse(b.content).presently_here;
  if (presently_here1) {
    return -1;
  } else if (presently_here2) {
    return 1;
  } else if (year1 !== year2) {
    return year2 - year1;
  } else {
    return month2 - month1;
  }
};

export const getIPConfig = async () => {
  let ipAddress = readCookie("ipAddress");
  let proIpUrl = "https://pro.ip-api.com/json?key=" + proIPKey();
  let ip2LocationUrl = "https://api.ip2location.io/?key=" + ip2LocationKey;
  if (!!ipAddress) {
    proIpUrl = "https://pro.ip-api.com/json/" + ipAddress + "?key=" + proIPKey();
    ip2LocationUrl = "https://api.ip2location.io/?key=" + ip2LocationKey + "&ip=" + ipAddress;
  }
  try {
    let proIpResponse = await axios.get(proIpUrl + "&fields=country,countryCode,status,query,currency,region,zip,city");
    if (
      !!proIpResponse &&
      !!proIpResponse.data &&
      proIpResponse.data.status === "success" &&
      !!proIpResponse.data.zip
    ) {
      createCookieBaseOnProIpAndIp2Response(
        proIpResponse.data.countryCode,
        proIpResponse.data.currency,
        proIpResponse.data.status,
        proIpResponse.data.query,
        proIpResponse.data.region,
        proIpResponse.data.zip,
        proIpResponse.data.city,
        ipAddress
      );
    } else {
      let ip2Response = await axios.get(ip2LocationUrl);
      if (!!ip2Response) {
        createCookieBaseOnProIpAndIp2Response(
          ip2Response.country_code,
          ip2Response.country.currency.code,
          "fail",
          ip2Response.ip,
          ip2Response.region.code.split("-")[1],
          ip2Response.zip_code,
          ip2Response.city_name,
          ipAddress
        );
      }
    }
  } catch (error) {
    console.log("proip fail", error);
    let ip2Response = await axios.get(ip2LocationUrl);
    if (!!ip2Response) {
      createCookieBaseOnProIpAndIp2Response(
        ip2Response.country_code,
        ip2Response.country.currency.code,
        "fail",
        ip2Response.ip,
        ip2Response.region.code.split("-")[1],
        ip2Response.zip_code,
        ip2Response.city_name,
        ipAddress
      );
    }
  }
};

const createCookieBaseOnProIpAndIp2Response = (country, currency, ipStatus, query, region, zip, city, ipAddress) => {
  if (!ipAddress) {
    createCookie("ipAddress", query);
  }
  createCookie("country", country);
  createCookie("currency", currency);
  createCookie("proipstatus", ipStatus);
  createCookie("zip", zip);
  createCookie("region", region);
  createCookie("city", city);
};

export const getPortalCD = (culture) => {
  let portalCD = PORTAL_CD.WSRB;
  if (!!culture) {
    if (culture.toLowerCase() == "es") {
      portalCD = PORTAL_CD.WSRBES;
    } else if (culture.toLowerCase() == "it") {
      portalCD = PORTAL_CD.WSRBIT;
    } else if (culture.toLowerCase() == "fr") {
      portalCD = PORTAL_CD.WSRBFR;
    } else if (culture.toLowerCase() == "pt") {
      portalCD = PORTAL_CD.WSRBPPT;
    } else if (culture.toLowerCase() == "ar") {
      portalCD = PORTAL_CD.WSRBAR;
    }
  }
  portalCD = localStorage.getItem("qsprtlsrc") || portalCD;
  return portalCD;
};
export const gotToDashBoardWithLocale = (locale) => {
  let localeval = "";
  if (!!locale) {
    localeval = locale + "/";
  }
  return getSsoResumeBuildEndPoint + localeval;
};

export const goToPdfSimpli = (locale) => {
  return getPdfSimpliBaseURL + (!locale ? "" : "/" + locale);
};
export const goToSignSimpli = (locale) => {
  return getSignSimpliBaseURL + (!locale ? "" : "/" + locale);
};
export const goToLegalSimpli = () => {
  return getLegalSimpliBaseURL;
};
export const getTrustPilotURL = (email, userGuid) => {
  let tpLink = "";
  if (!!email && !!userGuid) {
    let converted = base64_encode(email);
    let encoded = encodeURIComponent(email);
    userGuid = userGuid.replaceAll("-", "");
    // console.log(TrustPilotKey());
    // console.log(converted);
    // console.log(userGuid);
    // debugger;
    let shaString = sha1(TrustPilotKey() + email + userGuid);
    if (!!getTrustPilotServiceEndPoint) {
      tpLink =
        getTrustPilotServiceEndPoint + "?a=" + userGuid + "&b=" + converted + "&c=" + encoded + "&e=" + shaString;
    }
  }
  //console.log("tpLink", tpLink);
  return tpLink;
};
export const changeToValidFileName = (filname) => {
  filname = filname.replace(/["~!@#$%^&*\(\)=`{}\[\]\|\\:;'<>,\/?"\t\r\n]+/g, "");
  var validFileName = filname.replace(/ /g, " ");
  var ext = filname.split(".").pop().toLowerCase();
  if (validFileName.length >= 75) validFileName = filname.substring(0, 69) + "." + ext;
  return validFileName;
};
export const checkValidExtensionForUploadFile = (ext) => {
  //DOC, DOCX, PDF, RTF, TXT or HTML
  var validExt = false;
  var arrExt = ["pdf", "doc", "docx", "rtf", "txt", "html", "htm"];

  arrExt.forEach((ele) => {
    if (ext == ele) {
      validExt = true;
    }
  });
  return validExt;
};
export const userGuid = () => {
  let userguid = readCookie("userguid");
  if (!!userguid) {
    return userguid.toLocaleLowerCase();
  } else {
    console.log("PLEASE LOGIN !!");
  }
};
export const getServiceToken = async () => {
  let serviceToken = "";
  try {
    serviceToken = window.localStorage.getItem("serviceToken");
    if (!serviceToken) {
      const data = {
        serviceCode: getServiceCode(),
        serviceId: getService(),
        tenantId: getTenantId(),
      };
      const response = await AuthService.GetServiceTokenForUnAuthUser(data);
      serviceToken = response.jwtToken;
      if (!!serviceToken) {
        window.localStorage.setItem("serviceToken", serviceToken);
      }
    }
  } catch (error) {
    appLogger.log("Error in GetServiceTokenForUnAuthUser");
  }

  return serviceToken;
};

export const base64ArrayBuffer = (arrayBuffer) => {
  var base64 = "";
  var encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
};
export const generateUinqueIDentifier = () => {
  var d = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
export const loadScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  document.head.appendChild(script);
};

export const removeScript = (url) => {
  var scripts = document.getElementsByTagName("script");
  for (let script of scripts) {
    if (script.src === url) {
      script.remove();
      break;
    }
  }
};
export const loadScriptWithKey = (url, id, key) => {
  const script = document.createElement("script");
  script.src = url;
  script.id = id;
  script.setAttribute("data-app-key", key);
  document.head.appendChild(script);
};
export const setLocaleURL = (url, locale) => {
  let verifiedLocale = "en";
  if (!!locale) {
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
  }
  return url.replace(":locale", verifiedLocale);
};
export const getLocaleFromPortalSource = () => {
  const portalSource = new URLSearchParams(window.location.search).get("prtlsrc");
  let prtl = "";
  if (!!portalSource) {
    prtl = portalSource;
    if (prtl.length === 6) {
      prtl = prtl.slice(-2);
    }
  }
  return prtl.toLowerCase();
};

export const getCountryCode = async () => {
  let countryCode = "";
  try {
    const res = await axios.get("https://pro.ip-api.com/json?key=qGpUO64dv9znGra&fields=countryCode");
    countryCode = res?.data["countryCode"];
  } catch (error) {}

  return countryCode;
};
export const checkEmpty = (value) => {
  return value === null || value === "" || value === undefined;
};
export const documentName = (name) => {
  if (!checkEmpty(name)) {
    let underScoreName = name.split(" ").join("_");
    return "cover_letter_" + underScoreName + "_" + moment().format("MM/DD/YY");
  } else {
    return "cover_letter_" + moment().format("MM/DD/YY");
  }
};

export const updateActions = (dispatch, t, keyName, item) => {
  switch (keyName) {
    case t("opener.desired_company"):
      dispatch(updateDesiredCompanySuccess(item));
      break;

    case t("opener.desired_position"):
      dispatch(updateJobTitleSuccess(item));
      break;

    case t("opener.opener_recommend"):
      dispatch(updateOpenerRecommendSuccess(item));
      break;

    case t("opener.body_recommend"):
      dispatch(updateBodyRecommendSuccess(item));
      break;

    case t("opener.closer_recommend"):
      dispatch(updateCloserRecommendSuccess(item));
      break;

    case t("signature.signature"):
      dispatch(updateSignatureSuccess(item));
      break;

    case t("personal_info.para_name"):
      dispatch(updatePersonalInfoSuccess(item));
      break;

    case t("opener.skills"):
      dispatch(updateSkillsSuccess(item));
      break;

    case t("opener.experience"):
      dispatch(updateExperienceSuccess(item));
      break;

    default:
      break;
  }
};

export const focusValidateField = (value) => {
  const element = document.getElementById(value);
  if (element) {
    element.focus();
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  }
};

export const findParaName = (paragraphName, characters) => {
  let data = "";
  characters.length > 0 &&
    characters.forEach((item) => {
      if (item.paraName === paragraphName) {
        data = item.paraContents;
      }
    });
  return data;
};
export const findSquareBrackets = (str) => {
  let findSqareBrakets = [];
  for (let i = 0, j = 0; ; ) {
    i = str.indexOf("[", i);
    if (i !== -1) {
      i = i + 1;
      j = str.indexOf("]", i);
      if (j !== -1) {
        findSqareBrakets.push(str.substring(i, j));
        i = j + 1;
        continue;
      }
    }
    break;
  }
  return findSqareBrakets;
};

export const updateUploadDocCookie = (name, value) => {
  var expires = "";
  var _domain = "";
  var expireTime = readCookie("uploadexpiretime");
  expires = "; expires=" + expireTime;
  document.cookie = name + "=" + value + expires + _domain + "; path=/;";
};
export const getZipCityStateBasedOnIP = async (ipAddress) => {
  var lstAddress = [];
  const res = await axios.get(
    "https://pro.ip-api.com/json/" + ipAddress + "?key=" + proIPKey() + "&fields=zip,city,region,country,status"
  );
  lstAddress.push(res.data.city);
  lstAddress.push(res.data.region);
  lstAddress.push(res.data.country);
  lstAddress.push(res.data.zip);
  return lstAddress;
};
export const isAllAddressCookiePresent = () => {
  if (
    !!readCookie("ipAddress") &&
    !!readCookie("currency") &&
    !!readCookie("zip") &&
    !!readCookie("city") &&
    !!readCookie("region") &&
    !!readCookie("country")
  ) {
    return true;
  } else {
    return false;
  }
};
export const navigateToPaymentMS = (docGuid, locale) => {
  // navigate(pathRedirect.next);
  let isPmsEnable = readCookie("pms");
  let isPMSNavigate = false;
  if (isPmsEnable === "true" || isPaymentMSEnable() === true) {
    isPMSNavigate = true;
    createCookie("docId", docGuid, 1);
    window.location.assign(!!locale && locale !== "en" ? `/app/${locale}/billing` : "/app/billing");
  }
  return isPMSNavigate;
};
export const navigateToSignleLoginAPP = (locale, docid, type, NavigateToSignUp) => {
  let queryParam = "";
  if (!!docid && !!type) {
    queryParam = "?docid=" + docid + "&doctype=" + type;
  }
  let actionPage = "/signin";
  if (NavigateToSignUp === true) {
    actionPage = "/signup";
  }
  if (IsSingleLoginAPPEnable()) {
    let redirectionURL = !!locale && locale !== "en" ? `/app/${locale}/account/signup` : "/app/account" + actionPage;
    // window.location.assign(redirectionURL);
    if (`${process.env.REACT_APP_ENVIRONMENT}` == "DEVELOPMENT") {
      window.location.href = "https://localhost:5000/app" + (!locale ? "" : "/" + locale) + "/account" + actionPage;
    } else {
      window.location.assign(redirectionURL + queryParam);
    }
  } else {
    console.log("IsSingleLoginAPPEnable disable..");
  }
};
export const isUserLoggedIn = () => {
  let isLoggedIn = false;
  if (!!readCookie("jwtToken") || !!readCookie("refreshToken")) {
    isLoggedIn = true;
  }
  return isLoggedIn;
};

export const DecryptedString = (encryptedText, key) => {
  const iv = getInitializationVector();
  const bytes = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
  });
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  console.log(decrypted);
  return decrypted;
};

export const PerquisiteBountyEnable = (chiperText, userGuid) => {
  const decryptVal = DecryptedString(chiperText, userGuid.replaceAll("-", ""));
  return decryptVal?.toLowerCase() === "true" ? true : false;
};
export const useGTM = () => {
  useEffect(() => {
    const gtmTagValue = "GTM-59VL2P9";
    // Define the dataLayer array if it does not exist
    window.dataLayer = window.dataLayer || [];

    // Push the initial GTM event
    window.dataLayer.push({
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
    });

    // Create the GTM script tag
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmTagValue}`;
    document.head.appendChild(script);

    return () => {
      // Clean up the script if the component unmounts
      document.head.removeChild(script);
    };
  }, []);
};
