import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResumeAction from "./component";
import { resetStore } from "../../pages/review/actions";
import { deleteResume, duplicateResume } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStoreConnect: resetStore,
      duplicateResumeConnect: duplicateResume,
      deleteResumeConnect: deleteResume,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResumeAction);
