import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  userDocs: null,
};

export const getUserDocsSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, userDocs: data };
};

export const HANDLERS = {
  [Types.GET_SUCCESS]: getUserDocsSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
