import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
  data: "",
};

export const saveSignatureInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const saveTabValueInfo = (state = INITIAL_STATE, data) => {
  return { ...state, data: data.data };
};
export const updateSignatureInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const resetSignatureInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveSignatureInfo,
  [Types.UPDATE_SUCCESS]: updateSignatureInfo,
  [Types.RESET_SIGNATURE]: resetSignatureInfo,
  [Types.SAVE_TAB_VALUE]: saveTabValueInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
