export default {
  paymentSuccess: {
    event: "rb_paymentSuccess",
    eventCategory: "Payment",
    eventAction: "Payment Button Click",
  },
  paymentFailure: {
    event: "rb_paymentFailure",
    eventCategory: "Payment",
    eventAction: "Payment Button Click",
  },
  introductionView: {
    event: "rb_introduction",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  chooseView: {
    event: "rb_choose",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  chooseOptionsView: {
    event: "rb_chooseOptions",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  uploadResumeView: {
    event: "rb_uploadResume",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  selectedDocumentView: {
    event: "rb_selectedDocument",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  personalInfoView: {
    event: "rb_personalInfo",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  experienceTipsView: {
    event: "rb_experienceTips",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  experienceEditView: {
    event: "rb_experienceEdit",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  educationTipsView: {
    event: "rb_educationTips",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  educationEditView: {
    event: "rb_educationEdit",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  skillsTipsView: {
    event: "rb_skillTips",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  skillsEditView: {
    event: "rb_skillEdit",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  summaryTipsView: {
    event: "rb_summaryTips",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  summaryEditView: {
    event: "rb_summaryEdit",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  signUpView: {
    event: "rb_signUp",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  signInView: {
    event: "rb_signIn",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  resumeRevisitView: {
    event: "rb_resumeRevisit",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  planView: {
    event: "rb_plan",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  checkoutView: {
    event: "rb_checkout",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  confirmationView: {
    event: "rb_confirmation",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvIntroductionView: {
    event: "cv_introduction",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvChooseTemplateView: {
    event: "cv_choose_template",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvPersonalInfoView: {
    event: "cv_personal_info",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvOpenerView: {
    event: "cv_opener",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvJobTitleView: {
    event: "cv_jobTitle",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvDesiredCompanyView: {
    event: "cv_desired_company",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvExperienceView: {
    event: "cv_experience",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvOpenerRecommendView: {
    event: "cv_opener_recommend",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvBodyView: {
    event: "cv_body",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvSkillsView: {
    event: "cv_skills",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvBodyRecommendView: {
    event: "cv_body_recommend",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvCloserView: {
    event: "cv_closer",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvCloserRecommendView: {
    event: "cv_closer_recommend",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvSignatureView: {
    event: "cv_signature",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
  cvReviewView: {
    event: "cv_review",
    eventCategory: "R_Builder_Page_View",
    eventAction: "PageView",
  },
};
