import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSignInLink } from "../../utils/path-helper";
import Button from "../../components/shared/button";
import { Trans, useTranslation } from "react-i18next";

const SessionExpire = ({ resetStoreConnect }) => {
  const { locale } = useParams();
  const navigate = useNavigate();
  let signInLink = getSignInLink(locale);
  const { t } = useTranslation();
  useEffect(() => {
    signOutUser();
  }, []);
  const navigateSignIn = () => {
    navigate(signInLink);
  };

  const signOutUser = () => {
    localStorage.clear();
    resetStoreConnect(true);
  };

  return (
    <div>
      <h2>
        Your session has been expired. Please login again -
        <Button
          isLoading={false}
          disabled={false}
          onClick={navigateSignIn}
          classNames="btn btn-link px-2"
          ctaText={t("sign_up.sign_in")}
        />
      </h2>
    </div>
  );
};

export default SessionExpire;
