import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";

const createRecommendParagraph = (
  docGUID,
  paragraph,
  paraName,
  isSelected,
  data
) => {
  return function (dispatch) {
    if (docGUID === "" || docGUID == undefined) {
      return DocBuilderService.createDoc(data).then((response) => {
        if (response) {
          let para = createParagraph(
            response.docGuid,
            EMPTY_GUID,
            EMPTY_GUID,
            paragraph,
            SECTION_ORDER.CLSR,
            SECTION_TYPE.CLSR,
            SECTION_CODE.CLSR,
            paraName
          );

          DocBuilderService.createParagraph(response.docGuid, para).then(
            (paraResponse) => {
              dispatch(saveRecommendSuccess(paraResponse, paraResponse.id));
              dispatch(saveSelected(isSelected));
            }
          );
        }
        return response;
      });
    } else {
      let para = createParagraph(
        docGUID,
        EMPTY_GUID,
        EMPTY_GUID,
        paragraph,
        SECTION_ORDER.CLSR,
        SECTION_TYPE.CLSR,
        SECTION_CODE.CLSR,
        paraName
      );

      return DocBuilderService.createParagraph(docGUID, para).then(
        (paraResponse) => {
          dispatch(saveRecommendSuccess(paraResponse, paraResponse.id));
          dispatch(saveSelected(isSelected));
          let response = {
            docGuid: docGUID,
          };
          return response;
        }
      );
    }
  };
};

const updateRecommendParagraph = (docid, paraid, data, isSelected) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateCloserRecommendSuccess(response));
          dispatch(saveSelected(isSelected));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveRecommendSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateCloserRecommendSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

export const saveSelected = (data) => ({
  type: Types.SAVE_SELECTED,
  data,
});

export const resetCloserRecommend = () => ({
  type: Types.RESET_CLOSER_RECOMMEND,
});

export { createRecommendParagraph, updateRecommendParagraph };
