import React, { useState, useEffect } from "react";
import TempHeader from "../temp-header";
import TempPersonalInfo from "../temp-personal-info";
import TempSummary from "../temp-summary";
import TempExperience from "../temp-experience";
import TempSkills from "../temp-skills";
import TempEducation from "../temp-education";
import TempCustom from "../temp-custom";
import TempLanguages from "../temp-languages";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { validateEmptyArray } from "../../../utils/utility-functions";

const FinalReview = React.forwardRef(
  (
    {
      data,
      headingfontsize,
      docReview,
      resumeTemplate,
      resumeCategory,
      resumePosition,
      resumePersonalInfo,
      paragraphOrdering,
      resumeOnDashboard,
      toggleRenameModal,
      toggleDeleteModal,
      setSelectedRenameSection,
      componentRef,
      fontSize,
      fontFamily,
    },
    ref
  ) => {
    const { reviewDoc } = data;
    const [docSectionDetail, setDocSectionDetail] = useState(reviewDoc?.paragraphs || []);

    const SelectiveSection = {
      HEAD: TempHeader,
      SUMM: TempSummary,
      EXPR: TempExperience,
      SKLS: TempSkills,
      EDUC: TempEducation,
      CSTM: TempCustom,
      LANG: TempLanguages,
      OTHR: TempCustom,
    };
    let filterData = data.filter((x) => x.paraCD != "NONE");

    useEffect(() => {
      if (!!data && data.length > 0) {
        let filterData = data.filter((x) => x.paraCD != "NONE");
        updateCharacters(filterData);
      }
    }, [JSON.stringify(data)]);

    // Single
    const [characters, updateCharacters] = useState(filterData);

    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
      const items = Array.from(characters);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      let list = [];
      items.map((attr) =>
        list.push({
          order: attr.order,
          paraid: attr.id,
        })
      );
      list.map((currElement, index) => {
        currElement.order = index;
      });
      updateCharacters(items);
      paragraphOrdering(JSON.stringify(list));
    };
    // Double
    const [charactersAside, updateCharactersAside] = useState(
      characters?.filter((item) => ["HEAD", "SKLS", "LANG"].includes(item.paraCD))
    );
    const [charactersMain, updateCharactersMain] = useState(
      characters?.filter((item) => !["HEAD", "SKLS", "LANG"].includes(item.paraCD))
    );
    const handleOnDragAside = (result) => {
      if (!result.destination) return;
      const items = Array.from(charactersAside);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateCharactersAside(items);
    };
    const handleOnDragMain = (result) => {
      if (!result.destination) return;
      const items = Array.from(charactersMain);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateCharactersMain(items);
    };

    // Tripple
    const [charactersTrippleAside, updateCharactersTrippleAside] = useState(
      characters?.filter((item) => ["HEAD", "SKLS", "LANG"].includes(item.paraCD))
    );
    const [charactersTrippleMain, updateCharactersTrippleMain] = useState(
      characters?.filter((item) => !["HEAD", "SKLS", "LANG"].includes(item.paraCD))
    );
    const charactersHead = characters?.filter((item) => ["HEAD"].includes(item.paraCD));

    const handleOnDragTrippleAside = (result) => {
      if (!result.destination) return;
      const items = Array.from(charactersTrippleAside);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateCharactersTrippleAside(items);
    };
    const handleOnDragTrippleMain = (result) => {
      if (!result.destination) return;
      const items = Array.from(charactersTrippleMain);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateCharactersTrippleMain(items);
    };
    return (
      <>
        <div className="resume-wrapper" ref={componentRef}>
          <div className="resume">
            <div
              className={"resume-layout " + resumeTemplate + " " + resumeCategory + " " + resumePosition}
              style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
              }}
            >
              {resumeCategory === "tripple" ? (
                <div className="tripple-column resume-layout-box">
                  <div className="resume-header sdfsfsf">
                    <TempHeader
                      headingfontsize={headingfontsize}
                      templateType={resumeTemplate}
                      resumePersonalInfo={resumePersonalInfo}
                      data={validateEmptyArray(charactersHead) ? charactersHead[0].paraContents : []}
                      section={"HEAD"}
                    />
                  </div>
                  <div className="double-column">
                    <DragDropContext onDragEnd={handleOnDragTrippleAside}>
                      <Droppable droppableId="charactersTrippleAside" isDropDisabled={resumeOnDashboard}>
                        {(provided) => (
                          <div
                            className={resumePosition === "left" ? "double-column--left" : "double-column--right"}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {charactersTrippleAside?.map((item, index) => {
                              if (item.paraContents && item.paraContents.length > 0) {
                                const Section = SelectiveSection[item.paraCD];
                                {
                                  Object.assign(item.paraContents[0], {
                                    headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                  });
                                }
                                return (
                                  <Draggable
                                    key={item.paraCD + index}
                                    draggableId={item.paraCD + index}
                                    index={index}
                                    isDragDisabled={resumeOnDashboard}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Section
                                          // key={item.paraCD + index}
                                          headingfontsize={headingfontsize}
                                          templateType={resumeTemplate}
                                          data={item.paraContents}
                                          section={item.paraCD}
                                          resumePersonalInfo={resumePersonalInfo}
                                          paraName={item.paraName}
                                          docGuid={item.docGuid}
                                          paraId={item.id}
                                          toggleRenameModal={toggleRenameModal}
                                          toggleDeleteModal={toggleDeleteModal}
                                          setSelectedRenameSection={setSelectedRenameSection}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <DragDropContext onDragEnd={handleOnDragTrippleMain}>
                      <Droppable droppableId="charactersTrippleMain" isDropDisabled={resumeOnDashboard}>
                        {(provided) => (
                          <div className="double-column--right" {...provided.droppableProps} ref={provided.innerRef}>
                            {charactersTrippleMain?.map((item, index) => {
                              if (item.paraContents && item.paraContents.length > 0) {
                                const Section = SelectiveSection[item.paraCD];
                                {
                                  Object.assign(item.paraContents[0], {
                                    headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                    templateType: resumeTemplate,
                                  });
                                }
                                return (
                                  <Draggable
                                    key={item.paraCD + index}
                                    draggableId={item.paraCD + index}
                                    index={index}
                                    isDragDisabled={resumeOnDashboard}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Section
                                          // key={item.paraCD + index}
                                          headingfontsize={headingfontsize}
                                          templateType={resumeTemplate}
                                          data={item.paraContents}
                                          section={item.paraCD}
                                          paraName={item.paraName}
                                          docGuid={item.docGuid}
                                          paraId={item.id}
                                          toggleRenameModal={toggleRenameModal}
                                          toggleDeleteModal={toggleDeleteModal}
                                          setSelectedRenameSection={setSelectedRenameSection}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              ) : resumeCategory === "double" ? (
                <div className={`double-column resume-layout-box`}>
                  <DragDropContext onDragEnd={handleOnDragAside}>
                    <Droppable droppableId="charactersAside" isDropDisabled={resumeOnDashboard}>
                      {(provided) => (
                        <div
                          className={resumePosition === "left" ? "double-column--left" : "double-column--right"}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {charactersAside?.map((item, index) => {
                            if (item.paraContents && item.paraContents.length > 0) {
                              const Section = SelectiveSection[item.paraCD];
                              {
                                Object.assign(item.paraContents[0], {
                                  headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                });
                              }
                              return (
                                <Draggable
                                  key={item.paraCD + index}
                                  draggableId={item.paraCD + index}
                                  index={index}
                                  isDragDisabled={resumeOnDashboard}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Section
                                        // key={item.paraCD + index}
                                        headingfontsize={headingfontsize}
                                        templateType={resumeTemplate}
                                        data={item.paraContents}
                                        section={item.paraCD}
                                        paraName={item.paraName}
                                        docGuid={item.docGuid}
                                        paraId={item.id}
                                        toggleRenameModal={toggleRenameModal}
                                        toggleDeleteModal={toggleDeleteModal}
                                        setSelectedRenameSection={setSelectedRenameSection}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <DragDropContext onDragEnd={handleOnDragMain}>
                    <Droppable droppableId="charactersMain" isDropDisabled={resumeOnDashboard}>
                      {(provided) => (
                        <div className="double-column--right" {...provided.droppableProps} ref={provided.innerRef}>
                          {charactersMain?.map((item, index) => {
                            if (item.paraContents && item.paraContents.length > 0) {
                              const Section = SelectiveSection[item.paraCD];
                              {
                                Object.assign(item.paraContents[0], {
                                  headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                  templateType: resumeTemplate,
                                });
                              }
                              return (
                                <Draggable
                                  key={item.paraCD + index}
                                  draggableId={item.paraCD + index}
                                  index={index}
                                  isDragDisabled={resumeOnDashboard}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Section
                                        // key={item.paraCD + index}
                                        headingfontsize={headingfontsize}
                                        templateType={resumeTemplate}
                                        data={item.paraContents}
                                        section={item.paraCD}
                                        paraName={item.paraName}
                                        docGuid={item.docGuid}
                                        paraId={item.id}
                                        toggleRenameModal={toggleRenameModal}
                                        toggleDeleteModal={toggleDeleteModal}
                                        setSelectedRenameSection={setSelectedRenameSection}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <div className="single-column resume-layout-box">
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters" isDropDisabled={resumeOnDashboard}>
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {characters?.map((item, index) => {
                            //debugger;
                            if (item.paraContents && item.paraContents.length > 0) {
                              const Section = SelectiveSection[item.paraCD];
                              {
                                Object.assign(item.paraContents[0], {
                                  headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                  resumeOnDashboard: resumeOnDashboard,
                                });
                              }

                              return (
                                <Draggable
                                  key={item.paraCD + index}
                                  draggableId={item.paraCD + index}
                                  index={index}
                                  isDragDisabled={resumeOnDashboard}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Section
                                        // key={item.paraCD + index}
                                        headingfontsize={headingfontsize}
                                        templateType={resumeTemplate}
                                        data={item.paraContents}
                                        section={item.paraCD}
                                        paraName={item.paraName}
                                        docGuid={item.docGuid}
                                        paraId={item.id}
                                        resumeOnDashboard={resumeOnDashboard}
                                        toggleRenameModal={toggleRenameModal}
                                        toggleDeleteModal={toggleDeleteModal}
                                        setSelectedRenameSection={setSelectedRenameSection}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default FinalReview;
