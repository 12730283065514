import React, { useEffect, useState, useCallback } from "react";
import InputText from "../../components/shared/input-text/component";
import RBDropDown from "../../components/shared/dropdown/component";
import { getYearList } from "../../services/master-data";

import { useTranslation } from "react-i18next";

const EducationFields = ({ callback, isValid, errors, educationFieldsData, updateCall, setShowStickyBar }) => {
  const { t } = useTranslation();
  const [yearList, setYearList] = useState(getYearList());
  const [schoolName, setschoolName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [selectdegree, setdegree] = useState("");
  const [fieldofstudy, setfieldofstudy] = useState("");
  const [graduationMonth, setgraduationMonth] = useState("");
  const [graduationMonthLabel, setGraduationMonthLabel] = useState("");
  const [graduationYear, setgraduationYear] = useState("");
  const [presentlyAttend, setPresentlyAttend] = useState(false);
  const [schoolerrormessage, setSchoolErrorMessage] = useState("");
  const [degreeErrorMessage, setDegreeErrorMessage] = useState("");
  const [fieldofstudyErrorMessage, setFieldofstudyErrorMessage] = useState("");
  const [showCustomDegree, setshowCustomDegree] = useState("d-none");
  const [customDegree, setcustomDegree] = useState("");
  const [disableGraduationMonth, setDisableGraduationMonth] = useState(false);
  let monthList = t("months_name.data", { returnObjects: true });
  let degreeList = t("education.degree_list", { returnObjects: true });
  let educationField = {
    school_name: schoolName,
    city: city,
    state: state,
    degree: selectdegree,
    custom_degree: customDegree,
    field_of_study: fieldofstudy,
    education_graduation_month: graduationMonth,
    graduationMonthLabel: graduationMonthLabel != "empty" ? graduationMonthLabel : "",
    education_graduation_year: graduationYear != "empty" ? graduationYear : "",
    presently_here: presentlyAttend,
    labelPresent: t("education.label_Present"),
  };
  useEffect(() => {
    if (!!educationFieldsData && !!educationFieldsData.content) {
      SetFormData(educationFieldsData);
    }
    //callback(educationField);
  }, [JSON.stringify(educationFieldsData)]);
  const CheckDegreeExist = (value, degreeList) => {
    var IsExist = false;
    for (var i = 0; i < degreeList?.data?.length; i++) {
      var name = degreeList?.data[i]?.value;
      if (name == value) {
        IsExist = true;
        break;
      }
    }
    return IsExist;
  };
  const SetFormData = (para) => {
    setschoolName(JSON.parse(educationFieldsData.content).school_name);
    setCity(JSON.parse(educationFieldsData.content).city);
    setState(JSON.parse(educationFieldsData.content).state);
    setdegree(JSON.parse(educationFieldsData.content).degree);

    setcustomDegree(JSON.parse(educationFieldsData.content).custom_degree);
    if (JSON.parse(educationFieldsData.content).degree === "Custom Degree") {
      setshowCustomDegree("d-block");
    } else {
      setcustomDegree("");
    }
    setfieldofstudy(JSON.parse(educationFieldsData.content).field_of_study);
    setgraduationMonth(JSON.parse(educationFieldsData.content).education_graduation_month);
    setgraduationYear(JSON.parse(educationFieldsData.content).education_graduation_year);
    setGraduationMonthLabel(JSON.parse(educationFieldsData.content).graduationMonthLabel);
    setPresentlyAttend(JSON.parse(educationFieldsData.content).presently_here);

    if (JSON.parse(educationFieldsData?.content)?.degree) {
      const IsDegreeExist = CheckDegreeExist(JSON.parse(educationFieldsData.content).degree, degreeList);
      if (!IsDegreeExist) {
        setshowCustomDegree("d-block");
        setdegree("Custom Degree");
        setcustomDegree(JSON.parse(educationFieldsData.content).degree);
      }
    }
  };

  useEffect(() => {
    setSchoolErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "schoolname")["errorMessage"] : ""
    );
    setDegreeErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "degree")["errorMessage"] : ""
    );
    setFieldofstudyErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "field_of_study")["errorMessage"] : ""
    );
    let keys = Object.keys(errors);
    if (keys.length > 0) {
      if (errors[2].errorMessage === "Required") {
        setdegree(null);
        setshowCustomDegree("d-none");
      }
      window.scrollTo(0, 0);
    }
  }, [errors]);

  const onschoolNameChange = (e) => {
    const {
      target: { value },
    } = e;
    setschoolName(value);
    setSchoolErrorMessage("");
    //callback(value, "school_name");
    educationField.school_name = value;
    callback(educationField);
  };
  const addCityHandler = (e) => {
    const {
      target: { value },
    } = e;
    setCity(value);
    educationField.city = value;
    callback(educationField);
  };
  const addStateHandler = (e) => {
    const {
      target: { value },
    } = e;
    setState(value);
    //callback(value, "state");
    educationField.state = value;
    callback(educationField);
  };
  const addfieldofstudyHandler = (e) => {
    const {
      target: { value },
    } = e;
    setfieldofstudy(value);
    setFieldofstudyErrorMessage("");
    //callback(value, "field_of_study");
    educationField.field_of_study = value;
    callback(educationField);
  };
  const addcustomDegreeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setcustomDegree(value);
    //callback(value, "field_of_study");
    educationField.custom_degree = value;
    callback(educationField);
  };
  const addgraduationmonthHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setgraduationMonth(value);
    //callback(value, "education_graduation_month");
    let selectedmonth = e.target.selectedOptions[0].text.replace(/<(.|\n)*?>/g, "").substring(0, 3);
    setGraduationMonthLabel(selectedmonth);

    if (selectedmonth == "Mon") {
      selectedmonth = "";
    }
    if (educationField.education_graduation_year === "empty") {
      educationField.education_graduation_year = "";
    }
    if (value === "empty") {
      educationField.education_graduation_month = "";
      educationField.graduationMonthLabel = "";
      callback(educationField);
    } else {
      educationField.education_graduation_month = value;
      educationField.graduationMonthLabel = selectedmonth;
      callback(educationField);
    }
  };
  const addgraduationyearHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setgraduationYear(value);
    if (value == "empty") {
      educationField.education_graduation_year = "";
      setgraduationMonth("");
      educationField.education_graduation_month = "";
      educationField.graduationMonthLabel = "";
      setDisableGraduationMonth(true);
      callback(educationField);
    } else {
      setDisableGraduationMonth(false);
      educationField.education_graduation_year = value;
      callback(educationField);
    }

    //callback(value, "education_graduation_year");
  };
  const degreeHandleChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setdegree(value);
    setDegreeErrorMessage("");
    //callback(value, "degree");
    educationField.degree = value;
    if (value === "Custom Degree") {
      setshowCustomDegree("d-block");
    } else {
      setshowCustomDegree("d-none");
    }
    callback(educationField);
  };
  const handlePresentlyAttendCheck = (e) => {
    const {
      target: { checked },
    } = e;
    setPresentlyAttend(checked);
    //callback(checked.toString(), "presently_here");
    educationField.presently_here = checked;
    callback(educationField);
  };
  const onFocusCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(false);
    }
  };
  const onBlurCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  };
  window.addEventListener("scroll", (event) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  });
  return (
    <>
      <div className="row">
        <div
          className={"form-group string optional heading_name col-sm-6 " + (schoolerrormessage != "" ? "warning" : "")}
        >
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="school_name"
              name="schoolname"
              text={t("education.school_name")}
              type="text"
              required
              maxLength="50"
              value={schoolName}
              onChange={onschoolNameChange}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("education.school_name_placeholder")}
            />
          </div>
          <div className="form-text">
            <small key="pswrdErr" className="form-text text-danger">
              {schoolerrormessage}
            </small>
          </div>
        </div>
        <div className="form-group string optional heading_city col-sm-6">
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="heading_city"
              name="heading[city]"
              text={t("education.city")}
              type="text"
              maxLength="20"
              value={city}
              onChange={addCityHandler}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("education.city_placeholder")}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group string optional heading_city col-sm-6">
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="state"
              name="state"
              text={t("education.state")}
              type="text"
              maxLength="20"
              value={state}
              onChange={addStateHandler}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("education.state_placeholder")}
            />
          </div>
        </div>
        <div
          className={
            "form-group string optional education_degree col-sm-6 " + (degreeErrorMessage != "" ? "warning" : "")
          }
        >
          <label className="string optional" htmlFor="education_degree">
            {t("education.select_a_degree")}
          </label>
          <div className="tooltip-wrapper">
            <RBDropDown
              selectedValue={selectdegree || ""}
              options={degreeList.data}
              id="edu-degreelist"
              onChange={degreeHandleChange}
            />
          </div>
          <div className="form-text">
            <small key="pswrdErr" className="form-text text-danger">
              {degreeErrorMessage}
            </small>
          </div>
        </div>
        <div className={"form-group string optional education_degree col-sm-6 offset-md-6 " + showCustomDegree}>
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              required
              maxLength="30"
              id="field_of_Study"
              name="fieldofstudy"
              type="text"
              value={customDegree}
              onChange={addcustomDegreeHandler}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("education.custum_degree_placeholder")}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className={"form-group string optional col-sm-6 " + (fieldofstudyErrorMessage != "" ? "warning" : "")}>
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              required
              id="field_of_Study"
              name="fieldofstudy"
              text={t("education.field_of_Study")}
              type="text"
              value={fieldofstudy}
              onChange={addfieldofstudyHandler}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("education.field_of_Study_placeholder")}
            />
          </div>
          <div className="form-text">
            <small key="pswrdErr" className="form-text text-danger">
              {fieldofstudyErrorMessage}
            </small>
          </div>
        </div>
        <div className="form-group col-sm-6">
          <label className="string optional" htmlFor="experience_end_date">
            {t("education.graduation_date")}
          </label>
          <div className="row date-selector">
            <div
              className="form-group select optional experience_end_month col select-with-placeholder month-selector"
              id="exp_end_month"
            >
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={graduationMonth || ""}
                  name="graduation[month]"
                  id="graduation_month"
                  options={monthList}
                  disabled={presentlyAttend === true || disableGraduationMonth === true ? true : false}
                  onChange={addgraduationmonthHandler}
                />
              </div>
            </div>
            <div className="form-group select optional experience_end_year col select-with-placeholder year-selector">
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={graduationYear || ""}
                  name="graduation[year]"
                  id="graduation_year"
                  yearText={t("experience.year")}
                  options={yearList.data}
                  disabled={presentlyAttend === true ? true : false}
                  onChange={addgraduationyearHandler}
                />
              </div>
            </div>
          </div>

          <div className="boolean optional education_presently_attend_here builder-form__extra-field">
            <input name="education[presently_attend_here]" type="hidden" value="0" />
            {/* <InputCheckbox
              className="boolean optional"
              type="checkbox"
              value={presentlyAttend}
              presentlyAttend={presentlyAttend}
              id="education_presently_attend_here"
              name="education[presently_attend_here]"
              callback={handlePresentlyAttendCheck}
            /> */}
            <input
              data-on-check-disable="#graduation_year, #graduation_month"
              className="boolean optional"
              type="checkbox"
              value={presentlyAttend}
              {...(presentlyAttend === true && { checked: "checked" })}
              name="education[presently_attend_here]"
              onChange={handlePresentlyAttendCheck}
            />
            <label
              className="boolean optional"
              id="education_presently_attend_here"
              htmlFor="education_presently_attend_here"
            >
              {t("education.presently_attend_here")}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationFields;
