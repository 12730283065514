import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    docGuid: ''
};

export const updateIntroduction = (state = INITIAL_STATE,data) => {
    return {  docGuid: data.docGuid};
};

export const saveIntroduction = (state = INITIAL_STATE,data) => {
    return {...state, docGuid: data.docGuid};
};


export const HANDLERS = {
    [Types.UPDATE_SUCCESS]: updateIntroduction,
    [Types.SAVE_SUCCESS]: saveIntroduction
};

export default createReducer(INITIAL_STATE, HANDLERS);