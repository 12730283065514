import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import appLogger from "../../utils/app-logger";
import Button from "../../components/shared/button";
import PathHelper from "../../utils/path-helper";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { APP_NAVIGATION, SECTION_CODE } from "../../utils/constants";
import { EMPTY_GUID, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import moment from "moment";
import TextEditor from "../../components/text-editor";
import dompurify from "dompurify";
import { useTranslation } from "react-i18next";
import ResumeMap from "../../components/resume-map";
import { Helmet } from "react-helmet";

const OtherSection = ({
  othersection,
  docReview,
  otherSectionConnect,
  funnelStep,
  updateOtherSectionConnect,
}) => {
  const { sectionname, id } = useParams();
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  let pathRedirect = PathHelper(APP_NAVIGATION.OTHR);
  const navigate = useNavigate();
  const { paraContents, paraName } =
    othersection.Paragraphs.find((x) => x.paraName === sectionname) ??
    othersection.Paragraphs[0];
  const { docGUID } = funnelStep || {};
  const [otherSectionParaGuid, setOtherSectionParaGuid] = useState(EMPTY_GUID);
  const [editorContent, setEditorContent] = useState("");
  const [allValues, setAllValues] = useState();
  const [sectionOrder, setsectionOrder] = useState();
  useEffect(() => {
    if (
      !!docReview &&
      !!docReview.reviewDoc &&
      !!docReview.reviewDoc.paragraphs
    ) {
      let section = docReview.reviewDoc.paragraphs.find((element) => {
        return element.paraName.toLowerCase() === sectionname.toLowerCase();
      });
      if (!!section) {
        setsectionOrder(section.order);
      }
    }

    if (!!paraContents) {
      setEditorContent(
        !!paraContents && paraContents.length > 0 ? paraContents[0].content : ""
      );
      setOtherSectionParaGuid(
        !!paraContents && paraContents.length > 0 && paraName === sectionname
          ? paraContents[0].paraId
          : EMPTY_GUID
      );
    }
  }, []);
  const callBackEditorVal = (fieldValue, fieldId) => {
    setAllValues({ ...allValues, [fieldId]: fieldValue });
  };
  const saveOtherSectionClickHandler = () => {
    appLogger.log("saveOtherSectionClickHandler");
    if (!!allValues) {
      var otherSectionParaContent = [];
      otherSectionParaContent.push({
        id: EMPTY_GUID,
        paraId: otherSectionParaGuid,
        content: JSON.stringify(allValues),
        order: 0,
        createdOn: moment(new Date()),
        modifiedOn: moment(new Date()),
      });
      var para = {
        id: otherSectionParaGuid,
        docId: docGUID,
        paraContents: [...otherSectionParaContent],
        order:
          sectionOrder > 0
            ? sectionOrder
            : !!othersection &&
              !!othersection.Paragraphs &&
              !!othersection.Paragraphs.order &&
              othersection.Paragraphs.order != 0
            ? othersection.Paragraphs.order
            : SECTION_ORDER.OTHR,
        paraCD: SECTION_TYPE.OTHR,
        sectionCD: SECTION_CODE.OTHR,
        paraName: sectionname,
      };
      setAllValues([]);
      if (!!otherSectionParaGuid && otherSectionParaGuid !== EMPTY_GUID) {
        updateOtherSectionConnect(docGUID, otherSectionParaGuid, para)
          .then((response) => {
            console.log(response);
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        otherSectionConnect(para)
          .then((response) => {
            appLogger.log("inside otherSectionConnect then ");
            navigate(pathRedirect.next);
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
    } else {
      navigate(pathRedirect.next);
    }
  };
  let back_action = PathHelper(APP_NAVIGATION.OTHR, false);

  const backClickHandler = () => {
    navigate(back_action.back);
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.other_section")}</title>
      </Helmet>
      <section className="section main-content templates">
        <div className="container-fluid">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="builder-header exp-header">
                <hgroup>
                  <h1>{sectionname.toUpperCase()}</h1>
                </hgroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-9 other-editor-section">
                <label
                  className="text optional"
                  htmlFor="resume_summary_attributes_content"
                >
                  {t("summary.content")}
                </label>
                <div className="col-sm-12 col-lg-12 text-editor-container">
                  <div className="writer other-editor">
                    <TextEditor
                      sectionCode={SECTION_TYPE.OTHR}
                      callback={callBackEditorVal}
                      editorContent={editorContent}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--summary">
                <ResumeMap />
              </div>
            </div>

            <div className="btn-container fbn btn-justify">
              <Button
                isLoading={false}
                classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                ctaText={t("summary.back_action")}
                onClick={backClickHandler}
              />
              <Button
                isLoading={false}
                disabled={false}
                classNames="btn btn-primary btn-lg submit-on-enter"
                ctaText={t("summary.next_action")}
                onClick={saveOtherSectionClickHandler}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OtherSection;
