import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckoutPage from "./component";
import {
  paymentProcess,
  getSkuDetails,
  orderStatus,
  getUserProfile,
} from "./actions";
import { eventServicePost } from "../sign-up/actions";

const mapStateToProps = ({ CheckoutPage, funnelStep, checkout }) => ({
  CheckoutPage,
  funnelStep,
  checkout,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkoutConnect: paymentProcess,
      skuConnect: getSkuDetails,
      orderConnect: orderStatus,
      getUserProfileConnect: getUserProfile,
      eventServicePostConnect: eventServicePost,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
