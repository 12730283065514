import React, { useEffect, useState } from "react";
import "../../assets/scss/login-forms.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import ToastMessage from "../../components/toast-message";
import appLogger from "../../utils/app-logger";
import InputText from "../../components/shared/input-text/component";
import FacebookLogin from "react-facebook-login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateUUID, readCookie, getIPConfig, getPortalCD, isAllAddressCookiePresent } from "../../utils/helpers";
import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { validateEmail, validatePassword } from "../../validators/genericValidator";
import { EMPTY_GUID, TYPE_OF_DOC } from "../../utils/constants";
import i18next from "i18next";
import PathHelper from "../../utils/path-helper";
import { getSignUpLink, getForgetPasswordLink } from "../../utils/path-helper";
import { APP_NAVIGATION, DOC_TYPE } from "../../utils/constants";

import { proIPKey, getTenantId, getTenantGroupId } from "../../utils/general-config";
import axios from "axios";
import ReactLoader from "../../components/shared/loader";
import { getGoogleClientId, getFacebookClientId } from "../../utils/general-config";
import { Helmet } from "react-helmet";
import { useGoogleLogin } from "@react-oauth/google";
import { trackRBEvents } from "../../utils/tracking-events";
const SignIn = ({
  signInUserConnect,
  signUpExternalUserConnect,
  funnelStep,
  getUserDocsByTypeOfDocConnect,
  eventServicePostConnect,
  setDocTypeConnect,
}) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  let signUpLink = getSignUpLink(locale);
  let forgetpwdLink = getForgetPasswordLink(locale);
  const navigate = useNavigate();
  const [emailId, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const [disableBtn, setdisableBtn] = useState(false);
  const { docGUID, userGUID, personalInfoName, email, docType } = funnelStep || {};
  const [showLoader, setShowLoader] = useState("d-none");
  let pathRedirectSignIn = PathHelper(APP_NAVIGATION.CVLTRSINI);

  let pathRedirect = PathHelper(APP_NAVIGATION.SINI);
  useEffect(() => {
    // trackRBEvents("paymentSuccess", {
    //   eventLabel: "payment confirmation view",
    // });
    trackRBEvents("signInView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    NavigateToReviewForAuth();
    if (!isAllAddressCookiePresent()) {
      getIPConfig();
    }
    const documentType = new URLSearchParams(window.location.search).get("doctype");
    setDocType(documentType);
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (!!tokenResponse) {
        const userInfo = await axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          })
          .then((res) => {
            responseGoogle(res.data, tokenResponse.access_token);
          });
      } else {
        setLoginError(true);
        setLoginErrorMessage("Failed to login with google");
      }
    },
    onError: (errorResponse) => {
      setLoginError(true);
      setLoginErrorMessage("Failed to login with google");
      console.log(errorResponse);
    },
  });

  const NavigateToReviewForAuth = () => {
    if (
      // !!localStorage.getItem("jwtToken") ||
      // !!localStorage.getItem("refreshToken")
      !!readCookie("jwtToken") ||
      !!readCookie("refreshToken")
    ) {
      const typeofdoc = docType === DOC_TYPE.COVER_LETTER ? TYPE_OF_DOC.COVER_LETTER : TYPE_OF_DOC.RESUME;
      if (!!docGUID) {
        if (docType === DOC_TYPE.COVER_LETTER) {
          navigate(pathRedirectSignIn.next);
        } else {
          navigate(pathRedirect.next);
        }
      } else {
        getUserDocsByTypeOfDocConnect(userGUID, typeofdoc).then((docResponse) => {
          if (!!docResponse && docResponse.documents.length > 0) {
            if (docType === DOC_TYPE.COVER_LETTER) {
              navigate(pathRedirectSignIn.cstm);
            } else {
              navigate(pathRedirect.cstm);
            }
          } else {
            if (docType === DOC_TYPE.COVER_LETTER) {
              navigate(pathRedirectSignIn.back);
            } else {
              navigate(pathRedirect.back);
            }
          }
        });
      }
    }
  };
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const onEmailChangeHandler = (e) => {
    setEmailError(false);
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  const onPwdChangeHandler = (e) => {
    setPasswordError(false);
    const {
      target: { value },
    } = e;
    setPwd(value);
  };
  const onPasswordKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) {
      onSignIn();
    }
  };

  const onSignIn = () => {
    const typeofdoc = docType === DOC_TYPE.COVER_LETTER ? TYPE_OF_DOC.COVER_LETTER : TYPE_OF_DOC.RESUME;
    setShowLoader("d-block");
    let uid = CreateUUID();
    setLoginError(false);
    const isValid = validateData();
    if (isValid) {
      const params = {
        username: emailId.trim(),
        password: pwd.trim(),
        tenantGroupId: getTenantGroupId(),
      };
      signInUserConnect(params)
        .then((response) => {
          if (!!response && response.isAccountLocked) {
            setPasswordError(true);
            setPasswordErrorMsg(`${i18next.t("sign_in.account_lock")}`);
            setdisableBtn(false);
            setShowLoader("d-none");
          } else {
            if (!!response && response.isMigratedUser) {
              setPasswordError(true);
              setPasswordErrorMsg(`${i18next.t("sign_in.migrated_user")}`);
              setdisableBtn(false);
              setShowLoader("d-none");
            } else if (!!response) {
              const { validUserGuid, isMigratedUser } = response;
              if (validUserGuid == EMPTY_GUID) {
                setPasswordError(true);
                setPasswordErrorMsg(`${i18next.t("sign_in.no_account")}`);
                setdisableBtn(false);
                setShowLoader("d-none");
              } else if (isMigratedUser) {
                setPasswordError(true);
                setPasswordErrorMsg(`${i18next.t("sign_in.migrated_user")}`);
                setdisableBtn(false);
                setShowLoader("d-none");
              } else {
                let data = {
                  Id: CreateUUID(),
                  UserId: validUserGuid,
                  VisitorId: readCookie("visitorGuid"),
                  VisitId: readCookie("visitGuid"),
                };
                eventServicePostConnect(data).then((serviceResponse) => {});
                if (!!docGUID) {
                  if (docType === DOC_TYPE.COVER_LETTER) {
                    navigate(pathRedirectSignIn.next);
                  } else {
                    navigate(pathRedirect.next);
                  }
                } else {
                  getUserDocsByTypeOfDocConnect(validUserGuid, typeofdoc).then((docResponse) => {
                    if (!!docResponse && docResponse.documents.length > 0) {
                      if (docType == DOC_TYPE.COVER_LETTER) {
                        navigate(pathRedirectSignIn.cstm);
                      } else {
                        navigate(pathRedirect.cstm);
                      }
                    } else {
                      if (docType == DOC_TYPE.COVER_LETTER) {
                        navigate(pathRedirectSignIn.back);
                      } else {
                        navigate(pathRedirect.back);
                      }
                    }

                    return docResponse;
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          setShowLoader("d-none");
          const {
            detail: { message },
          } = error;
          setLoginError(true);
          setLoginErrorMessage(message);
        });
    } else {
      setShowLoader("d-none");
      setdisableBtn(false);
    }
  };
  const validateData = () => {
    setEmailError(false);
    setPasswordError(false);
    let errors = 0;
    let emailValidator = validateEmail(emailId.trim());
    if (emailValidator.isValid === false) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg(emailValidator.errorMessage);

      setPasswordErrorMsg(`${i18next.t("sign_in.no_account")}`);
    }
    let passwordValidator = validatePassword(pwd);
    if (passwordValidator.isValid === false) {
      setPasswordError(true);
      // setPasswordErrorMsg(passwordValidator.errorMessage);
      setPasswordErrorMsg(`${i18next.t("sign_in.no_account")}`);
    }
    if (errors > 0) {
      ++errors;
      setdisableBtn(false);
      return false;
    } else {
      setdisableBtn(true);
      return true;
    }
  };
  const responseGoogle = (response, accessToken) => {
    const typeofdoc = docType === DOC_TYPE.COVER_LETTER ? TYPE_OF_DOC.COVER_LETTER : TYPE_OF_DOC.RESUME;
    if (!!response && !!accessToken) {
      setShowLoader("d-block");
      setEmailError(false);

      const { email, id } = response;
      const externalData = {
        createUser: {
          countryCD: readCookie("country"),
          currencyCD: readCookie("currency"),
          portalCD: getPortalCD(locale),
          parentUserGuid: "",
          firstName: "",
          lastName: "",
          company: "",
          title: "",
          email: email,
          password: "",
          tenantId: getTenantId(),
          tenantGroupId: getTenantGroupId(),
        },
        externalLoginInfo: {
          loginType: 2,
          providerKey: id, // "100732998798818",
        },
      };
      signUpExternalUserConnect(externalData)
        .then((response) => {
          if (!!response) {
            let eventData = {
              Id: CreateUUID(),
              UserId: response.UserGuid,
              VisitorId: readCookie("visitorGuid"),
              VisitId: readCookie("visitGuid"),
            };
            eventServicePostConnect(eventData).then((serviceResponse) => {});

            if (!!docGUID) {
              if (docType === DOC_TYPE.COVER_LETTER) {
                navigate(pathRedirectSignIn.next);
              } else {
                navigate(pathRedirect.next);
              }
            } else {
              getUserDocsByTypeOfDocConnect(response.UserGuid, typeofdoc).then((docResponse) => {
                if (!!docResponse && docResponse.documents.length > 0) {
                  if (docType == DOC_TYPE.COVER_LETTER) {
                    navigate(pathRedirectSignIn.cstm);
                  } else {
                    navigate(pathRedirect.cstm);
                  }
                } else {
                  if (docType == DOC_TYPE.COVER_LETTER) {
                    navigate(pathRedirectSignIn.back);
                  } else {
                    navigate(pathRedirect.back);
                  }
                }

                return docResponse;
              });
            }
          }
        })
        .catch((error) => {
          setLoginError(true);
          setLoginErrorMessage(error.message);
        });

      let emailTest = email;
    }
  };
  const responseFacebook = (response) => {
    const typeofdoc = docType === DOC_TYPE.COVER_LETTER ? TYPE_OF_DOC.COVER_LETTER : TYPE_OF_DOC.RESUME;
    if (!!response && response.status != "unknown") {
      setShowLoader("d-block");
      setEmailError(false);
      const { name, email, id } = response;
      const externalData = {
        createUser: {
          countryCD: readCookie("country"),
          currencyCD: readCookie("currency"),
          portalCD: getPortalCD(locale),
          parentUserGuid: "",
          firstName: name,
          lastName: "",
          company: "",
          title: "",
          email: email, //email
          password: "",
          tenantId: getTenantId(),
          tenantGroupId: getTenantGroupId(),
        },
        externalLoginInfo: {
          loginType: 1,
          providerKey: id, // "100732998798818",
        },
      };
      signUpExternalUserConnect(externalData)
        .then((response) => {
          if (!!response) {
            let eventData = {
              Id: CreateUUID(),
              UserId: response.UserGuid,
              VisitorId: readCookie("visitorGuid"),
              VisitId: readCookie("visitGuid"),
            };
            eventServicePostConnect(eventData).then((serviceResonse) => {});
            if (!!docGUID) {
              if (docType === DOC_TYPE.COVER_LETTER) {
                navigate(pathRedirectSignIn.next);
              } else {
                navigate(pathRedirect.next);
              }
            } else {
              getUserDocsByTypeOfDocConnect(response.UserGuid, typeofdoc).then((docResponse) => {
                if (!!docResponse && docResponse.documents.length > 0) {
                  if (docType == DOC_TYPE.COVER_LETTER) {
                    navigate(pathRedirectSignIn.cstm);
                  } else {
                    navigate(pathRedirect.cstm);
                  }
                } else {
                  if (docType == DOC_TYPE.COVER_LETTER) {
                    navigate(pathRedirectSignIn.back);
                  } else {
                    navigate(pathRedirect.back);
                  }
                }

                return docResponse;
              });
            }
          }
        })
        .catch((error) => {
          setLoginError(true);
          setLoginErrorMessage(error.message);
        });
    }
  };

  const pwdNavigate = () => {
    navigate(forgetpwdLink);
  };
  const signUpNavigate = () => {
    navigate(signUpLink);
  };
  const setIPConfig = (param) => {
    let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
    return ipConfigData[param];
  };
  const setDocType = (documentType) => {
    if (
      documentType &&
      (documentType.toUpperCase() == DOC_TYPE.RESUME || documentType.toUpperCase() == DOC_TYPE.COVER_LETTER)
    ) {
      setDocTypeConnect(documentType.toUpperCase());
    } else {
      if (docType == null || docType == undefined) {
        setDocTypeConnect(DOC_TYPE.RESUME);
      }
    }
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.sign_in")}</title>
      </Helmet>
      <ReactLoader showloader={showLoader} />
      <section className="main-content">
        <div className="container">
          <div className="form-wrap">
            <hgroup className="text-center">
              <h1 className="main-headline">{t("sign_in.headline")}</h1>
              <p className="sub-para">{t("sign_in.content")}</p>
            </hgroup>
            <div className="social-login">
              <div className="social-login--facebook">
                <FacebookLogin
                  appId={getFacebookClientId()}
                  fields="name,email,picture"
                  textButton={t("sign_in.signin_with_fb")}
                  callback={responseFacebook}
                  icon={<img src="/app/static/images/facebook-login.svg" className="facebook-login-button" />}
                />
              </div>
              <div className="social-login--google">
                <button type="button" className="google-login-button" onClick={googleLogin}>
                  <div>
                    <img src={process.env.PUBLIC_URL + "/static/images/google-normal.svg"} />
                  </div>
                  <span> {t("sign_in.signin_with_google")}</span>
                </button>
              </div>
            </div>
            <div className="or-container">
              <span></span> or <span></span>
            </div>

            <div className="form-container">
              <div className="form-text">
                {(passwordError || emailError) && (
                  <p
                    key="pswrdErr"
                    //role={alert}
                    className="alert error"
                    dangerouslySetInnerHTML={{
                      __html: passwordErrorMsg,
                    }}
                  />
                )}
              </div>
              <div className="form-group">
                <InputText
                  showLabel={false}
                  id="email"
                  text="Email Address"
                  onChange={onEmailChangeHandler}
                  value={emailId.trim()}
                  maxLength={100}
                  placeholder={t("sign_in.placeholder_email")}
                />
              </div>
              {/* <div className="form-text">
              {emailError && (
                <small key="pswrdErr" className="form-text text-danger">
                  {emailErrorMsg}
                </small>
              )}
            </div> */}
              <div className="form-group">
                <InputText
                  showLabel={false}
                  id="password"
                  text="Password"
                  type="password"
                  onChange={onPwdChangeHandler}
                  value={pwd}
                  onKeyPress={onPasswordKeyPress}
                  placeholder={t("sign_in.placeholder_password")}
                />
              </div>

              <div className="mb-3">
                <div className="fbn">
                  {" "}
                  <Button
                    isLoading={false}
                    disabled={disableBtn}
                    onClick={onSignIn}
                    classNames="btn btn-primary w-100 btn-form"
                    id="sign-in-btnbtn btn-link"
                    ctaText={t("sign_in.btn_cta")}
                  />
                </div>

                <div className="form-container--footer text-center">
                  <p className="">
                    <Button
                      isLoading={false}
                      disabled={disableBtn}
                      onClick={pwdNavigate}
                      id="forgot_password"
                      classNames="btn btn-link"
                      ctaText={t("sign_in.forgot_password")}
                    />
                  </p>
                  <p className="small ">
                    {t("sign_in.create_account")}
                    <Button
                      isLoading={false}
                      disabled={disableBtn}
                      onClick={signUpNavigate}
                      id="create_one_btn"
                      classNames="btn btn-link px-0"
                      ctaText={t("sign_in.create_signup")}
                    />
                  </p>
                </div>
              </div>

              {/* <div className="Toastify">
              <ToastContainer autoClose={2000} />
            </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignIn;
