import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { createParagraph } from "../../initialState";
import { APP_NAVIGATION, EMPTY_GUID, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useNavigate } from "react-router-dom";
import CoverLetterExperienceSlider from "./slider/slider";
import "./component.css";
import PathHelper from "../../utils/path-helper";
import Sidebar from "../../components/cover-letter-sidebar";
import { getJobTitleFaqs, getSkillFaqs } from "../../services/master-data";
import CoverLetterPreview from "../../components/cover-letter-preview";
import { checkEmpty, getPortalCD, readCookie } from "../../utils/helpers";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
import WritingTips from "../../components/writing-tips";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterExperience = ({
  funnelStep,
  experienceConnect,
  updateExperienceParagraph,
  cvltrExperience,
  SaveDocGUIDConnect,
  updateProgress,
  cvltrTemplatePreview,
  previewTemplateAction,
}) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { templateCd, docGUID, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep || {};
  let pathRedirect = PathHelper(APP_NAVIGATION.CVLTREXP);

  const previewContent =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;

  const content =
    !!cvltrExperience &&
    !!cvltrExperience.paragraph &&
    !!cvltrExperience.paragraph.paraContents &&
    !!cvltrExperience.paragraph.paraContents[0].content
      ? JSON.parse(cvltrExperience.paragraph.paraContents[0].content)
      : {};

  const jobTitleFaqs = getJobTitleFaqs(t);
  const { question, answers } = jobTitleFaqs.data;

  const previewExperience = !!previewContent && !checkEmpty(previewContent.Experience) ? previewContent.Experience : 0;

  const id =
    !!cvltrExperience && !!cvltrExperience.paragraph && !!cvltrExperience.paragraph.id
      ? cvltrExperience.paragraph.id
      : "";

  const paraGraphId = !!id ? id : EMPTY_GUID;

  const [experience, setExperience] = useState(
    previewExperience !== 0 ? previewExperience : !!content && !checkEmpty(content.Experience) ? content.Experience : 0
  );
  const [showLoader, setShowLoader] = useState("d-none");

  const buttonClickHandler = () => {
    if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
      updateExperience();
    } else {
      saveExperience();
    }
  };

  const saveExperience = () => {
    setShowLoader("d-flex");
    let head = {
      Experience: experience,
    };

    experienceConnect(docGUID, JSON.stringify(head), t("opener.experience"))
      .then((response) => {
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
        navigate(pathRedirect.next);
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  const updateExperience = () => {
    setShowLoader("d-flex");
    let head = {
      Experience: experience,
    };

    let experienceOrder =
      !!cvltrExperience &&
      !!cvltrExperience.paragraph &&
      !!cvltrExperience.paragraph.order &&
      cvltrExperience.paragraph.order != 0
        ? cvltrExperience.paragraph.order
        : SECTION_ORDER.OPNR;

    let data = createParagraph(
      docGUID,
      cvltrExperience.paragraph.id,
      cvltrExperience.paragraph.paraContents[0].id,
      JSON.stringify(head),
      experienceOrder,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      t("opener.experience")
    );

    updateExperienceParagraph(docGUID, paraGraphId, data)
      .then((response) => {
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
        navigate(pathRedirect.next);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const getExperience = (value) => {
    setExperience(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      Experience: value,
    });
  };

  useEffect(() => {
    trackRBEvents("cvExperienceView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    updateProgress(40);
  }, []);
  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={1} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      <ScrollToTop />
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="cvrLtr-header">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("opener.experience_title"),
                    }}
                  />
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("opener.experience_subtitle")),
                    }}
                  />
                </hgroup>
                <WritingTips section={SECTION_TYPE.CVLTS} question={question} answers={answers} />
              </div>
            </div>
            <div className="row iPad-size">
              <div className="col-md-12 col-lg-9">
                <div>
                  <div className="row">
                    <CoverLetterExperienceSlider getExperience={getExperience} Experience={experience} />
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    id="back-btn"
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("opener.back")}
                    onClick={backClickHandler}
                  />

                  <Button
                    isLoading={false}
                    disabled={false}
                    id="next-btn"
                    classNames="btn btn-primary btn-lg submit-on-enter float-end"
                    ctaText={t("opener.next")}
                    onClick={buttonClickHandler}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterExperience;
