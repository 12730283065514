import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";

const createExperienceParagraph = (docGUID, paragraph, paraName) => {
  return function (dispatch) {
    let para = createParagraph(
      docGUID,
      EMPTY_GUID,
      EMPTY_GUID,
      paragraph,
      SECTION_ORDER.OPNR,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      paraName
    );

    return DocBuilderService.createParagraph(docGUID, para).then(
      (paraResponse) => {
        dispatch(saveExperienceSuccess(paraResponse, paraResponse.id));
      }
    );
  };
};

const updateExperienceParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateExperienceSuccess(response));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveExperienceSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateExperienceSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

export { createExperienceParagraph, updateExperienceParagraph };
