import React, { useEffect } from "react";
import { useLocation, Redirect, useParams } from "react-router-dom";
import { createCookie, readCookie, deleteCookie, deletedomainCookie } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { useTranslation } from "react-i18next";
import { ProcessLogout } from "../../utils/process-logout";

const SignOut = ({ resetStoreConnect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { locale } = useParams();

  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };

  const LogoutRequestHandler = (ssologout, domain, nextLogout) => {
    //ssologout true/false
    //cd current domain
    //nextlg with ~ next logout
    let logoutPageUrl = "";
    let pdflogout = "https://pdfsimpli.com";
    let sslogout = "https://signsimpli.com";

    if (ssologout === "true") {
      ///////clean cookie...
      localStorage.clear();
      resetStoreConnect(true);
      deleteCookie("jwtToken");
      deleteCookie("refreshToken");
      deletedomainCookie("jwtToken");

      deleteCookie("ssoToken");
      deleteCookie("ssoauth");
      deleteCookie("legacyTicket");
      deleteCookie("ssopl");
      deleteCookie("docId");

      if (!!nextLogout) {
        if (nextLogout.includes("ss")) {
          // logout from SignSimpli
          nextLogout = nextLogout.replace("ss", "");
          if (nextLogout.Length <= 0) {
            nextLogout = "~";
          }
          logoutPageUrl = sslogout + "/account/logout";
          logoutPageUrl = logoutPageUrl + "?ssologout=true&cd=" + domain + "&nextlg=" + nextLogout;
          console.log(logoutPageUrl);
          window.location.assign(logoutPageUrl);
        } else if (nextLogout.includes("ps")) {
          // logout from pdfSimpli
          nextLogout = nextLogout.replace("ps", "");
          if (nextLogout.Length <= 0) {
            nextLogout = "~";
          }
          logoutPageUrl = pdflogout + "/account/logout";
          logoutPageUrl = logoutPageUrl + "?ssologout=true&cd=" + domain + "&nextlg=" + nextLogout;
          window.location.assign(logoutPageUrl);
        } else {
          // default logout from RB
          window.location.assign(decodeURIComponent(domain));
        }
      }
      window.location.assign(decodeURIComponent(domain));
    }
  };

  useEffect(() => {
    const ssologout = new URLSearchParams(location.search).get("ssologout");
    const domain = new URLSearchParams(location.search).get("cd");
    const nextLogout = new URLSearchParams(location.search).get("nextlg");

    ProcessLogout(ssologout, domain, nextLogout, locale);
    resetStoreConnect(true);
  }, []);

  return (
    <div>
      <div className="displaymobile" style={{marginLeft:"144px"}}>
        <img
          className="rb-logo height-37 my-4 ps-20"
          src={process.env.PUBLIC_URL + "/static/images/resumebuild-logo.svg"}
          width="117"
          alt="resumebuild"
          draggable="false"
        />
      </div>
      <section className="section main-content text-center">
        <div className="container intro">
          <div className="row">
            <div className="col-12 headline-group">
              <hgroup>
                <h2>{t("generic_error.please_wait")}</h2>
              </hgroup>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignOut;
