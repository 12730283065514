import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PathHelper from "../../../../utils/path-helper";
import { APP_NAVIGATION, SECTION_ORDER } from "../../../../utils/constants";
import "./cvltr-7.css";
import PersonaInfo from "../cl-06-standard/section/personaInfo";
import Info from "../cl-06-standard/section/info";
import DesiredCompany from "../cl-06-standard/section/desiredCompany";
import MetaContent from "../cl-06-standard/section/metaContent";
import Signature from "../cl-06-standard/section/signature";
import { findParaName, updateActions } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";

const Cl07Professional = ({
  data,
  coverLetterOnDashboard,
  toggleDeleteModal,
  componentRef,
  fontSize,
  fontFamily,
  setCurrentParaConnect,
  cvltrSignature,
  paragraphOrdering
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let dateFormate = t("opener.date_formate");
  const date = moment().format(dateFormate);

  let paraNamePathRedirect = PathHelper(APP_NAVIGATION.TPRSNLINFO, false);
  let desiredCompanyPathRedirect = PathHelper(APP_NAVIGATION.TRECPT, false);
  let openerPathRedirect = PathHelper(APP_NAVIGATION.TOPNRRECOM, false);
  let bodyPathRedirect = PathHelper(APP_NAVIGATION.TBODYRECOM, false);
  let closerPathRedirect = PathHelper(APP_NAVIGATION.TCLSR, false);
  let signaturePathRedirect = PathHelper(APP_NAVIGATION.TSIGN, false);

  const navigate = useNavigate();

  const paraNameEditHandler = () => {
    navigate(paraNamePathRedirect.next);
  };
  const desiredCompanyEditHandler = () => {
    navigate(desiredCompanyPathRedirect.next);
  };
  const openerEditHandler = () => {
    navigate(openerPathRedirect.next);
  };
  const bodyEditHandler = () => {
    navigate(bodyPathRedirect.next);
  };
  const CloserEditHandler = () => {
    navigate(closerPathRedirect.next);
  };
  const signatureEditHandler = () => {
    navigate(signaturePathRedirect.next);
  };

  const deleteSectionHandler = (type) => {
    toggleDeleteModal();
    setCurrentParaConnect(type, "");
  };

  let filterData = data.filter((x) => x.paraCD != "NONE");

  useEffect(() => {
    if (!!data && data.length > 0) {
      let filterData = data.filter((x) => x.paraCD != "NONE");
      updateCharacters(filterData);

      data.map((item) => {
        if (item.paraContents && item.paraContents.length > 0) {
          let paraName = item.paraName;
          updateActions(dispatch, t, paraName, item);
        }
      });
    }
  }, [JSON.stringify(data)]);

  const [characters, updateCharacters] = useState(filterData);
  const [coverLetterSection, setCoverLetterSection] = useState({
    personalInformation: "",
    signature: "",
    desiredCompany: "",
    body: "",
    opener: "",
    closer: "",
    metaContent: [
      {
        id: "body_77",
        body: "",
      },
      {
        id: "opener_78",
        opener: "",
      },
      {
        id: "closer_79",
        closer: "",
      },
    ],
  });

  const handleOnDragEnd = (result) => {
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let list = [];
    items.map((attr) =>
      list.push({
        order: attr.order,
        paraid: attr.id,
        paraName: attr.paraName
      })
    );
    list.map((currElement, index) => {
      switch (currElement.paraName) {
        case t("personal_info.para_name"):
          currElement.order = SECTION_ORDER.HEAD;
          break;

        case t("opener.desired_company"):
          currElement.order = SECTION_ORDER.DSRDCPNY;
          break;

        case t("signature.signature"):
          currElement.order = SECTION_ORDER.SIGN;
          break;

        default:
          currElement.order = index + 1;
          break;
      }
    });
    updateCharacters(items);
    paragraphOrdering(JSON.stringify(list));
  };

  useEffect(() => {
    let updateContent = { ...coverLetterSection };
    if (characters.length > 0) {
      characters.map((item) => {
        if (item.paraContents && item.paraContents.length > 0) {
          let paraName = item.paraName;
          switch (paraName) {
            case t("opener.desired_company"):
              updateContent.desiredCompany = JSON.parse(
                item.paraContents[0].content
              );
              break;

            case t("opener.opener_recommend"):
              updateContent.opener = JSON.parse(item.paraContents[0].content);
              updateContent.metaContent[0].opener = JSON.parse(
                item.paraContents[0].content
              );
              break;

            case t("opener.body_recommend"):
              updateContent.body = JSON.parse(item.paraContents[0].content);
              updateContent.metaContent[1].body = JSON.parse(
                item.paraContents[0].content
              );
              break;

            case t("opener.closer_recommend"):
              updateContent.closer = JSON.parse(item.paraContents[0].content);
              updateContent.metaContent[2].closer = JSON.parse(
                item.paraContents[0].content
              );
              break;

            case t("signature.signature"):
              updateContent.signature = JSON.parse(
                item.paraContents[0].content
              );
              updateContent.signature = JSON.parse(
                item.paraContents[0].content
              );
              break;

            case t("personal_info.para_name"):
              updateContent.personalInformation = JSON.parse(
                item.paraContents[0].content
              );
              break;

            default:
              break;
          }
        }
        setCoverLetterSection(updateContent);
      });
    }
  }, [characters]);

  return (
    <>
      <div className="cvltr-wrapper cvltr-preview" ref={componentRef}>
        <div className="cvltr-view">
          <div
            className="cvltr-view-layout cv1 double left cvltr-layout cvltr-7"
            style={{
              fontFamily: fontFamily,
              fontSize: fontSize,
            }}
          >
            <div className="tripple-column cvltr-view-layout-box">
              <div className="single-column">
                <PersonaInfo
                  provided={'provided'}
                  paraNameEditHandler={paraNameEditHandler}
                  coverLetterSection={coverLetterSection}
                />
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable
                    droppableId="characters"
                    isDropDisabled={coverLetterOnDashboard}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="cvltr-single cvltr-twoside">
                          <Info
                            provided={provided}
                            paraNameEditHandler={paraNameEditHandler}
                            coverLetterSection={coverLetterSection}
                          />
                          <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
                            {
                              findParaName(t("opener.desired_company"), characters) &&
                              <DesiredCompany
                                provided={provided}
                                desiredCompanyEditHandler={
                                  desiredCompanyEditHandler
                                }
                                deleteSectionHandler={deleteSectionHandler}
                                date={date}
                                coverLetterSection={coverLetterSection}
                              />}

                            {characters.length > 0 && characters.map(
                              (item, index) => {
                                if ([t("opener.opener_recommend"), t("opener.closer_recommend"), t("opener.body_recommend")].includes(item.paraName)) {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                      isDragDisabled={false}
                                    >
                                      {(provided) => {
                                        let dragClass = false;
                                        if (
                                          provided.draggableProps !== undefined
                                        ) {
                                          if (
                                            provided.draggableProps.style
                                              .position !== undefined
                                          ) {
                                            dragClass = true;
                                          }
                                        }
                                        return (
                                          <MetaContent
                                            provided={provided}
                                            dragClass={dragClass}
                                            openerEditHandler={openerEditHandler}
                                            bodyEditHandler={bodyEditHandler}
                                            CloserEditHandler={CloserEditHandler}
                                            deleteSectionHandler={
                                              deleteSectionHandler
                                            }
                                            item={item}
                                          />
                                        );
                                      }}
                                    </Draggable>
                                  );
                                }
                              }
                            )}
                            {provided.placeholder}
                            {
                              findParaName(t("signature.signature"), characters) &&
                              <Signature
                                provided={provided}
                                signatureEditHandler={signatureEditHandler}
                                deleteSectionHandler={deleteSectionHandler}
                                coverLetterSection={coverLetterSection}
                                type={"cover-letter"}
                                cvltrSignature={cvltrSignature}
                              />}
                          </div>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cl07Professional;
