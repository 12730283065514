import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterIntroPage from "./component";
import { fetchTemplates, saveChooseTemplate } from "./actions";
import { setTemplate } from "../funnel-step/actions";
import { handleSteps } from "../cover-letter-template/actions";

const mapStateToProps = ({
  chooseTemplate,
  funnelStep,
  cvltrTemplatePreview,
}) => ({
  chooseTemplate,
  funnelStep,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTemplatesConnect: fetchTemplates,
      setTemplateConnect: setTemplate,
      saveChooseTemplate: saveChooseTemplate,
      handleSteps: handleSteps,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterIntroPage);
