import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import SummaryTypes from "../summary/types";
import EducationTypes from "../education/types";
import ExperienceTypes from "../experience/types";
import CustomSectionTypes from "../custom-section/types";
import OtherSectionTypes from "../other-section/types";
import SkillTypes from "../skill/types";
import DocBuilderApi from "../../services/doc-builder-api";
import LanguageTypes from "../language/types";
import PersonalInfoTypes from "../personal-info/types";
import JobTitleTypes from "../cover-letter-job-title/types";
import DesiredCompanyTypes from "../cover-letter-desired-company/types";
import CoverLetterExperienceTypes from "../cover-letter-experience/types";
import CoverLetterSkillsTypes from "../cover-letter-skills/types";
import CoverLetterRecommendTypes from "../cover-letter-recommend/types";
import CoverLetterBodyRecommendTypes from "../cover-letter-body-recommend/types";
import CoverLetterCloserRecommendTypes from "../cover-letter-closer-recommend/types";
import CoverLetterSignatureTypes from "../cover-letter-signature/types";
import CoverLetterTemplatePreviewTypes from "../cover-letter-template/types";
import CoverLetterSidebarTypes from "../../components/cover-letter-sidebar/types";
import FunnelStepsTypes from "../funnel-step/types";
import { SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import UserServiceApi from "../../services/user-service-api";
import CoverLetterPersonalInfoTypes from "../cover-letter-personal-information/types";
import { updateActions, createCookie } from "../../utils/helpers";

const generatePDFPath = (docGuid, userGUID) => (dispatch) => {
  return DocBuilderService.generatePDFPath(docGuid, userGUID).then((response) => {
    appLogger.log("generate path end", response);
    return response;
  });
};

const getUserResume = (docGuid, userGUID, t) => (dispatch) => {
  return DocBuilderService.getDoc(docGuid)
    .then((response) => {
      if (response) {
        DocBuilderService.updateUserGuid(docGuid, userGUID);
        //appLogger.log("inside getUserResume success",response.data);
        let arraySort = response.paragraphs.sort((a, b) => a.order > b.order);
        dispatch(getUserResumeSuccess(response));
        let personalInfoSection = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.HEAD);
        if (!!personalInfoSection) {
          dispatch(PushDataToPersonalInfoSection(personalInfoSection));
        } else {
          dispatch({
            type: PersonalInfoTypes.RESET_PERSONAL_INFO,
          });
        }
        let summSEction = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.SUMM);
        if (!!summSEction) {
          dispatch(PushDataToSummarySection(summSEction));
        } else {
          dispatch({
            type: SummaryTypes.RESET_SUMMARY,
          });
        }
        let educSEction = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.EDUC);
        if (!!educSEction) {
          dispatch(PushDataToEducationSection(educSEction));
        } else {
          dispatch({
            type: EducationTypes.RESET_EDUCATION,
          });
        }
        let exprSection = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.EXPR);
        if (!!exprSection) {
          dispatch(PushDataToExperienceSection(exprSection));
        } else {
          dispatch({
            type: ExperienceTypes.RESET_EXPERIENCE,
          });
        }
        let skillsSection = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.SKLS);
        if (!!skillsSection) {
          dispatch(PushDataToSkillSection(skillsSection));
        } else {
          dispatch({
            type: SkillTypes.RESET_SKILLS,
          });
        }
        let languageSection = response.paragraphs.find((x) => x.paraCD == SECTION_TYPE.LANG);
        if (!!languageSection) {
          dispatch(PushDataToLanguageSection(languageSection));
        } else {
          dispatch({
            type: LanguageTypes.RESET_LANGUAGES,
          });
        }
        let otherSection = response.paragraphs.filter((x) => x.paraCD == SECTION_TYPE.OTHR);
        if (!!otherSection) {
          dispatch(PushDataToOtherSection(otherSection));
        } else {
          dispatch({
            type: OtherSectionTypes.RESET_OTHER_SECTION,
          });
        }
        let customSection = response.paragraphs.filter((x) => x.paraCD == SECTION_TYPE.CSTM);
        if (!!customSection) {
          dispatch(PushDataToCustomSection(customSection));
        } else {
          dispatch({
            type: CustomSectionTypes.RESET_CUSTOM_SECTION,
          });
        }

        // updateActions : update the cover-letter redux store value.
        response.paragraphs.forEach((item) => {
          updateActions(dispatch, t, item.paraName, item);
        });
      } else {
        dispatch(getUserResumeFailure(response));
      }
      return response;
    })
    .catch((error) => {
      appLogger.log("inside REview error", error);
      dispatch(getUserResumeFailure(error));
    });
};
const updateUserGuid = (docGuid, userGuid) => {
  DocBuilderService.updateUserGuid(docGuid, userGuid);
};
const PushDataToPersonalInfoSection = (data) => ({
  type: PersonalInfoTypes.SAVE_SUCCESS,
  data,
});
const PushDataToEducationSection = (data) => ({
  type: EducationTypes.FETCH_EDUCATION_SUCCESS,
  data,
});

const PushDataToExperienceSection = (data) => ({
  type: ExperienceTypes.FETCH_EXPERIENCE_SUCCESS,
  data,
});

const PushDataToSummarySection = (data) => ({
  type: SummaryTypes.FETCH_SUMMARY_SUCCESS,
  data,
});
const PushDataToLanguageSection = (data) => ({
  type: LanguageTypes.FETCH_LANGUAGES_SUCCESS,
  data,
});
const PushDataToSkillSection = (data) => ({
  type: SkillTypes.FETCH_SKILLS_SUCCESS,
  data,
});
const PushDataToOtherSection = (data) => ({
  type: OtherSectionTypes.FETCH_OTHER_SECTION_SUCCESS,
  data,
});
const PushDataToCustomSection = (data) => ({
  type: CustomSectionTypes.FETCH_CUSTOM_SUCCESS,
  data,
});

const getUserResumeSuccess = (resume) => ({
  type: Types.RESUME_GET_SUCCESS,
  resume,
});

const getUserResumeFailure = (error) => ({
  type: Types.RESUME_GET_FAILURE,
  error,
});

const resetStore = (resetUserGuid) => (dispatch) => {
  dispatch({
    type: Types.RESUME_RESET,
  });
  dispatch({
    type: LanguageTypes.RESET_LANGUAGES,
  });
  dispatch({
    type: SummaryTypes.RESET_SUMMARY,
  });
  dispatch({
    type: EducationTypes.RESET_EDUCATION,
  });
  dispatch({
    type: CustomSectionTypes.RESET_CUSTOM_SECTION,
  });
  dispatch({
    type: OtherSectionTypes.RESET_OTHER_SECTION,
  });
  dispatch({
    type: SkillTypes.RESET_SKILLS,
  });
  dispatch({
    type: PersonalInfoTypes.RESET_PERSONAL_INFO,
  });
  dispatch({
    type: FunnelStepsTypes.RESET_FUNNEL_STEPS,
    isUserGuid: resetUserGuid,
  });
  dispatch({
    type: ExperienceTypes.RESET_EXPERIENCE,
  });
  dispatch({
    type: JobTitleTypes.RESET_JOB_TITLE,
  });
  dispatch({
    type: DesiredCompanyTypes.RESET_DESIRED_COMPANY,
  });
  dispatch({
    type: CoverLetterExperienceTypes.RESET_EXPERIENCE,
  });
  dispatch({
    type: CoverLetterSkillsTypes.RESET_SKILLS,
  });
  dispatch({
    type: CoverLetterRecommendTypes.RESET_RECOMMEND,
  });
  dispatch({
    type: CoverLetterBodyRecommendTypes.RESET_BODY_RECOMMEND,
  });
  dispatch({
    type: CoverLetterCloserRecommendTypes.RESET_CLOSER_RECOMMEND,
  });
  dispatch({
    type: CoverLetterSignatureTypes.RESET_SIGNATURE,
  });
  dispatch({
    type: CoverLetterSidebarTypes.RESET_SIDEBAR_PROGRESS,
  });
  dispatch({
    type: CoverLetterTemplatePreviewTypes.RESET_PREVIEW,
  });
};

const updateParagraphOrdering = (docId, data) => {
  return function (dispatch) {
    return DocBuilderApi.updateParagraphOrdering(docId, JSON.stringify(data))
      .then((response) => {
        if (response) {
          appLogger.log("inside updateParagraphOrdering");
          dispatch(fetchUpdateOrderingSuccess(data));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(fetchUpdateOrderingFailure(error)));
  };
};
const updateTemplateCd = (docId, templateCd) => {
  return function (dispatch) {
    return DocBuilderApi.chooseTemplate(docId, templateCd)
      .then((response) => {
        if (response) {
          appLogger.log("inside updateTemplateCd...");
          createCookie("regenerateDoc", true);
          dispatch(updateThemeSuccess(templateCd));
        } else {
        }
        return response;
      })
      .catch((error) => {});
  };
};
const fetchUpdateOrderingSuccess = (data) => ({
  type: Types.UPDATE_ORDERING_GET_SUCCESS,
  data,
});
const fetchUpdateOrderingFailure = (data) => ({
  type: Types.UPDATE_ORDERING_GET_FAILURE,
  data,
});

const renameParagraphSuccess = (paraId, newParaname) => ({
  type: Types.RENAME_PARAGRAPH_SUCCESS,
  paraId,
  newParaname,
});
const renameParagraph = (docid, paraid, paraCD, newParaname) => {
  return function (dispatch) {
    return DocBuilderService.renameParagraph(docid, paraid, newParaname)
      .then((response) => {
        if (response) {
          dispatch(renameParagraphSuccess(paraid, newParaname));
          switch (paraCD) {
            case SECTION_TYPE.CSTM:
              dispatch({
                type: CustomSectionTypes.RENAME_CUSTOM_SECTION,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.EDUC:
              dispatch({
                type: EducationTypes.RENAME_EDUCATION,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.EXPR:
              dispatch({
                type: ExperienceTypes.RENAME_EXPERIENCE,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.LANG:
              dispatch({
                type: LanguageTypes.RENAME_LANGUAGE,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.SKLS:
              dispatch({
                type: SkillTypes.RENAME_SKILL,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.OTHR:
              dispatch({
                type: OtherSectionTypes.RENAME_OTHER_SECTION,
                paraid,
                newParaname,
              });
              break;
            case SECTION_TYPE.SUMM:
              dispatch({
                type: SummaryTypes.RENAME_SUMMARY,
                paraid,
                newParaname,
              });
              break;
          }
        }
        return response;
      })
      .catch((error) => dispatch(getUserResumeFailure(error)));
  };
};

const deleteParagraph = (docId, paraId, paraCD) => {
  return function (dispatch) {
    return DocBuilderApi.deleteParagraph(docId, paraId)
      .then((response) => {
        if (response) {
          appLogger.log("inside deleteParagraph");
          createCookie("regenerateDoc", true);
          dispatch(deleteSuccess(paraId));
          switch (paraCD) {
            case SECTION_TYPE.HEAD:
              dispatch({
                type: PersonalInfoTypes.RESET_PERSONAL_INFO,
              });
              break;
            case SECTION_TYPE.CSTM:
              dispatch({
                type: CustomSectionTypes.RESET_CUSTOM_SECTION,
              });
              break;
            case SECTION_TYPE.EDUC:
              dispatch({
                type: EducationTypes.RESET_EDUCATION,
              });
              break;
            case SECTION_TYPE.EXPR:
              dispatch({
                type: ExperienceTypes.RESET_EXPERIENCE,
              });
              break;
            case SECTION_TYPE.LANG:
              dispatch({
                type: LanguageTypes.RESET_LANGUAGES,
              });
              break;
            case SECTION_TYPE.SKLS:
              dispatch({
                type: SkillTypes.RESET_SKILLS,
              });
              break;
            case SECTION_TYPE.OTHR:
              dispatch({
                type: OtherSectionTypes.RESET_OTHER_SECTION,
              });
              break;
            case SECTION_TYPE.SUMM:
              dispatch({
                type: SummaryTypes.RESET_SUMMARY,
              });
              break;
          }
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(deleteFailure(error)));
  };
};

const deleteSuccess = (paraId) => ({
  type: Types.DELETE_SUCCESS,
  paraId,
});
const deleteFailure = (data) => ({
  type: Types.DELETE_FAILURE,
  data,
});

const updateDocStyle = (docId, data) => {
  return function (dispatch) {
    return DocBuilderApi.updateDocStyle(docId, data).then((response) => {
      if (response) {
        appLogger.log("inside update docstyle");
        createCookie("regenerateDoc", true);
        dispatch(updateDocStyleSuccess(data));
      } else {
      }
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const updateDocStyleSuccess = (data) => ({
  type: Types.UPDATE_DOCSTYLE_SUCCESS,
  data,
});

const updateThemeSuccess = (data) => ({
  type: Types.UPDATE_THEME_SUCCESS,
  data,
});

const getUserProfile = (userId) => {
  return function (dispatch) {
    return UserServiceApi.getUserProfile(userId).then((response) => {
      appLogger.log("inside getUserProfile");
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const updateDesiredCompanySuccess = (data) => ({
  type: DesiredCompanyTypes.UPDATE_SUCCESS,
  data,
});

const updateJobTitleSuccess = (data) => ({
  type: JobTitleTypes.UPDATE_SUCCESS,
  data,
});

const updateOpenerRecommendSuccess = (data) => ({
  type: CoverLetterRecommendTypes.UPDATE_SUCCESS,
  data,
});

const updateBodyRecommendSuccess = (data) => ({
  type: CoverLetterBodyRecommendTypes.UPDATE_SUCCESS,
  data,
});

const updateCloserRecommendSuccess = (data) => ({
  type: CoverLetterCloserRecommendTypes.UPDATE_SUCCESS,
  data,
});

const updateSignatureSuccess = (data) => ({
  type: CoverLetterSignatureTypes.UPDATE_SUCCESS,
  data,
});

const updatePersonalInfoSuccess = (data) => ({
  type: CoverLetterPersonalInfoTypes.UPDATE_SUCCESS,
  data,
});

const updateSkillsSuccess = (data) => ({
  type: CoverLetterSkillsTypes.UPDATE_SUCCESS,
  data,
});

export {
  getUserResume,
  resetStore,
  updateParagraphOrdering,
  fetchUpdateOrderingSuccess,
  fetchUpdateOrderingFailure,
  renameParagraph,
  deleteParagraph,
  deleteSuccess,
  updateDocStyle,
  updateUserGuid,
  getUserProfile,
  updateTemplateCd,
  generatePDFPath,
};
