import { createTypes } from "reduxsauce";

export default createTypes(
  `
    RESUME_GET_SUCCESS
    RESUME_GET_FAILURE
    RESUME_RESET
    UPDATE_ORDERING_GET_SUCCESS
    UPDATE_ORDERING_GET_FAILURE
    RENAME_PARAGRAPH_SUCCESS
    RENAME_PARAGRAPH_FAILURE
    DELETE_SUCCESS
    DELETE_FAILURE
    UPDATE_DOCSTYLE_SUCCESS
    UPDATE_DOCSTYLE_FAILURE
    UPDATE_THEME_SUCCESS
`,
  {
    prefix: "review/",
  }
);
