import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getUserServiceEndPoint } from "../services/api-constants";
import { readCookie } from "../utils/helpers";

class UserService {
  UserService(url) {
    return getUserServiceEndPoint(url);
  }
  createUser(data) {
    appLogger.log("inside createUser ");
    const createUserEndpoint = this.UserService(`${endPoints.userService.CREATE_USER}`);
    return apiService.post(createUserEndpoint, data);
  }
  createExternalUser(data) {
    appLogger.log("inside createExternalUser ");
    const createUserEndpoint = this.UserService(`${endPoints.userService.CREATE_EXTERNAL_USER}`);
    return apiService.post(createUserEndpoint, data);
  }
  loginUser(data) {
    appLogger.log("inside loginUser ");
    const loginUserEndpoint = this.UserService(`${endPoints.userService.CREATE_USER}`);
    return apiService.post(loginUserEndpoint, data);
  }
  updatePassword(data) {
    appLogger.log("inside updatePassword ");
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    const updateUserPwdEndpoint = this.UserService(`${endPoints.userService.UPDATE_PWD}`);
    return apiService.post(updateUserPwdEndpoint, data, headers);
  }
  resetPassword(data) {
    appLogger.log("inside resetPassword ");
    const resetPasswordEndpoint = this.UserService(`${endPoints.userService.RESET_PWD}`);
    return apiService.post(resetPasswordEndpoint, data);
  }
  forgotPassword(data) {
    appLogger.log("inside forgotPassword ");
    const forgotPasswordEndpoint = this.UserService(`${endPoints.userService.FORGOT_PWD}`);
    return apiService.patch(forgotPasswordEndpoint, data);
  }
  getUserProfile(userGuid) {
    appLogger.log("inside getIsPremiumByUserGuid");
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    const serviceEndpoint = this.UserService(
      `${endPoints.userService.GET_USER_PROFILE.replace(":userGuid", userGuid)}`
    );
    return apiService.get(serviceEndpoint, null, headers);
  }
  signinAsAdmin(data) {
    appLogger.log("inside signinAsAdmin ");
    const signinAsAdminEndpoint = this.UserService(`${endPoints.userService.SIGNIN_AS_ADMIN}`);
    return apiService.post(signinAsAdminEndpoint, data);
  }
  getUserByUserName(userName, tenantGroupId) {
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    appLogger.log("inside getUserByUserName ");
    const getUserByUserNameEndpoint = this.UserService(
      `${endPoints.userService.GET_USER_BY_USERNAME.replace(":username", userName).replace(
        ":tenantGroupId",
        tenantGroupId
      )}`
    );
    return apiService.get(getUserByUserNameEndpoint, null, headers);
  }
  getUserByEmail(email, tenantGroupId) {
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    appLogger.log("inside getUserByEmail ");
    const getUserByEmailEndpoint = this.UserService(
      `${endPoints.userService.GET_USER_BY_EMAIL.replace(":email", email).replace(":tenantGroupId", tenantGroupId)}`
    );
    return apiService.get(getUserByEmailEndpoint, null, headers);
  }
  getUserByUserGuid(userGuid, tenantGroupId) {
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    appLogger.log("inside getUserByUserGuid ");
    const getUserByUserGuidEndpoint = this.UserService(
      `${endPoints.userService.GET_USER_BY_GUID.replace(":guid", userGuid).replace(":tenantGroupId", tenantGroupId)}`
    );
    return apiService.get(getUserByUserGuidEndpoint, null, headers);
  }

  getUserObject() {
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    appLogger.log("inside getUserObject ");
    const getUserObkectEndpoint = this.UserService(`${endPoints.userService.GET_USER_OBJECT}`);
    return apiService.get(getUserObkectEndpoint, null, headers);
  }
  loginAttempt(data) {
    appLogger.log("inside loginAttempt ");
    const loginAttemptEndpoint = this.UserService(`${endPoints.userService.LOGIN_ATTEMPT}`);
    return apiService.post(loginAttemptEndpoint, data);
  }
  getCaptchaScore(greToken, portal) {
    appLogger.log("inside getCaptchaScore ");
    const getCaptchaScoreEndpoint = this.UserService(
      `${endPoints.userService.RECAPTCHA_SCORE.replace(":greToken", greToken).replace(":portal", portal)}`
    );
    return apiService.get(getCaptchaScoreEndpoint);
  }
  validateLgctExpiry(token) {
    // const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    // const headers = {
    //   Authorization: "Bearer" + " " + jwtToken,
    // };
    const getValidateLgctTokenEndPoint = this.UserService(
      `${endPoints.userService.VALIDATE_LGCT_EXPIRY.replace(":token", token)}`
    );
    return apiService.get(getValidateLgctTokenEndPoint);
  }
  saveUserAddress(userAddress) {
    const headers = { Authorization: "Bearer" + " " + readCookie("jwtToken") };
    const saveUserAddressEndPoint = this.UserService(`${endPoints.userService.SAVE_USER_ADDRESS}`);
    return apiService.post(saveUserAddressEndPoint, userAddress, headers);
  }
  getIp2Connect(ipAddress) {
    const saveUserAddressEndPoint = this.UserService(
      `${endPoints.userService.IP_2_CONNECT.replace(":ipaddress", ipAddress)}`
    );
    return apiService.get(saveUserAddressEndPoint);
  }
}

export default new UserService();
