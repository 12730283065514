import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SET_IMAGE_URL
    RESET_IMAGE_URL
`,
  {
    prefix: "IMAGE_UPLOAD_MODAL/",
  }
);
