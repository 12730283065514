import React, { useEffect, useState, useCallback } from "react";
import RBDropDown from "../shared/dropdown/component";
import Button from "../../components/shared/button";
import InputRange from "../../components/shared/input-range";
import { useTranslation } from "react-i18next";
import "./cover-letter-tools.scss";
import { getFontFamilyList } from "../../services/master-data";
import { getCoverLetterTemplateList } from "../../services/master-data";

const CoverLetterTools = ({
  callback,
  docReview,
  selectedTemplate,
  fontFamily,
}) => {
  const { t } = useTranslation();
  const [fontFamilyList, setfontFamilyList] = useState(getFontFamilyList(t));
  const [fontToolBoxfamily, setToolBoxFontFamily] = useState("");
  const [fontToolBoxsize, setToolBoxFontSize] = useState("");
  const [headingToolBoxfontsize, setToolBoxHeadingFontSize] = useState("");
  const [resetCoverLetterToolValue, setResetCoverLetterToolValue] = useState(
    getCoverLetterTemplateList().data
  );
  const minfontsize = 0.85;
  const maxfontsize = 1.25;
  const minHeadingSize = 1.25;
  const maxHeadingSize = 1.85;
  const sliderSizeStep = 0.05;
  const resetToolsHandler = () => {
    let resetToolValue = resetCoverLetterToolValue.find(
      (x) => x.templatecd === selectedTemplate
    );
    setToolBoxFontSize(resetToolValue.resetFontSize);
    setToolBoxHeadingFontSize(resetToolValue.resetHeadingFontSize);
    setToolBoxFontFamily(resetToolValue.resetFontFamily);
    let data = {
      fontFamily: resetToolValue.resetFontFamily,
      fontSize: resetToolValue.resetFontSize,
      headingFontSize: resetToolValue.resetHeadingFontSize,
    };
    callback("", "reset", data);
  };
  const changeFontHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setToolBoxFontFamily(value);
    callback(value, "fontFamily");
  };
  const fontSizeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setToolBoxFontSize(value);
    callback(value, "fontSize");
  };
  const fontHeadingSizeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setToolBoxHeadingFontSize(value);
    callback(value, "headingFontSize");
  };
  useEffect(() => {
    const stateDocStyle =
      !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle
        ? docReview.reviewDoc.docStyle
        : {};

    setToolBoxFontFamily(
      !!stateDocStyle && !!stateDocStyle.fontFamily
        ? stateDocStyle.fontFamily
        : ""
    );
    callback(
      !!stateDocStyle && !!stateDocStyle.fontFamily
        ? stateDocStyle.fontFamily
        : "",
      "fontFamily"
    );
    setToolBoxFontSize(
      !!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : ""
    );
    callback(
      !!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : "",
      "fontSize"
    );
    setToolBoxHeadingFontSize(
      !!stateDocStyle && !!stateDocStyle.headingSize
        ? stateDocStyle.headingSize
        : ""
    );
    callback(
      !!stateDocStyle && !!stateDocStyle.headingSize
        ? stateDocStyle.headingSize
        : "",
      "headingFontSize"
    );
  }, []);
  useEffect(() => {
    const stateDocStyle =
      !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle
        ? docReview.reviewDoc.docStyle
        : {};

    setToolBoxFontFamily(
      !!stateDocStyle && !!stateDocStyle.fontFamily
        ? stateDocStyle.fontFamily
        : ""
    );
    callback(
      !!stateDocStyle && !!stateDocStyle.fontFamily
        ? stateDocStyle.fontFamily
        : "",
      "fontFamily"
    );
    if (
      !!docReview &&
      !!docReview.reviewDoc &&
      docReview.reviewDoc.docStyle.fontFamily == null
    ) {
      let fontFamilyTemplate = resetCoverLetterToolValue.find(
        (x) => x.templatecd === docReview.reviewDoc?.templateCD
      )?.resetFontFamily;
      setToolBoxFontFamily(fontFamilyTemplate);
      callback(fontFamilyTemplate);
    }
  }, [fontFamily]);

  return (
    <>
      <div className="coverletter-sidebox__pill coverletter-sidebox__button-group pt-5">
        <Button
          id="coverletter-edit-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-rotate-left"></i>}
          onClick={resetToolsHandler}
          classNames="btn btn-muted reset-btn"
          ctaText={t("review.reset")}
        />
      </div>
      <div className="form-group select optional coverletter_font_family">
        <label className="select optional" htmlFor="coverletter_font_family">
          {t("review.font_family")}
        </label>
        <RBDropDown
          selectedValue={fontToolBoxfamily}
          id="select-font-family"
          options={fontFamilyList.data}
          onChange={changeFontHandler}
        />
      </div>
      <div className="form-group range optional coverletter_font_size coverletter-sidebox__pill">
        <label className="range optional" htmlFor="coverletter_font_size">
          {t("review.font_size")}
        </label>
        <div className="slidecontainer">
          <InputRange
            showLabel={false}
            id="coverletter_font_size"
            name="coverletter[font_size]"
            min={minfontsize}
            max={maxfontsize}
            step={sliderSizeStep}
            type="range"
            className="slider"
            onChange={fontSizeHandler}
            value={fontToolBoxsize}
          />
        </div>
      </div>
    </>
  );
};

export default CoverLetterTools;
