import React, { useState, useEffect, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import "./plans.style.scss";
import { useNavigate, useParams } from "react-router-dom";
import PathHelper from "../../utils/path-helper";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import { Tab, Nav } from "react-bootstrap";
import PlanFeatures from "../../components/plan-features";
import dompurify from "dompurify";
//import planLists from "./plans.data";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import ReactLoader from "../../components/shared/loader";
import { APP_NAVIGATION } from "../../utils/constants";
import Logo from "../../components/header/navbar/logo";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../components/scroll-to-top/component";
import { userGuid } from "../../utils/general-config";
import { trackRBEvents } from "../../utils/tracking-events";
import { getPortalCD, readCookie, setLocaleURL } from "../../utils/helpers";
import { DOC_TYPE, SECTION_TYPE } from "../../utils/constants";

const PlanDesktop = ({ onChangeValue, activeSku, sku, index, plans, localeVal }) => {
  const price = parseFloat(sku.unitPrice).toFixed(2);
  let title = sku.label;
  let dynamicTitle = sku.label;
  const skuGuid = sku.id;
  const { t } = useTranslation();
  const onClick = () => {
    onChangeValue(index);
  };
  const active = skuGuid === activeSku;
  return (
    <div className="col-md-4">
      <div className={`pricing-wrap--item ${active ? "box-active" : ""}`} onClick={onClick}>
        <div className="pricing-wrap--header">
          <input className="plan-radio" type="radio" value={skuGuid} checked={active} readOnly />
          <label htmlFor="" className="item-title active">
            {dynamicTitle}{" "}
            <span className="item-price">
              {sku.currencySymbol}
              {price}
            </span>
          </label>
        </div>
        <PlanFeatures section={sku.skuSubCategory} plansList={plans}></PlanFeatures>
      </div>
    </div>
  );
};
const PlanMobile = ({ onChangeValue, activeSku, sku, index, localeVal }) => {
  const price = sku.unitPrice;
  const title = sku.label;
  const skuGuid = sku.id;
  let dynamicTitle = sku.label;
  const { t } = useTranslation();
  const onClick = () => {
    onChangeValue(index);
  };
  const active = skuGuid === activeSku;
  return (
    <Nav.Item onClick={onClick}>
      <Nav.Link eventKey={sku.skuSubCategory} className={active ? "box-active active" : ""}>
        <input className="plan-radio" type="radio" value={skuGuid} checked={active} readOnly />
        <label htmlFor="" className="item-title">
          {dynamicTitle}{" "}
          <span className="item-price">
            {" "}
            {sku.currencySymbol}
            {price}
          </span>
        </label>
      </Nav.Link>
    </Nav.Item>
  );
};

const Plans = ({ plansConnect, isLogoDisabled, funnelStep }) => {
  const sanitizer = dompurify.sanitize;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const { userGUID, email, personalInfoName, docType } = funnelStep || {};
  let pathRedirect = PathHelper(APP_NAVIGATION.PLAN);
  const checkoutPage = () => {
    navigate(pathRedirect.next + "?s=" + activeSku);
  };
  const setIPConfig = (param) => {
    let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
    return ipConfigData[param];
  };
  const data = {
    planType: "_default",
    currencyCD: "",
    countryCD: "",
    device: null,
    locale: !!locale ? locale : "en",
  };
  const [activeSku, setActive] = useState("");
  const [planLists, setPlans] = useState([]);
  const [showLoader, setShowLoader] = useState("d-none");
  useEffect(() => {
    trackRBEvents("planView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    setShowLoader("d-block");
    let uGuid = !!userGUID ? userGUID : userGuid();
    if (!!uGuid && !!readCookie("jwtToken")) {
      plansConnect(uGuid, data).then((items) => {
        setPlans(items);
        setActive(items[1].id);
        setShowLoader("d-none");
      });
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
  }, []);

  function onChangeValue(index) {
    setActive(planLists[index].id);
  }
  function olarkHandler() {
    window.olarkExpand();
  }
  return (
    <>
      <style>
        {`
            .d-hotline.h-btn {
              display: none;
            }
            #fc_frame.fc-widget-small{
          display: none;
      }
          `}
      </style>
      <ScrollToTop />
      <Helmet>
        <title>{t("page_title.plans")}</title>
      </Helmet>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Logo disabled={isLogoDisabled} />
          </div>
        </nav>
      </header>
      {/* <Header isShow={"d-none"} /> */}
      <ReactLoader showloader={showLoader} />
      <section className="plans-section">
        <div className="container">
          <div className="alignment-container">
            <div className="headline-group row align-items-center">
              <hgroup className="col-md-12 col-lg-8 plan-heading">
                <h1>
                  <strong
                    className="desktop-heading"
                    dangerouslySetInnerHTML={
                      docType != DOC_TYPE.COVER_LETTER
                        ? { __html: t("plans.headline") }
                        : { __html: t("plans.cvltr_headline") }
                    }
                  />
                  <strong
                    className="mobile-heading"
                    dangerouslySetInnerHTML={{
                      __html: t("plans.headline_mobile"),
                    }}
                  />
                </h1>
                <p className="plan-sub-headline">{t("plans.sub_headline")}</p>
              </hgroup>
              <div className="col-md-12 col-lg-4 fbn">
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={checkoutPage}
                  classNames="btn btn-primary btn-primary--heavy btn-lg text-uppercase d-block plan-btn"
                  ctaText={t("plans.mainCTA")}
                />
              </div>
            </div>

            <div className="plan-desktop d-lg-block d-none">
              <div className="row pricing-wrap">
                {/* {console.log(planLists)} */}
                {planLists.map((planList, index) => (
                  <PlanDesktop
                    {...planList}
                    index={index}
                    key={index}
                    activeSku={activeSku}
                    onChangeValue={onChangeValue}
                    sku={planList}
                    plans={planLists}
                    localeVal={locale}
                  />
                ))}
              </div>
              <div className="row text-center">
                <div className="moneyback">
                  <strong>{t("plans.money_back")} </strong>
                  <span dangerouslySetInnerHTML={{ __html: t("plans.may_cancel") }} />
                  <button onClick={olarkHandler}> {t("plans.chat")} </button>
                  {" " + t("plans.chat_calling")}
                  <strong> +1-800-756-5147 </strong>
                </div>
              </div>
            </div>
            <div className="price-mobile d-md-block d-lg-none">
              <div className="row">
                <Tab.Container id="price-mobile">
                  <Nav variant="pills" className="flex-column">
                    {planLists.map((planList, index) => (
                      <PlanMobile
                        {...planList}
                        index={index}
                        sku={planList}
                        key={index}
                        onChangeValue={onChangeValue}
                        activeSku={activeSku}
                        localeVal={locale}
                      />
                    ))}
                  </Nav>
                  <Tab.Content>
                    {planLists.map((planList, index) => (
                      <Fragment key={planLists[index]}>
                        {/* {console.log("activeSku---", activeSku)}
                        {console.log("planList.id---", planList.id)} */}
                        {activeSku === planList.id ? (
                          <PlanFeatures plansList={planLists} section={planLists[index].skuSubCategory} />
                        ) : null}
                      </Fragment>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div className="review-section">
              <div className="container review-section--container">
                <div className="row">
                  <h4 className="review-heading">{t("reviews.heading")}</h4>
                </div>
                <div className="row">
                  <div className="col-lg-3 review-widget">
                    <img src="/app/static/images/trustpilot.png" />
                  </div>

                  <div className="col-lg-9 review">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      navigation={true}
                      loop={true}
                      mousewheel={true}
                      keyboard={true}
                      modules={[Navigation, Mousewheel, Keyboard]}
                      className="review--slider"
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <div className="review-stars tp-stars tp-stars--5">
                          <img src="/app/static/images/stars-icon.svg" alt="Reviews" />
                        </div>
                        <b className="review-header">{t("reviews.reviews_title_1")}</b>
                        <div className="review-text">{t("reviews.reviews_text_1")}</div>
                        <div className="review-name">{t("reviews.reviews_name_1")}</div>
                        <div className="review-date">{t("reviews.reviews_date_1")}</div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="review-stars tp-stars tp-stars--5">
                          <img src="/app/static/images/stars-icon.svg" alt="Reviews" />
                        </div>
                        <b className="review-header">{t("reviews.reviews_title_2")}</b>
                        <div className="review-text">{t("reviews.reviews_text_2")}</div>
                        <div className="review-name">{t("reviews.reviews_name_2")}</div>
                        <div className="review-date">{t("reviews.reviews_date_2")}</div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="review-stars tp-stars tp-stars--5">
                          <img src="/app/static/images/stars-icon.svg" alt="Reviews" />
                        </div>
                        <b className="review-header">{t("reviews.reviews_title_3")}</b>
                        <div className="review-text">{t("reviews.reviews_text_3")}</div>
                        <div className="review-name">{t("reviews.reviews_name_3")}</div>
                        <div className="review-date">{t("reviews.reviews_date_3")}</div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Plans;
