export const LOCAL_STORAGE_KEYS = {
  LOGIN_DATA: "loginData",
  TOKEN: "token",
};
export const DOC_TYPE = {
  RESUME: "RSME",
  COVER_LETTER: "CVLT",
};

export const SECTION_CODE = {
  NONE: 0,
  HEAD: 10,
  OPNR: 15,
  SUMM: 20,
  BODY: 25,
  EDUC: 30,
  GAPS: 35,
  EXPR: 40,
  CLSR: 45,
  ACCM: 50,
  SKLS: 60,
  CSTM: 70,
  LANG: 80,
  OTHR: 90,
};

export const SECTION_TYPE = {
  NONE: "NONE",
  HEAD: "HEAD",
  SUMM: "SUMM",
  EDUC: "EDUC",
  EXPR: "EXPR",
  ACCM: "ACCM",
  SKLS: "SKLS",
  LANG: "LANG",
  CSTM: "CSTM",
  OTHR: "OTHR",
  OPNR: "OPNR",
  BODY: "BODY",
  GAPS: "GAPS",
  CLSR: "CLSR",
  CVLTS: "CVLTS",
};

export const SECTION_ORDER = {
  // NONE: 0,
  // HEAD: 1,
  // SUMM: 2,
  // EDUC: 3,
  // EXPR: 4,
  // ACCM: 5,
  // SKLS: 6,
  // LANG: 7,
  // CSTM: 8,
  // OTHR: 9,
  HEAD: 0,
  SUMM: 1,
  EXPR: 2,
  EDUC: 3,
  SKLS: 4,
  ACCM: 5,
  LANG: 6,
  CSTM: 7,
  OTHR: 8,
  OPNR: 10,
  BODY: 11,
  GAPS: 12,
  CLSR: 13,
  DSRDCPNY: 1, // SUMM:1 is used in resume build and DSRDCPNY:1 used in cover-letter. that's not conflict
  SIGN: 50,
  NONE: 100,
};

export const DOC_STAGE = {
  NONE: "NONE",
  HEAD: "HEAD",
  SUMM: "SUMM",
  EDUC: "EDUC",
  EXPR: "EXPR",
  ACCM: "ACCM",
  SKLS: "SKLS",
  CSTM: "CSTM",
  OTHR: "OTHR",
  OPNR: "OPNR",
  BODY: "BODY",
  GAPS: "GAPS",
  CLSR: "CLSR",
};

export const APP_NAVIGATION = {
  NONE: "NONE",
  ITRO: "ITRO", // Introduction
  TEMP: "TEMP", // choose template
  HEAD: "HEAD", // personal Ingo
  EDUT: "EDUT", // Education tips
  EDUC: "EDUC", // education
  EDUL: "EDUL", // education List
  EXPT: "EXPT", // experience tips
  EXPR: "EXPR", // Experience
  EXPL: "EXPL", // Experience List
  SKLT: "SKLT", // Skills Tips
  SKLS: "SKLS", // Skills
  SUMT: "SUMT", // Summary Tips
  SUMM: "SUMM", // Summary
  LANG: "LANG", // Languages
  CSTM: "CSTM", // Custom
  REVW: "REVW", // Review
  OTHR: "OTHR", // Other
  SINI: "SINI", // Sing In
  SINU: "SINU", // Sing Up
  SIOT: "SIOT", // Sing Out
  CONF: "CONF",
  SPFC: "SPFC", //Add Other section from review
  PLAN: "PLAN", // plans
  OTSP: "OTSP", //Other custom section
  TSKLS: "TSKLS", //Skill edit
  TCUS: "TCUS", //custum edit
  TEDU: "TEDU", //Education edit
  TEXP: "TEXP", //Experience edit
  THED: "THED", //Header edit
  TLAN: "TLAN", //Language edit
  TPER: "TPER", //Personal-ifno edit
  TSUM: "TSUM", //summary edit
  TPRSNLINFO: "TPRSNLINFO", //personal information edit
  TDSRDCPNY: "TDSRDCPNY", //opener first page
  TOPNRRECOM: "TOPNRRECOM", //opener recommend edit
  TBODYRECOM: "TBODYRECOM", //body recommend edit
  TSIGN: "TSIGN", //sign edit
  TRECPT: "TRECPT", //desired company edit
  TBODY: "TBODY", // body edit
  TCLSR: "TCLSR", // closer edit
  RSMS: "RSMS", //Resumes
  STNG: "STNG", //Settings
  ADLG: "ADLG", //Admin Login
  CASU: "CASU", //Cancel Subscription
  CHPG: "CHPG", //Checkout-page
  PRVY: "PRVY", //Privacy policy
  CNTC: "CNTC", //Contact us
  TUSE: "TUSE", //Terms of use
  RHOM: "RHOM", //Root home
  CVLTS: "CVLTS", //Cover letters
  OPNR: "OPNR", // CVLTR Opener
  BODY: "BODY", // CVLTR Body
  GAPS: "GAPS", // CVLTR Gaps
  CLSR: "CLSR", // CVLTR closer
  HEDR: "HEDR", // CVLTR choose template
  SOPNR: "SOPNR", // CVLTR sidebar Opener
  SBODY: "SBODY", // CVLTR sidebar Body
  SCLSR: "SCLSR", // CVLTR sidebar closer
  SHEDR: "SHEDR", // CVLTR sidebar choose template
  PRSNLINFO: "PRSNLINFO", // CVLTR personal-information,
  JOBTITLE: "JOBTITLE", // CVLTR job title,
  DSRDCPNY: "DSRDCPNY", // CVLTR desired company,
  CVLTREXP: "CVLTREXP", // CVLTR experience,
  OPNRRECOM: "OPNRRECOM", // CVLTR opener recommend,
  SKILS: "SKILS", // CVLTR body skills,
  BODYRECOM: "BODYRECOM", // CVLTR body recommend,
  CLSRRECOM: "CLSRRECOM", // CVLTR closer recommend,
  SIGN: "SIGN", // CVLTR closer signature,
  CVLTRPRVY: "CVLTRPRVY", //CVLTR Privacy policy
  CVLTRCNTC: "CVLTRCNTC", //CVLTR Contact us
  CVLTRTUSE: "COLTUSE", //CVLTR Terms of use
  CVLTRSINI: "CVLTRSINI", //CVLTR Sign IN
  CVLTRSINU: "CVLTRSINU", //CVLTR Sign Up
  OPNR: "OPNR", // CVLTR Opener
  BODY: "BODY", // CVLTR Body
  GAPS: "GAPS", // CVLTR Gaps
  CLSR: "CLSR", // CVLTR closer
  CVLINTRO: "CVLINTRO", // CVLTR Intro
  UPDRES: "UPDRES", //Upload Resume,
  SELDOC: "SELDOC", //Selected Document
};

export const RatingValues = {
  Basic: 1,
  Proficient: 2,
  Conversational: 3,
  Fluent: 4,
  "Native speaker": 5,
};
export const getRatingWidth = (rating) => {
  const currentValue = rating;
  if (!currentValue) return 0;
  return `${(currentValue * 100) / 5}%`;
};
export const GetRatingCircle = ({ rating }) => {
  const currentValue = rating;
  if (!currentValue) return <></>;
  const filled = Array.from({ length: currentValue }, (_, i) => i + 1);
  const empty = Array.from({ length: 5 - currentValue }, (_, i) => i + 1);
  return (
    <>
      {filled.map((item) => (
        <span className="active" key={"filled" + item} />
      ))}
      {empty.map((item) => (
        <span key={"empty" + item} />
      ))}
    </>
  );
};

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const DEFAULT_USER_GUID = "a0000000-0000-0000-0000-00000000000a";

export const AFFILIATE_STORAGE_NAME = "account";
export const AFFILIATE_KEYWORD_NAME = "keyword";
export const AFFILIATE_SOURCE_NAME = "regi";
export const AFFILIATE_SOURCE_VALUE = "intro";

export const PORTAL_CD = {
  WSRB: "WSRB",
  WSRBES: "WSRBES",
  WSRBIT: "WSRBIT",
  WSRBPT: "WSRBPT",
  WSRBFR: "WSRBFR",
  WSRBAR: "WSRBAR",
};
export const FOOTER_LINKS = {
  CONTACT_US: "contact-us",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_CONDITIONS: "terms-of-use",
};

export const SKILL_SELECTION_LIMIT = 3;
export const SIGNATURE_TYPE = {
  TYPE: "TYPE",
  DRAW: "DRAW",
  UPLOAD: "UPLOAD",
};
export const TYPE_OF_DOC = {
  RESUME: 10,
  COVER_LETTER: 20,
};
export const ImageType = {
  SIGNATURE: 0,
  PROFILE: 1,
};
export const ImageFormat = {
  none: 0,
  jpg: 1,
  jpeg: 2,
  png: 3,
  gif: 4,
  svg: 5,
  webp: 6,
};
export const EVENT_CATEGORY = {
  PAYMENT: "PAYMENT",
};
export const EVENT_SUBCATEGORY = {
  PAYMENT_FAILED: "PAYMENT_FAILED",
};
export const EVENT_TOPIC = {
  PAYMENT: "PAYMENT",
};
export const NewPricingFlowQueryString = {
  SKUCATEGORY: "skuc",
  CURRENCY: "currency",
  SKUGUID: "skuid",
  USERS: "users",
};
export const photoTemplates = ["Distinct", "Expressive", "Bold"];

export const DomainName = {
  PDFSIMPLI: "pdfsimpli",
  RESUMEBUILD: "resumebuild",
  LEGALSIMPLI: "legalsimpli",
  SIGNSIMPLI: "signsimpli",
  QUICKWERX: "quickwerx",
};
