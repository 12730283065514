import React, { useState, useEffect, useRef } from "react";
import "./resumes.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import ResumesList from "../../components/resumes-list";
import ResumesAction from "../../components/resumes-action";
import { EMPTY_GUID } from "../../utils/constants";
import RenameSectionModal from "../../components/shared/rename-section-modal";
import DownloadModal from "../../components/shared/download-modal";
import DownloadLoaderPopup from "../../components/shared/download-loader-popup";
import { useParams } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import i18next from "i18next";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, TYPE_OF_DOC } from "../../utils/constants";
import ReviewStyle from "../../components/cvs/review-style/common-style/component";
import { Helmet } from "react-helmet";
import { downloadUserResume } from "../../initialState";
import { getTrustPilotURL, readCookie, navigateToPaymentMS } from "../../utils/helpers";
import { DOC_TYPE } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import FinalStepTypes from "../funnel-step/types";
import { useDispatch } from "react-redux";
import ReactLoader from "../../components/shared/loader/component";
import { isPaymentMSEnable } from "../../utils/general-config";
import { PerquisiteBountyEnable } from "../../utils/helpers";
const Resumes = ({
  funnelStep,
  setDocGuidConnect,
  renameResumeConnect,
  getUserProfileConnect,
  setDocTypeConnect,
  setTemplateConnect,
  getAbridgedUserDocsConnect,
  getDocumentConnect,
}) => {
  const localtion = useLocation();
  const dispatch = useDispatch();
  let pathRedirect = PathHelper(APP_NAVIGATION.RSMS);
  const { t } = useTranslation();
  const [userDocs, setUserDocs] = useState("");
  const [resumeOptions, setResumeOption] = useState("");
  const [selectedResume, setSelectedResume] = useState();
  const { docGUID, userGUID, parentUserGuid, email, docType } = funnelStep || {};
  const [renameResumeModal, setRenameResumeModal] = useState(false);
  const [renameVal, setRenameVal] = useState("");
  const [renameDocGuid, setRenameDocGuid] = useState(EMPTY_GUID);
  const [downloadResumeModal, setDownloadResumeModal] = useState(false);
  const [downloadLoaderModal, setDownloadLoaderModal] = useState(false);
  const [selectedHtml, setselectedHtml] = useState("");
  const componentRef = useRef(null);
  const [signedInUser, setSignedInUser] = useState(false);
  const [signedInUserMsg, setSignedInUserMsg] = useState(false);
  const [showLoader, setShowLoader] = useState("d-none");

  const [downloadModalText, setDownloadModalText] = useState(t("review.resume_is_being_built"));
  const [downloadModalTitle, setDownloadModalTitle] = useState(t("review.review_download_btn"));
  const [downloadModalBtnText, setDownloadModalBtnText] = useState(t("review.review_download_btn"));
  const [isDownloadError, setIsDownloadError] = useState(false);
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  let isRequestForPrint = false;
  let docGuid = docGUID;
  let userGuid = userGUID;
  if (!docGuid) {
    docGuid = readCookie("docId");
  }
  if (!userGuid && !!readCookie("userguid")) {
    userGuid = readCookie("userguid");
  }
  useEffect(() => {
    setShowLoader("d-flex");
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    if (!!userGuid && !!readCookie("jwtToken")) {
      getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.RESUME)
        .then((response) => {
          if (!!response && response.documents.length > 0) {
            var resumeOptions = response.documents
              .sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn))
              .map((item) => {
                return {
                  label: item["name"],
                  value: item["id"],
                };
              });
            const docIdFromQueryString = new URLSearchParams(window.location.search).get("rsmid");
            var latestDocGuid =
              !!docGuid && docGuid != EMPTY_GUID && !!docType && docType != DOC_TYPE.COVER_LETTER
                ? docGuid
                : resumeOptions[0].value;
            latestDocGuid = !!docIdFromQueryString ? docIdFromQueryString : latestDocGuid;
            setDocTypeConnect(DOC_TYPE.RESUME);
            setUserDocs(response.documents);
            setDocGuidConnect(latestDocGuid);
            setResumeOption(resumeOptions);
            if (!!resumeOptions) {
              if (response.documents.find((x) => x.id == latestDocGuid) !== undefined) {
                setTemplateConnect(response.documents.find((x) => x.id == latestDocGuid).templateCD);
                getDocumentConnect(latestDocGuid).then((response) => {
                  if (!!response) {
                    setSelectedResume(response);
                    setShowLoader("d-none");
                  }
                });
              } else {
                setTemplateConnect(response.documents.find((x) => x.id == resumeOptions[0].value).templateCd);
                getDocumentConnect(resumeOptions[0].value).then((response) => {
                  if (!!response) {
                    setSelectedResume(response);
                    setShowLoader("d-none");
                  }
                });
              }
            }
          } else {
            setTemplateConnect("");
            navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
          }
        })
        .catch((ex) => {
          setTemplateConnect("");
          navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
        });
    } else {
      setTemplateConnect("");
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }

    //setSignedInUser(true);
    //setSignedInUser(`${i18next.t("sign_in.no_account")}`);
    setSignedInUserMsg("Welcome! You have signed up successfully.");
    setselectedHtml(componentRef.current);
    getTrustPilotURL(email, userGuid);
  }, []);

  const toggleRenameModal = () => {
    if (selectedResume != undefined) {
      setRenameVal(selectedResume.name);
      setRenameDocGuid(selectedResume.id);
    }
    setRenameResumeModal((q) => !q);
  };
  const toggleDownloadModal = () => {
    getUserProfileConnect(userGuid)
      .then((response) => {
        if (
          (!!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
          (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
        ) {
          setDownloadResumeModal((d) => !d);
          setDownloadModalText(t("review.resume_is_being_built"));
          setDownloadModalTitle(t("review.review_download_btn"));
          setIsDownloadError(false);
        } else {
          // navigate(pathRedirect.back); // back to plan page
          if (!navigateToPaymentMS(docGuid, locale)) {
            navigate(pathRedirect.back);
          }
        }
      })
      .catch((err) => {
        const { detail } = err;
      });
  };
  const toggleDownloadLoaderModal = () => {
    setDownloadLoaderModal((l) => !l);
  };
  const downloadErrorMessage = (isError) => {
    if (isError === "error") {
      setIsDownloadError(true);
      setDownloadModalText(t("review.download_error_message"));
    } else {
      setIsDownloadError(false);
      setDownloadModalText(t("review.resume_is_being_built"));
    }
  };
  const callBackResume = (fieldValue, fieldId) => {
    if (!!userDocs && userDocs.length > 0) {
      //
      getDocumentConnect(fieldValue).then((response) => {
        if (!!response) {
          let resumeData = response;
          if (resumeData != undefined && resumeData != null && resumeData != "") {
            setSelectedResume(resumeData);
            setTemplateConnect(resumeData.templateCD);
          } else {
            setTemplateConnect("");
          }
          setDocGuidConnect(fieldValue);
          setselectedHtml(componentRef.current);
        }
      });
      //
    } else {
      setTemplateConnect("");
    }
  };
  const callBackDuplicate = (prevDocLabel, newDocId) => {
    setDocGuidConnect(newDocId);
    getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.RESUME).then((response) => {
      if (!!response) {
        setUserDocs(response.documents);
        var resumeOptions = response.documents.map((item) => {
          return {
            label: item["name"],
            value: item["id"],
          };
        });
        setResumeOption(resumeOptions);
        if (!!resumeOptions) {
          getDocumentConnect(newDocId).then((response) => {
            if (!!response) {
              setSelectedResume(response);
            }
          });
        }
      }
    });
  };
  const callBackDelete = (deletedDocId) => {
    const resetToFirstDocId = !!userDocs ? userDocs[0].id : EMPTY_GUID;
    getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.RESUME).then((response) => {
      if (!!response) {
        setUserDocs(response.documents);
        if (response.documents.length > 0) {
          setDocGuidConnect(resetToFirstDocId);
        } else {
          setDocGuidConnect(EMPTY_GUID);
          setSelectedResume(undefined);
        }
        var resumeOptions = response.documents.map((item) => {
          return {
            label: item["name"],
            value: item["id"],
          };
        });
        setResumeOption(resumeOptions);
        if (!!resumeOptions) {
          getDocumentConnect(response.documents[0].id).then((response) => {
            if (!!response) {
              setSelectedResume(response);
            }
          });
        }
      }
    });
  };
  const callBackResumeName = (renameResume, renameDocGuid) => {
    console.log(renameResume);
    renameResumeConnect(renameDocGuid, renameResume).then((response) => {
      getAbridgedUserDocsConnect(userGuid, TYPE_OF_DOC.RESUME).then((response) => {
        if (!!response) {
          setUserDocs(response.documents);
          var resumeOptions = response.documents.map((item) => {
            return {
              label: item["name"],
              value: item["id"],
            };
          });
          setResumeOption(resumeOptions);
          if (!!resumeOptions) {
            getDocumentConnect(renameDocGuid).then((response) => {
              if (!!response) {
                setSelectedResume(response);
              }
            });
          }
        }
      });
    });
    setRenameResumeModal(false);
  };
  const callBackDownloadResume = (formatName) => {
    console.log(formatName);
    setDownloadResumeModal(false);
  };
  const printResumeCallBack = (fieldValue) => {
    getUserProfileConnect(userGuid)
      .then((response) => {
        if (
          (!!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
          (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
        ) {
          isRequestForPrint = true;
          toggleDownloadLoaderModal();
          setDownloadModalText(t("review.resume_is_being_print"));
          setDownloadModalTitle(t("review.review_print_btn"));
          downloadUserResume(
            docGuid,
            "1",
            "ASPOSE",
            toggleDownloadLoaderModal,
            downloadErrorMessage,
            isRequestForPrint
          );
          setIsDownloadError(false);
          // var divToPrint = selectedHtml;
          // if (divToPrint == null) {
          //   divToPrint = componentRef.current;
          // }
          // var htmlToPrint =
          //   "" +
          //   '<style type="text/css">' +
          //   ReviewStyle(selectedResume.templateCD) +
          //   "</style>";
          // htmlToPrint += divToPrint.outerHTML;
          // let newWin = window.open("");
          // // newWin.document.write("<h3 align='center'>Print Page</h3>");
          // newWin.document.write(htmlToPrint);
          // newWin.print();
          // newWin.close();
        } else {
          if (!navigateToPaymentMS(docGuid, locale)) {
            navigate(pathRedirect.back);
          }
          //navigate(pathRedirect.back); // back to plan page
        }
      })
      .catch((err) => {
        const { detail } = err;
      });
  };
  // const navigateToPaymentMS = () => {
  //   // navigate(pathRedirect.next);
  //   let isPmsEnable = readCookie("pms");
  //   if ((!!isPmsEnable && isPmsEnable === "true") || isPaymentMSEnable() === true) {
  //     createCookie("docId", docGuid, 1);
  //     window.location.assign(!!locale && locale !== "en" ? `/app/${locale}/billing` : "/app/billing");
  //   } else {
  //     navigate(pathRedirect.next);
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{t("page_title.resume")}</title>
      </Helmet>
      <Header />
      <section className="section main-content templates">
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <h1> {t("resumes.headline")}</h1>
            </div>
            <div className="box_content">
              <div className="row dashboard-item">
                <div className="col-md-12 col-lg-4">
                  <div className="form-text">
                    {signedInUser && (
                      <p
                        key="welcomMsg"
                        //role={alert}
                        className="alert error"
                        dangerouslySetInnerHTML={{
                          __html: signedInUserMsg,
                        }}
                      />
                    )}
                  </div>
                  {resumeOptions != undefined ? (
                    <ResumesList
                      callback={callBackResume}
                      resumeOptions={resumeOptions}
                      selectedResume={selectedResume}
                      componentRef={componentRef}
                    />
                  ) : null}
                </div>
                <div className="col-md-12 col-lg-4 col-md-offset-1 dashboard-item__info">
                  {selectedResume != undefined ? (
                    <ResumesAction
                      numberOfResumes={userDocs.length}
                      selectedResume={selectedResume}
                      callbackduplicate={callBackDuplicate}
                      callbackdelete={callBackDelete}
                      toggleRenameModal={toggleRenameModal}
                      downloadResumeModal={toggleDownloadModal}
                      printResumeCallBack={printResumeCallBack}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RenameSectionModal
        message=""
        show={renameResumeModal}
        toggleShow={toggleRenameModal}
        setRenameVal={setRenameVal}
        renameDocGuid={renameDocGuid}
        renameVal={renameVal}
        header={t("resumes.edit_resume")}
        callback={callBackResumeName}
      />
      <DownloadModal
        message=""
        show={downloadResumeModal}
        toggleShow={toggleDownloadModal}
        toggleDownloadLoaderModal={toggleDownloadLoaderModal}
        inputLabel={t("resumes.resumes_choose_format")}
        header={t("resumes.resumes_download_heading")}
        callback={callBackDownloadResume}
        docGUID={docGuid}
        downloadErrorMessage={downloadErrorMessage}
        isRequestForPrint={isRequestForPrint}
        downloadBtnTxt={downloadModalBtnText}
      />
      <DownloadLoaderPopup
        message=""
        toggleShow={toggleDownloadLoaderModal}
        show={downloadLoaderModal}
        inputLabel={downloadModalText}
        header={downloadModalTitle}
        docGUID={docGuid}
        isDownloadError={isDownloadError}
      />
      <ReactLoader showloader={showLoader} />

      <Footer />
    </>
  );
};

export default Resumes;
