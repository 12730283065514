import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterOpenerPage from "./component";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterOpenerPage);
