import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv2ExecutiveCSS = css`
  .resume-layout.cv2 {
    padding: 0;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv2 .sub-headline {
    display: block;
  }
  .resume-layout.cv2 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv2
    .single-column
    div[data-rbd-draggable-context-id]:nth-child(odd) {
    background-color: #f5f5f5;
  }
  .resume-layout.cv2 .single-column .resume__section {
    padding: 2em 3em;
    margin-bottom: 0;
  }
  .resume-layout.cv2 .resume__section .controls-menu {
    top: 0;
    right: 0;
  }
  .resume-layout.cv2 .resume__section.resume__heading {
    text-align: center;
  }
  .resume-layout.cv2 .resume__section.resume__heading .info-item {
    margin-bottom: 2px;
  }
  .resume-layout.cv2 .resume__section.resume__heading .info-item .info-label {
    display: none;
  }
  .resume-layout.cv2 .resume__section.resume__heading .info-item .info-text {
    width: 100%;
  }
  .resume-layout.cv2 .resume__heading .info-main {
    display: grid;
  }
  .resume-layout.cv2 .resume__heading .info-main .info-item {
    margin-bottom: 3px;
  }
  .resume-layout.cv2 .resume__heading .info-main .info-item:first-child {
    order: 2;
  }
  .resume-layout.cv2 .resume__heading .info-main .info-item:nth-child(2) {
    order: 3;
  }
  .resume-layout.cv2 .resume__heading .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv2 .resume__section {
    padding-top: 2rem;
  }
  .resume-layout.cv2 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    font-weight: normal;
    text-transform: capitalize;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv2 .resume__section .sub-headline h2 {
    font-size: 1.29em;
    margin-bottom: 1em;
    font-weight: normal;
    font-style: italic;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv2 .resume__section h1,
  .resume-layout.cv2 .resume__section h3 {
    font-size: 1.2em;
    margin-bottom: 0.25em;
    font-weight: normal;
    font-style: italic;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv2 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv2 .xp-item .xp-date {
    font-weight: normal;
    text-align: left;
    font-style: italic;
  }
  .resume-layout.cv2 .xp-item .xp-job {
    font-weight: 600;
  }
  .resume-layout.cv2 .xp-item .xp-job .xp-detail {
    font-weight: 400;
  }
  .resume-layout.cv2 .resume__section .resume__controls {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .resume-layout.cv2 .resume__section .resume__move {
    top: 2px;
    left: 0;
  }
  .resume-layout.cv2 .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv2 .wrap .left-col .extra .extra-info {
    width: 30%;
  }
  .resume-layout.cv2 .wrap .left-col .extra .extra-details {
    width: 70%;
    padding-left: 1.5rem;
  }
`;

export const Cv2ExecutiveStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv2ExecutiveCSS}
`;
