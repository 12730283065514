import React from "react";
import { useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../utils/constants";
import ResumeMap from "../../components/resume-map";

const TipsContainer = ({ section, resumemap }) => {
  const { t } = useTranslation();
  const getExperienceSection = () => {
    return (
      <div className="builder-info-tips">
        <ul className="writing-tips">
          <li>{t("experience_tips.exp_list_first")}</li>
          <li>{t("experience_tips.exp_list_second")}</li>
          <li>{t("experience_tips.exp_list_third")}</li>
          <li>{t("experience_tips.exp_list_forth")}</li>
        </ul>
      </div>
    );
  };
  const getEducationSection = () => {
    return (
      <div className="builder-info-tips">
        <ul className="writing-tips">
          <li>{t("education_tips.edu_list_first")}</li>
          <li>{t("education_tips.edu_list_second")}</li>
          <li>{t("education_tips.edu_list_third")}</li>
          <li>{t("education_tips.edu_list_forth")}</li>
        </ul>
      </div>
    );
  };
  const getSkillSection = () => {
    return (
      <div className="builder-info-tips">
        <ul className="writing-tips">
          <li>{t("skill_tips.skill_list_first")}</li>
          <li>{t("skill_tips.skill_list_second")}</li>
          <li>{t("skill_tips.skill_list_third")}</li>
          <li>{t("skill_tips.skill_list_forth")}</li>
        </ul>
      </div>
    );
  };
  const getSummarySection = () => {
    return (
      <div className="builder-info-tips">
        <ul className="writing-tips">
          <li>{t("summary_tips.sum_list_first")}</li>
          <li>{t("summary_tips.sum_list_second")}</li>
          <li>{t("summary_tips.sum_list_third")}</li>
          <li>{t("summary_tips.sum_list_forth")}</li>
        </ul>
      </div>
    );
  };
  const GetSectionTips = (section) => {
    switch (section) {
      case SECTION_TYPE.EXPR:
        return getExperienceSection();
        break;
      case SECTION_TYPE.EDUC:
        return getEducationSection();
        break;
      case SECTION_TYPE.SKLS:
        return getSkillSection();
        break;
      case SECTION_TYPE.SUMM:
        return getSummarySection();
        break;
    }
  };
  return (
    <>
      <div className="col-md-12 col-lg-8 builder-info-tips">
        {GetSectionTips(section)}
      </div>
      <div
        className={
          "col-md-3 col-lg-4 tips-info-image resume-navigator--" + resumemap
        }
      >
        <ResumeMap />
      </div>
    </>
  );
};

export default TipsContainer;
