import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import { downloadUserResume } from "../../initialState";

const saveIntroduction = (data) => {
  return function (dispatch) {
    return DocBuilderService.createDoc(data)
      .then((response) => {
        if (response) {
          appLogger.log("inside saveIntroduction ");
          dispatch(saveIntroSuccess(response.data.docGuid));
        } else {
          dispatch(saveIntroFailure(response.data));
        }
        return response;
      })
      .catch((error) => dispatch(saveIntroFailure(error)));
  };
};

const updateIntroduction = (docid, paraid, data) => (dispatch) => {
  return DocBuilderService.updateParagraph(docid, paraid, data)
    .then((response) => {
      if (response) {
        dispatch(updateIntroSuccess(response));
      } else {
        dispatch(updateIntroFailure(response));
      }
      return response;
    })
    .catch((error) => dispatch(updateIntroFailure(error)));
};
const saveIntroSuccess = (docGuid) => ({
  type: Types.SAVE_SUCCESS,
  docGuid,
});

const saveIntroFailure = (error) => ({
  type: Types.SAVE_FAILED,
  error,
});
const updateIntroSuccess = (successResponse) => ({
  type: Types.UPDATE_SUCCESS,
  successResponse,
});

const updateIntroFailure = (error) => ({
  type: Types.UPDATE_FAILED,
  error,
});

export { saveIntroduction, updateIntroduction };
