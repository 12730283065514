import Types from "./types";

const setCurrentStep = (currentStep) => ({
  type: Types.SET_CURRENTSTEP,
  currentStep: currentStep,
});

const setCurrentPara = (currentPara, currentParaId) => ({
  type: Types.SET_CURRENT_PARA,
  currentPara: currentPara,
  currentParaId: currentParaId,
});

const setTemplate = (templateCd) => ({
  type: Types.SET_TEMPLATE,
  templateCd,
});

const resetfunnelSteps = () => ({
  type: Types.RESET_FUNNEL_STEPS,
});

const setDocGUID = (docGUID) => ({
  type: Types.SET_DOCGUID,
  docGUID,
});
const setUserInformation = (userGUID, email, parentUserGuid) => ({
  type: Types.SET_USERINFO,
  userGUID,
  email,
  parentUserGuid,
});
const setName = (personalInfoName) => ({
  type: Types.SET_NAME,
  personalInfoName,
});

const setQueryStringInfo = (queryStringsInfo) => ({
  type: Types.SET_QUERYSTRING_INFO,
  queryStringsInfo,
});
const resetFolderQueryStringInfo = () => ({
  type: Types.RESET_FOLDER_QUERYSTRING,
});
const setDocType = (docType) => ({
  type: Types.SET_DOC_TYPE,
  docType,
});

export {
  setCurrentStep,
  setTemplate,
  resetfunnelSteps,
  setDocGUID,
  setUserInformation,
  setCurrentPara,
  setName,
  setQueryStringInfo,
  resetFolderQueryStringInfo,
  setDocType,
};
