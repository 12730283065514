import events from "./events";

const sendEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...data });
};

const trackCustomEvents = ({ rbEvents, category }, extras) => {
  const data = { ...extras, ...rbEvents[category] };
  sendEvent(data);
};

const trackRBEvents = (category, extras = {}) => {
  trackCustomEvents(
    {
      rbEvents: events,
      category,
    },
    extras
  );
};

export { trackRBEvents };
