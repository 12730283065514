import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_SIGNATURE
    SAVE_TAB_VALUE
`,
  {
    prefix: "closer/signature",
  }
);
