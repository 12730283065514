import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv5OriginalCSS = css`
  .resume-layout.cv5 {
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv5 .resume-header {
    padding-bottom: 3rem;
  }
  .resume-layout.cv5 .resume__summary ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .resume-layout.cv5 .resume-aside {
    order: 2;
    width: 30%;
    padding-left: 2em;
    padding-top: 0;
    border-left: 1px solid #cbcbcb;
  }
  .resume-layout.cv5 .resume-aside .resume__content {
    text-align: center;
  }
  .resume-layout.cv5 .resume-aside .resume__heading .info-main {
    display: block;
  }
  .resume-layout.cv5 .resume-aside .info-headline {
    display: none;
  }
  .resume-layout.cv5 .resume-aside .resume__heading .personal-info {
    display: block;
  }
  .resume-layout.cv5 .resume-aside .sub-headline {
    margin-bottom: 1rem;
  }
  .resume-layout.cv5 .resume-main {
    order: 1;
    width: 70%;
    padding-top: 0;
    padding-right: 2rem;
  }
  .resume-layout.cv5 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv5 .resume__section .sub-headline h2 {
    text-transform: capitalize;
    font-size: 1.43em;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 900;
    display: inline-block;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv5 .resume__section h1,
  .resume-layout.cv5 .resume__section h3 {
    text-transform: capitalize;
    font-size: 1.25em;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 600;
    display: inline-block;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv5 .sub-headline .svg-icon {
    display: inline-block;
  }
  .resume-layout.cv5 .resume__heading .info-main {
    display: none;
  }
  .resume-layout.cv5 .resume__heading .personal-info {
    display: none;
  }
  .resume-layout.cv5 .resume-aside .sub-headline .svg-icon {
    display: none;
  }
  .resume-layout.cv5 .personal-info {
    display: block;
  }
  .resume-layout.cv5 .info-item {
    display: block;
  }
  .resume-layout.cv5 .info-item .info-label,
  .resume-layout.cv5 .info-item .info-text,
  .resume-layout.cv5 .wrap .left-col,
  .resume-layout.cv5 .wrap .extra-info,
  .resume-layout.cv5 .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv5 .extra {
    display: block;
  }
  .resume-layout.cv5 .extra .extra-details .progress-starts,
  .resume-layout.cv5 .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv5 .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv5
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #83c99d;
  }
  .resume-layout.cv5 .xp-item {
    display: block;
  }
  .resume-layout.cv5 .xp-item .xp-date,
  .resume-layout.cv5 .xp-item .xp-job {
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv5 .resume-main .resume__section {
    padding-left: 2.5em;
    padding-bottom: 1em;
    border-left: 0.07em solid #cbcbcb;
  }
  .resume-layout.cv5 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv5 .resume__summary ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .resume-layout.cv5 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv5 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv5 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv5 .resume__education .edu-hide {
    display: block;
  }
`;

export const Cv5OriginalStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv5OriginalCSS}
`;
