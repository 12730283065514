import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
  isLoading: true,
  skillsList: [],
  selectedSkillsState: [],
  newSkillListState: [],
};

export const fetchTemplateSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    skillsList: [...data],
    isLoading: false,
  };
};
export const saveSkillsInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const updateSkillsInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const fetchSelectedSkills = (state = INITIAL_STATE, data) => {
  return { ...state, selectedSkillsState: data };
};
export const fetchNewSkillList = (state = INITIAL_STATE, data) => {
  return { ...state, newSkillListState: data };
};
export const resetSkillsInfo = (state = INITIAL_STATE) => {
  return {
    ...state,
    paragraph: "",
    isLoading: true,
    selectedSkillsState: [],
    newSkillListState: [],
  };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveSkillsInfo,
  [Types.UPDATE_SUCCESS]: updateSkillsInfo,
  [Types.RESET_SKILLS]: resetSkillsInfo,
  [Types.FETCH_SKILLS_SUCCESS]: fetchTemplateSuccess,
  [Types.SELECTED_SKILLS]: fetchSelectedSkills,
  [Types.NEW_SKILL_LIST]: fetchNewSkillList,
};

export default createReducer(INITIAL_STATE, HANDLERS);
