import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";

const createRecommendParagraph = (docGUID, paragraph, paraName, isSelected) => {
  return function (dispatch) {
    let para = createParagraph(
      docGUID,
      EMPTY_GUID,
      EMPTY_GUID,
      paragraph,
      SECTION_ORDER.OPNR,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      paraName
    );

    return DocBuilderService.createParagraph(docGUID, para).then(
      (paraResponse) => {
        dispatch(saveRecommendSuccess(paraResponse, paraResponse.id));
        dispatch(saveSelected(isSelected));
      }
    );
  };
};

const updateRecommendParagraph = (docid, paraid, data, isSelected) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateOpenerRecommendSuccess(response));
          dispatch(saveSelected(isSelected));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveRecommendSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateOpenerRecommendSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

export const saveSelected = (data) => ({
  type: Types.SAVE_SELECTED,
  data,
});

export const resetOpenerRecommend = () => ({
  type: Types.RESET_RECOMMEND,
});

export { createRecommendParagraph, updateRecommendParagraph };
