import React from "react";
import { useTranslation } from "react-i18next";
import "./page-not-found.scss";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import Logo from "../../components/header/navbar/logo";

const PageNotFound = () => {
  const { t } = useTranslation();
  let locale = localStorage.getItem("i18nextLng");
  let pathRedirectHome =
    !!locale && locale != "en"
      ? ROUTE_CONSTANT.ROOT_HOME_LOCALE.replace(":locale", locale)
      : ROUTE_CONSTANT.ROOT_HOME;
  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Logo />
          </div>
        </nav>
      </header>
      <Helmet>
        <title>{t("page_title.page_not_found")}</title>
      </Helmet>
      <section className="section main-content text-center">
        <div id="wrapper" className="container page-not-found">
          <div className="not-found-img">
            <img src="/app/static/images/not-found.svg" />
          </div>
          <div id="info" className="not-found-content">
            <h1>{t("page_not_found.heading")}</h1>
            <p>{t("page_not_found.page_description")}</p>
            <div className="text-center d-block pb-5">
              <a href={pathRedirectHome} className="btn btn-primary">
                {t("page_not_found.btn_text")}
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PageNotFound;
