import Types from "./types";
import DocBuilderApi from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import DocBuilderService from "../../services/doc-builder-api";

const createEducationParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderApi.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchEducationSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(fetchEducationFailure(error)));
  };
};
const updateEducationParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateEducationSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateEducationFailure(error)));
  };
};
const deleteEducation = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateEducationSuccess(data));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateEducationFailure(error)));
  };
};

const fetchEducationSuccess = (data) => ({
  type: Types.FETCH_EDUCATION_SUCCESS,
  data,
});

const fetchEducationFailure = (error) => ({
  type: Types.FETCH_EDUCATION_FAILURE,
  error,
});
const updateEducationSuccess = (data) => ({
  type: Types.UPDATE_EDU_SUCCESS,
  data,
});

const updateEducationFailure = (error) => ({
  type: Types.UPDATE_EDU_FAILED,
  error,
});
const resetEducation = () => ({
  type: Types.RESET_EDUCATION,
});

export {
  createEducationParagraph,
  updateEducationParagraph,
  resetEducation,
  deleteEducation,
};
