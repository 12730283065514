import * as baseValidations from "./baseValidator";
import i18next from "i18next";

export function validatePassword(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_in.error_required_message")}`,
    };
  } else if (!baseValidations.minLength(data, 6)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_in.password_length_error_message")}`,
    };
  } else {
    return { isValid: true, errorMessage: "" };
  }
}

export function validateEmail(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_in.error_required_message")}`,
    };
  } else if (!baseValidations.IsValidEmail(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_in.invalid_email_error_message")}`,
    };
  } else {
    return {
      isValid: true,
      errorMessage: `${i18next.t("sign_in.error_required_message")}`,
    };
  }
}

export function validateSignupPassword(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_up.error_required_pswrdmessage")}`,
    };
  } else if (!baseValidations.minLength(data, 6)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_up.password_length_error_message")}`,
    };
  } else {
    return { isValid: true, errorMessage: "" };
  }
}

export function validateSignupEmail(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_up.error_required_message")}`,
    };
  } else if (!baseValidations.IsValidEmail(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_up.invalid_email_error_message")}`,
    };
  } else {
    return {
      isValid: true,
      errorMessage: `${i18next.t("sign_up.error_required_messages")}`,
    };
  }
}

export function RequiredValidation(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("sign_in.error_required_message")}`,
    };
  } else {
    return { isValid: true, errorMessage: "" };
  }
}

export function ValidateNumber(data) {
  if (
    data !== undefined &&
    data !== "" &&
    data.trim() !== "" &&
    data.trim().length > 0
  ) {
    if (isNaN(+data)) {
      return {
        isValid: false,
        errorMessage: `${i18next.t("generic_error.error_invalid_message")}`,
      };
    } else {
      return { isValid: true, errorMessage: "" };
    }
  } else {
    return { isValid: true, errorMessage: "" };
  }
}
export function validateFirstName(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("generic_error.error_required_message")}`,
    };
  } else {
    return { isValid: true, errorMessage: "" };
  }
}

export function validateForgetPassword(data) {
  if (!baseValidations.IsPresent(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("forgot_password.email_required")}`,
    };
  } else if (!baseValidations.IsValidEmail(data)) {
    return {
      isValid: false,
      errorMessage: `${i18next.t("forgot_password.email_error")}`,
    };
  } else {
    return {
      isValid: true,
      errorMessage: `${i18next.t("forgot_password.email_required")}`,
    };
  }
}
