import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import "./trustpilot.css";

const TrustBox = ({ show, toggleShow, closeTrustPilotModalHandler, trustPilotURL }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        // onHide={toggleShow}
        className="modal-info-section"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <button type="button" className="btn-close" onClick={closeTrustPilotModalHandler}></button>
        </Modal.Header>
        <Modal.Body>
          <iframe id="trustPilotIframe" name="onsiteReview" src={trustPilotURL}></iframe>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default TrustBox;
