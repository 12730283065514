import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv19ProCSS = css`
  .resume-layout.cv19 {
    font-family: "Open Sans TTF", sans-serif;
  }
  // .resume-layout.cv19 .sub-headline {
  //   display: block;
  // }
  .resume-layout.cv19 .resume__section.resume__heading .resume-photo-content {
    padding-top: 55px;
  }
  .resume-layout.cv19 .resume__section.resume__heading h1.info-headline {
    color: #fff;
    font-size: 2.86em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0;
    background: #1494c2;
    margin-bottom: 3.57em;
    position: absolute;
    padding-left: 2.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    left: -4.85rem;
    top: -4.85rem;
    right: -4.85rem;
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv19 .resume__section .sub-headline h2 {
    font-weight: bold;
    font-size: 1.43em;
    line-height: 1;
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    border-bottom: 0.05em solid #e6e6e6;
    font-family: "Open Sans TTF", sans-serif;
    border-bottom: none !important;
  }
  .resume-layout.cv19 .resume__section h1,
  .resume-layout.cv19 .resume__section h3 {
    font-weight: bold;
    font-size: 1.15em;
    line-height: 1;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    border-bottom: 0.05em solid #e6e6e6;
    font-family: "Open Sans TTF", sans-serif;
    border-bottom: none !important;
  }
  .resume-layout.cv19 .xp-item .xp-job {
    font-weight: 600;
  }
  .resume-layout.cv19 .xp-detail {
    font-weight: 400;
  }
  .resume-layout.cv19 .resume__section.resume__heading .resume__controls {
    top: -3.85rem;
  }

  .resume-layout.cv19 .resume__skills .resume__text .wrap {
    width: 50%;
    float: left;
    display: inherit;
    border-right: 1px solid #c1c0c5;
  }
  .resume-layout.cv19 .resume__skills .resume__text .wrap:nth-child(even) {
    border-right: none;
    padding-left: 5rem;
  }
  .resume-layout.cv19 .resume__skills .resume__text .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv19 .resume__skills .resume__text:after {
    content: "";
    clear: both;
    display: table;
  }
  .resume-layout.cv19 .extra .extra-details {
    width: 120px;
  }
  .resume-layout.cv19 .resume__heading .resume__controls {
    z-index: 999;
    left: -4.85rem;
    top: -4.85rem !important;
    right: -4.85rem;
  }
  .resume-layout.cv19 .resume__heading .controls-menu {
    top: -4.85rem !important;
    right: -4.85rem !important;
  }
  @media (max-width: 767px) {
    .resume-layout.cv19 .resume__skills .resume__text .wrap:nth-child(even) {
      padding-left: 2.55rem;
    }
    .resume-layout.cv19 .extra .extra-details {
      width: 7em;
      margin-right: 5px;
      padding-right: 0;
    }
    .resume-layout.cv19 .info-headline {
      left: -1.75rem;
      right: -1.75rem;
      top: -2rem;
    }
    .resume-layout.cv19 .sub-headline {
      padding-top: 2rem;
    }
    .resume-layout.cv19 .resume__heading .resume__controls {
      left: -1.75rem;
      top: -1.95rem !important;
      right: -1.75rem;
    }
    .resume-layout.cv19 .resume__heading .controls-menu {
      top: -1.95rem !important;
      right: -1.75rem !important;
    }
    .resume-layout.cv19 .resume__summary .sub-headline {
      padding-top: 4rem;
    }
    .resume-layout-box .resume__section .resume__controls {
      min-height: 106px;
    }
  }
  @media (max-width: 1200px) and (min-width: 768px) {
    .resume-layout.cv19 .info-headline {
      left: -4rem;
      right: -4rem;
    }
    .resume-layout.cv19 .resume__heading .resume__controls {
      left: -4rem;
      right: -4rem;
    }
    .resume-layout.cv19 .resume__heading .controls-menu {
      right: -4rem !important;
    }
    .resume-layout.cv19 .resume__heading .resume__content {
      padding-top: 2rem;
    }
  }
  @media (min-width: 417px) and (max-width: 767px) {
    .resume-layout.cv19 .resume__summary .sub-headline {
      padding-top: 2rem;
      margin-left: 2.5rem;
    }
    .resume-layout-box .resume__section .resume__controls {
      min-height: 88px;
    }
  }
`;

export const Cv19ProStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv19ProCSS}
`;
