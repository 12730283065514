import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import { endPoints, getAuthServiceEndPoint } from "../services/api-constants";

class AuthService {
  AuthService(url) {
    return getAuthServiceEndPoint(url);
  }

  AuthenticateUser(data) {
    appLogger.log("inside AuthenticateUser ");
    const authEndpoint = this.AuthService(`${endPoints.authService.USER_AUTH}`);
    return apiService.post(authEndpoint, data);
  }
  LoginUser(data) {
    appLogger.log("inside AuthenticateUser ");
    const authEndpoint = this.AuthService(
      `${endPoints.authService.LOGIN_USER_AUTH}`
    );
    return apiService.post(authEndpoint, data);
  }
  GetServiceTokenForUnAuthUser(data) {
    const unAuthEndpoint = this.AuthService(
      `${endPoints.authService.UNAUTH_USER}`
    );
    return apiService.post(unAuthEndpoint, data);
  }
}

export default new AuthService();
