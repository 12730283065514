import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getUserToken } from "./token-utils";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import moment from "moment";
import { encode } from "base-64";

const instance = axios.create();

instance.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  const accessToken = ""; //getUserToken(); TODO
  let correlationAppContext = [
    {
      ApplicationName: "",
      CNameOrAzureAppName: window.location.href,
      TimeStamp: moment(),
    },
  ];
  config.headers["correlation-app-context"] = encode(JSON.stringify(correlationAppContext));
  config.headers["Content-Type"] = "application/json";
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    const expiresAt = !!localStorage.getItem("expiresAt") ? moment(localStorage.getItem("expiresAt")) : "";
    const currentDate = moment(new Date().toUTCString());
    if (expiresAt != "" && expiresAt.isBefore(currentDate)) {
      const locale = !!localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "";
      if (locale != "") {
        window.location.href = ROUTE_CONSTANT.SESSION_EXPIRE_LOCALE.replace(":locale", locale);
      } else {
        window.location.href = ROUTE_CONSTANT.SESSION_EXPIRE;
      }
    } else if (response.data) {
      return response.data;
    }
  }
  /////// !!!! NOTE :: Below code has a major imapct on all api responses where we'll get an error... !!!!
  // ,
  // (error) => {
  //   if (!!error && !!error.response && !!error.config) {
  //     const errorObject = {
  //       data: error.response.data,
  //       statusCode: error.response.status,
  //       statusText: error.response.statusText,
  //     };
  //     throw errorObject;
  //     // rg4js("send", {
  //     //   error: "Error log in calling the API",
  //     //   customData: {
  //     //     action: error.config.url,
  //     //     apiData: error.config.data,
  //     //     error: errorObject,
  //     //   },
  //     // });
  //   }
  // }
);

const apiService = {
  get: (url, data, headers, withCredentials) => {
    const config = { params: {}, headers: {} };
    if (!url) {
      throw Error("endPoint is required params");
    } else {
      if (data) {
        config.params = data;
      }
      if (headers) {
        config.headers = headers;
      }
      if (withCredentials) {
        config.withCredentials = true;
      }
      return instance.get(url, config);
    }
  },
  post: (url, data, headers) => {
    if (!url || !data) {
      throw Error("endPoint and data are required params");
    }
    return instance.post(url, data, { headers });
  },
  put: (url, data, headers) => {
    if (!url || !data) {
      throw Error("endpoint and data are required params");
    }
    return instance.put(url, data, { headers });
  },
  patch: (url, data, headers) => {
    if (!url || !data) {
      throw Error("endpoint and data are required params");
    }
    return instance.patch(url, data, { headers });
  },
  delete: (url, data, headers) => {
    if (!url || !data) {
      throw Error("endPoint and data are required params");
    }
    return instance.delete(url, data, { headers });
  },
};

export { apiService };
