import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterReview from "./component";
import {
  getUserResume,
  resetStore,
  updateParagraphOrdering,
  renameParagraph,
  deleteParagraph,
  updateDocStyle,
  updateUserGuid,
  getUserProfile,
  updateTemplateCd,
} from "./actions";
import { setDocGUID, setTemplate } from "../funnel-step/actions";
import { saveChooseTemplate } from "../cover-letter-intro-page/actions";
import {
  handleSteps,
  previewTemplateAction,
} from "../cover-letter-template/actions";
import { resetDesiredPosition } from "../cover-letter-job-title/actions";
import { resetDesiredCompany } from "../cover-letter-desired-company/actions";
import { resetOpenerRecommend } from "../cover-letter-recommend/actions";
import { resetBodyRecommend } from "../cover-letter-body-recommend/actions";
import { resetCloserRecommend } from "../cover-letter-closer-recommend/actions";
import {
  resetSignature,
  updateSignatureSuccess,
} from "../cover-letter-signature/actions";
import { resetPersonalInfo } from "../cover-letter-personal-information/actions";
import { generatePDFPath } from "../review/actions";

const mapStateToProps = ({ docReview, funnelStep, cvltrTemplatePreview }) => ({
  docReview,
  funnelStep,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserCoverLetterConnect: getUserResume,
      resetStoreConnect: resetStore,
      updateOrderingConnect: updateParagraphOrdering,
      renameParagraphConnect: renameParagraph,
      deleteParagraphConnect: deleteParagraph,
      updateDocStyleConnect: updateDocStyle,
      updateUserGuidConnect: updateUserGuid,
      getUserProfileConnect: getUserProfile,
      setTemplateConnect: setTemplate,
      updateTemplateCdConnect: updateTemplateCd,
      SaveDocGUIDConnect: setDocGUID,
      saveChooseTemplate: saveChooseTemplate,
      previewTemplateAction: previewTemplateAction,
      handleSteps: handleSteps,
      resetJobTitleSuccess: resetDesiredPosition,
      resetDesiredCompanySuccess: resetDesiredCompany,
      resetOpenerRecommendSuccess: resetOpenerRecommend,
      resetBodyRecommendSuccess: resetBodyRecommend,
      resetCloserRecommendSuccess: resetCloserRecommend,
      resetSignature: resetSignature,
      resetPersonalInfoSuccess: resetPersonalInfo,
      updateSignatureSuccess: updateSignatureSuccess,
      generatePDFPathConnect: generatePDFPath,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetterReview);
