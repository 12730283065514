import Types from "./types";
import UserServiceApi from "../../services/user-service-api";
import appLogger from "../../utils/app-logger";
import FunnelStepTypes from "../funnel-step/types";
import { createCookie } from "../../utils/helpers";
import { getTenantGroupId } from "../../utils/general-config";

const adminLoginProcess = (data) => {
  return function (dispatch) {
    return UserServiceApi.signinAsAdmin(data)
      .then((response) => {
        if (response) {
          const { jwtToken, refreshToken } = response.legalSimpliToken;
          localStorage.setItem("jwtToken", jwtToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("isAdmin", true);
          createCookie("jwtToken", jwtToken, 1);
          createCookie("refreshToken", refreshToken, 1);
          appLogger.log("inside adminLoginProcess");
          // call user service to get userguid
          return UserServiceApi.getUserByEmail(encodeURIComponent(data.userEmail), getTenantGroupId()).then(
            (userResponse) => {
              if (userResponse) {
                const { userGuid } = userResponse;
                var validUserGuid = userGuid;
                if (userGuid.includes("-") == false) {
                  validUserGuid = userGuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
                }
                dispatch(PushUserInformationToFunnelStep(validUserGuid, data.userEmail));
                return response;
              }
            }
          );
        }
      })
      .catch((error) => console.log("Error"));
  };
};

const PushUserInformationToFunnelStep = (userGUID, email) => ({
  type: FunnelStepTypes.SET_USERINFO,
  userGUID,
  email,
});

export { adminLoginProcess };
