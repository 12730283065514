import React from "react";
import Button from "../../../../shared/button";
import { checkEmpty } from "../../../../../utils/helpers";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

const MetaContent = ({
  provided,
  dragClass,
  openerEditHandler,
  bodyEditHandler,
  CloserEditHandler,
  deleteSectionHandler,
  item,
}) => {
  const { t } = useTranslation();

  let content = JSON.parse(item.paraContents[0].content)

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        className={
          dragClass
            ? "resume__section resume__summary dragging"
            : "resume__section resume__summary"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={() => {
              if (item.paraName === t("opener.opener_recommend")) {
                openerEditHandler();
              }
              if (item.paraName === t("opener.body_recommend")) {
                bodyEditHandler();
              }
              if (item.paraName === t("opener.closer_recommend")) {
                CloserEditHandler();
              }
            }}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={() => {
              deleteSectionHandler(item.paraName);
            }}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <div className="cvltr__section cvltr__opener">
          <div className="cvltr__content">
            {item.paraName === t("opener.opener_recommend") && (
              <p
                className="cvltr-mb30"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `${!!content && !checkEmpty(content.openerRecommend)
                      ? content.openerRecommend
                      : ""
                    }`
                  ),
                }}
              />
            )}
            {item.paraName === t("opener.body_recommend") && (
              <p
                className="cvltr-mb30"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `${!!content && !checkEmpty(content.bodyRecommend)
                      ? content.bodyRecommend
                      : ""
                    }`
                  ),
                }}
              />
            )}
            {item.paraName === t("opener.closer_recommend") && (
              <p
                className="cvltr-mb30"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `${!!content && !checkEmpty(content.closerRecommend)
                      ? content.closerRecommend
                      : ""
                    }`
                  ),
                }}
              />
            )}
          </div>
        </div>
        <div className="resume__move" {...provided.dragHandleProps}>
          <a
            className="btn-green resume__control-button js-sortableHandle"
            href="#"
          >
            <span className="fa fa-arrows" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MetaContent;
