import React, { useState, useEffect } from "react";
import "./introduction.scss";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import LanguageSelect from "../../languageSelect";
import dompurify from "dompurify";
import FinalStepTypes from "../funnel-step/types";
import { useDispatch, useSelector } from "react-redux";
import PathHelper from "../../utils/path-helper";
import {
  APP_NAVIGATION,
  AFFILIATE_STORAGE_NAME,
  AFFILIATE_KEYWORD_NAME,
  AFFILIATE_SOURCE_NAME,
  AFFILIATE_SOURCE_VALUE,
  EMPTY_GUID,
} from "../../utils/constants";
import { Helmet } from "react-helmet";
import { createCookie, getPortalCD, readCookie } from "../../utils/helpers";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { setLocaleURL, getLocaleFromPortalSource } from "../../utils/helpers";
import { trackRBEvents } from "../../utils/tracking-events";

const Introduction = ({ resetStoreConnect, setQueryStringInfoConnect, resetSidebarProgress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const { pathname } = localtion;
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  let pathRedirect = PathHelper(APP_NAVIGATION.ITRO);
  let { locale } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const queryStringInfo = {};
  for (const [name, value] of queryParams) {
    queryStringInfo[name.toLowerCase()] = value;
  }
  if (queryParams.has(AFFILIATE_STORAGE_NAME)) {
    console.log(queryParams.get(AFFILIATE_STORAGE_NAME));
    createCookie(AFFILIATE_STORAGE_NAME, queryParams.get(AFFILIATE_STORAGE_NAME));
  }
  if (queryParams.has(AFFILIATE_KEYWORD_NAME)) {
    console.log(queryParams.get(AFFILIATE_KEYWORD_NAME));
    createCookie(AFFILIATE_KEYWORD_NAME, queryParams.get(AFFILIATE_KEYWORD_NAME));
  }
  if (queryParams.has("slaenable")) {
    if (queryParams.get("slaenable") === "true") {
      createCookie("slaenable", true);
    } else {
      createCookie("slaenable", false);
    }
  }

  const goToChooser = () => {
    if (queryParams.has(AFFILIATE_SOURCE_NAME) && queryParams.get(AFFILIATE_SOURCE_NAME) == AFFILIATE_SOURCE_VALUE) {
      createCookie(AFFILIATE_SOURCE_NAME, queryParams.get(AFFILIATE_SOURCE_NAME));
      const localefromPortalSource = getLocaleFromPortalSource();
      if (!!localefromPortalSource) {
        locale = localefromPortalSource;
      }
      if (!!locale) {
        navigate(setLocaleURL(ROUTE_CONSTANT.SIGNUP_LOCALE, locale) + localtion.search);
      } else navigate(ROUTE_CONSTANT.SIGNUP + localtion.search);
    } else {
      navigate(pathRedirect.next + localtion.search);
    }
  };

  const goToCoverLetter = () => {
    resetSidebarProgress();
    navigate("/app/templates");
  };
  useEffect(() => {
    trackRBEvents("introductionView", {
      visitId: readCookie("visitGuid"),
      userGuid: "",
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: "",
      firstName: "",
      lastName: "",
    });
    resetStoreConnect();
    localStorage.removeItem("save_template");
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  useEffect(() => {
    setQueryStringInfoConnect(queryStringInfo);
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.introduction")}</title>
      </Helmet>
      <section className="section main-content text-center">
        <div className="container intro">
          <div className="row">
            <div className="col-12 headline-group">
              <hgroup>
                <h1>{t("introduction.headline")}</h1>
              </hgroup>
            </div>
          </div>

          <div className="row intro-wrap">
            <div className="col-xs-12 col-md-4 ">
              <div className="builder-step">
                <div className="builder-step--icon step-1">
                  <span className="builder-step--number">1</span>
                  <img src="/app/static/images/icon-cv.svg" />
                </div>
                <span
                  className="builder-step--text"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("introduction.stepFirst")),
                  }}
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-4 ">
              <div className="builder-step">
                <div className="builder-step--icon step-2">
                  <span className="builder-step--number">2</span>
                  <img src="/app/static/images/icon-build-cv.svg" />
                </div>
                <span className="builder-step--text">{t("introduction.stepSecond")}</span>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 ">
              <div className="builder-step">
                <div className="builder-step--icon step-3">
                  <span className="builder-step--number">3</span>
                  <img src="/app/static/images/icon-download2.svg" />
                </div>
                <span className="builder-step--text">{t("introduction.stepThird")}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="intro-bottom">
                <div className="intro-bottom--btn fbn d-flex flex-wrap justify-content-around">
                  <span className="cvltr-mbres">
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={goToChooser}
                      id="build-my-resume"
                      classNames="btn btn-primary btn-primary--heavy btn-lg intro-btn"
                      ctaText={t("introduction.mainCTA")}
                    />
                    <div className="intro-bottom--terms">
                      <small
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(t("introduction.clickTerms")),
                        }}
                      />
                    </div>
                  </span>
                  {/* <span>
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={goToCoverLetter}
                      classNames="btn btn-primary btn-primary--heavy btn-lg intro-btn"
                      ctaText={t("introduction.cover_letter")}
                    />
                    <div className="intro-bottom--terms">
                      <small
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(t("introduction.clickTermsCvltr")),
                        }}
                      />
                    </div>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Introduction;
