import React from "react";
import "./error-modal.scss";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import dompurify from "dompurify";

const ErrorModal = ({ errorModalShow, errorModal, errorMessage, refreshDataCallback }) => {
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  return (
    <>
      <Modal show={errorModal} onHide={errorModalShow} centered size="md" className="error-modal custom-modal-width">
        <Modal.Header closeButton className="closeButton text-center">
        </Modal.Header>
        <Modal.Body>
          <div><FontAwesomeIcon icon={faCircleExclamation} className=" warning-icon text-danger  fa-3x" /></div>
          <div><h2 className="text-danger my-3 text-uppercase">
            {t("error_modal.heading")}</h2>
            <div>
              <p variant="success" className="  warning-message fw-bold m-0"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    errorMessage
                  ),
                }}>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="  d-flex justify-content-center my-4 ">
          <Button variant="primary" className=" btn-refresh fs-2 " onClick={refreshDataCallback}>
            {t("error_modal.refresh")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorModal;
