import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Experience from "./component";
import {
  createExperienceParagraph,
  updateExperienceParagraph,
  deleteExperience,
} from "./actions";
const mapStateToProps = ({ experience, funnelStep }) => ({
  experience,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      experienceConnect: createExperienceParagraph,
      updateExperienceParagraphConnect: updateExperienceParagraph,
      deleteExperienceConnect: deleteExperience,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
