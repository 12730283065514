import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_CODE, SECTION_TYPE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  error: null,
  total: 0,
  Paragraphs: emptyParagraph(SECTION_CODE.LANG, SECTION_TYPE.LANG),
};
export const updateLanguageParagraph = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data };
};

export const fetchLanguagesSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    isLoading: false,
    error: null,
    Paragraphs: data,
  };
};

export const fetchLanguagesFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const resetLanguages = () => {
  return { ...INITIAL_STATE };
};
export const renameLanguageSection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};
export const HANDLERS = {
  [Types.FETCH_LANGUAGES_SUCCESS]: fetchLanguagesSuccess,
  [Types.FETCH_LANGUAGES_FAILURE]: fetchLanguagesFailure,
  [Types.RESET_LANGUAGES]: resetLanguages,
  [Types.UPDATE_LANGUAGE_SUCCESS]: updateLanguageParagraph,
  [Types.RENAME_LANGUAGE]: renameLanguageSection,
};

export default createReducer(INITIAL_STATE, HANDLERS);
