import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";

const createSignatureParagraph = (
  docGUID,
  paragraph,
  paraName,
  tabValue,
  key
) => {
  return function (dispatch) {
    let para = createParagraph(
      docGUID,
      EMPTY_GUID,
      EMPTY_GUID,
      paragraph,
      SECTION_ORDER.SIGN,
      SECTION_TYPE.CLSR,
      SECTION_CODE.CLSR,
      paraName
    );

    return DocBuilderService.createParagraph(docGUID, para).then(
      (paraResponse) => {
        dispatch(saveSignatureSuccess(paraResponse, paraResponse.id));
        dispatch(saveTabValue({ key: key, tabValue: tabValue }));
      }
    );
  };
};

const updateSignatureParagraph = (docid, paraid, data, tabValue, key) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateSignatureSuccess(response));
          dispatch(saveTabValue({ key: key, tabValue: tabValue }));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveSignatureImageAndGetBlobUrl = (docid, data) => {
  return function (dispatch) {
    return DocBuilderService.saveSignatureImage(docid, data)
      .then((response) => {
        if (response) {
          console.log("Image Blob Url", response);
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveSignatureSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateSignatureSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

export const saveTabValue = (data) => ({
  type: Types.SAVE_TAB_VALUE,
  data,
});

const resetSignature = () => ({
  type: Types.RESET_SIGNATURE,
});

export {
  createSignatureParagraph,
  updateSignatureParagraph,
  resetSignature,
  saveSignatureImageAndGetBlobUrl,
};
