import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const CvDistinctCSS = css`
  .resume-layout.cv-distinct {
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-distinct .resume-photo {
    display: block;
    background-color: #fff;
    position: absolute;
    border: 1px #f4f4f4 solid;
  }
  .resume-layout.cv-distinct .resume-photo-content {
    padding-left: 175px;
    padding-bottom: 10px;
    border-bottom: 5px #42a2e0 solid;
    min-height: 170px;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-distinct .resume-photo-content {
      padding-left: 90px;
      min-height: 80px;
      padding-bottom: 15px;
      border-bottom: 3px #42a2e0 solid;
    }
    .resume-layout.cv-expressive .resume-layout-box .resume__section {
      margin-bottom: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
  .resume-layout.cv-distinct .resume-main .resume__section {
    padding-left: 0;
  }
  .resume-layout.cv-distinct .resume-header .info-main {
    display: grid;
    font-weight: 300;
    position: relative;
  }
  @media (min-width: 768px) {
    .resume-layout.cv-distinct
      .resume-header
      .info-main
      .info-item:first-child {
      order: 3;
      // position: absolute;
      // left: 50%;
      // top: 38px;
      // bottom: 0;
      width: 100%;
    }
  }

  .resume-layout.cv-distinct .resume-header .info-main .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv-distinct .resume-header .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv-distinct .resume__section.resume__heading h1 {
    font-weight: bold;
    font-size: 2.86em;
    color: #42a2e0;
    padding-top: 0;
    line-height: 100%;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-distinct .resume__section.resume__heading h1 {
      padding-top: 0;
      margin-bottom: 0.5rem;
    }
  }
  .resume-layout.cv-distinct .resume-aside {
    order: 2;
    width: 25%;
    margin-right: 15px;
    padding-top: 2rem;
  }
  .resume-layout.cv-distinct .resume-main {
    order: 1;
    width: 75%;
    padding-top: 1rem;
    padding-right: 5rem;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-distinct .resume-main {
      padding-right: 2rem;
    }
  }
  .resume-layout.cv-distinct .resume-aside .resume__heading {
    display: none;
  }
  .resume-layout.cv-distinct .sub-headline {
    margin-bottom: 1rem;
  }
  .resume-layout.cv-distinct .resume__section .sub-headline h2 {
    display: inline-block;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-distinct .resume__section h1,
  .resume-layout.cv-distinct .resume__section h3 {
    font-size: 1.15em;
    margin-bottom: 0.25em;
    font-weight: 600;
    letter-spacing: 0;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-distinct .sub-headline .svg-icon {
    display: inline-block;
    margin-right: 10px;
    display: none;
  }
  .resume-layout.cv-distinct .sub-headline .svg-icon {
    background-color: #42a2e0;
    border: 4px solid #fbc498;
    border-radius: 100%;
    color: #ffefe3;
    height: 3em;
    width: 3em;
    line-height: normal;
    padding: 0.65rem;
    text-align: center;
    margin-left: 0;
  }
  .resume-layout.cv-distinct .sub-headline .svg-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .resume-layout.cv-distinct .info-item {
    display: block;
  }
  .resume-layout.cv-distinct .info-item .svg-icon {
    color: #42a2e0;
    margin-top: 4px;
  }
  .resume-layout.cv-distinct .info-item .svg-icon,
  .resume-layout.cv-distinct .info-item .info-text {
    // display: inline-block;
    display: flex;
  }
  .resume-layout.cv-distinct .info-item .info-label {
    display: none;
  }
  .resume-layout.cv-distinct .wrap .left-col,
  .resume-layout.cv-distinct .wrap .extra-info,
  .resume-layout.cv-distinct .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv-distinct .extra {
    display: block;
  }
  .resume-layout.cv-distinct .extra .extra-details .progress-starts,
  .resume-layout.cv-distinct .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv-distinct .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv-distinct
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #42a2e0;
  }
  .resume-layout.cv-distinct .xp-item {
    display: block;
  }
  .resume-layout.cv-distinct .xp-item .xp-date,
  .resume-layout.cv-distinct .xp-item .xp-job {
    //font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv-distinct .xp-item .xp-date {
    color: #42a2e0;
  }
  .resume-layout.cv-distinct .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv-distinct .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv-distinct .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv-distinct .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv-distinct .resume__education .edu-hide {
    display: block;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-distinct .sub-headline .svg-icon svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: -4px;
      margin-top: -6px;
    }
    .resume-layout.cv-distinct .resume-main {
      width: 70%;
    }
    .resume-layout.cv-distinct .resume-aside {
      width: 30%;
    }
  }
`;

export const CvDistinctStyle = createGlobalStyle`
  ${CommonCSS}
  ${CvDistinctCSS}
`;
