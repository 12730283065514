import React from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { createParagraph, emptyDocument } from "../../initialState";
import {
  APP_NAVIGATION,
  DOC_STAGE,
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useNavigate } from "react-router-dom";
import "./component.css";
import ChipBox from "./chipBox/chipBox";
import { useEffect } from "react";
import { getTenantId } from "../../utils/general-config";
import { checkEmpty, documentName, getPortalCD, readCookie } from "../../utils/helpers";
import PathHelper from "../../utils/path-helper";
import moment from "moment";
import Sidebar from "../../components/cover-letter-sidebar";
import CoverLetterPreview from "../../components/cover-letter-preview";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterSkills = ({
  funnelStep,
  skillsConnect,
  updateSkillsParagraph,
  cvltrSkills,
  SaveDocGUIDConnect,
  fetchSkills,
  updateProgress,
  previewTemplateAction,
  cvltrTemplatePreview,
  getNewSkills,
  getSelectedSkills,
}) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { docGUID, templateCd, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep;
  const { skillsList, selectedSkillsState, newSkillListState } = cvltrSkills || "";

  useEffect(() => {
    trackRBEvents("cvSkillsView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    updateProgress(60);
    fetchSkills(t);
  }, []);

  let pathRedirect = PathHelper(APP_NAVIGATION.SKILS, funnelStepsComplete, true);

  const content =
    !!cvltrSkills &&
    !!cvltrSkills.paragraph &&
    !!cvltrSkills.paragraph.paraContents &&
    !!cvltrSkills.paragraph.paraContents[0].content
      ? JSON.parse(cvltrSkills.paragraph.paraContents[0].content)
      : {};

  const id = !!cvltrSkills && !!cvltrSkills.paragraph && !!cvltrSkills.paragraph.id ? cvltrSkills.paragraph.id : "";

  let visitorGuid = readCookie("visitorGuid");

  const paraGraphId = !!id ? id : EMPTY_GUID;

  const [Skills, setSkills] = useState(!!content && !checkEmpty(content.Skills) ? content.Skills : "");
  const [SkillList, setSkillList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState(
    selectedSkillsState && selectedSkillsState.data ? selectedSkillsState.data : []
  );
  const [showLoader, setShowLoader] = useState("d-none");
  const [newSkillList, setNewSkillList] = useState(
    newSkillListState && newSkillListState.data ? newSkillListState.data : []
  );

  const buttonClickHandler = () => {
    if (cvltrSkills.paragraph == "" || content.Skills == undefined || content.Skills == "") {
      saveSkills();
    } else {
      updateSkills();
    }
  };

  const saveSkills = () => {
    setShowLoader("d-flex");
    let commaSkills = Skills;

    if (selectedSkills.length !== 0) {
      let updatedSkills = selectedSkills.toString();
      commaSkills = updatedSkills;
    }

    let head = {
      Skills: commaSkills,
    };

    let data = emptyDocument(
      documentName(cvltrTemplatePreview.fullName),
      JSON.stringify({}),
      templateCd,
      userGUID,
      getTenantId(),
      visitorGuid,
      ""
    );

    //Note: For cover letter document, we are passing typeOfDoc = 20 and DocStage = DOC_STAGE.BODY
    data.typeOfDoc = 20;
    data.DocStage = DOC_STAGE.BODY;

    skillsConnect(docGUID, JSON.stringify(head), t("opener.skills"), selectedSkills, newSkillList, data)
      .then((response) => {
        navigate(pathRedirect.next);
        setShowLoader("d-none");
        if (docGUID !== "" || docGUID !== undefined) {
          SaveDocGUIDConnect(response.docGuid);
        } else {
          SaveDocGUIDConnect(docGUID);
        }
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  useEffect(() => {
    getSelectedSkills(selectedSkills);
    getNewSkills(newSkillList);
  }, [selectedSkills, newSkillList]);

  useEffect(() => {
    let commaSkills = Skills;

    if (selectedSkills.length !== 0) {
      let updatedSkills = selectedSkills.toString();
      commaSkills = updatedSkills;
    }

    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      skills: commaSkills,
    });
  }, [Skills, selectedSkills]);

  const updateSkills = () => {
    setShowLoader("d-flex");
    let commaSkills = Skills;

    if (selectedSkills.length !== 0) {
      let updatedSkills = selectedSkills.toString();
      commaSkills = updatedSkills;
    }

    let head = {
      Skills: commaSkills,
    };

    let skillsOrder =
      !!cvltrSkills && !!cvltrSkills.paragraph && !!cvltrSkills.paragraph.order && cvltrSkills.paragraph.order != 0
        ? cvltrSkills.paragraph.order
        : SECTION_ORDER.OPNR;

    let data = createParagraph(
      docGUID,
      cvltrSkills.paragraph.id,
      cvltrSkills.paragraph.paraContents[0].id,
      JSON.stringify(head),
      skillsOrder,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      t("opener.skills")
    );

    updateSkillsParagraph(docGUID, paraGraphId, data, selectedSkills, newSkillList)
      .then((response) => {
        navigate(pathRedirect.next);
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  useEffect(() => {
    setSkillList(skillsList);
  }, [skillsList]);

  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={2} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      <ScrollToTop />
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <hgroup>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("opener.skills_title"),
                  }}
                />
                <h4
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("opener.skills_subtitle")),
                  }}
                />
              </hgroup>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-9 pt-4">
                <div>
                  <div className="row">
                    <div className="">
                      {skillsList && skillsList.length > 0 && (
                        <ChipBox
                          selectedSkills={selectedSkills}
                          setSelectedSkills={setSelectedSkills}
                          SkillList={SkillList}
                          setSkillList={setSkillList}
                          newSkillList={newSkillList}
                          setNewSkillList={setNewSkillList}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    id="back-btn"
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("opener.back")}
                    onClick={backClickHandler}
                  />

                  <Button
                    isLoading={false}
                    disabled={false}
                    id="next-btn"
                    classNames="btn btn-primary btn-lg submit-on-enter float-end cvltr-btn-spacing"
                    ctaText={t("opener.next")}
                    onClick={buttonClickHandler}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterSkills;
