import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../button";
import "./modal-popup.scss";
import { useTranslation } from "react-i18next";

const ModalPopup = ({ show, toggleShow, header, footerText, skipPage, message }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const skipPageHandler = () => {
    navigate(skipPage.next);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-info-section"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={message} />
        </Modal.Body>
        <Modal.Footer>
          <button className="textlink" onClick={skipPageHandler}>
            <span dangerouslySetInnerHTML={footerText} />
          </button>
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-primary"
            id="close_ok_btn"
            ctaText={t("experience.exp_modal_btn")}
            onClick={toggleShow}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPopup;
