import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterIntro from "./component";
import { saveIntroduction } from "./actions";
import { resetStore } from "../review/actions";
import { setQueryStringInfo } from "./../funnel-step/actions";
import { resetSidebarProgress } from "../../components/cover-letter-sidebar/actions";
import { setDocType } from "./../funnel-step/actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveIntroductionConnect: saveIntroduction,
      resetStoreConnect: resetStore,
      setQueryStringInfoConnect: setQueryStringInfo,
      resetSidebarProgress: resetSidebarProgress,
      setDocTypeConnect:setDocType
      //updateIntroductionConnect: updateIntroduction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetterIntro);
