import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterTemplate from "./component";
import { setTemplate } from "../funnel-step/actions";

const mapStateToProps = ({ funnelStep, docReview }) => ({ funnelStep, docReview });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTemplateConnect: setTemplate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterTemplate);
