import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_BODY_RECOMMEND
    SAVE_SELECTED
`,
  {
    prefix: "body/recommend",
  }
);
