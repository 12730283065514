import appLogger from "../utils/app-logger";
import { apiService } from "../services/base-api-service";
import {
  endPoints,
  getMetaContentServiceEndPoint,
} from "../services/api-constants";

class MetaContentService {
  MetaContentService(url) {
    return getMetaContentServiceEndPoint(url);
  }
  getIndusty = (sectionCode, locale) => {
    appLogger.log("inside getIndusty ");
    var industryEndPoint = `${
      endPoints.metaContentService.GET_INDUSTRY
    }?locale=${!!locale ? locale : ""}&contentType=${sectionCode}`;
    const getIndustryEndpoint = this.MetaContentService(industryEndPoint);
    return apiService.get(getIndustryEndpoint);
  };

  getSubIndusty = (industryId) => {
    appLogger.log("inside getSubIndusty ");
    var industryEndPoint = `${endPoints.metaContentService.GET_INDUSTRY}/${industryId}/subindustry`;
    const getIndustryEndpoint = this.MetaContentService(industryEndPoint);
    return apiService.get(getIndustryEndpoint);
  };

  getWritingContent = (
    sectionCode,
    industryId,
    subIndustryId,
    locale,
    years
  ) => {
    if (years === undefined) {
      years = "";
    }
    var industryEndPoint = "";
    if (!!industryId && !!subIndustryId) {
      industryEndPoint = `${endPoints.metaContentService.WRITING_CONTENT_BY_INDUSTRY}?industryId=${industryId}&subIndustryId=${subIndustryId}&contentType=${sectionCode}`;
    } else if (!!industryId) {
      industryEndPoint = `${endPoints.metaContentService.WRITING_CONTENT_BY_INDUSTRY}?industryId=${industryId}&contentType=${sectionCode}&years=${years}`;
    } else if (!!sectionCode) {
      industryEndPoint = `${
        endPoints.metaContentService.WRITING_CONTENT
      }?locale=${!!locale ? locale : ""}&contentType=${sectionCode}`;
    }
    const getIndustryEndpoint = this.MetaContentService(industryEndPoint);
    return apiService.get(getIndustryEndpoint);
  };
}

export default new MetaContentService();
