import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkEmpty, concatNotEmptyBy } from "../../../utils/helpers";

const CoverLetterRecipientSection = ({ cvltrTemplatePreview, type, data }) => {
  let content;
  const { t } = useTranslation();
  let dateFormate = t("opener.date_formate");

  if (type == "cover-letter" || (data && data.length > 0)) {
    content = JSON.parse(data[0].content);
  } else {
    content =
      !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField
        ? cvltrTemplatePreview.templateField
        : false;
  }

  const date = moment().format(dateFormate);

  const Company =
    !!content && !checkEmpty(content.DesiredCompany)
      ? content.DesiredCompany
      : type == "cover-letter"
      ? ""
      : "";

  const address =
    !!content && !checkEmpty(content.companyStreetAddress)
      ? `${content.companyStreetAddress}.`
      : type == "cover-letter"
      ? ""
      : "";

  const city =
    !!content && !checkEmpty(content.companyCity)
      ? `${content.companyCity}`
      : type == "cover-letter"
      ? ""
      : "";

  const ST =
    !!content && !checkEmpty(content.companyState)
      ? content.companyState
      : type == "cover-letter"
      ? ""
      : "";

  const zipCode =
    !!content && !checkEmpty(content.companyZipCode)
      ? content.companyZipCode
      : type == "cover-letter"
      ? ""
      : "";

  return (
    <>
      <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
        <div className="cvltr__section cvltr__recipient">
          <div className="cvltr__content">
            <p className="cvltr-mt0">
              <b>{date}</b>
            </p>
            <div className="cvltr-companyinfo 323">
              <p>{t("opener.recipient_name")}</p>
              <p>{Company}</p>
              <p>{address}</p>
              <p>{concatNotEmptyBy(", ")(city, ST, zipCode)}</p>
            </div>
            <p className="cvltr-mt30">
              <b>{t("opener.greeting_to_recipient_name")}:</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetterRecipientSection;
