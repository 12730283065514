import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import Sidebar from "../../components/cover-letter-sidebar";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { readCookie, getPortalCD } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const CoverLetterCloserPage = ({ funnelStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useParams();
  const sanitizer = dompurify.sanitize;
  const { templateCd, docGUID, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep || {};

  let pathRedirect = PathHelper(APP_NAVIGATION.CLSR);

  useEffect(() => {
    trackRBEvents("cvCloserView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
  }, []);

  const goToNext = () => {
    navigate(pathRedirect.next);
  };

  const goToBack = () => {
    navigate(pathRedirect.back);
  };

  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={3} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <section className="section main-content templates text-center sidebar-sticky sidebar-sticky-cvrltr">
        <div className="container">
          <div className="alignment-container">
            <div className="row pb-5">
              <div className="headline-group text-center heading-style mb-3">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: t("opener.closer_title"),
                    }}
                  />
                </hgroup>
              </div>
              <div className="pb-5">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("opener.closer_description")),
                  }}
                />
                <h4>{t("opener.Closer_sub_title")}</h4>
              </div>
            </div>

            <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn intro-footer">
              <Button
                isLoading={false}
                id="back-btn"
                classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                ctaText={t("opener.back")}
                onClick={goToBack}
              />

              <Button
                isLoading={false}
                disabled={false}
                id="next-btn"
                classNames="btn btn-primary btn-lg submit-on-enter float-end"
                ctaText={t("opener.next")}
                onClick={goToNext}
              />
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterCloserPage;
