import { createTypes } from "reduxsauce";

export default createTypes(
    `
    QUACK
`,
    {
        prefix: "resetpassword/"
    }
);
