import React from "react";
import Modal from "react-modal";
import styles from "./toastmessage.css";
import Close from "../images/close.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ToastMessage = ({
  isOpen,
  close,
  heading = "",
  message = "",
  closeOnOutside = true,
  showCloseButton = true,
}) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button onClick={openModal}>Open Modal</button>
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}
        appElement={document.getElementById("root")}
      >
        <button onClick={closeModal}>close</button>
        <h2>{heading}</h2>
        <div>{message}</div>
      </Modal>
    </>
  );
};

export default ToastMessage;
