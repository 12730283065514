import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv14ArmonyCSS = css`
  .resume-layout.cv14 {
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv14 .resume__section {
    padding-top: 3rem;
    padding-bottom: 2em;
    margin-bottom: 0;
  }
  .resume-layout.cv14 .resume__section.resume__heading h1 {
    text-align: center;
    font-size: 4.29em;
    color: #42a2e0;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: none;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv14 .sub-headline {
    display: block;
  }
  .resume-layout.cv14 .resume__section .sub-headline h2 {
    color: #fff;
    padding: 0.5em 1.25em 0.42em;
    font-size: 1.71em;
    margin-bottom: 1em;
    font-weight: normal;
    text-transform: uppercase;
    background-color: #42a2e0;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv14 .resume__section h1,
  .resume-layout.cv14 .resume__section h3 {
    font-size: 1.375em;
    margin-bottom: 0em;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv14 .info-main {
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    margin-top: 30px;
    padding: 1.5rem 0;
    display: flex;
  }
  .resume-layout.cv14 .info-item {
    width: 40%;
    text-align: center;
    margin-bottom: 0.125rem;
  }
  .resume-layout.cv14 .info-item:first-child {
    width: 20%;
  }
  .resume-layout.cv14 .info-text {
    display: flex;
    text-align: left;
    align-items: baseline;
  }
  .resume-layout.cv14 .resume__heading .info-main .info-item:first-child {
    order: 3;
  }
  .resume-layout.cv14 .resume__heading .info-main .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv14 .resume__heading .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv14 .info-item .info-label {
    display: none;
  }
  .resume-layout.cv14 .info-item .svg-icon {
    display: inline-block;
    color: #42a2e0;
    font-size: 1.43em;
  }
  .resume-layout.cv14 .xp-item .xp-date {
    position: relative;
    font-weight: normal;
    padding-right: 1.36em;
    padding-top: 1.36em;
  }
  .resume-layout.cv14 .xp-item .xp-job {
    font-weight: normal;
    // font-size: 1rem;
    padding: 1.6em 1.66em 0;
  }
  .resume-layout.cv14 .xp-item .xp-job .exp-show {
    font-weight: bold;
    font-size: 1.29em;
  }
  .resume-layout.cv14 .xp-item .xp-job.edu-show ul {
    list-style: none;
  }
  .resume-layout.cv14 .xp-date:after,
  .resume-layout.cv14 .extra .extra-details:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #42a2e0;
    position: absolute;
    top: 5px;
    right: -5px;
  }
  .resume-layout.cv14 .extra .extra-details:after {
    top: 1.85em;
    right: -0.45em;
  }
  .resume-layout.cv14 .wrap {
    margin-bottom: 0;
  }
  .resume-layout.cv14 .wrap .left-col,
  .resume-layout.cv14 .wrap .extra {
    width: 100%;
  }
  .resume-layout.cv14 .wrap .extra {
    margin-bottom: 0;
  }
  .resume-layout.cv14 .wrap .extra-info {
    order: 2;
    width: 70%;
    //padding: 10px 0;
    font-weight: bold;
    border-left: 1px solid lightgrey;
    padding: 1.6em 1.66em 0;
    min-height: 40.29px;
  }
  .resume-layout.cv14 .extra .extra-details {
    order: 1;
    width: 30%;
    padding: 0;
    position: relative;
    padding-right: 1.36em;
    padding-top: 1.36em;
  }
  .resume-layout.cv14 .xp-date:after {
    top: 1.85em;
    right: -0.45em;
  }
  .resume-layout.cv14 .extra .extra-details .progress-starts {
    text-align: right;
    //padding: 10px 20px 10px 0;
    //margin-right: 20px;
    //border-right: 1px #ddd solid;
    min-height: 21.45px;
  }
  .resume-layout.cv14 .extra .extra-details .progress-starts em {
    font-style: normal;
  }
  .resume-layout.cv14 .resume__heading .sub-headline {
    display: none;
  }
  .resume-layout.cv14 .xp-item {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    .resume-layout.cv14 .extra .extra-details .progress-starts {
      min-height: inherit;
    }
    .resume-layout.cv14 .wrap .extra-info {
      min-height: inherit;
    }
    .resume-layout.cv14 .xp-date:after,
    .resume-layout.cv14 .extra .extra-details:after {
      width: 0.7em;
      height: 0.7em;
    }
  }
`;

export const Cv14ArmonyStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv14ArmonyCSS}
`;
