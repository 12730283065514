import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../../utils/constants";
import Button from "../../shared/button";
import moment from "moment";
import { useNavigate, link } from "react-router-dom";
import PathHelper from "../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../utils/constants";

const ResumeExperience = ({ data }) => {
  const parseData = JSON.parse(data.content);
  const {
    employer,
    jobTitle,
    city,
    state,
    startDateMonth,
    startDateYear,
    endDateMonth,
    endDateYear,
    startDateMonthLabel,
    endDateMonthLabel,
    presently_here,
    content,
  } = parseData;
  const job_description = "";
  const { t } = useTranslation();

  return (
    <div className="xp-item">
      <div className="exp-hide txt-bold">
        {jobTitle && jobTitle + ", "} {employer && employer + ". "} {city}
        {state && ", " + state}
      </div>

      <div className="xp-date">
        {startDateYear &&
          startDateMonthLabel != "" &&
          startDateMonthLabel + ". " + startDateYear}
        {startDateYear && startDateMonthLabel === "" && startDateYear}
        {!!presently_here && JSON.parse(presently_here)
          ? " - " + t("experience.label_Present")
          : !!endDateMonth
          ? ""
          : ""}
        {presently_here != true &&
          endDateYear &&
          endDateMonthLabel != "" &&
          " - " + endDateMonthLabel + ". " + endDateYear}

        {presently_here != true &&
          endDateYear &&
          startDateYear &&
          endDateMonthLabel === "" &&
          " - " + endDateYear}
        <div className="xp-city exp-show">
          {city} {state && ", " + state}
        </div>
      </div>
      <div className="xp-job">
        <span className="exp-show">
          {jobTitle && jobTitle + ", "} {employer && employer + ". "}
        </span>
        {/* Lawyer, Legal Firm */}
        <div className="xp-detail">
          {/* <div>
            Responsible for not only handling personal caseloads but also
            assisting other partners in high value complex cases. Also in charge
            of preparing and drafting legal documents, such as wills, deeds,
            patent applications, mortgages, leases, and
          </div> */}
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

const TempExperience = ({
  data,
  toggleRenameModal,
  toggleDeleteModal,
  setCurrentParaConnect,
  setSelectedRenameSection,
  paraName,
  dragHandleProps,
  dragStart,
}) => {
  let dragClass = false;
  if (dragStart !== undefined) {
    if (dragStart.style.position !== undefined) {
      dragClass = true;
    }
  }
  const { t } = useTranslation();
  if (paraName === "Employment History") {
    paraName = t("experience.employment_history");
  }
  let pathRedirect = PathHelper(APP_NAVIGATION.TEXP, false);
  const FinalReviewExperienceClick = () => {
    setSelectedRenameSection(paraName);
    toggleRenameModal();
    setCurrentParaConnect(SECTION_TYPE.EXPR, "");
  };
  const deleteSectionHandler = () => {
    toggleDeleteModal();
    setCurrentParaConnect(SECTION_TYPE.EXPR, "");
  };
  // Nav
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };

  return (
    <>
      <div
        className={
          dragClass
            ? "resume__section resume__experience dragging"
            : "resume__section resume__experience"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={editHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">{t("review.edit")}</span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={FinalReviewExperienceClick}
            icon={
              <>
                <span className="fa fa-file-text-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.rename")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={deleteSectionHandler}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <div className="resume__content">
          <div className="sub-headline">
            <i className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                aria-hidden="true"
                style={{ MsTransform: "rotate(360deg)" }}
                transform="rotate(360)"
                viewBox="0 0 1792 1536"
              >
                <path
                  fill="currentColor"
                  d="M640 256h512V128H640v128zm1152 640v480q0 66-47 113t-113 47H160q-66 0-113-47T0 1376V896h672v160q0 26 19 45t45 19h320q26 0 45-19t19-45V896h672zm-768 0v128H768V896h256zm768-480v384H0V416q0-66 47-113t113-47h352V96q0-40 28-68t68-28h576q40 0 68 28t28 68v160h352q66 0 113 47t47 113z"
                ></path>
              </svg>
            </i>
            <h2
              style={{
                fontSize: data[0].headingfontsize,
                fontFamily: data[0].fontFamily,
              }}
            >
              {paraName}
            </h2>
          </div>
          {data
            ?.sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <ResumeExperience data={item} key={"exp" + index} />
            ))}
        </div>
        <div className="resume__move" {...dragHandleProps}>
          <a
            className="btn-green resume__control-button js-sortableHandle"
            href="#"
          >
            <span className="fa fa-arrows" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </>
  );
};

export default TempExperience;
