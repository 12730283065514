import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Education from "./component";
import {
  createEducationParagraph,
  deleteEducation,
  updateEducationParagraph,
} from "./actions";

const mapStateToProps = ({ education, funnelStep }) => ({
  education,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createEducationParagraphConnect: createEducationParagraph,
      updateEducationParagraphConnect: updateEducationParagraph,
      deleteEducationConnect: deleteEducation,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Education);
