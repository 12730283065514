import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../../utils/constants";
import Button from "../../shared/button";
import moment from "moment";
import { useNavigate, link } from "react-router-dom";
import PathHelper from "../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../utils/constants";
import { concatNotEmptyBy } from "../../../utils/helpers";

const Education = ({ data }) => {
  const parseData = JSON.parse(data.content);
  const { t } = useTranslation();
  const {
    school_name,
    city,
    state,
    degree,
    custom_degree,
    field_of_study,
    education_graduation_month,
    graduationMonthLabel,
    education_graduation_year,
    presently_here,
  } = parseData;
  // const concatNotEmptyBy =
  //   (separator) =>
  //   (...args) =>
  //     args.filter((n) => n).join(separator);
  let graduationYear = "";
  if (presently_here) {
    graduationYear = t("experience.label_Present");
  } else {
    if (graduationMonthLabel && !education_graduation_year) {
      graduationYear = graduationMonthLabel;
    } else if (!graduationMonthLabel && education_graduation_year) {
      graduationYear = education_graduation_year;
    } else if (graduationMonthLabel && education_graduation_year) {
      graduationYear = graduationMonthLabel + ". " + education_graduation_year;
    } else {
      graduationYear = "";
    }
  }
  return (
    <div className="xp-item">
      <div className="xp-job edu-hide txt-bold">
        {concatNotEmptyBy(", ")(school_name, city, state)}
      </div>
      <div className="xp-date edu-hide">
        {(degree || field_of_study) &&
          degree === "Custom Degree" &&
          concatNotEmptyBy(", ")(custom_degree, field_of_study, graduationYear)}
        {(degree || field_of_study) &&
          degree !== "Custom Degree" &&
          concatNotEmptyBy(", ")(degree, field_of_study, graduationYear)}
        {/* {degree || field_of_study ? ", " : ""}
        {!!presently_here && JSON.parse(presently_here)
          ? t("experience.label_Present")
          : !!graduationMonthLabel
          ? graduationMonthLabel + ". " + education_graduation_year
          : ""} */}
      </div>

      <div className="xp-date edu-show">{graduationYear}</div>

      <div className="xp-job edu-show">
        <span className="txt-bold">
          {(degree || field_of_study) &&
            degree === "Custom Degree" &&
            concatNotEmptyBy(": ")(custom_degree, field_of_study)}
          {(degree || field_of_study) &&
            degree !== "Custom Degree" &&
            concatNotEmptyBy(": ")(degree, field_of_study)}
        </span>
        <div className="xp-detail">
          <ul>
            <li>{concatNotEmptyBy(", ")(school_name, city, state)}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const TempEducation = ({
  data,
  toggleRenameModal,
  toggleDeleteModal,
  setCurrentParaConnect,
  setSelectedRenameSection,
  paraName,
  dragHandleProps,
  dragStart,
}) => {
  let dragClass = false;
  if (dragStart !== undefined) {
    if (dragStart.style.position !== undefined) {
      dragClass = true;
    }
  }
  const { t } = useTranslation();
  if (paraName === "Education") {
    paraName = t("education.title");
  }
  let pathRedirect = PathHelper(APP_NAVIGATION.TEDU, false);
  const FinalReviewExperienceClick = () => {
    setSelectedRenameSection(paraName);
    toggleRenameModal();
    setCurrentParaConnect(SECTION_TYPE.EDUC, "");
  };
  const deleteSectionHandler = () => {
    toggleDeleteModal();
    setCurrentParaConnect(SECTION_TYPE.EDUC, "");
  };
  // Nav
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };
  return (
    <>
      <div
        className={
          dragClass
            ? "resume__section resume__education dragging"
            : "resume__section resume__education"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={editHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />

          <Button
            isLoading={false}
            disabled={false}
            onClick={FinalReviewExperienceClick}
            icon={
              <>
                <span className="fa fa-file-text-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.rename")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={deleteSectionHandler}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <div className="resume__content">
          <div className="sub-headline">
            <i className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                aria-hidden="true"
                className="iconify icon:fa:graduation-cap"
                data-height="1em"
                data-icon="fa:graduation-cap"
                data-inline="false"
                data-width="1em"
                transform="rotate(360)"
                viewBox="0 0 2304 1536"
              >
                <path
                  fill="currentColor"
                  d="M1774 708l18 316q4 69-82 128t-235 93.5-323 34.5-323-34.5-235-93.5-82-128l18-316 574 181q22 7 48 7t48-7zm530-324q0 23-22 31L1162 767q-4 1-10 1t-10-1L490 561q-43 34-71 111.5T385 851q63 36 63 109 0 69-58 107l58 433q2 14-8 25-9 11-24 11H224q-15 0-24-11-10-11-8-25l58-433q-58-38-58-107 0-73 65-111 11-207 98-330L22 415q-22-8-22-31t22-31L1142 1q4-1 10-1t10 1l1120 352q22 8 22 31z"
                ></path>
              </svg>
            </i>
            <h2
              style={{
                fontSize: data[0].headingfontsize,
                fontFamily: data[0].fontFamily,
              }}
            >
              {paraName}
            </h2>
          </div>
          {data
            ?.sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <Education data={item} key={"education" + index} />
            ))}
        </div>
        <div className="resume__move" {...dragHandleProps}>
          <a
            className="btn-green resume__control-button js-sortableHandle"
            href="#"
          >
            <span className="fa fa-arrows" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </>
  );
};

export default TempEducation;
