import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResumeTools from "./component";

const mapStateToProps = ({ docReview }) => ({ docReview });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResumeTools);
