import Types from "./types";
import { getCoverLetterTemplateList } from "../../services/master-data";

const fetchTemplates = (locale) => (dispatch) => {
  let response = getCoverLetterTemplateList(locale);
  dispatch(fetchTemplateSuccess(response.data));
};

const fetchTemplateSuccess = (data) => ({
  type: Types.FETCH_TEMPLATES_SUCCESS,
  data,
});

const resetChooseTemplate = () => ({
  type: Types.RESET_CHOOSE_TEMPLATE,
});

const saveChooseTemplate = (data) => {
  return function (dispatch) {
    localStorage.setItem("save_template", JSON.stringify(data));
    dispatch({ type: Types.SAVE_CHOOSE_TEMPLATE });
  };
};

export { fetchTemplates, resetChooseTemplate, saveChooseTemplate };
