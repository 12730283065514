import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const CvBoldCSS = css`
  .sidebar-bg {
    background-color: #000;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 250px;
  }
  @media (max-width: 767px) {
    .sidebar-bg {
      width: 180px;
    }
  }
  @media (max-width: 639px) {
    .sidebar-bg {
      width: 100px;
    }
  }
  .resume-layout.cv-bold {
    font-weight: normal;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-bold .resume-photo {
    display: block;
    background-color: #fff;
    position: absolute;
    right: 0;
    overflow: hidden;
    width: 170px;
    height: 170px;
  }
  .resume-layout.cv-bold .resume-photo-content {
    padding-right: 220px;
    min-height: 140px;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-bold .resume-photo {
      width: 145px;
      height: 145px;
    }
    .resume-layout.cv-bold .resume-photo-content {
      padding-right: 180px;
      min-height: 140px;
    }
  }
  @media (max-width: 639px) {
    .resume-layout.cv-bold .resume-photo {
      width: 65px;
      height: 65px;
    }
    .resume-layout.cv-bold .resume-photo-content {
      padding-right: 100px;
      min-height: 90px;
    }
    .resume-layout.cv-bold .resume-layout-box .resume__section {
      margin-bottom: 0.5rem;
    }
  }
  .resume-layout.cv-bold
    .resume-layout-box
    .resume__section.resume__heading
    .resume__controls {
    left: -1rem;
    right: -1rem;
    bottom: -3rem;
    width: 102.5%;
  }
  .resume-layout.cv-bold
    .resume-layout-box
    .resume__section.resume__heading
    .controls-menu {
    right: -1rem;
  }
  .resume-layout.cv-bold .resume-main .resume__section {
    padding-left: 0;
  }
  .resume-layout.cv-bold .resume__heading {
    font-weight: 300;
  }
  .resume-layout.cv-bold .resume__section.resume__heading h1 {
    font-size: 2.86em;
    padding-top: 0.5rem;
    text-transform: capitalize;
    font-weight: bold;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-bold .resume__section .sub-headline h2 {
    font-size: 1.43em;
    line-height: 1;
    margin-bottom: 0;
    font-family: "Century Gothic", sans-serif;
    display: inline-block;
    text-transform: capitalize;
  }
  .resume-layout.cv-bold .resume__section h1,
  .resume-layout.cv-bold .resume__section h3 {
    font-size: 1.15em;
    margin-bottom: 0.25em;
    font-weight: 600;
    letter-spacing: 0;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Century Gothic", sans-serif;
  }
  .resume-layout.cv-bold .resume-header .info-main {
    display: grid;
  }
  .resume-layout.cv-bold .resume-header .info-main .info-item:first-child {
    order: 3;
  }
  .resume-layout.cv-bold .resume-header .info-main .info-item:nth-child(2) {
    order: 2;
  }
  .resume-layout.cv-bold .resume-header .info-main .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv-bold .resume__summary ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .resume-layout.cv-bold .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv-bold .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv-bold .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv-bold .resume__education .edu-hide {
    display: block;
  }
  .resume-layout.cv-bold .resume-aside {
    order: 2;
    width: 210px;
    margin-right: 0;
    padding-top: 4rem;
    color: #fff;
    padding-left: 5rem;
  }
  .resume-layout.cv-bold .resume-main {
    order: 1;
    // width: 75%;
    width: -webkit-calc(100% - 210px);
    width: -moz-calc(100% - 210px);
    width: calc(100% - 210px);
    padding-top: 1rem;
    padding-right: 5rem;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-bold .resume-aside {
      width: 160px;
      padding-top: 1rem;
      padding-left: 1.5rem;
    }
    .resume-layout.cv-bold .resume-main {
      width: -webkit-calc(100% - 160px);
      width: -moz-calc(100% - 160px);
      width: calc(100% - 160px);
      padding-top: 0;
      padding-right: 2rem;
    }
  }
  @media (max-width: 639px) {
    .resume-layout.cv-bold .resume-aside {
      width: 80px;
      padding-top: 4rem;
      padding-left: 1rem;
    }
    .resume-layout.cv-bold .resume-main {
      width: -webkit-calc(100% - 80px);
      width: -moz-calc(100% - 80px);
      width: calc(100% - 80px);
      padding-top: 1rem;
      padding-right: 1.5rem;
    }
  }
  .resume-layout.cv-bold .resume-aside .resume__heading {
    display: none;
  }
  .resume-layout.cv-bold .sub-headline {
    margin-bottom: 1rem;
  }
  .resume-layout.cv-bold .sub-headline .svg-icon {
    display: none;
    margin-right: 10px;
  }
  .resume-layout.cv-bold .sub-headline .svg-icon {
    background-color: #848484;
    border: 4px solid #a1d1f0;
    border-radius: 100%;
    color: #ffefe3;
    height: 3em;
    width: 3em;
    line-height: normal;
    padding: 0.65rem;
    text-align: center;
    margin-left: 0;
  }
  .resume-layout.cv-bold .sub-headline .svg-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .resume-layout.cv-bold .info-item {
    display: block;
  }
  .resume-layout.cv-bold .info-item .svg-icon,
  .resume-layout.cv-bold .info-item .info-text {
    // display: inline-block;
    display: flex;
  }
  .resume-layout.cv-bold .info-item .svg-icon {
    color: #444;
    margin-top: 4px;
  }
  .resume-layout.cv-bold .info-item .info-label {
    display: none;
  }
  .resume-layout.cv-bold .wrap .left-col,
  .resume-layout.cv-bold .wrap .extra-info,
  .resume-layout.cv-bold .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv-bold .extra {
    display: block;
  }
  .resume-layout.cv-bold .extra .extra-details .progress-starts,
  .resume-layout.cv-bold .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv-bold .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv-bold
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #848484;
  }
  .resume-layout.cv-bold .xp-item {
    display: block;
  }
  .resume-layout.cv-bold .xp-item .xp-date,
  .resume-layout.cv-bold .xp-item .xp-job {
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv-bold .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv-bold .xp-item .xp-date {
    color: #848484;
  }
  @media (max-width: 767px) {
    .resume-layout.cv-bold .sub-headline .svg-icon svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: -4px;
      margin-top: -6px;
    }
  }
`;

export const CvBoldStyle = createGlobalStyle`
  ${CommonCSS}
  ${CvBoldCSS}
`;
