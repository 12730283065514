import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./component";
import { getUserObject, getUserDocumentAttributes } from "./actions";
import { partnerLogOut } from "../../pages/sign-in/actions";
import { getUserProfile } from "../../pages/resumes/actions";

const mapStateToProps = ({ funnelStep }) => ({
  funnelStep,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDocumentAttributesConnect: getUserDocumentAttributes,
      getUserObjectConnect: getUserObject,
      partnerLogOutConnect: partnerLogOut,
      getUserProfileConnect: getUserProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
