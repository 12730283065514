import logo from "./logo.svg";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.scss";
import { store, persistor } from "./store";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import RenderRoutes from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { getVisitorServiceUrl } from "./services/api-constants";
import $ from "jquery";
import { useEffect } from "react";
import { useGTM } from "./utils/helpers";

function AppContent() {
  const currentEnv = `${process.env.REACT_APP_ENVIRONMENT}`;
  const url = getVisitorServiceUrl("");
  const ssourl = "app/ssolegacy.js";
  const location = useLocation(); // Now works as it is inside BrowserRouter
  let referrer = document.referrer || ""; // Initial referrer
  window.$ = $;
  window.jQuery = $;
  useGTM();
  useEffect(() => {
    if (window.addDataTrackAttribute) {
      window.addDataTrackAttribute();
    }

    // Log a page view whenever the route changes
    if (window.logPageView) {
      window.logPageView({ referrer }); // Pass the tracked referrer
    }
    referrer = window.location.href;
    // Update the referrer for the next navigation
    // Attach global listeners for user interaction events
    if (window.attachListeners) {
      window.attachListeners();
    }
    if (window.startQueueProcessor) {
      window.startQueueProcessor();
    }
  }, [location.pathname]);
  return (
    <>
      <Helmet>
        <script src={url} type="text/javascript" />
      </Helmet>
      <RenderRoutes />
    </>
  );
}
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
export default App;
