import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/tips.scss";
import HeaderSteps from "../../components/header-steps";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import TipsContainer from "../../components/tips-container";
import { SECTION_TYPE } from "../../utils/constants";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import dompurify from "dompurify";
import { Helmet } from "react-helmet";
import { getPortalCD, readCookie } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { trackRBEvents } from "../../utils/tracking-events";
const ExperienceTips = ({ funnelStep }) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const { userGUID, email, personalInfoName } = funnelStep || {};
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const tempSkip = () => {
    navigate(pathRedirect.next);
  };
  let pathRedirect = PathHelper(APP_NAVIGATION.EXPT);

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  useEffect(() => {
    trackRBEvents("experienceTipsView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
  }, []);

  return (
    <>
      <HeaderSteps activeStep={2} />
      <Helmet>
        <title>{t("page_title.experience_tips")}</title>
      </Helmet>
      <section className="section main-content templates sidebar-sticky">
        <div className="container ">
          <div className="alignment-container">
            <div className="row">
              <div className="headline-group text-center heading-style">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("experience_tips.headline")),
                    }}
                  />
                </hgroup>
              </div>
            </div>
            <div className="row tip-content">
              <TipsContainer section={SECTION_TYPE.EXPR} resumemap="xp"></TipsContainer>
            </div>
            <div className="row ">
              <div className="box__footer box__footer--steps btn-container fbn btn-justify">
                <Button
                  isLoading={false}
                  classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                  id="experience_tip_back_btn"
                  ctaText={t("personal_info.back_action")}
                  onClick={backClickHandler}
                />

                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={tempSkip}
                  classNames="btn btn-primary btn-lg submit-on-enter"
                  id="experience_tip_next_btn"
                  ctaText={t("experience.next")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ExperienceTips;
