import Types from "./types";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";
import appLogger from "../../utils/app-logger";
const cancelUserByUserGuid = (data, userId) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.cancelUserByUserGuid(data, userId).then(
      (response) => {
        appLogger.log("inside cancelUserByUserGuid");
        return response;
      }
    );
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

export { cancelUserByUserGuid };
