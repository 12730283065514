import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_SKU_SUCCESS
    FETCH_SKU_FAILURE
    FETCH_ORDER_SUCCESS
    FETCH_ORDER_FAILURE
    QUACK
`,
  {
    prefix: "checkoutpage/",
  }
);
