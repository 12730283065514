import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SUCCESS
    FAILURE
    RESET_UPLOAD_RESUME
`,
  {
    prefix: "uploadresume/",
  }
);
