import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_EXPERIENCE_SUCCESS
    FETCH_EXPERIENCE_FAILURE
    UPDATE_EXP_SUCCESS
    UPDATE_EXP_FAILED
    QUACK
    DELETE_EXPERIENCE_SUCCESS
    RESET_EXPERIENCE
    RENAME_EXPERIENCE
`,
  {
    prefix: "experience/",
  }
);
