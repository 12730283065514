// import Types from "./types";

// const quack = () => ({
//     type: Types.QUACK
// });

// export {
//     quack
// };

import appLogger from "../../utils/app-logger";
import Types from "./types";
import PaymentServiceAPI from "../../services/payment-service-api";
import OrderServiceAPI from "../../services/order-service-api";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";
import UserServiceApi from "../../services/user-service-api";
import ABTestServiceApi from "../../services/abtest-service-api";
import PaymentService from "../../services/payment-service-api";
import DocBuilderService from "../../services/doc-builder-api";

const paymentProcess = (data) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.checkoutProcess(data)
      .then((response) => {
        if (!!response) {
          appLogger.log("inside checkoutProcess process");
          dispatch(fetchOrderSuccess(response));
        } else {
          dispatch(fetchOrderFailure(response));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
const paymentProcess3DS = (data) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.checkoutProcess3DS(data)
      .then((response) => {
        if (!!response) {
          dispatch(fetchOrderSuccess(response));
        } else {
          dispatch(fetchOrderFailure(response));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
const CreatePaymentIntent = (data) => {
  return function (dispatch) {
    return PaymentServiceAPI.CreatePaymentIntent(data)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
const getSkuDetails = (data, locale) => {
  return function (dispatch) {
    return PaymentServiceAPI.getSkuDetails(data, locale)
      .then((response) => {
        if (response) {
          //appLogger.log("inside getSkuDetails process");
          dispatch(fetchSkuSuccess(response));
        } else {
          dispatch(fetchSkuFailure(response));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const BadBinCards = (bin) => {
  return function (dispatch) {
    return PaymentServiceAPI.isCardInBadBins(bin)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return false;
        }
        return response;
      })
      .catch((error) => {
        alert("Test");
        return false;
        console.log("Error");
      });
  };
};

const orderStatus = (userGuid) => {
  return function (dispatch) {
    return OrderServiceAPI.getOrderDetailsByUserGuid(userGuid)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const getUserProfile = (userId) => {
  return function (dispatch) {
    return UserServiceApi.getUserProfile(userId).then((response) => {
      //appLogger.log("inside update docstyle");
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const abTestUserCheck = (data) => {
  return function (dispatch) {
    return ABTestServiceApi.UserConduct(data)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const getCaptchaScore = (greToken, portal) => {
  return function (dispatch) {
    return UserServiceApi.getCaptchaScore(greToken, portal)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error");
        return null;
      });
  };
};
const calculateGuestUserPricing = (skuGuid, currency, skuCategory, users) => {
  return function (dispatch) {
    return PaymentService.CalculatePricing(skuGuid, currency, skuCategory, users)
      .then((response) => {
        if (!!response) {
          //appLogger.log("inside getSkuDetails process");
          dispatch(fetchSkuSuccess(response));
        } else {
          dispatch(fetchSkuFailure(response));
        }
        return response;
      })
      .catch((error) => {
        return null;
      });
  };
};
const getGatewayABTest = (cardtoken, currency, portal) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.getGatewayAbtestResponse(cardtoken, currency, portal)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error");
      });
  };
};
const isCardInBadBins = (bin, portal, currencyCD, country) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.isCardInBadBins(bin, portal, currencyCD, country)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error");
      });
  };
};
const getAllDocumentOfUser = (userGuid) => {
  return function () {
    return DocBuilderService.getAllDocumentOfUser(userGuid)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
const getTaxEstimate = (stateCode, zipCode, planAmount) => {
  return function (dispatch) {
    return PaymentServiceAPI.getTaxEstimate(stateCode, zipCode, planAmount)
      .then((response) => {
        if (response) {
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const fetchSkuSuccess = (data) => ({
  type: Types.FETCH_SKU_SUCCESS,
  data,
});

const fetchSkuFailure = (error) => ({
  type: Types.FETCH_SKU_FAILURE,
  error,
});

const fetchOrderSuccess = (data) => ({
  type: Types.FETCH_ORDER_SUCCESS,
  data,
});

const fetchOrderFailure = (error) => ({
  type: Types.FETCH_ORDER_FAILURE,
  error,
});

export {
  paymentProcess,
  getSkuDetails,
  orderStatus,
  getUserProfile,
  BadBinCards,
  CreatePaymentIntent,
  paymentProcess3DS,
  abTestUserCheck,
  getCaptchaScore,
  calculateGuestUserPricing,
  getGatewayABTest,
  getAllDocumentOfUser,
  isCardInBadBins,
  getTaxEstimate,
};
