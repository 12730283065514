import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WriterSnippetExperience from "./component";
import { fetchIndustry, fetchSubIndustry, fetchMetaContent } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIndustryConnect: fetchIndustry,
      fetchSubIndustryConnect: fetchSubIndustry,
      fetchMetaContentConnect: fetchMetaContent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriterSnippetExperience);
