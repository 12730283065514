import React from "react";
import Button from "../../shared/button";
import PathHelper from "../../../utils/path-helper";
import { useNavigate } from "react-router-dom";
import { APP_NAVIGATION } from "../../../utils/constants";
const { REACT_APP_ENV } = process.env;
const Logo = ({ disabled }) => {
  let pathRedirect = PathHelper(APP_NAVIGATION.RHOM, false);
  // Nav
  return (
    <a href={pathRedirect.next}>
      <img
        className="rb-logo"
        src={process.env.PUBLIC_URL + "/static/images/resumebuild-logo.svg"}
        width="117"
        alt="Logo"
      />
    </a>
  );
};

export default Logo;
