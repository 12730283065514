import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResetPassword from "./component";
import { resetPassword } from "./actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			resetPasswordConnect: resetPassword,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
