import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SsoTransfer from "./component";
import { getUserObject, validateLgctToken } from "./actions";
import { resetStore } from "../review/actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserObjectConnect: getUserObject,
      resetStoreConnect: resetStore,
      validateLgctTokenConnect: validateLgctToken,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SsoTransfer);
