import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./user-settings.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text/component";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { validatePassword } from "../../validators/genericValidator";
import appLogger from "../../utils/app-logger";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { Helmet } from "react-helmet";
import { getPortalCD, gotToDashBoardWithLocale, PerquisiteBountyEnable } from "../../utils/helpers";

const UserSettings = ({
  updateUserSettingsConnect,
  funnelStep,
  checkout,
  getUserProfileConnect,
  getBillingInfoConnect,
  cancelUserConnect,
  getUserDocsConnect,
  restartSubscriptionConnect,
  getPSSSBillingInfoConnect,
  getUserCVs,
}) => {
  const { userGUID, email, parentUserGuid } = funnelStep || {};
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate();
  let pathRedirect = PathHelper(APP_NAVIGATION.REVW);
  let pathRedirectToResume = PathHelper(APP_NAVIGATION.CONF);
  let pathRedirectToCoverLetter = PathHelper(APP_NAVIGATION.CVLTS);
  let pathRedirectSettings = PathHelper(APP_NAVIGATION.STNG);
  let pathRedirectHome = PathHelper(APP_NAVIGATION.HOME);
  let pathRedirectCoverLetterHome = PathHelper(APP_NAVIGATION.CVLINTRO);
  const { locale } = useParams();
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [pwdErrorMsg, setPwdErrorMsg] = useState("");
  const [billingInfo, setBillingInfo] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [nextBillDate, setNextBillDate] = useState("");
  const [lastBillDate, setLastBillDate] = useState("");
  const [activeUntil, setActiveUntil] = useState("");
  const [showEditInfo, setshowEditInfo] = useState(false);
  const [showReturnToResumeBtn, setShowReturnToResumeBtn] = useState(true);
  const [showLoader, setShowLoader] = useState("d-none");
  const onfullNameChange = (e) => {
    const {
      target: { value },
    } = e;
    setFullName(value);
    setShowReturnToResumeBtn(false);
    setshowEditInfo(true);
  };

  const onpasswordChange = (e) => {
    setPwdError(false);
    const {
      target: { value },
    } = e;
    setShowReturnToResumeBtn(false);
    setshowEditInfo(true);
    setPassword(value);
  };
  const onconfirmPasswordChange = (e) => {
    setPwdError(false);
    const {
      target: { value },
    } = e;
    setShowReturnToResumeBtn(false);
    setshowEditInfo(true);
    setConfirmPassword(value);
  };
  const userGuid = userGUID;
  const returnToResumeHandler = () => {
    getUserDocsConnect(userGUID).then((response) => {
      if (!!response.documents && response.documents.length > 0) {
        navigate(pathRedirectToResume.next);
      } else {
        navigate(pathRedirectHome.next);
      }
    });
  };

  const returnToCoverLetterHandler = () => {
    getUserCVs(userGUID).then((response) => {
      if (!!response.documents && response.documents.length > 0) {
        navigate(pathRedirectToCoverLetter.next);
      } else {
        navigate(pathRedirectCoverLetterHome.next);
      }
    });
  };
  const saveClickHandler = (e) => {
    appLogger.log("saveClickHandlerForChangepasswordCalled");
    const isValid = validateData();
    if (isValid) {
      var data = {
        userGuid: userGuid,
        newPassword: password,
      };

      updateUserSettingsConnect(data)
        .then((response) => {
          appLogger.log("inside updateUserSettingsConnect then ");
          setActive(!isActive);
          setshowEditInfo(false);
          setShowReturnToResumeBtn(true);
          setConfirmPassword("");
          setPassword("");
          // navigate(ROUTE_CONSTANT.USER_ADMIN_SETTINGS);
          const {
            data: {
              detail: { data },
            },
          } = response;
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    }
  };
  const validateData = () => {
    let isValid = true;
    if (isActive !== "false") {
      if (password === "" && confirmPassword === "") {
        setShowReturnToResumeBtn(true);
        setshowEditInfo(false);
        handleToggle();
        return false;
      }
      setPwdError(false);
      let passwordValidator = validatePassword(password);
      // let confirmPasswordValidator = validatePassword(confirmPassword);
      // if (
      //   passwordValidator.isValid === false ||
      //   confirmPasswordValidator.isValid == false
      // ) {
      setPwdError(true);
      if (!password) {
        setPwdErrorMsg(t("generic_error.enter_password"));
        isValid = false;
      }
      if (!confirmPassword) {
        setPwdErrorMsg(t("generic_error.confirm_password"));
        isValid = false;
      }
      if (password !== "" && confirmPassword !== "") {
        if (password != confirmPassword) {
          setPwdErrorMsg(t("generic_error.unmatch_password"));
          isValid = false;
        } else if (password === confirmPassword && password.length < 6 && confirmPassword.length < 6) {
          setPwdErrorMsg(t("generic_error.password_length"));
          isValid = false;
        }
      }
      //}
    }
    if (isValid) {
      setPwdErrorMsg("");
    }
    return isValid;
  };
  const [key, setKey] = useState("Overview");
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const returnFormattedDate = (dateValue) => {
    let dateObj = new Date(dateValue);
    let localeValue = "en-US";
    if (!!locale) {
      localeValue = locale;
    }
    return dateObj.toLocaleDateString(localeValue, options);
  };
  const handleSelect = (select) => {
    if (select == "Overview") {
      setKey("Overview");
    }
    if (select == "Subscription") {
      setShowLoader("d-block");
      getUserProfileConnect(userGUID)
        .then((response) => {
          if (
            !!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGUID) == true || !!parentUserGuid)
          ) {
            let localeValue = "en";
            if (!!locale) {
              localeValue = locale;
            }
            let myObjLower;

            getBillingInfoConnect(userGUID, localeValue)
              .then((response) => {
                if (!!response) {
                  myObjLower = lowercaseKeys(response);
                  setBillingDeatils(myObjLower);
                }
              })
              .catch((err) => {
                const { detail } = err;
              });
          } else {
            navigate(pathRedirect.next);
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };

  const setBillingDeatils = (obj) => {
    setBillingInfo(obj);
    setPurchaseDate(returnFormattedDate(obj.purchasedate));
    setNextBillDate(returnFormattedDate(obj.nextbillingdate));
    setLastBillDate(returnFormattedDate(obj.lastbillingdate));
    setActiveUntil(returnFormattedDate(obj.cancelledat));
    setKey("Subscription");
    setShowLoader("d-none");
  };

  const lowercaseKeys = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});

  const cancelChangsesHanlder = () => {
    setFullName("");
    setShowReturnToResumeBtn(true);
    setshowEditInfo(false);
    setActive(!isActive);
    setConfirmPassword("");
    setPassword("");
  };

  const onRestartSubscriptionHandler = () => {
    setShowLoader("d-block");
    let data = {
      isCancelled: false,
    };
    restartSubscriptionConnect(data, userGUID)
      .then((response) => {
        setShowLoader("d-none");
        setKey("Overview");
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };

  const onCancelSubscriptionHandler = () => {
    navigate(pathRedirectSettings.back);
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.user_setting")}</title>
      </Helmet>
      <Header />
      <ReactLoader showloader={showLoader} />
      <section className="main-content user-settings">
        <div className="container">
          <div className="alignment-container">
            <hgroup className="mb-4 setting-page-headline">
              <h1
                dangerouslySetInnerHTML={{
                  __html: sanitizer(t("user_settings.headline")),
                }}
              />
            </hgroup>

            <Tabs
              defaultActiveKey="Overview"
              id="uncontrolled-tab-example"
              className="mb-4 "
              //onSelect={handleSelect}
              activeKey={key}
              onSelect={(k) => handleSelect(k)}
            >
              <Tab eventKey="Overview" title={t("user_settings.tab_overview")} className="tab-overview ">
                <Row>
                  <Col className="col-md-8 col-lg-7">
                    <h2 className="pt-5">{t("user_settings.tab_first_sub_headline")}</h2>
                    <Form.Group className="mb-3 fullname-icon " controlId="fullName">
                      <InputText
                        showLabel={true}
                        id="fullName"
                        text={t("user_settings.name_label")}
                        type="text"
                        required
                        onChange={onfullNameChange}
                        value={fullName}
                        placeholder={t("user_settings.name_placeholder")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 email-icon" controlId="userEmail">
                      <InputText
                        showLabel={true}
                        id="email"
                        text={t("user_settings.email_label")}
                        type="email"
                        value={email}
                        name="email"
                        placeholder={t("user_settings.email_placeholder")}
                        readonly={true}
                      />
                    </Form.Group>
                    <div id="changePass" className={isActive ? "section-hide" : null}>
                      <button className="toggle-btn" onClick={handleToggle}>
                        {t("user_settings.change_password")}
                      </button>
                      <div className="change-password ">
                        <Form.Group className="mb-3 password-icon">
                          <InputText
                            showLabel={true}
                            id="Password"
                            text={t("user_settings.password_label")}
                            type="password"
                            required
                            value={password}
                            onChange={onpasswordChange}
                            placeholder={t("user_settings.password_placeholder")}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 password-icon">
                          <InputText
                            showLabel={true}
                            id="confirmPassword"
                            text={t("user_settings.confirm_password_label")}
                            type="password"
                            onChange={onconfirmPasswordChange}
                            value={confirmPassword}
                            placeholder={t("user_settings.confirm_password_placeholder")}
                          />
                        </Form.Group>
                        <div className="form-text">
                          <small key="pswrdErr" className="form-text text-danger">
                            {pwdErrorMsg}
                          </small>
                        </div>
                      </div>
                    </div>
                    <Form.Group className="tab-footer" controlId="userEmail">
                      {showReturnToResumeBtn && (
                        <div className="fbn">
                          <Button
                            isLoading={false}
                            disabled={false}
                            classNames="btn btn-primary btn-lg me-5 mb-3"
                            onClick={returnToResumeHandler}
                            ctaText={t("user_settings.mainCTA")}
                          />
                          <Button
                            isLoading={false}
                            disabled={false}
                            classNames="btn btn-primary btn-lg mb-3"
                            onClick={returnToCoverLetterHandler}
                            ctaText={t("user_settings.return_cvltr")}
                          />
                        </div>
                      )}

                      {showEditInfo && (
                        <div className="user-setting-edit-btn row">
                          <div className="col-sm-6">
                            <Button
                              isLoading={false}
                              disabled={false}
                              classNames="btn btn-primary btn-lg is-block"
                              onClick={cancelChangsesHanlder}
                              ctaText={t("user_settings.cancel")}
                            />
                          </div>
                          <div className="col-sm-6 fbn">
                            <Button
                              isLoading={false}
                              disabled={false}
                              classNames="btn btn-primary btn-lg is-block"
                              onClick={saveClickHandler}
                              ctaText={t("user_settings.save_changes")}
                            />
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="Subscription" title={t("user_settings.tab_subscription")} className="tab-subscription ">
                <Row>
                  <Col className="col-12 col-md-6 col-sm-12">
                    <h2 className="pt-5">{t("user_settings.tab_second_sub_headline")}</h2>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <dl className="row">
                          <dd className="col-md-4">{t("user_settings.product_name")}</dd>
                          <dt className="col-md-8">WorkSimpli's Resume Build</dt>
                        </dl>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <dl className="row">
                          <dd className="col-md-4">{t("user_settings.plan_key")}</dd>
                          <dt className="col-md-8">
                            {console.log("billingInfo", billingInfo)}
                            {billingInfo.nextbillingdate == "0001-01-01T00:00:00"
                              ? t("user_settings.canceled")
                              : t("user_settings.active")}
                          </dt>
                        </dl>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <dl className="row">
                          <dd className="col-md-4">{t("user_settings.purchase_date")}</dd>
                          <dt className="col-md-8">{purchaseDate}</dt>
                        </dl>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <dl className="row">
                          <dd className="col-md-4">{t("user_settings.last_billed")}</dd>
                          <dt className="col-md-8">{lastBillDate}</dt>
                        </dl>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <dl className="row">
                          <dd className={billingInfo.nextbillingdate == "0001-01-01T00:00:00" ? "d-none" : "col-md-4"}>
                            {t("user_settings.next_bill")}
                          </dd>
                          <dt className={billingInfo.nextbillingdate == "0001-01-01T00:00:00" ? "d-none" : "col-md-8"}>
                            {nextBillDate}
                          </dt>
                          <dd className={billingInfo.nextbillingdate == "0001-01-01T00:00:00" ? "col-md-4" : "d-none"}>
                            {t("user_settings.active_until")}
                          </dd>
                          <dt className={billingInfo.nextbillingdate == "0001-01-01T00:00:00" ? "col-md-8" : "d-none"}>
                            {activeUntil}
                          </dt>
                        </dl>
                      </ListGroup.Item>
                    </ListGroup>
                    <p className="small my-3 pb-3">
                      {/* <button
                        onClick={onCancelSubscriptionHandler}
                        className={
                          billingInfo.nextBillingDate == "0001-01-01T00:00:00"
                            ? "d-none"
                            : "btn btn-link cancel-subscription"
                        }
                      >
                        {t("user_settings.cancel_subscription")}
                      </button> */}
                      <div className="fbn">
                        <Button
                          isLoading={false}
                          disabled={false}
                          classNames="btn btn-primary btn-lg"
                          onClick={returnToResumeHandler}
                          ctaText={t("user_settings.mainCTA")}
                        />
                      </div>
                    </p>
                  </Col>
                  <Col className="col-md-6 col-sm-12 plan-container">
                    <h5>{t("user_settings.plan_heading")}</h5>
                    <div className="plan-cancel-container">
                      <h5>{t("user_settings.plan_sub_heading")}</h5>
                      <p>
                        {billingInfo.plandescription}
                        {/* Unlimited printing and downloading for 14 days.After 14
                        days, auto-renews $1.95 billed every 4 weeks. */}
                      </p>

                      <button
                        className={
                          billingInfo.nextbillingdate == "0001-01-01T00:00:00" && !!billingInfo.statusinchargebee
                            ? "d-none"
                            : "btn btn-primary btn--large submit-on-enter plan-buynow-btn"
                        }
                        onClick={onCancelSubscriptionHandler}
                      >
                        <strong>{t("user_settings.plan_cancel")}</strong>
                      </button>
                      <button
                        className={
                          billingInfo.nextbillingdate == "0001-01-01T00:00:00" &&
                          !!billingInfo.statusinchargebee == true
                            ? "btn btn-primary btn--large submit-on-enter"
                            : "d-none"
                        }
                        onClick={onRestartSubscriptionHandler}
                      >
                        <strong>{t("user_settings.restart_plan")}</strong>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserSettings;
