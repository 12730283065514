import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
const reactPlugin = new ReactPlugin();
const appEnv = process.env.REACT_APP_ENVIRONMENT;

const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      appEnv == "PRODUCTION"
        ? "f75f93eb-7fd7-48ee-8a0b-5b813c00abe3"
        : "867e087f-a9fb-4343-8f66-ba927b1a1ff8",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: window.location.pathname },
    },
  },
});
ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
