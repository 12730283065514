import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SessionExpire from "./component";
import { resetStore } from "../review/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetStoreConnect: resetStore }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpire);
