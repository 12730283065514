import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";

const CoverLetterExperienceSlider = ({ getExperience, Experience }) => {
  const [value, setValue] = React.useState(Experience * 10);
  const onSliderOnChangeHandler = (e) => {
    const newValue = parseInt(e.target.value);

    setValue(newValue);
    localStorage.setItem("rangeSliderValue", newValue);
    getExperience(newValue / 10);
  };

  const onNumberClickHandler = (num) => {
    const newValue = num * 10;
    setValue(newValue);
    localStorage.setItem("rangeSliderValue", newValue);
    getExperience(num);
  };

  return (
    <>
      <div className="">
        <Form className="cvltr-steps">
          <Form.Group as={Row}>
            <Col lg="10">
              <RangeSlider
                className="mx-auto"
                value={value}
                onChange={onSliderOnChangeHandler}
                tooltipLabel={(currentValue) =>
                  currentValue < 20 ? (
                    <span>
                      {currentValue / 10}
                      <span className="cvltr-year-text">year</span>
                    </span>
                  ) : (
                    <span>
                      {currentValue / 10}
                      <span className="cvltr-year-text">years</span>
                    </span>
                  )
                }
                step={10}
                tooltipPlacement="top"
                tooltip="on"
              />
              <div className="d-flex justify-content-between align-items-center mt-4">
                {[...Array(11).keys()].map((num) => (
                  <p
                    key={num}
                    className={`mt-1 cvltr-step ${value / 10 === num && "cvltr-activestep"}`}
                    onClick={() => onNumberClickHandler(num)}
                    style={{ cursor: "pointer" }}
                  >
                    {num === 10 ? "10+" : num}
                  </p>
                ))}
              </div>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default CoverLetterExperienceSlider;
