import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_CUSTOM_SUCCESS
    FETCH_CUSTOM_FAILURE
    UPDATE_CUSTOM_SUCCESS
    UPDATE_CUSTOM_FAILED
    QUACK
    RESET_CUSTOM_SECTION
    RENAME_CUSTOM_SECTION
`,
  {
    prefix: "custom-section/",
  }
);
