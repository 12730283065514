import React, { useEffect, useState } from "react";
import Logo from "./navbar/logo";
import TopNav from "./navbar/top-nav";
import TopBtnGroup from "./navbar/btns-group";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, TYPE_OF_DOC } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../scroll-to-top/component";
import { readCookie, createCookie } from "../../utils/helpers";
import { ProcessLogout } from "../../utils/process-logout";

const Header = ({
  getUserDocumentAttributesConnect,
  funnelStep,
  isShow,
  isLogoDisabled,
  getUserObjectConnect,
  partnerLogOutConnect,
}) => {
  const navigate = useNavigate();
  const { userGUID, parentUserGuid, docType } = funnelStep || {};
  let pathRedirectResumes = PathHelper(APP_NAVIGATION.RSMS);
  let pathRedirectCoverLetters = PathHelper(APP_NAVIGATION.CVLTS);
  let pathRedirectCoverLetterIntro = PathHelper(APP_NAVIGATION.CVLINTRO);
  let pathRedirectSettings = PathHelper(APP_NAVIGATION.STNG);
  let pathRedirectSignOutOrHome = PathHelper(APP_NAVIGATION.SIOT);
  const { locale } = useParams();
  const topBtnGroupCallback = (fieldValue) => {
    if (fieldValue == "ddResumes") {
      getUserDocumentAttributesConnect(userGUID, TYPE_OF_DOC.RESUME).then((response) => {
        if (!!response && response.docCount > 0) {
          navigate(pathRedirectResumes.next);
        } else {
          navigate(pathRedirectSignOutOrHome.next);
        }
      });
    }

    if (fieldValue === "ddCoverLetters") {
      getUserDocumentAttributesConnect(userGUID, TYPE_OF_DOC.COVER_LETTER).then((response) => {
        if (!!response && response.docCount > 0) {
          navigate(pathRedirectCoverLetters.next);
        } else {
          navigate(pathRedirectCoverLetterIntro.next);
        }
      });
    }

    if (fieldValue == "ddSettings") {
      navigate(pathRedirectSettings.next);
    }
    if (fieldValue == "ddSignout") {
      partnerLogOutConnect();
      ProcessLogout("", "", "", locale);
    }
  };
  useEffect(() => {
    const jwtToken = readCookie("jwtToken");
    const ssoAuth = readCookie("ssoauth");
    if (!!jwtToken) {
      getUserObjectConnect().then((response) => {
        if (!!response) {
          createCookie("ssoauth", "1");
        } else {
          partnerLogOutConnect();
          ProcessLogout("", "", "", locale);
        }
      });
    }
  }, []);
  return (
    <>
      <ScrollToTop />
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className={
              window.location.pathname.split("/").pop() === "setting" ? "container borderBottomRmv" : "container"
            }
          >
            <Logo disabled={isLogoDisabled} />
            <div id="navbarNavAltMarkup">
              <TopNav />
              <TopBtnGroup callback={topBtnGroupCallback} isShow={isShow} funnelStep={funnelStep} />
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
