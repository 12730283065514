import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { renameCoverLetter, getUserProfile } from "./actions";
import { setDocGUID } from "../funnel-step/actions";
import CoverLetters from "./component";
import { setDocType } from "./../funnel-step/actions";
import { getAbridgedUserDocs } from "../resumes/actions";
import { getDocument } from "../resume-review/actions";

const mapStateToProps = ({ funnelStep }) => ({
  funnelStep,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDocGuidConnect: setDocGUID,
      renameCoverLetterConnect: renameCoverLetter,
      getUserProfileConnect: getUserProfile,
      setDocTypeConnect: setDocType,
      getAbridgedUserDocsConnect: getAbridgedUserDocs,
      getDocumentConnect: getDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetters);
