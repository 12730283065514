import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserSettings from "./component";
import {
  updatePassword,
  getUserProfile,
  getBillingInfo,
  cancelUserByUserGuid,
  getUserDocs,
  restartSubscription,
  getPSSSBillingInfo,
} from "./actions";
import { getUserCVs } from "../../components/header/actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserSettingsConnect: updatePassword,
      getUserProfileConnect: getUserProfile,
      getBillingInfoConnect: getBillingInfo,
      cancelUserConnect: cancelUserByUserGuid,
      getUserDocsConnect: getUserDocs,
      restartSubscriptionConnect: restartSubscription,
      getPSSSBillingInfoConnect: getPSSSBillingInfo,
      getUserCVs: getUserCVs
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
