import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_TEMPLATES_SUCCESS
    FETCH_TEMPLATES_FAILURE
    RESET_CHOOSE_TEMPLATE
    SAVE_CHOOSE_TEMPLATE
`,
  {
    prefix: "CHOOSE_TEMPLATES/",
  }
);
