import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_CODE, SECTION_TYPE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  Paragraphs: [emptyParagraph(SECTION_CODE.OTHR, SECTION_TYPE.OTHR)],
  error: null,
  total: 0,
};
export const updateOtherSectionParagraph = (
  state = INITIAL_STATE,
  headerdata
) => {
  const { data } = headerdata;
  return {
    ...state,
    Paragraphs: Object.values(
      [...state.Paragraphs, data].reduce((result, { id, ...rest }) => {
        result[id] = {
          ...(result[id] || {}),
          id,
          ...rest,
        };
        return result;
      }, {})
    ),
    isLoading: false,
    error: null,
  };
};

export const fetchOtherSectionSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    Paragraphs: Object.values(
      [...state.Paragraphs, ...data].reduce((result, { id, ...rest }) => {
        result[id] = {
          ...(result[id] || {}),
          id,
          ...rest,
        };
        return result;
      }, {})
    ),
    isLoading: false,
    error: null,
  };
};

export const fetchOtherSectionFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};
export const resetOtherSection = () => {
  return { ...INITIAL_STATE };
};
export const renameOtherSection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};
export const HANDLERS = {
  [Types.FETCH_OTHER_SECTION_SUCCESS]: fetchOtherSectionSuccess,
  [Types.FETCH_OTHER_SECTION_FAILURE]: fetchOtherSectionFailure,
  [Types.UPDATE_OTHER_SUCCESS]: updateOtherSectionParagraph,
  [Types.RESET_OTHER_SECTION]: resetOtherSection,
  [Types.RENAME_OTHER_SECTION]: renameOtherSection,
};

export default createReducer(INITIAL_STATE, HANDLERS);
