import Types from "./types";

const quack = () => ({
  type: Types.QUACK,
});

const updateProgress = (data) => ({
  type: Types.PROGRESS,
  data,
});

const resetSidebarProgress = () => ({
  type: Types.RESET_SIDEBAR_PROGRESS,
});

export { quack, updateProgress, resetSidebarProgress };
