import React, { useEffect, useState, useCallback } from "react";
import RBDropDown from "../shared/dropdown/component";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text";
import InputRange from "../../components/shared/input-range";
import { useTranslation } from "react-i18next";
import "./resume-tools.scss";
import { getFontFamilyList } from "../../services/master-data";
import { getTemplateList } from "../../services/master-data";

const ResumeTools = ({ callback, docReview, selectedTemplate, fontFamily }) => {
  const { t } = useTranslation();
  const [fontFamilyList, setfontFamilyList] = useState(getFontFamilyList(t));
  const [fontToolBoxfamily, setToolBoxFontFamily] = useState("");
  const [fontToolBoxsize, setToolBoxFontSize] = useState("");
  const [headingToolBoxfontsize, setToolBoxHeadingFontSize] = useState("");
  const [resetResumeToolValue, setResetResumeToolValue] = useState(getTemplateList().data);
  const minfontsize = 0.85;
  const maxfontsize = 1.25;
  const minHeadingSize = 1.25;
  const maxHeadingSize = 1.85;
  const sliderSizeStep = 0.05;
  const resetToolsHandler = () => {
    // setToolBoxFontSize(resetToolValue.resetFontSize);
    // setToolBoxHeadingFontSize(resetToolValue.resetHeadingFontSize);
    //setToolBoxFontFamily(resetToolValue.resetFontFamily);
    setToolBoxFontSize("");
    setToolBoxHeadingFontSize("");
    setToolBoxFontFamily("");
    let data = {
      fontFamily: "",
      fontSize: "",
      headingFontSize: "",
    };
    callback("", "reset", data, true);
  };
  const changeFontHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setToolBoxFontFamily(value);
    callback(value, "fontFamily", null, true);
  };
  const fontSizeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setToolBoxFontSize(value);
    callback(value, "fontSize", null, true);
  };
  const fontHeadingSizeHandler = (e) => {
    const {
      target: { value },
    } = e;
    setToolBoxHeadingFontSize(value);
    callback(value, "headingFontSize", null, true);
  };
  // useEffect(() => {
  //   const stateDocStyle =
  //     !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle ? docReview.reviewDoc.docStyle : {};

  //   setToolBoxFontFamily(!!stateDocStyle && !!stateDocStyle.fontFamily ? stateDocStyle.fontFamily : "");
  //   callback(!!stateDocStyle && !!stateDocStyle.fontFamily ? stateDocStyle.fontFamily : "", "fontFamily", null, false);
  //   setToolBoxFontSize(!!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : "");
  //   callback(!!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : "", "fontSize", null, false);
  //   setToolBoxHeadingFontSize(!!stateDocStyle && !!stateDocStyle.headingSize ? stateDocStyle.headingSize : "");
  //   callback(
  //     !!stateDocStyle && !!stateDocStyle.headingSize ? stateDocStyle.headingSize : "",
  //     "headingFontSize",
  //     null,
  //     false
  //   );
  // }, []);
  useEffect(() => {
    const stateDocStyle =
      !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle ? docReview.reviewDoc.docStyle : {};

    setToolBoxFontFamily(!!stateDocStyle && !!stateDocStyle.fontFamily ? stateDocStyle.fontFamily : "");
    callback(!!stateDocStyle && !!stateDocStyle.fontFamily ? stateDocStyle.fontFamily : "", "fontFamily", null, false);
    setToolBoxFontSize(!!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : "");
    callback(!!stateDocStyle && !!stateDocStyle.fontSize ? stateDocStyle.fontSize : "", "fontSize", null, false);
    setToolBoxHeadingFontSize(!!stateDocStyle && !!stateDocStyle.headingSize ? stateDocStyle.headingSize : "");
    callback(
      !!stateDocStyle && !!stateDocStyle.headingSize ? stateDocStyle.headingSize : "",
      "headingFontSize",
      null,
      false
    );

    if (!!docReview && !!docReview.reviewDoc && docReview.reviewDoc.docStyle.fontFamily == null) {
      if (!docReview.reviewDoc.templateCD) {
        docReview.reviewDoc.templateCD = "Classic";
      }
      let fontFamilyTemplate = resetResumeToolValue.find(
        (x) => x.templatecd === docReview.reviewDoc.templateCD
      )?.resetFontFamily;
      setToolBoxFontFamily(fontFamilyTemplate);
      callback(fontFamilyTemplate, null, null, false);
    }
  }, [fontFamily]);

  return (
    <>
      <div className="resume-sidebox__pill resume-sidebox__button-group pt-5">
        <Button
          id="resume-edit-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-rotate-left"></i>}
          onClick={resetToolsHandler}
          classNames="btn btn-muted reset-btn"
          ctaText={t("review.reset")}
        />
      </div>
      <div className="form-group select optional resume_font_family">
        <label className="select optional" htmlFor="resume_font_family">
          {t("review.font_family")}
        </label>
        <RBDropDown
          selectedValue={fontToolBoxfamily}
          id="select-font-family"
          options={fontFamilyList.data}
          onChange={changeFontHandler}
        />
      </div>
      <div className="form-group range optional resume_font_size resume-sidebox__pill">
        <label className="range optional" htmlFor="resume_font_size">
          {t("review.font_size")}
        </label>
        <div className="slidecontainer">
          <InputRange
            showLabel={false}
            id="resume_font_size"
            name="resume[font_size]"
            min={minfontsize}
            max={maxfontsize}
            step={sliderSizeStep}
            type="range"
            className="slider"
            onChange={fontSizeHandler}
            value={fontToolBoxsize}
          />
        </div>
      </div>
      <div className="form-group resume_heading_size resume-sidebox__pill">
        <label className="range optional" htmlFor="resume_heading_size">
          {t("review.heading_size")}
        </label>
        <div className="slidecontainer">
          <InputRange
            showLabel={false}
            id="resume_font_size"
            name="resume[heading_size]"
            min={minHeadingSize}
            max={maxHeadingSize}
            step={sliderSizeStep}
            type="range"
            className="slider"
            onChange={fontHeadingSizeHandler}
            value={headingToolBoxfontsize}
          />
        </div>
      </div>
    </>
  );
};

export default ResumeTools;
