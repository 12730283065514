import appLogger from "../../utils/app-logger";
import Types from "./types";
import PaymentServiceAPI from "../../services/payment-service-api";
import OrderServiceAPI from "../../services/order-service-api";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";
import UserServiceApi from "../../services/user-service-api";

const paymentProcess = (data) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.checkoutProcess(data)
      .then((response) => {
        if (!!response) {
          // appLogger.log("inside checkoutProcess process");
          dispatch(fetchOrderSuccess(response));
        } else {
          dispatch(fetchOrderFailure(response));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const getSkuDetails = (data, locale) => {
  return function (dispatch) {
    return PaymentServiceAPI.getSkuDetails(data, locale)
      .then((response) => {
        if (response) {
          // appLogger.log("inside getSkuDetails process");
          dispatch(fetchSkuSuccess(response));
        } else {
          dispatch(fetchSkuFailure(response));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const orderStatus = (userGuid) => {
  return function (dispatch) {
    return OrderServiceAPI.getOrderDetailsByUserGuid(userGuid)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};

const getUserProfile = (userId) => {
  return function (dispatch) {
    return UserServiceApi.getUserProfile(userId).then((response) => {
      // appLogger.log("inside update docstyle");
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const fetchSkuSuccess = (data) => ({
  type: Types.FETCH_SKU_SUCCESS,
  data,
});

const fetchSkuFailure = (error) => ({
  type: Types.FETCH_SKU_FAILURE,
  error,
});

const fetchOrderSuccess = (data) => ({
  type: Types.FETCH_ORDER_SUCCESS,
  data,
});

const fetchOrderFailure = (error) => ({
  type: Types.FETCH_ORDER_FAILURE,
  error,
});

export { paymentProcess, getSkuDetails, orderStatus, getUserProfile };
