import { combineReducers } from "redux";
import {
  saveIntroduction,
  updateIntroduction,
} from "./pages/introduction/reducers";
import skillsReducer from "./pages/skill/reducers";
import customSectionReducer from "./pages/custom-section/reducers";
import ExperienceReducer from "./pages/experience/reducers";
import FunnelStepReducer from "./pages/funnel-step/reducers";
import chooseTemplateReducer from "./pages/choose-templates/reducers";
import languagesReducer from "./pages/language/reducers";
import summaryReducer from "./pages/summary/reducers";
import PersonalInfoReducer from "./pages/personal-info/reducers";
import docReviewReducer from "./pages/review/reducers";
import otherSectionReducer from "./pages/other-section/reducers";
import EducationReducer from "./pages/education/reducers";
import CustomSectionReducer from "./pages/custom-section/reducers";
import ResumesReducer from "./pages/resumes/reducers";
import CheckoutPageReducer from "./pages/checkout-page/reducers";
import checkoutReducer from "./pages/checkout/reducers";
import uploadResumeReducer from "./pages/upload-resume/reducers";
import cvltrJobtitleReducer from "./pages/cover-letter-job-title/reducers";
import cvltrDesiredCompanyReducer from "./pages/cover-letter-desired-company/reducers";
import cvltrExperienceReducer from "./pages/cover-letter-experience/reducers";
import cvltrSkillsReducer from "./pages/cover-letter-skills/reducers";
import cvltrRecommendReducer from "./pages/cover-letter-recommend/reducers";
import cvltrBodyRecommendReducer from "./pages/cover-letter-body-recommend/reducers";
import cvltrCloserRecommendReducer from "./pages/cover-letter-closer-recommend/reducers";
import cvltrSignatureReducer from "./pages/cover-letter-signature/reducers";
import cvltrSidebarReducer from "./components/cover-letter-sidebar/reducers";
import cvltrTemplatePreviewReducer from "./pages/cover-letter-template/reducers";
import imageUploadReducer from "./components/image-upload-modal/reducers";

export default combineReducers({
  updateIntroductionReducer: updateIntroduction,
  saveIntroductionReducer: saveIntroduction,
  skills: skillsReducer,
  customSections: customSectionReducer,
  experience: ExperienceReducer,
  funnelStep: FunnelStepReducer,
  chooseTemplate: chooseTemplateReducer,
  languages: languagesReducer,
  summary: summaryReducer,
  personalInfo: PersonalInfoReducer,
  docReview: docReviewReducer,
  othersection: otherSectionReducer,
  education: EducationReducer,
  customsection: CustomSectionReducer,
  resumes: ResumesReducer,
  checkout: CheckoutPageReducer,
  ccpage: checkoutReducer,
  uploadResume: uploadResumeReducer,
  jobtitle: cvltrJobtitleReducer,
  desiredCompany: cvltrDesiredCompanyReducer,
  cvltrExperience: cvltrExperienceReducer,
  cvltrSkills: cvltrSkillsReducer,
  cvltrRecommend: cvltrRecommendReducer,
  cvltrBodyRecommend: cvltrBodyRecommendReducer,
  cvltrCloserRecommend: cvltrCloserRecommendReducer,
  cvltrSignature: cvltrSignatureReducer,
  cvltrSidebar: cvltrSidebarReducer,
  cvltrTemplatePreview: cvltrTemplatePreviewReducer,
  imageUploadPreview: imageUploadReducer,
});
