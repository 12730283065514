import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_EDUCATION_SUCCESS
    FETCH_EDUCATION_FAILURE
    UPDATE_EDU_FAILED
    UPDATE_EDU_SUCCESS
    RESET_EDUCATION
    RENAME_EDUCATION
`,
  {
    prefix: "education/",
  }
);
