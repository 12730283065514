import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_CODE, SECTION_TYPE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  Paragraphs: emptyParagraph(SECTION_CODE.SUMM, SECTION_TYPE.SUMM), //sectionType :  SUMM = 20
  error: null,
  total: 0,
};

export const fetchSummarySuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    Paragraphs: data,
    isLoading: false,
    error: null,
  };
};
export const updateSummaryParagraph = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data, isLoading: false, error: null };
};
export const fetchSummaryFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};
export const resetSummary = () => {
  return { ...INITIAL_STATE };
};
export const renameSummarySection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};
export const HANDLERS = {
  [Types.FETCH_SUMMARY_SUCCESS]: fetchSummarySuccess,
  [Types.FETCH_SUMMARY_FAILURE]: fetchSummaryFailure,
  [Types.RESET_SUMMARY]: resetSummary,
  [Types.UPDATE_SUMMARY_SUCCESS]: updateSummaryParagraph,
  [Types.RENAME_SUMMARY]: renameSummarySection,
};

export default createReducer(INITIAL_STATE, HANDLERS);
