import { createTypes } from "reduxsauce";

export default createTypes(
  `
    QUACK
    PROGRESS
    RESET_SIDEBAR_PROGRESS
`,
  {
    prefix: "SIDEBAR/",
  }
);
