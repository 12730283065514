import React, { useEffect, useState, useCallback, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../components/shared/button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./image-upload.scss";
import { useTranslation } from "react-i18next";
import { generateUinqueIDentifier } from "../../utils/helpers";
import { ImageType, ImageFormat } from "../../utils/constants";

const ImageUploadModal = ({
  imageUploadModalHide,
  imageUpload,
  saveImageAndGetBlobUrlConnect,
  setImageUrlConnect,
  setImageloader,
  imageUploadPreview,
  setprofilePicUrl,
}) => {
  const { t } = useTranslation();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [selectedImgName, setSelectedImgName] = useState(false);
  const [error, setError] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 100,
    zoom: 1,
    height: 100,
    aspect: 1 / 1,
  });
  const previewCanvasRef = useRef(null);
  const [showLoader, setShowLoader] = useState("d-none");
  const [imageExt, setImageExt] = useState("");
  const { imageUrl } = imageUploadPreview;

  const onSelectFile = (e) => {
    const arr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
    const fileExtension = e.target.files[0].name.split(".").pop().toLowerCase();
    if (arr.includes(e.target.files[0].type)) {
      if (e.target.files && e.target.files.length > 0) {
        const maxSizeInBytes = 500 * 1024;
        if (e.target.files[0].size > maxSizeInBytes) {
          setError(t("image_upload_modal.file_size_error"));
        } else {
          let imageFormat = ImageFormat.none;
          for (const format in ImageFormat) {
            if (fileExtension === format) {
              imageFormat = ImageFormat[format];
              setImageExt(imageFormat);
              break;
            }
          }
          setSelectedImgName(e.target.files[0].name);
          setError();
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    } else {
      setError(t("image_upload_modal.wrong_format"));
    }
  };
  const handleImageModal = () => {
    // setCrop("");
    setUpImg("");
    setError("");
    setCompletedCrop("");
    setSelectedImgName("");
    imageUploadModalHide();
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);

  function savePreviewImg(canvas, crop) {
    const uniqueGuid = generateUinqueIDentifier().toLowerCase();
    console.log("uniqueGuid", uniqueGuid);
    const regex = /data:.*base64,/;

    canvas.toBlob(
      (blob) => {
        var reader = new FileReader();
        reader.onload = () => {
          setImageloader(true);
          const base64Img = reader.result;
          const uploadedBaseIMg = base64Img.replace(regex, "");
          const data = {
            ImageData: uploadedBaseIMg,
            ImageFormat: imageExt,
            UploadType: ImageType.PROFILE,
          };
          saveImageAndGetBlobUrlConnect(uniqueGuid, data)
            .then((response) => {
              setTimeout(() => {
                setImageloader(false);
              }, 2000);
              setprofilePicUrl(response);
            })
            .catch((error) => {
              console.log(error);
              setImageloader(false);
            });
        };
        reader.readAsDataURL(blob);
      },
      "image/png",
      1
    );
    setShowLoader(false);
    setUpImg("");
    setError("");
    setCompletedCrop("");
    setSelectedImgName("");
    imageUploadModalHide();
  }
  function setCanvasImage(image, canvas, crop) {
    if (!crop || !canvas || !image) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }
  return (
    <>
      <Modal
        show={imageUpload}
        onHide={handleImageModal}
        className="modal-custom-section image-upload"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t("image_upload_modal.image_upload")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row justify-content-between">
            <div className=" col-sm-4 col-md-3 img-upload-modal-rhs">
              {upImg ? (
                <>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    // style={{ maxWidth: "80%", maxHeight: "80%" }}
                    onComplete={(c) => setCompletedCrop(c)}
                    keepSelection={true}
                  />
                  <label htmlFor="files" className="upload-btns">
                    <div className="pt-3">{t("image_upload_modal.change_photo")}</div>
                  </label>
                </>
              ) : (
                <label htmlFor="files" className="upload-btns">
                  <img src="/app/static/images/avatar.png" />
                  <div className="pt-3">{t("image_upload_modal.upload_photo")}</div>
                </label>
              )}
              {/* <label htmlFor="files" className="upload-btns">
                {upImg ? t("image_upload_modal.change_photo") : t("image_upload_modal.upload_photo")}
              </label> */}
              <input
                id="files"
                type="file"
                accept="image/png, image/gif, image/jpeg, image/jpg"
                style={{ display: "none" }}
                onChange={onSelectFile}
              />
            </div>
            <div className="col-sm-8 col-md-9 img-upload-modal-lhs">
              <ul>
                <li>{t("image_upload_modal.choose_recent_color")}</li>
                <li>{t("image_upload_modal.crop_your_photo")}</li>{" "}
                <li>{t("image_upload_modal.uploading_new_photo")}</li>{" "}
              </ul>
              {selectedImgName ? (
                <p className="selected-img">
                  {t("image_upload_modal.uploaded")} {selectedImgName}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
          {completedCrop ? (
            <div className="row justify-content-between">
              <div className="col-sm-6 col-md-3">
                <div className="preview-section">
                  <canvas ref={previewCanvasRef} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="px-4">
          {upImg ? (
            <Button
              onClick={() => savePreviewImg(previewCanvasRef.current, completedCrop)}
              classNames="btn btn-primary btn-lg submit-on-enter"
              ctaText={t("signature.save")}
            />
          ) : (
            ""
          )}{" "}
          <Button
            classNames="btn btn-outline-secondary btn-lg"
            ctaText={t("opener.cancel")}
            onClick={handleImageModal}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageUploadModal;
