import React, { useState } from "react";
import "../../assets/scss/login-forms.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import ToastMessage from "../../components/toast-message";
import InputText from "../../components/shared/input-text/component";
import { ToastContainer } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { CreateUUID, getIPConfig, readCookie, isAllAddressCookiePresent } from "../../utils/helpers";
import { validateEmail, validatePassword } from "../../validators/genericValidator";
import { EMPTY_GUID } from "../../utils/constants";
import i18next from "i18next";
import PathHelper from "../../utils/path-helper";
import appLogger from "../../utils/app-logger";
import { APP_NAVIGATION } from "../../utils/constants";
import { useEffect } from "react";
const AdminLogin = ({ adminLoginProcessConnect }) => {
  const { locale } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [adminEmail, setadminEmail] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();

  let pathRedirect = PathHelper(APP_NAVIGATION.ADLG);

  useEffect(() => {
    if (!!readCookie("jwtToken") || !!readCookie("refreshToken")) {
      navigate(pathRedirect.next);
    }
  }, []);

  const onAdminEmailChangeHandler = (e) => {
    setEmailError(false);
    const {
      target: { value },
    } = e;
    setadminEmail(value);
  };
  const onUserEmailChangeHandler = (e) => {
    setEmailError(false);
    const {
      target: { value },
    } = e;
    setuserEmail(value);
  };
  const onPwdChangeHandler = (e) => {
    setPasswordError(false);
    const {
      target: { value },
    } = e;
    setPwd(value);
  };
  const onPasswordKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) {
      onAdminSignIn();
    }
  };
  const onAdminSignIn = () => {
    setLoginError(false);
    const isValid = validateData();
    if (isValid) {
      const params = {
        adminUserName: adminEmail.trim(),
        password: pwd.trim(),
        userEmail: userEmail.trim(),
      };

      adminLoginProcessConnect(params)
        .then((response) => {
          if (!!response) {
            if (response == EMPTY_GUID) {
              setPasswordError(true);
              setPasswordErrorMsg(`${i18next.t("sign_in.no_account")}`);
            } else {
              if (!!isAllAddressCookiePresent()) {
                getIPConfig();
              }
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    }
  };
  const validateData = () => {
    setEmailError(false);
    setPasswordError(false);
    let errors = 0;
    let adminEmailValidator = validateEmail(adminEmail);
    if (adminEmailValidator.isValid === false) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg(adminEmailValidator.errorMessage);
    }
    let userEmailValidator = validateEmail(userEmail);
    if (userEmailValidator.isValid === false) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg(userEmailValidator.errorMessage);
    }
    let passwordValidator = validatePassword(pwd);
    if (passwordValidator.isValid === false) {
      setPasswordError(true);
      setPasswordErrorMsg(passwordValidator.errorMessage);
    }
    if (errors > 0) {
      ++errors;
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Header />

      <div className="contailer">
        <div className="form-wrap">
          <hgroup className="text-center">
            <h1>Login as Admin</h1>
          </hgroup>

          <div className="mb-3">
            <InputText
              showLabel={false}
              id="email"
              text="Email Address"
              onChange={onAdminEmailChangeHandler}
              value={adminEmail}
              maxLength={100}
              placeholder="Admin Email Address"
            />
          </div>
          <div className="mb-3">
            <InputText
              showLabel={false}
              id="email"
              text="Email Address"
              onChange={onUserEmailChangeHandler}
              value={userEmail}
              maxLength={100}
              placeholder="Login as Email Address"
            />
          </div>
          <div className="form-text">
            {emailError && (
              <small key="pswrdErr" className="form-text text-danger">
                {emailErrorMsg}
              </small>
            )}
          </div>
          <div className="mb-3">
            <InputText
              showLabel={false}
              id="password"
              text="Password"
              type="password"
              onChange={onPwdChangeHandler}
              value={pwd}
              onKeyPress={onPasswordKeyPress}
              placeholder="Admin Password"
            />
          </div>
          <div className="form-text">
            {passwordError && (
              <small key="pswrdErr" className="form-text text-danger">
                {passwordErrorMsg}
              </small>
            )}
          </div>
          <div className="mb-3">
            <Button
              isLoading={false}
              disabled={false}
              onClick={onAdminSignIn}
              classNames="btn btn-primary w-100 text-uppercase"
              ctaText={t("sign_in.btn_cta")}
            />
          </div>

          <div className="Toastify">
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminLogin;
