import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  Modifier,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import WriterSnippetExperience from "../../components/writer-snippet-experience";
import { SECTION_TYPE } from "../../utils/constants";

const TextEditor = ({ sectionCode, callback, editorContent, placeholder }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [keyCode, setkeyCode] = useState("");
  const [addPlaceHolder, setaddPlaceHolder] = useState(placeholder);
  const sendTextToEditor = (text, type) => {
    if (editorState.getCurrentContent().hasText() === true) {
      text = "\n" + text;
    }
    setEditorState(insertText(text, editorState, type));
  };

  const insertText = (text, editorValue, type) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const pastedBlocks = ContentState.createFromText(text).blockMap;
    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      pastedBlocks
    );
    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-fragment"
    );
    const finalState = EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
    const finaltext = RichUtils.toggleBlockType(finalState, type);

    return finaltext;
  };

  const getEditorVal = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    callback(currentContentAsHTML, "content");
  };

  const handleEditorChange = (editorState) => {
    setaddPlaceHolder("");
    getEditorVal();
    setEditorState(editorState);
  };

  const paragraphContent = () => {
    let contentText = "";
    if (JSON.parse(editorContent).content != undefined) {
      contentText = JSON.parse(editorContent).content;
    }
    const contentBlocks = convertFromHTML(contentText);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap
    );
    let content = EditorState.createWithContent(contentState);
    return content;
  };

  useEffect(() => {
    if (!!editorContent) {
      setEditorState(
        editorContent === "" ? EditorState.createEmpty() : paragraphContent()
      );
    }
    // callback("", "content");
  }, [editorContent]);

  const onChangeHandler = (e) => {
    if (keyCode != "split-block") {
      if (document.getElementsByClassName("rdw-editor-main").length > 0) {
        const element = document.getElementsByClassName("rdw-editor-main")[0];
        element.scrollTop = element.scrollHeight;
      }
    }
  };
  const onkeyPressHandler = (command) => {
    if (command === "split-block") {
      setkeyCode("split-block");
    }
  };
  // const myHandleReturn = (e) => {
  //   console.log(e);
  // };
  return (
    <>
      <div className="col-sm-6">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          //onFocus={onChangeHandler}
          //onInput={onChangeHandler}
          //handleKeyCommand={onkeyPressHandler}
          autoCapitalize={"none"}
          autoComplete={"off"}
          autoCorrect={"off"}
          placeholder={addPlaceHolder}
          spellCheck={window.innerWidth < 768 ? false : true}
          toolbar={{
            options: ["inline", "link", "blockType", "list", "history"],
            inline: { options: ["bold", "italic", "strikethrough"] },
            link: { options: ["link"] },
            blockType: {
              inDropdown: false,
              options: ["H3", "Blockquote", "Code"],
              className: "block-type",
            },
          }}
        />
      </div>
      <div
        className={
          sectionCode == SECTION_TYPE.CSTM || sectionCode == SECTION_TYPE.OTHR
            ? "d-none"
            : "col-sm-6 px-0 editor-right-box"
        }
      >
        <WriterSnippetExperience
          actionName={sendTextToEditor}
          sectionCode={sectionCode}
        />
      </div>
    </>
  );
};
export default TextEditor;
