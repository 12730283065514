import Types from "./types";
import DocBuilderApi from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import DocBuilderService from "../../services/doc-builder-api";

const createParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderApi.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchSummarySuccess(response));
        } else {
        }
        return response;
      })

      .catch((error) => dispatch(fetchSummaryFailure(error)));
  };
};
const updateSummaryParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateSummarySuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateSummaryFailure(error)));
  };
};

const fetchSummarySuccess = (data) => ({
  type: Types.FETCH_SUMMARY_SUCCESS,
  data,
});

const fetchSummaryFailure = (error) => ({
  type: Types.FETCH_SUMMARY_FAILURE,
  error,
});
const updateSummarySuccess = (data) => ({
  type: Types.UPDATE_SUMMARY_SUCCESS,
  data,
});

const updateSummaryFailure = (error) => ({
  type: Types.UPDATE_SUMMARY_FAILED,
  error,
});
export { createParagraph, fetchSummarySuccess, updateSummaryParagraph };
