import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const PlanFeatures = ({ section, plansList }) => {
  const { t } = useTranslation();
  let limitedAccessList5 = t("plans.limited_access.list_5");
  let fullAccessList7 = t("plans.full_access.list_7");
  let annualAccessList7 = t("plans.annual_plan.list_7");
  if (!!plansList) {
    //console.log("YEARLY PLAN:", plansList[2]);
    limitedAccessList5 = limitedAccessList5
      .replace("#DAYS#", plansList[0].renewalInDays.toString())
      .replace("#CURRENCY#", plansList[0].currencySymbol.toString())
      .replace("#AMOUNT#", plansList[0].recurringPrice.toString());
    fullAccessList7 = fullAccessList7
      .replace("#DAYS#", plansList[1].renewalInDays.toString())
      .replace("#CURRENCY#", plansList[1].currencySymbol.toString())
      .replace("#AMOUNT#", plansList[1].recurringPrice.toString());
    annualAccessList7 = annualAccessList7
      .replace("#DAYS#", plansList[2].renewalInDays.toString())
      .replace("#CURRENCY#", plansList[2].currencySymbol.toString())
      .replace("#AMOUNT#", parseFloat(plansList[2].recurringPrice).toFixed(2));
  }

  const getLimitedAccess = () => {
    return (
      <ul className="pricing-wrap--features">
        <li key="1">
          <span>{t("plans.limited_access.list_1")}</span>
        </li>
        <li key="2">
          <span>{t("plans.limited_access.list_2")}</span>
        </li>
        <li key="3">
          <span>{t("plans.limited_access.list_3")}</span>
        </li>
        <li key="4">
          <span>{t("plans.limited_access.list_4")}</span>
        </li>
        <li key="5">
          <span>{limitedAccessList5}</span>
        </li>
        <li key="6">
          <span>{t("plans.limited_access.list_6")}</span>
        </li>
      </ul>
    );
  };
  const getFullAccess = () => {
    return (
      <ul className="pricing-wrap--features">
        <li key="1">
          <span>{t("plans.full_access.list_1")}</span>
        </li>
        <li key="2">
          <span>{t("plans.full_access.list_2")}</span>
        </li>
        <li key="3">
          <span>{t("plans.full_access.list_3")}</span>
        </li>
        <li key="4">
          <span>{t("plans.full_access.list_4")}</span>
        </li>
        <li key="5">
          <span>{t("plans.full_access.list_5")}</span>
        </li>
        <li key="6">
          <span>{t("plans.full_access.list_6")}</span>
        </li>
        <li key="7">
          <span>{fullAccessList7}</span>
        </li>
        <li key="8">
          <span>{t("plans.full_access.list_8")}</span>
        </li>
      </ul>
    );
  };
  const getAnnualPlan = () => {
    return (
      <ul className="pricing-wrap--features">
        <li key="1">
          <span>{t("plans.annual_plan.list_1")}</span>
        </li>
        <li key="2">
          <span>{t("plans.annual_plan.list_2")}</span>
        </li>
        <li key="3">
          <span>{t("plans.annual_plan.list_3")}</span>
        </li>
        <li key="4">
          <span>{t("plans.annual_plan.list_4")}</span>
        </li>
        <li key="5">
          <span>{t("plans.annual_plan.list_5")}</span>
        </li>
        <li key="6">
          <span>{t("plans.annual_plan.list_6")}</span>
        </li>
        <li key="7">
          <span>{annualAccessList7}</span>
        </li>
        <li key="8">
          <span>{t("plans.annual_plan.list_8")}</span>
        </li>
      </ul>
    );
  };
  const getPlans = (section) => {
    if (section.startsWith("M") && section.endsWith("000")) {
      return getLimitedAccess();
    } else if (section.startsWith("M") && section.endsWith("195")) {
      return getFullAccess();
    } else {
      return getAnnualPlan();
    }
  };

  return <>{getPlans(section)}</>;
};

export default PlanFeatures;
