import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Plans from "./component";
import { getPlans } from "./actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      plansConnect: getPlans,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
