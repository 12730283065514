import React, { useEffect, useState } from "react";
import "../../assets/scss/pages.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import FinalStepTypes from "../funnel-step/types";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, DEFAULT_USER_GUID } from "../../utils/constants";
import { Helmet } from "react-helmet";
import Button from "../../components/shared/button";
import Upload from "rc-upload";
import ReactLoader from "../../components/shared/loader";
import {
  changeToValidFileName,
  checkValidExtensionForUploadFile,
  generateUinqueIDentifier,
  base64ArrayBuffer,
  loadScript,
  removeScript,
  loadScriptWithKey,
  getServiceToken,
  readCookie,
  getPortalCD,
} from "../../utils/helpers";
import IconFileUp from "../../components/icons/icon-file-up";
import IconGoogleDrive from "../../components/icons/icon-google-drive";
import IconDropBox from "../../components/icons/icon-drop-box";
import IconOneDrive from "../../components/icons/icon-one-drive";
import DashboardOrchestrationService from "../../services/dashboard-orchestration-service-api";
import { endPoints } from "../../services/api-constants";
import {
  getGoogleDriveClientId,
  getGoogleDriveDeveloperKey,
  getDropboxAppKey,
  getResumeBuildOneDriveClientID,
} from "../../utils/general-config";
import appLogger from "../../utils/app-logger";
import useGoogleDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import jwt_decode from "jwt-decode";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
const UploadResume = ({ uploadResumeConnect, uploadDocsConnect, uploadResume, funnelStep }) => {
  const originalFileUrl = uploadResume?.uploadResponse?.renderPdfFileUrl;
  const { userGUID, email, personalInfoName } = funnelStep || {};
  let isValidFile = true;
  const { t } = useTranslation();
  const { locale } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let pathRedirect = PathHelper(APP_NAVIGATION.UPDRES);
  const [error, setError] = useState("");
  const [uploadServiceToken, setuploadServiceToken] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [accessToken, setAccessToken] = useState("");

  const setServiceToken = async () => {
    const serviceToken = await getServiceToken();
    setuploadServiceToken(serviceToken);
  };
  const xtnforOneDrive = ".pdf,.docx,.doc,.txt,.rtf,.htm,.html";
  const xtnforDropBox = [".pdf", ".docx", ".doc", ".txt", ".htm", ".html", ".rtf"];
  const folderPath = "";
  const [openGooglePicker, data, authResponse] = useGoogleDrivePicker();
  const handleGooglePicker = () => {
    const googleClientId = getGoogleDriveClientId();
    const googleDeveloperKey = getGoogleDriveDeveloperKey();
    if (!!accessToken && accessToken != "") {
      openGooglePicker({
        clientId: googleClientId,
        developerKey: googleDeveloperKey,
        viewId: "DOCS",
        token: accessToken,
        callbackFunction: (data) => {
          if (data.action === "cancel") {
            console.log("User clicked cancel/close button");
          }
          if (!!data && !!data.docs) {
            setShowLoader("d-block");
            //this will give file name and file bytes
            data.docs.map((i) => console.log(i));
            let file = data.docs[0];
            let fileByteArray;
            var accessToken = accessToken;

            var axios = require("axios");
            var config = {
              method: "get",
              responseType: "arraybuffer",
              url: "https://www.googleapis.com/drive/v3/files/" + file.id + "?alt=media",
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            };

            axios(config)
              .then(function (response) {
                //console.log("base64 :: ", base64ArrayBuffer(response.data));
                fileByteArray = base64ArrayBuffer(response.data);
                var unqn = generateUinqueIDentifier().toLowerCase();
                unqn = unqn.split("-").join("");
                unqn = file.name.replace(/[^A-Za-z0-9._-]/gi, "").replace(".", "_" + unqn + ".");

                let formFileData = new FormData();
                for (var i = 0; i < data.length; i++) {
                  formFileData.append(`file`, data.docs[0]);
                }
                formFileData.append(`UserId`, DEFAULT_USER_GUID);
                formFileData.append("Filename", file.name);
                formFileData.append("FolderName", folderPath);
                formFileData.append("DocumentProfile.OriginalName", file.name);
                formFileData.append("DocumentProfile.UniqueName", unqn);
                formFileData.append(`FileByteArray`, fileByteArray);
                uploadDocsConnect(formFileData)
                  .then((response) => {
                    if (response != null) {
                      // Dispatch action and save name/blob file in store
                      setShowLoader("d-none");
                      setError("");
                      dispatch(uploadResumeConnect(response));
                      navigate(pathRedirect.next);
                    } else {
                      setShowLoader("d-none");
                      setError(t("upload_resume_page.error_while_Uploading_Document"));
                    }
                  })
                  .catch((error) => {
                    setShowLoader("d-none");
                    setError(t("upload_resume_page.error_while_Uploading_Document"));
                  });
              })
              .catch(function (error) {
                setShowLoader("d-none");
                setError(t("upload_resume_page.error_while_Uploading_Document"));
              });
          }
        },
      });
    } else {
      let tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: googleClientId,
        scope: "https://www.googleapis.com/auth/drive",
        callback: (tokenResponse) => {
          setAccessToken(tokenResponse.access_token);
          openGooglePicker({
            clientId: googleClientId,
            developerKey: googleDeveloperKey,
            viewId: "DOCS",
            token: tokenResponse.access_token,
            callbackFunction: (data) => {
              if (data.action === "cancel") {
                console.log("User clicked cancel/close button");
              }
              if (!!data && !!data.docs) {
                setShowLoader("d-block");
                //this will give file name and file bytes
                data.docs.map((i) => console.log(i));
                let file = data.docs[0];
                let fileByteArray;
                var accessToken = tokenResponse.access_token;

                var axios = require("axios");
                var config = {
                  method: "get",
                  responseType: "arraybuffer",
                  url: "https://www.googleapis.com/drive/v3/files/" + file.id + "?alt=media",
                  headers: {
                    Authorization: "Bearer " + accessToken,
                  },
                };

                axios(config)
                  .then(function (response) {
                    //console.log("base64 :: ", base64ArrayBuffer(response.data));
                    fileByteArray = base64ArrayBuffer(response.data);
                    var unqn = generateUinqueIDentifier().toLowerCase();
                    unqn = unqn.split("-").join("");
                    unqn = file.name.replace(/[^A-Za-z0-9._-]/gi, "").replace(".", "_" + unqn + ".");

                    let formFileData = new FormData();
                    for (var i = 0; i < data.length; i++) {
                      formFileData.append(`file`, data.docs[0]);
                    }
                    formFileData.append(`UserId`, DEFAULT_USER_GUID);
                    formFileData.append("Filename", file.name);
                    formFileData.append("FolderName", folderPath);
                    formFileData.append("DocumentProfile.OriginalName", file.name);
                    formFileData.append("DocumentProfile.UniqueName", unqn);
                    formFileData.append(`FileByteArray`, fileByteArray);
                    uploadDocsConnect(formFileData)
                      .then((response) => {
                        if (response != null) {
                          // Dispatch action and save name/blob file in store
                          setShowLoader("d-none");
                          setError("");
                          dispatch(uploadResumeConnect(response));
                          navigate(pathRedirect.next);
                        } else {
                          setShowLoader("d-none");
                          setError(t("upload_resume_page.error_while_Uploading_Document"));
                        }
                      })
                      .catch((error) => {
                        setShowLoader("d-none");
                        setError(t("upload_resume_page.error_while_Uploading_Document"));
                      });
                  })
                  .catch(function (error) {
                    setShowLoader("d-none");
                    setError(t("upload_resume_page.error_while_Uploading_Document"));
                  });
              }
            },
          });
        },
      });
      tokenClient.requestAccessToken();
    }
  };

  const uploaderProps = {
    className: "outline-none",
    // accept:
    //   "application/pdf, .docx, .doc, .jpg, .jpeg, .png, .tiff, .tif, .ppt, .pptx, .xls, .xlsx, .odt",
    ContentType: "multipart/form-data",
    headers: { Authorization: "Bearer" + " " + uploadServiceToken },
    beforeUpload: (file, fileList) => {
      return checkFileUploadValidation(file);
    },
    action: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            DashboardOrchestrationService.DashboardOrchestrationService(
              endPoints.dashboardOrchestrationService.UPLOAD_DOCUMENT
            )
          );
        });
      });
    },
    directory: false,
    data: (file) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (checkFileUploadValidation(file)) {
            var unqn = generateUinqueIDentifier().toLowerCase();
            unqn = unqn.split("-").join("");
            unqn = file.name.replace(/[^A-Za-z0-9._-]/gi, "").replace(".", "_" + unqn + ".");
            let fileObj = {
              Filename: file.name,
              FolderName: "",
              UserId: DEFAULT_USER_GUID,
              "DocumentProfile.FolderPath": "",
              "DocumentProfile.OriginalName": file.name,
              "DocumentProfile.UniqueName": unqn,
              "DocumentProfile.SizeInKB": file.size,
              "DocumentProfile.OriginalFileFormat": file.name.split(".").pop(),
            };
            resolve(fileObj);
          }
        });
      });
    },
    onSuccess: (response) => {
      // Dispatch action and save name/blob file in store
      dispatch(uploadResumeConnect(response));
      navigate(pathRedirect.next);
      setShowLoader("d-none");
      setError("");
    },
    onError: (error) => {
      setError(t("upload_resume_page.error_while_Uploading_Document"));
      setShowLoader("d-none");
    },
  };
  const checkFileUploadValidation = (file) => {
    const validFileName = changeToValidFileName(file.name); // remove junk characters and validate file name
    var ext = validFileName.split(".").pop().toLowerCase();
    let isValidExtension = checkValidExtensionForUploadFile(ext);
    if (!isValidExtension) {
      isValidFile = false;
      setError(t("error_messages.wrong_format_msg"));
      setShowLoader("d-none");
      return isValidFile;
    } else {
      isValidFile = true;
      setShowLoader("d-block");
      setError("");
    }

    return checkFileSize(file.size);
  };

  const checkFileSize = (fileSize) => {
    if (fileSize / 1000 > 20000) {
      //TO DO LOADER Remove Loader and show message
      //toggleLoader(false);
      //TO DO LOADER
      setError(t("error_messages.file_size_msg"));
      setShowLoader("d-none");
      isValidFile = false;
    }
    return isValidFile;
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const nextClickHandler = () => {
    navigate(pathRedirect.next);
  };
  const DROPBOX_APP_KEY = getDropboxAppKey();
  const handleDropBoxSuccess = (files) => {
    setShowLoader("d-block");
    var config = {
      method: "get",
      responseType: "arraybuffer",
      url: files[0].link,
    };
    callAxios(config, files[0]);
  };
  const callAxios = (config, file) => {
    var axios = require("axios");
    let fileByteArray;
    axios(config)
      .then(function (response) {
        console.log("base64 ---- ", base64ArrayBuffer(response.data));
        fileByteArray = base64ArrayBuffer(response.data);
        var unqn = generateUinqueIDentifier().toLowerCase();
        unqn = unqn.split("-").join("");
        unqn = file.name.replace(/[^A-Za-z0-9._-]/gi, "").replace(".", "_" + unqn + ".");

        let formFileData = new FormData();
        formFileData.append(`file`, file);
        formFileData.append(`UserId`, DEFAULT_USER_GUID);
        formFileData.append("Filename", file.name);
        formFileData.append("FolderName", folderPath);
        formFileData.append("DocumentProfile.OriginalName", file.name);
        formFileData.append("DocumentProfile.UniqueName", unqn);
        formFileData.append(`FileByteArray`, fileByteArray);

        uploadDocsConnect(formFileData)
          .then((response) => {
            if (response != null) {
              setError("");
              setShowLoader("d-none");
              dispatch(uploadResumeConnect(response));
              navigate(pathRedirect.next);
            }
          })
          .catch((error) => {
            setError(t("upload_resume_page.error_while_Uploading_Document"));
            setShowLoader("d-none");
          });
      })
      .catch(function (error) {
        setError(t("upload_resume_page.error_while_Uploading_Document"));
        setShowLoader("d-none");
      });
  };
  const handleOneDriveClick = () => {
    let advanced = {};
    advanced.filter = xtnforOneDrive;
    console.log("handleOneDriveClick");
    if (window.location.host.indexOf("resumebuild") > -1 || window.location.host.indexOf("local") > -1) {
      launchOneDrivePicker(getResumeBuildOneDriveClientID(), "share", false, advanced);
    }
  };
  var launchOneDrivePicker = function (oneDriveApplicationId, action, multiSelect, advancedOptions) {
    return new Promise(function (resolve, reject) {
      var odOptions = {
        clientId: oneDriveApplicationId,
        action: action || "download",
        multiSelect: multiSelect || true,
        openInNewWindow: true,
        advanced: advancedOptions || {},
        success: function (files) {
          handleSuccess(files);
        },
        cancel: function () {
          handleCancel();
        },
        error: function (e) {
          handleError(e);
        },
      };
      window.OneDrive.open(odOptions);
    });
  };
  const handleSuccess = (files) => {
    var config = {
      method: "get",
      responseType: "arraybuffer",
      url: files.value[0]["@microsoft.graph.downloadUrl"],
    };
    callAxios(config, files.value[0]);
  };
  const handleCancel = () => console.log("CANCELLED");
  const handleError = (err) => console.log("ERROR: ", err);
  useEffect(() => {
    trackRBEvents("uploadResumeView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    let currentStep = location.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  useEffect(() => {
    setServiceToken();
  }, []);

  useEffect(() => {
    loadScript("https://apis.google.com/js/client.js");
    loadScript("https://js.live.net/v7.2/OneDrive.js");
    loadScriptWithKey("https://www.dropbox.com/static/api/2/dropins.js", "dropboxjs", getDropboxAppKey());
    return () => {
      removeScript("https://apis.google.com/js/client.js");
      removeScript("https://js.live.net/v7.2/OneDrive.js");
      removeScript("https://www.dropbox.com/static/api/2/dropins.js");
    };
  }, []);
  return (
    <>
      <Helmet>
        <title> {t("page_title.upload_resume_page")}</title>
      </Helmet>
      <Header />
      <ReactLoader showloader={showLoader} />
      <section className="section main-content">
        <div className="container page-section">
          <div className="row">
            <div className="col-12 headline-group text-center">
              <hgroup>
                <h1>{t("upload_resume_page.heading")}</h1>
              </hgroup>
            </div>
          </div>

          <div className="row page-section-wrap drive justify-content-around">
            <div className="col-md-6 col-lg-5 mb-4 text-center">
              <Upload {...uploaderProps}>
                <div className="border pt-4 pb-5 px-4 h-100 pointer-hover">
                  <h3 className="pt-2">{t("upload_resume_page.upload_resume")}</h3>
                  <div className="pt-2">
                    <IconFileUp width={90} height={90} className="mb-4" />
                  </div>
                  <label>
                    <button className="btn btn-secondary">{t("upload_resume_page.upload_cta")}</button>
                  </label>
                  <small className="text-secondary">{t("upload_resume_page.upload_resume_txt")}</small>
                  <div className="text-danger">{error}</div>
                </div>
              </Upload>
            </div>
            <div className="col-md-1 align-items-center d-none d-md-flex" style={{ width: "40px" }}>
              <span className="fw-bold text-uppercase">{t("universal.or")}</span>
            </div>
            <div className="col-md-5 col-lg-4 mb-4 text-left">
              <div className="h-100 align-content-between flex-wrap d-flex">
                <div
                  className="col-12 border d-block py-4 px-5 mb-4 cursor-pointer"
                  onClick={() => handleGooglePicker()}
                >
                  <IconGoogleDrive className="" />
                </div>
                <div className="col-12 border d-block py-4 px-5 mb-4 cursor-pointer">
                  <DropboxChooser
                    appKey={DROPBOX_APP_KEY}
                    linkType="direct"
                    success={handleDropBoxSuccess}
                    cancel={() => console.log("hey")}
                    multiselect={false}
                    disabled={false}
                    extensions={xtnforDropBox}
                  >
                    <IconDropBox className="" />
                  </DropboxChooser>
                </div>
                <div className="col-12 border d-block py-4 px-5 cursor-pointer" onClick={() => handleOneDriveClick()}>
                  <IconOneDrive className="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <p>
              <small className="text-secondary">{t("universal.file_types")}</small>
            </p>
          </div>
          <div className="btn-container fbn sticky-bar btn-justify">
            <Button
              isLoading={false}
              classNames="btn btn-outline-secondary btn-lg"
              ctaText={t("universal.back_action")}
              onClick={backClickHandler}
            />
            <Button
              isLoading={false}
              disabled={!!originalFileUrl ? false : true}
              classNames="btn btn-primary btn-lg submit-on-enter float-end"
              ctaText={t("universal.next_action")}
              onClick={nextClickHandler}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UploadResume;
