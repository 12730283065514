import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Resumes from "./component";
import { getUserDocs, renameResume, getUserProfile, getAbridgedUserDocs } from "./actions";
import { setDocGUID, setTemplate } from "../../pages/funnel-step/actions";
import { setDocType } from "../funnel-step/actions";
import { getDocument } from "../resume-review/actions";

const mapStateToProps = ({ funnelStep }) => ({
  funnelStep,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDocsConnect: getUserDocs,
      setDocGuidConnect: setDocGUID,
      renameResumeConnect: renameResume,
      getUserProfileConnect: getUserProfile,
      setDocTypeConnect: setDocType,
      setTemplateConnect: setTemplate,
      getAbridgedUserDocsConnect: getAbridgedUserDocs,
      getDocumentConnect: getDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Resumes);
