import DocBuilderService from "../../services/doc-builder-api";
import { blobSignature } from "../../utils/general-config";
import Types from "./types";
const saveImageAndGetBlobUrl = (docid, data) => {
  return function (dispatch) {
    return DocBuilderService.saveImage(docid, data)
      .then((response) => {
        let imageUrl = "";
        if (!!response && !!response.data) {
          imageUrl = response.data.blobUrl;
          dispatch(setImageUrl(imageUrl));
        }
        return imageUrl;
      })
      .catch((error) => {});
  };
};
const setImageUrl = (imageUrl) => ({
  type: Types.SET_IMAGE_URL,
  imageUrl,
});
const resetImageURl = () => ({
  type: Types.RESET_IMAGE_URL,
});
export { saveImageAndGetBlobUrl, setImageUrl, resetImageURl };
