import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv1ClassicCSS = css`
  .resume-layout.cv1 {
    font-family: "Open Sans TTF", sans-serif;
  }
  .resume-layout.cv1 .resume__section {
    margin-bottom: 0;
    padding-top: 2em;
    padding-bottom: 1.6em;
  }
  .resume-layout.cv1 .sub-headline {
    display: block;
  }
  .resume-layout.cv1 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv1 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-weight: 600;
  }
  .resume-layout.cv1 .resume__section .sub-headline h2 {
    font-size: 1.29em;
    margin-bottom: 1em;   
     font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid lightgrey;
  }
  .resume-layout.cv1 .resume__section h1,
  .resume-layout.cv1 .resume__section h3 {
    font-size: 1.29em;
    margin-bottom: 0.25em;   
    font-weight: 500;
    text-transform: uppercase;
  }
  .resume-layout.cv1 .resume__skills .resume__text .wrap {
    width: 50%;
    float: left;
    display: inherit;
    border-right: 1px solid #c1c0c5;
  }
  .resume-layout.cv1 .resume__skills .resume__text .wrap:nth-child(even) {
    border-right: none;
    padding-left: 5rem;
  }
  .resume-layout.cv1 .resume__skills .resume__text .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv1 .resume__skills .resume__text:after {
    content: "";
    clear: both;
    display: table;
  }
  .resume-layout.cv1 .extra .extra-details {
    width: 120px;
  }
  .resume-layout.cv1 .xp-item .xp-job .exp-show {
        font-weight: 600;
    }
    .resume-layout.cv3 .xp-item .xp-job .exp-show {
        font-weight: 600;
    }
    @media (max-width: 767px) {
      .resume-layout.cv1 .resume__skills .resume__text .wrap:nth-child(even) {
        padding-left: 2.55rem;
      }
      .resume-layout.cv1 .extra .extra-details {
        width: 7em;
        margin-right: 5px;
        padding-right: 0;
      }
    }
`;

export const Cv1ClassicStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv1ClassicCSS}
`;
