import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import "./template-chooser.scss";
import HeaderSteps from "../../components/header-steps";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import appLogger from "../../utils/app-logger";
import TemplateContainer from "../../components/template-container";

import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import MetaContentService from "../../services/meta-content-api";
import PathHelper from "../../utils/path-helper";
import FinalStepTypes from "../funnel-step/types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { APP_NAVIGATION, EMPTY_GUID, DOC_TYPE } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import UploadResumeTypes from "../upload-resume/types";
import { getPortalCD, readCookie } from "../../utils/helpers";
import { trackRBEvents } from "../../utils/tracking-events";

const ChooseTemplates = ({
  chooseTemplate, //rootreducer
  funnelStep, //rootreducer
  fetchTemplatesConnect, //action method
  setTemplateConnect,
  resetImageURlConnect,
}) => {
  const dispatch = useDispatch();
  const localtion = useLocation();
  const { locale } = useParams();
  const { userGUID, email, personalInfoName, docType } = funnelStep || {};

  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  let pathRedirect = PathHelper(APP_NAVIGATION.TEMP);

  useEffect(() => {
    resetImageURlConnect();
    trackRBEvents("chooseView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    fetchTemplatesConnect(locale);
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    dispatch({
      type: UploadResumeTypes.RESET_UPLOAD_RESUME,
    });

    let docType = DOC_TYPE.RESUME;
    dispatch({
      type: FinalStepTypes.SET_DOC_TYPE,
      docType,
    });
  }, []);

  const { data, isLoading, total, error } = chooseTemplate || {};
  const navigate = useNavigate();
  const skipChooser = () => {
    setTemplateConnect("Classic");
    navigate(pathRedirect.next + localtion.search);
  };
  const chooseTemplateHandler = (templateCd) => {
    setTemplateConnect(templateCd);
    navigate(pathRedirect.next + localtion.search);
  };
  return (
    <>
      <HeaderSteps activeStep={0} />
      <Helmet>
        <title>{t("page_title.choose_template")}</title>
      </Helmet>
      <section className="section main-content templates text-center sidebar-sticky">
        <div className="container">
          <div className="alignment-container">
            <div className="mb-5">
              <hgroup className="text-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("choose_templates.headline"),
                  }}
                />
                <p>{t("choose_templates.content")}</p>
              </hgroup>
            </div>

            <div className="row template-container">
              {!!data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <TemplateContainer
                    data={item}
                    key={index}
                    onClick={(e) => chooseTemplateHandler(e, item.templatecd)}
                  ></TemplateContainer>
                ))}
            </div>
            <div className="row">
              <div className="templates-bottom">
                <div className="templates-bottom--btn">
                  <div className="fbn">
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={skipChooser}
                      id={t("choose_templates.skip")}
                      classNames="btn btn-outline-success btn-lg template-skip-btn"
                      ctaText={t("choose_templates.skip")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="headline-group">
                <hgroup>
                  <h1>
                    <span className="heading-extra">Choose your</span> resume
                    template
                  </h1>
                  <h4>
                    Remember, you can always change your template later on.
                  </h4>
                </hgroup>
              </div>
              <div className="templates-content flex-container">
                <div className="box__content template-container">
                  <div className="row">
                    {!!data &&
                      data.length > 0 &&
                      data.map((item, index) => (
                        <TemplateContainer
                          data={item}
                          key={index}
                          onClick={(e) =>
                            chooseTemplateHandler(e, item.templatecd)
                          }
                        ></TemplateContainer>
                      ))}
                  </div>
                </div>
              </div>
              <div className="templates-bottom">
                <div className="templates-bottom--btn">
                  <Button
                    isLoading={false}
                    disabled={false}
                    onClick={skipChooser}
                    classNames="btn btn-primary-light--large"
                    ctaText="SKIP"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default ChooseTemplates;
