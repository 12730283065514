import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let docId = searchParams.get("docid");
  const [showHome, setShowHome] = useState(false);
  const redirectfunnelStepRuleData = useSelector((state) => state.funnelStep);
  const { templateCd, currentStep } = redirectfunnelStepRuleData;
  useEffect(() => {
    //var url = window.location.origin + location.pathname;
    setShowHome(true);
    if (templateCd == "") {
      setShowHome(true);
    }
  }, []);

  const goToIntro = () => {
    navigate("/app/introduction");
  };
  return (
    <>
      <Header />
      {showHome && (
        <section className="section intro text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="headline-group">
                  <hgroup>
                    <h1>IMPRESSIVE RESUMES EASY ONLINE BUILDER</h1>
                  </hgroup>
                </div>

                <div className="intro-bottom">
                  <div className="intro-bottom--btn">
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={goToIntro}
                      classNames="btn btn-primary btn-primary--heavy btn-lg text-uppercase"
                      ctaText="Build My Resume"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Home;
