import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverLetterPreviewButton from "./component";

const mapStateToProps = ({ funnelStep, docReview }) => ({ funnelStep, docReview });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterPreviewButton);
