import React from "react";
import Button from "../../../shared/button";
import { useNavigate } from "react-router-dom";
import PathHelper from "../../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import CoverLetterPersonalSection from "../../cover-letter-personal-section";

const TempCoverLetterHeader = ({ data }) => {
  const { t } = useTranslation();
  let pathRedirect = PathHelper(APP_NAVIGATION.TPRSNLINFO, false);
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };

  return (
    <div className="resume__section resume__heading resume__contact">
      <div className="resume__controls"></div>
      <div className="controls-menu">
        <Button
          isLoading={false}
          disabled={false}
          onClick={editHandler}
          icon={
            <>
              <span className="fa fa-pencil" aria-hidden="true"></span>
              <span className="resume__control-text"> {t("review.edit")}</span>
            </>
          }
          classNames="btn-green resume__control-button"
          ctaText=""
        />
      </div>
      <CoverLetterPersonalSection type="cover-letter" data={data} />
    </div>
  );
};

export default TempCoverLetterHeader;
