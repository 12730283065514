import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dompurify from "dompurify";
import HeaderSteps from "../../components/header-steps";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import appLogger from "../../utils/app-logger";
import Button from "../../components/shared/button";
import PathHelper from "../../utils/path-helper";
import { useTranslation } from "react-i18next";
import TextEditor from "../../components/text-editor";
import { readCookie, deleteCookie, getPortalCD, navigateToSignleLoginAPP, isUserLoggedIn } from "../../utils/helpers";
import { createUpdateDocumentUpload, IsSingleLoginAPPEnable } from "../../utils/general-config";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
  AFFILIATE_SOURCE_VALUE,
  AFFILIATE_SOURCE_NAME,
  AFFILIATE_STORAGE_NAME,
  AFFILIATE_KEYWORD_NAME,
} from "../../utils/constants";
import moment from "moment";
import ResumeMap from "../../components/resume-map";
import { APP_NAVIGATION } from "../../utils/constants";
import WritingTips from "../../components/writing-tips";
import "./summary.scss";
import { Helmet } from "react-helmet";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Summary = ({ summary, summaryConnect, funnelStep, updateSummaryConnect }) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate();
  const { docGUID, userGUID, funnelStepsComplete, currentStep, email, personalInfoName } = funnelStep || {};
  const [editorContent, setEditorContent] = useState("");
  const {
    Paragraphs: { paraContents, paraName, id },
  } = summary;
  const [summaryParaGuid, setSummaryParaGuid] = useState(!!id ? id : EMPTY_GUID);
  const [allValues, setAllValues] = useState();
  const isAffilateUser = () => {
    return readCookie(AFFILIATE_SOURCE_NAME) == AFFILIATE_SOURCE_VALUE;
  };
  const callBackEditorVal = (fieldValue, fieldId) => {
    setAllValues({ ...allValues, [fieldId]: fieldValue });
  };
  let acc = "";
  if (!!readCookie(AFFILIATE_STORAGE_NAME)) {
    acc = readCookie(AFFILIATE_STORAGE_NAME);
  }
  let kwrd = "";
  if (!!readCookie(AFFILIATE_KEYWORD_NAME)) {
    kwrd = readCookie(AFFILIATE_KEYWORD_NAME);
  }
  const saveSummaryClickHandler = () => {
    let summaryParaContent = [];
    appLogger.log("saveSummaryClickHandler");
    createUpdateDocumentUpload();
    if (!!paraContents && Object.keys(paraContents).length > 0) {
      paraContents.map((element, i) =>
        summaryParaContent.push({
          id: element.id,
          paraId: element.paraId,
          content: !!JSON.stringify(allValues) ? JSON.stringify(allValues) : element.content,
          order: element.order,
          createdOn: element.createdOn,
          modifiedOn: element.modifiedOn,
        })
      );
    } else {
      summaryParaContent.push({
        id: EMPTY_GUID,
        paraId: summaryParaGuid,
        content: JSON.stringify(allValues),
        order: 0,
        createdOn: moment(new Date()),
        modifiedOn: moment(new Date()),
      });
    }
    setAllValues([]);
    var para = {
      id: summaryParaGuid,
      docId: docGUID,
      paraContents: [...summaryParaContent],
      order:
        !!summary && !!summary.Paragraphs && !!summary.Paragraphs.order && summary.Paragraphs.order != 0
          ? summary.Paragraphs.order
          : SECTION_ORDER.SUMM,
      paraCD: SECTION_TYPE.SUMM,
      sectionCD: SECTION_CODE.SUMM,
      paraName: !!paraName ? paraName : t("summary.para_name"),
    };
    if (!!summaryParaGuid && summaryParaGuid !== EMPTY_GUID) {
      updateSummaryConnect(docGUID, summaryParaGuid, para)
        .then((response) => {
          console.log(response);
          if (isAffilateUser()) {
            deleteCookie(AFFILIATE_SOURCE_NAME);
            navigate(pathRedirect.next + "?account=" + acc + "&keyword=" + kwrd);
          } else {
            if (IsSingleLoginAPPEnable()) {
              if (isUserLoggedIn()) {
                navigate(pathRedirect.next);
              } else {
                navigateToSignleLoginAPP(locale, docGUID, "rsme", true);
              }
            } else {
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    } else {
      summaryConnect(para)
        .then((response) => {
          appLogger.log("inside summaryConnect then ");
          if (isAffilateUser()) {
            deleteCookie(AFFILIATE_SOURCE_NAME);
            navigate(pathRedirect.next + "?account=" + acc + "&keyword=" + kwrd);
          } else {
            if (IsSingleLoginAPPEnable()) {
              if (isUserLoggedIn()) {
                navigate(pathRedirect.next);
              } else {
                navigateToSignleLoginAPP(locale, docGUID, "rsme", true);
              }
            } else {
              navigate(pathRedirect.next);
            }
          }
          const {
            data: {
              detail: { para },
            },
          } = response;
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    }
  };

  useEffect(() => {
    trackRBEvents("summaryEditView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    if (!!paraContents) {
      setEditorContent(!!paraContents && paraContents.length > 0 ? paraContents[0].content : "");
      setSummaryParaGuid(!!id ? id : EMPTY_GUID);
    }
  }, []);

  let pathRedirect = PathHelper(APP_NAVIGATION.SUMM, funnelStepsComplete);

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  return (
    <>
      <Helmet>
        <title>{t("page_title.summary")}</title>
      </Helmet>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={5} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}

      <section className={classNames(funnelStepsComplete ? "" : "sidebar-sticky", "section main-content templates")}>
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group ">
              {" "}
              <div className="builder-header exp-header">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("summary.headline")),
                    }}
                  />
                  <h4>{t("summary.subheading")}</h4>
                </hgroup>

                <WritingTips section={SECTION_TYPE.SUMM} page="Summary" />
              </div>
            </div>
            <div className="row summary-container">
              <div className="col-md-12 col-lg-9 ">
                <label className="text optional" htmlFor="resume_summary_attributes_content">
                  {t("summary.summary")}
                </label>
                <div className="col-sm-12 col-lg-12 text-editor-container">
                  <div className="writer">
                    <TextEditor
                      sectionCode="SUMM"
                      callback={callBackEditorVal}
                      editorContent={editorContent}
                      placeholder={t("summary.sum_editor_placeholder")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--summary">
                <ResumeMap />
              </div>
            </div>
            <div className="row py-4 mb-5">
              <div className="col ">
                <div className="box__footer box__footer--steps tips-btn-container fbn btn-justify">
                  <Button
                    isLoading={false}
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    id="summary_back_button"
                    ctaText={t("summary.back_action")}
                    onClick={backClickHandler}
                  />
                  <Button
                    isLoading={false}
                    disabled={false}
                    classNames="btn btn-primary btn-lg submit-on-enter save-n-next-btn"
                    id="summary_next_button"
                    ctaText={t("summary.next_action")}
                    onClick={saveSummaryClickHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Summary;
