import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cl06Standard from "./component";

const mapStateToProps = ({ cvltrTemplatePreview, cvltrSignature }) => ({
  cvltrTemplatePreview, cvltrSignature
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cl06Standard);
