import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createCustomSectionParagraph,
  updateCustomSectionParagraph,
} from "./actions";
import CustomSection from "./component";

const mapStateToProps = ({ customsection, funnelStep, docReview }) => ({
  customsection,
  funnelStep,
  docReview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      customSectionConnect: createCustomSectionParagraph,
      updateCustomSectionConnect: updateCustomSectionParagraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomSection);
