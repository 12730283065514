import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import InputRadio from "../input-radio";
import InputText from "../input-text";
import Button from "../button";
import "./custom-section.scss";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../../routing/route-constants";
import { useParams } from "react-router-dom";

const AddSectionModal = ({ show, toggleShow, header, callback }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sections, setSections] = useState(t("review.affiliations"));
  const [otherVal, setOtherVal] = useState("");
  const [checkRadio, setCheckRadio] = useState(false);
  const [addDefault, setAddDefault] = useState(true);

  const { locale } = useParams();
  const others_val = t("review.others");
  const onSectionChange = (e) => {
    const {
      target: { value },
    } = e;
    setSections(value);
    callback(value, "Sections");
    setCheckRadio(value);
    setAddDefault(false);
  };
  const addOtherSectionHandler = (e) => {
    setCheckRadio(others_val);
  };
  const otherSectionChange = (e) => {
    const {
      target: { value },
    } = e;
    setSections("");
    setOtherVal(value);
    setAddDefault(false);
  };

  const buttonClickHandler = () => {
    navigate(pathHelperForSections());
  };
  const pathHelperForSections = () => {
    const selectedCustomSection = !!sections ? sections : otherVal;
    if (!!sections) {
      return !!locale
        ? setOtherSectionURL(
            ROUTE_CONSTANT.OTHER_SECTIONS_LOCALE,
            true,
            selectedCustomSection
          )
        : setOtherSectionURL(
            ROUTE_CONSTANT.OTHER_SECTIONS,
            false,
            selectedCustomSection
          );
    } else {
      return !!locale
        ? setOtherSectionURL(
            ROUTE_CONSTANT.CUSTOM_SECTION_LOCALE,
            true,
            selectedCustomSection
          )
        : setOtherSectionURL(
            ROUTE_CONSTANT.CUSTOM_SECTION,
            false,
            selectedCustomSection
          );
    }
  };
  const setOtherSectionURL = (url, isLocaleDefined, selectedCustomSection) => {
    return !!isLocaleDefined
      ? url
          .replace(
            ":sectionname",
            !!selectedCustomSection ? selectedCustomSection : ""
          )
          .replace(":locale", !!locale ? locale : "en")
      : url.replace(
          ":sectionname",
          !!selectedCustomSection ? selectedCustomSection : ""
        );
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-custom-section"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="affiliations"
              className="string optional"
              value={t("review.affiliations")}
              checked={
                addDefault === true ? t("review.affiliations") : checkRadio
              }
              onChange={onSectionChange}
              defaultChecked={true}
            />
          </div>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="awards"
              className="string optional"
              value={t("review.awards")}
              checked={checkRadio}
              onChange={onSectionChange}
            />
          </div>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="information"
              className="string optional"
              value={t("review.additional_information")}
              checked={checkRadio}
              onChange={onSectionChange}
            />
          </div>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="publications"
              className="string optional"
              value={t("review.publications")}
              checked={checkRadio}
              onChange={onSectionChange}
            />
          </div>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="accomplishments"
              className="string optional"
              checked={checkRadio}
              value={t("review.accomplishments")}
              onChange={onSectionChange}
            />
          </div>
          <div className="form-group">
            <InputRadio
              type="radio"
              name="section[name]"
              id="other"
              className="string optional others_section"
              value={others_val}
              checked={checkRadio}
              onChange={onSectionChange}
            />
            <InputText
              type="text"
              name="other_name"
              placeholder=""
              id="other-name"
              className="string optional input-inline form-control"
              value={otherVal}
              onFocusCallback={addOtherSectionHandler}
              onChange={otherSectionChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-primary section-modal"
            ctaText={t("review.add_new")}
            onClick={buttonClickHandler}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSectionModal;
