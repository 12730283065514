import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootreducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: rootReducer,
//   devTools: true,
// })
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default store;
const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore,
  composeEnhancer(applyMiddleware(thunk))
);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

//export default {store, persistor}
export { store, persistor };
