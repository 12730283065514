import React from "react";
import CoverLetterTemplate from "../../pages/cover-letter-template";
import CoverLetterPreviewButton from "../../components/shared/cvlt-preview-button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import WritingTips from "../../components/writing-tips";
import { SECTION_TYPE } from "../../utils/constants";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CoverLetterPreview = () => {
  return (
    <>
      <div className="preview-section cvrLtr-prv">
        <div className={"intro-page mb-3"}>
          <CoverLetterTemplate />
        </div>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <CoverLetterPreviewButton />
        </div>
      </div>
    </>
  );
};

export default CoverLetterPreview;
