import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_SKILLS_SUCCESS
    FETCH_SKILLS_FAILURE
    RESET_SKILLS
    UPDATE_SKILL_SUCCESS
    UPDATE_SKILL_FAILED
    RENAME_SKILL
`,
  {
    prefix: "skill/",
  }
);
