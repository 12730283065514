import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDocGUID } from "../funnel-step/actions";
import {
  createDesiredCompanyParagraph,
  updateDesiredCompanyParagraph,
} from "./actions";
import CoverLetterDesiredCompany from "./component";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import { previewTemplateAction } from "../cover-letter-template/actions";

const mapStateToProps = ({
  funnelStep,
  desiredCompany,
  cvltrTemplatePreview,
}) => ({
  funnelStep,
  desiredCompany,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      desiredCompanyConnect: createDesiredCompanyParagraph,
      updateDesiredCompanyParagraph: updateDesiredCompanyParagraph,
      SaveDocGUIDConnect: setDocGUID,
      previewTemplateAction: previewTemplateAction,
      updateProgress: updateProgress,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterDesiredCompany);
