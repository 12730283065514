import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Button from "../button";
import "./download-modal.scss";
import RBDropDown from "../dropdown/component";
import { downloadUserResume } from "../../../initialState";

const CoverLetterDownloadModal = ({
  show,
  toggleShow,
  toggleDownloadLoaderModal,
  header,
  inputLabel,
  callback,
  docGUID,
  downloadErrorMessage,
  isRequestForPrint,
}) => {
  const { t } = useTranslation();
  const [chooseFormatVal, setchooseFormatVal] = useState("1");
  const chooseFormatHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setchooseFormatVal(value);
  };

  const downloadCoverLetter = (conversionType) => {
    toggleDownloadLoaderModal();
    callback(chooseFormatVal);
    if (conversionType == "1") {
      downloadUserResume(
        docGUID,
        conversionType,
        "ASPOSE",
        toggleDownloadLoaderModal,
        downloadErrorMessage,
        isRequestForPrint
      );
    } else {
      conversionType = 2;
      let conversionFrom = "ASPOSE";
      if (conversionType == "6") {
        conversionType = 3;
        conversionFrom = "CLOUD";
      } else if (conversionType == "5") {
        conversionFrom = "ADOBE";
      } else if (conversionType == "4") {
        conversionFrom = "ZAMZAR";
      } else if (conversionType == "3") {
        conversionFrom = "SOLID";
      }

      downloadUserResume(
        docGUID,
        conversionType,
        conversionFrom,
        toggleDownloadLoaderModal,
        downloadErrorMessage,
        isRequestForPrint
      );
    }
  };

  const buttonClickHandler = () => {
    callback(chooseFormatVal);
    setchooseFormatVal("");
  };
  let formatList = {
    data: [
      { key: "pdf", value: "1", label: "Adobe PDF (.pdf)" },
      { key: "docx", value: "2", label: "MS Word (.docx)" },
    ],
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-download-section "
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="string optional" htmlFor="experience_start_date">
              {inputLabel}
            </label>
            <RBDropDown
              selectedValue={chooseFormatVal || ""}
              name="download[format]"
              id="download_format"
              options={formatList.data}
              onChange={chooseFormatHandler}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            isLoading={false}
            disabled={false}
            id="download-modal"
            classNames="btn btn-primary btn-lg add-new-section-btn"
            ctaText={t("review.cover_letter_review_download_btn")}
            onClick={() => {
              downloadCoverLetter(chooseFormatVal);
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoverLetterDownloadModal;
