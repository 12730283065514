// Core
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FinalStepTypes from "../funnel-step/types";
import signTypes from "../cover-letter-signature/types";
import { useLocation } from "react-router-dom";
// Style
import "./review.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CoverLetterReviewAddSection from "../../components/cover-letter-review-add-section";
// Plugs
import { useTranslation } from "react-i18next";

// Components
import Header from "../../components/header";
import Footer from "../../components/footer";
import CoverLetterTools from "../../components/cover-letter-tools";
import CoverLetterThumbnail from "../../components/cover-letter-thumbnail";
import PrintBtnGroup from "../../components/print-btn-group";
import AddSectionModal from "../../components/shared/add-section-modal";
import RenameSectionModal from "../../components/shared/rename-section-modal";
import DeletePopupModal from "../../components/shared/delete-popup-modal";
import { DOC_TYPE, SECTION_TYPE } from "../../utils/constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION, AFFILIATE_STORAGE_NAME } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import DownloadLoaderPopup from "../../components/shared/download-loader-popup";
import Cl01Modern from "../../components/cvlt/cover-letter-final-template/cl-01-modern";
import Cl02Elegant from "../../components/cvlt/cover-letter-final-template/cl-02-elegant";
import Cl03Professional from "../../components/cvlt/cover-letter-final-template/cl-03-professional";
import Cl04Classic from "../../components/cvlt/cover-letter-final-template/cl-04-classic";
import Cl05Contemporary from "../../components/cvlt/cover-letter-final-template/cl-05-contemporary";
import Cl06Standard from "../../components/cvlt/cover-letter-final-template/cl-06-standard";
import Cl07Professional from "../../components/cvlt/cover-letter-final-template/cl-07-professional";
import Cl08Modern from "../../components/cvlt/cover-letter-final-template/cl-08-modern";
import { Helmet } from "react-helmet";
import { downloadUserResume } from "../../initialState";
import {
  readCookie,
  deleteCookie,
  getPortalCD,
  setLocaleURL,
  navigateToPaymentMS,
  PerquisiteBountyEnable,
} from "../../utils/helpers";
import appLogger from "../../utils/app-logger";
import CoverLetterDownloadModal from "../../components/shared/cover-letter-download-modal";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { isPaymentMSEnable } from "../../utils/general-config";
const SelectedCoverLetterComponent = ({ name, ...other }) => {
  const SelectiveSection = {
    cl01: Cl01Modern,
    cl02: Cl02Elegant,
    cl03: Cl03Professional,
    cl04: Cl04Classic,
    cl05: Cl05Contemporary,
    cl06: Cl06Standard,
    cl07: Cl07Professional,
    cl08: Cl08Modern,
  };
  const SelectedCoverLetter = SelectiveSection[name];
  if (!SelectedCoverLetter) {
    return null;
  }
  return <SelectedCoverLetter {...other} />;
};

const CoverLetterReview = ({
  getUserCoverLetterConnect,
  docReview,
  funnelStep,
  updateOrderingConnect,
  renameParagraphConnect,
  deleteParagraphConnect,
  updateDocStyleConnect,
  getUserProfileConnect,
  setTemplateConnect,
  updateTemplateCdConnect,
  SaveDocGUIDConnect,
  saveChooseTemplate,
  previewTemplateAction,
  handleSteps,
  resetJobTitleSuccess,
  resetDesiredCompanySuccess,
  resetOpenerRecommendSuccess,
  resetBodyRecommendSuccess,
  resetCloserRecommendSuccess,
  resetSignature,
  resetPersonalInfoSuccess,
  resetExperienceSuccess,
  resetSkillsSuccess,
  updateSignatureSuccess,
  generatePDFPathConnect,
}) => {
  console.log("Cover Letter Review Page");
  let pathRedirect = PathHelper(APP_NAVIGATION.REVW);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useParams();
  const { reviewDoc } = docReview || {};

  // if document is undefined redirect to root path.
  if (reviewDoc === undefined) {
    navigate("/");
  }

  const [fontFamily, setFontFamily] = useState();
  const [templatefontFamily, setTemplateFontFamily] = useState();
  const [fontSize, setFontSize] = useState();
  const [headingfontsize, setHeadingFontSize] = useState("");
  const [coverLetterTemplate, setCoverLetterTemplate] = useState("cl01");
  const [coverLetterCategory, setCoverLetterCategory] = useState("single");
  const [coverLetterPosition, setCoverLetterPosition] = useState("left");
  const [coverLetterPersonalInfo, setCoverLetterPersonalInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [coverLetterOnDashboard, setCoverLetterOnDashboard] = useState(false);
  const [renameVal, setRenameVal] = useState("");
  const [thumbMouseOver, setThumbMouseOver] = useState(false);
  const [selectedHtml, setselectedHtml] = useState("");
  const componentRef = useRef(null);
  const [scrollToSection, setScrollToSection] = useState("");
  const [isUpdated, setIsUpdated] = useState(true);
  const {
    templateCd,
    docGUID,
    currentPara,
    currentParaId,
    userGUID,
    parentUserGuid,
    email,
    personalInfoName,
    docType,
  } = funnelStep || {};
  const [selectedTemplate, setSelectedTemplate] = useState(templateCd);
  const [showLoader, setShowLoader] = useState("d-none");
  const [isLoaded, setIsLoaded] = useState(false);
  let renameSection = "";
  let alreadyFilledDocPara = [];
  let isRequestForPrint = false;
  let filteredDocPara = reviewDoc?.paragraphs || [];
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
    filteredDocPara.map((element) => {
      alreadyFilledDocPara.push(element.paraName);
    });
  }

  const [docSectionDetail, setDocSectionDetail] = useState(filteredDocPara);
  const [isDataLoaded, setIsDataLoaded] = useState(filteredDocPara.length > 0 ? true : false);
  const [downloadCoverLetterModal, setDownloadCoverLetterModal] = useState(false);
  const [downloadLoaderModal, setDownloadLoaderModal] = useState(false);
  const [downloadModalText, setDownloadModalText] = useState(t("review.cover_letter_is_being_built"));
  const [downloadModalTitle, setDownloadModalTitle] = useState(t("review.cover_letter_review_download_btn"));
  const [isDownloadError, setIsDownloadError] = useState(false);
  let docGuid = docGUID;
  let userGuid = userGUID;

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has("docid")) {
    docGuid = queryParams.get("docid");
  }
  if (!docGuid) {
    docGuid = readCookie("docId");
  }
  if (!userGuid) {
    userGuid = readCookie("userguid");
  }

  // if (!docGuid || !userGuid) {
  //   docGuid = readCookie("docId");
  //   userGuid = readCookie("userguid");
  // }
  useEffect(() => {
    trackRBEvents("cvReviewView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGuid,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    setShowLoader("d-block");
    LoadCoverLetter(docGuid, userGuid);
    SaveDocGUIDConnect(docGuid);
    let currentStep = location.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    var funnelStepsComplete = true;
    dispatch({
      type: FinalStepTypes.SET_FUNNEL_STEP_COMPLETE,
      funnelStepsComplete,
    });
    if (docType != DOC_TYPE.COVER_LETTER) {
      let docTypeUpdate = DOC_TYPE.COVER_LETTER;
      dispatch({
        type: FinalStepTypes.SET_DOC_TYPE,
        docTypeUpdate,
      });
    }
    if (!!readCookie("jwtToken")) {
      generatePDFPathConnect(docGuid, userGuid).then((response) => {
        if (response) {
        } else {
          appLogger.log("error while generatePDFPath");
        }
      });
    }
    if (!!readCookie(AFFILIATE_STORAGE_NAME)) {
      readCookie(AFFILIATE_STORAGE_NAME);
      deleteCookie(AFFILIATE_STORAGE_NAME);
    }

    saveChooseTemplate({ templatename: selectedTemplate });

    handleSteps({
      personalInfo: true,
      recipient: true,
      opener: true,
      body: true,
      closer: true,
      signature: true,
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.prevParaName) {
        setScrollToSection(location.state.prevParaName);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!templateCd || templateCd == "") {
      setTemplateConnect("Modern");
    }
  }, [funnelStep]);
  const dispatch = useDispatch();
  useEffect(() => {
    setShowLoader("d-block");
    if (!!reviewDoc && reviewDoc?.paragraphs.length > 0) {
      setIsLoaded(true);
      let filteredDocPara = reviewDoc?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }

      setDocSectionDetail(filteredDocPara.sort((a, b) => a.order - b.order));
    } else {
      if (!!docGuid && !!userGuid) {
        getUserCoverLetterConnect(docGuid, userGuid, t)
          .then((response) => {
            if (!!response && response?.paragraphs.length > 0) {
              setIsLoaded(true);
            }
          })
          .catch((err) => {
            const { detail } = err;
          });
      } else {
        navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
      }
    }
    if (isLoaded) {
      setSelectedTemplate(reviewDoc.templateCD);
      setselectedHtml(componentRef.current);
      setShowLoader("d-none");
    }
  }, [JSON.stringify(reviewDoc)]);

  const LoadCoverLetter = (docGuid, userGuid) => {
    if (!!docGuid && !!userGuid) {
      getUserCoverLetterConnect(docGuid, userGuid, t);
    } else {
      navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE, locale) : ROUTE_CONSTANT.HOME);
    }
  };
  const stateDocStyle =
    !!docReview && !!docReview.reviewDoc && !!docReview.reviewDoc.docStyle ? docReview.reviewDoc.docStyle : {};
  const callBackCoverLetterToolsValue = (fieldValue, fieldId, resetVal, isDocStyleUpdate) => {
    let fontFamilyValue = "";
    let fontSizeValue = "";
    let headingSizeValue = "";
    if (fieldId === "fontFamily") {
      setFontFamily(fieldValue);
      if (!!stateDocStyle.fontFamily == false) {
        fontFamilyValue = fieldValue;
      } else if (!!stateDocStyle.fontFamily && stateDocStyle.fontFamily != fieldValue) fontFamilyValue = fieldValue;
    }
    if (fieldId === "fontSize") {
      setFontSize(fieldValue);
      if (!!stateDocStyle.fontSize == false) {
        fontSizeValue = fieldValue;
      } else if (!!stateDocStyle.fontSize && stateDocStyle.fontSize != fieldValue) fontSizeValue = fieldValue;
    }
    if (fieldId === "headingFontSize") {
      setHeadingFontSize(fieldValue);
      if (!!stateDocStyle.headingSize == false) {
        headingSizeValue = fieldValue;
      } else if (!!stateDocStyle.headingSize && stateDocStyle.headingSize != fieldValue) headingSizeValue = fieldValue;
    }
    if (fieldId === "reset") {
      fontFamilyValue = resetVal.fontFamily;
      fontSizeValue = resetVal.fontSize;
      headingSizeValue = resetVal.headingFontSize;
      setFontFamily(resetVal.fontFamily);
      setFontSize(resetVal.fontSize);
      setHeadingFontSize(resetVal.headingFontSize);
    }
    fontFamilyValue = !!fontFamilyValue ? fontFamilyValue : stateDocStyle.fontFamily;
    fontSizeValue = !!fontSizeValue ? fontSizeValue : stateDocStyle.fontSize;
    headingSizeValue = !!headingSizeValue ? headingSizeValue : stateDocStyle.headingSize;
    if (isDocStyleUpdate) {
      let docStyle = {
        docId: docGuid,
        fontFamily: fontFamilyValue !== null ? fontFamilyValue : "",
        fontSize: fontSizeValue !== null ? fontSizeValue : "",
        headingSize: headingSizeValue !== null ? headingSizeValue : "",
      };
      // console.log("docStyle == ", docStyle);
      updateDocStyleConnect(docGuid, docStyle)
        .then((response) => {
          setIsUpdated(true);
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };

  const paragraphOrderingList = (data) => {
    const docId = docGuid;
    updateOrderingConnect(docId, data)
      .then((response) => {})
      .catch((err) => {
        const { detail } = err;
      });
  };
  const callBackMouseHoverTemplate = (fieldValue) => {
    setTimeout(() => {
      setScrollToSection("");
    }, 1000);
    if (!!fieldValue) {
      setCoverLetterTemplate(fieldValue.key);
    }
  };
  const callBackSelectedTemplate = (fieldValue) => {
    //console.log(fieldValue);
    setselectedHtml(componentRef.current);
    setCoverLetterTemplate(fieldValue.key);
    setTemplateConnect(fieldValue.templatecd);
    updateTemplateCdConnect(docGuid, fieldValue.templatecd);
    let docStyle = {
      fontFamily: fieldValue.resetFontFamily,
      docId: docGuid,
      fontSize: fieldValue.resetFontSize,
      headingSize: fieldValue.resetHeadingFontSize,
    };
    setSelectedTemplate(fieldValue.templatecd);
  };
  const toggleShow = () => setShow((p) => !p);
  const toggleRenameModal = () => {
    setRenameVal(renameSection);
    setShowRenameModal((q) => !q);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((d) => !d);
  };

  const callBackCustomSection = (fieldValue) => {
    //console.log(fieldValue);
  };
  const callBackRenameSection = (newSectionName) => {
    if (!!reviewDoc && !!reviewDoc.paragraphs && !!reviewDoc.paragraphs.length > 0) {
      if (currentPara === SECTION_TYPE.OTHR || currentPara === SECTION_TYPE.CSTM) {
        let para = reviewDoc.paragraphs.find((x) => x.id == currentParaId && x.paraCD == currentPara);
        renameParagraphConnect(para.docId, para.id, currentPara, newSectionName);
      } else {
        let para = reviewDoc.paragraphs.find((x) => x.paraCD == currentPara);
        renameParagraphConnect(para.docId, para.id, currentPara, newSectionName);
      }
    }
    setShowRenameModal(false);
  };

  const callBackDeleteSection = async (newSectionName) => {
    if (!!reviewDoc && !!reviewDoc.paragraphs && !!reviewDoc.paragraphs.length > 0) {
      let para = reviewDoc.paragraphs.find((x) => x.paraName == currentPara);
      let paraName = para ? para.paraName : "";
      await deleteParagraphConnect(para.docId, para.id, para.paraCD);
      setDocSectionDetail([]);
      getUserCoverLetterConnect(docGuid, userGuid, t)
        .then((response) => {
          switch (paraName) {
            case t("opener.desired_position"):
              resetJobTitleSuccess();
              break;

            case t("opener.desired_company"):
              resetDesiredCompanySuccess();
              break;

            case t("opener.opener_recommend"):
              resetExperienceSuccess();
              resetOpenerRecommendSuccess();
              break;

            case t("opener.body_recommend"):
              resetBodyRecommendSuccess();
              break;

            case t("opener.closer_recommend"):
              resetCloserRecommendSuccess();
              break;

            case t("signature.signature"):
              resetSignature();
              break;

            case t("personal_info.para_name"):
              resetPersonalInfoSuccess();
              break;

            case t("opener.experience"):
              resetExperienceSuccess();
              break;

            case t("opener.skills"):
              resetSkillsSuccess();
              break;

            default:
              break;
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
    setShowDeleteModal(false);
  };
  const callBackPrintBtnGroup = (fieldValue) => {
    setShowLoader("d-block");
    if (fieldValue === "btnDownload") {
      getUserProfileConnect(userGuid)
        .then((response) => {
          if (
            (!!response &&
              (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)) ||
            (!!localStorage.getItem("isAdmin") && localStorage.getItem("isAdmin") == "true")
          ) {
            setShowLoader("d-none");
            setDownloadCoverLetterModal((d) => !d);
            setDownloadModalText(t("review.cover_letter_is_being_built"));
            setDownloadModalTitle(t("review.cover_letter_review_download_btn"));
            setIsDownloadError(false);
          } else {
            if (!navigateToPaymentMS(docGuid, locale)) {
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    } else if (fieldValue === "btnPrint") {
      setShowLoader("d-none");
      getUserProfileConnect(userGuid)
        .then((response) => {
          if (
            !!response &&
            (PerquisiteBountyEnable(response.perquisiteBountyKey, userGuid) == true || !!parentUserGuid)
          ) {
            isRequestForPrint = true;
            setShowLoader("d-none");
            toggleDownloadLoaderModal();
            setDownloadModalText(t("review.cover_letter_is_being_print"));
            setDownloadModalTitle(t("review.cover_letter_review_print_btn"));
            downloadUserResume(
              docGuid,
              "1",
              "ASPOSE",
              toggleDownloadLoaderModal,
              downloadErrorMessage,
              isRequestForPrint
            );
            setIsDownloadError(false);
          } else {
            if (!navigateToPaymentMS(docGuid, locale)) {
              navigate(pathRedirect.next);
            }
          }
        })
        .catch((err) => {
          const { detail } = err;
        });
    }
  };
  const setSelectedRenameSection = (name) => {
    renameSection = name;
  };
  const callBackDownloadCoverLetter = (formatName) => {
    //console.log(formatName);
    setDownloadCoverLetterModal(false);
  };
  const toggleDownloadModal = () => {
    setDownloadCoverLetterModal((d) => !d);
  };
  const toggleDownloadLoaderModal = () => {
    setDownloadLoaderModal((l) => !l);
  };
  const downloadErrorMessage = (isError) => {
    if (isError === "error") {
      setIsDownloadError(true);
      setDownloadModalText(t("review.download_error_message"));
    } else {
      setIsDownloadError(false);
      setDownloadModalText(t("review.cover_letter_is_being_built"));
    }
  };

  useEffect(() => {
    let updatePreviewTemplate = {};
    let signature = {};
    let fullName = "";
    docSectionDetail.length > 0 &&
      docSectionDetail.map((item) => {
        let data;
        if (item.paraContents && item.paraContents.length > 0) {
          data = JSON.parse(item.paraContents[0].content);
          if (item.paraName === t("signature.signature")) {
            signature = item;
          }
          if (item.paraName === t("personal_info.para_name")) {
            fullName = data.fullName;
          }
          data &&
            Object.entries(data).map((object) => {
              return (updatePreviewTemplate[`${object[0]}`] = `${object[1]}`);
            });
        }
      });
    updatePreviewTemplate["fullName"] = fullName;
    previewTemplateAction(updatePreviewTemplate);
    dispatch(updateSignatureSuccess(signature));
  }, [docSectionDetail]);
  // const navigateToPaymentMS = () => {
  //   // navigate(pathRedirect.next);
  //   let isPmsEnable = readCookie("pms");
  //   if ((!!isPmsEnable && isPmsEnable === "true") || isPaymentMSEnable() === true) {
  //     createCookie("docId", docGuid, 1);
  //     window.location.assign(!!locale && locale !== "en" ? `/app/${locale}/billing` : "/app/billing");
  //   } else {
  //     navigate(pathRedirect.next);
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{t("page_title.review")}</title>
      </Helmet>
      <Header activeStep={6} />

      {isLoaded && (
        <section className="section main-content review">
          <div className="container">
            <div className="alignment-container">
              <div className="row review">
                <div className="col-md-9 col-lg-9">
                  <div className="resume-buttons">
                    <PrintBtnGroup
                      callback={callBackPrintBtnGroup}
                      thumbMouseOver={thumbMouseOver}
                      content={selectedHtml}
                    ></PrintBtnGroup>
                  </div>
                  <ReactLoader showloader={showLoader} />
                  <SelectedCoverLetterComponent
                    name={coverLetterTemplate}
                    data={docSectionDetail}
                    headingfontsize={headingfontsize}
                    docReview={docReview}
                    coverLetterTemplate={coverLetterTemplate}
                    coverLetterPosition={coverLetterPosition}
                    coverLetterCategory={coverLetterCategory}
                    coverLetterPersonalInfo={coverLetterPersonalInfo}
                    dataLoaded={isDataLoaded}
                    paragraphOrdering={paragraphOrderingList}
                    coverLetterOnDashboard={coverLetterOnDashboard}
                    toggleRenameModal={toggleRenameModal}
                    toggleDeleteModal={toggleDeleteModal}
                    setSelectedRenameSection={setSelectedRenameSection}
                    fontFamily={fontFamily}
                    fontSize={fontSize + "em"}
                    componentRef={componentRef}
                    scrollToSection={scrollToSection}
                  ></SelectedCoverLetterComponent>
                  <div className="resume-buttons">
                    <PrintBtnGroup callback={callBackPrintBtnGroup} content={componentRef.current}></PrintBtnGroup>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 resume-sidebox-wrapper">
                  <div id="templates-selector" className="resume-sidebox sb-template box">
                    <div className="box__header box__header--small">
                      <h4 className="box-title">{t("review.cover_letter_designs")}</h4>
                    </div>
                    <div
                      id="change-template"
                      className="resume-sidebox__inner sb-template__change"
                      data-reload-url="/resume/dada38e2-4012-40b1-b40a-863e737232d2/change_template"
                    >
                      <div className="sb-template__change-inner">
                        <CoverLetterThumbnail
                          callback={callBackMouseHoverTemplate}
                          selectedTemplateCallBack={callBackSelectedTemplate}
                          selectedTemplate={selectedTemplate}
                          setThumbMouseOver={setThumbMouseOver}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resume-sidebox">
                    <CoverLetterReviewAddSection
                      toggleShow={toggleShow}
                      alreadyFilledPara={alreadyFilledDocPara}
                    ></CoverLetterReviewAddSection>
                  </div>
                  <div className="resume-sidebox box">
                    <div className="box__header box__header--small">
                      <h4 className="box-title">{t("review.cover_letter_tools")}</h4>
                    </div>
                    <div className="resume-sidebox__inner">
                      {isUpdated && (
                        <CoverLetterTools
                          callback={callBackCoverLetterToolsValue}
                          selectedTemplate={selectedTemplate}
                          fontFamily={templatefontFamily}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <AddSectionModal
        message="hei you"
        show={show}
        toggleShow={toggleShow}
        header={t("review.custom_modal_title")}
        callback={callBackCustomSection}
      />
      <RenameSectionModal
        message="hei you"
        show={showRenameModal}
        toggleShow={toggleRenameModal}
        setRenameVal={setRenameVal}
        renameVal={renameVal}
        header={t("review.edit_section_name")}
        callback={callBackRenameSection}
      />
      <DeletePopupModal
        message={t("review.delete_popup_message")}
        show={showDeleteModal}
        toggleShow={toggleDeleteModal}
        header={t("review.delete")}
        callback={callBackDeleteSection}
      />
      <CoverLetterDownloadModal
        message=""
        toggleShow={toggleDownloadModal}
        toggleDownloadLoaderModal={toggleDownloadLoaderModal}
        show={downloadCoverLetterModal}
        inputLabel={t("review.review_choose_format")}
        header={t("review.cover_letter_review_download_heading")}
        callback={callBackDownloadCoverLetter}
        docGUID={docGuid}
        downloadErrorMessage={downloadErrorMessage}
        isRequestForPrint={isRequestForPrint}
      />
      <DownloadLoaderPopup
        message=""
        toggleShow={toggleDownloadLoaderModal}
        show={downloadLoaderModal}
        inputLabel={downloadModalText}
        header={downloadModalTitle}
        docGUID={docGuid}
        isDownloadError={isDownloadError}
      />
      <Footer />
    </>
  );
};
export default CoverLetterReview;
