import { createParagraph } from "../../initialState";
import DocBuilderService from "../../services/doc-builder-api";
import {
  EMPTY_GUID,
  SECTION_CODE,
  SECTION_ORDER,
  SECTION_TYPE,
} from "../../utils/constants";
import Types from "./types";

const createJobTitleParagraph = (data, paragraph, paraName, docGUID) => {
  return function (dispatch) {
    if (docGUID === "" || docGUID == undefined) {
      return DocBuilderService.createDoc(data).then((response) => {
        if (response) {
          let para = createParagraph(
            response.docGuid,
            EMPTY_GUID,
            EMPTY_GUID,
            paragraph,
            SECTION_ORDER.OPNR,
            SECTION_TYPE.OPNR,
            SECTION_CODE.OPNR,
            paraName
          );
          DocBuilderService.createParagraph(response.docGuid, para).then(
            (paraResponse) => {
              dispatch(saveJobTitleSuccess(paraResponse, paraResponse.id));
            }
          );
        }
        return response;
      });
    } else {
      let para = createParagraph(
        docGUID,
        EMPTY_GUID,
        EMPTY_GUID,
        paragraph,
        SECTION_ORDER.OPNR,
        SECTION_TYPE.OPNR,
        SECTION_CODE.OPNR,
        paraName
      );
      return DocBuilderService.createParagraph(docGUID, para).then(
        (paraResponse) => {
          dispatch(saveJobTitleSuccess(paraResponse, paraResponse.id));
          let response = {
            docGuid: docGUID,
          };
          return response;
        }
      );
    }
  };
};

const updateJobTitleParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateJobTitleSuccess(response));
        }
        return response;
      })
      .catch((error) => {});
  };
};

const saveJobTitleSuccess = (data, paraId) => ({
  type: Types.SAVE_SUCCESS,
  data,
  paraId,
});

export const updateJobTitleSuccess = (data) => ({
  type: Types.UPDATE_SUCCESS,
  data,
});

export const resetDesiredPosition = () => ({
  type: Types.RESET_JOB_TITLE,
});

export { createJobTitleParagraph, updateJobTitleParagraph };
