import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignIn from "./component";
import { signInUser, getUserDocs, getUserDocsByTypeOfDoc } from "./actions";
import {
  signUpExternalUser,
  eventServicePost,
  loginAttempt,
} from "../sign-up/actions";
import { setDocType } from "./../funnel-step/actions";
const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signInUserConnect: signInUser,
      signUpExternalUserConnect: signUpExternalUser,
      getUserDocsByTypeOfDocConnect: getUserDocsByTypeOfDoc,
      eventServicePostConnect: eventServicePost,
      setDocTypeConnect: setDocType,
      loginAttemptConnect: loginAttempt,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
