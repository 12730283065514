import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cl01Modern from "./component";

const mapStateToProps = ({ cvltrTemplatePreview }) => ({
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cl01Modern);
