import Types from "./types";
import UserService from "../../services/user-service-api";
import FunnelStepsTypes from "../../pages/funnel-step/types";

const getUserObject = () => {
  return function (dispatch) {
    return UserService.getUserObject()
      .then((response) => {
        if (!!response) {
          let userGuid = response.userGuid;
          let email = response.email;
          var validUserGuid = userGuid;
          if (userGuid.includes("-") == false) {
            validUserGuid = userGuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
          }
          let parentUserGuid = response.parentUserGuid;

          dispatch(PushDataToUserInfo(validUserGuid, email, parentUserGuid));
        }
        return response;
      })
      .catch((error) => {});
  };
};
const validateLgctToken = (token) => {
  return function (dispatch) {
    return UserService.validateLgctExpiry(encodeURIComponent(token))
      .then((response) => {
        if (!!response) {
        }
        return response;
      })
      .catch((error) => {
        return false;
      });
  };
};

const PushDataToUserInfo = (userGUID, email, parentUserGuid) => ({
  type: FunnelStepsTypes.SET_USERINFO,
  userGUID,
  email,
  parentUserGuid,
});

export { getUserObject, validateLgctToken };
