import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_LANGUAGES_SUCCESS
    FETCH_LANGUAGES_FAILURE
    RESET_LANGUAGES
    UPDATE_LANGUAGE_SUCCESS
    UPDATE_LANGUAGE_FAILED
    RENAME_LANGUAGE
`,
  {
    prefix: "language/",
  }
);
