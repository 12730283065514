import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  uploadResponse: null,
};

export const resetUploadResume = () => {
  return { ...INITIAL_STATE };
};

export const updateUploadResponse = (state = INITIAL_STATE, { data }) => {
  return { ...state, uploadResponse: data, isLoading: true };
};

export const HANDLERS = {
  [Types.SUCCESS]: updateUploadResponse,
  [Types.RESET_UPLOAD_RESUME]: resetUploadResume,
};

export default createReducer(INITIAL_STATE, HANDLERS);
