import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Confirmation from "./component";
import { getUserDocs, getUser, setConfirmation, getBillingInfo } from "./actions";
import { getUserProfile } from "../checkout-page/actions";
import { getUserResume } from "../cover-letter-review/actions";

const mapStateToProps = ({ ccpage, funnelStep }) => ({
  ccpage,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDocsConnect: getUserDocs,
      getUserCoverLetterConnect: getUserResume,
      getUserConnect: getUser,
      getUserProfileConnect: getUserProfile,
      setConfirmationConnect: setConfirmation,
      getBillingInfoConnect: getBillingInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
