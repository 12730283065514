import React from "react";
import Button from "../../../../shared/button";
import { checkEmpty } from "../../../../../utils/helpers";
import { useTranslation } from "react-i18next";

const PersonaInfo = ({ provided, paraNameEditHandler, coverLetterSection }) => {
  const { t } = useTranslation();

  const checkEmptyPersonalInfoFields = (param) => {
    return !!coverLetterSection.personalInformation && !checkEmpty(param)
      ? param
      : "";
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="resume__section resume__heading resume__contact">
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={paraNameEditHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <div className="cvltr-headingname cvltr-dother-none">
          <h1>
            {checkEmptyPersonalInfoFields(
              coverLetterSection.personalInformation.fullName
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PersonaInfo;
