import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skill from "./component";
import { createParagraph, updateSkillParagraph } from "./actions";

const mapStateToProps = ({ skills, funnelStep }) => ({
  skills,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSkillsParagraph: createParagraph,
      updateSkillsParagraph: updateSkillParagraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Skill);
