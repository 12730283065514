import React, { useState, useEffect, useRef } from "react";
import "./checkout.styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text";
import RBDropDown from "../../components/shared/dropdown/component";
import Footer from "../../components/footer";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { getExpMonthList } from "../../services/master-data";
import { getExpYearList } from "../../services/master-data";
import appLogger from "../../utils/app-logger";
import PathHelper from "../../utils/path-helper";
import CryptoJS from "crypto-js";
import ReactLoader from "../../components/shared/loader";
import axios from "axios";
import { Overlay, Tooltip } from "react-bootstrap";
import qs from "qs";
import dompurify from "dompurify";
import { readCookie, getPortalCD, PerquisiteBountyEnable } from "../../utils/helpers";
import { APP_NAVIGATION } from "../../utils/constants";
import Logo from "../../components/header/navbar/logo";
import CreateUUID from "../../utils/helpers";
import ScrollToTop from "../../components/scroll-to-top/component";
import { trackRBEvents } from "../../utils/tracking-events";
import { defaultIPAddress, defaultCountryCode, defaultCurrencyCode } from "../../utils/general-config";
import {
  getTokenExID,
  getTokenExSecretKey,
  getTokenExScheme,
  getTokenExApiKey,
  getTokenExJsURL,
  getPaypalHostUrl,
  getPaypalClientId,
  getPaypalClientSecret,
} from "../../utils/payment-config";
const CheckoutPage = ({
  checkoutConnect,
  funnelStep,
  skuConnect,
  checkout,
  orderConnect,
  isLogoDisabled,
  getUserProfileConnect,
  eventServicePostConnect,
}) => {
  window.$ = $;
  window.jQuery = $;
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const [expMonthList, setExpMonthList] = useState(getExpMonthList());
  const [expYearList, setExpYearList] = useState(getExpYearList());
  const queryParams = new URLSearchParams(window.location.search);
  const { userGUID, parentUserGuid, email, personalInfoName } = funnelStep || {};
  const { isLoading } = checkout;
  const [skuGuid, setskuGuid] = useState(!!checkout && !!checkout.skuGuid ? checkout.skuGuid : "");
  const [skuGuidDetails, setSkuGuidDetails] = useState(
    !!checkout && !!checkout.skuGuidDetails ? checkout.skuGuidDetails : ""
  );
  const [skuDetailsText, setSkuDetailsText] = useState("");
  const [skuDetailsDownContent, setSkuDetailsDownContent] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [isShowError, setIsShowError] = useState("d-none");
  const { locale } = useParams();
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [recurringPrice, setRecurringPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  // let pathRedirect = PathHelper(window.location.pathname);
  let pathRedirect = PathHelper(APP_NAVIGATION.CHPG);
  useEffect(() => {
    trackRBEvents("checkoutView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
  }, []);
  useEffect(() => {
    setShowLoader("d-block");
    skuDetails();
    getUserProfileConnect(userGUID)
      .then((response) => {
        if (
          !!response &&
          (PerquisiteBountyEnable(response.perquisiteBountyKey, userGUID) == true || !!parentUserGuid)
        ) {
          navigate(pathRedirect.cstm);
        }
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
    if (queryParams.has("pplrtrn") && queryParams.has("token")) {
      setShowLoader("d-block");
      excecutePaypalProcess(queryParams.get("token"));
    }

    loadScript("/app/tokenEXscript.js");
    return () => {
      removeScript("/app/tokenEXscript.js");
    };
  }, [isLoading]);

  const skuDetails = () => {
    skuConnect(queryParams.get("s"), locale)
      .then((response) => {
        // appLogger.log("inside skuConnect then ");
        setSkuGuidDetails(response);
        let _skudetailsText = "";
        if (response.skuSubCategory.startsWith("M") && response.skuSubCategory.endsWith("000")) {
          _skudetailsText = t("checkout.footer_full_access_plan");
        } else if (response.skuSubCategory.startsWith("M") && response.skuSubCategory.endsWith("195")) {
          _skudetailsText = t("checkout.footer_limited_access_plan");
        } else {
          _skudetailsText = t("checkout.footer_annual_access_plan");
        }
        _skudetailsText = _skudetailsText
          .replace("{skuGuidDetails.currencySymbol}", response.currencySymbol)
          .replace("{skuGuidDetails.unitPrice}", response.unitPrice)
          .replace("{skuGuidDetails.currencySymbol}", response.currencySymbol)
          .replace("{skuGuidDetails.recurringPrice}", parseFloat(response.recurringPrice).toFixed(2));
        setSkuDetailsText(_skudetailsText);
        if (queryParams.has("pplrtrn") == false) {
          setShowLoader("d-none");
        }
        setRecurringPrice(parseFloat(response.recurringPrice).toFixed(2));
        setUnitPrice(parseFloat(response.unitPrice).toFixed(2));
        setDynamicTitle(response.label);
        let monthOrYear = "";
        if (response.scheduleTypeCD == "MONTH") {
          monthOrYear = "month";
        } else {
          monthOrYear = "year";
        }
        let _skuDetailsDownContent = t("checkout.down_content");
        _skuDetailsDownContent = _skuDetailsDownContent.replace("{skuDetailsDownContent.scheduleTypeCD}", monthOrYear);
        setSkuDetailsDownContent(_skuDetailsDownContent);
        const {
          data: {
            detail: { data },
          },
        } = response;
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };
  const loadScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    document.head.appendChild(script);
  };

  const removeScript = (url) => {
    var scripts = document.getElementsByTagName("script");
    for (let script of scripts) {
      if (script.src === window.location.origin + url) {
        script.remove();
        break;
      }
    }
  };
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [ExpMonth, setExpMonth] = useState("");
  const [ExpYear, setExpYear] = useState("");

  const nameOnCard = (e) => {
    const {
      target: { value },
    } = e;
    setFullName(value);
  };
  const addExpMonthHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setExpMonth(value);
  };
  const addEXpYearHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setExpYear(value);
  };

  const setIPConfig = (param, defaultValue) => {
    if (localStorage.getItem("ipconfig") !== null) {
      let ipConfigData = JSON.parse(localStorage.getItem("ipconfig"));
      return ipConfigData[param];
    } else {
      return defaultValue;
    }
  };
  let customFieldsData = {
    ipcountry: readCookie("country"),
    protalsrc: getPortalCD(locale),
    localeval: locale,
  };
  const onPaymentClickHandler = () => {
    window.reactFunction = () => {
      setShowLoader("d-block");
      const name = fullName.split(" ");
      let fname = name[0];
      let lname = name.length > 1 ? fullName.substring(fullName.indexOf(" ") + 1) : "";

      let data = {
        firstName: fname,
        lastName: lname,
        email: email,
        paymentPortal: getPortalCD(locale),
        skuGuid: queryParams.get("s"),
        userGuid: userGUID,
        visitid: readCookie("visitGuid"),
        visitorid: readCookie("visitorGuid"),
        portalSource: getPortalCD(locale),
        customFields: customFieldsData,
        customerSource: "",
        paypalToken: "",
        ipAddress: readCookie("ipAddress"),
        ccDetails: {
          cardType: document.getElementById("hdnCardType").value,
          token: document.getElementById("tokenExToken").value,
          ccExpiryMonth: ExpMonth,
          ccExpiryYear: ExpYear,
          cvv: "0",
        },
      };
      checkoutConnect(data)
        .then((response) => {
          // appLogger.log("inside checkoutConnect then ");

          trackRBEvents("paymentSuccess", {
            userGuid: userGUID,
            portalSource: getPortalCD(locale),
            eventLabel: "payment Clicked",
            email: email,
            firstName: fname,
            lastName: lname,
          });
          let eventData = {
            Id: CreateUUID(),
            UserId: userGUID,
            VisitorId: readCookie("visitorGuid"),
            VisitId: readCookie("visitGuid"),
          };

          eventServicePostConnect(eventData).then((response) => {});
          if (!!response) {
            navigate(pathRedirect.next);
          } else {
            setShowLoader("d-none");
            setIsShowError("d-block");
          }
        })
        .catch((err) => {
          const { detail } = err;
          trackRBEvents("paymentFailure", {
            userGuid: userGUID,
            portalSource: getPortalCD(locale),
            eventLabel: "payment Clicked",
            email: email,
            firstName: fname,
            lastName: lname,
          });
          appLogger.log(detail); // TODO
        });
    };
  };

  const getUTCTimeStamp = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      ("0" + date.getUTCDate()).slice(-2) +
      ("0" + date.getUTCHours()).slice(-2) +
      ("0" + date.getUTCMinutes()).slice(-2) +
      ("0" + date.getUTCSeconds()).slice(-2)
    );
  };
  const createHmac = () => {
    var concatinatedString =
      getTokenExID() + "|" + window.location.origin + "|" + getUTCTimeStamp() + "|" + getTokenExScheme();
    var hash = CryptoJS.HmacSHA256(concatinatedString, getTokenExSecretKey());
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    return hashInBase64;
  };

  const createPaypalAccessToken = () => {
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      grant_type: "client_credentials",
    });
    var auth = "Basic " + btoa(getPaypalClientId() + ":" + getPaypalClientSecret());
    var config = {
      method: "post",
      url: getPaypalHostUrl() + "/v1/oauth2/token",
      headers: {
        Authorization: auth,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (!!response && !!response.data) {
          localStorage.setItem("ppat", response.data.access_token); //ppat=>PayPal Access Token
          createBillingAgreementToken(response.data.access_token);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const createBillingAgreementToken = (accessToken) => {
    let returnUrl = window.location.href + "&pplrtrn=true";
    let cancelUrl = window.location.href + "&cancel=true";

    var data = JSON.stringify({
      description: "ResumeBuild Subscription",
      payer: {
        payment_method: "Paypal",
        payer_info: {
          email: email,
        },
      },
      plan: {
        type: "MERCHANT_INITIATED_BILLING",
        merchant_preferences: {
          return_url: returnUrl,
          cancel_url: cancelUrl,
          accepted_pymt_type: "INSTANT",
          skip_shipping_address: true,
          immutable_shipping_address: true,
        },
      },
    });
    let access_token = "Bearer " + accessToken;
    var config = {
      method: "post",
      url: getPaypalHostUrl() + "/v1/billing-agreements/agreement-tokens",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        localStorage.setItem("pptoken_id", response.data.token_id); //pp=>PayPal
        window.location = response.data.links[0].href;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const excecutePaypalProcess = (token) => {
    setShowLoader("d-block");
    let data = {
      firstName: email,
      lastName: "",
      email: email,
      paymentPortal: getPortalCD(locale),
      skuGuid: queryParams.get("s"),
      userGuid: userGUID,
      visitid: readCookie("visitGuid"),
      visitorid: readCookie("visitorGuid"),
      portalSource: getPortalCD(locale),
      customFields: customFieldsData,
      customerSource: "",
      paypalToken: token,
      ipAddress: readCookie("ipAddress"),
      ccDetails: null,
    };
    checkoutConnect(data)
      .then((response) => {
        trackRBEvents("paymentSuccess", {
          userGuid: userGUID,
          portalSource: getPortalCD(locale),
          eventLabel: "payment Clicked",
          email: email,
        });
        appLogger.log("inside checkoutConnect then ");
        if (!!response) {
          navigate(pathRedirect.next);
        } else {
          setShowLoader("d-none");
          setIsShowError("d-block");
        }
        const {
          data: {
            detail: { data },
          },
        } = response;
      })
      .catch((err) => {
        trackRBEvents("paymentFailure", {
          userGuid: userGUID,
          portalSource: getPortalCD(locale),
          eventLabel: "payment Clicked",
          email: email,
        });
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };

  const paypalHandler = () => {
    setShowLoader("d-block");
    let acccessToken = createPaypalAccessToken();
  };

  const [show, setShow] = useState(false);

  const handleMouseOver = () => {
    setShow(true);
  };

  const handleMouseOut = () => {
    setShow(false);
  };

  const target = useRef(null);

  return (
    <>
      {" "}
      <style>
        {`
            .d-hotline.h-btn {
              display: none;
            }
            #fc_frame.fc-widget-small{
          display: none;
      }
          `}
      </style>
      <ScrollToTop />
      <Helmet>
        <title>{t("page_title.checkout")}</title>
      </Helmet>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <img
              className="rb-logo"
              src={process.env.PUBLIC_URL + "/static/images/resumebuild-logo.svg"}
              width="117"
              alt="Logo"
            />
          </div>
        </nav>
      </header>
      {/* <Header isShow={"d-none"} isLogoDisabled={true} /> */}
      {isLoading && (
        <>
          <input type="hidden" id="txOrigin" value={window.location.origin} />
          <input type="hidden" id="txID" value={getTokenExID()} />
          <input type="hidden" id="txTokenScheme" value={getTokenExScheme()} />
          <input type="hidden" id="hdnCardType" value="" />
          <input type="hidden" id="tokenExToken" value="" />
          <input type="hidden" id="hmac" value={createHmac()} />
          <input type="hidden" id="timeStamp" value={getUTCTimeStamp()} />
          <input type="hidden" id="cardNumberText" value={t("checkout.card_number")} />

          <ReactLoader showloader={showLoader} />
          <section className="checkout">
            <div className="container">
              <div className="tooltip-wrap">
                <button className="ssl-tooltip" ref={target} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      x="0"
                      y="0"
                      enableBackground="new 0 0 448 512"
                      version="1.1"
                      viewBox="0 0 448 512"
                      xmlSpace="preserve"
                    >
                      <path
                        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                        className="st0"
                      ></path>
                    </svg>
                  </span>
                  <span className="ssl-tooltip-btn">
                    {t("checkout.secure_server")}
                    <br />
                    <small>{t("checkout.secure_server_more")}</small>
                  </span>
                </button>
                <span className="ssl-tooltiptext">
                  <Overlay target={target.current} show={show} placement="left">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        {t("checkout.secure_server_content")}
                      </Tooltip>
                    )}
                  </Overlay>
                </span>
              </div>

              <div className="row">
                <div className="col-md-12 col-lg-7">
                  <div className="card-details">
                    <h1> {t("checkout.heading")} </h1>
                    <div className="payment__cards-container ">
                      <ul className="payment__cards col-8">
                        <li>
                          <img src="/app/static/images/card-visa.svg" />
                        </li>
                        <li>
                          <img src="/app/static/images/card-mc.svg" />
                        </li>
                        <li>
                          <img src="/app/static/images/card-amex.svg" />
                        </li>
                        <li>
                          <img src="/app/static/images/card-discover.svg" />
                        </li>
                        <li>
                          <img src="/app/static/images/diners-club-card.svg" />
                        </li>
                        <li className="jbc-card">
                          <img src="/app/static/images/JCB-card.svg" />
                        </li>
                      </ul>
                      <div className="all-card">
                        <img src="/app/static/images/grey-card.jpg" />
                      </div>
                      <div className={readCookie("currency") == "ZAR" ? "d-none" : "payment-options"}>
                        <div className="paypalBtnCC pay-options-mark paypalmark">
                          <img
                            isLoading={false}
                            disabled={false}
                            onClick={paypalHandler}
                            classNames="btn btn-primary btn-lg submit-on-enter w-100"
                            ctaText="Paypal"
                            src="/app/static/images/pay-new-pp.svg"
                          />
                          {/* <a href="/paypal/checkout?plan_id=annual_50_row%7C4136120">
                                                                    <img src="/app/static/images/pay-new-fb.svg" />
                                                                  </a> */}
                        </div>
                      </div>
                    </div>
                    <div className={isShowError}>
                      <p className="alert error small">{t("checkout.error")}</p>
                    </div>
                    <div className="payment-forms">
                      <div className="row">
                        <div className="col checkoutpage-user-logo ">
                          <InputText
                            showLabel={true}
                            id="checkout_form_card_name"
                            text={t("checkout.name")}
                            type="text"
                            required
                            style={{ paddingLeft: 50 }}
                            onChange={nameOnCard}
                            value={fullName}
                          />

                          <span id="billing-card-name-flag" className="flagger red hidden">
                            {t("checkout.name_error")}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {}
                          <div className="card_number fsd" id="cardnumber">
                            <label htmlFor="card-number" className=" collapseitcc">
                              {t("checkout.card_number")}
                            </label>

                            <div id="tokenExIframeDiv" className="form-control collapseitcc security-field"></div>
                            <span id="card-number-flag" className="flagger red hidden">
                              {t("checkout.card_error")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row exp-date-year">
                        <div className="col" id="expMonthDiv">
                          <RBDropDown
                            id="expmo"
                            showLabel={true}
                            text={t("checkout.exp_month")}
                            selectedValue={ExpMonth || ""}
                            classNames="ccMonthExp"
                            index={0}
                            options={expMonthList.data}
                            onChange={addExpMonthHandler}
                          />
                          <span id="billing-card-exp-month-flag" className="flagger red hidden">
                            {t("checkout.exp_month_error")}
                          </span>
                        </div>
                        <div className="col" id="expYearDiv">
                          <RBDropDown
                            id="expyr"
                            showLabel={true}
                            classNames="ccYearExp"
                            text={t("checkout.exp_year")}
                            selectedValue={ExpYear || ""}
                            index={0}
                            options={expYearList.data}
                            onChange={addEXpYearHandler}
                          />
                          <span id="billing-card-exp-year-flag" className="flagger red hidden">
                            {t("checkout.exp_year_error")}
                          </span>
                        </div>
                        <div className="col" id="CVV">
                          {}
                          <div className="card_date" id="cvvDiv">
                            <label
                              htmlFor="billing-card-cvv"
                              className="form-label"
                              dangerouslySetInnerHTML={{
                                __html: t("checkout.securtity_Code"),
                              }}
                            />

                            <div id="card-cvv-element" className="form-control collapseitcc security-field"></div>
                            <span id="cvv-flag" className="flagger red hidden">
                              {t("checkout.securtity_Code_error")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row  align-items-center">
                        <div className="col-10">
                          <div className="fbn bounce-effect">
                            <Button
                              id="btnChargeebeeSubmit"
                              isLoading={false}
                              disabled={false}
                              onClick={onPaymentClickHandler}
                              classNames="btn btn-primary btn-lg submit-on-enter w-100 checkout-btn"
                              ctaText={t("checkout.pay_securely")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="ssl-logo">
                            <img src="/app/static/images/ssl-seal-gold.png" />
                          </div>
                        </div>
                      </div>
                      <div className="small">
                        <img src="/app/static/images/lock.svg" />
                        {t("checkout.secure_checkout")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-bottom"
                    dangerouslySetInnerHTML={{
                      __html: skuDetailsDownContent,
                    }}
                  />
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="payment-info-container">
                    <div className="payment-header">
                      <h3> {t("checkout.review_your_order")} </h3>
                    </div>
                    <div className="payment-info">
                      <p className="payment-info-plan">
                        <strong> {t("checkout.item")}: </strong>
                        {dynamicTitle}
                      </p>
                      <ul>
                        <li> {t("checkout.writing_tips1")} </li>
                        <li> {t("checkout.writing_tips2")} </li>
                        <li> {t("checkout.writing_tips3")} </li>
                        <li> {t("checkout.writing_tips4")} </li>
                      </ul>
                    </div>
                    <div className="payment-summary">
                      {t("checkout.total_due")}:{" "}
                      <span style={{ color: "#2ebe6e" }}>
                        {skuGuidDetails.currencySymbol}
                        {skuGuidDetails.scheduleTypeCD == "YEAR" ? recurringPrice : unitPrice}
                        <span className="payment-summary--total total">{}</span>
                      </span>
                    </div>
                    <div
                      className={skuGuidDetails.scheduleTypeCD == "MONTH" ? "payment-footer" : "d-none payment-footer"}
                      dangerouslySetInnerHTML={{
                        __html: skuDetailsText,
                      }}
                    />
                    <div
                      className={skuGuidDetails.scheduleTypeCD === "YEAR" ? "payment-footer" : "d-none payment-footer"}
                      dangerouslySetInnerHTML={{
                        __html: skuDetailsText,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default CheckoutPage;
