import appLogger from "../../utils/app-logger";
import Types from "./types";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";

const getPlans = (userGUID, data) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.getUserPlan(userGUID, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside getPlans");
          //dispatch(savePersonalInfoSuccess(response.data.docGuid));
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
export { getPlans };
