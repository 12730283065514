import React, { useEffect, useState } from "react";
import RBDropDown from "../shared/dropdown/component";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import "./cover-letter-list.scss";
import { useParams, useNavigate } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import Cl01Modern from "../cvlt/cover-letter-final-template/cl-01-modern";
import Cl02Elegant from "../cvlt/cover-letter-final-template/cl-02-elegant";
import Cl03Professional from "../cvlt/cover-letter-final-template/cl-03-professional";
import Cl04Classic from "../cvlt/cover-letter-final-template/cl-04-classic";
import Cl05Contemporary from "../cvlt/cover-letter-final-template/cl-05-contemporary";
import Cl06Standard from "../cvlt/cover-letter-final-template/cl-06-standard";
import Cl07Professional from "../cvlt/cover-letter-final-template/cl-07-professional";
import Cl08Modern from "../cvlt/cover-letter-final-template/cl-08-modern";

const SelectedCoverLetterComponent = ({ name, ...other }) => {
  if (name == "Executive I") {
    name = "ExecutiveI";
  }
  const SelectiveSection = {
    Modern: Cl01Modern,
    Elegant: Cl02Elegant,
    Professional: Cl03Professional,
    Classic: Cl04Classic,
    Contemporary: Cl05Contemporary,
    Standard: Cl06Standard,
    Professional1: Cl07Professional,
    Modern1: Cl08Modern,
  };
  const SelectedCoverLetter = SelectiveSection[name];
  if (!SelectedCoverLetter) {
    return null;
  }
  return <SelectedCoverLetter {...other} />;
};
const CoverLetterList = ({ callback, coverLetterOptions, selectedCoverLetter, componentRef }) => {
  const { t } = useTranslation();
  const [coverLetter, setCoverLetter] = useState("");
  const [headingfontsize, setHeadingFontSize] = useState("1.65");
  const [coverLetterTemplate, setCoverLetterTemplate] = useState("cl01");
  const [coverLetterCategory, setCoverLetterCategory] = useState("single");
  const [coverLetterPosition, setCoverLetterPosition] = useState("left");
  const [coverLetterPersonalInfo, setCoverLetterPersonalInfo] = useState(false);
  const [coverLetterOnDashboard, setCoverLetterOnDashboard] = useState(true);
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };

  let filteredDocPara = selectedCoverLetter?.paragraphs || [];
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
  }
  const [docSectionDetail, setDocSectionDetail] = useState(filteredDocPara);
  const [isDataLoaded, setIsDataLoaded] = useState(filteredDocPara.length > 0 ? true : false);
  if (coverLetterOptions == undefined || coverLetterOptions == null || coverLetterOptions == "") {
    coverLetterOptions = [];
  }

  const coverLetterSelectHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setCoverLetter(value);
    callback(value, "id");
  };
  const editCoverLetterHandler = () => {
    navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW);
  };
  useEffect(() => {
    if (!!selectedCoverLetter && selectedCoverLetter?.paragraphs.length > 0) {
      let filteredDocPara = selectedCoverLetter?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }

      setDocSectionDetail(filteredDocPara.sort((a, b) => a.order - b.order));
      setCoverLetter(selectedCoverLetter.id);
      setCoverLetterTemplate(selectedCoverLetter?.templateCD);
    }
  }, [JSON.stringify(selectedCoverLetter)]);

  return (
    <>
      <div className="dashboard-select mb-5">
        <label htmlFor="my-docs">{t("cover_letters.my_documents")}</label>
        <RBDropDown selectedValue={coverLetter} options={coverLetterOptions} onChange={coverLetterSelectHandler} />
      </div>
      <div className="dashboard-item__img">
        <SelectedCoverLetterComponent
          name={coverLetterTemplate}
          data={docSectionDetail}
          headingfontsize={headingfontsize}
          coverLetterTemplate={coverLetterTemplate}
          coverLetterPosition={coverLetterPosition}
          coverLetterCategory={coverLetterCategory}
          coverLetterPersonalInfo={coverLetterPersonalInfo}
          dataLoaded={isDataLoaded}
          coverLetterOnDashboard={coverLetterOnDashboard}
          componentRef={componentRef}
        ></SelectedCoverLetterComponent>
        <Button
          isLoading={false}
          disabled={false}
          onClick={editCoverLetterHandler}
          id="edit-cover-ltr-btn"
          classNames="btn btn-primary btn-green btn-lg edit-cover-letter w-100 mb-5"
          ctaText={t("cover_letters.edit_cover_letter")}
        />
      </div>
    </>
  );
};

export default CoverLetterList;
