import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDocGUID } from "../funnel-step/actions";
import { createJobTitleParagraph, updateJobTitleParagraph } from "./actions";
import CoverLetterJobTitle from "./component";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import {
  handleSteps,
  previewTemplateAction,
} from "../cover-letter-template/actions";

const mapStateToProps = ({
  docguid,
  funnelStep,
  jobtitle,
  cvltrTemplatePreview,
}) => ({
  docguid,
  funnelStep,
  jobtitle,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      jobTitleConnect: createJobTitleParagraph,
      updateJobTitleParagraph: updateJobTitleParagraph,
      SaveDocGUIDConnect: setDocGUID,
      updateProgress: updateProgress,
      previewTemplateAction: previewTemplateAction,
      handleSteps: handleSteps,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterJobTitle);
