import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChooseOptions from "./component";
import { setDocGUID } from "../funnel-step/actions";
import { resetImageURl } from "../../components/image-upload-modal/actions";

const mapStateToProps = ({ funnelStep }) => ({ funnelStep });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDocGUIDConnect: setDocGUID,
      resetImageURlConnect: resetImageURl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChooseOptions);
