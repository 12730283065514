import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPassword from "./component";
import { forgotPassword } from "./actions";
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			forgotPasswordConnect: forgotPassword,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
