import React from "react";
import { checkEmpty, findParaName } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { imageBlobSignature } from "../../../utils/general-config";

const CoverLetterSignatureSection = ({ cvltrTemplatePreview, data, type, cvltrSignature, characters }) => {
  const { t } = useTranslation();

  const signatureContent = !!cvltrSignature && !!cvltrSignature.data && cvltrSignature.data;
  let key = "";

  let tabValue = signatureContent.tabValue ? signatureContent.tabValue : "";

  let content;
  if (type == "cover-letter" || (data && data.length > 0)) {
    content = JSON.parse(data[0].content);
    key = !!content && !checkEmpty(content.signType) ? content.signType : "";
  }

  const name =
    !!content && !checkEmpty(content.signName) ? content.signName : type == "cover-letter" ? "" : "[Your Name]";

  let sign = !!content && !checkEmpty(content.signature) ? content.signature : "";
  //let sign = !!tabValue && !checkEmpty(tabValue.previewImage) ? tabValue.previewImage : "";
  const signImg = !!tabValue && !!tabValue.previewImage && tabValue.previewImage;
  if (sign.indexOf("?") < 0) {
    sign = sign + imageBlobSignature();
  }
  const selectedFont = !!content && !checkEmpty(content.selectedFont) ? content.selectedFont : "";

  const findPara = findParaName(t("personal_info.para_name"), characters);

  const personalInfoContent = findPara && findPara[0].content ? JSON.parse(findPara[0].content) : "";

  const fullName = personalInfoContent !== "" ? personalInfoContent.fullName : "[Your Name]";

  return (
    <>
      <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
        <div className="cvltr__section cvltr__signature">
          <div className="cvltr__content">
            <p className="">
              <b>{type == "cover-letter" && name !== "" && sign !== "" && "Sincerely,"}</b>
              {type !== "cover-letter" && <b>Sincerely,</b>}
            </p>
            <p className="">
              {key == t("signature.type") ? (
                <label className="form-check-label d-flex justify-content-between ps-2 pe-5">
                  <span
                    className={`${selectedFont !== "" && selectedFont.fontName} font-24 p-3`}
                    style={{
                      color: selectedFont !== "" && selectedFont.fontColor,
                    }}
                  >
                    {tabValue.label && tabValue.label == t("personal_info.para_name") ? tabValue.name : name}
                  </span>
                </label>
              ) : (
                <b>
                  {sign !== "" && (
                    <img
                      src={signImg}
                      style={{
                        width: 120,
                      }}
                    />
                  )}
                </b>
              )}
            </p>
            <p>
              <b>{tabValue.label && tabValue.label == t("personal_info.para_name") ? tabValue.name : fullName}</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetterSignatureSection;
