import React from "react";
import RBDropDown from "../../components/shared/dropdown";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import InputText from "../../components/shared/input-text";
import { useState } from "react";
import { createParagraph } from "../../initialState";
import { APP_NAVIGATION, EMPTY_GUID, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useLocation, useNavigate } from "react-router-dom";
import PathHelper from "../../utils/path-helper";
import Sidebar from "../../components/cover-letter-sidebar";
import { getExperienceFaqs, getListOfStates } from "../../services/master-data";
import CoverLetterTemplate from "../cover-letter-template";
import CoverLetterPreviewButton from "../../components/shared/cvlt-preview-button";
import { ValidateNumber } from "../../validators/genericValidator";
import moment from "moment";
import { useEffect } from "react";
import { checkEmpty, getPortalCD, readCookie } from "../../utils/helpers";
import Modal from "react-bootstrap/Modal";
import DOMPurify from "dompurify";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
import WritingTips from "../../components/writing-tips";
import CoverLetterPreview from "../../components/cover-letter-preview";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterDesiredCompany = ({
  funnelStep,
  desiredCompanyConnect,
  updateDesiredCompanyParagraph,
  desiredCompany,
  SaveDocGUIDConnect,
  updateProgress,
  cvltrTemplatePreview,
  previewTemplateAction,
}) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const sanitizer = dompurify.sanitize;
  const dateFormate = t("opener.date_formate");
  const date = moment().format(dateFormate);
  const experienceFaqs = getExperienceFaqs(t);
  const { question, answers } = experienceFaqs.data;
  const { templateCd, docGUID, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep || {};

  const previewContent =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;

  const content =
    !!desiredCompany &&
    !!desiredCompany.paragraph &&
    !!desiredCompany.paragraph.paraContents &&
    !!desiredCompany.paragraph.paraContents[0].content
      ? JSON.parse(desiredCompany.paragraph.paraContents[0].content)
      : {};

  const previewDesiredCompany =
    !!previewContent && !checkEmpty(previewContent.DesiredCompany) ? previewContent.DesiredCompany : "";

  const previewCity = !!previewContent && !checkEmpty(previewContent.companyCity) ? previewContent.companyCity : "";

  const previewStreetAddress =
    !!previewContent && !checkEmpty(previewContent.companyStreetAddress) ? previewContent.companyStreetAddress : "";

  const previewST = !!previewContent && !checkEmpty(previewContent.companyState) ? previewContent.companyState : "";

  const previewZipCode =
    !!previewContent && !checkEmpty(previewContent.companyZipCode) ? previewContent.companyZipCode : "";

  const id =
    !!desiredCompany && !!desiredCompany.paragraph && !!desiredCompany.paragraph.id ? desiredCompany.paragraph.id : "";

  const prevParaName = {
    state: {
      prevParaName: t("opener.desired_company"),
    },
  };

  const paraGraphId = !!id ? id : EMPTY_GUID;
  let allState = [{ value: "", label: "Select State" }, ...getListOfStates().data];
  const [listOfState, setListOfState] = useState(allState);
  const [show, setShow] = useState(false);

  const [companyName, setCompanyName] = useState(
    previewDesiredCompany !== ""
      ? previewDesiredCompany
      : !!content && !checkEmpty(content.DesiredCompany)
      ? content.DesiredCompany
      : ""
  );
  const [city, setCity] = useState(
    previewCity !== "" ? previewCity : !!content && !checkEmpty(content.companyCity) ? content.companyCity : ""
  );
  const [streetAddress, setStreetAddress] = useState(
    previewStreetAddress !== ""
      ? previewStreetAddress
      : !!content && !checkEmpty(content.companyStreetAddress)
      ? content.companyStreetAddress
      : ""
  );
  const [state, setState] = useState(
    previewST !== "" ? previewST : !!content && !checkEmpty(content.companyState) ? content.companyState : ""
  );
  const [zipCode, setZipCode] = useState(
    previewZipCode !== ""
      ? previewZipCode
      : !!content && !checkEmpty(content.companyZipCode)
      ? content.companyZipCode
      : ""
  );
  const [showLoader, setShowLoader] = useState("d-none");
  const [showStickyBar, setShowStickyBar] = useState(true);
  const [isReviewSection, setIsReviewSection] = useState(false);
  const handleClose = () => setShow(false);
  let pathRedirect = PathHelper(APP_NAVIGATION.DSRDCPNY, false, true, isReviewSection);

  useEffect(() => {
    trackRBEvents("cvDesiredCompanyView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    updateProgress(30);
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.isReview) {
        setIsReviewSection(true);
      }
    } else {
      setIsReviewSection(false);
    }
  }, [location]);

  const buttonClickHandler = (type) => {
    if (companyName == "") {
      setShow(true);
      return false;
    }
    if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
      updateDesiredCompany(type);
    } else {
      saveDesiredCompany(type);
    }
  };

  const saveDesiredCompany = (type) => {
    setShowLoader("d-flex");
    let head = {
      DesiredCompany: type == "skip" ? "" : companyName,
      companyCity: type == "skip" ? "" : city,
      companyStreetAddress: type == "skip" ? "" : streetAddress,
      companyState: type == "skip" ? "" : state,
      companyZipCode: type == "skip" ? "" : zipCode,
      date: date,
      labelManager: t("opener.greeting_to_recipient_name"),
      labelRecipientName: t("opener.recipient_name"),
    };

    desiredCompanyConnect(docGUID, JSON.stringify(head), t("opener.desired_company"))
      .then((response) => {
        setShowLoader("d-none");
        navigate(pathRedirect.next, prevParaName);
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {
        setShowLoader("d-none");
      });
  };

  const updateDesiredCompany = (type) => {
    setShowLoader("d-flex");
    let head = {
      DesiredCompany: type == "skip" ? "" : companyName,
      companyCity: type == "skip" ? "" : city,
      companyStreetAddress: type == "skip" ? "" : streetAddress,
      companyState: type == "skip" ? "" : state,
      companyZipCode: type == "skip" ? "" : zipCode,
      date: date,
      labelManager: t("opener.greeting_to_recipient_name"),
      labelRecipientName: t("opener.recipient_name"),
    };
    let desiredCompanyOrder = SECTION_ORDER.DSRDCPNY;

    let data = createParagraph(
      docGUID,
      desiredCompany.paragraph.id,
      desiredCompany.paragraph.paraContents[0].id,
      JSON.stringify(head),
      desiredCompanyOrder,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      t("opener.desired_company")
    );

    updateDesiredCompanyParagraph(docGUID, paraGraphId, data)
      .then((response) => {
        navigate(pathRedirect.next, prevParaName);
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const handleDesiredPosition = (e) => {
    setCompanyName(e.target.value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      DesiredCompany: e.target.value,
    });
  };

  const skip = () => {
    setShow(false);
    setCompanyName("");
    setZipCode("");
    setState("");
    setStreetAddress("");
    setCity("");
    if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
      updateDesiredCompany("skip");
    } else {
      saveDesiredCompany("skip");
    }
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      DesiredCompany: "",
      companyCity: "",
      companyStreetAddress: "",
      companyState: "",
      companyZipCode: "",
    });
  };
  const oneStreetAddressChange = (e) => {
    const {
      target: { value },
    } = e;
    setStreetAddress(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      companyStreetAddress: value,
    });
  };
  const onFocusCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(false);
    }
  };
  const onZipCodeChange = (e) => {
    const {
      target: { value },
    } = e;
    setZipCode(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      companyZipCode: value,
    });
  };
  const onCityChange = (e) => {
    const {
      target: { value },
    } = e;

    let result = /^[a-zA-Z ]+$/.test(value);

    if (result) {
      setCity(value);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        companyCity: value,
      });
    } else {
      if (value == "") {
        setCity("");
        previewTemplateAction({
          ...cvltrTemplatePreview.templateField,
          companyCity: "",
        });
      }
    }
  };
  const onStateChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setState(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      companyState: value,
    });
  };
  return (
    <>
      <Sidebar>
        {!funnelStepsComplete && (
          <>
            <HeaderSteps activeStep={1} isCoverLetter={true} />
          </>
        )}
        {funnelStepsComplete && (
          <>
            <Header />
          </>
        )}
        <ReactLoader showloader={showLoader} />
        <ScrollToTop />
        <section
          className={classNames(
            funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
            "section main-content templates"
          )}
        >
          <div className="container">
            <div className="alignment-container">
              <div className="row headline-group">
                <div className="cvrLtr-header">
                  <hgroup>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t("opener.employer_title"),
                      }}
                    />
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(t("opener.employer_subtitle")),
                      }}
                    />
                  </hgroup>
                  <WritingTips section={SECTION_TYPE.CVLTS} question={question} answers={answers} />
                </div>
              </div>
              <div className="row iPad-size">
                <div className="col-md-12 col-lg-9">
                  <div>
                    <div className="row">
                      <div className="form-group string optional heading_name">
                        <label className="email optional" htmlFor="heading_email">
                          {t("opener.desired_company")}
                        </label>
                        <div className="tooltip-wrapper">
                          <InputText
                            data-validate=""
                            className="string optional form-control"
                            placeholder={t("opener.desired_company_placeholder")}
                            type="text"
                            name="desired_company"
                            id="desired_company"
                            required
                            maxLength={100}
                            value={companyName}
                            onChange={handleDesiredPosition}
                            onFocusCallback={() => {}}
                            onBlurCallback={() => {}}
                          />
                        </div>
                      </div>
                      <div className="form-group optional">
                        <label className="email optional" htmlFor="heading_email">
                          {t("personal_info.street_address")}
                        </label>
                        <div className="tooltip-wrapper">
                          <InputText
                            data-validate=""
                            classname="string optional form-control"
                            placeholder={t("personal_info.enter_street_placeholder")}
                            type="text"
                            name="heading[street]"
                            id="street_adderess"
                            onChange={oneStreetAddressChange}
                            onFocusCallback={onFocusCallback}
                            onBlurCallback={() => {}}
                            value={streetAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group string optional heading_city col-sm-6 ">
                        <label className="string optional" htmlFor="heading_city">
                          {t("personal_info.city")}
                        </label>
                        <div className="tooltip-wrapper">
                          <InputText
                            data-validate=""
                            classname="string optional form-control"
                            placeholder={t("personal_info.enter_city_placeholder")}
                            type="text"
                            maxLength={50}
                            name="heading[city]"
                            id="heading_city"
                            onChange={onCityChange}
                            onFocusCallback={onFocusCallback}
                            onBlurCallback={() => {}}
                            value={city}
                          />
                        </div>
                      </div>
                      <div className="form-group state optional heading_state col-sm-3">
                        <label className="state optional" htmlFor="heading_state">
                          {t("personal_info.state")}
                        </label>
                        <div className="tooltip-wrapper">
                          <RBDropDown
                            className="state optional form-control"
                            name="heading[state]"
                            id="heading_state"
                            value={state}
                            options={listOfState}
                            selectedValue={state || ""}
                            onChange={onStateChange}
                          />
                        </div>
                      </div>
                      <div className="form-group string optional heading_zipcode col-sm-3">
                        <label className="string optional" htmlFor="heading_zipcode">
                          {t("personal_info.zip_code")}
                        </label>
                        <div className="tooltip-wrapper">
                          <InputText
                            data-validate=""
                            classname="string optional form-control"
                            placeholder={t("personal_info.enter_zip_code_placeholder")}
                            type="text"
                            name="heading[zipcode]"
                            id="heading_zipcode"
                            onChange={onZipCodeChange}
                            onFocusCallback={onFocusCallback}
                            onBlurCallback={() => {}}
                            value={zipCode}
                            maxLength={10}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                    <Button
                      isLoading={false}
                      id="back-btn"
                      classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                      ctaText={t("opener.back")}
                      onClick={backClickHandler}
                    />

                    <Button
                      isLoading={false}
                      disabled={false}
                      id="next-btn"
                      classNames="btn btn-primary btn-lg submit-on-enter float-end"
                      ctaText={t("opener.next")}
                      onClick={buttonClickHandler}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                  <CoverLetterPreview />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Sidebar>
      <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t("opener.modal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t("opener.skip_desired_company")),
            }}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <a className="textlink" role="button" onClick={skip}>
            {t("opener.no_company")}
          </a>
          <a onClick={handleClose} className="btn btn-primary">
            {t("opener.ok")}
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoverLetterDesiredCompany;
