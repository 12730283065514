import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import "./sidebar.css";
import CoverLetterFooter from "./sidebar/cover-letter-footer/footer";
import CoverLetterSidebar from "./sidebar/sidebar";
import Footer from "../footer/component";

const SideBarLayout = ({
  children,
  progress,
  cvltrSidebar,
  updateProgress,
}) => {
  let reduxProgress =
    cvltrSidebar && cvltrSidebar.progress && cvltrSidebar.progress.data;

  return (
    <>
      {/* <CoverLetterSidebar progress={reduxProgress} /> */}
      {children}
      {/* <CoverLetterFooter /> */}
      <Footer />
    </>
  );
};
export default SideBarLayout;
