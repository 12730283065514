import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import DOMPurify from "dompurify";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useTranslation } from "react-i18next";
const CoverLetterContentBox = ({
  recommendList,
  isSelected,
  setIsSelected,
  setRecommendList,
}) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState("");
  const [contentIndex, setContentIndex] = useState(
    isSelected.index !== "" || isSelected.index !== undefined
      ? isSelected.index
      : ""
  );
  const [disableButton, setDisableButton] = useState(false);
  const [resetContent, setResetContent] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const { t } = useTranslation();
  const handleClose = (type) => {
    if (type !== "" && type !== "close") {
      if (content.getCurrentContent().hasText()) {
        let masterList = [...recommendList];
        masterList[contentIndex].content = getEditorVal();
        setRecommendList(masterList);
        setIsSelected({
          ...isSelected,
          index: contentIndex,
          value: getEditorVal(),
        });
      }
    }
    setShow(false);
    setShowReset(false);
  };

  const handleShow = (item, index) => {
    setContentIndex(index);
    setShow(true);
    setContent(paragraphContent(item.content));
    setTitle(item.title);
    setResetContent(item.content);
  };

  const getEditorVal = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(content.getCurrentContent())
    );

    return currentContentAsHTML.replace(/<\/?a[^>]*>/g, "");
  };

  const paragraphContent = (item) => {
    const contentBlocks = htmlToDraft(item);

    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap
    );

    let getContent = EditorState.createWithContent(contentState);

    return getContent;
  };

  const handleSelect = (item, key, index) => {
    key == "add" &&
      setIsSelected({ id: item.id, index: index, value: item.content });
  };

  const handleEditorChange = (editorState) => {
    setContent(editorState);
    if (editorState.getCurrentContent().hasText()) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setShowReset(true);
  };

  const resetPara = () => {
    setContent(paragraphContent(resetContent));
  };
  const handleReset = () => {
    resetPara();
    setResetModal(false);
  };

  return (
    <>
      {recommendList.length !== 0 &&
        recommendList.map((item, index) => {
          return (
            <>
              {item.id == isSelected.id ? (
                <>
                  <div className="cvltr-wrapper-activebox">
                    <span onClick={() => handleSelect(item, "remove", index)}>
                      <div className="cvltr-wrapper-activeheading position-relative">
                        <p className="mb-0">{item.title}</p>
                        <span className="position-absolute top-0 end-0 pe-2 check-icon">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>

                      <div className="cvltr-wrapper-activecontent">
                        <p
                          id="parentId"
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.content),
                          }}
                        />
                      </div>
                    </span>
                    <div className="d-flex justify-content-end cvltr-wrapper-editimg">
                      <a
                        onClick={() => handleShow(item, index)}
                        href="javascript:void(0)"
                        data-bs-target="#edit-wrapper"
                        data-bs-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <i
                          className="cover-letter-pencil fa fa-pencil"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="cvltr-wrapper-box"
                    onClick={() => handleSelect(item, "add", index)}
                  >
                    <div className="cvltr-wrapper-heading">
                      <p className="mb-0">{item.title}</p>
                    </div>
                    <div className="cvltr-wrapper-activecontent">
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.content),
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          );
        })}
      <Modal
        show={show}
        onHide={() => handleClose("close")}
        centered
        size="lg"
        className="cvltr-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 style={{ textTransform: "none" }} className="mb-0">
              {title}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content !== "" && (
            <Editor
              editorState={content}
              onEditorStateChange={handleEditorChange}
              autoCapitalize={"none"}
              autoComplete={"off"}
              autoCorrect={"off"}
              stripPastedStyles
              placeholder={""}
              spellCheck={window.innerWidth < 768 ? false : true}
              toolbar={{
                options: ["inline", "list", "history"],
                inline: { options: ["bold", "italic", "underline"] },
                list: { options: ["unordered"] },
              }}
            />
          )}
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => handleClose("close")}>
            {t("opener.cancel")}
          </Button>

          <div className="text-center">
            {showReset && (
              <>
                <span
                  className="btn btn-link"
                  onClick={() => setResetModal(true)}
                >
                  <FontAwesomeIcon className="me-2" icon={faTrash} />
                  {t("opener.reset")}
                </span>

                {/* <Button
                  onClick={() => setResetModal(true)}
                  className="btn-muted--light writting-tip-btn btn btn-link"
                >
                  <FontAwesomeIcon className="me-2" icon={faTrash} />
                  {t("opener.save_changes")}
                </Button> */}
              </>
            )}
          </div>

          <Button
            disabled={disableButton}
            variant="primary"
            onClick={handleClose}
          >
            {t("opener.save_changes")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* reset confirmation modal */}
      <Modal
        show={resetModal}
        onHide={() => setResetModal(false)}
        centered
        size="md"
        className="cvltr-modal"
        backdrop="static"
      >
        <Modal.Header closeButton className="px-4">
          <Modal.Title>
            <h1 style={{ textTransform: "none" }} className="mb-0"></h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="pt-5">{t("opener.reset_title")}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pb-5">
          <Button variant="primary" onClick={handleReset}>
            {t("opener.reset")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoverLetterContentBox;
