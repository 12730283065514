import React, { useState } from "react";
import PropTypes from "prop-types";
// import styles from "./input-text.module.css";

import useToggle from "../../hooks/use-toggle";

const InputText = ({
  showLabel,
  value = "",
  name = "",
  id = "",
  className = "form-control",
  text = "",
  placeholder = "",
  onChange = () => {},
  onBlurCallback = () => {},
  validator = () => {},
  errorMessage = "",
  inputRef,
  onFocusCallback = () => {},
  maxLength = "",
  onKeyPress = () => {},
  type = "text",
  readonly = false,
  validationError = false,
  onInput = () => {},
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useToggle(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMessage);
  const [isEmpty, setisEmpty] = useState(true);
  const onChangeHandler = (e) => {
    var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    e.target.value=e.target.value.replace(htmlRegexG, '');
    const inputValue = e.target.value;
    if (validator(inputValue)) {
      setHasError(false);
      setError("");
    } else {
      setHasError(true);
      setError(errorMessage);
    }
    onChange(e);
    // console.log("inputValue", inputValue);
    // if (inputValue.length > 0) {
    //   console.log("hiii");
    // } else console.log("type");
  };

  const onFocus = () => {
    setIsFocused();
    setIsTouched(true);
    onFocusCallback();
  };

  const onBlur = (e) => {
    const inputValue = e.target.value;
    setIsFocused();
    onBlurCallback(e.target.value);
    if (inputValue.trim().length > 0) {
      setisEmpty(false);
    }
    if (inputValue.trim().length === 0) {
      setisEmpty(true);
    }
  };

  return (
    <>
      {showLabel && <label className="form-label">{text}</label>}
      <input
        ref={inputRef}
        id={id}
        placeholder={placeholder}
        type={type}
        name={name}
        className={`${className} ${!isEmpty ? "success" : ""}`}
        value={value}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onInput={onInput}
        onFocus={(e) => onFocus(e)}
        onBlur={(e) => onBlur(e)}
        autoComplete="off"
        onKeyPress={(e) => onKeyPress(e)}
        {...(readonly === true && { readOnly: "readonly" })}
      />

      {isTouched && !isFocused && hasError && <span className="">{error}</span>}
    </>
  );
};

InputText.propTypes = {
  value: PropTypes.string,
  showLabel: PropTypes.bool,
  id: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onBlurCallback: PropTypes.func,
  maxLength: PropTypes.number,
  validator: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  onFocusCallback: PropTypes.func,
  onInput: PropTypes.func,
};
export default InputText;
