import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_JOB_TITLE
`,
  {
    prefix: "opener/job_title",
  }
);
