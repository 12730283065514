import React, { useEffect, useState, useCallback } from "react";
import RBDropDown from "../shared/dropdown/component";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import "./resumes-list.scss";
import FinalReview from "../../components/cvs/final-review";
import { useParams, useNavigate } from "react-router-dom";
import { availableLanguages } from "../../i18nextInit";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import Cv1Classic from "../cvs/resume-templates/cv1-classic";
import Cv2ExecutiveI from "../cvs/resume-templates/cv2-executive-i";
import Cv3Collegiate from "../cvs/resume-templates/cv3-collegiate";
import Cv4Elegant from "../cvs/resume-templates/cv4-elegant";
import Cv5Original from "../cvs/resume-templates/cv5-original";
import Cv6Modern from "../cvs/resume-templates/cv6-modern";
import Cv7Fugiat from "../cvs/resume-templates/cv7-fugiat";
import Cv8Totam from "../cvs/resume-templates/cv8-totam";
import Cv9Lectus from "../cvs/resume-templates/cv9-lectus";
import Cv10Harum from "../cvs/resume-templates/cv10-harum";
import Cv11Pure from "../cvs/resume-templates/cv11-pure";
import Cv12Graphite from "../cvs/resume-templates/cv12-graphite";
import Cv13Quo from "../cvs/resume-templates/cv13-quo";
import Cv14Armony from "../cvs/resume-templates/cv14-armony";
import Cv15Gravity from "../cvs/resume-templates/cv15-gravity";
import Cv19Pro from "../cvs/resume-templates/cv19-pro";
import Cv20Neque from "../cvs/resume-templates/cv20-neque";
import CvDistinct from "../cvs/resume-templates/cv-distinct";
import CvExpressive from "../cvs/resume-templates/cv-expressive";
import CvBold from "../cvs/resume-templates/cv-bold";
import ReviewStyle from "../cvs/review-style/common-style/component";

const SelectedResumeComponent = ({ name, ...other }) => {
  if (name == "Executive I") {
    name = "ExecutiveI";
  }
  const SelectiveSection = {
    Classic: Cv1Classic,
    ExecutiveI: Cv2ExecutiveI,
    Collegiate: Cv3Collegiate,
    Elegant: Cv4Elegant,
    Original: Cv5Original,
    Modern: Cv6Modern,
    Fugiat: Cv7Fugiat,
    Totam: Cv8Totam,
    Lectus: Cv9Lectus,
    Harum: Cv10Harum,
    Pure: Cv11Pure,
    Graphite: Cv12Graphite,
    Quo: Cv13Quo,
    Armony: Cv14Armony,
    Gravity: Cv15Gravity,
    Pro: Cv19Pro,
    Neque: Cv20Neque,
    Distinct: CvDistinct,
    Expressive: CvExpressive,
    Bold: CvBold,
  };
  if (!name) {
    name = "Classic";
  }
  const SelectedResume = SelectiveSection[name];
  if (!SelectedResume) {
    return null;
  }
  return <SelectedResume {...other} />;
};
const ResumesList = ({ callback, resumeOptions, selectedResume, componentRef }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState("");
  const [headingfontsize, setHeadingFontSize] = useState("1.65");
  const [resumeTemplate, setResumeTemplate] = useState("cv1");
  const [resumeCategory, setResumeCategory] = useState("single");
  const [resumePosition, setResumePosition] = useState("left");
  const [resumePersonalInfo, setResumePersonalInfo] = useState(false);
  const [resumeOnDashboard, setResumeOnDashboard] = useState(true);
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  let filteredDocPara = selectedResume?.paragraphs || [];
  if (!!filteredDocPara && filteredDocPara.length > 0) {
    filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
  }
  const [docSectionDetail, setDocSectionDetail] = useState(filteredDocPara);
  const [isDataLoaded, setIsDataLoaded] = useState(filteredDocPara.length > 0 ? true : false);
  if (resumeOptions == undefined || resumeOptions == null || resumeOptions == "") {
    resumeOptions = [];
  }

  const resumeSelectHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    setResumes(value);
    callback(value, "id");
  };
  const editResumeHandler = () => {
    navigate(!!locale ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW);
  };
  useEffect(() => {
    if (!!selectedResume && selectedResume?.paragraphs?.length > 0) {
      let filteredDocPara = selectedResume?.paragraphs || [];
      if (!!filteredDocPara && filteredDocPara?.length > 9) {
        filteredDocPara = filteredDocPara.filter((x) => x.paraCD != "NONE");
      }
      setDocSectionDetail(filteredDocPara.sort((a, b) => a.order - b.order));
      setResumes(selectedResume.id);
      setResumeTemplate(selectedResume?.templateCD);
    }
  }, [JSON.stringify(selectedResume)]);
  return (
    <>
      <div className="dashboard-select mb-5">
        <label htmlFor="my-docs">{t("resumes.my_documents")}</label>
        <RBDropDown selectedValue={resumes} options={resumeOptions} onChange={resumeSelectHandler} />
      </div>
      <div className="dashboard-item__img">
        {/* <div className="resume-layout" style={{ fontSize: "7.23771px" }}>
            <FinalReview
              data={docSectionDetail}
              headingfontsize={headingfontsize}
              resumeTemplate={resumeTemplate}
              resumePosition={resumePosition}
              resumeCategory={resumeCategory}
              resumePersonalInfo={resumePersonalInfo}
              dataLoaded={isDataLoaded}
              resumeOnDashboard={resumeOnDashboard}
            ></FinalReview>
          </div> */}
        <SelectedResumeComponent
          name={resumeTemplate}
          data={docSectionDetail}
          headingfontsize={headingfontsize}
          resumeTemplate={resumeTemplate}
          resumePosition={resumePosition}
          resumeCategory={resumeCategory}
          resumePersonalInfo={resumePersonalInfo}
          dataLoaded={isDataLoaded}
          resumeOnDashboard={resumeOnDashboard}
          componentRef={componentRef}
        ></SelectedResumeComponent>
        <Button
          isLoading={false}
          disabled={false}
          onClick={editResumeHandler}
          id="edit-resume-btn"
          classNames="btn btn-primary btn-green btn-lg edit-resume w-100 mb-5"
          ctaText={t("resumes.edit_resume")}
        />
      </div>
    </>
  );
};

export default ResumesList;
