import React from "react";
import { useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../../../utils/constants";
import Button from "../../../shared/button";
import { useNavigate } from "react-router-dom";
import PathHelper from "../../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../../utils/constants";
import CoverLetterRecipientSection from "../../cover-letter-recipient-section";

const TempCoverLetterDesiredCompany = ({
  data,
  toggleDeleteModal,
  setCurrentParaConnect,
  dragStart,
}) => {
  let dragClass = false;
  if (dragStart !== undefined) {
    if (dragStart.style.position !== undefined) {
      dragClass = true;
    }
  }
  const { t } = useTranslation();
  let pathRedirect = PathHelper(APP_NAVIGATION.TRECPT, false);

  const delteSectionHandler = () => {
    toggleDeleteModal();
    setCurrentParaConnect(t("opener.desired_company"), "");
  };
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next, {
      state: {
        isReview: true,
      },
    });
  };
  return (
    <>
      <div
        className={
          dragClass
            ? "resume__section resume__summary dragging"
            : "resume__section resume__summary"
        }
      >
        <div className="resume__controls"></div>
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={editHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
          <Button
            isLoading={false}
            disabled={false}
            onClick={delteSectionHandler}
            icon={
              <>
                <span className="fa fa-trash-o" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.delete")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
        <CoverLetterRecipientSection type="cover-letter" data={data} />
      </div>
    </>
  );
};

export default TempCoverLetterDesiredCompany;
