import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import Button from "../../components/shared/button";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import InputText from "../../components/shared/input-text";
import { useState } from "react";
import { createParagraph, emptyDocument } from "../../initialState";
import { checkEmpty, documentName, getPortalCD, readCookie } from "../../utils/helpers";
import { getTenantId } from "../../utils/general-config";
import { APP_NAVIGATION, DOC_STAGE, SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import ReactLoader from "../../components/shared/loader";
import { useNavigate } from "react-router-dom";
import PathHelper from "../../utils/path-helper";
import Sidebar from "../../components/cover-letter-sidebar";
import { getJobTitleFaqs } from "../../services/master-data";
import CoverLetterPreview from "../../components/cover-letter-preview";
import Modal from "react-bootstrap/Modal";
import DOMPurify from "dompurify";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { useParams } from "react-router-dom";
import WritingTips from "../../components/writing-tips";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterJobTitle = ({
  funnelStep,
  jobTitleConnect,
  updateJobTitleParagraph,
  jobtitle,
  SaveDocGUIDConnect,
  updateProgress,
  previewTemplateAction,
  cvltrTemplatePreview,
  handleSteps,
}) => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const { templateCd, userGUID, docGUID, funnelStepsComplete, email, personalInfoName } = funnelStep;

  const content =
    !!jobtitle &&
    !!jobtitle.paragraph &&
    !!jobtitle.paragraph.paraContents &&
    !!jobtitle.paragraph.paraContents[0].content
      ? JSON.parse(jobtitle.paragraph.paraContents[0].content)
      : {};
  const jobTitleFaqs = getJobTitleFaqs(t);
  const { question, answers } = jobTitleFaqs.data;

  let visitorGuid = readCookie("visitorGuid");

  let pathRedirect = PathHelper(APP_NAVIGATION.JOBTITLE, funnelStepsComplete, true);

  const previewContent =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;

  const previewJobTitle =
    !!previewContent && !checkEmpty(previewContent.desiredPosition) ? previewContent.desiredPosition : "";

  const [desiredPosition, setDesiredPosition] = useState(
    previewJobTitle !== ""
      ? previewJobTitle
      : !!content && !checkEmpty(content.desiredPosition)
      ? content.desiredPosition
      : ""
  );
  const [showLoader, setShowLoader] = useState("d-none");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    trackRBEvents("cvJobTitleView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    updateProgress(20);
    handleSteps({
      ...cvltrTemplatePreview.steps,
      recipient: true,
      greeting: true,
    });
  }, []);

  const buttonClickHandler = () => {
    if (desiredPosition == "") {
      setShow(true);
      return false;
    }
    if (jobtitle.paragraph == "" || content.desiredPosition == undefined || content.desiredPosition == "") {
      saveJobTitle();
    } else {
      updateJobTitle();
    }
  };

  const saveJobTitle = (type) => {
    setShowLoader("d-flex");
    let head = {
      desiredPosition: type == "skip" ? "" : desiredPosition,
    };

    let data = emptyDocument(
      documentName(cvltrTemplatePreview.fullName),
      JSON.stringify({}),
      templateCd,
      userGUID,
      getTenantId(),
      visitorGuid,
      ""
    );

    //Note: For cover letter document, we are passing typeOfDoc = 20 and DocStage = DOC_STAGE.OPNR
    data.typeOfDoc = 20;
    data.DocStage = DOC_STAGE.OPNR;

    jobTitleConnect(data, JSON.stringify(head), t("opener.desired_position"), docGUID)
      .then((response) => {
        if (docGUID !== "" || docGUID !== undefined) {
          SaveDocGUIDConnect(response.docGuid);
        } else {
          SaveDocGUIDConnect(docGUID);
        }
        setShowLoader("d-none");
        navigate(pathRedirect.next);
      })
      .catch((err) => {});
  };

  const updateJobTitle = () => {
    setShowLoader("d-flex");
    let head = {
      desiredPosition: desiredPosition,
    };

    let jobtitleOrder =
      !!jobtitle && !!jobtitle.paragraph && !!jobtitle.paragraph.order && jobtitle.paragraph.order != 0
        ? jobtitle.paragraph.order
        : SECTION_ORDER.OPNR;

    var data = createParagraph(
      docGUID,
      jobtitle.paragraph.id,
      jobtitle.paragraph.paraContents[0].id,
      JSON.stringify(head),
      jobtitleOrder,
      SECTION_TYPE.OPNR,
      SECTION_CODE.OPNR,
      t("opener.desired_position")
    );

    updateJobTitleParagraph(docGUID, jobtitle.paragraph.id, data)
      .then((response) => {
        navigate(pathRedirect.next);
        setShowLoader("d-none");
        SaveDocGUIDConnect(docGUID);
      })
      .catch((err) => {});
  };

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const handleDesiredPosition = (e) => {
    setDesiredPosition(e.target.value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      desiredPosition: e.target.value,
    });
  };

  const skip = () => {
    setShow(false);
    setDesiredPosition("");
    saveJobTitle("skip");
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      desiredPosition: "",
    });
  };

  return (
    <>
      <Sidebar>
        {!funnelStepsComplete && (
          <>
            <HeaderSteps activeStep={1} isCoverLetter={true} />
          </>
        )}
        {funnelStepsComplete && (
          <>
            <Header />
          </>
        )}
        <ReactLoader showloader={showLoader} />
        <ScrollToTop />
        <section
          className={classNames(
            funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
            "section main-content templates"
          )}
        >
          <div className="container">
            <div className="alignment-container">
              <div className="row headline-group">
                <div className="cvrLtr-header">
                  <hgroup>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t("opener.job_title"),
                      }}
                    />
                    <h4
                      className="cvltr-black-color"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(t("opener.job_title_description")),
                      }}
                    />
                  </hgroup>
                  <WritingTips section={SECTION_TYPE.CVLTS} question={question} answers={answers} />
                </div>
              </div>

              <div className="row iPad-size">
                <div className="col-md-12 col-lg-9">
                  <div>
                    <div
                      className="row"
                      style={{
                        marginBottom: "20%",
                      }}
                    >
                      <div className="form-group string optional heading_name">
                        <label className="email optional" htmlFor="heading_email">
                          {t("opener.desired_position")}
                        </label>
                        <div className="tooltip-wrapper">
                          <InputText
                            data-validate=""
                            className="string optional form-control"
                            placeholder={t("opener.desired_position_placeholder")}
                            type="text"
                            name="desired_position"
                            id="desired_position"
                            required
                            maxLength={100}
                            value={desiredPosition}
                            onChange={handleDesiredPosition}
                            onFocusCallback={() => {}}
                            onBlurCallback={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                    <Button
                      isLoading={false}
                      id="back-btn"
                      classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                      ctaText={t("opener.back")}
                      onClick={backClickHandler}
                    />

                    <Button
                      isLoading={false}
                      disabled={false}
                      id="next-btn"
                      classNames="btn btn-primary btn-lg submit-on-enter float-end"
                      ctaText={t("universal.next_action")}
                      onClick={buttonClickHandler}
                      //href="JAVASCRIPT:void(0)"
                      data-bs-target="#next-mode"
                      data-bs-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                  <CoverLetterPreview />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Sidebar>

      <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t("opener.modal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t("opener.skip_desired_position")),
            }}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <a className="textlink" role="button" id="text-link" onClick={skip}>
            {t("opener.no_job")}
          </a>
          <a onClick={handleClose} className="btn btn-primary" id="more-info-ok-btn">
            {t("opener.ok")}
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoverLetterJobTitle;
