import { createTypes } from "reduxsauce";

export default createTypes(
  `
    FETCH_SKILLS_SUCCESS
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_SKILLS
    SELECTED_SKILLS
    NEW_SKILL_LIST
`,
  {
    prefix: "body/skills",
  }
);
