import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/tips.scss";
import HeaderSteps from "../../components/header-steps";
import Button from "../../components/shared/button";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import TipsContainer from "../../components/tips-container";
import { SECTION_TYPE } from "../../utils/constants";
import dompurify from "dompurify";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { Helmet } from "react-helmet";
import { getPortalCD, readCookie } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { trackRBEvents } from "../../utils/tracking-events";
import { createUpdateDocumentUpload } from "../../utils/general-config";
const SummaryTips = ({ funnelStep }) => {
  const { t } = useTranslation();
  const { userGUID, email, personalInfoName } = funnelStep || {};
  const navigate = useNavigate();
  const sanitizer = dompurify.sanitize;
  const tempSkip = () => {
    createUpdateDocumentUpload();
    navigate(pathRedirect.next);
  };
  let pathRedirect = PathHelper(APP_NAVIGATION.SUMT);
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const { locale } = useParams();
  useEffect(() => {
    trackRBEvents("summaryTipsView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("page_title.summary_tips")}</title>
      </Helmet>
      <HeaderSteps activeStep={5} />
      <section className="section main-content templates sidebar-sticky">
        <div className="container ">
          <div className="alignment-container">
            <div className="row">
              <div className="headline-group text-center heading-style">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("summary_tips.headline")),
                    }}
                  />
                </hgroup>
              </div>
            </div>
            <div className="row tip-content">
              {" "}
              <TipsContainer section={SECTION_TYPE.SUMM} resumemap="summary"></TipsContainer>
            </div>
            <div className="row">
              {" "}
              <div className="box__foote box__footer--steps btn-container fbn btn-justify">
                <Button
                  isLoading={false}
                  classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                  id="summary_tip_back_btn"
                  ctaText={t("summary.back_action")}
                  onClick={backClickHandler}
                />
                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={tempSkip}
                  classNames="btn btn-primary btn-lg submit-on-enter"
                  id="summary_tip_next_button"
                  ctaText={t("summary.next")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SummaryTips;
