import { createTypes } from "reduxsauce";

export default createTypes(
  `
      FETCH_OTHER_SECTION_SUCCESS
      FETCH_OTHER_SECTION_FAILURE
      QUACK
      UPDATE_OTHER_SUCCESS
      UPDATE_OTHER_FAILED
      RESET_OTHER_SECTION
      RENAME_OTHER_SECTION
  `,
  {
    prefix: "others/",
  }
);
