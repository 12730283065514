import Types from "./types";
import { getTemplateList } from "../../services/master-data";

const fetchTemplates = (locale) => (dispatch) => {
  let response = getTemplateList(locale);
  dispatch(fetchTemplateSuccess(response.data));
};

const fetchTemplateSuccess = (data) => ({
  type: Types.FETCH_TEMPLATES_SUCCESS,
  data,
});
const resetChooseTemplate = () => ({
  type: Types.RESET_CHOOSE_TEMPLATE,
});

// const saveChooseTemplateFailure = (error) => ({
//   type: Types.SAVE_FAILED,
//   error,
// });

export { fetchTemplates, resetChooseTemplate };
