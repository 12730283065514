import React from "react";
import "./experience-list.scss";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";

const ExperienceList = ({ paraContents, UpdateParaContent, UpdateExpList }) => {
  const { t } = useTranslation();
  let pathRedirect = PathHelper(APP_NAVIGATION.TEXP);
  const paragraphContent = (data) => {
    if (paraContents.length > 0 && JSON.parse(data)?.content != undefined) {
      let val = JSON.parse(data).content;
      let content = val.replace(/<(.|\n)*?>/g, "").substring(0, 80);
      if (content.length === 80) {
        content = content + "...";
      }
      return content.replace(/&nbsp;/g, " ");
    }
  };
  const UpdateData = (id) => {
    UpdateParaContent(id);
  };
  const DeleteList = (id, i) => {
    UpdateExpList(id, i);
  };
  return (
    <>
      {!!paraContents &&
        paraContents.length > 0 &&
        paraContents
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <div className="added-item" key={index}>
              <div className="added_item__details">
                <span className="added-item__title">
                  <span>
                    <b>
                      {!!JSON.parse(item.content)?.employer &&
                        JSON.parse(item.content)?.employer + " "}
                    </b>
                    {!!JSON.parse(item.content)?.city &&
                      JSON.parse(item.content)?.city}
                    {!!JSON.parse(item.content)?.state &&
                      "," + JSON.parse(item.content)?.state}
                  </span>
                </span>
                <span className="added-item__date">
                  {!!JSON.parse(item.content)?.jobTitle &&
                    JSON.parse(item.content)?.jobTitle + " "}
                  {!!JSON.parse(item.content)?.startDateYear &&
                    !!JSON.parse(item.content)?.startDateMonthLabel &&
                    ", " +
                      JSON.parse(item.content)?.startDateMonthLabel +
                      "." +
                      JSON.parse(item.content)?.startDateYear}

                  {!!JSON.parse(item.content)?.startDateYear &&
                    JSON.parse(item.content)?.startDateMonthLabel === "" &&
                    JSON.parse(item.content)?.startDateYear}

                  {!JSON.parse(item.content)?.presently_here &&
                    !!JSON.parse(item.content)?.endDateYear &&
                    !!JSON.parse(item.content)?.endDateMonthLabel &&
                    " - " +
                      JSON.parse(item.content)?.endDateMonthLabel +
                      "." +
                      JSON.parse(item.content)?.endDateYear}

                  {!JSON.parse(item.content)?.presently_here &&
                    !!JSON.parse(item.content)?.endDateYear &&
                    !!JSON.parse(item.content)?.startDateYear &&
                    JSON.parse(item.content)?.endDateMonthLabel === "" &&
                    " - " + JSON.parse(item.content)?.endDateYear}

                  {JSON.parse(item.content)?.presently_here &&
                    " - " + t("experience.label_Present")}
                </span>
                <small className="added-item__content">
                  {paragraphContent(item.content) !== undefined &&
                    paragraphContent(item.content) &&
                    " - " + paragraphContent(item.content)}
                </small>
              </div>
              <div className="added-item__actions">
                <HashLink
                  smooth
                  to={pathRedirect.next + "#exp-scroll-container"}
                >
                  <Button
                    isLoading={false}
                    disabled={false}
                    onClick={() => UpdateData(item.id)}
                    titleName={t("experience.edit")}
                    icon={<i className="fa fa-pencil"></i>}
                    classNames="muted added-item__action"
                    ctaText=""
                  />
                </HashLink>

                <Button
                  isLoading={false}
                  disabled={false}
                  onClick={() => DeleteList(item.id, index)}
                  titleName={t("experience.destroy")}
                  icon={<i className="fa fa-trash"></i>}
                  classNames="muted added-item__action disable-resubmit"
                  ctaText=""
                />
              </div>
            </div>
          ))}
    </>
  );
};

export default ExperienceList;
