import Types from "./types";
import DocBuilderService from "../../services/doc-builder-api";
import UserServiceApi from "../../services/user-service-api";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";
import { getTenantGroupId } from "../../utils/general-config";
const getUserDocs = (userguid) => {
  return function (dispatch) {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        return response;
      })
      .catch((error) => dispatch());
  };
};

const getUser = (userguid) => {
  return function (dispatch) {
    return UserServiceApi.getUserByUserGuid(userguid, getTenantGroupId()).then((userResponse) => {
      if (userResponse) {
        return userResponse;
      }
    });
  };
};

const setConfirmation = (userguid) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.setConfirmationinCB(userguid)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("Error"));
  };
};
const getBillingInfo = (userId, locale) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.getBillingInfo(userId, locale).then((response) => {
      return response;
    });
  };
};
export { getUserDocs, getUser, setConfirmation, getBillingInfo };
