import { createTypes } from "reduxsauce";

export default createTypes(
  `
    SAVE_SUCCESS
    UPDATE_SUCCESS
    RESET_DESIRED_COMPANY
`,
  {
    prefix: "opener/desired_company",
  }
);
