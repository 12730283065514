import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderPopup from "./component";

const mapStateToProps = ({ uploadResume }) => ({ uploadResume });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoaderPopup);
