import { fallbackLng } from "../i18nextInit";
import { availableLanguages } from "../i18nextInit";

export const getTemplateList = (locale) => {
  let verifiedLocale = "en";
  if (availableLanguages.indexOf(locale) > -1) {
    verifiedLocale = locale;
  }

  let response = {
    data: [
      {
        key: "Distinct",
        templatename: "Distinct",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv-distinct.webp`,
        templatecd: "Distinct",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "Expressive",
        templatename: "Expressive",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv-expressive.webp`,
        templatecd: "Expressive",
        resetFontFamily: '"Century Gothic", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "Bold",
        templatename: "Bold",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv-bold.webp`,
        templatecd: "Bold",
        resetFontFamily: '"Century Gothic", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv4",
        templatename: "Elegant",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv4.webp`,
        templatecd: "Elegant",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv13",
        templatename: "Quo",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv13.webp`,
        templatecd: "Quo",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.75",
      },
      {
        key: "cv5",
        templatename: "Original",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv5.webp`,
        templatecd: "Original",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv11",
        templatename: "Pure",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv11.webp`,
        templatecd: "Pure",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv2",
        templatename: "Executive I",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv2.webp`,
        templatecd: "Executive I",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv3",
        templatename: "Collegiate",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv3.webp`,
        templatecd: "Collegiate",
        resetFontFamily: '"Open Sans TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv12",
        templatename: "Graphite",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv12.webp`,
        templatecd: "Graphite",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.25",
      },
      {
        key: "cv9",
        templatename: "Lectus",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv9.webp`,
        templatecd: "Lectus",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv20",
        templatename: "Neque",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv20.webp`,
        templatecd: "Neque",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv6",
        templatename: "Modern",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv6.webp`,
        templatecd: "Modern",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv15",
        templatename: "Gravity",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv15.webp`,
        templatecd: "Gravity",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv19",
        templatename: "Pro",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv19.webp`,
        templatecd: "Pro",
        resetFontFamily: '"Open Sans TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv8",
        templatename: "Totam",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv8.webp`,
        templatecd: "Totam",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1.15",
        resetHeadingFontSize: "1.4",
      },
      {
        key: "cv14",
        templatename: "Armony",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv14.webp`,
        templatecd: "Armony",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.75",
      },
      {
        key: "cv10",
        templatename: "Harum",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv10.webp`,
        templatecd: "Harum",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
      {
        key: "cv1",
        templatename: "Classic",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv1.webp`,
        templatecd: "Classic",
        resetFontFamily: '"Open Sans TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cv7",
        templatename: "Fugiat",
        imagesource: `/app/static/images/thumbs/${verifiedLocale}/webp/cv7.webp`,
        templatecd: "Fugiat",
        resetFontFamily: '"Roboto TTF", sans-serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.45",
      },
    ],
  };
  return response;
};
export const getCoverLetterTemplateList = (locale) => {
  let verifiedLocale = "en";
  if (availableLanguages.indexOf(locale) > -1) {
    verifiedLocale = locale;
  }

  let response = {
    data: [
      {
        key: "cl01",
        templatename: "Modern1",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl01.jpg`,
        templatecd: "Modern",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl02",
        templatename: "Elegant",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl02.jpg`,
        templatecd: "Elegant",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl03",
        templatename: "Professional1",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl03.jpg`,
        templatecd: "Professional",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl04",
        templatename: "Classic",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl04.jpg`,
        templatecd: "Classic",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl05",
        templatename: "Contemporary",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl05.jpg`,
        templatecd: "Contemporary",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl06",
        templatename: "Standard",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl06.jpg`,
        templatecd: "Standard",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl07",
        templatename: "Professional2",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl07.jpg`,
        templatecd: "Professional1",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
      {
        key: "cl08",
        templatename: "Modern2",
        imagesource: `/app/static/images/cover-letter-thumbs/${verifiedLocale}/cl08.jpg`,
        templatecd: "Modern1",
        resetFontFamily: '"Merriweather", serif',
        resetFontSize: "1",
        resetHeadingFontSize: "1.3",
      },
    ],
  };
  return response;
};
export const getYearList = () => {
  var currentYear = new Date().getFullYear();
  var startYear = currentYear - 60;
  var years = [];
  // years.push({ label: "", value: "empty" });

  for (let i = currentYear; i >= startYear; i--) {
    years.push({ label: i, value: i });
  }
  let response = {
    data: years,
  };
  return response;
};
export const getExpMonthList = () => {
  let response = {
    data: [
      { value: "", label: "Month(MM)" },
      { value: "1", label: "01" },
      { value: "2", label: "02" },
      { value: "3", label: "03" },
      { value: "4", label: "04" },
      { value: "5", label: "05" },
      { value: "6", label: "06" },
      { value: "7", label: "07" },
      { value: "8", label: "08" },
      { value: "9", label: "09" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
    ],
  };
  return response;
};
export const getExpYearList = () => {
  let response = {
    data: [
      { value: "", label: "Year" },
      { value: "2024", label: "2024" },
      { value: "2025", label: "2025" },
      { value: "2026", label: "2026" },
      { value: "2027", label: "2027" },
      { value: "2028", label: "2028" },
      { value: "2029", label: "2029" },
      { value: "2030", label: "2030" },
      { value: "2031", label: "2031" },
      { value: "2032", label: "2032" },
      { value: "2033", label: "2033" },
      { value: "2034", label: "2034" },
    ],
  };
  return response;
};
export const getFontFamilyList = (t) => {
  let response = {
    data: [
      { value: "", label: t("review.select_font") },
      { value: "Arial", label: "Arial" },
      { value: '"Bodoni MT"', label: "Bodoni MT" },
      { value: '"Century Gothic"', label: "Century Gothic" },
      { value: '"Courier New"', label: "Courier New" },
      { value: "Georgia", label: "Georgia" },
      { value: '"Merriweather", serif', label: "Merriweather, serif" },
      { value: '"Open Sans TTF", sans-serif', label: "Open Sans" },
      { value: '"Palatino Linotype"', label: "Palatino Linotype" },
      { value: "PT Serif", label: "PT Serif" },
      { value: '"Roboto TTF", sans-serif', label: "Roboto" },
      { value: "Tahoma", label: "Tahoma" },
      { value: '"Times New Roman"', label: "Times New Roman" },
      { value: '"Trebuchet MS"', label: "Trebuchet MS" },
      { value: "Verdana", label: "Verdana" },
    ],
  };
  return response;
};
export const getTumbnailImages = () => {
  let response = {
    data: [
      {
        key: "Distinct",
        value: "/app/static/images/thumbs/en/cv-distinct.jpg",
        label: "Distinct",
        category: "tripple",
        position: "left",
        personal_info: true,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "Expressive",
        value: "/app/static/images/thumbs/en/cv-expressive.jpg",
        label: "Expressive",
        category: "tripple",
        position: "left",
        personal_info: true,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "Bold",
        value: "/app/static/images/thumbs/en/cv-bold.jpg",
        label: "Bold",
        category: "tripple",
        position: "left",
        personal_info: true,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv15",
        value: "/app/static/images/thumbs/en/cv15.jpg",
        label: "Gravity",
        category: "tripple",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv5",
        value: "/app/static/images/thumbs/en/cv5.jpg",
        label: "Original",
        category: "tripple",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv4",
        value: "/app/static/images/thumbs/en/cv4.jpg",
        label: "Elegant",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: '"Roboto TTF", sans-serif',
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv13",
        value: "/app/static/images/thumbs/en/cv13.jpg",
        label: "Quo",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv8",
        value: "/app/static/images/thumbs/en/cv8.jpg",
        label: "Totam",
        category: "double",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv1",
        value: "/app/static/images/thumbs/en/cv1.jpg",
        label: "Classic",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv7",
        value: "/app/static/images/thumbs/en/cv7.jpg",
        label: "Fugiat",
        category: "tripple",
        position: "left",
        personal_info: true,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv20",
        value: "/app/static/images/thumbs/en/cv20.jpg",
        label: "Neque",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv14",
        value: "/app/static/images/thumbs/en/cv14.jpg",
        label: "Armony",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv3",
        value: "/app/static/images/thumbs/en/cv3.jpg",
        label: "Collegiate",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv6",
        value: "/app/static/images/thumbs/en/cv6.jpg",
        label: "Modern",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv2",
        value: "/app/static/images/thumbs/en/cv2.jpg",
        label: "Executive I",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv11",
        value: "/app/static/images/thumbs/en/cv11.jpg",
        label: "Pure",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv10",
        value: "/app/static/images/thumbs/en/cv10.jpg",
        label: "Harum",
        category: "tripple",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv12",
        value: "/app/static/images/thumbs/en/cv12.jpg",
        label: "Graphite",
        category: "double",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv9",
        value: "/app/static/images/thumbs/en/cv9.jpg",
        label: "Lectus",
        category: "tripple",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
      {
        key: "cv19",
        value: "/app/static/images/thumbs/en/cv19.jpg",
        label: "Pro",
        category: "single",
        position: "left",
        personal_info: false,
        defaultFontFamily: "",
        defaultFontSize: "",
        defaultHeadingFontSize: "",
      },
    ],
  };
  return response;
};
const InputPlaceholder = [{ value: "e.g. Spanish" }, { value: "e.g. German" }, { value: "e.g. French" }];
export const getLanguageList = () => {
  let response = {
    data: [
      { placeholder: "e.g Spanish" },
      { placeholder: "German" },
      { placeholder: "French" },
      { placeholder: "Swedish" },
      { placeholder: "Arabic" },
      { placeholder: "Hindi" },
      { placeholder: "Chinese" },
      { placeholder: "Chinese" },
      { placeholder: "Chinese" },
      { placeholder: "Chinese" },
    ],
  };
  return response;
};
export const getSkillList = (t) => {
  let response = {
    data: [
      { placeholder: t("skill.exp_level_1") },
      { placeholder: t("skill.exp_level_2") },
      { placeholder: t("skill.exp_level_3") },
      { placeholder: t("skill.exp_level_4") },
      { placeholder: t("skill.exp_level_5") },
      { placeholder: t("skill.exp_level_6") },
      { placeholder: t("skill.exp_level_7") },
      { placeholder: t("skill.exp_level_8") },
      { placeholder: t("skill.exp_level_9") },
      { placeholder: t("skill.exp_level_10") },
    ],
  };
  return response;
};
export const getListOfStates = (t) => {
  let response = {
    data: [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AS", label: "American Samoa" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "DC", label: "District of Columbia" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "GU", label: "Guam" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "PR", label: "Puerto Rico" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "VI", label: "Virgin Islands" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
    ],
  };
  return response;
};
export const getCoverLetterSkills = (t) => {
  let response = {
    data: [
      { id: 0, skillTitle: t("cover_letter_skills.Communication") },
      { id: 1, skillTitle: t("cover_letter_skills.Customer service") },
      { id: 2, skillTitle: t("cover_letter_skills.Teamwork") },
      { id: 3, skillTitle: t("cover_letter_skills.Leadership") },
      { id: 4, skillTitle: t("cover_letter_skills.Problem-solving") },
      { id: 5, skillTitle: t("cover_letter_skills.Time management") },
      { id: 6, skillTitle: t("cover_letter_skills.Adaptability") },
      { id: 7, skillTitle: t("cover_letter_skills.Dependability") },
      { id: 8, skillTitle: t("cover_letter_skills.Technological") },
      { id: 9, skillTitle: t("cover_letter_skills.Work ethic") },
      { id: 10, skillTitle: t("cover_letter_skills.Forecasting") },
      { id: 11, skillTitle: t("cover_letter_skills.Data mining") },
      { id: 12, skillTitle: t("cover_letter_skills.Data presentation") },
      { id: 13, skillTitle: t("cover_letter_skills.Resource management") },
      { id: 14, skillTitle: t("cover_letter_skills.Data engineering") },
      { id: 15, skillTitle: t("cover_letter_skills.Database management") },
      {
        id: 16,
        skillTitle: t("cover_letter_skills.Data and metrics interpreting"),
      },
      { id: 17, skillTitle: t("cover_letter_skills.Reporting") },
      { id: 18, skillTitle: t("cover_letter_skills.Diagnostics") },
      { id: 19, skillTitle: t("cover_letter_skills.Creativity") },
      { id: 20, skillTitle: t("cover_letter_skills.Theorizing") },
      { id: 21, skillTitle: t("cover_letter_skills.Visual communication") },
      { id: 22, skillTitle: t("cover_letter_skills.Slideshows") },
      { id: 23, skillTitle: t("cover_letter_skills.Research") },
      { id: 24, skillTitle: t("cover_letter_skills.Data analysis") },
      { id: 25, skillTitle: t("cover_letter_skills.Graphic design") },
    ],
  };
  return response;
};
export const getFonts = () => {
  let response = [
    { id: 0, fontName: "font-dancing" },
    { id: 1, fontName: "font-saint" },
    { id: 2, fontName: "font-dynalight" },
  ];
  return response;
};

export const getColor = () => {
  let response = [
    { id: 0, type: "black", color: "#111111" },
    { id: 1, type: "blue", color: "#044F8A" },
  ];
  return response;
};

export const getJobTitleFaqs = (t) => {
  return {
    data: {
      question: t("faq-job-title.question"),
      answers: [
        {
          id: 1,
          faq: t("faq-job-title.answer-1"),
        },
        {
          id: 2,
          faq: t("faq-job-title.answer-2"),
        },
        {
          id: 3,
          faq: t("faq-job-title.answer-3"),
        },
        {
          id: 4,
          faq: t("faq-job-title.answer-4"),
        },
      ],
    },
  };
};

export const getExperienceFaqs = (t) => {
  return {
    data: {
      question: t("faq-experience.question"),
      answers: [
        {
          id: 1,
          faq: t("faq-experience.answer-1"),
        },
        {
          id: 2,
          faq: t("faq-experience.answer-2"),
        },
        {
          id: 3,
          faq: t("faq-experience.answer-3"),
        },
        {
          id: 4,
          faq: t("faq-experience.answer-4"),
        },
      ],
    },
  };
};

export const getSkillFaqs = (t) => {
  return {
    data: {
      question: t("faq-skills.question"),
      answers: [
        {
          id: 1,
          faq: t("faq-skills.answer-1"),
        },
        {
          id: 2,
          faq: t("faq-skills.answer-2"),
        },
        {
          id: 3,
          faq: t("faq-skills.answer-3"),
        },
        {
          id: 4,
          faq: t("faq-skills.answer-4"),
        },
      ],
    },
  };
};

export const getRecommendFaqs = (t) => {
  return {
    data: {
      question: t("faq-recommend.question"),
      answers: [
        {
          id: 1,
          faq: t("faq-recommend.answer-1"),
        },
        {
          id: 2,
          faq: t("faq-recommend.answer-2"),
        },
        {
          id: 3,
          faq: t("faq-recommend.answer-3"),
        },
        {
          id: 4,
          faq: t("faq-recommend.answer-4"),
        },
      ],
    },
  };
};
