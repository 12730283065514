import { createReducer } from "reduxsauce";
import Types from "./types";
import { emptyParagraph } from "../../initialState";
import { SECTION_TYPE, SECTION_CODE } from "../../utils/constants";

export const INITIAL_STATE = {
  isLoading: true,
  error: null,
  total: 0,
  Paragraphs: emptyParagraph(SECTION_CODE.EXPR, SECTION_TYPE.EXPR),
};
export const updateExperienceParagraph = (
  state = INITIAL_STATE,
  headerdata
) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data };
};

export const fetchExperienceSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    Paragraphs: data,
    isLoading: false,
    error: null,
  };
};

export const fetchExperienceFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};

export const deleteExperienceSuccess = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, Paragraphs: data, isLoading: false, error: null };
};
export const resetExperience = () => {
  return { ...INITIAL_STATE };
};
export const renameExperienceSection = (
  state = INITIAL_STATE,
  { paraid, newParaname }
) => {
  let newState = { ...state };
  newState.Paragraphs.find((x) => x.id == paraid).paraName = newParaname;
  return { ...newState, isLoading: false, error: null };
};
export const HANDLERS = {
  [Types.FETCH_EXPERIENCE_SUCCESS]: fetchExperienceSuccess,
  [Types.FETCH_EXPERIENCE_FAILURE]: fetchExperienceFailure,
  [Types.UPDATE_EXP_SUCCESS]: updateExperienceParagraph,
  [Types.DELETE_EXPERIENCE_SUCCESS]: deleteExperienceSuccess,
  [Types.RESET_EXPERIENCE]: resetExperience,
  [Types.RENAME_EXPERIENCE]: renameExperienceSection,
};
export default createReducer(INITIAL_STATE, HANDLERS);
