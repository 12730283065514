export const ROUTE_CONSTANT = {
  ROOT_HOME: "/",
  HOME: "/app",
  SSO_TRANSFER: "/app/sso-transfer",
  SIGNOUT: "/app/sign-out",
  SIGNIN: "/app/signin",
  SIGNUP: "/app/signup",
  SIGNIN_V2: "/app/account/signin",
  SIGNUP_V2: "/app/account/signup",
  INTRODUCTION: "/app/introduction",
  BUILD_MY_RESUME: "/app/build-my-resume",
  PERSON_INFO: "/app/personal-info",
  PERSON_INFO_EDIT: "/app/personal-info/edit",
  OPENER_PAGE: "/app/opener-page",
  BODY_PAGE: "app/body-page",
  SKILLS_PAGE: "/app/body-page/skills",
  SKILLS_PAGE_LOCAL: "/app/:locale/body-page/skills",
  SIGNATURE_PAGE: "/app/closer-page/signature",
  SIGNATURE_PAGE_LOCAL: "/app/:locale/closer-page/signature",
  RECOMMEND_PAGE: "/app/opener-page/recommend",
  RECOMMEND_PAGE_LOCAL: "/app/:locale/opener-page/recommend",
  BODY_RECOMMEND_PAGE: "/app/body-page/recommend",
  BODY_RECOMMEND_PAGE_LOCAL: "/app/:locale/body-page/recommend",
  CLOSER_RECOMMEND_PAGE: "/app/closer-page/recommend",
  CLOSER_RECOMMEND_PAGE_LOCAL: "/app/:locale/closer-page/recommend",
  CLOSER: "/app/closer-page",
  CLOSER_LOCAL: "/app/:locale/closer-page",
  SKILLS_TIPS: "/app/skill-tips",
  SKILLS: "/app/skill",
  LANGUAGE: "/app/language",
  SKILLS_EDIT: "/app/skills/edit",
  EDUCATION_TIPS: "/app/education-tips",
  EDUCATION: "/app/education",
  EDUCATION_EDIT: "/app/education/edit",
  CHOOSE_TEMPLATE: "/app/choose-templates",
  INTRO_PAGE: "/app/templates",
  PERSONAL_INFORMATION: "app/templates/personal-information",
  JOB_TITLE: "app/opener-page/job-title",
  CHOOSE_TEMPLATE_LOCALE: "/app/:locale/choose-templates",
  JOB_TITLE_LOCAL: "app/:locale/opener-page/job-title",
  BODY_PAGE_LOCAL: "app/:locale/body-page",
  INTRO_PAGE_LOCALE: "/app/:locale/templates",
  OPENER_PAGE_LOCAL: "/app/:locale/opener-page",
  CVLTR_EXPERIENCE: "app/opener-page/experience",
  CVLTR_EXPERIENCE_LOCAL: "app/:locale/opener-page/experience",
  CVLTR_REVIEW: "app/cover-letter-review",
  CVLTR_REVIEW_LOCAL: "app/:locale/cover-letter-review",
  COVER_LETTERS: "/app/cover-letters",
  COVER_LETTERS_LOCALE: "/app/:locale/cover-letters",
  DESIRED_COMPANY_LOCAL: "/app/:locale/opener-page/desired-company",
  DESIRED_COMPANY: "/app/opener-page/desired-company",
  PERSONAL_INFORMATION_LOCAL: "/app/:locale/templates/personal-information",
  EXPERIENCE_TIPS: "/app/experience-tips",
  EXPERIENCE: "/app/experience",
  EXPERIENCE_EDIT: "/app/experience/edit",
  SUMMARY: "/app/summary",
  SUMMARY_TIPS: "/app/summary-tips",
  SUMMARY_EDIT: "/app/summary/edit",
  USER_ADMIN_DASHBOARD: "/app/dashboard",
  USER_ADMIN_SETTINGS: "/app/settings",
  FORGOT_PASSWORD: "/app/forgot-password",
  PAYMENT_PLAN: "/app/plans",
  CHECKOUT_PAGE: "/app/checkout-page",
  CHECKOUT: "/app/checkout",
  CANCEL_SUBSCRIPTION: "/app/cancel-subscription",
  CANCEL_SUBSCRIPTION_CONFIRMATION: "/app/cancel-subscription-confirmation",
  CONFIRMATION: "/app/confirmation",
  ADMIN_DASHBOARD: "/app/admin/dashboard",
  ADMIN_SUBSCRIPTION_VIEW: "/admin/subscription/view",
  ADMIN_SUBSCRIPTION_CANCEL: "/admin/subscription/cancel",
  ADMIN_RESUME_DOWNLOAD: "/admin/resume/download",
  ADMIN_BECOME: "/admin/become",
  REVIEW: "/app/review",
  SETTINGS: "/app/setting",
  RESET_PASSWORD: "/app/reset-password",
  RESET_PASSWORD_LOCALE: "/app/:locale/reset-password",
  CUSTOM_SECTION: "/app/custom-section/:sectionname",
  OTHER_SECTIONS: "/app/others/:sectionname",
  THANK_YOU: "/app/thank-you",
  PLANS: "/app/plans",
  RESUMES: "/app/resumes",
  ADMIN_LOGIN: "/app/admin-login",
  PAGE_NOT_FOUND: "/app/page-not-found",
  ROOT_HOME_LOCALE: "/:locale/",
  HOME_LOCALE: "/app/:locale",
  SSO_TRANSFER_LOCALE: "/app/:locale/sso-transfer",
  SIGNOUT_LOCALE: "/app/:locale/sign-out",
  SIGNIN_LOCALE: "/app/:locale/signin",
  SIGNUP_LOCALE: "/app/:locale/signup",
  SIGNIN_LOCALE_V2: "/app/:locale/account/signin",
  SIGNUP_LOCALE_V2: "/app/:locale/account/signup",
  INTRODUCTION_LOCALE: "/app/:locale/introduction",
  BUILD_MY_RESUME_LOCALE: "/app/:locale/build-my-resume",
  PERSON_INFO_LOCALE: "/app/:locale/personal-info",
  PERSON_INFO_EDIT_LOCALE: "/app/:locale/personal-info/edit",
  SKILLS_TIPS_LOCALE: "/app/:locale/skill-tips",
  SKILLS_LOCALE: "/app/:locale/skill",
  LANGUAGES_LOCALE: "/app/:locale/language",
  SKILLS_EDIT_LOCALE: "/app/:locale/skills/edit",
  EDUCATION_TIPS_LOCALE: "/app/:locale/education-tips",
  EDUCATION_LOCALE: "/app/:locale/education",
  EDUCATION_EDIT_LOCALE: "/app/:locale/education/edit",
  EXPERIENCE_TIPS_LOCALE: "/app/:locale/experience-tips",
  EXPERIENCE_LOCALE: "/app/:locale/experience",
  EXPERIENCE_EDIT_LOCALE: "/app/:locale/experience/edit",
  SUMMARY_LOCALE: "/app/:locale/summary",
  SUMMARY_TIPS_LOCALE: "/app/:locale/summary-tips",
  SUMMARY_EDIT_LOCALE: "/app/:locale/summary/edit",
  USER_ADMIN_DASHBOARD_LOCALE: "/app/:locale/dashboard",
  USER_ADMIN_SETTINGS_LOCALE: "/app/:locale/settings",
  FORGOT_PASSWORD_LOCALE: "/app/:locale/forgot-password",
  PAYMENT_PLAN_LOCALE: "/app/:locale/plans",
  CHECKOUT_PAGE_LOCALE: "/app/:locale/checkout-page",
  CHECKOUT_LOCALE: "/app/:locale/checkout",
  CANCEL_SUBSCRIPTION_LOCALE: "/app/:locale/cancel-subscription",
  CANCEL_SUBSCRIPTION_CONFIRMATION_LOCALE: "/app/:locale/cancel-subscription-confirmation",
  CONFIRMATION_LOCALE: "/app/:locale/confirmation",
  ADMIN_DASHBOARD_LOCALE: "/app/:locale/admin/dashboard",
  ADMIN_SUBSCRIPTION_VIEW_LOCALE: "/:locale/admin/subscription/view",
  ADMIN_SUBSCRIPTION_CANCEL_LOCALE: "/:locale/admin/subscription/cancel",
  ADMIN_RESUME_DOWNLOAD_LOCALE: "/:locale/admin/resume/download",
  ADMIN_BECOME_LOCALE: "/:locale/admin/become",
  REVIEW_LOCALE: "/app/:locale/review",
  SETTINGS_LOCALE: "/app/:locale/setting",
  CUSTOM_SECTION_LOCALE: "/app/:locale/custom-section/:sectionname",
  OTHER_SECTIONS_LOCALE: "/app/:locale/others/:sectionname",
  THANK_YOU_LOCALE: "/app/:locale/thank-you",
  PLANS_LOCALE: "/app/:locale/plans",
  RESUMES_LOCALE: "/app/:locale/resumes",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_POLICY_LOCALE: "/:locale/privacy-policy",
  CVLTR_PRIVACY_POLICY: "https://resumebuild.com/privacy-policy/",
  CVLTR_PRIVACY_POLICY_LOCALE: "https://resumebuild.com/:locale/privacy-policy/",
  CONTACT_US: "/contact-us",
  CONTACT_US_LOCALE: "/:locale/contact-us",
  CVLTR_CONTACT_US: "https://resumebuild.com/contact-us/",
  CVLTR_CONTACT_US_LOCALE: "https://resumebuild.com/:locale/contact-us/",
  TERMS_USE: "/terms-of-use",
  TERMS_USE_LOCALE: "/:locale/terms-of-use",
  CVLTR_TERMS_USE: "https://resumebuild.com/terms-of-use/",
  CVLTR_TERMS_USE_LOCALE: "https://resumebuild.com/:locale/terms-of-use/",
  PAGE_NOT_FOUND_LOCALE: "/app/:locale/page-not-found",
  SESSION_EXPIRE: "/app/session-expire",
  SESSION_EXPIRE_LOCALE: "/app/:locale/session-expire",
  CHOOSE_OPTIONS: "/app/choose-options",
  CHOOSE_OPTIONS_LOCALE: "/app/:locale/choose-options",
  UPLOAD_RESUME: "/app/upload-resume",
  UPLOAD_RESUME_LOCALE: "/app/:locale/upload-resume",
  SELECTED_DOCUMENT: "/app/selected-document",
  SELECTED_DOCUMENT_LOCALE: "/app/:locale/selected-document",
  APPLY_TEMPLATE: "/app/apply-template",
  APPLY_TEMPLATE_LOCALE: "/app/:locale/apply-template",
  COVER_LETTER_INTRO: "/app/cover-letter-introduction",
  COVER_LETTER_INTRO_LOCALE: "/app/:locale/cover-letter-introduction",
};
