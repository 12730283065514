import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSelectedTemplate,
  savePersonalInfo,
  updatePersonalInfo,
} from "./actions";
import { setCurrentStep, setDocGUID, setName } from "../funnel-step/actions";
import CoverLetterPersonalInformation from "./component";
import { updateProgress } from "../../components/cover-letter-sidebar/actions";
import {
  handleSteps,
  previewTemplateAction,
} from "../cover-letter-template/actions";
import { saveTabValue } from "../cover-letter-signature/actions";

const mapStateToProps = ({
  docguid,
  funnelStep,
  personalInfo,
  selectedTemplate,
  cvltrTemplatePreview,
}) => ({
  docguid,
  funnelStep,
  personalInfo,
  selectedTemplate,
  cvltrTemplatePreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      savePersonalInfoConnect: savePersonalInfo,
      SaveDocGUIDConnect: setDocGUID,
      updatePersonalInfoConnect: updatePersonalInfo,
      setCurrentStepConnect: setCurrentStep,
      setNameConnect: setName,
      getSelectedTemplate: getSelectedTemplate,
      updateProgress: updateProgress,
      previewTemplateAction: previewTemplateAction,
      handleSteps: handleSteps,
      saveSignatureConnect: saveTabValue,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverLetterPersonalInformation);
