import React, { useState, useEffect } from "react";
import "./cover-letter-thumbnail.scss";
import { getCoverLetterTemplateList } from "../../services/master-data";

const CoverLetterThumb = ({
  item,
  index,
  updateSelection,
  selectedResume,
  callback,
  setThumbMouseOver,
}) => {
  useEffect(() => {
    callback(selectedResume.selected);
  }, []);
  const mouseOver = () => {
    callback(item);
    setThumbMouseOver(true);
  };
  const mouseOut = () => {
    callback(selectedResume.selected);
  };
  const onClick = () => {
    updateSelection({ item, index });
  };
  return (
    <div
      className={
        index === selectedResume.index
          ? "sb-template__template is-selected"
          : "sb-template__template"
      }
      onClick={onClick}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <img src={item.imagesource} />
      <span>{item.templatename}</span>
    </div>
  );
};

const CoverLetterThumbnail = ({
  callback,
  selectedTemplateCallBack,
  selectedTemplate,
  setThumbMouseOver,
}) => {
  const [tumbnailList, setTumbnailList] = useState(
    getCoverLetterTemplateList().data
  );
  const [selectedResume, setSelectedResume] = useState({
    selected:
      selectedTemplate != ""
        ? tumbnailList.find((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic"),
    index:
      selectedTemplate != ""
        ? tumbnailList.findIndex((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic"),
  });
  const updateSelection = ({ item, index }) => {
    selectedTemplateCallBack(item);
    setSelectedResume({ selected: item, index });
  };
  useEffect(() => {
    const selectedResumeTemplate =
      selectedTemplate != ""
        ? tumbnailList.find((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic");
    const selectedResumeTemplateIndex =
      selectedTemplate != ""
        ? tumbnailList.findIndex((x) => x.templatecd === selectedTemplate)
        : tumbnailList.find((x) => x.templatecd == "Classic");
    setSelectedResume({
      selected: selectedResumeTemplate,
      index: selectedResumeTemplateIndex,
    });
  }, [selectedTemplate]);
  return (
    <>
      {tumbnailList.map((item, index) => {
        return (
          <CoverLetterThumb
            key={"CoverLetterThumb" + index}
            item={item}
            index={index}
            updateSelection={updateSelection}
            selectedResume={selectedResume}
            callback={callback}
            setThumbMouseOver={setThumbMouseOver}
          />
        );
      })}
    </>
  );
};

export default CoverLetterThumbnail;
