export function IsPresent(input) {
  return input && input.trim() ? true : false;
}
export function IsValidEmail(input) {
  let re = /^\w+([-+.']\w+)*@\w+([-.']\w+)*\.\w+([-.]\w+)*$/; // window.RDL.EmailRegex;
  let result = re.test(input);
  return result;
}
export function IsValidGuid(input) {
  let regExp =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regExp.test(input);
}
export function minLength(text, minLength) {
  let result = text.length < minLength ? false : true;
  return result;
}
