import React, { useState, useEffect } from "react";
import "./header-steps.scss";
import Logo from "../header/navbar/logo";
// import Logo from "./navbar/logo";
// import TopNav from "./navbar/top-nav";
// import TopBtnGroup from "./navbar/btns-group";
import LanguageSelect from "../../languageSelect";
import { Trans, useTranslation } from "react-i18next";
import ScrollToTop from "../scroll-to-top/component";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Header = ({ activeStep, isCoverLetter }) => {
  const { t } = useTranslation();
  let headerStepList = "";
  if (isCoverLetter !== undefined) {
    headerStepList = t("header.coverLetterHeaderSteps.data", {
      returnObjects: true,
    });
  } else {
    headerStepList = t("header.headerSteps.data", { returnObjects: true });
  }
  const [currentStep, setCurrentStep] = useState(activeStep);
  useEffect(() => {
    setCurrentStep(activeStep);
  }, []);
  let activeClass = "";
  let completedClass = "";
  let iscompleted = true;
  return (
    <>
      <ScrollToTop />
      <header
        className={classNames(
          isCoverLetter !== undefined ? "sidebar-sticky-cvrltr" : "",
          "header sidebar-sticky"
        )}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container navsteps">
            <Logo />

            {/* <Steps /> */}
            <ul
              className={classNames(
                isCoverLetter !== undefined ? "cover-letter-sticky" : "",
                "steps sticky-list"
              )}
            >
              <div className="progressbar">
                <span className="pb-1 d-block">{t("header.pregress")}</span>
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${
                        ((currentStep + 1) / headerStepList.length) * 100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              {headerStepList?.map((item, index) => {
                if (currentStep === index) {
                  activeClass = " active";
                  iscompleted = false;
                  completedClass = "";
                } else if (iscompleted) {
                  completedClass = " steps__completed";
                } else {
                  activeClass = "";
                }
                return (
                  <li
                    className={"steps__item" + completedClass + activeClass}
                    key={item.key}
                  >
                    <span className="steps__number">
                      <i className="fa fa-check"></i>
                    </span>
                    <span className="steps__label">{item.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
