import React, { useState } from "react";
import "../../assets/scss/login-forms.scss";
import Header from "../../components/header";
import Footer from "../../components/footer/component";
import { useNavigate, useParams } from "react-router-dom";
import resetPassword from ".";
import Button from "../../components/shared/button";
import InputText from "../../components/shared/input-text/component";
import appLogger from "../../utils/app-logger";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { getSignInLink } from "../../utils/path-helper";
import { getSignUpLink } from "../../utils/path-helper";
import { Helmet } from "react-helmet";

const ResetPassword = ({ resetPasswordConnect }) => {
  const { t } = useTranslation();
  const { locale } = useParams();
  let signInLink = getSignInLink(locale);
  let signUpLink = getSignUpLink(locale);

  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [pwdErrorMsg, setPwdErrorMsg] = useState("");

  const onpasswordChange = (e) => {
    const {
      target: { value },
    } = e;
    setPassword(value);
  };
  const onconfirmPasswordChange = (e) => {
    const {
      target: { value },
    } = e;
    setConfirmPassword(value);
  };
  const token = searchParams.get("cd");
  //if (token != "" && token != null) {
  const userGuid = searchParams.get("uid");
  //const userGuid = "4ffffc0b949e4f1bb652aed0e9249404"; //TODO: get this from states;
  const saveClickHandler = (e) => {
    appLogger.log("saveClickHandlerForresetpasswordCalled");
    if (validateData()) {
      var data = {
        userGuid: userGuid,
        newPassword: password,
        token: token,
      };

      resetPasswordConnect(data)
        .then((response) => {
          appLogger.log("inside updateUserSettingsConnect then ");
          navigate(signInLink);
          const {
            data: {
              detail: { data },
            },
          } = response;
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail); // TODO
        });
    }
  };
  const navigateSignIn = () => {
    navigate(signInLink);
  };
  const signUpNavigate = () => {
    navigate(signUpLink);
  };
  const validateData = () => {
    let isValid = true;
    if (!password) {
      setPwdErrorMsg(t("generic_error.enter_password"));
      isValid = false;
    }
    if (isValid && !confirmPassword) {
      setPwdErrorMsg(t("generic_error.confirm_password"));
      isValid = false;
    }
    if (isValid && password !== "" && confirmPassword !== "") {
      if (password != confirmPassword) {
        setPwdErrorMsg(t("generic_error.unmatch_password"));
        isValid = false;
      } else if (
        password === confirmPassword &&
        password.length < 6 &&
        confirmPassword.length < 6
      ) {
        setPwdErrorMsg(t("generic_error.password_length"));
        isValid = false;
      }
    }
    if (isValid) {
      setPwdErrorMsg("");
    }
    return isValid;
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.reset_password")}</title>
      </Helmet>
      <Header />
      <section className="main-content">
        <div className="container" style={{ paddingBottom: 250 }}>
          <div className="form-wrap">
            <div className="form-container">
              <hgroup className="text-center mb-4">
                <h1 className="main-headline">
                  {t("reset_password.headline")}
                </h1>
              </hgroup>
              <div className="form-group">
                <InputText
                  showLabel={false}
                  required="required"
                  id="password"
                  text="Password"
                  type="password"
                  name="password"
                  maxlength="128"
                  onChange={onpasswordChange}
                  value={password}
                  placeholder={t("reset_password.placeholder_new_password")}
                />
              </div>
              <div className="form-group">
                <InputText
                  showLabel={false}
                  required="required"
                  id="confirmPassword"
                  text="Password"
                  type="password"
                  name="confirmPassword"
                  maxlength="128"
                  onChange={onconfirmPasswordChange}
                  value={confirmPassword}
                  placeholder={t("reset_password.placeholder_confirm_password")}
                />
              </div>
              <div className="form-text">
                <small className="form-text text-danger">{pwdErrorMsg}</small>
              </div>
              <div className="fbn">
                <Button
                  isLoading={false}
                  disabled={false}
                  classNames="btn btn-primary w-100 text-uppercase"
                  onClick={saveClickHandler}
                  ctaText={t("reset_password.btn_cta")}
                />
              </div>
              <div className="form-container--footer text-center">
                <p className="small">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("sign_up.already_account")),
                    }}
                  />
                  <Button
                    onClick={navigateSignIn}
                    classNames="btn btn-link px-0"
                    ctaText={t("sign_up.sign_in")}
                  />
                </p>
                <p className="small ">
                  {t("sign_in.create_account")}
                  <Button
                    onClick={signUpNavigate}
                    classNames="btn btn-link px-0"
                    ctaText={t("sign_in.create_signup")}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ResetPassword;
