import {
  getSsoPdfsimpliEndPoint,
  getSsoSignsimpliEndPoint,
  endPoints,
} from "./api-constants";
import { apiService } from "../services/base-api-service";

class SingleSignOn {
  SingleSignOn(url, portal) {
    if (portal == "SS") {
      return getSsoSignsimpliEndPoint(url);
    } else {
      return getSsoPdfsimpliEndPoint(url);
    }
  }
  partnerLogin(legacyTicketCookie, portal) {
    const partnerLoginEndPoint = this.SingleSignOn(
      `${endPoints.ssoLogin.partnerLogin.replace(
        ":legacyTicketCookie",
        legacyTicketCookie
      )}`,
      portal
    );
    // // const partnerLoginEndPoint = this.SingleSignOn(
    // //   `${endPoints.ssoLogin.partnerLogin}?legacyTicket=${legacyTicketCookie}`,
    // //   portal
    // // );
    // const partnerLoginEndPoint = this.SingleSignOn(
    //   `${endPoints.ssoLogin.partnerLogin}?legacyTicket=${legacyTicketCookie}`,
    //   portal
    // );
    return apiService.get(partnerLoginEndPoint, null, null, true);
  }
  partnerLogOut(portal) {
    const partnerLogOutEndPoint = this.SingleSignOn(
      `${endPoints.ssoLogin.partnerLogOut}`,
      portal
    );
    return apiService.get(partnerLogOutEndPoint, null, null, true);
  }
}
export default new SingleSignOn();
