import Types from "./types";
import DashboardOrchestrationService from "../../services/dashboard-orchestration-service-api";

const resetUploadResume  = () => ({
  type: Types.RESET_UPLOAD_RESUME
});
const uploadResume = (data) => ({
  type: Types.SUCCESS,
  data: data,
});

const uploadDocs = (data) => {
  return function (dispatch) {
    return DashboardOrchestrationService.uploadDocuments(data)
      .then((response) => {
        console.log("DashboardOrchestrationService", response);
        if (!!response) {
        } else {
        }
        return response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

export { uploadResume, uploadDocs,resetUploadResume };
