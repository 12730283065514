import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
  selectedData: "",
};

export const saveRecommendInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const updateRecommendInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const saveSelectedInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, selectedData: data };
};
export const resetRecommendInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveRecommendInfo,
  [Types.UPDATE_SUCCESS]: updateRecommendInfo,
  [Types.SAVE_SELECTED]: saveSelectedInfo,
  [Types.RESET_CLOSER_RECOMMEND]: resetRecommendInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
