import React from "react";
import "./button.css";
import ButtonLoader from "../button-loader";

const RBButton = (props) => {
  const {
    ctaText,
    id = "",
    onClick = () => {},
    icon = " ",
    classNames = "",
    titleName = "",
    disabled = false,
    actionCtaText = "",
    isLoading,
    name = "",
  } = props;
  return (
    <button
      onClick={onClick}
      id={id}
      className={classNames}
      title={titleName}
      disabled={disabled}
      {...(name && { name: name })}
    >
      {icon}
      {actionCtaText ? actionCtaText : ctaText}
      {isLoading && <ButtonLoader />}
    </button>
  );
};

export default RBButton;
