import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Review from "./component";
import {
  getUserResume,
  resetStore,
  updateParagraphOrdering,
  renameParagraph,
  deleteParagraph,
  updateDocStyle,
  updateUserGuid,
  getUserProfile,
  updateTemplateCd,
  generatePDFPath,
} from "./actions";
import { setTemplate,setDocGUID } from "../funnel-step/actions";

const mapStateToProps = ({ docReview, funnelStep }) => ({
  docReview,
  funnelStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserResumeConnect: getUserResume,
      resetStoreConnect: resetStore,
      updateOrderingConnect: updateParagraphOrdering,
      renameParagraphConnect: renameParagraph,
      deleteParagraphConnect: deleteParagraph,
      updateDocStyleConnect: updateDocStyle,
      updateUserGuidConnect: updateUserGuid,
      getUserProfileConnect: getUserProfile,
      setTemplateConnect: setTemplate,
      updateTemplateCdConnect: updateTemplateCd,
      generatePDFPathConnect: generatePDFPath,
      setDocGUIDConnect: setDocGUID,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Review);
