import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv4ElegantCSS = css`
  .resume-layout.cv4 {
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv4 .sub-headline {
    display: block;
  }
  .resume-layout.cv4 .personal-info.sub-headline {
    display: none;
  }
  .resume-layout.cv4 .resume__section.resume__heading {
    text-align: center;
    border-top: 0;
    padding-top: 1rem;
  }
  .resume-layout.cv4 .resume__section.resume__heading .info-main {
    display: grid;
  }
  .resume-layout.cv4
    .resume__section.resume__heading
    .info-main
    .info-item:first-child {
    order: 2;
  }
  .resume-layout.cv4
    .resume__section.resume__heading
    .info-main
    .info-item:nth-child(2) {
    order: 3;
  }
  .resume-layout.cv4
    .resume__section.resume__heading
    .info-main
    .info-item:last-child {
    order: 1;
  }
  .resume-layout.cv4 .resume__section.resume__heading .info-item {
    margin-bottom: 2px;
  }
  .resume-layout.cv4 .resume__section.resume__heading .info-item .info-label {
    display: none;
  }
  .resume-layout.cv4 .resume__section.resume__heading .info-item .info-text {
    width: 100%;
  }
  .resume-layout.cv4 .resume__section {
    padding-top: 3rem;
    padding-bottom: 2em;
    margin-bottom: 0;
    border-top: 0.07em solid #d0d0d0;
  }
  .resume-layout.cv4 .resume__section.resume__heading h1 {
    font-size: 2.86em;
    font-weight: normal;
    text-transform: capitalize;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv4 .resume__section .sub-headline h2 {
    font-size: 1.29em;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: normal;
    font-style: italic;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv4 .resume__section h1,
  .resume-layout.cv4 .resume__section h3 {
    font-size: 1.15em;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-weight: normal;
    font-style: italic;
    font-family: "Merriweather", serif;
  }
  .resume-layout.cv4 .xp-item .xp-job {
    border: 0;
    font-weight: 400;
  }
  .resume-layout.cv4 .xp-item .xp-job .exp-show {
    font-weight: 600;
  }
  .resume-layout.cv4 .xp-item .xp-date {
    text-align: left;
    font-weight: normal;
    font-style: italic;
  }
  .resume-layout.cv4 .extra .extra-details {
    font-size: 1em;
    min-width: 145px;
  }
  .resume-layout.cv4 .wrap .left-col {
    width: 100%;
  }
  .resume-layout.cv4 .wrap .left-col .extra .extra-info {
    width: 30%;
  }
  .resume-layout.cv4 .wrap .left-col .extra .extra-details {
    width: 70%;
    padding-left: 1.5rem;
  }
`;

export const Cv4ElegantStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv4ElegantCSS}
`;
