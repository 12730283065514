import { baseUrls } from "./base-url";
import { environment, environmentTest } from "./enum";

let BASE_URL_USER_SERVICE;
let BASE_URL_DOCBUILDER_SERVICE;
let BASE_URL_DOCPARSER_SERVICE;
let BASE_URL_EVENT_SERVICE;
let BASE_URL_AUTH_SERVICE;
let BASE_URL_META_CONTENT_SERVICE;
let VISITOR_SERVICE_URL;
let BASE_URL_PAYMENT_SERVICE;
let BASE_URL_ORDER_SERVICE;
let BASE_URL_ORCHESTRATION_SERVICE;
let BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI;
let BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI;
let BASE_URL_SSO_DASHBOARD;
let BASE_URL_SIGNSIMPI;
let BASE_URL_PDFSIMPI;
let BASE_DASHBOARD_ORCHESTRATION;
let BASE_URL_TRUSTPILOT;
let BASE_URL_ABTEST;
let BASE_URL_LEGALSIMPLI;

const currentEnv = `${process.env.REACT_APP_ENVIRONMENT}`;

const baseUrl = {
  userService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.USER_SERVICE,
    QA: baseUrls.QA.USER_SERVICE,
    STAGING: baseUrls.STAGING.USER_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.USER_SERVICE,
  },
  docBuilderService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.DOCBUILDER_SERVICE,
    QA: baseUrls.QA.DOCBUILDER_SERVICE,
    STAGING: baseUrls.STAGING.DOCBUILDER_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.DOCBUILDER_SERVICE,
  },
  docParserService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.DOCPARSER_SERVICE,
    QA: baseUrls.QA.DOCPARSER_SERVICE,
    STAGING: baseUrls.STAGING.DOCPARSER_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.DOCPARSER_SERVICE,
  },
  eventService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.EVENT_SERVICE,
    QA: baseUrls.QA.EVENT_SERVICE,
    STAGING: baseUrls.STAGING.EVENT_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.EVENT_SERVICE,
  },
  authService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.AUTH_SERVICE,
    QA: baseUrls.QA.AUTH_SERVICE,
    STAGING: baseUrls.STAGING.AUTH_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.AUTH_SERVICE,
  },
  metaContentService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.META_CONTENT_SERVICE,
    QA: baseUrls.QA.META_CONTENT_SERVICE,
    STAGING: baseUrls.STAGING.META_CONTENT_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.META_CONTENT_SERVICE,
  },
  paymentService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.PAYMENT_SERVICE,
    QA: baseUrls.QA.PAYMENT_SERVICE,
    STAGING: baseUrls.STAGING.PAYMENT_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.PAYMENT_SERVICE,
  },
  orderService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.ORDER_SERVICE,
    QA: baseUrls.QA.ORDER_SERVICE,
    STAGING: baseUrls.STAGING.ORDER_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.ORDER_SERVICE,
  },
  orchestrationService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.ORCHESTRATION_SERVICE,
    QA: baseUrls.QA.ORCHESTRATION_SERVICE,
    STAGING: baseUrls.STAGING.ORCHESTRATION_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.ORCHESTRATION_SERVICE,
  },
  ssoPdfSimpli: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.SINGLE_SIGN_ON_PDFSIMPLI,
    QA: baseUrls.QA.SINGLE_SIGN_ON_PDFSIMPLI,
    STAGING: baseUrls.STAGING.SINGLE_SIGN_ON_PDFSIMPLI,
    PRODUCTION: baseUrls.PRODUCTION.SINGLE_SIGN_ON_PDFSIMPLI,
  },
  ssoSignSimpli: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.SINGLE_SIGN_ON_SIGNSIMPLI,
    QA: baseUrls.QA.SINGLE_SIGN_ON_SIGNSIMPLI,
    STAGING: baseUrls.STAGING.SINGLE_SIGN_ON_SIGNSIMPLI,
    PRODUCTION: baseUrls.PRODUCTION.SINGLE_SIGN_ON_SIGNSIMPLI,
  },
  ssoDashboard: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.SINGLE_SIGN_ON_DASHBOARD,
    QA: baseUrls.QA.SINGLE_SIGN_ON_DASHBOARD,
    STAGING: baseUrls.STAGING.SINGLE_SIGN_ON_DASHBOARD,
    PRODUCTION: baseUrls.PRODUCTION.SINGLE_SIGN_ON_DASHBOARD,
  },
  pdfSimpli: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.PDFSIMPLI_URL,
    QA: baseUrls.QA.PDFSIMPLI_URL,
    STAGING: baseUrls.STAGING.PDFSIMPLI_URL,
    PRODUCTION: baseUrls.PRODUCTION.PDFSIMPLI_URL,
  },
  signSimpli: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.SIGNSIMPLI_URL,
    QA: baseUrls.QA.SIGNSIMPLI_URL,
    STAGING: baseUrls.STAGING.SIGNSIMPLI_URL,
    PRODUCTION: baseUrls.PRODUCTION.SIGNSIMPLI_URL,
  },
  dashboardOrchestrationService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.DASHBOARD_ORCH_SERVICE,
    QA: baseUrls.QA.DASHBOARD_ORCH_SERVICE,
    STAGING: baseUrls.STAGING.DASHBOARD_ORCH_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.DASHBOARD_ORCH_SERVICE,
  },
  TrustPilotService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.TRUSTPILOT_SERVICE,
    QA: baseUrls.QA.TRUSTPILOT_SERVICE,
    STAGING: baseUrls.STAGING.TRUSTPILOT_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.TRUSTPILOT_SERVICE,
  },
  abtestService: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.ABTEST_SERVICE,
    QA: baseUrls.QA.ABTEST_SERVICE,
    STAGING: baseUrls.STAGING.ABTEST_SERVICE,
    PRODUCTION: baseUrls.PRODUCTION.ABTEST_SERVICE,
  },
  legalSimpli: {
    DEVELOPMENT: baseUrls.DEVELOPMENT.LEGALSIMPLI_URL,
    QA: baseUrls.QA.LEGALSIMPLI_URL,
    STAGING: baseUrls.STAGING.LEGALSIMPLI_URL,
    PRODUCTION: baseUrls.PRODUCTION.LEGALSIMPLI_URL,
  },
};
switch (currentEnv) {
  case environment.DEVELOPMENT:
    BASE_URL_USER_SERVICE = baseUrl.userService.DEVELOPMENT;
    BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.DEVELOPMENT;
    BASE_URL_DOCPARSER_SERVICE = baseUrl.docParserService.DEVELOPMENT;
    BASE_URL_EVENT_SERVICE = baseUrl.eventService.DEVELOPMENT;
    BASE_URL_AUTH_SERVICE = baseUrl.authService.DEVELOPMENT;
    BASE_URL_META_CONTENT_SERVICE = baseUrl.metaContentService.DEVELOPMENT;
    BASE_URL_PAYMENT_SERVICE = baseUrl.paymentService.DEVELOPMENT;
    BASE_URL_ORDER_SERVICE = baseUrl.orderService.DEVELOPMENT;
    VISITOR_SERVICE_URL = "https://test-app.pdfsimpli.com/content/js/visitor.js";
    BASE_URL_ORCHESTRATION_SERVICE = baseUrl.orchestrationService.DEVELOPMENT;
    BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI = baseUrl.ssoPdfSimpli.DEVELOPMENT;
    BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI = baseUrl.ssoSignSimpli.DEVELOPMENT;
    BASE_URL_SSO_DASHBOARD = baseUrl.ssoDashboard.DEVELOPMENT;
    BASE_URL_SIGNSIMPI = baseUrl.signSimpli.DEVELOPMENT;
    BASE_URL_PDFSIMPI = baseUrl.pdfSimpli.DEVELOPMENT;
    BASE_DASHBOARD_ORCHESTRATION = baseUrl.dashboardOrchestrationService.DEVELOPMENT;
    BASE_URL_TRUSTPILOT = baseUrl.TrustPilotService.DEVELOPMENT;
    BASE_URL_ABTEST = baseUrl.abtestService.DEVELOPMENT;
    BASE_URL_LEGALSIMPLI = baseUrl.legalSimpli.DEVELOPMENT;
    break;
  case environment.QA:
    BASE_URL_USER_SERVICE = baseUrl.userService.QA;
    BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.QA;
    BASE_URL_DOCPARSER_SERVICE = baseUrl.docParserService.QA;
    BASE_URL_EVENT_SERVICE = baseUrl.eventService.QA;
    BASE_URL_AUTH_SERVICE = baseUrl.authService.QA;
    BASE_URL_META_CONTENT_SERVICE = baseUrl.metaContentService.QA;
    BASE_URL_PAYMENT_SERVICE = baseUrl.paymentService.QA;
    BASE_URL_ORDER_SERVICE = baseUrl.orderService.QA;
    VISITOR_SERVICE_URL = "https://test-app.pdfsimpli.com/content/js/visitor.js";
    BASE_URL_ORCHESTRATION_SERVICE = baseUrl.orchestrationService.QA;
    BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI = baseUrl.ssoPdfSimpli.QA;
    BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI = baseUrl.ssoSignSimpli.QA;
    BASE_URL_SSO_DASHBOARD = baseUrl.ssoDashboard.QA;
    BASE_URL_SIGNSIMPI = baseUrl.signSimpli.QA;
    BASE_URL_PDFSIMPI = baseUrl.pdfSimpli.QA;
    BASE_DASHBOARD_ORCHESTRATION = baseUrl.dashboardOrchestrationService.QA;
    BASE_URL_TRUSTPILOT = baseUrl.TrustPilotService.QA;
    BASE_URL_ABTEST = baseUrl.abtestService.QA;
    BASE_URL_LEGALSIMPLI = baseUrl.legalSimpli.QA;
    break;
  case environment.STAGING:
    BASE_URL_USER_SERVICE = baseUrl.userService.STAGING;
    BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.STAGING;
    BASE_URL_DOCPARSER_SERVICE = baseUrl.docParserService.STAGING;
    BASE_URL_EVENT_SERVICE = baseUrl.eventService.STAGING;
    BASE_URL_AUTH_SERVICE = baseUrl.authService.STAGING;
    BASE_URL_META_CONTENT_SERVICE = baseUrl.metaContentService.STAGING;
    BASE_URL_PAYMENT_SERVICE = baseUrl.paymentService.STAGING;
    BASE_URL_ORDER_SERVICE = baseUrl.orderService.STAGING;
    VISITOR_SERVICE_URL = "https://static.worksimpli.io/cdn/content/js/visitor.js";
    BASE_URL_ORCHESTRATION_SERVICE = baseUrl.orchestrationService.STAGING;
    BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI = baseUrl.ssoPdfSimpli.STAGING;
    BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI = baseUrl.ssoSignSimpli.STAGING;
    BASE_URL_SSO_DASHBOARD = baseUrl.ssoDashboard.STAGING;
    BASE_URL_SIGNSIMPI = baseUrl.signSimpli.STAGING;
    BASE_URL_PDFSIMPI = baseUrl.pdfSimpli.STAGING;
    BASE_DASHBOARD_ORCHESTRATION = baseUrl.dashboardOrchestrationService.STAGING;
    BASE_URL_TRUSTPILOT = baseUrl.TrustPilotService.STAGING;
    BASE_URL_ABTEST = baseUrl.abtestService.STAGING;
    BASE_URL_LEGALSIMPLI = baseUrl.legalSimpli.STAGING;
    break;
  case environment.PRODUCTION:
    BASE_URL_USER_SERVICE = baseUrl.userService.PRODUCTION;
    BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.PRODUCTION;
    BASE_URL_DOCPARSER_SERVICE = baseUrl.docParserService.PRODUCTION;
    BASE_URL_EVENT_SERVICE = baseUrl.eventService.PRODUCTION;
    BASE_URL_AUTH_SERVICE = baseUrl.authService.PRODUCTION;
    BASE_URL_META_CONTENT_SERVICE = baseUrl.metaContentService.PRODUCTION;
    BASE_URL_PAYMENT_SERVICE = baseUrl.paymentService.PRODUCTION;
    BASE_URL_ORDER_SERVICE = baseUrl.orderService.PRODUCTION;
    VISITOR_SERVICE_URL = "https://static.worksimpli.io/cdn/content/js/visitor.js";
    BASE_URL_ORCHESTRATION_SERVICE = baseUrl.orchestrationService.PRODUCTION;
    BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI = baseUrl.ssoPdfSimpli.PRODUCTION;
    BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI = baseUrl.ssoSignSimpli.PRODUCTION;
    BASE_URL_SSO_DASHBOARD = baseUrl.ssoDashboard.PRODUCTION;
    BASE_URL_SIGNSIMPI = baseUrl.signSimpli.PRODUCTION;
    BASE_URL_PDFSIMPI = baseUrl.pdfSimpli.PRODUCTION;
    BASE_DASHBOARD_ORCHESTRATION = baseUrl.dashboardOrchestrationService.PRODUCTION;
    BASE_URL_TRUSTPILOT = baseUrl.TrustPilotService.PRODUCTION;
    BASE_URL_ABTEST = baseUrl.abtestService.PRODUCTION;
    BASE_URL_LEGALSIMPLI = baseUrl.legalSimpli.PRODUCTION;
    break;
  //   case environmentTest.DEVELOPMENT:
  //     BASE_URL_USER_SERVICE = baseUrl.userService.DEVELOPMENT;
  //     BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.DEVELOPMENT;
  //     BASE_URL_EVENT_SERVICE = baseUrl.eventService.DEVELOPMENT;
  //   break;
  default:
    BASE_URL_USER_SERVICE = baseUrl.userService.QA;
    BASE_URL_DOCBUILDER_SERVICE = baseUrl.docBuilderService.QA;
    BASE_URL_DOCPARSER_SERVICE = baseUrl.docParserService.QA;
    BASE_URL_EVENT_SERVICE = baseUrl.eventService.QA;
    BASE_URL_AUTH_SERVICE = baseUrl.authService.QA;
    BASE_URL_META_CONTENT_SERVICE = baseUrl.metaContentService.QA;
    BASE_URL_PAYMENT_SERVICE = baseUrl.paymentService.QA;
    BASE_URL_ORDER_SERVICE = baseUrl.orderService.QA;
    VISITOR_SERVICE_URL = "https://static.worksimpli.io/cdn/content/js/visitor.js";
    BASE_URL_ORCHESTRATION_SERVICE = baseUrl.orchestrationService.DEVELOPMENT;
    BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI = baseUrl.ssoPdfSimpli.QA;
    BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI = baseUrl.ssoSignSimpli.QA;
    BASE_URL_SSO_DASHBOARD = baseUrl.ssoDashboard.QA;
    BASE_URL_SIGNSIMPI = baseUrl.signSimpli.QA;
    BASE_URL_PDFSIMPI = baseUrl.pdfSimpli.QA;
    BASE_URL_ABTEST = baseUrl.abtestService.QA;
    BASE_URL_LEGALSIMPLI = baseUrl.legalSimpli.QA;
    break;
}

export const endPoints = {
  userService: {
    API_V1: "/api/v1",
    API_V2: "/api/v2",
    CREATE_USER: "user",
    CREATE_EXTERNAL_USER: "user/social",
    UPDATE_PWD: "user/change-password",
    RESET_PWD: "user/reset-password",
    GET_USER_PROFILE: "userprofile/:userGuid",
    SIGNIN_AS_ADMIN: "user/admin",
    GET_USER_BY_EMAIL: "user/search?email=:email&tenantGroupId=:tenantGroupId",
    GET_USER_BY_USERNAME: "user/search?username=:username&tenantGroupId=:tenantGroupId",
    FORGOT_PWD: "user/forgot-password",
    GET_USER_BY_GUID: "user/search?userguid=:guid&tenantGroupId=:tenantGroupId",
    GET_USER_OBJECT: "user/abridged",
    LOGIN_ATTEMPT: "user/login-attempt",
    RECAPTCHA_SCORE: "user/captcha-score?greToken=:greToken&portal=:portal",
    VALIDATE_LGCT_EXPIRY: "user/validate-lgct-token?token=:token",
    SAVE_USER_ADDRESS: "user/address",
    IP_2_CONNECT: "user/ip2connect?ipaddress=:ipaddress",
  },
  docBuilderService: {
    API_V1: "/api/v1",
    CREATE_DOCUMENT: "doc",
    GET_DOCUMENT: "doc/:docId",
    DUPLICATE_DOCUMENT: "doc/:docId/duplicate",
    DELETE_DOCUMENT: "doc/:docId",
    UPDATE_DOC_STYLE: "doc/:docId/docstyle",
    DELETE_DOC_STYLE: "doc/:docId/paragraph/:paraId",
    UPDATE_PARAGRAPH_ORDERING: "doc/:docId/para-reorder",
    CHOOSE_TEMPLATE: "doc/:docId/choose-template",
    UPDATE_PARAGRAPH: "doc/:docId/paragraph/:paraId",
    RENAME_PARAGRAPH: "doc/:docId/paragraph/:paraId/rename",
    // DOCUMENT_DOWNLOAD:
    //   "doc/:docId/download?downloadDocType=:downloadDocType&docConversionMethod=:docConversionMethod",
    DOCUMENT_DOWNLOAD:
      "doc/:docId/download?downloadDocType=:downloadDocType&docConversionMethod=:docConversionMethod&version=:version&userId=:userId&regenerateDoc=:regenerateDoc",
    //CONVERSION_STATUS: "doc/:conversionId/download-status",
    CONVERSION_STATUS: "doc/:docId/download-statusv2/:conversionId",
    RENAME_DOCUMENT: "doc/:docId/doc-rename",
    GENERATE_PDF_PATH: "doc/:docId/genereate-pdf-path?userId=:userId",
    DOC_PARSE: "doc",
    UPLOAD_IMAGE: "doc/:docId/image",
    GET_ALLDOCUMENT_OF_USER: "doc/user/:userGuid",
    GET_ABRIDGED_DATA: "doc/abridged?userGuid=:userGuid&typeofdoc=:typeofdoc",
    GET_USER_DOCUMENTS_ATTRIBUTES: "doc/attributes?userGuid=:userGuid&typeofdoc=:typeofdoc",
  },
  eventService: {
    API_V1: "/api/v1",
    CREATE_EVENT: "/api/v1/event",
  },
  authService: {
    API_V1: "/api/v1",
    USER_AUTH: "v1/auth/user/generate-token-userguid",
    LOGIN_USER_AUTH: "v1/auth/user/generate-token",
    UNAUTH_USER: "v1/auth/service/generate-token",
  },
  metaContentService: {
    API_V1: "/api/v1",
    GET_INDUSTRY: "docmetacontent/industry",
    WRITING_CONTENT: "docmetacontent/MetaContent",
    WRITING_CONTENT_BY_INDUSTRY: "docmetacontent/MetaContentByIndustry",
  },
  paymentService: {
    API_V1: "/api/v1",
    GET_PLANS: "payment-process/plans",
    PAYMENT_PROCESS: "payment-process",
    GET_SKU_DETAILS: "payment-process/sku",
    BAD_BINS: "payment-process/badbin",
    PAYMENT_INTENT: "payment-process/payment-intent",
    GET_GUSETUSER_PRICING:
      "payment-process/calculate/pricing?skuguid=:skuguid&currency=:currency&skucategory=:skucategory&users=:users",
    TAX_MATRIX: "payment-process/taxmatrix?statecode=:stateCode&zipcode=:zipCode&amount=:planAmount",
  },
  orderService: {
    API_V1: "/api/v1",
    GET_USER_ORDERS: "Order/GetUserOrders",
  },
  orchestrationService: {
    API_V1: "/api/v1",
    CHECKOUT: "checkout",
    BILLING_INFO: ":userGuid/billing/:localeVal",
    CANCEL_USER: "user/:userGuid/cancel",
    RESTART_SUB: "reactivate/user/:userGuid/order",
    SET_CONFIRMATION: "user/:userGuid/confirmation",
    GET_USER_PLANS: "plans/:userGuid",
    CHECKOUT_3DS: "checkout/3ds",
    GATEWAY_ABTEST: "gateway?cardtoken=:cardtoken&currency=:currency&portal=:portal",
    BAD_BINS: "gateway?cardtoken=:token&currency=:currency&portal=:portal&CountryCd=:CountryCd",
  },
  ssoLogin: {
    partnerLogin: "partner-login?legacyTicket=:legacyTicketCookie",
    partnerLogOut: "partner-logout",
  },
  dashboardOrchestrationService: {
    BILLING_INFO: "/api/v1/dashboard/billing/account/:userGuid/:locale",
    UPLOAD_DOCUMENT: "/api/v1/dashboard/upload-documents",
  },
  abtestService: {
    USER_CONDUCT: "/user/conduct",
    VISITOR_CONDUCT: "/visitor/conduct",
  },
};
const getBaseUrl = (baseUrl) => {
  return (endPoint) => {
    return `${baseUrl}${endPoint}`;
  };
};
const getBaseUrlV2 = (baseUrl) => {
  return `${baseUrl}`;
};
export const getUserServiceEndPoint = getBaseUrl(BASE_URL_USER_SERVICE);
export const getDocBuilderServicendPoint = getBaseUrl(BASE_URL_DOCBUILDER_SERVICE);
export const getDocParserServicendPoint = getBaseUrl(BASE_URL_DOCPARSER_SERVICE);

export const getEventServiceEndPoint = getBaseUrl(BASE_URL_EVENT_SERVICE);
export const getAuthServiceEndPoint = getBaseUrl(BASE_URL_AUTH_SERVICE);
export const getMetaContentServiceEndPoint = getBaseUrl(BASE_URL_META_CONTENT_SERVICE);

export const getVisitorServiceUrl = getBaseUrl(VISITOR_SERVICE_URL);
export const getPaymentServiceEndPoint = getBaseUrl(BASE_URL_PAYMENT_SERVICE);
export const ERROR_CODES = {
  UNAUTHORISED: 401,
};
export const getOrderServiceEndPoint = getBaseUrl(BASE_URL_ORDER_SERVICE);
export const getOrchestrationServiceEndPoint = getBaseUrl(BASE_URL_ORCHESTRATION_SERVICE);
export const getSsoPdfsimpliEndPoint = getBaseUrl(BASE_URL_SSO_PARTNER_LOGIN_PDFSIMPLI);
export const getSsoSignsimpliEndPoint = getBaseUrl(BASE_URL_SSO_PARTNER_LOGIN_SIGNSIMPLI);
export const getSsoResumeBuildEndPoint = getBaseUrlV2(BASE_URL_SSO_DASHBOARD);

export const getDashboardOrchestrationServiceEndPoint = getBaseUrl(BASE_DASHBOARD_ORCHESTRATION);

export const getSignSimpliBaseURL = getBaseUrlV2(BASE_URL_SIGNSIMPI);
export const getPdfSimpliBaseURL = getBaseUrlV2(BASE_URL_PDFSIMPI);

export const getTrustPilotServiceEndPoint = getBaseUrlV2(BASE_URL_TRUSTPILOT);
export const getABTestEndPoint = getBaseUrl(BASE_URL_ABTEST);
export const getLegalSimpliBaseURL = getBaseUrlV2(BASE_URL_LEGALSIMPLI);
