import { createTypes } from "reduxsauce";

export default createTypes(
  `
    QUACK
`,
  {
    prefix: "plans/",
  }
);
