import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./personal-info.scss";
import Button from "../../components/shared/button";
import appLogger from "../../utils/app-logger";
import InputText from "../../components/shared/input-text";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import RBDropDown from "../../components/shared/dropdown";
import { createParagraph, emptyDocument } from "../../initialState";
import ReactLoader from "../../components/shared/loader";
import { getTenantId } from "../../utils/general-config";
import moment from "moment";
import axios from "axios";
import { APP_NAVIGATION, SECTION_CODE, SECTION_ORDER, SECTION_TYPE, SIGNATURE_TYPE } from "../../utils/constants";
import { checkEmpty, documentName, getPortalCD, readCookie } from "../../utils/helpers";
import { Helmet } from "react-helmet";
import { getColor, getFonts, getListOfStates } from "../../services/master-data";
import ScrollToTop from "../../components/scroll-to-top";
import { Col, Row } from "react-bootstrap";
import PathHelper from "../../utils/path-helper";
import { validateEmail, ValidateNumber } from "../../validators/genericValidator";
import Sidebar from "../../components/cover-letter-sidebar";
import CoverLetterPreview from "../../components/cover-letter-preview";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CoverLetterPersonalInformation = ({
  savePersonalInfoConnect,
  updatePersonalInfoConnect,
  funnelStep,
  SaveDocGUIDConnect,
  personalInfo,
  setNameConnect,
  updateProgress,
  previewTemplateAction,
  cvltrTemplatePreview,
  handleSteps,
  saveSignatureConnect,
}) => {
  const { locale } = useParams();
  const location = useLocation();

  const { templateCd, docGUID, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep || {};

  let pathRedirect = PathHelper(APP_NAVIGATION.PRSNLINFO, funnelStepsComplete, true);

  let visitorGuid = readCookie("visitorGuid");
  let countries = require("i18n-iso-mycountries");
  const queryParams = new URLSearchParams(window.location.search);
  const qParams = new URLSearchParams();
  for (const [name, value] of queryParams) {
    qParams.append(name.toLowerCase(), value);
  }
  countries.registerLocale(require("i18n-iso-mycountries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/fr.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/es.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/it.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/ar.json"));

  const previewContent =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField ? cvltrTemplatePreview.templateField : false;

  const previewFullName = !!previewContent && !checkEmpty(previewContent.fullName) ? previewContent.fullName : "";

  const previewCity = !!previewContent && !checkEmpty(previewContent.city) ? previewContent.city : "";

  const previewST = !!previewContent && !checkEmpty(previewContent.state) ? previewContent.state : "";

  const previewZipCode = !!previewContent && !checkEmpty(previewContent.zipCode) ? previewContent.zipCode : "";

  const previewEmail = !!previewContent && !checkEmpty(previewContent.email) ? previewContent.email : "";

  const previewStreetAddress =
    !!previewContent && !checkEmpty(previewContent.streetAddress) ? previewContent.streetAddress : "";

  const previewPhone = !!previewContent && !checkEmpty(previewContent.phone) ? previewContent.phone : "";

  const content =
    !!personalInfo &&
    !!personalInfo.paragraph &&
    !!personalInfo.paragraph.paraContents &&
    !!personalInfo.paragraph.paraContents[0].content
      ? JSON.parse(personalInfo.paragraph.paraContents[0].content)
      : {};
  const [countryOption, setCountryOption] = useState([
    !!content && content.country !== undefined ? [content.country] : [],
  ]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  let allState = [{ value: "", label: "Select State" }, ...getListOfStates().data];
  const [listOfState, setListOfState] = useState(allState);
  const [fullName, setFullName] = useState(
    previewFullName !== "" ? previewFullName : !!content && !checkEmpty(content.fullName) ? content.fullName : ""
  );
  const [city, setCity] = useState(
    previewCity !== "" ? previewCity : !!content && !checkEmpty(content.city) ? content.city : ""
  );
  const [state, setState] = useState(
    previewST !== "" ? previewST : !!content && !checkEmpty(content.state) ? content.state : ""
  );

  const [country, setCountry] = useState(!!content && !checkEmpty(content.country) ? content.country : "");
  const [zipCode, setZipCode] = useState(
    previewZipCode !== "" ? previewZipCode : !!content && !checkEmpty(content.zipCode) ? content.zipCode : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    previewEmail !== "" ? previewEmail : !!content && !checkEmpty(content.emailAddress) ? content.emailAddress : ""
  );

  const [streetAddress, setStreetAddress] = useState(
    previewStreetAddress !== ""
      ? previewStreetAddress
      : !!content && !checkEmpty(content.streetAddress)
      ? content.streetAddress
      : ""
  );

  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [showStickyBar, setShowStickyBar] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [isNumberValid, setIsNumber] = useState(false);
  const prevParaName = {
    state: {
      prevParaName: t("personal_info.para_name"),
    },
  };
  const [phone, setPhone] = useState(
    previewPhone !== "" ? previewPhone : !!content && !checkEmpty(content.phone) ? content.phone : ""
  );

  useEffect(() => {
    trackRBEvents("cvPersonalInfoView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    if (!funnelStepsComplete) {
      handleSteps({
        ...cvltrTemplatePreview.steps,
        personalInfo: true,
        signature: false,
      });
    }
  }, []);

  const sanitizer = dompurify.sanitize;
  const fontFamily = getFonts();
  const fontColor = getColor();
  const onFullNameChange = (e) => {
    const {
      target: { value },
    } = e;

    let result = /^[a-zA-Z ]+$/.test(value);

    if (result) {
      setFullName(value);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        fullName: value.trim(),
      });
      if (!funnelStepsComplete) {
        let signatureObject = {
          font: fontFamily[0].fontName,
          color: fontColor[0].color,
          label: t("personal_info.para_name"),
          name: value,
        };
        saveSignatureConnect({
          key: t("signature.type"),
          tabValue: signatureObject,
        });
      }
    } else {
      if (value == "") {
        setFullName("");
        previewTemplateAction({
          ...cvltrTemplatePreview.templateField,
          fullName: "",
        });
        if (!funnelStepsComplete) {
          let signatureObject = {
            font: fontFamily[0].fontName,
            color: fontColor[0].color,
            label: "",
            name: "",
          };
          saveSignatureConnect({
            key: t("signature.type"),
            tabValue: signatureObject,
          });
        }
      }
    }
  };

  const onemailAddressChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmailAddress(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      emailAddress: value,
    });
  };

  const oneStreetAddressChange = (e) => {
    const {
      target: { value },
    } = e;
    setStreetAddress(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      streetAddress: value,
    });
  };

  const isNumberKey = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (charCode != 46 && charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      setIsNumber(false);
    } else {
      setIsNumber(true);
    }
  };

  const onPhoneChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value == "") {
      setPhone("");
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        phone: "",
      });
    }
    if (isNumberValid) {
      setPhone(value);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        phone: value,
      });
    }
  };
  const onCityChange = (e) => {
    const {
      target: { value },
    } = e;

    let result = /^[a-zA-Z ]+$/.test(value);

    if (result) {
      setCity(value);
      previewTemplateAction({
        ...cvltrTemplatePreview.templateField,
        city: value,
      });
    } else {
      if (value == "") {
        setCity("");
        previewTemplateAction({
          ...cvltrTemplatePreview.templateField,
          city: "",
        });
      }
    }
  };
  const onStateChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setState(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      state: value,
    });
  };
  const onZipCodeChange = (e) => {
    const {
      target: { value },
    } = e;
    setZipCode(value);
    previewTemplateAction({
      ...cvltrTemplatePreview.templateField,
      zipCode: value,
    });
  };
  const countryCode = async () => {
    const res = await axios.get("https://pro.ip-api.com/json?key=qGpUO64dv9znGra&fields=countryCode");
    const countryCode = res.data["countryCode"];
    setCountry(countryCode);
  };
  useEffect(() => {
    updateProgress(10);
    async function fetcCounty() {
      const response = await countries.getNames(!!locale ? locale : "en");
      if (!!response) {
        let countryOptions = Object.entries(response).map((item) => {
          return {
            label: item[1],
            value: item[0],
          };
        });
        countryOptions.splice(0, 0, {
          label: t("personal_info.select"),
          value: "",
        });
        setCountryOption(countryOptions.sort((a, b) => a.label.localeCompare(b.label)));
      }
    }
    fetcCounty();
    if (!!content.country == false) {
      countryCode();
    }
    if (qParams.has("zip")) {
      setZipCode(qParams.get("zip"));
    }
    if (qParams.has("city")) {
      setCity(qParams.get("city"));
    }
    if (qParams.has("email")) {
      setEmailAddress(qParams.get("email"));
    }
    if (qParams.has("prtlsrc")) {
      localStorage.setItem("qsprtlsrc", qParams.get("prtlsrc")); //qsprtlsrc = Querystring Portal source
    }
  }, []);

  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const buttonClickHandler = () => {
    if (isEmailValid) {
      setShowLoader("d-block");
      !!content && content.fullName != undefined && content.fullName.trim != ""
        ? updatePersonalInfoClickHandler()
        : savePersonalInfoClickHandler();
    }
  };

  const updatePersonalInfoClickHandler = () => {
    setNameConnect(fullName);
    let head = {
      fullName: fullName,
      emailAddress: emailAddress,
      phone: phone,
      city: city,
      state: state,
      zipCode: zipCode,
      streetAddress: streetAddress,
    };
    let personalInfoOrder = SECTION_ORDER.HEAD;
    let data = createParagraph(
      docGUID,
      personalInfo.paragraph.id,
      personalInfo.paragraph.paraContents[0].id,
      JSON.stringify(head),
      personalInfoOrder,
      SECTION_TYPE.HEAD,
      SECTION_CODE.HEAD,
      t("personal_info.para_name")
    );
    updatePersonalInfoConnect(docGUID, personalInfo.paragraph.id, data)
      .then((response) => {
        setShowLoader("d-none");
        navigate(pathRedirect.next + location.search, prevParaName);
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };

  const savePersonalInfoClickHandler = () => {
    appLogger.log("savePersonalInfoClickHandler");
    setNameConnect(fullName);
    let head = {
      fullName: fullName,
      emailAddress: emailAddress,
      phone: phone,
      city: city,
      state: state,
      zipCode: zipCode,
      streetAddress: streetAddress,
    };
    let data = emptyDocument(
      documentName(fullName),
      JSON.stringify({}),
      templateCd,
      userGUID,
      getTenantId(),
      visitorGuid,
      funnelStep?.queryStringsInfo?.fp ? funnelStep?.queryStringsInfo?.fp : null
    );

    //Note: For cover letter document, we are passing typeOfDoc = 20
    data.typeOfDoc = 20;

    savePersonalInfoConnect(data, JSON.stringify(head), t("personal_info.para_name"))
      .then((response) => {
        SaveDocGUIDConnect(response.docGuid);
        setShowLoader("d-none");
        navigate(pathRedirect.next + location.search, prevParaName);
      })
      .catch((err) => {
        const { detail } = err;
        appLogger.log(detail); // TODO
      });
  };

  const onFocusCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(false);
    }
  };
  const onBlurCallback = (e) => {
    let emailValidator = validateEmail(emailAddress);
    // if (emailAddress !== "") {
    //   if (!emailValidator.isValid) {
    //     setIsEmailValid(false);
    //     setEmailErrorMsg(emailValidator.errorMessage);
    //   } else {
    //     setIsEmailValid(true);
    //     setEmailErrorMsg("");
    //   }
    // } else {
    //   setIsEmailValid(true);
    //   setEmailErrorMsg("");
    // }
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  };
  window.addEventListener("scroll", (event) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  });

  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={0} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <Helmet>
        <title>{t("page_title.personal_info")}</title>
      </Helmet>
      <ReactLoader showloader={showLoader} />
      <ScrollToTop />
      <section
        className={classNames(
          funnelStepsComplete ? "" : "sidebar-sticky sidebar-sticky-cvrltr",
          "section main-content templates"
        )}
      >
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <hgroup>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("personal_info.form_header_title"),
                  }}
                />
                <h3
                  className="cvltr-black-color"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("personal_info.form_header_subtitle")),
                  }}
                />
                {/* <h4>{t("personal_info.form_header_description")}</h4> */}
              </hgroup>
            </div>

            <div className="row iPad-size">
              <div className="col-md-12 col-lg-9">
                <div className="row">
                  <div className="form-group string optional heading_name">
                    <label className="string optional" htmlFor="heading_email">
                      Full name
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        className="string optional form-control"
                        placeholder={t("personal_info.full_name_placeholder")}
                        type="text"
                        name="heading[full_name]"
                        id="heading_name"
                        required
                        maxLength={50}
                        value={fullName}
                        onChange={onFullNameChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 form-group email optional heading_email">
                    <label className="email optional" htmlFor="heading_email">
                      {t("personal_info.email_address")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string email optional form-control"
                        placeholder={t("personal_info.enter_email_placeholder")}
                        name="heading[email]"
                        id="email"
                        maxLength={50}
                        onChange={onemailAddressChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={emailAddress}
                      />
                      <div className="tooltip">
                        <a className="fa fa-question-circle tooltip-trigger"></a>
                        <div className="tooltip__content">{t("personal_info.email_address_tooltip")}</div>
                      </div>
                    </div>
                    <div className="form-text">
                      {emailErrorMsg !== "" && (
                        <small key="pswrdErr" className="form-text text-danger">
                          {emailErrorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-12 form-group tel optional heading_phone">
                    <label className="tel optional" htmlFor="heading_phone">
                      {t("personal_info.phone_number")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        className="string tel optional form-control"
                        placeholder={t("personal_info.phone_placeholder")}
                        type="tel"
                        maxLength={15}
                        name="heading[phone]"
                        id="heading_phone"
                        value={phone}
                        onChange={onPhoneChange}
                        onKeyPress={isNumberKey}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                      />
                    </div>
                    <div className="form-text">
                      {phoneError && (
                        <small key="pswrdErr" className="form-text text-danger">
                          {phoneErrorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group optional">
                  <label className="email optional" htmlFor="heading_email">
                    {t("personal_info.street_address")}
                  </label>
                  <div className="tooltip-wrapper">
                    <InputText
                      data-validate=""
                      classname="string optional form-control"
                      placeholder={t("personal_info.enter_street_placeholder")}
                      type="text"
                      name="heading[street]"
                      id="street_adderess"
                      onChange={oneStreetAddressChange}
                      onFocusCallback={onFocusCallback}
                      onBlurCallback={onBlurCallback}
                      value={streetAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={classNames(
                      country === "US" ? "col-sm-3 " : "col-sm-6 ",
                      "form-group string optional heading_city"
                    )}
                  >
                    <label className="string optional" htmlFor="heading_city">
                      {t("personal_info.city")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string optional form-control"
                        placeholder={t("personal_info.enter_city_placeholder")}
                        type="text"
                        maxLength={50}
                        name="heading[city]"
                        id="heading_city"
                        onChange={onCityChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={city}
                      />
                    </div>
                  </div>
                  <div className="form-group state optional heading_state col-sm-3">
                    <label className="state optional" htmlFor="heading_state">
                      {t("personal_info.state")}
                    </label>
                    <div className="tooltip-wrapper">
                      <RBDropDown
                        index={0}
                        className="state optional form-control"
                        name="heading[state]"
                        id="heading_state"
                        value={state}
                        options={listOfState}
                        selectedValue={state || ""}
                        onChange={onStateChange}
                      />
                    </div>
                  </div>
                  <div className="form-group string optional heading_zipcode col-sm-3">
                    <label className="string optional" htmlFor="heading_zipcode">
                      {t("personal_info.zip_code")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string optional form-control"
                        placeholder={t("personal_info.enter_zip_code_placeholder")}
                        type="text"
                        name="heading[zipcode]"
                        id="heading_zipcode"
                        onChange={onZipCodeChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={zipCode}
                        maxLength={10}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-container fbn sticky-bar btn-justify cvltr-footer-btn">
                  <Button
                    isLoading={false}
                    classNames="btn btn-outline-secondary btn-lg cvltr-btn-blue"
                    ctaText={t("personal_info.back_action")}
                    onClick={backClickHandler}
                  />

                  <Button
                    isLoading={false}
                    disabled={false}
                    classNames="btn btn-primary btn-lg submit-on-enter float-end"
                    ctaText={t("universal.next_action")}
                    onClick={buttonClickHandler}
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <CoverLetterPreview />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterPersonalInformation;
