import React, { useEffect, useState, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { validateTextInput } from "../../validators/genericValidator";
import RBDropDown from "../../components/shared/dropdown/component";
import InputText from "../../components/shared/input-text/component";
import { getYearList } from "../../services/master-data";
const ExperienceFields = ({
  callback,
  isValid,
  errors,
  experience,
  experienceFieldsData,
  updateCall,
  setShowStickyBar,
}) => {
  const { t } = useTranslation();
  let exprProps = [];
  const [emplayerErrorMessage, setEmployerErrorMessage] = useState("");
  const [jobTitleErrorMessage, setJobTitleErrorMessage] = useState("");
  const [startDateErrorMessage, setStartDateErrorMessage] = useState("");
  const [endDateErrorMessage, setEndDateErrorMessage] = useState("");

  const [employer, setEmployer] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [startDateMonth, setStartDateMonth] = useState("");
  const [startDateMonthLabel, setStartDateMonthLabel] = useState("");
  const [endDateMonth, setEndDateMonth] = useState("");
  const [startDateYear, setStartDateYear] = useState("");
  const [endDateMonthLabel, setEndDateMonthLabel] = useState("");
  const [endDateYear, setEndDateYear] = useState("");
  const [presentlyAttend, setPresentlyAttend] = useState(false);
  const [yearList, setYearList] = useState(getYearList());
  const [disableStartMonth, setDisableStartMonth] = useState(false);
  const [disableEndMonth, setDisableEndMonth] = useState(false);
  let monthList = t("months_name.data", { returnObjects: true });
  let jsonfield = {
    employer: employer,
    jobTitle: jobTitle,
    city: city,
    state: state,
    startDateMonth: startDateMonth,
    startDateMonthLabel: startDateYear !== "empty" ? startDateMonthLabel : "",
    startDateYear: startDateYear !== "empty" ? startDateYear : "",
    endDateMonth: endDateMonth,
    endDateMonthLabel: endDateYear !== "empty" ? endDateMonthLabel : "",
    endDateYear: endDateYear != "empty" ? endDateYear : "",
    presently_here: presentlyAttend,
    labelPresent: t("experience.label_Present"),
    content: "",
  };
  useEffect(() => {
    if (!!experienceFieldsData && !!experienceFieldsData.content) {
      SetFormData(experienceFieldsData);
    }
  }, [JSON.stringify(experienceFieldsData)]);
  const SetFormData = (para) => {
    setEmployer(JSON.parse(experienceFieldsData.content).employer);
    setJobTitle(JSON.parse(experienceFieldsData.content).jobTitle);
    setState(JSON.parse(experienceFieldsData.content).state);
    setCity(JSON.parse(experienceFieldsData.content).city);
    setStartDateMonth(JSON.parse(experienceFieldsData.content).startDateMonth);
    setStartDateYear(JSON.parse(experienceFieldsData.content).startDateYear);
    setEndDateMonth(JSON.parse(experienceFieldsData.content).endDateMonth);
    setEndDateYear(JSON.parse(experienceFieldsData.content).endDateYear);
    setEndDateMonthLabel(JSON.parse(experienceFieldsData.content).endDateMonthLabel);
    setStartDateMonthLabel(JSON.parse(experienceFieldsData.content).startDateMonthLabel);
    setPresentlyAttend(JSON.parse(experienceFieldsData.content).presently_here);
  };
  useEffect(() => {
    setEmployerErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "expr_employer")["errorMessage"] : ""
    );
    setJobTitleErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "expr_jobTitle")["errorMessage"] : ""
    );
    setStartDateErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "start_DateYear")["errorMessage"] : ""
    );
    setEndDateErrorMessage(
      !!errors && errors.length > 0 ? errors.find((x) => x.errorControl == "End_DateMonth")["errorMessage"] : ""
    );
    let keys = Object.keys(errors);
    if (keys.length > 0) {
      // window.scrollTo(0, 0);
      document.getElementById("exp-scroll-container").scrollIntoView();
    }
    if (!!experienceFieldsData && !!experienceFieldsData.content) {
      SetFormData(experienceFieldsData);
    }
  }, [errors]);
  const onEmployerChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmployer(value);
    setEmployerErrorMessage("");
    jsonfield.employer = value;
    callback(jsonfield);
  };
  const onJobTitleChange = (e) => {
    const {
      target: { value },
    } = e;
    setJobTitle(value);
    jsonfield.jobTitle = value;
    callback(jsonfield);
  };
  const onCityChange = (e) => {
    const {
      target: { value },
    } = e;
    setCity(value);
    jsonfield.city = value;
    callback(jsonfield);
  };
  const onStateChange = (e) => {
    const {
      target: { value },
    } = e;
    setState(value);
    jsonfield.state = value;
    callback(jsonfield);
  };
  const onStartDateMonthChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setStartDateMonth(value);
    let selectedmonth = e.target.selectedOptions[0].text.replace(/<(.|\n)*?>/g, "").substring(0, 3);
    setStartDateMonthLabel(selectedmonth);
    if (selectedmonth == "Mon") {
      selectedmonth = "";
    }
    if (jsonfield.startDateYear == "empty") {
      jsonfield.startDateYear = "";
    }
    if (jsonfield.endDateYear == "empty") {
      jsonfield.endDateYear = "";
    }
    if (value == "empty") {
      jsonfield.startDateMonth = "";
      jsonfield.startDateMonthLabel = "";
      callback(jsonfield);
    } else {
      jsonfield.startDateMonth = value;
      jsonfield.startDateMonthLabel = selectedmonth;
      callback(jsonfield);
    }
  };
  const onStartDateYearChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setStartDateYear(value);
    if (value == "empty") {
      jsonfield.startDateYear = "";
      setStartDateMonth("");
      jsonfield.startDateMonth = "";
      jsonfield.startDateMonthLabel = "";
      jsonfield.startDateYear = "";
      if (jsonfield.endDateYear == "empty") {
        jsonfield.endDateYear = "";
      }
      setDisableStartMonth(true);
      callback(jsonfield);
    } else {
      setDisableStartMonth(false);
      jsonfield.startDateYear = value;
      callback(jsonfield);
    }
  };
  const onEndMonthChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setEndDateMonth(value);
    let selectedEndMonth = e.target.selectedOptions[0].text.replace(/<(.|\n)*?>/g, "").substring(0, 3);
    setEndDateMonthLabel(selectedEndMonth);
    if (selectedEndMonth == "Mon") {
      selectedEndMonth = "";
    }
    if (jsonfield.startDateYear == "empty") {
      jsonfield.startDateYear = "";
    }
    if (jsonfield.endDateYear == "empty") {
      jsonfield.endDateYear = "";
    }
    if (value == "empty") {
      jsonfield.endDateMonth = "";
      jsonfield.endDateMonthLabel = "";
      callback(jsonfield);
    } else {
      jsonfield.endDateMonth = value;
      jsonfield.endDateMonthLabel = selectedEndMonth;
      callback(jsonfield);
    }
  };
  const onEndYearChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setEndDateYear(value);
    if (value == "empty") {
      jsonfield.endDateMonth = "";
      setEndDateMonth("");
      jsonfield.endDateMonth = "";
      jsonfield.endDateMonthLabel = "";
      jsonfield.endDateYear = "";
      if (jsonfield.startDateYear == "empty") {
        jsonfield.startDateYear = "";
      }
      setDisableEndMonth(true);
      callback(jsonfield);
    } else {
      setDisableEndMonth(false);
      jsonfield.endDateYear = value;
      callback(jsonfield);
    }
  };
  const handlePresentlyAttendCheck = (e) => {
    const {
      target: { checked },
    } = e;
    setPresentlyAttend(checked);
    jsonfield.presently_here = checked;
    callback(jsonfield);
  };
  const onFocusCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(false);
    }
  };
  const onBlurCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  };
  window.addEventListener("scroll", (event) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  });
  return (
    <>
      <div className="row" id="exp-scroll-container">
        <div
          className={
            "form-group string optional expr_employer col-sm-12 col-md-6 " +
            (emplayerErrorMessage != "" ? "warning" : "")
          }
        >
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="expr_employer"
              name="expr_employer"
              text={t("experience.first_name")}
              type="text"
              maxLength={50}
              value={employer}
              onChange={onEmployerChange}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("experience.first_name_placeholder")}
            />
          </div>
          <div className="form-text">
            <small key="pswrdErr" className="form-text text-danger">
              {emplayerErrorMessage}
            </small>
          </div>
        </div>
        <div
          className={
            "form-group string optional expr_jobTitle col-sm-12 col-md-6 " +
            (jobTitleErrorMessage != "" ? "warning" : "")
          }
        >
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="expr_jobTitle"
              name="expr_jobTitle"
              maxLength={50}
              text={t("experience.job_title")}
              type="text"
              value={jobTitle}
              onChange={onJobTitleChange}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("experience.job_title_placeholder")}
            />
            <div className="form-text">
              <small key="pswrdErr" className="form-text text-danger">
                {jobTitleErrorMessage}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group string optional heading_city col-sm-12 col-md-6 ">
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="heading_city"
              maxLength={20}
              name="heading_city"
              text={t("experience.city")}
              type="text"
              value={city}
              onChange={onCityChange}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("experience.city_placeholder")}
            />
          </div>
        </div>
        <div className="form-group string optional heading_state col-sm-12 col-md-6 ">
          <div className="tooltip-wrapper">
            <InputText
              showLabel={true}
              data-validate=""
              id="heading_state"
              name="heading_state"
              text={t("experience.state")}
              type="text"
              maxLength={20}
              value={state}
              onChange={onStateChange}
              onFocusCallback={onFocusCallback}
              onBlurCallback={onBlurCallback}
              placeholder={t("experience.state_placeholder")}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="string optional" htmlFor="experience_start_date">
            {t("experience.start_date")}
          </label>
          <div className="row date-selector">
            <div className="form-group select optional experience_start_month col select-with-placeholder month-selector">
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={startDateMonth || ""}
                  name="experience[start_month]"
                  id="experience_start_month"
                  options={monthList}
                  onChange={onStartDateMonthChange}
                  disabled={disableStartMonth === true ? true : false}
                />
              </div>
            </div>
            <div
              className={
                "form-group select optional experience_start_year col select-with-placeholder year-selector " +
                (startDateErrorMessage != "" ? "warning" : "")
              }
            >
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={startDateYear || ""}
                  name="experience[start_year]"
                  id="experience_start_year"
                  yearText={t("experience.year")}
                  options={yearList.data}
                  onChange={onStartDateYearChange}
                />
              </div>
              <div className="form-text error-message-date">
                <small key="pswrdErr" className="form-text text-danger">
                  {startDateErrorMessage}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="string optional" htmlFor="experience_end_date">
            {t("experience.end_date")}
          </label>
          <div className="row date-selector">
            <div className="form-group select optional experience_end_month col select-with-placeholder month-selector">
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={endDateMonth || ""}
                  name="experience[end_month]"
                  id="experience_end_month"
                  options={monthList}
                  presentlyAttend={presentlyAttend}
                  onChange={onEndMonthChange}
                  disabled={presentlyAttend === true || disableEndMonth === true ? true : false}
                />
              </div>
            </div>
            <div
              className={
                "form-group select optional experience_end_year col select-with-placeholder year-selector " +
                (endDateErrorMessage != "" ? "warning" : "")
              }
            >
              <div className="tooltip-wrapper">
                <RBDropDown
                  selectedValue={endDateYear || ""}
                  name="experience[end_year]"
                  id="experience_end_year"
                  yearText={t("experience.year")}
                  options={yearList.data}
                  presentlyAttend={presentlyAttend}
                  onChange={onEndYearChange}
                  disabled={presentlyAttend === true ? true : false}
                />
              </div>
              <div className="form-text">
                <small key="pswrdErr" className="form-text text-danger">
                  {endDateErrorMessage}
                </small>
              </div>
            </div>
          </div>

          <div className="boolean optional experience_work_here builder-form__extra-field">
            <input name="experience[work_here]" type="hidden" value="0" />
            <input
              data-on-check-disable="#experience_end_year, #experience_end_month"
              className="boolean optional"
              type="checkbox"
              value={presentlyAttend}
              {...(presentlyAttend === true && { checked: "checked" })}
              name="experience[work_here]"
              id="experience_work_here"
              onChange={handlePresentlyAttendCheck}
            />
            {/* <InputCheckbox
              className="boolean optional"
              type="checkbox"
              value={presentlyAttend}
              presentlyAttend={presentlyAttend}
              id="education_presently_attend_here"
              name="experience[work_here]"
              callback={handlePresentlyAttendCheck}
            /> */}
            <label className="boolean optional" id="presently_work_here" htmlFor="experience_work_here">
              {t("experience.presently_work_here")}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceFields;
