import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import InputRadio from "../input-radio";
import InputText from "../input-text";
import Button from "../button";
import "./custom-section.scss";
import { useNavigate } from "react-router-dom";
import { APP_NAVIGATION } from "../../../utils/constants";
import PathHelper from "../../../utils/path-helper";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const RenameSectionModal = ({
  show,
  toggleShow,
  header,
  callback,
  setRenameVal,
  renameDocGuid,
  renameVal,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [characterLimitError, setCharacterLimitError] = useState(false);
  const paraNameChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.length <= 60) {
      setRenameVal(value);
      setCharacterLimitError(false)
    } else {
      setRenameVal(value.slice(0, 60));
      setCharacterLimitError(true)
    }
  };
  // const buttonClickHandler = () => {
  //   callback(renameVal, renameDocGuid);
  //   setRenameVal("");
  // };
  const buttonClickHandler = () => {
    const trimmedRenameVal = renameVal.trim();
    callback(trimmedRenameVal, renameDocGuid);
    setRenameVal("");
  };
  const restrictSpecialCharacters = (e) => {
    const { value } = e.target;
    const re = /[^0-9a-zA-Z _/-]/g;
    if (re.test(value)) {
      setError(true);
      e.target.value = value.replace(re, '');
      setRenameVal(e.target.value);
    } else {
      setError(false);
    }
  };
  useEffect(() => {
    setError(false);
    setCharacterLimitError(false)
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={toggleShow}
        className="modal-rename-section"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div>
            <div className="form-group">
              <InputText
                showLabel={true}
                type="text"
                name="other_name"
                text={t("review.popup_label")}
                placeholder={renameVal}
                id="other-name"
                className="string optional form-control"
                value={renameVal}
                onChange={paraNameChange}
                onInput={restrictSpecialCharacters}
              />
              <p
                className={classNames(
                  (characterLimitError || error) ? "visible" : "invisible",
                  "text-danger text-center my-3"
                )}
              >
                {characterLimitError
                  ? t("rename_popup.character_limit_error")
                  : error
                    ? t("rename_popup.errorMessage")
                    : "\u00A0"}
              </p>
              <p
                className={classNames(
                  error ? "visible" : "invisible",
                  "text-danger text-center mb-0"
                )}
              >
                <span className="fs-2">/, -, _</span>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-4">
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-outline-secondary section-modal"
            ctaText={t("review.cancel")}
            onClick={toggleShow}
          />
          <Button
            isLoading={false}
            disabled={false}
            classNames="btn btn-primary section-modal"
            ctaText={t("review.change")}
            onClick={buttonClickHandler}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenameSectionModal;
