import React from "react";
import Button from "../../shared/button";
import { useNavigate, link } from "react-router-dom";
import PathHelper from "../../../utils/path-helper";
import { APP_NAVIGATION } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const ResumePersonalInfo = ({ data }) => {
  const { t } = useTranslation();
  const { fname, lname, phone, email, address, city, zipcode, country } =
    JSON.parse(data.content);
  return (
    <>
      <h1
        className="info-headline"
        style={{
          fontSize: data.headingfontsize,
          fontFamily: data[0].fontFamily,
        }}
      >
        {fname} {lname}
      </h1>
      <div className="info-sub-headline">
        <h2>{t("personal_info.para_name")}</h2>
      </div>
      <div className="info-main">
        <div className="info-item">
          <span className="info-label">Phone:</span>
          <span className="info-text">{phone}</span>
        </div>

        <div className="info-item">
          <span className="info-label">Email:</span>
          <span className="info-text">{email}</span>
        </div>

        <div className="info-item">
          <span className="info-label">Address:</span>
          <span className="info-text">
            {address} {city} {zipcode} {country}
            {/* Temp Apartments, Floor 19, Blue Street, San Franciso, 94016 (US) */}
          </span>
        </div>
      </div>
    </>
  );
};

const TempPersonalInfo = ({ data }) => {
  let pathRedirect = PathHelper(APP_NAVIGATION.TPER, false);
  const { t } = useTranslation();
  // Nav
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathRedirect.next);
  };
  return (
    <div className="resume__section resume__heading resume__contact">
      <div className="resume__controls">
        <div className="controls-menu">
          <Button
            isLoading={false}
            disabled={false}
            onClick={editHandler}
            icon={
              <>
                <span className="fa fa-pencil" aria-hidden="true"></span>
                <span className="resume__control-text">
                  {" "}
                  {t("review.edit")}
                </span>
              </>
            }
            classNames="btn-green resume__control-button"
            ctaText=""
          />
        </div>
      </div>
      <div className="resume__content">
        {data.map((item, index) => (
          <ResumePersonalInfo data={item} key={"temHeader" + index} />
        ))}
      </div>
    </div>
  );
};

export default TempPersonalInfo;
