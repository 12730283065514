import React, { useEffect, useState } from "react";
import "./loader.scss";

const ReactLoader = (data) => {
  return (
    <>
      <div className={"react-loader " + data.showloader}>
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default ReactLoader;
