const DEBUG = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";

const appLogger = {
    log(message, data) {
        if (DEBUG) {
            if (typeof data == "undefined") {
                console.log(new Date().toISOString() + " :: " + message);
            }
            else {
                console.log(new Date().toISOString() + " :: " + message + ", Data :: " + JSON.stringify(data));
            }
        }
    }
}
export default appLogger;