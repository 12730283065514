import { ROUTE_CONSTANT } from "../routing/route-constants";
import { useParams } from "react-router-dom";
import { APP_NAVIGATION, FOOTER_LINKS } from "./constants";
import { availableLanguages } from "../i18nextInit";
import { readCookie } from "../utils/helpers";
import { useTranslation } from "react-i18next";
import { IsSingleLoginAPPEnable } from "./general-config";

export const PathHelper = (sectionName, isfunnelStepsComplete, isCoverLetterSection, isReviewSection) => {
  const { t } = useTranslation();
  let { locale } = useParams();
  if (locale == undefined && localStorage.getItem("i18nextLng") != "en") {
    locale = localStorage.getItem("i18nextLng");
  }
  const queryParams = new URLSearchParams(window.location.search);
  const qParams = new URLSearchParams();
  for (const [name, value] of queryParams) {
    qParams.append(name.toLowerCase(), value.toLowerCase());
  }
  let prtl = "";
  if (qParams.has("prtlsrc")) {
    prtl = qParams.get("prtlsrc");
    if (prtl.length === 6) {
      prtl = prtl.slice(-2);
    }
  }

  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    if (prtl.indexOf("es") > -1) {
      verifiedLocale = "es";
    } else if (prtl.indexOf("fr") > -1) {
      verifiedLocale = "fr";
    } else if (prtl.indexOf("it") > -1) {
      verifiedLocale = "it";
    }
    return url.replace(":locale", verifiedLocale);
  };
  const setUrlTextForDifferentLocale = (url, navigation) => {
    let linkUrl = "";
    if (navigation == APP_NAVIGATION.CNTC) {
      linkUrl = url.replace(FOOTER_LINKS.CONTACT_US, t("footer_links.contact_us"));
    } else if (navigation == APP_NAVIGATION.PRVY) {
      linkUrl = url.replace(FOOTER_LINKS.PRIVACY_POLICY, t("footer_links.privacy_policy"));
    } else if (navigation == APP_NAVIGATION.TUSE) {
      linkUrl = url.replace(FOOTER_LINKS.TERMS_CONDITIONS, t("footer_links.terms_conditions"));
    }
    return linkUrl;
  };

  let pathValue = {
    back: ROUTE_CONSTANT.HOME,
    next: ROUTE_CONSTANT.HOME,
    cstm: ROUTE_CONSTANT.HOME,
  };
  if (isfunnelStepsComplete && !isCoverLetterSection) {
    pathValue.next = !!locale ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
    pathValue.back = !!locale ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
  } else {
    let verifiedLocale = "en";
    switch (sectionName) {
      case APP_NAVIGATION.RHOM:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en" ? setLocaleURL(ROUTE_CONSTANT.ROOT_HOME_LOCALE) : ROUTE_CONSTANT.ROOT_HOME;
        break;
      case APP_NAVIGATION.HOME:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        break;
      case APP_NAVIGATION.ITRO:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_TEMPLATE_LOCALE) : ROUTE_CONSTANT.CHOOSE_TEMPLATE;
        break;
      case APP_NAVIGATION.CVLINTRO:
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTER_INTRO_LOCALE)
            : ROUTE_CONSTANT.COVER_LETTER_INTRO;
        break;
      case APP_NAVIGATION.TEMP:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_OPTIONS_LOCALE) : ROUTE_CONSTANT.CHOOSE_OPTIONS;
        break;
      case APP_NAVIGATION.HEDR:
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.PERSONAL_INFORMATION_LOCAL)
            : ROUTE_CONSTANT.PERSONAL_INFORMATION;
        break;
      case APP_NAVIGATION.PRSNLINFO:
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.INTRO_PAGE_LOCALE) : ROUTE_CONSTANT.INTRO_PAGE;
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.OPENER_PAGE_LOCAL) : ROUTE_CONSTANT.OPENER_PAGE;
        }
        break;
      case APP_NAVIGATION.DSRDCPNY:
        if (isReviewSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.JOB_TITLE_LOCAL) : ROUTE_CONSTANT.JOB_TITLE;
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_EXPERIENCE_LOCAL) : ROUTE_CONSTANT.CVLTR_EXPERIENCE;
        }
        break;
      case APP_NAVIGATION.CVLTREXP:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.DESIRED_COMPANY_LOCAL) : ROUTE_CONSTANT.DESIRED_COMPANY;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RECOMMEND_PAGE_LOCAL) : ROUTE_CONSTANT.RECOMMEND_PAGE;
        break;
      case APP_NAVIGATION.OPNRRECOM:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_EXPERIENCE_LOCAL) : ROUTE_CONSTANT.CVLTR_EXPERIENCE;
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.BODY_PAGE_LOCAL) : ROUTE_CONSTANT.BODY_PAGE;
        }
        break;
      case APP_NAVIGATION.SKILS:
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.BODY_PAGE_LOCAL) : ROUTE_CONSTANT.BODY_PAGE;
        }
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.BODY_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.BODY_RECOMMEND_PAGE;
        break;
      case APP_NAVIGATION.CLSR:
        pathValue.back =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.BODY_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.BODY_RECOMMEND_PAGE;
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE;
        break;
      case APP_NAVIGATION.BODYRECOM:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_PAGE_LOCAL) : ROUTE_CONSTANT.SKILLS_PAGE;
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CLOSER_LOCAL) : ROUTE_CONSTANT.CLOSER;
        }
        break;
      case APP_NAVIGATION.CLSR:
        pathValue.back =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.BODY_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.BODY_RECOMMEND_PAGE;
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE;
        break;
      case APP_NAVIGATION.CLSRRECOM:
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
          pathValue.next =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CLOSER_LOCAL) : ROUTE_CONSTANT.CLOSER;
          pathValue.next =
            !!locale || !!prtl
              ? setLocaleURL(ROUTE_CONSTANT.SIGNATURE_PAGE_LOCAL) //
              : ROUTE_CONSTANT.SIGNATURE_PAGE;
        }
        break;
      case APP_NAVIGATION.BODY:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RECOMMEND_PAGE_LOCAL) : ROUTE_CONSTANT.RECOMMEND_PAGE;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_PAGE_LOCAL) : ROUTE_CONSTANT.SKILLS_PAGE;
        break;
      case APP_NAVIGATION.JOBTITLE:
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.OPENER_PAGE_LOCAL) : ROUTE_CONSTANT.OPENER_PAGE;
        }
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.DESIRED_COMPANY_LOCAL) : ROUTE_CONSTANT.DESIRED_COMPANY;
        break;
      case APP_NAVIGATION.SHEDR:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.INTRO_PAGE_LOCALE) : ROUTE_CONSTANT.INTRO_PAGE;
        break;
      case APP_NAVIGATION.SOPNR:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.OPENER_PAGE_LOCAL) : ROUTE_CONSTANT.OPENER_PAGE;
        break;
      case APP_NAVIGATION.SBODY:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.BODY_PAGE_LOCAL) : ROUTE_CONSTANT.BODY_PAGE;
        break;
      case APP_NAVIGATION.SCLSR:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CLOSER_LOCAL) : ROUTE_CONSTANT.CLOSER;
        break;
      case APP_NAVIGATION.OPNR:
        pathValue.back =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.PERSONAL_INFORMATION_LOCAL)
            : ROUTE_CONSTANT.PERSONAL_INFORMATION;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.JOB_TITLE_LOCAL) : ROUTE_CONSTANT.JOB_TITLE;
        break;
      case APP_NAVIGATION.SIGN:
        if (isfunnelStepsComplete && isCoverLetterSection) {
          pathValue.back =
            !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
          pathValue.next =
            !!locale || !!prtl
              ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) //
              : ROUTE_CONSTANT.CVLTR_REVIEW;
        } else {
          pathValue.back =
            !!locale || !!prtl
              ? setLocaleURL(ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE_LOCAL)
              : ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE;
          pathValue.next =
            !!locale || !!prtl
              ? setLocaleURL(ROUTE_CONSTANT.SIGNUP_LOCALE) //
              : ROUTE_CONSTANT.SIGNUP;
        }
        break;
      case APP_NAVIGATION.CONF:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.cstm = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        break;
      case APP_NAVIGATION.HEAD:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_OPTIONS_LOCALE) : ROUTE_CONSTANT.CHOOSE_OPTIONS;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EXPERIENCE_TIPS_LOCALE) : ROUTE_CONSTANT.EXPERIENCE_TIPS;
        break;
      case APP_NAVIGATION.EXPT:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE) : ROUTE_CONSTANT.PERSON_INFO;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EXPERIENCE_LOCALE) : ROUTE_CONSTANT.EXPERIENCE;
        break;
      case APP_NAVIGATION.EXPR:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE) : ROUTE_CONSTANT.PERSON_INFO;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EDUCATION_TIPS_LOCALE) : ROUTE_CONSTANT.EDUCATION_TIPS;
        break;
      case APP_NAVIGATION.EDUT:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EXPERIENCE_LOCALE) : ROUTE_CONSTANT.EXPERIENCE;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EDUCATION_LOCALE) : ROUTE_CONSTANT.EDUCATION;
        break;
      case APP_NAVIGATION.EDUC:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EXPERIENCE_LOCALE) : ROUTE_CONSTANT.EXPERIENCE;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_TIPS_LOCALE) : ROUTE_CONSTANT.SKILLS_TIPS;
        break;
      case APP_NAVIGATION.SKLT:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EDUCATION_LOCALE) : ROUTE_CONSTANT.EDUCATION;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_LOCALE) : ROUTE_CONSTANT.SKILLS;
        break;
      case APP_NAVIGATION.SKLS:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EDUCATION_LOCALE) : ROUTE_CONSTANT.EDUCATION;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SUMMARY_TIPS_LOCALE) : ROUTE_CONSTANT.SUMMARY_TIPS;
        break;
      case APP_NAVIGATION.SUMT:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_LOCALE) : ROUTE_CONSTANT.SKILLS;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SUMMARY_LOCALE) : ROUTE_CONSTANT.SUMMARY;
        break;
      case APP_NAVIGATION.SUMM:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_LOCALE) : ROUTE_CONSTANT.SKILLS;
        // NEED TO CHECK loging for review page
        // else on login page
        if (
          // !!localStorage.getItem("jwtToken") ||
          // !!localStorage.getItem("refreshToken")
          !!readCookie("jwtToken") ||
          !!readCookie("refreshToken")
        ) {
          pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        } else {
          if (IsSingleLoginAPPEnable()) {
            pathValue.next =
              !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SIGNUP_LOCALE_V2) : ROUTE_CONSTANT.SIGNUP_V2;
          } else {
            pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SIGNUP_LOCALE) : ROUTE_CONSTANT.SIGNUP;
          }
        }
        break;
      case APP_NAVIGATION.LANG:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.ADMIN_DASHBOARD_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.ADMIN_DASHBOARD_LOCALE) : ROUTE_CONSTANT.REVIEW;
        break;
      case APP_NAVIGATION.REVW:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS;
        break;
      case APP_NAVIGATION.SINI:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.cstm = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        break;
      case APP_NAVIGATION.CVLTRSINI:
        pathValue.back =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTER_INTRO_LOCALE)
            : ROUTE_CONSTANT.COVER_LETTER_INTRO;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        pathValue.cstm =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTERS_LOCALE) : ROUTE_CONSTANT.COVER_LETTERS;
        break;
      case APP_NAVIGATION.SINU:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.cstm = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        break;
      case APP_NAVIGATION.CVLTRSINU:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
        pathValue.cstm = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        break;
      case APP_NAVIGATION.CSTM:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;

        break;
      case APP_NAVIGATION.OTHR:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.REVIEW_LOCALE) : ROUTE_CONSTANT.REVIEW;
        break;
      case APP_NAVIGATION.CHPG:
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS;
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.THANK_YOU_LOCALE) : ROUTE_CONSTANT.THANK_YOU;
        pathValue.cstm = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        break;
      case APP_NAVIGATION.TEXP:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EXPERIENCE_LOCALE) : ROUTE_CONSTANT.EXPERIENCE;
        break;
      case APP_NAVIGATION.TEDU:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.EDUCATION_LOCALE) : ROUTE_CONSTANT.EDUCATION;
        break;
      case APP_NAVIGATION.THED:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE) : ROUTE_CONSTANT.PERSON_INFO;
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_TEMPLATE_LOCALE) : ROUTE_CONSTANT.CHOOSE_TEMPLATE;
        break;
      case APP_NAVIGATION.TPRSNLINFO:
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.PERSONAL_INFORMATION_LOCAL)
            : ROUTE_CONSTANT.PERSONAL_INFORMATION;
        break;
      case APP_NAVIGATION.TDSRDCPNY:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.JOB_TITLE_LOCAL) : ROUTE_CONSTANT.JOB_TITLE;
        break;
      case APP_NAVIGATION.TRECPT:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.DESIRED_COMPANY_LOCAL) : ROUTE_CONSTANT.DESIRED_COMPANY;
        break;
      case APP_NAVIGATION.TOPNRRECOM:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.JOB_TITLE_LOCAL) : ROUTE_CONSTANT.JOB_TITLE;
        break;
      case APP_NAVIGATION.TBODYRECOM:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_PAGE_LOCAL) : ROUTE_CONSTANT.SKILLS_PAGE;
        break;
      case APP_NAVIGATION.TSIGN:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SIGNATURE_PAGE_LOCAL) : ROUTE_CONSTANT.SIGNATURE_PAGE;
        break;
      case APP_NAVIGATION.TBODY:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_PAGE) : ROUTE_CONSTANT.SKILLS_PAGE;
        break;
      case APP_NAVIGATION.TCLSR:
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE_LOCAL)
            : ROUTE_CONSTANT.CLOSER_RECOMMEND_PAGE;
        break;
      case APP_NAVIGATION.TLAN:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.LANGUAGES_LOCALE) : ROUTE_CONSTANT.LANGUAGE;
        break;
      case APP_NAVIGATION.TPER:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE) : ROUTE_CONSTANT.PERSON_INFO;
        break;
      case APP_NAVIGATION.TSKLS:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SKILLS_LOCALE) : ROUTE_CONSTANT.SKILLS;
        break;
      case APP_NAVIGATION.TSUM:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SUMMARY_LOCALE) : ROUTE_CONSTANT.SUMMARY;
        break;
      case APP_NAVIGATION.SIOT:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
        break;
      case APP_NAVIGATION.RSMS:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.RESUMES_LOCALE) : ROUTE_CONSTANT.RESUMES;
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS;
        break;
      case APP_NAVIGATION.CVLTS:
        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.COVER_LETTERS_LOCALE) : ROUTE_CONSTANT.COVER_LETTERS;
        //TODO: We need to address this before send PR
        pathValue.back = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PLANS_LOCALE) : ROUTE_CONSTANT.PLANS;
        break;
      case APP_NAVIGATION.STNG:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SETTINGS_LOCALE) : ROUTE_CONSTANT.SETTINGS;
        pathValue.back =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_LOCALE)
            : ROUTE_CONSTANT.CANCEL_SUBSCRIPTION;
        break;
      case APP_NAVIGATION.ADLG:
        pathValue.next = ROUTE_CONSTANT.RESUMES;
        break;
      case APP_NAVIGATION.CASU:
        pathValue.next =
          !!locale || !!prtl
            ? setLocaleURL(ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_CONFIRMATION_LOCALE)
            : ROUTE_CONSTANT.CANCEL_SUBSCRIPTION_CONFIRMATION;
        pathValue.back = !!locale ? setLocaleURL(ROUTE_CONSTANT.SETTINGS_LOCALE) : ROUTE_CONSTANT.SETTINGS;
        break;
      case APP_NAVIGATION.PLAN:
        pathValue.next = !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHECKOUT_LOCALE) : ROUTE_CONSTANT.CHECKOUT;
        break;
      case APP_NAVIGATION.PRVY:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en"
            ? setUrlTextForDifferentLocale(setLocaleURL(ROUTE_CONSTANT.PRIVACY_POLICY_LOCALE), APP_NAVIGATION.PRVY)
            : ROUTE_CONSTANT.PRIVACY_POLICY;
        break;
      case APP_NAVIGATION.CVLTRPRVY:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en"
            ? setLocaleURL(ROUTE_CONSTANT.CVLTR_PRIVACY_POLICY_LOCALE)
            : ROUTE_CONSTANT.CVLTR_PRIVACY_POLICY;
        break;
      case APP_NAVIGATION.CNTC:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en"
            ? setUrlTextForDifferentLocale(setLocaleURL(ROUTE_CONSTANT.CONTACT_US_LOCALE), APP_NAVIGATION.CNTC)
            : ROUTE_CONSTANT.CONTACT_US;
        break;
      case APP_NAVIGATION.CVLTRCNTC:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en"
            ? setLocaleURL(ROUTE_CONSTANT.CVLTR_CONTACT_US_LOCALE)
            : ROUTE_CONSTANT.CVLTR_CONTACT_US;
        break;
      case APP_NAVIGATION.TUSE:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en"
            ? setUrlTextForDifferentLocale(setLocaleURL(ROUTE_CONSTANT.TERMS_USE_LOCALE), APP_NAVIGATION.TUSE)
            : ROUTE_CONSTANT.TERMS_USE;
        break;
      case APP_NAVIGATION.UPDRES:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.CHOOSE_OPTIONS_LOCALE) : ROUTE_CONSTANT.CHOOSE_OPTIONS;

        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SELECTED_DOCUMENT_LOCALE) : ROUTE_CONSTANT.SELECTED_DOCUMENT;
        break;
      case APP_NAVIGATION.SELDOC:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.UPLOAD_RESUME_LOCALE) : ROUTE_CONSTANT.UPLOAD_RESUME;

        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.APPLY_TEMPLATE_LOCALE) : ROUTE_CONSTANT.APPLY_TEMPLATE;

        break;

      case APP_NAVIGATION.APPLYTEMP:
        pathValue.back =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.SELECTED_DOCUMENT_LOCALE) : ROUTE_CONSTANT.SELECTED_DOCUMENT;

        pathValue.next =
          !!locale || !!prtl ? setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE) : ROUTE_CONSTANT.PERSON_INFO;

      case APP_NAVIGATION.CVLTRTUSE:
        if (!!locale) {
          if (availableLanguages.indexOf(locale) > -1) {
            verifiedLocale = locale;
          }
        }
        pathValue.next =
          verifiedLocale != "en" ? setLocaleURL(ROUTE_CONSTANT.CVLTR_TERMS_USE_LOCALE) : ROUTE_CONSTANT.CVLTR_TERMS_USE;
        break;
    }
  }
  // pathValue.back = pathValue.back.replace(":locale", locale);
  // pathValue.next = pathValue.next.replace(":locale", locale);
  return pathValue;
};

export const getSignInLink = (locale) => {
  if (IsSingleLoginAPPEnable()) {
    if (!!locale) {
      let verifiedLocale = "en";
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
      return ROUTE_CONSTANT.SIGNIN_LOCALE_V2.replace(":locale", verifiedLocale);
    } else {
      return ROUTE_CONSTANT.SIGNIN_V2;
    }
  } else {
    if (!!locale) {
      let verifiedLocale = "en";
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
      return ROUTE_CONSTANT.SIGNIN_LOCALE.replace(":locale", verifiedLocale);
    } else {
      return ROUTE_CONSTANT.SIGNIN;
    }
  }
};
export const getCoverLetterSignInLink = (locale) => {
  if (!!locale) {
    let verifiedLocale = "en";
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
    return ROUTE_CONSTANT.SIGNIN_LOCALE.replace(":locale", verifiedLocale);
  } else {
    return ROUTE_CONSTANT.SIGNIN;
  }
};
export const getSignUpLink = (locale) => {
  if (IsSingleLoginAPPEnable()) {
    if (!!locale) {
      let verifiedLocale = "en";
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
      return ROUTE_CONSTANT.SIGNUP_LOCALE_V2.replace(":locale", verifiedLocale);
    } else {
      return ROUTE_CONSTANT.SIGNUP_V2;
    }
  } else {
    if (!!locale) {
      let verifiedLocale = "en";
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
      return ROUTE_CONSTANT.SIGNUP_LOCALE.replace(":locale", verifiedLocale);
    } else {
      return ROUTE_CONSTANT.SIGNUP;
    }
  }
};
export const getCoverLetterSignUpLink = (locale) => {
  if (!!locale) {
    let verifiedLocale = "en";
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
    return ROUTE_CONSTANT.SIGNUP_LOCALE.replace(":locale", verifiedLocale);
  } else {
    return ROUTE_CONSTANT.SIGNUP;
  }
};
export const getForgetPasswordLink = (locale) => {
  if (!!locale) {
    let verifiedLocale = "en";
    if (availableLanguages.indexOf(locale) > -1) {
      verifiedLocale = locale;
    }
    return ROUTE_CONSTANT.FORGOT_PASSWORD_LOCALE.replace(":locale", verifiedLocale);
  } else {
    return ROUTE_CONSTANT.FORGOT_PASSWORD;
  }
};
export default PathHelper;
