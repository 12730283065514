import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PathHelper from "../../utils/path-helper";
import Button from "../../components/shared/button";
import FinalStepTypes from "../funnel-step/types";
import { useDispatch } from "react-redux";
import { APP_NAVIGATION } from "../../utils/constants";
import { Helmet } from "react-helmet";
import TemplateView from "../../components/cover-letter-template-view";
import { slice } from "lodash";
import ReactLoader from "../../components/shared/loader";
import "./intro-page.css";
import Sidebar from "../../components/cover-letter-sidebar";
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import { trackRBEvents } from "../../utils/tracking-events";
import { getPortalCD, readCookie } from "../../utils/helpers";

const CoverLetterIntroPage = ({
  chooseTemplate, //rootreducer
  funnelStep, //rootreducer
  fetchTemplatesConnect, //action method
  setTemplateConnect,
  saveChooseTemplate,
  handleSteps,
  cvltrTemplatePreview,
}) => {
  const dispatch = useDispatch();
  const localtion = useLocation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const { data } = chooseTemplate || {};
  const { t } = useTranslation();
  const { userGUID, email, personalInfoName, funnelStepsComplete } = funnelStep || {};
  let pathRedirect = PathHelper(APP_NAVIGATION.HEDR);
  const [index, setIndex] = useState(8);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showLoader, setShowLoader] = useState("d-flex");

  const loadMore = () => {
    setIndex(index + 4);
    if (index >= data.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  const initialPosts = slice(!!data && data.length !== 0 ? data : [], 0, index);

  useEffect(() => {
    trackRBEvents("cvChooseTemplateView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    fetchTemplatesConnect(locale);
    let currentStep = localtion.pathname;
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
    handleSteps({
      ...cvltrTemplatePreview.steps,
      personalInfo: true,
      signature: true,
    });
  }, []);

  useEffect(() => {
    if (!!data && data.length !== 0) {
      setTimeout(() => {
        setShowLoader("d-none");
      }, 300);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (index >= data.length) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    }
  }, [data, index]);

  const chooseTemplateHandler = (e, item) => {
    setTemplateConnect(item.templatecd);
    saveChooseTemplate(item);
    navigate(pathRedirect.next + localtion.search);
  };
  const skipChooser = () => {
    console.log("Classic");
    setTemplateConnect("Classic");
    navigate(pathRedirect.next + localtion.search);
  };

  const skip = () => {
    navigate(pathRedirect.next);
  };

  return (
    <Sidebar>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={0} isCoverLetter={true} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <Helmet>
        <title>{t("page_title.choose_template")}</title>
      </Helmet>

      <ReactLoader showloader={showLoader} />
      <section className="section main-content templates text-center sidebar-sticky sidebar-sticky-cvrltr">
        <div className="container">
          <div className="alignment-container">
            <div className="">
              <hgroup className="text-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("choose_cover_letter.headline"),
                  }}
                />
                <p>{t("choose_cover_letter.content")}</p>
                <h2 className="mt-5">{t("choose_cover_letter.caption")}</h2>
              </hgroup>
            </div>

            <div className="intro-page">
              <div className="row template-container">
                {!!data && data.length > 0 && (
                  <>
                    {initialPosts.map((item, index) => {
                      return <TemplateView data={item} key={index} onClick={(e) => chooseTemplateHandler(e, item)} />;
                    })}
                  </>
                )}

                {data.length !== 0 && (
                  <div className="d-flex justify-content-center my-3">
                    {!isCompleted && (
                      <button
                        onClick={loadMore}
                        type="button"
                        className="btn btn-lg p-4 w-50 cvltr-load-more"
                        style={{
                          border: 0,
                        }}
                      >
                        <b>{t("choose_templates.load_more")}</b>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="templates-bottom">
                <div className="templates-bottom--btn">
                  <div className="fbn">
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={skipChooser}
                      classNames="btn btn-outline-success btn-lg template-skip-btn"
                      ctaText={t("choose_templates.skip")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default CoverLetterIntroPage;
