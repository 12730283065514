import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FinalStepTypes from "../funnel-step/types";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { Helmet } from "react-helmet";
import Button from "../../components/shared/button";
import { useParams } from "react-router-dom";
import IconFileLeftArrow from "../../components/icons/icon-file-left";
import { ROUTE_CONSTANT } from "../../routing/route-constants.js";
import { setLocaleURL, readCookie, getCountryCode, getPortalCD } from "../../utils/helpers";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { blobSignature, getTenantId } from "../../utils/general-config";
import { DEFAULT_USER_GUID } from "../../utils/constants";
import LoaderPopup from "../../components/loader-popup";
import { trackRBEvents } from "../../utils/tracking-events";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const SelectedDocument = ({
  parsedResumeConnect,
  saveDocGUIDConnect,
  uploadResume,
  funnelStep,
  resetUploadResumeConnect,
}) => {
  const { userGUID, email, personalInfoName } = funnelStep || {};
  const [loaderPopupShow, setLoaderPopupShow] = useState(false);
  const handleLoaderPopupHide = () => setLoaderPopupShow(false);
  const originalFileUrl = uploadResume?.uploadResponse?.originalFileUrl + blobSignature();

  const renderPdfFileUrl = uploadResume?.uploadResponse?.renderPdfFileUrl + blobSignature();
  const originalName = uploadResume?.uploadResponse?.originalName;
  const templateName = funnelStep?.templateCd;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let pathRedirect = PathHelper(APP_NAVIGATION.SELDOC);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isLoadedThumbnail, setIsLoadedThumbnail] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const { locale } = useParams();
  const [error, setError] = useState("");
  const [country, setCountry] = useState("");
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const docParseHandler = () => {
    setLoaderPopupShow(true);
    // navigate(pathRedirect.next);
    const data = {
      docURL: originalFileUrl,
      renderPdfUrl: renderPdfFileUrl,
      docName: originalName.split(".").length > 0 ? originalName.split(".")[0] : "",
      tenantId: getTenantId(),
      userGuid: userGUID ? userGUID : DEFAULT_USER_GUID,
      visitorId: readCookie("visitorGuid"),
      templateName: templateName,
      locale: !!locale ? locale : "en",
      countryCode: country,
      folderPath: funnelStep?.queryStringsInfo?.fp ? funnelStep?.queryStringsInfo?.fp : null,
    };
    parsedResumeConnect(data)
      .then((response) => {
        saveDocGUIDConnect(response?.document?.id);
        if (localStorage.getItem("serviceToken")) {
          localStorage.removeItem("serviceToken");
        }
        resetUploadResumeConnect();
        setTimeout(() => {
          if (!!locale) {
            navigate(setLocaleURL(ROUTE_CONSTANT.PERSON_INFO_LOCALE, locale));
          } else {
            navigate(ROUTE_CONSTANT.PERSON_INFO);
          }
        }, 1000);
        setLoaderPopupShow(false);
        setError("");
      })
      .catch((err) => {
        setError(t("selected_document.unable_to_Parse_resume"));
        setTimeout(() => {
          setLoaderPopupShow(false);
          // navigate(pathRedirect.back);
        }, 1800);
        //setLoaderPopupShow(false);
      });
  };
  function onDocumentLoadSuccess({ numPages }) {
    console.log("Inside onDocumentLoadSuccess");
    setIsLoadedThumbnail(true);
    setNumPages(numPages);
  }
  const passwordHandler = () => {
    console.log("password handler click");
  };
  const setCountryCode = async () => {
    const countryCode = await getCountryCode();
    setCountry(countryCode);
  };
  useEffect(() => {
    trackRBEvents("selectedDocumentView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    let currentStep = location.pathname;
    setPreviewUrl(renderPdfFileUrl);
    setCountryCode();
    dispatch({
      type: FinalStepTypes.SET_CURRENTSTEP,
      currentStep,
    });
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>{t("page_title.selected_document")}</title>
      </Helmet>
      <section className="section main-content">
        <div className="container page-section">
          <div className="row">
            <div className="col-12 headline-group text-center">
              <hgroup>
                <h1>{t("selected_document.heading")}</h1>
              </hgroup>
            </div>
          </div>
          <div className="row page-section-wrap justify-content-around">
            <div className="col-md-6 col-lg-5 mb-4">
              <div className="border h-100 row py-3 px-1 m-0">
                <div className="col-md-6 d-flex align-items-start flex-column py-4 px-4 mh-100">
                  <h3 className="mb-5">{t("selected_document.upload_heading")}</h3>
                  <p className="mb-auto pb-4">{originalName}</p>
                  <small className="text-secondary">{t("selected_document.upload_txt")}</small>
                </div>

                <div className={"col-md-6 pdf-review " + (isLoadedThumbnail ? "show" : "loading")}>
                  <Document file={previewUrl} onLoadSuccess={onDocumentLoadSuccess} onPassword={passwordHandler}>
                    <Page key={`page_${1}`} pageNumber={1} />
                  </Document>
                  {/* <img
                    className="border"
                    alt="Quo"
                    src="/app/static/images/thumbs/en/cv13.jpg"
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-md-1 align-items-center d-none d-md-flex mb-4" style={{ width: "40px" }}>
              <span className="fw-bold text-uppercase">{t("universal.or")}</span>
            </div>
            <div onClick={backClickHandler} className="col-md-5 col-lg-4 mb-4 text-center">
              <div className="border h-100 pointer-hover">
                <h3 className="pt-5">{t("selected_document.change_document_headeing")}</h3>
                <div className="pt-4 pb-4">
                  <IconFileLeftArrow width={90} height={90} className="mb-4 mt-2" />
                </div>
                <label>
                  <Button
                    isLoading={false}
                    id={t("selected_document.change_document_cta")}
                    classNames="btn btn-secondary"
                    ctaText={t("selected_document.change_document_cta")}
                  />
                </label>
                {/* <small className="text-secondary pt-3 d-block">
                  Or Drag & Drop your file into this box
                </small> */}
              </div>
              {/* <Upload {...uploaderProps}></Upload> */}
            </div>
            {error && (
              <div
                className="text-center text-danger pt-4 mb-0"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(error),
                }}
              />
            )}
          </div>
          <div className="btn-container fbn sticky-bar btn-justify">
            <Button
              isLoading={false}
              classNames="btn btn-outline-secondary btn-lg"
              ctaText={t("universal.back_action")}
              onClick={backClickHandler}
            />
            <Button
              isLoading={false}
              disabled={false}
              classNames="btn btn-primary btn-lg submit-on-enter float-end"
              ctaText={t("universal.next_action")}
              onClick={docParseHandler}
            />
          </div>
        </div>
      </section>
      <LoaderPopup loaderPopupShow={loaderPopupShow} handleLoaderPopupHide={handleLoaderPopupHide} error={error} />
      <Footer />
    </>
  );
};

export default SelectedDocument;
