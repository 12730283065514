import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PersonalInfo from "./component";
import { createPersonalInfoParagraph, savePersonalInfo, updatePersonalInfo } from "./actions";
import { setDocGUID, setCurrentStep, setName } from "./../funnel-step/actions";
import { resetFolderQueryStringInfo } from "./../funnel-step/actions";
import { setImageUrl } from "./../../components/image-upload-modal/actions";

const mapStateToProps = ({ docguid, funnelStep, personalInfo, imageUploadPreview }) => ({
  docguid,
  funnelStep,
  personalInfo,
  imageUploadPreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      savePersonalInfoConnect: savePersonalInfo,
      SaveDocGUIDConnect: setDocGUID,
      updatePersonalInfoConnect: updatePersonalInfo,
      setCurrentStepConnect: setCurrentStep,
      setNameConnect: setName,
      resetFolderQueryStringInfoConnect: resetFolderQueryStringInfo,
      createPersonalInfoParagraphConnect: createPersonalInfoParagraph,
      setImageUrlConnect: setImageUrl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
