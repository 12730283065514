import Types from "./types";
import DocBuilderApi from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import DocBuilderService from "../../services/doc-builder-api";

const createParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderApi.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create Paragraph");
          dispatch(fetchOtherSectionSuccess(response));
        } else {
        }
        return response;
      })

      .catch((error) => dispatch(fetchOtherSectionFailure(error)));
  };
};
const updateOtherSectionParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateOtherSectionSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateOtherSectionFailure(error)));
  };
};

const fetchOtherSectionSuccess = (data) => ({
  type: Types.FETCH_OTHER_SECTION_SUCCESS,
  data,
});

const fetchOtherSectionFailure = (error) => ({
  type: Types.FETCH_OTHER_SECTION_FAILURE,
  error,
});
const updateOtherSectionSuccess = (data) => ({
  type: Types.UPDATE_OTHER_SUCCESS,
  data,
});

const updateOtherSectionFailure = (error) => ({
  type: Types.UPDATE_OTHER_FAILED,
  error,
});
const resetOtherSection = () => ({
  type: Types.RESET_OTHER_SECTION,
});

export { createParagraph, updateOtherSectionParagraph, resetOtherSection };
