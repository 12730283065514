import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./translations/locales/en/translation.json";
import translationAR from "./translations/locales/ar/translation.json";
import translationES from "./translations/locales/es/translation.json";
import translationFR from "./translations/locales/fr/translation.json";
import translationIT from "./translations/locales/it/translation.json";
const queryParams = new URLSearchParams(window.location.search);
const qParams = new URLSearchParams();
for (const [name, value] of queryParams) {
  qParams.append(name.toLowerCase(), value.toLowerCase());
}
let prtl = "";
if (qParams.has("prtlsrc")) {
  prtl = qParams.get("prtlsrc");
  if (prtl.length === 6) {
    prtl = prtl.slice(-2);
  } else if (!!prtl) {
    prtl = "en";
  }
}
let lang = "en";
if (window.location.pathname.match("/es")) {
  localStorage.setItem("i18nextLng", "es");
  lang = "es";
} else if (window.location.pathname.match("/fr")) {
  localStorage.setItem("i18nextLng", "fr");
  lang = "fr";
} else if (window.location.pathname.match("/it")) {
  localStorage.setItem("i18nextLng", "it");
  lang = "it";
} else {
  localStorage.setItem("i18nextLng", "en");
}

if (prtl.indexOf("es") > -1) {
  localStorage.setItem("i18nextLng", "es");
  lang = "es";
} else if (prtl.indexOf("fr") > -1) {
  localStorage.setItem("i18nextLng", "fr");
  lang = "fr";
} else if (prtl.indexOf("it") > -1) {
  localStorage.setItem("i18nextLng", "it");
  lang = "it";
} else if (prtl.indexOf("en") > -1) {
  localStorage.setItem("i18nextLng", "en");
  lang = "en";
}

export const fallbackLng = [lang];
export const availableLanguages = ["en", "es", "fr", "it"];

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
