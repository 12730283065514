import { css, createGlobalStyle } from "styled-components";
import { CommonCSS } from "../../review-style/common-style/common-style";

export const Cv12GraphiteCSS = css`
  .resume-layout.cv12 {
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv12 .resume__section.resume__heading h1 {
    font-size: 2.5em;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    word-break: break-word;
    margin-bottom: 2rem;
    font-family: "Roboto TTF", sans-serif;
  }
  .resume-layout.cv12 .resume__section .sub-headline h2 {
    display: inline-block;
    margin-bottom: 0;
    font-family: "Roboto TTF", sans-serif;
    font-size: 1.43em;
    line-height: 1;
    font-weight: 900;
    text-transform: capitalize;
  }
  .resume-layout.cv12 .resume__section h1,
  .resume-layout.cv12 .resume__section h3 {
    display: inline-block;
    margin-bottom: 0;
    font-family: "Roboto TTF", sans-serif;
    font-size: 1.25em;
    line-height: 1;
    font-weight: 600;
    text-transform: capitalize;
  }
  .resume-layout.cv12 .resume-aside .sub-headline {
    display: block;
    margin-bottom: 2.5rem;
  }
  .resume-layout.cv12 .resume-aside .sub-headline h2 {
    font-size: 1.29em;
  }
  .resume-layout.cv12 .sub-headline .svg-icon {
    display: inline-block;
  }
  .resume-layout.cv12 .resume-aside {
    text-align: center;
    padding-right: 2rem;
  }
  .resume-layout.cv12 .resume-aside .sub-headline .svg-icon {
    display: none;
  }
  .resume-layout.cv12 .personal-info {
    display: block;
  }
  .resume-layout.cv12 .info-item {
    display: block;
  }
  .resume-layout.cv12 .info-item .info-label,
  .resume-layout.cv12 .info-item .info-text,
  .resume-layout.cv12 .wrap .left-col,
  .resume-layout.cv12 .wrap .extra-info,
  .resume-layout.cv12 .wrap .extra-details {
    width: 100%;
    display: block;
    padding: 0;
  }
  .resume-layout.cv12 .wrap .extra-info {
    font-weight: bold;
  }
  .resume-layout.cv12 .extra {
    display: block;
  }
  .resume-layout.cv12 .extra .extra-details .progress-starts,
  .resume-layout.cv12 .extra .extra-details .progress-circle {
    display: none;
  }
  .resume-layout.cv12 .extra .extra-details .progress-line {
    display: block;
  }
  .resume-layout.cv12
    .extra
    .extra-details
    .progress-line
    .extra-details__progress {
    background-color: #a8afb6;
  }
  .resume-layout.cv12 .xp-item {
    display: block;
  }
  .resume-layout.cv12 .xp-item .xp-date,
  .resume-layout.cv12 .xp-item .xp-job {
    // font-size: 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: normal;
    padding: 0;
  }
  .resume-layout.cv12 .resume-main .resume__section {
    padding-bottom: 1em;
    border-left: 0.07em solid #cbcbcb;
  }
  .resume-layout.cv12 .xp-item .xp-job {
    border-left: 0;
  }
  .resume-layout.cv12 .resume__experience .exp-show {
    display: none;
  }
  .resume-layout.cv12 .resume__experience .exp-hide {
    display: block;
  }
  .resume-layout.cv12 .resume__education .edu-show {
    display: none;
  }
  .resume-layout.cv12 .resume__education .edu-hide {
    display: block;
  }
`;

export const Cv12GraphiteStyle = createGlobalStyle`
  ${CommonCSS}
  ${Cv12GraphiteCSS}
`;
