import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SECTION_TYPE } from "../../../utils/constants";
import Button from "../../shared/button";
import { useNavigate, link, useParams } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../../routing/route-constants";

const ResumeCustom = ({ data }) => {
  const { heading, content } = JSON.parse(data.content);
  return (
    <>
      <div className="wrap">
        <ul dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
};

const TempCustom = ({
  data,
  toggleRenameModal,
  toggleDeleteModal,
  setCurrentParaConnect,
  setSelectedRenameSection,
  paraName,
  section,
  paraId,
  dragHandleProps,
  dragStart,
}) => {
  let dragClass = false;
  if (dragStart !== undefined) {
    if (dragStart.style.position !== undefined) {
      dragClass = true;
    }
  }
  const { t } = useTranslation();
  if (paraName === "affiliations") {
    paraName = t("review.affiliations");
  } else if (paraName === "awards") {
    paraName = t("review.awards");
  } else if (paraName === "additional information") {
    paraName = t("review.additional_information");
  } else if (paraName === "publications") {
    paraName = t("review.publications");
  } else if (paraName === "accomplishments") {
    paraName = t("review.accomplishments");
  }
  const { locale } = useParams();
  const pathHelperForSections = () => {
    if (section == SECTION_TYPE.CSTM) {
      return !!locale
        ? setOtherSectionURL(
            ROUTE_CONSTANT.CUSTOM_SECTION_LOCALE,
            true,
            paraName
          )
        : setOtherSectionURL(ROUTE_CONSTANT.CUSTOM_SECTION, false, paraName);
    } else {
      return !!locale
        ? setOtherSectionURL(
            ROUTE_CONSTANT.OTHER_SECTIONS_LOCALE,
            true,
            paraName
          )
        : setOtherSectionURL(ROUTE_CONSTANT.OTHER_SECTIONS, false, paraName);
    }
  };
  const setOtherSectionURL = (url, isLocaleDefined, selectedCustomSection) => {
    return !!isLocaleDefined
      ? url
          .replace(
            ":sectionname",
            !!selectedCustomSection ? selectedCustomSection : ""
          )
          .replace(":locale", !!locale ? locale : "en")
      : url.replace(
          ":sectionname",
          !!selectedCustomSection ? selectedCustomSection : ""
        );
  };
  const FinalReviewCustomSectionClick = () => {
    setSelectedRenameSection(paraName);
    toggleRenameModal();
    if (section == SECTION_TYPE.CSTM) {
      setCurrentParaConnect("CSTM", paraId);
    } else {
      setCurrentParaConnect("OTHR", paraId);
    }
  };
  const deleteSectionHandler = () => {
    toggleDeleteModal();
    if (section == SECTION_TYPE.CSTM) {
      setCurrentParaConnect("CSTM", "");
    } else {
      setCurrentParaConnect("OTHR", "");
    }
  };
  // Nav
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(pathHelperForSections());
  };
  return (
    <div
      className={
        dragClass
          ? "resume__section resume__others dragging"
          : "resume__section resume__others"
      }
    >
      <div className="resume__controls"></div>
      <div className="controls-menu">
        <Button
          isLoading={false}
          disabled={false}
          onClick={editHandler}
          icon={
            <>
              <span className="fa fa-pencil" aria-hidden="true"></span>
              <span className="resume__control-text"> {t("review.edit")}</span>
            </>
          }
          classNames="btn-green resume__control-button"
          ctaText=""
        />
        <Button
          isLoading={false}
          disabled={false}
          onClick={FinalReviewCustomSectionClick}
          icon={
            <>
              <span className="fa fa-file-text-o" aria-hidden="true"></span>
              <span className="resume__control-text">
                {" "}
                {t("review.rename")}
              </span>
            </>
          }
          classNames="btn-green resume__control-button"
          ctaText=""
        />
        <Button
          isLoading={false}
          disabled={false}
          onClick={deleteSectionHandler}
          icon={
            <>
              <span className="fa fa-trash-o" aria-hidden="true"></span>
              <span className="resume__control-text">
                {" "}
                {t("review.delete")}
              </span>
            </>
          }
          classNames="btn-green resume__control-button"
          ctaText=""
        />
      </div>
      <div className="resume__content">
        <div className="sub-headline">
          <i className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              aria-hidden="true"
              className="iconify icon:fa:book"
              data-height="1em"
              data-icon="fa:book"
              data-inline="false"
              data-width="1em"
              transform="rotate(360)"
              viewBox="0 0 1696 1536"
            >
              <path
                fill="currentColor"
                d="M1671 350q40 57 18 129l-275 906q-19 64-76.5 107.5T1215 1536H292q-77 0-148.5-53.5T44 1351q-24-67-2-127 0-4 3-27t4-37q1-8-3-21.5t-3-19.5q2-11 8-21t16.5-23.5T84 1051q23-38 45-91.5t30-91.5q3-10 .5-30t-.5-28q3-11 17-28t17-23q21-36 42-92t25-90q1-9-2.5-32t.5-28q4-13 22-30.5t22-22.5q19-26 42.5-84.5T372 283q1-8-3-25.5t-2-26.5q2-8 9-18t18-23 17-21q8-12 16.5-30.5t15-35 16-36 19.5-32T504.5 12t36-11.5T588 6l-1 3q38-9 51-9h761q74 0 114 56t18 130l-274 906q-36 119-71.5 153.5T1057 1280H188q-27 0-38 15-11 16-1 43 24 70 144 70h923q29 0 56-15.5t35-41.5l300-987q7-22 5-57 38 15 59 43zm-1064 2q-4 13 2 22.5t20 9.5h608q13 0 25.5-9.5T1279 352l21-64q4-13-2-22.5t-20-9.5H670q-13 0-25.5 9.5T628 288zm-83 256q-4 13 2 22.5t20 9.5h608q13 0 25.5-9.5T1196 608l21-64q4-13-2-22.5t-20-9.5H587q-13 0-25.5 9.5T545 544z"
              ></path>
            </svg>
          </i>
          <h2
            style={{
              fontSize: data[0].headingfontsize,
              fontFamily: data[0].fontFamily,
            }}
          >
            {paraName}
          </h2>
        </div>
        {data?.map((item, index) => (
          <ResumeCustom data={item} key={"custom" + index} />
        ))}
      </div>
      <div className="resume__move" {...dragHandleProps}>
        <a
          className="btn-green resume__control-button js-sortableHandle"
          href="#"
        >
          <span className="fa fa-arrows" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  );
};

export default TempCustom;
