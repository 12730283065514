import Types from "./types";
import DocBuilderApi from "../../services/doc-builder-api";
import appLogger from "../../utils/app-logger";
import DocBuilderService from "../../services/doc-builder-api";

const createExperienceParagraph = (data) => {
  return function (dispatch) {
    return DocBuilderApi.createParagraph(data.docId, data)
      .then((response) => {
        if (response) {
          appLogger.log("inside create experience paragraph");
          dispatch(fetchExperienceSuccess(response));
        } else {
        }
        return response;
      })

      .catch((error) => dispatch(fetchExperienceFailure(error)));
  };
};
const updateExperienceParagraph = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateExperienceSuccess(response));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateExperienceFailure(error)));
  };
};
const deleteExperience = (docid, paraid, data) => {
  return function (dispatch) {
    return DocBuilderService.updateParagraph(docid, paraid, data)
      .then((response) => {
        if (response) {
          dispatch(updateExperienceSuccess(data));
        } else {
        }
        return response;
      })
      .catch((error) => dispatch(updateExperienceFailure(error)));
  };
};
const deleteExperienceSuccess = (id) => ({
  type: Types.DELETE_EXPERIENCE_SUCCESS,
  id,
});
const fetchExperienceSuccess = (data) => ({
  type: Types.FETCH_EXPERIENCE_SUCCESS,
  data,
});

const fetchExperienceFailure = (error) => ({
  type: Types.FETCH_EXPERIENCE_FAILURE,
  error,
});
const updateExperienceSuccess = (data) => ({
  type: Types.UPDATE_EXP_SUCCESS,
  data,
});

const updateExperienceFailure = (error) => ({
  type: Types.UPDATE_EXP_FAILED,
  error,
});
const resetExperience = () => ({
  type: Types.RESET_EXPERIENCE,
});
export {
  createExperienceParagraph,
  fetchExperienceSuccess,
  fetchExperienceFailure,
  updateExperienceParagraph,
  deleteExperience,
  resetExperience,
};
