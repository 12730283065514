import React from "react";

const IconOneDrive = ({
  width = 190,
  height = 46,
  className = { className },
}) => {
  return (
    <>
      <svg
        id="One_Drive"
        data-name="One Drive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 190.968 34.742"
        className={className}
        width={width}
        height={height}
      >
        <path
          id="Path_121"
          data-name="Path 121"
          d="M25.205,17.642,37.46,12.72c-3.668-14.3-22.58-17.3-30.6-5.112C7.032,7.6,25.205,17.642,25.205,17.642"
          transform="translate(6.537 0)"
          fill="#0364c5"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M20.7,5.915v0a13.5,13.5,0,0,0-7.129-2.024c-.057,0-.115,0-.172,0A13.564,13.564,0,0,0,2.439,25.219l2.131.549L32.092,12.739Z"
          transform="translate(0 3.712)"
          fill="#0078e1"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M28.335,6.525c-.246-.016-.494-.027-.744-.027a10.955,10.955,0,0,0-4.37.9l0,0-6.79,2.858L35.752,23.494l1.545-.738A11.032,11.032,0,0,0,28.335,6.525Z"
          transform="translate(15.662 6.195)"
          fill="#1490ec"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M1.248,20.9A13.549,13.549,0,0,0,12.38,26.714H42.064A11.02,11.02,0,0,0,51.77,20.92L30.9,8.421Z"
          transform="translate(1.189 8.028)"
          fill="#28a8f7"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M42.972,28.254a10.429,10.429,0,0,1-8.094-3.33,12.354,12.354,0,0,1-3.041-8.667q0-5.734,3.1-9.147A10.821,10.821,0,0,1,43.37,3.7,10.192,10.192,0,0,1,51.3,7.01a12.394,12.394,0,0,1,3.008,8.667q0,5.816-3.082,9.2a10.621,10.621,0,0,1-8.253,3.381m.2-22.039a7.658,7.658,0,0,0-6.081,2.7,10.452,10.452,0,0,0-2.338,7.092,10.523,10.523,0,0,0,2.279,7.067,7.43,7.43,0,0,0,5.94,2.676A7.8,7.8,0,0,0,49.136,23.2q2.253-2.552,2.254-7.143,0-4.705-2.188-7.274a7.5,7.5,0,0,0-6.032-2.569"
          transform="translate(30.348 3.525)"
          fill="#0364c5"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M58.936,24.542H56.219V14.865q0-5.4-3.944-5.4A4.28,4.28,0,0,0,48.9,11a5.682,5.682,0,0,0-1.334,3.869v9.677H44.851V7.573h2.717V10.39h.066A6.125,6.125,0,0,1,53.2,7.175a5.191,5.191,0,0,1,4.258,1.8,8.012,8.012,0,0,1,1.475,5.194Z"
          transform="translate(42.755 6.84)"
          fill="#0364c5"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M68.192,16.736H56.213a6.34,6.34,0,0,0,1.524,4.375,5.256,5.256,0,0,0,4.01,1.541,8.344,8.344,0,0,0,5.27-1.889v2.551A9.841,9.841,0,0,1,61.1,24.94a7.176,7.176,0,0,1-5.651-2.313,9.465,9.465,0,0,1-2.055-6.5A9.282,9.282,0,0,1,55.64,9.669a7.2,7.2,0,0,1,5.577-2.494A6.381,6.381,0,0,1,66.37,9.329a8.982,8.982,0,0,1,1.822,5.983Zm-2.783-2.3a5.532,5.532,0,0,0-1.135-3.662,3.867,3.867,0,0,0-3.108-1.309A4.391,4.391,0,0,0,57.9,10.837a6.24,6.24,0,0,0-1.656,3.6Z"
          transform="translate(50.899 6.84)"
          fill="#0364c5"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M62.566,27.663V3.9h6.561q12.561,0,12.562,11.581A11.674,11.674,0,0,1,78.2,24.323a12.939,12.939,0,0,1-9.339,3.34ZM65.349,6.419V25.143h3.545a10.068,10.068,0,0,0,7.276-2.5,9.379,9.379,0,0,0,2.6-7.092q0-9.13-9.712-9.13Z"
          transform="translate(59.642 3.719)"
          fill="#0364c5"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M82.823,10.275a3.318,3.318,0,0,0-2.055-.547,3.469,3.469,0,0,0-2.908,1.641,7.578,7.578,0,0,0-1.168,4.473v8.651H73.975V7.525h2.717v3.5h.066a5.939,5.939,0,0,1,1.774-2.793,4.042,4.042,0,0,1,2.668-1,4.443,4.443,0,0,1,1.623.232Z"
          transform="translate(70.519 6.889)"
          fill="#0364c5"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M81.107,6.9a1.724,1.724,0,0,1-1.242-.5,1.676,1.676,0,0,1-.514-1.26,1.741,1.741,0,0,1,1.756-1.774,1.753,1.753,0,0,1,1.268.506A1.691,1.691,0,0,1,82.9,5.14a1.678,1.678,0,0,1-.522,1.242,1.744,1.744,0,0,1-1.268.514m1.326,21.277H79.716V11.2h2.717Z"
          transform="translate(75.643 3.209)"
          fill="#0364c5"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M97.728,7.378l-6.76,16.968H88.3L81.869,7.378h2.983l4.309,12.329a11,11,0,0,1,.6,2.369h.066a11.15,11.15,0,0,1,.531-2.3l4.506-12.4Z"
          transform="translate(78.044 7.034)"
          fill="#0364c5"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M104.99,16.736H93.011a6.34,6.34,0,0,0,1.524,4.375,5.256,5.256,0,0,0,4.01,1.541,8.344,8.344,0,0,0,5.27-1.889v2.551A9.841,9.841,0,0,1,97.9,24.94a7.176,7.176,0,0,1-5.651-2.313,9.465,9.465,0,0,1-2.055-6.5,9.273,9.273,0,0,1,2.246-6.454,7.2,7.2,0,0,1,5.577-2.494,6.381,6.381,0,0,1,5.153,2.154,8.982,8.982,0,0,1,1.822,5.983Zm-2.783-2.3a5.532,5.532,0,0,0-1.135-3.662,3.867,3.867,0,0,0-3.108-1.309A4.391,4.391,0,0,0,94.7,10.837a6.24,6.24,0,0,0-1.656,3.6Z"
          transform="translate(85.978 6.84)"
          fill="#0364c5"
        />
      </svg>
    </>
  );
};

export default IconOneDrive;
