import React from "react";
import "./footer.scss";
import Button from "../shared/button";
import PathHelper from "../../utils/path-helper";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { APP_NAVIGATION } from "../../utils/constants";

const Footer = () => {
  const { t } = useTranslation();
  let pathRedirectPrivacy = PathHelper(APP_NAVIGATION.PRVY, false);
  let pathRedirectContact = PathHelper(APP_NAVIGATION.CNTC, false);
  let pathRedirectTerms = PathHelper(APP_NAVIGATION.TUSE, false);
  let pathRedirectHome = PathHelper(APP_NAVIGATION.RHOM, false);
  // Nav
  const location = useLocation();

  const navigate = useNavigate();
  const redirectHomeHandle = () => {
    navigate(pathRedirectHome.next);
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center footer-wrap">
          <div className="col-md-3 col-lg-3 footer--logo">
            {location.pathname.includes("checkout") ? (
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/static/images/resumebuild-white.svg  "
                }
              />
            ) : (
              <a href={pathRedirectHome.next}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/resumebuild-white.svg  "
                  }
                />
              </a>
            )}
          </div>
          <div className="col-md-9 col-lg-5">
            <ul className="footer--link list-inline list-group list-group-horizontal">
              <li>
                <a
                  className="btn btn-link footer-link px-0"
                  href={pathRedirectContact.next}
                  title={t("footer.contact_us")}
                >
                  {t("footer.contact_us")}
                </a>
              </li>
              <li>
                <a
                  className="btn btn-link  footer-link px-0"
                  href={pathRedirectPrivacy.next}
                  title={t("footer.privacy_policy")}
                >
                  {t("footer.privacy_policy")}
                </a>
              </li>
              <li>
                <a
                  className="btn btn-link  footer-link px-0"
                  href={pathRedirectTerms.next}
                  title={t("footer.terms_condition")}
                >
                  {t("footer.terms_condition")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-4 ">
            <span className="footer--copyright">
              © {new Date().getFullYear()}. WorkSimpli Software, LLC. <br />{" "}
              {t("footer.subsidiary")}
              <br /> {t("footer.all_rights_reserved")}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
