import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SAVE_SUCCESS
    SAVE_FAILED
    UPDATE_SUCCESS
    UPDATE_FAILED
`,
    {
        prefix: "WRITER_SNIPPET_EXPERIENCE/"
    }
);
