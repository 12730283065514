import React, { useState, useEffect } from "react";
import TempHeader from "../../temp-header";
import TempPersonalInfo from "../../temp-personal-info";
import TempSummary from "../../temp-summary";
import TempExperience from "../../temp-experience";
import TempSkills from "../../temp-skills";
import TempEducation from "../../temp-education";
import TempCustom from "../../temp-custom";
import TempLanguages from "../../temp-languages";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Cv15GravityStyle } from "./cv15-gravity.style";
import { validateEmptyArray } from "../../../../utils/utility-functions";

// import "./cv15.styles.scss";

const Cv15Gravity = (
  {
    data,
    headingfontsize,
    docReview,
    resumeTemplate,
    resumePersonalInfo,
    paragraphOrdering,
    resumeOnDashboard,
    toggleRenameModal,
    toggleDeleteModal,
    setSelectedRenameSection,
    componentRef,
    fontSize,
    fontFamily,
  },
  ref
) => {
  const { reviewDoc } = data;
  const [docSectionDetail, setDocSectionDetail] = useState(reviewDoc?.paragraphs || []);

  const SelectiveSection = {
    HEAD: TempHeader,
    SUMM: TempSummary,
    EXPR: TempExperience,
    SKLS: TempSkills,
    EDUC: TempEducation,
    CSTM: TempCustom,
    LANG: TempLanguages,
    OTHR: TempCustom,
  };
  //const [characters, updateCharacters] = useState(docSectionDetail);
  let filterData = data.filter((x) => x.paraCD != "NONE");

  useEffect(() => {
    if (!!data && data.length > 0) {
      let filterData = data.filter((x) => x.paraCD != "NONE");
      updateCharacters(filterData);
    }
  }, [JSON.stringify(data)]);

  const [characters, updateCharacters] = useState(filterData);

  const charactersTrippleAside = characters?.filter((item) => ["HEAD", "SKLS", "LANG"].includes(item.paraCD));
  const charactersTrippleMain = characters?.filter((item) => !["HEAD", "SKLS", "LANG"].includes(item.paraCD));
  const charactersHead = characters?.filter((item) => ["HEAD"].includes(item.paraCD));

  const handleOnDragEndTripleAside = (result) => {
    if (!result.destination) return;
    const asideItems = Array.from(characters?.filter((item) => ["HEAD", "SKLS", "LANG"].includes(item.paraCD)));
    const head = Array.from(characters?.filter((item) => ["HEAD"].includes(item.paraCD)));
    const [reorderedItems] = asideItems.splice(result.source.index, 1);
    asideItems.splice(result.destination.index, 0, reorderedItems);
    let list = [];
    let dOrder = 4;
    asideItems.map((attr) => {
      list.push({
        order: dOrder,
        paraid: attr.id,
      });
      dOrder++;
    });
    head.map((attr) => {
      list.push({
        order: 0,
        paraid: attr.id,
      });
    });

    updateCharacters(filterData);
    paragraphOrdering(JSON.stringify(list));
  };

  const handleOnDragEndTripleMain = (result) => {
    if (!result.destination) return;

    const mainItems = Array.from(characters?.filter((item) => !["HEAD", "SKLS", "LANG"].includes(item.paraCD)));

    const head = Array.from(characters?.filter((item) => ["HEAD"].includes(item.paraCD)));
    const [reorderedItem] = mainItems.splice(result.source.index, 1);
    mainItems.splice(result.destination.index, 0, reorderedItem);
    let list = [];
    let iOrder = 1;
    mainItems.map((attr) => {
      list.push({
        order: iOrder,
        paraid: attr.id,
      });
      iOrder++;
    });

    head.map((attr) => {
      list.push({
        order: 0,
        paraid: attr.id,
      });
    });
    updateCharacters(filterData);
    paragraphOrdering(JSON.stringify(list));
  };

  // Tripple
  // const [charactersTrippleAside, updateCharactersTrippleAside] = useState(
  //   characters?.filter((item) => ["HEAD", "SKLS", "LANG"].includes(item.paraCD))
  // );
  // const [charactersTrippleMain, updateCharactersTrippleMain] = useState(
  //   characters?.filter(
  //     (item) => !["HEAD", "SKLS", "LANG"].includes(item.paraCD)
  //   )
  // );
  // const handleOnDragTrippleAside = (result) => {
  //   if (!result.destination) return;
  //   const items = Array.from(charactersTrippleAside);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   updateCharactersTrippleAside(items);
  // };
  // const handleOnDragTrippleMain = (result) => {
  //   if (!result.destination) return;
  //   const items = Array.from(charactersTrippleMain);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   updateCharactersTrippleMain(items);
  // };

  return (
    <>
      <div className="resume-wrapper" ref={componentRef}>
        <Cv15GravityStyle />
        <div className="resume">
          <div
            className="resume-layout cv15 tripple left"
            style={{
              fontFamily: fontFamily,
              fontSize: fontSize,
            }}
          >
            <div className="tripple-column resume-layout-box">
              <div className="resume-header">
                <TempHeader
                  headingfontsize={headingfontsize}
                  templateType={resumeTemplate}
                  resumePersonalInfo={resumePersonalInfo}
                  data={validateEmptyArray(charactersHead) ? charactersHead[0].paraContents : []}
                  section={"HEAD"}
                />
              </div>
              <div className="resume-body">
                <DragDropContext onDragEnd={handleOnDragEndTripleAside}>
                  <Droppable droppableId="charactersTrippleAside" isDropDisabled={resumeOnDashboard}>
                    {(provided) => (
                      <div className="resume-aside" {...provided.droppableProps} ref={provided.innerRef}>
                        {charactersTrippleAside?.map((item, index) => {
                          if (item.paraContents && item.paraContents.length > 0) {
                            const Section = SelectiveSection[item.paraCD];
                            {
                              Object.assign(item.paraContents[0], {
                                headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                fontFamily: fontFamily,
                              });
                            }
                            return (
                              <Draggable
                                key={item.paraCD + index}
                                draggableId={item.paraCD + index}
                                index={index}
                                isDragDisabled={item.paraCD === "HEAD" || resumeOnDashboard}
                              >
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps}>
                                    <Section
                                      // key={item.paraCD + index}
                                      headingfontsize={headingfontsize}
                                      fontFamily={fontFamily}
                                      templateType={resumeTemplate}
                                      data={item.paraContents}
                                      section={item.paraCD}
                                      resumePersonalInfo={resumePersonalInfo}
                                      toggleRenameModal={toggleRenameModal}
                                      toggleDeleteModal={toggleDeleteModal}
                                      setSelectedRenameSection={setSelectedRenameSection}
                                      paraName={item.paraName}
                                      docGuid={item.docGuid}
                                      paraId={item.id}
                                      dragHandleProps={provided.dragHandleProps}
                                      dragStart={provided.draggableProps}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <DragDropContext onDragEnd={handleOnDragEndTripleMain}>
                  <Droppable droppableId="charactersTrippleMain" isDropDisabled={resumeOnDashboard}>
                    {(provided) => (
                      <div className="resume-main" {...provided.droppableProps} ref={provided.innerRef}>
                        {charactersTrippleMain?.map((item, index) => {
                          if (item.paraContents && item.paraContents.length > 0) {
                            const Section = SelectiveSection[item.paraCD];
                            {
                              Object.assign(item.paraContents[0], {
                                headingfontsize: headingfontsize + (headingfontsize ? "em" : ""),
                                templateType: resumeTemplate,
                                fontFamily: fontFamily,
                              });
                            }
                            return (
                              <Draggable
                                key={item.paraCD + index}
                                draggableId={item.paraCD + index}
                                index={index}
                                isDragDisabled={resumeOnDashboard}
                              >
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps}>
                                    <Section
                                      // key={item.paraCD + index}
                                      headingfontsize={headingfontsize}
                                      fontFamily={fontFamily}
                                      templateType={resumeTemplate}
                                      data={item.paraContents}
                                      section={item.paraCD}
                                      paraName={item.paraName}
                                      docGuid={item.docGuid}
                                      paraId={item.id}
                                      toggleRenameModal={toggleRenameModal}
                                      toggleDeleteModal={toggleDeleteModal}
                                      setSelectedRenameSection={setSelectedRenameSection}
                                      dragHandleProps={provided.dragHandleProps}
                                      dragStart={provided.draggableProps}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cv15Gravity;
