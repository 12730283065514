import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtherSection from "./component";
import { createParagraph, updateOtherSectionParagraph } from "./actions";

const mapStateToProps = ({ othersection, funnelStep, docReview }) => ({
  othersection,
  funnelStep,
  docReview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      otherSectionConnect: createParagraph,
      updateOtherSectionConnect: updateOtherSectionParagraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OtherSection);
