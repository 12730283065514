import Types from "./types";
import appLogger from "../../utils/app-logger";
import UserServiceApi from "../../services/user-service-api";
import OrchestrationServiceAPI from "../../services/orchestration-service-api";
import DocBuilderService from "../../services/doc-builder-api";
import DashboardOrchService from "../../services/dashboard-orchestration-service-api";

const updatePassword = (data) => {
  return function (dispatch) {
    return UserServiceApi.updatePassword(data)
      .then((response) => {
        if (response) {
          appLogger.log("inside update password");
          //dispatch(savePersonalInfoSuccess(response.data.docGuid));
        } else {
          //dispatch(savePersonalInfoFailure(response.data));
        }
        return response;
      })
      .catch((error) => console.log("Error"));
    // .catch((error) => dispatch(savePersonalInfoFailure(error)));
  };
};
const getUserProfile = (userId) => {
  return function (dispatch) {
    return UserServiceApi.getUserProfile(userId).then((response) => {
      appLogger.log("inside update docstyle");
      return response;
    });
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const getBillingInfo = (userId, locale) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.getBillingInfo(userId, locale).then(
      (response) => {
        appLogger.log("inside update docstyle....");
        return response;
      }
    );
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

const cancelUserByUserGuid = (data, userId) => {
  return function (dispatch) {
    return OrchestrationServiceAPI.cancelUserByUserGuid(data, userId).then(
      (response) => {
        appLogger.log("inside cancelUserByUserGuid");
        return response;
      }
    );
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};
const getUserDocs = (userguid) => {
  return function () {
    return DocBuilderService.findByUserGuid(userguid)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
};

const restartSubscription = (data, userGUID) => {
  return function () {
    return OrchestrationServiceAPI.restartSubscription(data, userGUID)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
};

const getPSSSBillingInfo = (userId, locale) => {
  return function (dispatch) {
    return DashboardOrchService.getPSSSBillingInfo(userId, locale).then(
      (response) => {
        appLogger.log("inside DashboardOrchService....");
        return response;
      }
    );
    //.catch((error) => dispatch(deleteFailure(error)));
  };
};

export {
  updatePassword,
  getUserProfile,
  getBillingInfo,
  cancelUserByUserGuid,
  getUserDocs,
  restartSubscription,
  getPSSSBillingInfo,
};
