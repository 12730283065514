import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  paragraph: "",
};

export const saveDesiredCompanyInfo = (state = INITIAL_STATE, headerdata) => {
  return { ...state, paragraph: headerdata.data };
};
export const updateDesiredCompanyInfo = (state = INITIAL_STATE, headerdata) => {
  const { data } = headerdata;
  return { ...state, paragraph: data };
};
export const resetDesiredCompanyInfo = () => {
  return { ...INITIAL_STATE };
};

export const HANDLERS = {
  [Types.SAVE_SUCCESS]: saveDesiredCompanyInfo,
  [Types.UPDATE_SUCCESS]: updateDesiredCompanyInfo,
  [Types.RESET_DESIRED_COMPANY]: resetDesiredCompanyInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
