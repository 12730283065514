import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./personal-info.scss";
import Button from "../../components/shared/button";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import appLogger from "../../utils/app-logger";
import InputText from "../../components/shared/input-text/component";
import HeaderSteps from "../../components/header-steps";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import RBDropDown from "../../components/shared/dropdown/component";
import { emptyDocument, createParagraph } from "../../initialState";
import ReactLoader from "../../components/shared/loader";
import ResumeMap from "../../components/resume-map";
import { getTenantId, blobSignature, imageBlobSignature } from "../../utils/general-config";
import moment from "moment";
import axios from "axios";
import { validateEmail, validateFirstName, ValidateNumber } from "../../validators/genericValidator";
import { SECTION_TYPE, SECTION_ORDER, SECTION_CODE, EMPTY_GUID, photoTemplates } from "../../utils/constants";
import PathHelper from "../../utils/path-helper";
import { APP_NAVIGATION } from "../../utils/constants";
import { getPortalCD, readCookie } from "../../utils/helpers";
import { Helmet } from "react-helmet";
import { getListOfStates } from "../../services/master-data";
import { trackRBEvents } from "../../utils/tracking-events";
import ImageUploadModal from "../../components/image-upload-modal";
import Spinner from "react-bootstrap/Spinner";
import ErrorModal from "../../components/ErrorModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const PersonalInfo = ({
  savePersonalInfoConnect,
  updatePersonalInfoConnect,
  funnelStep,
  SaveDocGUIDConnect,
  personalInfo,
  setCurrentStepConnect,
  setNameConnect,
  resetFolderQueryStringInfoConnect,
  imageUploadPreview,
  createPersonalInfoParagraphConnect,
  setImageUrlConnect,
}) => {
  const { locale } = useParams();
  const location = useLocation();
  let visitorGuid = readCookie("visitorGuid");
  let countries = require("i18n-iso-mycountries");
  const queryParams = new URLSearchParams(window.location.search);
  const qParams = new URLSearchParams();
  for (const [name, value] of queryParams) {
    qParams.append(name.toLowerCase(), value);
  }
  countries.registerLocale(require("i18n-iso-mycountries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/fr.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/es.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/it.json"));
  countries.registerLocale(require("i18n-iso-mycountries/langs/ar.json"));

  const content =
    !!personalInfo &&
    !!personalInfo.paragraph &&
    !!personalInfo.paragraph.paraContents &&
    !!personalInfo.paragraph.paraContents[0].content
      ? JSON.parse(personalInfo.paragraph.paraContents[0].content)
      : {};
  const [countryOption, setCountryOption] = useState([
    !!content && content.country !== undefined ? [content.country] : [],
  ]);
  const { templateCd, docGUID, userGUID, funnelStepsComplete, email, personalInfoName } = funnelStep || {};

  const { imageUrl } = imageUploadPreview || {};

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [docGuid, setDocId] = useState({ docGuid: "" });
  const [listOfState, setListOfState] = useState(getListOfStates());
  const [firstName, setFirstName] = useState(!!content && !!content.firstName ? content.firstName : "");

  const [lastName, setLastName] = useState(!!content && !!content.lastName ? content.lastName : "");
  const [address, setAddress] = useState(!!content && !!content.address ? content.address : "");
  const [city, setCity] = useState(!!content && !!content.city ? content.city : "");
  const [state, setState] = useState(!!content && !!content.state ? content.state : "");
  const [country, setCountry] = useState(!!content && !!content.country ? content.country : "");
  const [zipCode, setZipCode] = useState(!!content && !!content.zipCode ? content.zipCode : "");
  const [emailAddress, setEmailAddress] = useState(!!content && !!content.emailAddress ? content.emailAddress : "");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState("d-none");
  const [showStickyBar, setShowStickyBar] = useState(true);
  const [imageUpload, setImageUpload] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [profilePicUrl, setprofilePicUrl] = useState(
    !!content && !!content.profilePicUrl ? content.profilePicUrl : imageUrl
  );

  const imageUploadModalHide = () => {
    setImageUpload(false);
  };
  const imageUploadModalShow = () => {
    setImageUpload(true);
  };

  const [errorModal, setErrorModal] = useState(false);
  const errorModalShow = () => {
    setErrorModal((prev) => !prev);
  };

  const refreshDataCallback = () => {
    setFirstName(!!content && !!content.firstName ? content.firstName : "");
    setLastName(!!content && !!content.lastName ? content.lastName : "");
    setAddress(!!content && !!content.address ? content.address : "");
    setCity(!!content && !!content.city ? content.city : "");
    setState(!!content && !!content.state ? content.state : "");
    setCountry(!!content && !!content.country ? content.country : "");
    setZipCode(!!content && !!content.zipCode ? content.zipCode : "");
    setEmailAddress(!!content && !!content.emailAddress ? content.emailAddress : "");
    setPhone(!!content && content.phone !== undefined ? content.phone : "");
    setErrorModal((prev) => !prev);
  };
  // on selecting file we set load the image on to cropper

  const [phone, setPhone] = useState(!!content && content.phone !== undefined ? content.phone : "");

  const sanitizer = dompurify.sanitize;

  const onfirstNameChange = (e) => {
    const {
      target: { value },
    } = e;
    setFirstName(value);
  };
  const onlastNameChange = (e) => {
    const {
      target: { value },
    } = e;
    setLastName(value);
  };
  const onemailAddressChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmailAddress(value);
  };

  const onPhoneChange = (e) => {
    const {
      target: { value },
    } = e;
    setPhone(value);
  };
  const onAddressChange = (e) => {
    const {
      target: { value },
    } = e;
    setAddress(value);
  };
  const onCityChange = (e) => {
    const {
      target: { value },
    } = e;
    setCity(value);
  };
  const onStateChange = (i, e) => {
    const {
      target: { value },
    } = e;
    setState(value);
  };
  const onZipCodeChange = (e) => {
    const {
      target: { value },
    } = e;
    setZipCode(value);
  };
  const countryCode = async () => {
    const res = await axios.get("https://pro.ip-api.com/json?key=qGpUO64dv9znGra&fields=countryCode");

    const countryCode = res.data["countryCode"];
    if (countryCode !== "US") {
      setState("");
    } else {
      if (
        funnelStep?.queryStringsInfo?.state &&
        funnelStep?.queryStringsInfo?.state.replace(/[^A-Za-z]/gi, "").length === 2
      ) {
        setState(funnelStep?.queryStringsInfo?.state.toUpperCase());
      } else {
        setState("AL");
      }
    }
    setCountry(countryCode);
  };

  useEffect(() => {
    trackRBEvents("personalInfoView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
  }, []);
  useEffect(() => {
    async function fetcCounty() {
      const response = await countries.getNames(!!locale ? locale : "en");
      if (!!response) {
        var countryOptions = Object.entries(response).map((item) => {
          return {
            label: item[1],
            value: item[0],
          };
        });
        countryOptions.splice(0, 0, {
          label: t("personal_info.select"),
          value: "",
        });
        setCountryOption(countryOptions.sort((a, b) => a.label.localeCompare(b.label)));
      }
    }
    fetcCounty();
    if (!!content.country == false) {
      countryCode();
    }
    setCurrentStepConnect(location.pathname);
    if (funnelStep?.queryStringsInfo?.fname) {
      setFirstName(funnelStep?.queryStringsInfo?.fname);
    }
    if (funnelStep?.queryStringsInfo?.lname) {
      setLastName(funnelStep?.queryStringsInfo?.lname);
    }
    if (funnelStep?.queryStringsInfo?.zip) {
      setZipCode(funnelStep?.queryStringsInfo?.zip);
    }
    if (funnelStep?.queryStringsInfo?.city) {
      setCity(funnelStep?.queryStringsInfo?.city);
    }
    if (funnelStep?.queryStringsInfo?.email) {
      setEmailAddress(funnelStep?.queryStringsInfo?.email);
    }
    if (funnelStep?.queryStringsInfo?.prtlsrc) {
      localStorage.setItem("qsprtlsrc", funnelStep?.queryStringsInfo?.prtlsrc); //qsprtlsrc = Querystring Portal source
    }
  }, []);
  const countryChangeHandler = (i, e) => {
    const {
      target: { value },
    } = e;
    if (value !== "US") {
      setState("");
    } else {
      if (
        funnelStep?.queryStringsInfo?.state &&
        funnelStep?.queryStringsInfo?.state?.replace(/[^A-Za-z]/gi, "").length === 2
      ) {
        setState(funnelStep?.queryStringsInfo?.state?.toUpperCase());
      } else {
        setState("AL");
      }
    }
    setCountry(value);
  };
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };

  const buttonClickHandler = () => {
    setShowLoader("d-block");
    !!docGUID ? updatePersonalInfoClickHandler() : savePersonalInfoClickHandler();
  };

  const updatePersonalInfoClickHandler = () => {
    setNameConnect(firstName + " " + lastName);
    var head = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      emailAddress: emailAddress,
      phone: phone,
      city: city,
      country: country,
      state: state,
      zipCode: zipCode,
      labelPhone: t("personal_info.label_phone"),
      labelEmail: t("personal_info.label_email"),
      labelAddress: t("personal_info.label_address"),
      profilePicUrl: imageUploadPreview.imageUrl !== "" ? imageUploadPreview.imageUrl : profilePicUrl,
    };
    let personalInfoOrder =
      !!personalInfo && !!personalInfo.paragraph && !!personalInfo.paragraph.order && personalInfo.paragraph.order != 0
        ? personalInfo.paragraph.order
        : SECTION_ORDER.HEAD;

    if (!!personalInfo.paragraph) {
      var data = createParagraph(
        docGUID,
        personalInfo.paragraph?.id,
        personalInfo.paragraph?.paraContents[0].id,
        JSON.stringify(head),
        personalInfoOrder,
        SECTION_TYPE.HEAD,
        SECTION_CODE.HEAD,
        t("personal_info.para_name")
      );
      setImageUrlConnect(head.profilePicUrl);
      updatePersonalInfoConnect(docGUID, personalInfo.paragraph?.id, data)
        .then((response) => {
          if (!!response) {
            if (response.error) {
              setErrorModal(true);
              setShowLoader("d-none");
            } else {
              navigate(pathRedirect.next);
              setShowLoader("d-none");
            }
          } else {
            setErrorModal(true);
            setShowLoader("d-none");
          }
        })
        .catch((err) => {
          setErrorModal(true);
          setShowLoader("d-none");
          // para error
        });
    } else {
      let para = createParagraph(
        docGUID,
        EMPTY_GUID,
        EMPTY_GUID,
        JSON.stringify(head),
        SECTION_ORDER.HEAD,
        SECTION_TYPE.HEAD,
        SECTION_CODE.HEAD,
        t("personal_info.para_name")
      );
      createPersonalInfoParagraphConnect(docGUID, para)
        .then((paraResponse) => {
          if (!!paraResponse) {
            if (paraResponse.error) {
              setErrorModal(true);
              setShowLoader("d-none");
            } else {
              navigate(pathRedirect.next);
              setShowLoader("d-none");
            }
          } else {
            setErrorModal(true);
            setShowLoader("d-none");
          }
        })
        .catch((paraError) => {
          setErrorModal(true);
          setShowLoader("d-none");
        });
    }
  };

  const savePersonalInfoClickHandler = () => {
    appLogger.log("savePersonalInfoClickHandler");
    setNameConnect(firstName + " " + lastName);
    var head = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      emailAddress: emailAddress,
      phone: phone,
      city: city,
      country: country,
      state: state,
      zipCode: zipCode,
      labelPhone: t("personal_info.label_phone"),
      labelEmail: t("personal_info.label_email"),
      labelAddress: t("personal_info.label_address"),
      profilePicUrl: imageUploadPreview.imageUrl,
    };
    var data = emptyDocument(
      "Resume " + moment().format("MM/DD/YY"),
      JSON.stringify({}),
      templateCd,
      userGUID,
      getTenantId(),
      visitorGuid,
      funnelStep?.queryStringsInfo?.fp ? funnelStep?.queryStringsInfo?.fp : null
    );
    savePersonalInfoConnect(data)
      .then((response) => {
        if (!!response && !response.error) {
          SaveDocGUIDConnect(response.docGuid);
          resetFolderQueryStringInfoConnect();
          let para = createParagraph(
            response.docGuid,
            EMPTY_GUID,
            EMPTY_GUID,
            JSON.stringify(head),
            SECTION_ORDER.HEAD,
            SECTION_TYPE.HEAD,
            SECTION_CODE.HEAD,
            t("personal_info.para_name")
          );
          createPersonalInfoParagraphConnect(response.docGuid, para)
            .then((paraResponse) => {
              if (!!paraResponse) {
                if (paraResponse.error) {
                  setErrorModal(true);
                  setShowLoader("d-none");
                } else {
                  setShowLoader("d-none");
                  navigate(pathRedirect.next);
                }
              } else {
                setErrorModal(true);
                setShowLoader("d-none");
              }
            })
            .catch((paraError) => {
              setErrorModal(true);
              setShowLoader("d-none");
              // para error
            });
        } else {
          setErrorModal(true);
          setShowLoader("d-none");
        }
      })
      .catch((err) => {
        setErrorModal(true);
        setShowLoader("d-none");
      });
  };

  const validateData = () => {
    setFirstNameError(false);
    setEmailError(false);
    setPhoneError(false);
    let errors = 0;
    let firstNameValidator = validateFirstName(firstName);

    let emailValidator = validateEmail(emailAddress);
    let phoneValidator = ValidateNumber(phone);
    if (phoneValidator.isValid === false) {
      ++errors;
      setPhoneError(true);
      setPhoneErrorMsg(phoneValidator.errorMessage);
    }
    if (emailValidator.isValid === false) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg(emailValidator.errorMessage);
    }

    if (firstNameValidator.isValid === false) {
      ++errors;
      setFirstNameError(true);
      setFirstNameErrorMsg(firstNameValidator.errorMessage);
    }
    if (errors > 0) {
      ++errors;
      return false;
    } else {
      return true;
    }
  };
  const onFocusCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(false);
    }
  };
  const onBlurCallback = (e) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  };
  window.addEventListener("scroll", (event) => {
    if (window.innerWidth < 768) {
      setShowStickyBar(true);
    }
  });
  let pathRedirect = PathHelper(APP_NAVIGATION.HEAD, funnelStepsComplete);

  return (
    <>
      <Helmet>
        <title>{t("page_title.personal_info")}</title>
      </Helmet>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={1} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}

      <ReactLoader showloader={showLoader} />
      <section className={classNames(funnelStepsComplete ? "" : "sidebar-sticky", "section main-content templates")}>
        <div className="container">
          <div className="alignment-container">
            <div className="row headline-group">
              <hgroup>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("personal_info.form_title")),
                  }}
                />
                <h4
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(t("personal_info.form_subtitle")),
                  }}
                />
              </hgroup>
            </div>
            {photoTemplates.includes(templateCd) ? (
              <button onClick={imageUploadModalShow} className="upload-image-btn">
                <span className="photo-placeholder">
                  {profilePicUrl ? (
                    <>
                      <img src={profilePicUrl + imageBlobSignature()} className="square" />
                    </>
                  ) : (
                    <>
                      <img src="/app/static/images/photo-placeholder.jpg" className="square" />
                    </>
                  )}
                </span>
                <span className="btn btn-secondary upload-btn" id="upload_Photo_btn">
                  {imageloader ? (
                    <Spinner animation="border" color="light" />
                  ) : profilePicUrl ? (
                    t("image_upload_modal.change_photo")
                  ) : (
                    t("image_upload_modal.upload_photo")
                  )}
                </span>
              </button>
            ) : (
              <>
                <div className="temp-not-selected">
                  <span className="icon fa fa-lock" aria-hidden="true"></span>
                  <span className="temp-not-selected-text">
                    {t("image_upload_modal.upload_not_supported_template")}
                  </span>
                </div>
              </>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="col-md-12 col-lg-9">
                <div className="row">
                  <div className="form-group string optional heading_name col-sm-6">
                    <label className="string optional" htmlFor="heading_name">
                      {t("personal_info.first_name")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        className="string optional form-control"
                        placeholder={t("personal_info.first_name_placeholder")}
                        type="text"
                        name="heading[name]"
                        id="heading_name"
                        required
                        maxLength="50"
                        value={firstName}
                        onChange={onfirstNameChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                      />
                    </div>
                    <div className="form-text">
                      {firstNameError && (
                        <small key="pswrdErr" className="form-text text-danger">
                          {firstNameErrorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-group string optional heading_last_name col-sm-6">
                    <label className="string optional" htmlFor="heading_last_name">
                      {t("personal_info.last_name")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        className="string optional form-control"
                        placeholder={t("personal_info.last_name_placeholder")}
                        type="text"
                        maxLength="50"
                        name="heading[last_name]"
                        id="heading_last_name"
                        value={lastName}
                        onChange={onlastNameChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group string optional heading_address">
                  <label className="string optional" htmlFor="heading_address">
                    {t("personal_info.address")}
                  </label>
                  <div className="tooltip-wrapper">
                    <InputText
                      data-validate=""
                      classname="string optional form-control"
                      placeholder={t("personal_info.address_placeholder")}
                      type="text"
                      maxLength="100"
                      name="heading[address]"
                      id="heading_address"
                      value={address}
                      onChange={onAddressChange}
                      onFocusCallback={onFocusCallback}
                      onBlurCallback={onBlurCallback}
                    />
                    <div className="tooltip">
                      <i className="fa fa-question-circle tooltip-trigger" aria-hidden="true"></i>
                      <div className="tooltip__content">{t("personal_info.address_tooltip")}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={classNames(
                      country === "US" ? "col-sm-3 " : "col-sm-6 ",
                      "form-group string optional heading_city"
                    )}
                  >
                    <label className="string optional" htmlFor="heading_city">
                      {t("personal_info.city")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string optional form-control"
                        placeholder={t("personal_info.city_placeholder")}
                        type="text"
                        maxLength="20"
                        name="heading[city]"
                        id="heading_city"
                        onChange={onCityChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={city}
                      />
                    </div>
                  </div>
                  {country === "US" && (
                    <div className="form-group state optional heading_state col-sm-3">
                      <label className="state optional" htmlFor="heading_state">
                        {t("personal_info.state")}
                      </label>
                      <div className="tooltip-wrapper">
                        <RBDropDown
                          className="state optional form-control"
                          name="heading[state]"
                          id="heading_state"
                          value={state}
                          options={listOfState.data}
                          selectedValue={state || ""}
                          onChange={onStateChange}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-group country optional heading_country col-sm-3">
                    <label className="country optional" htmlFor="heading_country">
                      {t("personal_info.country")}
                    </label>
                    <div className="tooltip-wrapper">
                      <RBDropDown
                        className="country optional form-control"
                        name="heading[country]"
                        id="heading_country"
                        value={country}
                        options={countryOption}
                        selectedValue={country || ""}
                        onChange={countryChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="form-group string optional heading_zipcode col-sm-3">
                    <label className="string optional" htmlFor="heading_zipcode">
                      {t("personal_info.zip_code")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string optional form-control"
                        placeholder={t("personal_info.zip_code_placeholder")}
                        type="text"
                        name="heading[zipcode]"
                        id="heading_zipcode"
                        onChange={onZipCodeChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={zipCode}
                        maxLength={10}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group email optional heading_email col-sm-6 col-md-6">
                    <label className="email optional" htmlFor="heading_email">
                      {t("personal_info.email_address")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        classname="string email optional form-control"
                        placeholder={t("personal_info.email_address_placeholder")}
                        type="email"
                        name="heading[email]"
                        id="email"
                        maxLength="30"
                        onChange={onemailAddressChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                        value={emailAddress}
                      />
                      <div className="tooltip">
                        <a className="fa fa-question-circle tooltip-trigger"></a>
                        <div className="tooltip__content">{t("personal_info.email_address_tooltip")}</div>
                      </div>
                    </div>
                    <div className="form-text">
                      {emailError && (
                        <small key="pswrdErr" className="form-text text-danger">
                          {emailErrorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-group tel optional heading_phone col-sm-6 col-md-6">
                    <label className="tel optional" htmlFor="heading_phone">
                      {t("personal_info.phone")}
                    </label>
                    <div className="tooltip-wrapper">
                      <InputText
                        data-validate=""
                        className="string tel optional form-control"
                        placeholder={t("personal_info.phone_placeholder")}
                        type="tel"
                        maxLength="15"
                        name="heading[phone]"
                        id="heading_phone"
                        value={phone}
                        onChange={onPhoneChange}
                        onFocusCallback={onFocusCallback}
                        onBlurCallback={onBlurCallback}
                      />
                    </div>
                    <div className="form-text">
                      {phoneError && (
                        <small key="pswrdErr" className="form-text text-danger">
                          {phoneErrorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--info">
                <ResumeMap />
              </div>
            </div>
            <div
              className={
                showStickyBar === true
                  ? "btn-container fbn sticky-bar btn-justify cvltr-footer-btn"
                  : "btn-container fbn sticky-bar btn-justify d-none"
              }
            >
              <Button
                isLoading={false}
                classNames="btn btn-outline-secondary btn-lg"
                id="go_back_btn"
                ctaText={t("personal_info.back_action")}
                onClick={backClickHandler}
              />

              <Button
                isLoading={false}
                disabled={false}
                classNames="btn btn-primary btn-lg submit-on-enter float-end"
                ctaText={t("personal_info.next_action")}
                id="save_next_btn"
                onClick={buttonClickHandler}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ImageUploadModal
        imageUpload={imageUpload}
        imageUploadModalHide={imageUploadModalHide}
        setImageloader={setImageloader}
        setprofilePicUrl={setprofilePicUrl}
      />

      <ErrorModal
        errorModalShow={errorModalShow}
        errorModal={errorModal}
        errorMessage={t("error_modal.warning_message")}
        refreshDataCallback={refreshDataCallback}
      />
    </>
  );
};
export default PersonalInfo;
