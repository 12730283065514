import React, { useEffect } from "react";
import CoverLetterPersonalSection from "../../cover-letter-personal-section";
import CoverLetterRecipientSection from "../../cover-letter-recipient-section";
import CoverLetterOpenerSection from "../../cover-letter-opener-section";
import CoverLetterBodySection from "../../cover-letter-body-section";
import CoverLetterCloserSection from "../../cover-letter-closer-section";
import CoverLetterSignatureSection from "../../cover-letter-signature-section";
import { checkEmpty, concatNotEmptyBy } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import "./cvltr-6.css";
import moment from "moment";

const Cl06Standard = ({ cvltrTemplatePreview, cvltrSignature, orderComponent }) => {
  const { t } = useTranslation();
  let dateFormate = t("opener.date_formate");

  const signatureContent =
    !!cvltrSignature && !!cvltrSignature.data && cvltrSignature.data;
  let key = signatureContent.key;
  let tabValue = signatureContent.tabValue ? signatureContent.tabValue : "";

  const steps =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.steps
      ? cvltrTemplatePreview.steps
      : false;

  const content =
    !!cvltrTemplatePreview && !!cvltrTemplatePreview.templateField
      ? cvltrTemplatePreview.templateField
      : false;

  const fullName =
    !!content && !checkEmpty(content.fullName)
      ? content.fullName
      : "[Your Name]";
  const city = !!content && !checkEmpty(content.city) ? content.city : "";
  const ST = !!content && !checkEmpty(content.state) ? content.state : "";
  const zipCode =
    !!content && !checkEmpty(content.zipCode) ? content.zipCode : "";
  const email =
    !!content && !checkEmpty(content.emailAddress) ? content.emailAddress : "";
  const phone = !!content && !checkEmpty(content.phone) ? content.phone : "";
  const address =
    !!content && !checkEmpty(content.streetAddress)
      ? content.streetAddress
      : "";
  const date = moment().format(dateFormate);
  const Company =
    !!content && !checkEmpty(content.DesiredCompany)
      ? content.DesiredCompany
      : "";
  const companyStreetAddress =
    !!content && !checkEmpty(content.companyStreetAddress)
      ? content.companyStreetAddress
      : "";
  const companyCity =
    !!content && !checkEmpty(content.companyCity) ? content.companyCity : "";
  const companyState =
    !!content && !checkEmpty(content.companyState) ? content.companyState : "";
  const companyZipCode =
    !!content && !checkEmpty(content.companyZipCode)
      ? content.companyZipCode
      : "";

  // opener
  const opener =
    !!content && !checkEmpty(content.openerRecommend)
      ? content.openerRecommend
      : "";
  const jobtitle =
    !!content && !checkEmpty(content.desiredPosition)
      ? content.desiredPosition
      : t("recommend.job_title");
  const experience =
    !!content && !checkEmpty(content.experience)
      ? content.experience
      : t("recommend.exp_10");
  const experience_15 =
    !!content && !checkEmpty(content.experience)
      ? content.experience
      : t("recommend.exp_15");
  const companyName =
    !!content && !checkEmpty(content.DesiredCompany)
      ? content.DesiredCompany
      : t("recommend.company");
  const emailAddress =
    !!content && !checkEmpty(content.emailAddress)
      ? content.emailAddress
      : t("recommend.company");
  const phoneLabel =
    !!content && !checkEmpty(content.phone)
      ? content.phone
      : t("recommend.company");

  // body
  const body =
    !!content && !checkEmpty(content.bodyRecommend)
      ? content.bodyRecommend
      : "";

  // closer
  const closer =
    !!content && !checkEmpty(content.closerRecommend)
      ? content.closerRecommend
      : "";

  //signature
  const name =
    !!content && !checkEmpty(content.signName)
      ? content.signName
      : "[Your Name]";
  const sign =
    !!content && !checkEmpty(content.signature) ? content.signature : "";

  useEffect(() => {
    // opener
    const jobId = document.getElementById("jobTitle");
    const experienceId15 = document.getElementById("experience_15");
    const experienceId10 = document.getElementById("experience_10");
    const companyNameId = document.getElementById("companyName");
    const phoneId = document.getElementById("phone");
    const emailAddressId = document.getElementById("emailAddress");

    if (jobId) {
      jobId.innerText = jobtitle;
    }
    if (experienceId15) {
      experienceId15.innerText = experience_15;
    }
    if (experienceId10) {
      experienceId10.innerText = experience;
    }
    if (companyNameId) {
      companyNameId.innerText = companyName;
    }
    if (phoneId) {
      phoneId.innerText = phoneLabel;
    }
    if (emailAddressId) {
      emailAddressId.innerText = emailAddress;
    }

    // body
    const bodyJobId = document.getElementById("bodyJobTitle");
    const bodyExperienceId15 = document.getElementById("bodyExperience_15");
    const bodyExperienceId10 = document.getElementById("bodyExperience_10");
    const bodyCompanyNameId = document.getElementById("bodyCompanyName");
    const bodyPhoneId = document.getElementById("bodyPhone");
    const bodyEmailAddressId = document.getElementById("bodyEmailAddress");

    if (bodyJobId) {
      bodyJobId.innerText = jobtitle;
    }
    if (bodyExperienceId15) {
      bodyExperienceId15.innerText = experience_15;
    }
    if (bodyExperienceId10) {
      bodyExperienceId10.innerText = experience;
    }
    if (bodyCompanyNameId) {
      bodyCompanyNameId.innerText = companyName;
    }
    if (bodyPhoneId) {
      bodyPhoneId.innerText = phoneLabel;
    }
    if (bodyEmailAddressId) {
      bodyEmailAddressId.innerText = emailAddress;
    }

    // closer
    const closerJobId = document.getElementById("closerJobTitle");
    const closerExperienceId15 = document.getElementById("closerExperience_15");
    const closerExperienceId10 = document.getElementById("closerExperience_10");
    const closerCompanyNameId = document.getElementById("closerCompanyName");
    const closerPhoneId = document.getElementById("closerPhone");
    const closerEmailAddressId = document.getElementById("closerEmailAddress");

    if (closerJobId) {
      closerJobId.innerText = jobtitle;
    }
    if (closerExperienceId15) {
      closerExperienceId15.innerText = experience_15;
    }
    if (closerExperienceId10) {
      closerExperienceId10.innerText = experience;
    }
    if (closerCompanyNameId) {
      closerCompanyNameId.innerText = companyName;
    }
    if (closerPhoneId) {
      closerPhoneId.innerText = phoneLabel;
    }
    if (closerEmailAddressId) {
      closerEmailAddressId.innerText = emailAddress;
    }
  }, [cvltrTemplatePreview]);

  return (
    <>
      {/* <div className="cvltr-preview">
      <div className="cvltr-layout cvltr-6">
        {steps.personalInfo && <CoverLetterPersonalSection />}
        {steps.recipient && <CoverLetterRecipientSection />}
        {steps.opener && <CoverLetterOpenerSection />}
        {steps.body && <CoverLetterBodySection />}
        {steps.closer && <CoverLetterCloserSection />}
        {steps.signature && <CoverLetterSignatureSection />}
      </div>
    </div> */}

      <div className="cvltr-preview">
        <div className="cvltr-layout cvltr-6">
          {steps.personalInfo && (
            <div className="cvltr-headingname cvltr-dother-none">
              <h1>{fullName}</h1>
            </div>
          )}
          <div className="cvltr-single cvltr-twoside">
            {/* personalInfo section */}
            {steps.personalInfo && (
              <div className="cvltr__section cvltr__personal cvltr-tablewidth cvltr-order2">
                <div className="cvltr__content">
                  <div className="cvltr-headinginfo cvltr-mb30 cvltr-dotted-border cvltr-solid-border">
                    <div className="cvltr-headingname cvltr-side-none">
                      <h1>{fullName}</h1>
                    </div>
                    <div className="cvltr-dother-none">
                      <h2>Personal Info</h2>
                    </div>
                    <div className="cvltr-information cvltr-graybackground">
                      <p>
                        <b>INFO</b>
                      </p>
                    </div>
                    <div className="cvltr-personalinfo cvltr-justify cvltr-display-flex cvltr-justifycenter cvltr-info cvltr-order-property">
                      <div className="cvltr-d-flex cvltr-order-2">
                        <div className="cvltr-img">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3209 3L14.8209 21C14.777 21.0957 14.7066 21.1769 14.6179 21.2338C14.5293 21.2906 14.4262 21.3209 14.3209 21.3209C14.2156 21.3209 14.1125 21.2906 14.0238 21.2338C13.9352 21.1769 13.8647 21.0957 13.8209 21L10.3209 14L3.32087 10.5C3.22513 10.4561 3.144 10.3857 3.08712 10.2971C3.03024 10.2084 3 10.1053 3 10C3 9.89468 3.03024 9.79158 3.08712 9.70295C3.144 9.61431 3.22513 9.54387 3.32087 9.5L21.3209 3Z"
                              fill="#3BA1E3"
                            />
                          </svg>
                        </div>
                        <div className="cvltr-d-flex">
                          <p className="cvltr-mb0 cvltr-mb-0">
                            <span className="cvltr-lable">
                              <b>Address:</b>
                            </span>{" "}
                            {address}
                          </p>
                          <p className="cvltr-mb0 cvltr-mt0">
                            {concatNotEmptyBy(", ")(city, ST, zipCode)}
                          </p>
                        </div>
                      </div>
                      <div className="cvltr-d-flex">
                        <div className="cvltr-img">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.9664 6.8834C20.8882 6.36072 20.6254 5.88335 20.2255 5.53783C19.8256 5.19231 19.3151 5.0015 18.7866 5H5.2134C4.68491 5.0015 4.17445 5.19231 3.77455 5.53783C3.37465 5.88335 3.11178 6.36072 3.0336 6.8834L12 12.6854L20.9664 6.8834Z"
                              fill="#3BA1E3"
                            />
                            <path
                              d="M12.3258 13.904C12.2287 13.9668 12.1156 14.0002 12 14.0002C11.8844 14.0002 11.7713 13.9668 11.6742 13.904L3 8.29159V17.1866C3.00064 17.7734 3.23404 18.3361 3.64899 18.751C4.06395 19.166 4.62656 19.3994 5.2134 19.4H18.7866C19.3734 19.3994 19.9361 19.166 20.351 18.751C20.766 18.3361 20.9994 17.7734 21 17.1866V8.29099L12.3258 13.904Z"
                              fill="#3BA1E3"
                            />
                          </svg>
                        </div>
                        <div className="cvltr-emaildisplay">
                          <p>{email}</p>
                        </div>
                      </div>
                      <div className="cvltr-d-flex">
                        <div className="cvltr-img">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 3H10L12 8L9.5 9.5C10.571 11.6715 12.3285 13.429 14.5 14.5L16 12L21 14V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20C15.0993 19.763 11.4202 18.1065 8.65683 15.3432C5.8935 12.5798 4.23705 8.90074 4 5C4 4.46957 4.21071 3.96086 4.58579 3.58579C4.96086 3.21071 5.46957 3 6 3Z"
                              fill="#3BA1E3"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="cvltr-mb0">
                            <span className="cvltr-lable">
                              <b>Phone:</b>
                            </span>
                            {phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="cvltr-d-flex cvltr-justifycenter cvltr-emailnone">
                      <div className="cvltr-mail-spacing">
                        <p>
                          <span className="cvltr-lable">
                            <b>Email:</b>
                          </span>
                          {email}
                        </p>
                      </div>
                    </div>
                    <div className="cvltr-subheading cvltr-graybackground">
                      <h2>COVER LETTER</h2>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="cvltr-tdleftspacing cvltr-tdrightspacing">
              {steps.recipient && (
                <div className="cvltr__section cvltr__recipient">
                  <div className="cvltr__content">
                    <p className="cvltr-mt0">
                      <b>{date}</b>
                    </p>
                    <div className="cvltr-companyinfo">
                      <p>{t("opener.recipient_name")}</p>
                      <p>{Company}</p>
                      <p>{companyStreetAddress}</p>
                      <p>{`${companyCity}, ${companyState} ${companyZipCode}`}</p>
                    </div>
                    <p className="cvltr-mt30">
                      <b>{t("opener.greeting_to_recipient_name")}:</b>
                    </p>
                  </div>
                </div>
              )}

              {
                orderComponent && orderComponent.length > 0 && orderComponent.map((item) => {
                  return (
                    <>
                      {item === t("opener.opener_recommend") && steps.opener && (
                        <div className="cvltr__section cvltr__opener">
                          <div className="cvltr__content">
                            <p
                              className="cvltr-mb30"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(opener),
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {item === t("opener.body_recommend") && steps.body && (
                        <div className="cvltr__section cvltr__body">
                          <div className="cvltr__content">
                            <p
                              className="cvltr-mb30"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(body),
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {item === t("opener.closer_recommend") && steps.closer && (
                        <div className="cvltr__section cvltr__closer">
                          <div className="cvltr__content">
                            <div className="cvltr-dotted-border">
                              <p
                                className="cvltr-mb30"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(closer),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                })}
              {steps.signature && (
                <div className="cvltr__section cvltr__signature">
                  <div className="cvltr__content">
                    <p className="cvltr-mt30 cvltr-mb30">
                      <b>Sincerely,</b>
                    </p>
                    <p className="cvltr-mb30">
                      {key == t("signature.type") ? (
                        <label className="form-check-label d-flex justify-content-between">
                          <span
                            className={`${tabValue.font} font-24 p-3`}
                            style={{
                              color: tabValue.color,
                            }}
                          >
                            {name}
                          </span>
                        </label>
                      ) : (
                        <b>
                          {sign !== "" && <img src={sign} className="sign-img" />}
                        </b>
                      )}
                      {sign && sign == "" && <b>[Signature]</b>}
                    </p>
                    <p>
                      <b>{fullName}</b>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cl06Standard;
