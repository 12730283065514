import React from "react";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import "./cover-letter-action.scss";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { availableLanguages } from "../../i18nextInit";
import { getTenantId } from "../../utils/general-config";
import { readCookie } from "../../utils/helpers";

const CoverLetterAction = ({
  selectedCoverLetter,
  numberOfCoverLetters,
  resetStoreConnect,
  duplicateCoverLetterConnect,
  callbackduplicate,
  deleteCoverLetterConnect,
  callbackdelete,
  toggleRenameModal,
  downloadCoverLetterModal,
  printCoverLetterCallBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const setLocaleURL = (url) => {
    let verifiedLocale = "en";
    if (!!locale) {
      if (availableLanguages.indexOf(locale) > -1) {
        verifiedLocale = locale;
      }
    }
    return url.replace(":locale", verifiedLocale);
  };
  const nextPage = (coverLetterAction) => {
    if (coverLetterAction === "CreateNew") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.INTRO_PAGE_LOCALE) : ROUTE_CONSTANT.INTRO_PAGE;
    } else if (coverLetterAction === "Edit") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.CVLTR_REVIEW_LOCAL) : ROUTE_CONSTANT.CVLTR_REVIEW;
    } else if (coverLetterAction === "Delete") {
      return !!locale ? setLocaleURL(ROUTE_CONSTANT.HOME_LOCALE) : ROUTE_CONSTANT.HOME;
    }
  };
  const editCoverLetterHandler = () => {
    navigate(nextPage("Edit"));
  };
  const duplicateCoverLetterHandler = () => {
    let dupDocInfo = {
      visitorId: readCookie("visitorGuid"),
      tenantId: getTenantId(),
    };
    duplicateCoverLetterConnect(selectedCoverLetter.id, dupDocInfo).then((response) => {
      if (!!response) {
        callbackduplicate(selectedCoverLetter.name, response);
      }
      window.scrollTo(0, 0);
    });
  };
  const deleteCoverLetterHandler = () => {
    if (window.confirm(t("cover_letters.alert_message"))) {
      deleteCoverLetterConnect(selectedCoverLetter.id).then((response) => {
        callbackdelete(selectedCoverLetter.name);
        window.scrollTo(0, 0);
        if (numberOfCoverLetters === 1) {
          resetStoreConnect();
          navigate(nextPage("Delete"));
        }
      });
    }
  };
  const printCoverLetterHandler = (e) => {
    const {
      target: { id },
    } = e;

    printCoverLetterCallBack(id);
  };
  const createCoverLetterHandler = () => {
    resetStoreConnect();
    navigate(nextPage("CreateNew"));
  };
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const returnFormattedDate = (dateValue) => {
    let dateObj = new Date(dateValue);
    let localeValue = "en-US";
    if (!!locale) {
      localeValue = locale;
    }
    return dateObj.toLocaleDateString(localeValue, options);
  };
  return (
    <>
      <span className="dashboard-item__title">
        {selectedCoverLetter.name}
        <Button
          id="cover-letter-rename-btn"
          isLoading={false}
          disabled={false}
          onClick={toggleRenameModal}
          icon={<i className="fa fa-pencil"></i>}
          classNames="muted"
          ctaText=""
        />
      </span>
      <span className="dashboard-item__edited">
        {t("cover_letters.last_edited_on")} {returnFormattedDate(selectedCoverLetter.modifiedOn)}
      </span>
      <div className="dashboard-item__actions">
        <Button
          id="cover-letter-edit-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-pencil"></i>}
          onClick={editCoverLetterHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("cover_letters.edit_cover_letter")}
        />
        <Button
          id="cover-letter-duplicate-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-copy"></i>}
          onClick={duplicateCoverLetterHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("cover_letters.duplicate_cover_letter")}
        />
        <Button
          id="cover-letter-delete-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-trash"></i>}
          onClick={deleteCoverLetterHandler}
          classNames="btn btn--icon btn-primary-light btn-lg add-section-btns"
          ctaText={t("cover_letters.delete_cover_letter")}
        />
      </div>
      <div className="dashboard-item__actions">
        <Button
          id="cover-letter-download-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-download"></i>}
          onClick={downloadCoverLetterModal}
          classNames="btn btn--icon btn-green btn-lg "
          ctaText={t("cover_letters.download_cover_letter")}
        />
        <Button
          id="cover-letter-print-btn"
          isLoading={false}
          disabled={false}
          icon={<i className="fa fa-print"></i>}
          onClick={printCoverLetterHandler}
          classNames="btn btn--icon btn-green btn-lg"
          ctaText={t("cover_letters.print_cover_letter")}
        />
      </div>
      <Button
        isLoading={false}
        disabled={false}
        id="create-new-cover-ltr-btn"
        onClick={createCoverLetterHandler}
        classNames="btn btn-primary btn-lg create-cover-letter"
        ctaText={t("cover_letters.create_cover_letter")}
      />
    </>
  );
};

export default CoverLetterAction;
