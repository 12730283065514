import axios from "axios";
import { apiUrl, apiAuthKey } from "../utils/url-constants";
import { ERROR_CODES } from "./api-constants";

const DEBUG = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";
const BASE_API_URL = apiUrl();
/* Create axios instance */
const baseApi = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

baseApi.interceptors.request.use(
  (config) => {
    config.headers["api-auth-key"] = apiAuthKey();
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
      console.info("✉️ ", config);
    }
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
baseApi.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.info("📩 ", response);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (DEBUG) {
        console.error("Error: ", error.response.data);
      }
      if (error.response.status === ERROR_CODES.UNAUTHORISED && !window.location.pathname.includes("login")) {
        localStorage.clear();
        window.location = `${window.origin}/login`;
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error("📩 ", error);
    }
    return Promise.reject(error.message);
  }
);

export default baseApi;
