import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  isLoading: true,
  data: [],
  error: null,
  total: 0,
};

export const fetchTemplateSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    data: [...data],
    isLoading: false,
    error: null,
  };
};

export const fetchTemplateFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, data: [], isLoading: false };
};
export const resetChooseTemplate = () => {
  return { ...INITIAL_STATE };
};
export const HANDLERS = {
  [Types.FETCH_TEMPLATES_SUCCESS]: fetchTemplateSuccess,
  [Types.FETCH_TEMPLATES_FAILURE]: fetchTemplateFailure,
  [Types.RESET_CHOOSE_TEMPLATE]: resetChooseTemplate,
};

export default createReducer(INITIAL_STATE, HANDLERS);
