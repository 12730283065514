import appLogger from "../utils/app-logger";
import { apiService } from "./base-api-service";
import {
  endPoints,
  getDashboardOrchestrationServiceEndPoint,
} from "./api-constants";
import { readCookie, getServiceToken } from "../utils/helpers";

class DashboardOrchestrationService {
  DashboardOrchestrationService(url) {
    return getDashboardOrchestrationServiceEndPoint(url);
  }

  getPSSSBillingInfo(userGuid, locale) {
    // PdfSimpli , SignSimpli
    appLogger.log("inside getBillingInfo ");
    const billingInfoEndpoint = this.DashboardOrchestrationService(
      `${endPoints.dashboardOrchestrationService.BILLING_INFO.replace(
        ":userGuid",
        userGuid
      ).replace(":locale", locale)}`
    );
    const jwtToken = readCookie("jwtToken"); //localStorage.getItem("jwtToken");
    const headers = { Authorization: "Bearer" + " " + jwtToken };
    return apiService.get(billingInfoEndpoint, null, headers);
  }
  async uploadDocuments(data) {
    appLogger.log("inside uploadDocument");
    //const jwtToken = readCookie("jwtToken");
    const jwtToken = await getServiceToken();
    const headers = {
      "content-type": "multipart/form-data",
      Authorization: "Bearer" + " " + jwtToken,
    };
    const uploadDocumentEndpoint = this.DashboardOrchestrationService(
      `${endPoints.dashboardOrchestrationService.UPLOAD_DOCUMENT}`
    );
    return apiService.post(uploadDocumentEndpoint, data, headers);
  }
}

export default new DashboardOrchestrationService();
