import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBodyRecommendSuccess } from "../../../../pages/cover-letter-body-recommend/actions";
import { updateCloserRecommendSuccess } from "../../../../pages/cover-letter-closer-recommend/actions";
import { updateDesiredCompanySuccess } from "../../../../pages/cover-letter-desired-company/actions";
import { updateExperienceSuccess } from "../../../../pages/cover-letter-experience/actions";
import { updateJobTitleSuccess } from "../../../../pages/cover-letter-job-title/actions";
import { updatePersonalInfoSuccess } from "../../../../pages/cover-letter-personal-information/actions";
import { updateOpenerRecommendSuccess } from "../../../../pages/cover-letter-recommend/actions";
import { updateSignatureSuccess } from "../../../../pages/cover-letter-signature/actions";
import { updateSkillsSuccess } from "../../../../pages/cover-letter-skills/actions";
import Cl08Modern from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateJobTitleSuccess: updateJobTitleSuccess,
      updateDesiredCompanySuccess: updateDesiredCompanySuccess,
      updateOpenerRecommendSuccess: updateOpenerRecommendSuccess,
      updateBodyRecommendSuccess: updateBodyRecommendSuccess,
      updateCloserRecommendSuccess: updateCloserRecommendSuccess,
      updateSignatureSuccess: updateSignatureSuccess,
      updatePersonalInfoSuccess: updatePersonalInfoSuccess,
      updateExperienceSuccess: updateExperienceSuccess,
      updateSkillsSuccess: updateSkillsSuccess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Cl08Modern);
