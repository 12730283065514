import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, link, useParams } from "react-router-dom";
import appLogger from "../../utils/app-logger";
// Style

// Plugs
import dompurify from "dompurify";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { ROUTE_CONSTANT } from "../../routing/route-constants";
import { SECTION_CODE, SECTION_ORDER, SECTION_TYPE } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import MetaContentService from "../../services/meta-content-api";

// Components
import Header from "../../components/header";
import HeaderSteps from "../../components/header-steps";
import Footer from "../../components/footer";
import ResumeMap from "../../components/resume-map";
import Button from "../../components/shared/button";
import WritingTips from "../../components/writing-tips/component";
import EducationList from "../education-list/component";
import EducationFields from "../../components/education-fields";
import PathHelper from "../../utils/path-helper";
import { RequiredValidation } from "../../validators/genericValidator";
import { EMPTY_GUID } from "../../utils/constants";
import moment from "moment";
import { APP_NAVIGATION } from "../../utils/constants";
import ModalPopup from "../../components/shared/modal-popup";
import { getPortalCD, readCookie, sorterEducation } from "../../utils/helpers";
import ReactLoader from "../../components/shared/loader";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import { trackRBEvents } from "../../utils/tracking-events";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Education = ({
  education,
  funnelStep,
  createEducationParagraphConnect,
  updateEducationParagraphConnect,
  deleteEducationConnect,
}) => {
  const { docGUID, funnelStepsComplete, currentStep, userGUID, email, personalInfoName } = funnelStep || {};
  const { locale } = useParams();
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [allValues, setAllValues] = useState();
  const [fieldfinalval, setFieldsValArray] = useState([]);
  const [showEducationList, setShowEducationList] = useState(true);
  const [formMessageClass, setFormMessageClass] = useState("alert add");
  const [formMessages, setFormMessages] = useState("");
  const [isData, setIsData] = useState(false);
  const [updateCall, setUpdateCall] = useState(false);
  const [showModalPopUp, setShowModalPopUp] = useState(false);
  const [educationListData, setEducationListData] = useState("");
  const [showAddMoreBtn, setShowAddMoreBtn] = useState(false);
  const [showLoader, setShowLoader] = useState("d-none");
  const [showStickyBar, setShowStickyBar] = useState(true);
  // Localization
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  let pathRedirect = PathHelper(APP_NAVIGATION.EDUC, funnelStepsComplete);
  let pathRedirect1 = PathHelper(APP_NAVIGATION.TEDU);

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  const {
    Paragraphs: { paraContents, paraName, id },
  } = education;

  // Nav
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(pathRedirect.back);
  };
  const backToFormFieldsHandler = () => {
    setFormFields(!showFormFields);
  };
  const saveNextClickHandler = () => {
    setFormFields(!showFormFields);
    navigate(pathRedirect.next);
  };
  const triggerValidation = () => {
    setIsValid(true);
    let isValidate = true;
    let finalErrors = [];
    if (
      (allValues.fieldValue.school_name == "" &&
        allValues.fieldValue.degree == "" &&
        allValues.fieldValue.field_of_study == "") ||
      (allValues.fieldValue.school_name == "" &&
        allValues.fieldValue.degree == "Custom Degree" &&
        allValues.fieldValue.custom_degree == "" &&
        allValues.fieldValue.field_of_study == "")
    ) {
      isValidate = false;
      setIsValid(false);
      let educationErrValidator = RequiredValidation("");

      var educationErrors = {
        errorControl: "schoolname",
        errorMessage: t("error_messages.required"),
      };
      var educTitle = {
        errorControl: "degree",
        errorMessage: t("error_messages.required"),
      };
      var fieldOfStudy = {
        errorControl: "field_of_study",
        errorMessage: t("error_messages.required"),
      };
      if (!allValues.fieldValue.school_name == "") {
        educationErrors.errorMessage = "";
      }
      if (!allValues.fieldValue.degree == "" && !allValues.fieldValue.degree == "Custom Degree") {
        educTitle.errorMessage = "";
      }
      if (!allValues.fieldValue.field_of_study == "") {
        fieldOfStudy.errorMessage = "";
      }
      finalErrors = [...[educationErrors], ...[educTitle], ...[fieldOfStudy]];
      setErrors(finalErrors);
      if (typeof paraContents === "undefined" || paraContents.length >= 1) {
        toggleModalPopUp(true);
      }
      let message = t("error_messages.must_fill_out_two_fields");
      setFormMessages(message);
      setFormMessageClass("alert error");
      //setShowErrorMsg(true);
      setIsData(true);
    }
    if (allValues.fieldValue.degree == "Custom Degree" && allValues.fieldValue.custom_degree == "") {
      allValues.fieldValue.degree = "";
    }
    return isValidate;
  };
  const updateOrderParaContents = (educParaContent) => {
    return educParaContent.sort(sorterEducation).map(({ order, ...educParaContent }, index) => ({
      ...educParaContent,
      order: index,
    }));
  };
  const saveClickHandler = () => {
    // executeScroll();
    if (typeof paraContents === "undefined" || paraContents.length === 0) {
      if (
        Object.values(allValues.fieldValue).every(
          (value) => value === null || value === false || (typeof value == "string" && !(value || false))
        )
      ) {
        toggleModalPopUp(true);
        return true;
      }
    }
    if (triggerValidation()) {
      setShowLoader("d-block");
      setFormFields(!showFormFields);
      setShowAddMoreBtn(true);
      const paraGraphId = !!id ? id : EMPTY_GUID;
      let educParaContent = [];
      let objIndex = -1;
      if (!!fieldfinalval && Object.keys(fieldfinalval).length > 0) {
        objIndex = paraContents.findIndex((x) => x.id == fieldfinalval.id);
      }
      if (objIndex > -1) {
        paraContents[objIndex].content = JSON.stringify(allValues.fieldValue);
        setFieldsValArray([]);
      } else {
        educParaContent.push({
          id: EMPTY_GUID,
          paraId: paraGraphId,
          content: JSON.stringify(allValues.fieldValue),
          createdOn: moment(new Date()),
          modifiedOn: moment(new Date()),
        });
      }
      if (!!paraContents && Object.keys(paraContents).length > 0) {
        paraContents.map((element, i) =>
          educParaContent.push({
            id: element.id,
            paraId: element.paraId,
            content: element.content,
            order: element.order,
            createdOn: element.createdOn,
            modifiedOn: element.modifiedOn,
          })
        );
      }
      setAllValues([]);
      const orderedEducParaContent = updateOrderParaContents(educParaContent);
      var para = {
        id: paraGraphId,
        docId: docGUID,
        paraContents: [...orderedEducParaContent],
        order:
          !!education && !!education.Paragraphs && !!education.Paragraphs.order && education.Paragraphs.order != 0
            ? education.Paragraphs.order
            : SECTION_ORDER.EDUC,
        paraCD: SECTION_TYPE.EDUC,
        sectionCD: SECTION_CODE.EDUC,
        paraName: !!paraName ? paraName : t("education.title"),
      };

      if (!!paraGraphId && paraGraphId !== EMPTY_GUID) {
        updateEducationParagraphConnect(docGUID, paraGraphId, para)
          .then((response) => {
            setEducationListData(response.paraContents);
            setShowEducationList(showEducationList);
            setShowAddMoreBtn(true);
            setShowLoader("d-none");
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      } else {
        createEducationParagraphConnect(para)
          .then((response) => {
            appLogger.log("inside education then ");
            setEducationListData(response.paraContents);
            setShowEducationList(showEducationList);
            setShowAddMoreBtn(true);
            setShowLoader("d-none");
            const {
              data: {
                detail: { para },
              },
            } = response;
          })
          .catch((err) => {
            const { detail } = err;
            appLogger.log(detail); // TODO
          });
      }
    }
    //  else {
    //   if (Object.keys(paraContents).length == 0) {
    //     toggleModalPopUp(true);
    //   }
    // }
    //navigate(ROUTE_CONSTANT.EDUCATION_TIPS);
  };
  const callBackEducation = (fieldValue) => {
    setAllValues({ ...allValues, fieldValue });
  };

  const [showFormFields, setFormFields] = useState(true);
  const setShowFormFields = () => {
    setErrors([]);
    if (paraContents.length > 0) {
      let adddegreemessage = t("education.add_another_degree");
      setFormMessages(adddegreemessage);
      setFormMessageClass("alert add");
      setIsData(true);
    }
    let fieldValue = {
      school_name: "",
      city: "",
      state: "",
      degree: "",
      custom_degree: "",
      field_of_study: "",
      education_graduation_month: "",
      education_graduation_year: "",
      graduationMonthLabel: "",
      presently_here: false,
    };
    setAllValues({ ...allValues, fieldValue });
    setFormFields(!showFormFields);
    setShowAddMoreBtn(false);
    setShowEducationList(showEducationList);
  };

  const UpdateParaContent = (paraContentId) => {
    if (!!paraContents && paraContents.length > 0) {
      let paraData = paraContents.find((x) => x.id == paraContentId);
      let tobeUpdateData = {
        school_name: JSON.parse(paraData.content).school_name,
        city: JSON.parse(paraData.content).city,
        state: JSON.parse(paraData.content).state,
        degree: JSON.parse(paraData.content).degree,
        field_of_Study: JSON.parse(paraData.content).field_of_study,
        education_graduation_month: JSON.parse(paraData.content).education_graduation_month,
        education_graduation_year: JSON.parse(paraData.content).education_graduation_year,
        presently_here: JSON.parse(paraData.content).presently_here,
      };
      setAllValues(tobeUpdateData);
      setFormFields(true);
      let message = JSON.parse(paraData.content).field_of_study;
      setIsData(true);
      setFormMessages(message);
      setFormMessageClass("alert edit");
      let fieldValue = JSON.parse(paraData.content);
      setAllValues({ ...allValues, fieldValue });
      setFieldsValArray(paraData);
      setUpdateCall(true);
      setShowAddMoreBtn(false);
      setErrors([]);
    }
    // alert(`inside UpdateParaContent with paracontentid is ${paraContentId}`);
  };
  const UpdateEducationList = (paraContentId, i) => {
    setFormFields(false);
    setShowAddMoreBtn(true);
    if (!!educationListData && educationListData.length > 0) {
      let newFormValues = [...educationListData];
      var index = newFormValues.findIndex((o) => o["id"] === paraContentId);
      if (index > -1) {
        newFormValues.splice(index, 1);
      }
      if (newFormValues.length === 0) {
        setFieldsValArray([]);
      }
      setEducationListData(newFormValues);
      var para = {
        id: educationListData[0].paraId,
        docId: docGUID,
        paraContents: [...newFormValues],
        order: SECTION_ORDER.EDUC,
        paraCD: SECTION_TYPE.EDUC,
        sectionCD: SECTION_CODE.EDUC,
        paraName: !!paraName ? paraName : t("education.title"),
      };
      deleteEducationConnect(docGUID, educationListData[0].paraId, para)
        .then((response) => {
          console.log(response);
          if (!!response.paraContents) {
            setFormMessages("");
            setIsData(false);
            setFormFields(false);
            setShowAddMoreBtn(true);
          }
        })
        .catch((err) => {
          const { detail } = err;
          appLogger.log(detail);
        });
    }
  };
  useEffect(() => {
    trackRBEvents("educationEditView", {
      visitId: readCookie("visitGuid"),
      userGuid: userGUID,
      portalSource: getPortalCD(locale),
      eventLabel: "Page View",
      email: email,
      firstName: personalInfoName,
      lastName: "",
    });
    if (paraContents.length > 0) {
      setFormFields(!showFormFields);
      setEducationListData(paraContents);
      setShowAddMoreBtn(true);
      setIsData(true);
    }
    let fieldValue = {
      school_name: "",
      city: "",
      state: "",
      degree: "",
      custom_degree: "",
      field_of_study: "",
      education_graduation_month: "",
      education_graduation_year: "",
      graduationMonthLabel: "",
      presently_here: false,
    };
    setAllValues({ ...allValues, fieldValue });
  }, []);
  const toggleModalPopUp = () => setShowModalPopUp((q) => !q);
  const callBackModalValue = (fieldValue) => {
    console.log(fieldValue);
  };
  return (
    <>
      <Helmet>
        <title>{t("page_title.education")}</title>
      </Helmet>
      {!funnelStepsComplete && (
        <>
          <HeaderSteps activeStep={3} />
        </>
      )}
      {funnelStepsComplete && (
        <>
          <Header />
        </>
      )}
      <ReactLoader showloader={showLoader} />
      <section className={classNames(funnelStepsComplete ? "" : "sidebar-sticky", "section main-content templates")}>
        <div className="container ">
          <div className="alignment-container">
            <div className="row headline-group">
              <div className="builder-header exp-header">
                <hgroup>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("education.headline")),
                    }}
                  />
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(t("education.subtitle")),
                    }}
                  />
                </hgroup>
                <WritingTips section={SECTION_TYPE.EDUC} page="Education" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {showEducationList && (
                  <EducationList
                    paraContents={educationListData}
                    UpdateParaContent={UpdateParaContent}
                    UpdateEducationList={UpdateEducationList}
                  />
                )}

                <div id="edu-scroll-container">
                  {showFormFields && (
                    <div className="row">
                      {isData && (
                        <div className="col-12">
                          <div id="form-messages" className={formMessageClass} role="alert">
                            {formMessageClass != "alert edit" && formMessages}
                            {formMessageClass === "alert edit" && (
                              <>
                                {t("education.editing_your")}
                                <strong> "{formMessages}" </strong>
                                {t("education.degree")}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-md-12 col-lg-9  px-2">
                        <EducationFields
                          callback={callBackEducation}
                          isValid={isValid}
                          errors={errors}
                          educationFieldsData={fieldfinalval}
                          updateCall={updateCall}
                          setShowStickyBar={setShowStickyBar}
                        />
                      </div>
                      <div className="col-md-4 col-lg-3 builder-info__image resume-navigator--education">
                        <ResumeMap />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {showAddMoreBtn && (
                  <button className="btn btn-primary btn-lg add-exp-btn is-mobile" onClick={setShowFormFields}>
                    <span className="fa fa-plus"></span>
                    {" " + t("education.add_degree")}
                  </button>
                )}
              </div>
              <div
                className={
                  showStickyBar === true
                    ? "btn-container fbn sticky-bar btn-justify cvltr-footer-btn"
                    : "btn-container fbn sticky-bar btn-justify d-none"
                }
              >
                <Button
                  isLoading={false}
                  classNames="btn btn-outline-secondary btn-lg back-btn  edu-back-btn cvltr-btn-blue"
                  id="back-from-education-page"
                  ctaText={t("personal_info.back_action")}
                  onClick={backClickHandler}
                />

                {/* {!showFormFields && (
                  <Button
                    isLoading={false}
                    classNames="btn btn-outline-secondary btn-lg"
                    ctaText={t("personal_info.back_action")}
                    onClick={backToFormFieldsHandler}
                  />
                )} */}
                {showFormFields && (
                  <HashLink smooth to={pathRedirect1.next + "#edu-scroll-container"}>
                    <Button
                      isLoading={false}
                      disabled={false}
                      onClick={saveClickHandler}
                      classNames="btn btn-primary btn-lg submit-on-enter"
                      id="next_page_after_education"
                      ctaText={t("education.save")}
                    />
                  </HashLink>
                )}
                {showAddMoreBtn && (
                  <button
                    className="btn btn-primary btn-lg add-exp-btn is-desktop"
                    id="add_another-degree"
                    onClick={setShowFormFields}
                  >
                    <span className="fa fa-plus"></span>
                    {" " + t("education.add_degree")}
                  </button>
                )}
                {!showFormFields && (
                  <Button
                    isLoading={false}
                    disabled={false}
                    onClick={saveNextClickHandler}
                    id="education_save_next"
                    classNames="btn btn-primary btn-lg submit-on-enter save-n-next-btn"
                    ctaText={t("education.next_action")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalPopup
        message={
          paraContents.length === 0
            ? {
                __html: sanitizer(t("education.edu_modal_message")),
              }
            : {
                __html: sanitizer(t("education.edu_modal_message_second_step")),
              }
        }
        footerText={
          paraContents.length === 0
            ? {
                __html: sanitizer(t("education.edu_modal__footer_text")),
              }
            : {
                __html: sanitizer(t("education.edu_modal__footer_text_second_step")),
              }
        }
        skipPage={pathRedirect}
        show={showModalPopUp}
        toggleShow={toggleModalPopUp}
        header={t("education.edu_modal_title")}
        callback={callBackModalValue}
      />
      <Footer />
    </>
  );
};
export default Education;
